// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import  secureLocalStorage  from  "react-secure-storage";
import TicketRaiseHeader from 'layouts/common_components/ticket_raise_header';

function AssignList() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [assignlist, setassignlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [ticketid,setticketid] = useState('');
  const [id,setid] = useState('');
  const [status,setstatus] = useState('');
  const [start_date,setstart_date] = useState('');
  const [end_date,setend_date] =  useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [remark,setremark] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  const fetchData = async () => {
    try {
      const endpoint = `assign-ticket-list/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setassignlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
 useState(()=>{
   fetchData();

  },[])
const handleOpenModal = (id,ticketid) =>{
  setticketid(ticketid);
  setid(id);
  setOpenModal(true);
}
const handlesubmit = async() =>{
    const errors = {};
    if (Object.keys(errors).length > 0) {
      return; 
    }
   // console.log(selectedOptions);

    const formData = new FormData();
    formData.append('status',status);
    formData.append('start_date',start_date);
    formData.append('end_date',end_date);
    formData.append('ticket_id',ticketid);
    formData.append('id',id);
    formData.append('remark',remark);
    formData.append('emp_id',emp_id);
   // setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('assign-ticket-update', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //navigate("/ticket-receive");
        handleCloseModal();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } 



}

const handleCloseModal = () => {
  setOpenModal(false);
  };
//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <TicketRaiseHeader/>
              <div className="card-body">
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                 <div className="table-responsive">
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Ticket Id</td>
                  <td>From Dept</td>
                  <td>Attribute</td>
                  <td>Subattribute</td>
                  <td>Completion Time</td>
                  <td>Assign By</td>
                  <td>Action</td>
               </tr>
               {assignlist.map((ticket, index) => (
                <tr key={ticket.id}>
                  <td>{index + 1}</td>
                  <td>{ticket.ticket_id}</td>
                  <td>{ticket.department_name}</td>
                  <td>{ticket.attribute_name}</td>
                  <td>{ticket.name}</td>
                  <td>{ticket.completion_time} hour</td>
                   <td>{ticket.emp_fname} {ticket.emp_lame}</td>
                   <td>
                  {ticket.close_status === 0 ? (
                       <Link to={`/view-ticket-status/${ticket.ticket_id}`} className='btn btn-success me-2'>
                              view
                  </Link>
                      ) : (
                    <Link className='btn btn-danger me-2'>
                         Closed
                     </Link>
                      )}

                    </td>
               </tr>
              ))}
            </table>
            </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}
      >
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <form>
            <div>
             <label>Select Status:</label>
              <select className="form-control"
                             value={status}
                             onChange={(e) => setstatus(e.target.value)}
                              >
                             <option value=''>Select Status</option>
                                <option value='not started'>Not started</option>
                                 <option value='done'>Done</option>
                                 <option value='need to discuss'>Need To Discuss</option>
                                 <option value='in progress'>In Progress</option>
                                </select>
                           </div>
       
                     <div>
              <label>Start Date Of Task:</label>
              <TextField
                type="date"
                fullWidth
                 value={start_date}
                onChange={(e) =>setstart_date(e.target.value)}
              />
             
            </div>
            <div>
              <label>End Date Of Task:</label>
              <TextField
                type="date"
                fullWidth
                value={end_date}
                onChange={(e) => setend_date(e.target.value)}
              />
              
            </div>

             <div>
              <label>Remark:</label>
              <TextField
                type="text"
                fullWidth
                value={remark}
                onChange={(e) => setremark(e.target.value)}
              />
              
            </div>
          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick = {handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick = {handlesubmit}>
           Update
          </Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default AssignList;
