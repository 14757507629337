import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate,useParams } from "react-router-dom";
import EmployeeDetailsHeader from "layouts/common_components/emp_details_header";
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";


function AccountInfo() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const [name, setname] = useState('');
  const [accountno, setaccountno] = useState('');
  const [ifsc, setifsc] = useState('');
  const [mode, setmode] = useState('');
  const[accountlist,setaccountlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [pf,setpf] = useState('');
  const [esic,setesic] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);

  const saveaccount = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!name) {
      errors.bank_name = 'Bank Name is required';
    }
    if (!accountno) {
      errors.account_no = 'Account no  is required';
    }
    if (!ifsc) {
      errors.ifsc_code = 'Ifsc Code is required';
    }
    if (!mode) {
      errors.payment_mode = 'Payment Mode is required';
    }
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
    const formData = new FormData();
    formData.append('bank_name', name);
    formData.append('account_no', accountno);
    formData.append('ifsc_code', ifsc);
    formData.append('payment_mode', mode);
    formData.append('emp_id', id);
    formData.append('pf',pf);
    formData.append('esic',esic);
   // alert(emp_id);

    try {
      const response = await dashboardApi.post('save-account', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate(`/account-info/${id}`);
        fetchaccountdetails();
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
  }
  };

  useEffect(() => {
    fetchaccount();
  }, []);

  const fetchaccount = async () => {
 await dashboardApi.get(`edit-account/${id}`)
    .then(({ data }) => {
    if(data.account.length>0){
      const { emp_id, bank_name, account_no, ifsc_code, payment_mode,uan_no,esic_no } = data.account[0];
      setname(bank_name);
      setaccountno(account_no);
      setifsc(ifsc_code);
      setmode(payment_mode);
      setpf(uan_no);
      setesic(esic_no);
    }
    else{
      setname('');
      setaccountno('');
      setifsc('');
      setmode('');
    }

    })
    .catch((error) => {
      console.error(error);
      Swal.fire({
        text: 'An error occurred while fetching account information',
        icon: 'error',
      });
    });
};
useEffect(()=>{
        fetchaccountdetails();
    },[])
   const fetchaccountdetails = async () => {
    try {
      const response = await dashboardApi.get(`account-details/${id}`);
      if (response.data.status === 200) {
         const accountData = Array.isArray(response.data.account) ? response.data.account : [response.data.account];
        setaccountlist(accountData);
      } else {
        console.error('Error fetching account:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching account:', error);
      setLoading(false);
    }
  };
  const toggleStatus = async (accountId) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`get-account-status/${accountId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      // Calculate the new status (toggle)
      const newStatus = currentStatus === 1 ? 0 : 1;
      // Make an API call to update the status
      const updateResponse = await dashboardApi.post('account-status', {
        id: accountId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setaccountlist((prevState) =>
          prevState.map((accountObj) =>
            accountObj.id === accountId ? { ...accountObj, status: newStatus } : accountObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
           <EmployeeDetailsHeader id={id}/>
            <Card>
              <div className="card-body">
              <Link className='btn btn-primary mb-2 float-end' to={"/employee-list"}>
                  Back
                </Link>
                <form onSubmit={saveaccount}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="bussiness name" style={{ fontSize: '16px' }}>Bank Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Bank Name"
                            value={name}
                            onChange={(event) => {
                              setname(event.target.value)
                            }}
                          />
                          {validationErrors.bank_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.bank_name}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Account No</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Account Number"
                            value={accountno}
                            onChange={(event) => {
                              setaccountno(event.target.value)
                            }}
                          />
                          {validationErrors.account_no && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.account_no}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>IFSC Code</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ifsc Code"
                            value={ifsc}
                            onChange={(event) => {
                              setifsc(event.target.value)
                            }}
                          />
                          {validationErrors.ifsc_code && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.ifsc_code}</div>
                             )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Payment Mode</label>
                          <select className="form-control"
                            value={mode}
                            onChange={(event) => {
                              setmode(event.target.value)
                            }}
                          >
                            <option value=''>Select Payment Mode</option>
                            <option value='online'>Online</option>
                            <option value='cheque'>Cheque</option>
                            <option value="neft">NEFT</option>
                          </select>
                        </div>
                        {validationErrors.payment_mode && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.payment_mode}</div>
                             )}
                      </div>

                    <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>UAN No</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Uan No"
                            value={pf}
                            onChange={(event) => {
                              setpf(event.target.value)
                            }}
                          />
                        </div>
                        {validationErrors.pf && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.pf}</div>
                             )}
                      </div>
                    
                   <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>ESIC No</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Esic No"
                            value={esic}
                            onChange={(event) => {
                              setesic(event.target.value)
                            }}
                          />
                        </div>
                        {validationErrors.esic && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.esic}</div>
                             )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                          Save
                        </button>
                         <div className="d-flex justify-content-end mt-3">
                           
                       </div> 
                      </div>
                    </div>
                  </div>
                </form>
                <center>Account Details</center>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Bank Name</td>
                  <td>Account No</td>
                  <td>IFSC code</td>
                  <td>Mode</td>
                  <td>Status</td>
               </tr>
               {accountlist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.bank_name}</td>
                  <td>{row.account_no}</td>
                  <td>{row.ifsc_code}</td>
                  <td>{row.payment_mode}</td>
                <td><button
                  type="button"
                  className={`btn btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>

               </tr>
              ))}

            </table>
             )}
                

              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AccountInfo;
