import { useEffect, useState } from 'react';
import dashboardApi from 'layouts/common_components/apibase_url';
import secureLocalStorage from 'react-secure-storage';

function SessionOut() {
  const [idleStartTime, setIdleStartTime] = useState(null);
  const emp_id = secureLocalStorage.getItem('emp_id');
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
   const seconds = String(now.getSeconds()).padStart(2, '0');

    // Format the components into a string
    const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log(formattedTime);

  const handleLogout = async () => {
    try {
      const endpoint = 'session-destroy';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === '200') {
        secureLocalStorage.removeItem('token');
        window.location.href = 'newhrms/sign-in';
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  let logoutTimer;
  let idleTimer;

  const resetTimer = () => {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(handleLogout, 360000);
  };

  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(() => {
      setIdleStartTime(formattedTime);
      logIdleTime();
    }, 60000);
  };

  const logIdleTime = async () => {
    if (idleStartTime) {
      try {
        const endpoint = 'store-idle-time';
        const response = await dashboardApi.post(endpoint, {
          emp_id: emp_id,
          time_start: idleStartTime,
          time_end: formattedTime,
        });

        if(response.data.status === '200'){
          setIdleStartTime(null); 
          resetIdleTimer();
        }

        console.log('Idle time logged successfully:', response.data);
      } catch (error) {
        console.error('Error logging idle time:', error);
      }
    }
  };

  const handleUserActivity = () => {
    setIdleStartTime(null); 
    resetIdleTimer();
  };

  useEffect(() => {
    resetTimer();
    resetIdleTimer();

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      clearTimeout(logoutTimer);
      clearTimeout(idleTimer);
    };
  }, [emp_id, idleStartTime]);

  return null;
}

export default SessionOut;
