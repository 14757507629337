// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import TicketRaiseHeader from 'layouts/common_components/ticket_raise_header';


function ListOfOrgnisation() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [raiselist, setraiselist] = useState([])
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(5);
   const [openModal, setOpenModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState('');
  const [remark,setremark] = useState('');
   const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
 useEffect(() => {
   fetchData();
  }, []);
   const fetchData = async (page = 0) => {
    try {
    const endpoint = 'ticket-list-raise-in-org';
    const response = await dashboardApi.get(endpoint, {
     params: {
       per_page: postPerPage,
       page: page + 1,
      }
     });

      if (response.data.status === 200) {
        //setraiselist(response.data.data);
        const responseData = response.data.data;
        const ticketdata = responseData.data || responseData;
        setraiselist(ticketdata);
        setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchData(selected.selected);
    //fetchData(selected.selected);
   // console.log(selected);


  };
  const handleOpenModal = async (ticketId, departmentId,group) => {
  //alert(group);
    try {
        const endpoint = 'get-employee-details-with-group';
         const response = await dashboardApi.get(endpoint, {
       params: {
       department: departmentId,
       group: group,
      }
      });
        //console.log(response);
      if (response.data.status === 200) {
         setSelectedDepartmentId(response.data.data);
      } 
      setLoading(false);
    } catch (error) {
      console.error('Error fetching leave:', error);
      setLoading(false);
    }
    setSelectedTicket(ticketId);
   // setSelectedOptions([]);
    //setSelectedDepartmentId(departmentId);
    setOpenModal(true);
  };
  //console.log(selectedDepartmentId);

  const handleCloseModal = () => {
    setSelectedTicket(null);
    //setSelectedDepartmentId(null);
    setSelectedOptions('');
    setOpenModal(false);
    setremark('')
   // se
  };
   const savedata = async (e) => {
    e.preventDefault();
    const errors = {};
    if (setSelectedOptions.length === 0) {
      errors.selected_team = 'Please select at least one Member';
    //alert();
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return; 
    }
   // console.log(selectedOptions);

    const formData = new FormData();
    formData.append('selected_team', selectedOptions);
    formData.append('remark', remark);
    formData.append('ticket_id',selectedTicket);
    formData.append('emp_id',emp_id);
   // setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-assign-ticket', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //navigate("/ticket-receive");
        handleCloseModal();
        fetchData();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } 
  };
//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <TicketRaiseHeader/>
              <div className="card-body">
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                 <div className="table-responsive">
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Ticket Id</td>
                  <td>Request From</td>
                  <td>Request To</td>
                  <td>Attribute</td>
                  <td>Subattribute</td>
                  <td>Action Time(in Hour)</td>
                  <td>Completion Time(in Hour)</td>
                  <td>Action</td>
                  <td>Ticket Status</td>
               </tr>
               {raiselist.map((ticket, index) => (
                <tr key={ticket.id}>
                  <td>{index + 1}</td>
                  <td>{ticket.ticket_id}</td>
                  <td>{ticket.request_from}</td>
                  <td>{ticket.request_to}</td>
                  <td>{ticket.attribute}</td>
                  <td>{ticket.subattribute}</td>
                  <td>{ticket.action_time}</td>
                  <td>{ticket.completion_time}</td>
                 <td>
                  <Link to={`/view-ticket-status/${ticket.ticket_id}`} className='btn btn-success me-2 btn-sm'>
                              View 
                  </Link>
                   &nbsp; &nbsp;
                  {ticket.exists =='No'  && ticket.close_status === 0 &&
                 <button className="btn btn-info btn-sm" onClick={() => handleOpenModal(ticket.ticket_id, ticket.request_to_department,ticket.group_id)}>Assign</button>
                }
                  </td>
                  <td>
                  {ticket.close_status === 0 ? (
                       <p>
                         progress
                       </p>
                     ) : (
                 <Link className='btn btn-danger me-2 btn-sm'>
                    Closed
                 </Link>
                )}

                    </td>
               </tr>
              ))}
            </table>
            </div>
             )}
              {raiselist.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
       <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'500px' } }}
      >
        <DialogTitle>Assign To Team</DialogTitle>
        <DialogContent>
          <form>
            <div>
              <label>Select Team:</label>
              <select className="form-control"
                             value={selectedOptions}
                             onChange={(e) => setSelectedOptions(e.target.value)}
                              >
                            <option value=''>Select Team</option>
                             {selectedDepartmentId.map((item) => (
                             <option key={item.emp_id} value={item.emp_id}>
                              {item.emp_fname}  {item.emp_lame}
                              </option>
                              ))}
                          </select>
            
              {validationErrors.selected_team&& (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selected_team}</div>
                )}
            </div>
            <div>
              <label>Remark:</label>
              <TextField
                type="text"
                fullWidth
                 value={remark}
                onChange={(e) => setremark(e.target.value)}
              />
             {validationErrors.remark&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                )}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick={savedata}>
            Assign
          </Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default ListOfOrgnisation;