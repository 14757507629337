import { useEffect } from 'react';
import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";

function SessionLogout() {
  const emp_id = secureLocalStorage.getItem('emp_id');
  useEffect(()=>{
    handleLogout();
   },[emp_id])

  const handleLogout = async () => {
    try {
      const endpoint = 'session-logout';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === '500') {
        //alert('hi');
        secureLocalStorage.removeItem('token');
        window.location.href = "newhrms/sign-in";
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };


  return null; 
}

export default SessionLogout;