import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ReportsBarChart from 'theme_layout/Charts/BarCharts/ReportsBarChart';
import ReportsLineChart from 'theme_layout/Charts/LineCharts/ReportsLineChart';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';

// Data
import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Dashboard components
import EmpBirthday from 'layouts/emp_birthday';
import CalanderLeave from 'layouts/yearly_leave';
import BirthdayNotification from 'layouts/birtday_wish';
import {Link} from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');

  return (
    <DashboardLayout>
    {empId =='RIMS1' && 
    <div className="container">
    <div className="row">
    <div className="col-md-4">
    <Link to="/sales-dashboard" className="btn btn-info">Sales Dashboard</Link>


    </div>
    <div className="col-md-4">
    <Link to="/cs-dashboard" className="btn btn-info">Cs Dashboard</Link>


    </div>
    <div className="col-md-4">
    <Link to="/digital-dashboard" className="btn btn-info">Digital Dashboard</Link>


    </div>
    </div>
    </div>

      
    }
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Bookings"
                count={281}
                percentage={{
                  color: 'success',
                  amount: '+55%',
                  label: 'than lask week',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Today's Users"
                count="2,300"
                percentage={{
                  color: 'success',
                  amount: '+3%',
                  label: 'than last month',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count="34k"
                percentage={{
                  color: 'success',
                  amount: '+1%',
                  label: 'than yesterday',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: 'success',
                  amount: '',
                  label: 'Just updated',
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <CalanderLeave />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
               <EmpBirthday />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
     <BirthdayNotification/>
     <ToastContainer />
    </DashboardLayout>
  );
}

export default Dashboard;
