import React from 'react';
import { Link,useLocation } from "react-router-dom";
function ProductDetailsHeader() 
{
  let location = useLocation();
  let currentPage = location.pathname;
  return (
            <div>
                <Link style={currentPage === "/product-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/product-details">
                Product Details
                </Link>
                &nbsp;&nbsp;&nbsp;
                <Link style={currentPage === "/service-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/service-details">
                Service Details
                </Link>
                &nbsp;&nbsp;&nbsp;
                <Link style={currentPage === "/category-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/category-details">
                Category Details
                </Link>
                &nbsp;&nbsp;&nbsp;

                <Link style={currentPage === "/attribute-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/attribute-details">
                Attribute Details
                </Link>
                &nbsp;&nbsp;&nbsp;

                <Link style={currentPage === "/package-category" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/package-category">
                Package Category
                </Link>
                &nbsp;&nbsp;&nbsp;

                <Link style={currentPage === "/package-category-type" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/package-category-type">
                Package Category Types
                </Link>
                &nbsp;&nbsp;&nbsp;
            </div>
  );
}
export default ProductDetailsHeader;
