import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import {useNavigate,useParams } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import { Link } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import ProfileDetailsHeader from  "layouts/common_components/profileheader.js";

function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [option,setoption] = useState('');
  //console.log(option);
  let emp_id = '';
  const { id } = useParams();
  if(id){
   emp_id = id;
 }
 else{
  emp_id = secureLocalStorage.getItem('emp_id');

 }
 //alert(emp_id);
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const[profiledata,getprofiledata] = useState({});
    useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await dashboardApi.get(`emp-profile/${emp_id}`);
        if (response.data.status === 200) {
          getprofiledata(response.data.data[0]); // Assuming the API returns an array with a single object
        } else {
          console.error('Error fetching employee profile:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching employee profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [emp_id]);
    useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
    //alert(profiledata.id);
    const profile =  `http://192.168.1.253:4000/profile_picture/${profiledata.profile_picture}`


  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  if(option=='policy'){
    navigate('/company-policy')
  }
  if(option=='password'){
    navigate('/change-password')
  }
  if(option=="raise"){
    navigate('/generate-ticket');
  }
  if(option=="history"){
    navigate(`/emp-salary-history/${emp_id}`);
  }
  if(option =='regularize'){
    navigate('/attendance-regularize');

  }
  if(option =='resign'){
    navigate('/emp-resign');

  }
  if(option =='opening_request'){
    navigate('/opening-request')
  }

  if(option =='stock'){
    navigate('/emp-stock-list')
  }
  if(option=='idle_break'){
    navigate('/break-and-idle-time')
  }
  if(option =='birthday_holiday'){
    navigate('/birthday-and-holidays');

  }


  return (
    
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular"> 
              
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={profile} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
               {profiledata.emp_fname} {profiledata.emp_lame}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {profiledata.department_name} / {profiledata.designation_name}
              </MDTypography>
            </MDBox>
          </Grid>
          {!id && (
          <Grid item xs={12} md={8} lg={4} sx={{ ml: "auto" }}>
            <div style={{width:'200px'}}>
            <select className="form-control"
              value={option}
              onChange={(e) => setoption(e.target.value)}
            
            >
              <option value="">Select Option</option>
              <option value ='password'>Change Password</option>
              <option value = 'policy'>Company Policy</option>
              <option value="raise">Raise Ticket</option>
              <option value="history">View Your Histroy</option>
              <option value="resign">Resign</option>
              <option value = 'regularize'>Attendance Request</option>
              <option value='opening_request'>Send Opening Request</option>
              <option value='stock'>Stock Details</option>
              <option value ='idle_break'>Idle/Break Time Report</option>
              <option value = 'birthday_holiday'>Birthday and Holidays</option>

             </select>
            </div>
            
                {/* <Link to="/change-password" className="btn btn-info">Change Password</Link>&nbsp;
                
                <Link to="/company-policy" className="btn btn-info">Company Policy</Link> */}
              
          </Grid>
          )}
        </Grid>
          {!id && (
          <ProfileDetailsHeader/>
          )}
          
          <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
