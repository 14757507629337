import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

function RaiseAssetRequestFinance() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [requestList, setRequestList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [id,setid] = useState('');
  //const [remark, setRemark] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [status,setstatus] = useState('');
  const [remark,setremark] = useState('');
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchStock();

  },[])

    const fetchStock = async () => {
      try {
        const endpoint = 'raise-asset-request-list';
        const response = await dashboardApi.get(endpoint);
        if (response.data.status === 200) {
          setRequestList(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

  const openModalHandler = async(raiseid) => {
   // alert(raiseid);
     setid(raiseid);
     try {
        const endpoint = `get-asset-request-status/${raiseid}`;
        const response = await dashboardApi.get(endpoint);
       if (response.data.status === 200) {
        console.log(response);
          //alert(response.data.data[0].approval_remark);
      //const assignToValues = response.data.data.map(item => item.assign_to);
      setremark(response.data.data.finance_remark);
      setstatus(response.data.data.status);
      //setselectedemployee(assignToValues);
        } else {
          console.error('Error fetching data:');
        }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }

     setOpenModal(true);
  }

  const closeModalHandler = () => {
    setid('');
    setremark('');
    setstatus('');
    setOpenModal(false);
    setValidationErrors('');
  }

  const saveHandler = async(e) => {
    e.preventDefault();
    const errors = {};
    const formData = new FormData();
    if(status!=0){
        formData.append('status',status);

    }
    if(remark!=null){
        formData.append('remark',remark);
    }
    formData.append('raise_id',id);
    formData.append('emp_id',empId);

    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('change-asset-request-status', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchStock();
        closeModalHandler();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }
    
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Category</td>
                        <td>Brand Name</td>
                        <td>Branch</td>
                        <td>Quantity</td>
                        <td>Status</td>
                        <td>Admin Remark</td>
                        <td>Finance Remark</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {requestList.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.category_name}</td>
                          <td>{row.brand_name}</td>
                          <td>{row.branch_name}</td>
                          <td>{row.quantity}</td>
                          <td>
                            {row.status === 1 && "Approved"}
                            {row.status === 2 && "Rejected"}
                            {row.status === 0 && "Pending"}
                          </td>
                          <td>{row.admin_remark}</td>
                          <td>{row.finance_remark}</td>
                          {row.status ===1 ?(
                           <td>
                            <Link>
                             Accepted
                           </Link>
                         </td>
                         ):(
                       
                        <td><button className="btn btn-info"  onClick={() => openModalHandler(row.id)}>Change Status</button></td>
                    
                        )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        onClose={closeModalHandler}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Asset Request</DialogTitle>
        <DialogContent>
          <form>
            <div>
            <div>
              <label>Change Status</label>
              <select className="form-control" value={status} onChange={(e) => setstatus(e.target.value)} fullWidth>
                <option value=" ">Change Status</option>
                <option value="1">Accept</option>
                <option value="2">Reject</option>
              </select>
            </div>
             {validationErrors.status&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                )}
            <div>
              <label>Reason:</label>
              <TextField
                type="text"
                placeholder = "Enter reason"
                fullWidth
                 value={remark}
                onChange={(e) => setremark(e.target.value)}
              />
               {validationErrors.remark&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                )}
            </div>
            
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closeModalHandler}>
            Close
          </button>
          <button className="btn btn-info" onClick={saveHandler}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default RaiseAssetRequestFinance;