import React, {useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import {useNavigate,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";


function AddDepartment() {
  const navigate = useNavigate();
  const token = secureLocalStorage.getItem('token');
  const [department, setDepartment] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
   useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  const savedepartment = async (e) => {
    e.preventDefault();
    //alert();
    const formData = new FormData()
    formData.append('name', department)
    setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('save-department', formData);
      //console.log(response);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/department-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
    finally {
        setIsSubmitting(false);
      }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <Link className='btn btn-info' to='/department-list'>
                  Back
                </Link>
                <form onSubmit={savedepartment}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Department Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="department-name"
                            placeholder="Enter Department Name"
                            value = {department}
                            onChange={(event)=>{
                              setDepartment(event.target.value)}}

                          />
                           {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddDepartment;
