import React from 'react';
import { Link, useLocation } from "react-router-dom";
function FinanceDetailsHeader() 
{
    let location = useLocation();
    let currentPage = location.pathname;

  return (
    
    <div>
      <Link style={currentPage === "/finance-admin" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/finance-admin">
        New Amount
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/approved-amount" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/approved-amount">
        Approved Amount
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/payment-history" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/payment-history">
        Payment History
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/ledger-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/ledger-details">
        Ladger Details
      </Link>
      &nbsp;&nbsp;&nbsp;
    
   </div>
  );
}


export default FinanceDetailsHeader;
