import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {useNavigate } from 'react-router-dom'
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function Product() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
   const navigate = useNavigate();
  const [product, setproduct] = useState([]);
  const [loading, setLoading] = useState(true);
   const permission = secureLocalStorage.getItem('permission');
   const parsedPermission = permission ? JSON.parse(permission) : [];
   const add_product = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-product");
   const edit_product = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-product");
   const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
   useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
        fetchproduct() 
    },[])
   const fetchproduct = async () => {
    try {
      const response =await dashboardApi.get('product-list');
      if (response.data.status === 200) {
        setproduct(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };
  const toggleStatus = async (productId) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    // Make an API call to get the current status
    const response =await dashboardApi.get(`product-status/${productId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      //console.log(response.data.data);
      // Calculate the new status (toggle)
      const newStatus = currentStatus === 1 ? 0 : 1;
      // Make an API call to update the status
      const updateResponse = await dashboardApi.post('product-change-status', {
        product_id: productId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setproduct((prevState) =>
          prevState.map((productObj) =>
            productObj.product_id === productId ? { ...productObj, status: newStatus } : productObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
            {add_product && (
              <Link className='btn btn-primary mb-2 float-end' to={"/add-product"}>
                    Add Product
                </Link>
              )}
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Product Name</td>
                  <td>Company Name</td>
                  <td>Short Name</td>
                  {change_status && (
                  <td>Status</td>
                  )}
                 {edit_product && (
                  <td>Action</td>
                 )}
               </tr>
               {product.map((products, index) => (
                <tr key={products.product_id}>
                  <td>{index + 1}</td>
                  <td>{products.product_name}</td>
                  <td>{products.business_name}</td>
                  <td>{products.short_name}</td>
                  {change_status && (
                  <td><button
                  type="button"
                  className={`btn btn-${products.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(products.product_id)}
                  >
                  {products.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                )}
                {edit_product && (
                 <td>
                  <Link to={`/edit-product/${products.product_id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>

                 </td>
                   )}
                  
               </tr>
              ))}

            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Product;
