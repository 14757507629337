import { useEffect } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate,useParams } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

const BirthdayNotification = () => {
 const navigate = useNavigate();
 const emp_id = secureLocalStorage.getItem('emp_id');
 const token = secureLocalStorage.getItem('token');
 useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);

 useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = `birthday-notification/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

                 if (response.data.isBirthday) {
                    toast.success('🎉 Happy Birthday'+' '+response.data.name, {
                        position: "top-right",
                        autoClose: 5000, // 5 seconds
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                } 
             } catch (error) {
               console.error('Error fetching data:');
               }
        };

  fetchData();
}, []);
}

export default BirthdayNotification;
