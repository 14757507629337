import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";


function EmpHistoryHeader(props) {



  return (
            <Card>
              <div className="card-body">
                  <div className="container-fluid">
                  <div className="row">
                  <div className="col-md-2">
                  <Link  to={`/emp-salary-history/${props.emp_id}`} className='btn btn-outline-primary'>
                           Salary
                  </Link>
                  </div>
                  <div className="col-md-2">
                 <Link to={`/emp-leave-history/${props.emp_id}`} className='btn btn-outline-primary'>
                            Leave
                  </Link>
                  </div>
                  <div className="col-md-2">
                 <Link to={`/emp-wfh-history/${props.emp_id}`} className='btn btn-outline-primary'>
                             WFH
                  </Link>
                  </div>
                  <div className="col-md-2">
                  <Link to={`/emp-stock/${props.emp_id}`} className='btn btn-outline-primary'>
                           Stock
                  </Link>
                  </div>
                   <div className="col-md-2">
                   <Link  to={`/emp-reporting-history/${props.emp_id}`} className='btn btn-outline-primary'>
                            Reporting
                  </Link>
                  </div>
                 <br/> <br/>

                  <div className="col-md-2">
                   <Link to={`/emp-roster-history/${props.emp_id}`} className='btn btn-outline-primary'>
                           Roster 
                  </Link>
                  </div>

                  </div>
                  </div>
              </div>
            </Card>
  );
}


export default EmpHistoryHeader;
