import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Form, Button, ProgressBar } from 'react-bootstrap';
// Data
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import docurl from 'layouts/common_components/doc_base_url';
import { useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
//import ClientDetailsHeader from 'layouts/common_components/client_details_header';
import { TextField } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tab from 'assets/theme/components/tabs/tab';
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';

import styled from "styled-components";
import MatureClientProfileHeader from 'layouts/common_components/mature_client_profile_header';
import { MultiSelect } from "react-multi-select-component";
import { CheckBox } from '@mui/icons-material';

function MatureClientProfile() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [packageloading, setPackageLoading] = useState(false);
  const [duePaymentLoading, setDuePaymentLoading] = useState(false);


  const [addModal, setAddModal] = useState(false);
  const [clientFollowup, setClientFollowup] = useState();

  //const [clientDetails,setClientDetails] = useState();
  const [documentType, setDocumentType] = useState();
  const [orgType, setOrgType] = useState();
  const [matureClientDetails, setMatureClientDetails] = useState();
  const [companyDetails, setClientCompanyDetails] = useState();


  const [soldPackages, setSoldPackages] = useState('');

  const [orgTypeId, setOrgTypeId] = useState('');
  //const [clientName, setClientName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientMobile, setClientMobile] = useState('');
  const [clientCity, setClientCity] = useState('');
  const [clientState, setClientState] = useState('');
  const [clientPinNo, setClientPinNo] = useState('');
  const [clientAddress, setClientAddress] = useState('');
  const [clientPanCardNo, setPanCardNo] = useState('');


  const [isDivShow, setDivShow] = useState(false);
  const [followup, setFollowup] = useState([]);
  const [nxtFollowupDate, setNxtFollowupDate] = useState('');
  //const [nextFollowupDate, setNextFollowupDate] = useState('');

  const myDate = new Date(nxtFollowupDate);
  const nxtFolDate = myDate.toLocaleDateString();

  const [follwoupModal, setFollowupModal] = useState(false);
  const [selectedFollowCallStatus, setFollCallStatus] = useState('');
  const [selectedFollowStatus, setFollStatus] = useState('');
  const [followupRemarks, setFollowupRemarks] = useState('');
  const [followupAmount, setFollowupAmount] = useState('');

  const [packageby, setpackageby] = useState(false);
  const [duePackagePay, setDuePackagePay] = useState(false);

  // const [prePackageDetails, setLmartPrePackageDetails] = useState([]);
  // const [filteredUsers, setFilteredUsers] = useState(prePackageDetails);
  const [packagedetails, setpackagedetails] = useState([]);
  const [cityDetails, setPackageCityList] = useState([]);

  useEffect(() => {
    if (cityDetails.length > 0) {
      setSelectedPackageCity(cityDetails);
    }
  }, [cityDetails]);




  const [viewOfferDetailsStatus, setViewOfferDetails] = useState(0);

  const [duePackagedetails, setDuePackagedetails] = useState({});
  const [walletHistory, setWalletHistory] = useState();
  const [amount, setClientAmount] = useState('');

  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState();

  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();

  const [selectedService, setSelectedService] = useState();
  const [service, setService] = useState([]);

  const [compDetails, setCompDetails] = useState([]);
  const [selectedComp, setSelectedComp] = useState();

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [prePackageList, setPackageList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState('');

  const [nextDays, setNextDays] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);

  //const [mainGroup,setMainGroup]= useState();

  const [selectedDocumentType, setSelectedDocumentType] = useState();
  const [selectedDocumentNo, setSelectedDocument] = useState();
  const [documentFile, setDocumentFile] = useState([]);
  const [clientDocDetails, setUplodedDocuments] = useState([]);

  const [feedbackModal, setFeedbackModal] = useState(false);

  const handleFileChange = (e) => {
    setDocumentFile(e.target.files);
  };

  const [packageModal, setPackageModal] = useState(false);
  const [updatePackageId, setUpdatePackageId] = useState();

  //package start and stop date
  const [currentPackageStatus, setCurrentPackageStatus] = useState();

  const [packageStopRemark, setPackageStopRemarks] = useState();
  const [packageStartDate, setPackageStartDate] = useState();
  const [areYouSure, setAreYouSure] = useState(false);
  const [packageHistory, setPackageHistory] = useState();

  // Helper function to get today's date and 7 days from today
  const getDateRange = () => {
    const today = new Date();
    const minDate = today.toISOString().split('T')[0]; // Today
    const maxDate = new Date(today.setDate(today.getDate() + 7))
      .toISOString()
      .split('T')[0]; // 7 days from today
    return { minDate, maxDate };
  };

  //package stop date

  const { minDate, maxDate } = getDateRange();


  const [packageLeadHistory, setPackageLeadHistory] = useState([]);
  const [compititorDetails, setCompititorDetails] = useState();
  const [toLocation, setToLocationArray] = useState([]);

  const [packageCategory, setPackageCategory] = useState();

  const [prePackageId, setPrePackageId] = useState();

  const [prePackages, setPrePackages] = useState();
  const [selectedProposalId, setProposalId] = useState('');
  const [selectedSalePackageId, setSalePackageId] = useState('');
  const [feedbackDetails, setFeedbackDetails] = useState([]);




  const packageStatusModal = (packageId, packageStatus) => {
    setCurrentPackageStatus(packageStatus);
    setUpdatePackageId(packageId);
    setPackageModal(true);
  }

  const getPackageCategory = async () => {
    const response = await dashboardApi.get('get-package-category', {
      params: {
        product_id: 1
      }
    })

    if (response.data.status === 200) {
      setPackageCategory(response.data.data);
    }
    else {
      console.error('Error fetching countries:', response.data.message);
    }
    setLoading(false);
  }

  const sentPurposal = async (id) => {
    setProposalId(id);
    setLoading(true);
    try {
      const response = await dashboardApi.get('sent-purposal-to-client', {
        params: {
          id: id,
          client_id: clientId,
          emp_id: emp_id,
          user_type: 'client'
        }
      });
      if (response.data.status === 200) {
        setLoading(false);
        setProposalId('');
        Swal.fire({
          icon: 'success',
          text: response.data.message || 'An error occurred',
        });
      }
      else {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
      //console.log(packagedetails);
    }
    catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    const getPackageCategoryById = async () => {
      const response = await dashboardApi.get('get-pre-package-by-category', {
        params: {
          product_id: selectedProduct,
          category_id: prePackageId,
          group: selectedCity,
          service: selectedService,
        }
      })

      if (response.data.status === 200) {
        setPrePackages(response.data.data);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }
    getPackageCategoryById();
  }, [prePackageId, selectedProduct, selectedCity, selectedService])

  const packageStatus = async (pkgId) => {
    setActive('Package Status');
    try {
      const response = await dashboardApi.get(`get-package-enable-disable-history/${pkgId}`);
      if (response.data.status === 200) {
        setPackageHistory(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }



  const setCompititor = async (pkgId) => {
    setActive('Compititor');
    const response = await dashboardApi.get(`get-company-compititor-details/${pkgId}`);
    if (response.data.status === 200) {
      setCompititorDetails(response.data.data);
    }
  }

  const setToLocaton = async (pkgId) => {
    setActive('To Location');
    const response = await dashboardApi.get(`get-to-location-details/${pkgId}`);
    //console.log(response);
    if (response.data.status === 200) {
      setToLocationArray(response.data.data);
      setSelectedToLocationCity(response.data.cityDetails.map(str => +str));
      setSelectedToLocationGroup(response.data.groupDetails.map(str => +str));
    }
  }

  const leadsDetails = async (pkgId) => {
    setActive('Leads Details');
    try {
      const response = await dashboardApi.get(`get-package-leads-details/${pkgId}`);
      if (response.data.status === 200) {
        setPackageLeadHistory(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }



  const submitPackageActiveDeactive = async () => {
    const errors = {};
    setActive('Package Profile');
    const formData = new FormData();
    formData.append('package_start_date', packageStartDate);
    formData.append('package_stop_remarks', packageStopRemark);
    formData.append('package_id', updatePackageId);
    formData.append('package_status', currentPackageStatus);
    formData.append('client_id', clientId);
    formData.append('created_by', emp_id);


    if (!packageStartDate) {
      errors.packageStartDate = 'Package start date is required';
    }

    if (!packageStopRemark) {
      errors.packageStopRemark = 'Remarks is required.';
    }

    if (!areYouSure) {
      errors.areYouSure = 'Cheked checkbox to sure process ahed.';
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      const response = await dashboardApi.post('package-active-deactive', formData);
      if (response.data.status === 200) {
        setPackageModal(false);
        packageStatus(updatePackageId);
        setActive('Package Status');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
      }
      else {
        setPackageModal(false);
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });
      }
      setPackageStopRemarks('');
      setPackageStartDate('');
      setAreYouSure('');
    } catch (error) {
      setPackageModal(false);
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  const closePackageStatusModal = () => {
    setPackageModal(false);
  }

  const uplodeDocument = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!selectedDocumentType) {
      errors.selectedDocumentType = 'Document type is required.';
    }

    if (!selectedDocumentNo) {
      errors.selectedDocumentNo = 'Document no is required.';
    }

    if (!documentFile) {
      errors.documentFile = 'Document file is required.';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < documentFile.length; i++) {
      formData.append('document_file[]', documentFile[i]);
    }

    formData.append('document_type', selectedDocumentType);
    formData.append('document_no', selectedDocumentNo);
    // formData.append('document_file',JSON.stringify(documentFile));
    formData.append('client_id', clientId);
    formData.append('comp_id', clientId);
    formData.append('created_by', emp_id);
    try {
      const response = await dashboardApi.post('uplode-company-document', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        setSelectedDocument('');
        setDocumentFile('');
        setSelectedDocumentType('');
        getClientUplodedDocument();
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  const getClientUplodedDocument = async () => {
    try {
      const response = await dashboardApi.get(`get-company-uploded-document/${clientId}`);
      if (response.data.status === 200) {
        setUplodedDocuments(response.data.data);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  // const [deposit_amount , setDepositAmountByClient] = useState('');

  const [salePackageInfo, setSalePackageList] = useState({});
  const [walletDetails, setWalletDetails] = useState({});

  const [selectedRegAmount, setRegistrationAmount] = useState();
  const [selectedGstPercent, setGstPercent] = useState();
  const [groupList, setGroupList] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [salectAllLocalityId, setsalectAllLocalityId] = useState();

  const [deposit_amount, setDepositAmountByClient] = useState('');

  const [pkgTypeList, setPkgTypeList] = useState();
  //const [selectedPkgType,setSelectedPkgType]= useState();
  const [partialPayment, setPartialAmount] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  // const handleFilter = (event) => {
  //   const value = event.target.value;
  //   const filtered = prePackageDetails && prePackageDetails.filter(category => category.category_name.includes(value));
  //     setFilteredUsers(filtered);
  // };



  //const categoryNameFilter = prePackageDetails && prePackageDetails.map(q => q.category_name);
  //categoryNameFilter.filter((q, idx) => categoryNameFilter.indexOf(q) !== idx);
  const [depositAmount, setDepositAmount] = useState('');
  const [balanceAmount, setBalanceAmount] = useState('');
  const [dueLeads, setDueLeads] = useState('');
  const [taxAmount, setTaxAmount] = useState('');


  const [newKycDoc, setNewKycDoc] = useState([]);

  const [pkgProfile, setPackageProfile] = useState([]);


  const [selectedAreaLocality, setSelectedLocality] = useState([]);
  const [selectedAreaCity, setSelectedAreaCity] = useState([]);
  const [selectedAreaGroup, setSelectedAreaGroup] = useState([]);

  const [compititorNumber, setCompititorNumber] = useState('');
  const [compCompititorDetails, setCompCompititorDetails] = useState();

  const searchCompititorDetails = async () => {
    if (!compititorNumber) {
      Swal.fire({
        icon: 'error',
        text: 'Compititor mobile number is required.',
      });
      return;
    }
    else {

      const response = await dashboardApi.get(`get-compititor-details/${compititorNumber}`);
      if (response.data.status === 200) {
        setCompCompititorDetails(response.data.data);
        setCompititorNumber('');
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });
      }
    }
  }

  const setCompititorForThisClient = async (comp_id, packageId, compId, pkgId) => {
    const response = await dashboardApi.get(`set-compititor/${comp_id}/${packageId}/${compId}/${pkgId}`);
    if (response.data.status === 200) {
      setActive('Compititor');
      setCompCompititorDetails('');
      setCompititor(pkgId);
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
    }
    else {
      setCompititor(pkgId);
      Swal.fire({
        icon: 'error',
        text: response.data.message,
      });
    }
  }

  const removeCompititor = async (comp_id, pkgId, compId) => {
    const response = await dashboardApi.get(`remove-compititor/${comp_id}/${pkgId}/${compId}`);
    if (response.data.status === 200) {
      setCompititor(pkgId);
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
    }
    else {
      Swal.fire({
        icon: 'error',
        text: response.data.message,
      });
    }
  }

  const getGroupList = async () => {
    try {
      const response = await dashboardApi.get(`get-group-list`);
      if (response.data.status === 200) {
        setGroupList(response.data.data);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  // const handleCheckboxLocality = (localityId) =>
  // {
  //   setSelectedLocality((locality) => {
  //     const updatedCheckboxesLocality = [...locality];
  //     const index = updatedCheckboxesLocality.indexOf(localityId);
  //     if (index === -1) {
  //       updatedCheckboxesLocality.push(localityId);
  //     } else {
  //       updatedCheckboxesLocality.splice(index, 1);
  //     }
  //     return updatedCheckboxesLocality;
  //   });
  // };

  const handleCheckboxLocality = (localityId) => {
    setSelectedLocality((locality) => {
      const updatedCheckboxesLocality = [...locality];
      const index = updatedCheckboxesLocality.indexOf(localityId);
      if (index === -1) {
        updatedCheckboxesLocality.push(localityId);
      } else {
        updatedCheckboxesLocality.splice(index, 1);
      }
      return updatedCheckboxesLocality;
    });
  };



  // const handleCheckboxCity = (cityId) =>
  //   {
  //     setSelectedAreaCity((city) => {
  //       const updatedCheckboxesCity = [...city];
  //       const index = updatedCheckboxesCity.indexOf(cityId);
  //       if (index === -1) {
  //         updatedCheckboxesCity.push(cityId);
  //       } else {
  //         updatedCheckboxesCity.splice(index, 1);
  //       }
  //       return updatedCheckboxesCity;
  //     });
  // };

  const handleCheckboxCity = (cityId) => {
    setSelectedAreaCity((city) => {
      const updatedCheckboxesCity = [...city];
      const index = updatedCheckboxesCity.indexOf(cityId);
      if (index === -1) {
        updatedCheckboxesCity.push(cityId);
      } else {
        updatedCheckboxesCity.splice(index, 1);
      }
      return updatedCheckboxesCity;
    });
  };


  // const handleCheckboxGroup = (groupId) =>
  // {
  //   setSelectedAreaGroup((group) => {
  //       const updatedCheckboxesGroup = [...group];
  //       const index = updatedCheckboxesGroup.indexOf(groupId);
  //       if (index === -1) {
  //         updatedCheckboxesGroup.push(groupId);
  //       } else {
  //         updatedCheckboxesGroup.splice(index, 1);
  //       }
  //       return updatedCheckboxesGroup;
  //     });
  // };

  const handleCheckboxGroup = (groupId) => {
    setSelectedAreaGroup((group) => {
      const updatedCheckboxesGroup = [...group];
      const index = updatedCheckboxesGroup.indexOf(groupId);
      if (index === -1) {
        updatedCheckboxesGroup.push(groupId);
      } else {
        updatedCheckboxesGroup.splice(index, 1);
      }
      return updatedCheckboxesGroup;
    });
  };

  const handleLocalityToggle = (cityInfo, isChecked) => {
    const cityIds = cityInfo.localityInfo.map((lInfo) => lInfo.city_id);
    setSelectedLocality((prevSelected) => {
      if (isChecked) {
        // Add all localities under this city if not already selected
        return [...new Set([...prevSelected, ...cityIds])];
      } else {
        // Remove all localities under this city
        return prevSelected.filter((id) => !cityIds.includes(id));
      }
    });
  };

  // const handleToLocationGroup = (groupId) =>
  //   {
  //     setSelectedToLocationGroup((group) => {
  //         const updatedCheckboxesGroup = [...group];
  //         const index = updatedCheckboxesGroup.indexOf(groupId);
  //         if (index === -1) {
  //           updatedCheckboxesGroup.push(groupId);
  //         } else {
  //           updatedCheckboxesGroup.splice(index, 1);
  //         }
  //         return updatedCheckboxesGroup;
  //       });
  //   };

  const handleToLocationGroup = (groupId) => {
    // Find the group information based on groupId
    const group = toLocation.find((g) => g.groupInfo.some((grp) => grp.group_id === groupId));

    // Check if group checkbox is currently selected or not
    if (toLocationGroup.includes(groupId)) {
      // Uncheck the group and all its associated cities
      setSelectedToLocationGroup(toLocationGroup.filter((id) => id !== groupId));
      const citiesToRemove = group.groupInfo
        .find((grp) => grp.group_id === groupId)
        .city_details.map((city) => city.city_id);
      setSelectedToLocationCity(toLocationCity.filter((cityId) => !citiesToRemove.includes(cityId)));
    } else {
      // Check the group and all its associated cities
      setSelectedToLocationGroup([...toLocationGroup, groupId]);
      const citiesToAdd = group.groupInfo
        .find((grp) => grp.group_id === groupId)
        .city_details.map((city) => city.city_id);
      setSelectedToLocationCity([...toLocationCity, ...citiesToAdd]);
    }
  };

  // const handleToLocationCity = (cityId) =>
  //   {
  //     setSelectedToLocationCity((city) => {
  //       const updatedCheckboxesCity = [...city];
  //       const index = updatedCheckboxesCity.indexOf(cityId);
  //       if (index === -1) {
  //         updatedCheckboxesCity.push(cityId);
  //       } else {
  //         updatedCheckboxesCity.splice(index, 1);
  //       }
  //       return updatedCheckboxesCity;
  //     });
  // };

  const handleToLocationCity = (cityId) => {
    setSelectedToLocationCity((city) => {
      const updatedCheckboxesCity = [...city];
      const index = updatedCheckboxesCity.indexOf(cityId);
      if (index === -1) {
        updatedCheckboxesCity.push(cityId);
      } else {
        updatedCheckboxesCity.splice(index, 1);
      }
      return updatedCheckboxesCity;
    });
  };


  const [toLocationGroup, setSelectedToLocationGroup] = useState([]);
  const [toLocationCity, setSelectedToLocationCity] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAllChange = (localityId) => {

    if (selectAll) {
      setsalectAllLocalityId([localityId]);
      setSelectedLocality([]);


    } else {
      setSelectedLocality([...selectedAreaLocality]);
    }
    setSelectAll(!selectAll);
  };

  const updatePackageArea = async (packageId) => {
    const formDataClient = new FormData();
    formDataClient.append('created_by', emp_id);
    formDataClient.append('client_id', clientId);
    formDataClient.append('package_id', packageId);
    formDataClient.append('locality_id', selectedAreaLocality);
    formDataClient.append('city_id', selectedAreaCity);
    formDataClient.append('group_id', selectedAreaGroup);

    try {
      const response = await dashboardApi.post('update-package-area', formDataClient);
      if (response.data === 1) {
        Swal.fire({
          icon: 'success',
          text: 'Area updated Successfully.',
        });

      }
      else {
        Swal.fire({
          icon: 'error',
          text: 'Somthing went wrong, Please Try again.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while updating the area',
      });
    }
  }

  const updateToLocationPackageArea = async (packageId) => {
    const formDataClient = new FormData();
    formDataClient.append('created_by', emp_id);
    formDataClient.append('client_id', clientId);
    formDataClient.append('package_id', packageId);
    formDataClient.append('to_city', toLocationCity);
    formDataClient.append('to_group', toLocationGroup);

    try {
      const response = await dashboardApi.post('update-package-to-location-area', formDataClient);
      if (response.data === 1) {
        Swal.fire({
          icon: 'success',
          text: 'To location area added successfully.',
        });

      }
      else {
        Swal.fire({
          icon: 'error',
          text: 'Somthing went wrong, Please Try again.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while updating the area',
      });
    }
  }





  const [selectedPackageCity, setSelectedPackageCity] = useState([]);
  const citiesValue = selectedPackageCity.map(e => e.value).join(',');



  const checkTaxAmount = async (totalAmount, totalLead, isPartial, regAmount) => {
    if(isPartial=='')
    {
      setPartialAmount(0);
    }
    
    const errors = {};
    let paidAmountWillBe = '';
    //alert(partialPayment);
    if(isPartial=='')
    {
      setPartialAmount(0);
    }
    const depositAmountNum = parseFloat(depositAmount);
    // Validate partial payment
    if (partialPayment === '') {
      errors.partialPayment = 'Partial payment check is required';
    }
    // Validate deposit amount
    if (isNaN(depositAmountNum) || depositAmountNum <= 0) {
      errors.depositAmount = 'Deposit amount must be a positive number';
    }

    if (partialPayment === 0) {
      setDueLeads(0);
    }
    else {
      let paidAmountPercent = (depositAmountNum / totalAmount) * 100; // Calculate the percentage
      // alert(paidAmountPercent);
      let totalDueLead; // Define the variable outside the conditions

      if (paidAmountPercent >= 50 && paidAmountPercent < 60) {
        totalDueLead = Math.round(totalLead * 33 / 100);
      } else if (paidAmountPercent >= 60 && paidAmountPercent < 70) {
        totalDueLead = Math.round(totalLead * 40 / 100);
      } else if (paidAmountPercent >= 70 && paidAmountPercent < 80) {
        totalDueLead = Math.round(totalLead * 45 / 100);
      } else if (paidAmountPercent >= 80 && paidAmountPercent < 90) {
        totalDueLead = Math.round(totalLead * 65 / 100);
      } else if (paidAmountPercent >= 90 && paidAmountPercent < 100) {
        totalDueLead = Math.round(totalLead * 80 / 100);
      } else if (paidAmountPercent == 100) {
        totalDueLead = 0;
      }

      setDueLeads(totalDueLead); // Set the state
    }

    // Calculate paid amount based on partial payment
    if(isPartial === 0)
      {
      paidAmountWillBe = totalAmount;
    } else {
      paidAmountWillBe = Math.round(totalAmount / 2);
    }

    // Check if deposit amount is valid
    if (depositAmountNum >= paidAmountWillBe) {
      const balanceAmount = Math.round(totalAmount - depositAmountNum);
      const taxAmount = Math.round(depositAmountNum - (depositAmountNum / 1.18));

      // Ensure balanceAmount and taxAmount are non-negative
      if (balanceAmount < 0) {
        errors.depositAmount = 'Deposit amount cannot exceed the total amount';
        setBalanceAmount('');
        setTaxAmount('');
      } else {
        setBalanceAmount(balanceAmount);
        setTaxAmount(taxAmount);
      }
    } else {
      errors.depositAmount = 'Deposit amount must be more than half and less than or equal to the package amount';
      setBalanceAmount('');
      setTaxAmount('');
    }
   

    setValidationErrors(errors);

    // Reset deposit amount if there are errors
    if (Object.keys(errors).length > 0) {
      setDepositAmount('');
      return; // Exit early if there are errors
    }
  };


 

  const getCityDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-city-details`);
      if (response.data.status === 200) {
        setCityList(response.data.data);
      }
      else {
        console.log('Data not found');
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

  const getProduct = async () => {
    try {
      const response = await dashboardApi.get(`sales-product-list/active`);
      if (response.data.status === 200) {
        setProduct(response.data.data);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      const fetchService = async () => {
        try {
          const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
          if (response.data.status === 200) {
            setService(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchService();
    }
  }, [selectedProduct]);

  const [packageType, setPackageType] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [regAmount, setRegAmount] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);

  useEffect(() => {
    if (selectedComp) {
      const getCompanyPackageDetails = async () => {
        try {
          const response = await dashboardApi.get(`check-company-package-info`, {
            params: {
              client_id: clientId,
              comp_id: selectedComp,
              pre_package_id: selectedSalePackageId,
            }
          });

          if (response.data.status === 200) {
            setPackageType(response.data.packageType);
            setCompanyType(response.data.companyType);
            setRegAmount(response.data.regAmount);
            setServiceCharge(response.data.serviceCharge);
          }
          else {
            console.error('Error fetching countries:', response.data.message);
          }
          //setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          //setLoading(false);
        }
      }
      getCompanyPackageDetails();
    }
  }, [selectedComp]);



  const fetchCompanyDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-client-company-details/${clientId}`);
      if (response.data.status === 200) {
        setCompDetails(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }



  useEffect(() => {
    if (selectedProduct && selectedCity && selectedService) {
      const fetchPrePackage = async () => {
        try {
          const response = await dashboardApi.get(`get-pre-package-list/${selectedProduct}/${selectedCity}/${selectedService}`);
          if (response.data.status === 200) {
            setPackageList(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchPrePackage();
    }
  }, [selectedCity, selectedCity, selectedService]);

  useEffect(() => {
    if (selectedPackage && selectedFollowStatus == 19) {
      const fetchPrePackageDetails = async () => {
        try {
          const response = await dashboardApi.get(`get-sale-package-details/${selectedPackage}`);
          if (response.data.status === 200) {
            setSalePackageList(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchPrePackageDetails();
    }
  }, [selectedPackage, selectedFollowStatus]);

  useEffect(() => {
    if (selectedPackage && selectedFollowStatus == 19) {
      const fetchTaxRegDetails = async () => {
        try {
          const response = await dashboardApi.get(`get-tax-and-reg-details/${selectedProduct}`);
          if (response.data.status === 200) {
            setRegistrationAmount(response.data.data.reg_amount);
            setGstPercent(response.data.data.gst_percent);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchTaxRegDetails();
    }
  }, [selectedPackage, selectedFollowStatus]);




  useEffect(() => {
    if (selectedService) {
      const fetchCategory = async () => {
        try {
          const response = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
          if (response.data.status === 200) {
            setCategory(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchCategory();
    }
  }, [selectedService]);

  useEffect(() => {
    if (selectedFollowCallStatus) {
      const getFollowupStatus = async () => {
        try {
          const response = await dashboardApi.get(`get-followup-list/${selectedFollowCallStatus}`);
          if (response.data.status === 200) {
            setFollowup(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getFollowupStatus();
    }
  }, [selectedFollowCallStatus]);


  useEffect(() => {
    if (selectedFollowStatus) {
      const getNextFollowupDate = async () => {
        try {
          const response = await dashboardApi.get(`get-next-followup-date/${selectedFollowStatus}`);
          if (response.data.status === 200) {
            setNextDays(response.data.data);
          }
          else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        }
        catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getNextFollowupDate();
    }
  }, [selectedFollowStatus]);

  const packageFormFieldEmpty = () => {
    setDepositAmount('');
    setCheckedOffers('');
    setApplyDiscountPercent('');
    setApplyDiscountPercent('');
    setPartialAmount('');
    setPaybleAmountAfterDicount('');
    setSelectedComp('');
    setDiscountPercent('');
    setDiscountType('');
    setPayblePackageAmount('');
    setDueLeads('');
    setTaxAmount('');
    setRegAmount('');
    setCompanyType('');
   
  }

  const submitPackageForm = async (e) => {
    const errors = {};
    if (partialPayment === '') {
      errors.partialPayment = 'Is partial payment checked is required.';
    }

    if (!selectedComp) {
      errors.compDetails = 'Company is required.';
    }

    if (!depositAmount) {
      errors.depositAmount = 'Deposit amount is required.';
    }

    // alert(depositAmount);
    // alert(payblePackageAmount);

    if (depositAmount > payblePackageAmount) {
      errors.depositAmount = 'You cant pay more than package price.';
    }

    if (citiesValue.length < 1) {
      errors.citiesValue = 'Minimum 1 cites is required.';
    }

    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    setPackageLoading(true);
    const response = await dashboardApi.get('buy-new-package', {
      params: {
        client_id: clientId,
        package_id: e,
        payblePackageAmount: payblePackageAmount,
        depositAmount: depositAmount,
        balanceAmount: balanceAmount,
        dueLead: dueLeads,
        taxAmount: taxAmount,
        comp_id: selectedComp,
        created_by: emp_id,
        created_from: 'Sales',
        payment_for: packageType,
        companyType: companyType,
        cities_id: citiesValue,
        checkedOffers: checkedOffers,
        selectedOfferTypes: selectedOfferTypes,
        selectedPercent: selectedPercent,
        discountPercent: discountPercent,
        discountType: discountType,
        reg_amount: regAmount,
        afterDiscountLead: afterDiscountLead,
        isPartialPayment: partialPayment // 1= partial and 2 = not partail
      }
    });

    if (response.data.status === 200) {
      setPackageLoading(false);
      setpackageby(false);
      packageFormFieldEmpty();
      Swal.fire({
        icon: 'success',
        text: response.data.messages,
      });
    }
    else {
      setPackageLoading(false);
      Swal.fire({
        icon: 'error',
        text: response.data.messages,
      });
    }
  }

  const submitDuePackageForm = async (e) => {
    setDuePaymentLoading(true);
    const response = await dashboardApi.get('package-due-payment', {
      params: {
        client_id: clientId,
        package_id: e,
        depositAmount: depositAmount,
        balanceAmount: balanceAmount,
        dueLead: dueLeads,
        taxAmount: taxAmount,
        created_by: emp_id,
        payment_for: 'Due Amount',
      }
    });
    if (response.data.status === 200) {
      setDuePackagePay(false);
      setDuePaymentLoading(false);

      Swal.fire({
        icon: 'success',
        text: response.data.messages,
      });

    }
    else {
      setDuePaymentLoading(false);
      Swal.fire({
        icon: 'error',
        text: response.data.messages,
      });
    }
  }

  //   useEffect(()=>{
  //     if(selectedFollowCallStatus)
  //     {


  //     const getFollowupStatus = async () => {
  //         try {
  //         const response = await dashboardApi.get(`get-followup-list/${selectedFollowCallStatus}`);
  //         if (response.data.status === 200) {
  //           setFollowup(response.data.data);
  //          } else {
  //             console.error('Error fetching countries:', response.data.message);
  //         }
  //         setLoading(false);
  //         } catch (error) {
  //         console.error('Error fetching countries:', error);
  //         setLoading(false);
  //         }
  //     }
  //     getFollowupStatus();
  //     }
  // },[selectedFollowCallStatus]);


  // const getPrePackageDetails = async() =>{
  //   const response = await dashboardApi.get(`get-lmart-pre-packages-list`);
  //   if (response.data.status === 200)
  //   {
  //     setLmartPrePackageDetails(response.data.data);
  //   } 
  // }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (Object.keys(errors).length > 0) {
      return;
    }
    const formDataClient = new FormData();
    formDataClient.append('orgTypeId', orgTypeId);
    formDataClient.append('clientProduct', selectedProduct);
    formDataClient.append('clientService', selectedService);
    formDataClient.append('businessName', businessName);
    formDataClient.append('clientEmail', clientEmail);
    formDataClient.append('clientMobile', clientMobile);
    formDataClient.append('clientCity', clientCity);
    formDataClient.append('clientState', clientState);
    formDataClient.append('clientPinNo', clientPinNo);
    formDataClient.append('clientAddress', clientAddress);
    formDataClient.append('clientPanCardNo', clientPanCardNo);
    formDataClient.append('created_by', emp_id);
    formDataClient.append('client_id', clientId);
    formDataClient.append('group_id', selectedGroup);

    //console.log(formDataClient);
    try {
      const response = await dashboardApi.post('add-new-company-details', formDataClient);
      console.log(response);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        setActive('Company Details');
        //checkClientCompanyDetails();
      }
      else if (response.data.status === 400) {
        //setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  const Tab = styled.button`
  font-size: 16px;
  padding: 10px 10px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${({ active }) =>
      active &&
      `
    border-bottom: 2px solid blue;
    opacity: 1;
  `}
`;
  const ButtonGroup = styled.div`
  display: flex;
`;
  const types = ["Followup", "Feedback", "Company Details", "Documents", "Services", "Packages", "Wallet History"];
  const [active, setActive] = useState(types[0]);

  styled.td`
  padding: .1rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  margin-bottom: .1rem;
  `;
  useEffect(() => {
    checkFollowupDetails();
    getOrganizationType();
    //getPrePackageDetails();
    getCityDetails();
    getProduct();
    fetchCompanyDetails();
    getGroupList();
    getPackageCategory();

  }, [])

  const checkFollowupDetails = async () => {
    try {
      const response = await dashboardApi.get(`check-client-followup-details/${clientId}`);
      if (response.data.status === 200) {
        setClientFollowup(response.data.data);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }



  useEffect(() => {
    if (orgTypeId && selectedProduct && selectedService) {

      const getDocumentType = async () => {
        try {
          const response = await dashboardApi.get(`get-document-type/${orgTypeId}/${selectedProduct}/${selectedService}`);
          if (response.data.status === 200) {
            setDocumentType(response.data.data);
          }
        }
        catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getDocumentType();
    }

  }, [orgTypeId, selectedProduct, selectedService]);

  const getOrganizationType = async () => {
    try {
      const response = await dashboardApi.get(`get-organization-type`);
      if (response.data.status === 200) {
        setOrgType(response.data.data);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

  const renew_current_package = async (id) => {
    try {
      const response = await dashboardApi.get(`renew-current-package/${id}`);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }

  }



  useEffect(() => {

    if (active === 'Client Details') {
      const checkMatureClientDetails = async () => {
        try {
          const response = await dashboardApi.get(`check-mature-client-details/${emp_id}`);
          if (response.data.status === 200) {
            setMatureClientDetails(response.data.data);

          }
        }
        catch (error) {
          console.error('Error fetching countries:', error);
        }

      }
      checkMatureClientDetails();
    }

  }, [active === 'Client Details', emp_id]);


  useEffect(() => {

    if (active === 'Company Details') {
      const checkClientCompanyDetails = async () => {
        try {
          const response = await dashboardApi.get(`get-client-company-details/${clientId}`);
          console.log(response.data.data);
          if (response.data.status === 200) {
            setClientCompanyDetails(response.data.data);

          }
        }
        catch (error) {
          console.error('Error fetching countries:', error);
        }

      }
      checkClientCompanyDetails();
    }

  }, [active === 'Company Details', clientId]);

  useEffect(() => {
    if (active === 'Packages') {
      const getSoldPackages = async () => {
        try {
          const response = await dashboardApi.get(`get-client-package-details/${clientId}`);
          if (response.data.status === 200) {
            setSoldPackages(response.data.data);

          }
        }
        catch (error) {
          console.error('Error fetching countries:', error);
        }

      }
      getSoldPackages();
    }

  }, [active === 'Packages']);

  useEffect(() => {
    if (active === 'Documents') {
      const getNewKycDetails = async () => {
        try {
          const response = await dashboardApi.get(`get-client-kyc-details/${clientId}`);
          if (response.data.status === 200) {
            setNewKycDoc(response.data.data);
          }
          else {
            console.error('Error fetching countries:', response.data.message);
          }
        }
        catch (error) {
          console.error('Error fetching countries:', error);
        }
      }
      getNewKycDetails();
    }
  }, [active === 'Documents', currentPage]);


  useEffect(() => {
    if (active === 'Wallet History') {
      const getWalletHistory = async () => {
        //const response = await dashboardApi.get(`get-wallet-history/${clientId}`,);
        const response = await dashboardApi.get(`get-wallet-history/${clientId}`, {
          params: {
            per_page: postPerPage,
            page: currentPage + 1,
          }
        });
        if (response.data.status === 200) {
          const responseData = response.data.data;
          const walet_data = responseData.data || responseData;
          setNumber(responseData.last_page);
          setWalletHistory(walet_data);
        }
      }
      getWalletHistory();
    }
  }, [active === 'Wallet History', currentPage]);

  const packageProfile = async (id) => {

    setActive('Package Profile');
    const response = await dashboardApi.get(`get-client-package-details-by-id/${id}`);
    if (response.data.status === 200) {

      setPackageProfile(response.data.data);
      setSelectedLocality(response.data.localityDetails.map(str => +str));
      setSelectedAreaCity(response.data.cityDetails.map(str => +str));
      setSelectedAreaGroup(response.data.groupDetails.map(str => +str));
    }
  }

  useEffect(() => {
    if (active === 'Feedback') {
      const getFeedbackDetails = async () => {
        const response = await dashboardApi.get(`get-client-feedback-details`, {
          params: {
            clientId: clientId,
            per_page: postPerPage,
            page: currentPage + 1,
          }
        });
        if (response.data.status === 200) {
          const responseData = response.data.data;
          const feedback_data = responseData.data || responseData;
          setNumber(responseData.last_page);
          setFeedbackDetails(feedback_data);
        }
      }
      getFeedbackDetails();
    }
  }, [active === 'Feedback', currentPage]);


  const packageArea = () => {
    setActive('Package Area');
  }

  const addNewCompany = () => {
    setActive('Add New Company');
  }


  const viewOfferDetails = async (id, category_name, service_name, duration_name, price, name, total_lead, is_partial_payment, package_name, city_id, group_id) => {
    setSalePackageId(id);
    setViewOfferDetails(1);
    setLoading(true);
    try {
      const response = await dashboardApi.get('check-offer-details', {
        params: {
          id: id,
          category_name: category_name,
          service_name: service_name,
          duration_name: duration_name,
          price: price,
          name: name,
          total_lead: total_lead,
          client_id: clientId,
          isPartialPayment: is_partial_payment,
          package_name: package_name,
          city_id: city_id,
          group_id: group_id
        }
      });

      if (response.data.status === 200) {

        setpackagedetails(response.data.data);
        setPackageCityList(response.data.data.cityDetails);
        setSelectedPackageCity(response.data.data.city_id);
        setpackageby(true);
        setLoading(false);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }

    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const salePackageNew = async (id, category_name, service_name, duration_name, price, name, total_lead, is_partial_payment, package_name, city_id, group_id) => {
    setSalePackageId(id);
    setViewOfferDetails(0);
    setLoading(true);
    try {
      const response = await dashboardApi.get('check-client-wallet-balance', {
        params: {
          id: id,
          category_name: category_name,
          service_name: service_name,
          duration_name: duration_name,
          price: price,
          name: name,
          total_lead: total_lead,
          client_id: clientId,
          isPartialPayment: is_partial_payment,
          package_name: package_name,
          city_id: city_id,
          group_id: group_id
        }
      });

      if (response.data.status === 200) {

        setpackagedetails(response.data.data);
        setPackageCityList(response.data.data.cityDetails);
        setSelectedPackageCity(response.data.data.city_id);
        setpackageby(true);
        setLoading(false);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }

    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const duePaid = async (id, package_name, package_price, total_lead, package_id, due_status, due_amount, due_lead, paid_amount) => {
    let params = {
      id: id,
      package_name: package_name,
      price: package_price,
      total_lead: total_lead,
      client_id: clientId,
      package_id: package_id,
      due_status: due_status,
      due_amount: due_amount,
      due_lead: due_lead,
      paid_amount: paid_amount,
      isPartialPayment: 2 //not partial payment
    };
    try {
      const response = await dashboardApi.get('check-client-wallet-balance', {
        params: params
      });
      if (response.data.status === 200) {
        setDuePackagedetails(params);
        setDepositAmount(due_amount);
        setTaxAmount(Math.round(due_amount - (due_amount / 1.18)));
        setDueLeads(0);
        setBalanceAmount(0);

        setDuePackagePay(true);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
      //console.log(packagedetails);
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  }



  const closePackageModel = () => {
    packageFormFieldEmpty();
    setpackageby(false);
  }

  const closeDuePackageModel = () => {
    setDuePackagePay(false);
  }

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    //setWalletHistory(selected.selected);
  };

  const submitFollowupForm = async () => {
    const errors = {};
    
      // Validate remarks
      if (!followupRemarks) {
        errors.followupRemarks = 'Remarks are required';
      }
    
      // Validate package if the follow-up status matches certain values
      if ([5, 6, 7, 9].includes(parseInt(selectedFollowStatus))) {
        if (!selectedPackage) {
          errors.selectedPackage = 'Package is required';
        }
      }
    
      // Validate next follow-up date if it is not empty
      if (!nxtFollowupDate) {
        errors.nxtFollowupDate = 'Next Follow-up Date is required';
      }
    
      // Check if there are validation errors
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);  // Set errors in state to display them in the UI
        return;
      }
    
      setValidationErrors({});
    const formData = new FormData();
    formData.append('disposition', selectedFollowCallStatus);
    formData.append('followup_status', selectedFollowStatus);
    formData.append('next_followup_date', nxtFolDate);
    formData.append('remarks', followupRemarks);
    formData.append('refer_package_id', selectedPackage);
    formData.append('client_id', clientId);
    formData.append('created_by', emp_id);
    formData.append('client_type', 1);
    formData.append('product_id', selectedProduct);
    formData.append('service_id', selectedService);
    formData.append('category_id', selectedCategory);
    formData.append('city_id', selectedCity);

    try {
      const response = await dashboardApi.post('add-client-followup', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        closeFollowupControl();
        checkFollowupDetails();
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  const submitCreatePackageForm = async () => {
    const errors = {};
    if (Object.keys(errors).length > 0) {
      return;
    }
    const formData = new FormData();
    formData.append('disposition', selectedFollowCallStatus);
    formData.append('followup_status', selectedFollowStatus);
    formData.append('next_followup_date', nxtFolDate);
    formData.append('remarks', followupRemarks);
    formData.append('refer_package_id', selectedPackage);
    formData.append('client_id', clientId);
    formData.append('created_by', emp_id);
    formData.append('client_type', 1);
    formData.append('product_id', selectedProduct);
    formData.append('service_id', selectedService);
    formData.append('category_id', selectedCategory);
    formData.append('city_id', selectedCity);

    try {
      const response = await dashboardApi.post('create-client-package', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        closeFollowupControl();
        checkFollowupDetails();
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  const [step, setStep] = useState(1);
  const handleNext = async () => {
    const errors = {};

    if (!businessName) {
      errors.businessName = 'Business Name is required';
    }
    if (!clientEmail) {
      errors.clientEmail = 'Email is required';
    }
    if (!selectedGroup) {
      errors.selectedGroup = 'Group is required';
    }
    if (!clientMobile) {
      errors.clientMobile = 'Mobile Number is required';
    }

    if (!clientCity) {
      errors.clientCity = 'City is required';
    }
    if (!clientState) {
      errors.clientState = 'State is required';
    }
    if (!clientPinNo) {
      errors.clientPinNo = 'Zipcode is required';
    }
    if (!clientAddress) {
      errors.clientAddress = 'Address is required';
    }
    // alert(clientPanCardNo);
    if (!clientPanCardNo) {
      errors.clientPanCardNo = 'Pancard No is required';
    }



    if (clientPanCardNo != '') {
      const response = await dashboardApi.get(`check-company-pancard-number/${clientPanCardNo}`);
      if (response.data === 1) {
        setPanCardNo('');
        errors.clientPanCardNo = 'Pancard already added in your system. ( ' + clientPanCardNo + ' )';
      }
    }

    if (!orgTypeId) {
      errors.orgTypeId = 'Org Type is required';
    }

    if (!selectedProduct) {
      errors.selectedProduct = 'Product is required';
    }

    if (!selectedService) {
      errors.selectedService = 'Service is required';
    }

    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };



  const followupOpenControl = () => {
    setFollowupModal(true);
  }

  const closeFollowupControl = () => {
    setFollowupModal(false);
  }

  const feedbackOpenControl = () => {
    setFeedbackModal(true);
  }

  const closeFeedbackControl = () => {
    setFeedbackModal(false);
  }

  const closeModalOpenControl = () => {
    setAddModal(false);
  }

  const Tab1 = styled.button`
    font-size: 16px;
    padding: 10px 10px;
    margin-right:10px;
    cursor: pointer;
    opacity: 0.6;
    color:black;
    border: 0;
    outline: 0;
    ${({ active }) => active && `border-bottom: 4px solid #0e3a6a; opacity: 1;`}`;

  const ButtonGroup1 = styled.div`display: flex;`;
  const topMenuBar1 = ["Lmart Packages", "Zoopgo Packages", "Others Packages", "Website Packages"];
  const [activeMenu1, setActive1] = useState(topMenuBar1[0]);

  // State initialization
  const [checkedOffers, setCheckedOffers] = useState(null); // Holds the currently checked offer's ID
  const [selectedOfferTypes, setSelectedOfferTypes] = useState({}); // Holds offer types mapped by offer ID
  const [selectedPercent, setSelectedPercent] = useState({}); // Holds selected percent by offer ID
  const [discountPercent, setDiscountPercent] = useState('');
  const [discountType, setDiscountType] = useState('');

  const [applyDiscountPercent, setApplyDiscountPercent] = useState('');
  const [afterDiscountLead, setAfterDiscountLead] = useState('');
  const [paybleAmountAfterDiscount, setPaybleAmountAfterDicount] = useState('');
  const [payblePackageAmount, setPayblePackageAmount] = useState('');

  // Calculate the payable amount when dependencies change
  useEffect(() => {
    const calculatedAmount =
      discountPercent && selectedOfferTypes && discountType === 1
        ? Math.round(Number(regAmount) + Number(paybleAmountAfterDiscount))
        : Math.round(Number(regAmount) + Number(packagedetails.price));

    setPayblePackageAmount(calculatedAmount);
  }, [discountPercent, selectedOfferTypes, discountType, regAmount, paybleAmountAfterDiscount, packagedetails.price]);

  useEffect(() => {
    // Update the afterDiscountLead when packagedetails or discountPercent changes
    const calculatedLead = Math.round(
      Number(packagedetails.total_lead) +
      Number((packagedetails.total_lead * discountPercent) / 100)
    );
    setAfterDiscountLead(calculatedLead);
  }, [packagedetails, discountPercent]);



  // Handle the selection of offers (only one offer can be selected at a time)
  const handleOfferCheck = (offerId) => {
    setCheckedOffers(offerId);

    // Reset the details for the previous offer
    setSelectedOfferTypes(prevState => ({
      ...prevState,
      [offerId]: prevState[offerId] || ''
    }));
    setSelectedPercent(prevState => ({
      ...prevState,
      [offerId]: ''
    }));

    // Optionally, reset discountPercent and discountType if necessary
    setSelectedPercent('');
    setDiscountPercent('');
    setDiscountType('');
  };

  // Handle partial payment change
  const handlePartialPaymentChange = (value) => {
    setPartialAmount(value);

    if (value == '') {
      setPartialAmount(0);
      // If 'No', make Deposit Amount read-only and set to the payable amount after discount
      //setDepositAmount('');
    }
  };

  useEffect(() => {
    handlePartialPaymentChange(0); // Call with default value
  }, []);


  // Handle offer type selection, ensuring only one type is selectable
  const handleOfferTypeChange = (id, type) => {
    setSelectedOfferTypes((prev) => ({
      ...prev,
      [id]: type,
    }));
    setSelectedPercent((prev) => ({
      ...prev,
      [id]: '', // Reset percent selection when offer type changes
    }));
  };

  // Handle percent selection, ensuring only one percent can be selected at a time
  const handlePercentChange = (id, value) => {
    setSelectedPercent((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (
      checkedOffers &&
      selectedPercent[checkedOffers] &&
      selectedOfferTypes[checkedOffers]
    ) {
      const getOfferDetails = async () => {
        const response = await dashboardApi.get(`get-package-offer-details`, {
          params: {
            checkedOffers,
            selectedOfferTypes: selectedOfferTypes[checkedOffers],
            selectedPercent: selectedPercent[checkedOffers],
          },
        });

        if (response.data.status === 200) {
          setDiscountType(response.data.type);
          setDiscountPercent(response.data.percent);
        }
      };
      getOfferDetails();
    }
  }, [checkedOffers, selectedOfferTypes, selectedPercent]);

  useEffect(() => {
    const calculatePayableAmount = () => {
      // Calculate the payable amount after discount and tax
      const discountedPrice = packagedetails.package_price - (packagedetails.package_price * discountPercent) / 100;
      const payableAmount = Math.round(discountedPrice + discountedPrice * 0.18);
      setPaybleAmountAfterDicount(payableAmount);
    };

    // Call the calculation function
    calculatePayableAmount();
  }, [packagedetails.package_price, discountPercent]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <MatureClientProfileHeader></MatureClientProfileHeader>
                <div className="row" style={{ overflowY: "hidden" }}>
                  <ButtonGroup>
                    {types.map((type) => (
                      <Tab
                        key={type}
                        active={active === type}
                        onClick={() => setActive(type)}
                        style={{ width: 'max-content' }}
                      >
                        {type}
                      </Tab>
                    ))}
                  </ButtonGroup>
                </div>
              </div>

              {active === 'Followup' &&
                <div className='card-body' id="followupDiv" style={{ overflowY: "hidden" }}>
                  <table className="table table-bordered table-hovered">
                    <tr>
                      <td colspan="12">
                        <Link className='btn btn-info btn-sm' style={{ float: 'right' }} onClick={followupOpenControl}>
                          +
                        </Link>
                      </td>
                    </tr>
                    <tr style={{ fontWeight: '700' }}>
                      <td>S.No</td>
                      <td>Client Type</td>
                      <td>City Name</td>
                      <td>Refer Package Name</td>
                      <td>Package Price</td>
                      <td>Disposition</td>
                      <td>Followup Info</td>
                      <td>Followup Status</td>
                      <td>Remark</td>
                      <td>Followup Date</td>
                      <td>Created By</td>
                      <td>Created Date</td>
                    </tr>

                    {clientFollowup && clientFollowup.map((cd, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{cd.client_type === 1 ? 'Guest' : 'Mature'}</td>
                        <td>{cd.city_name}</td>
                        <td>{cd.package_name}</td>
                        <td>{cd.package_price}</td>
                        <td>{cd.disposition}</td>
                        <td>{cd.product_name}<br />{cd.service_name}<br />{cd.category_name}</td>
                        <td>{cd.activity_name}</td>
                        <td>{cd.remark}</td>
                        <td>{cd.next_followup_date}</td>
                        <td>{cd.created_by}</td>
                        <td>{cd.created_date}</td>
                      </tr>
                    ))
                    }
                  </table>
                </div>
              }

              {active === 'Feedback' &&
                <div className='card-body' id="followupDiv" style={{ overflowY: "hidden" }}>
                  <table className="table table-bordered table-hovered">
                    <tr>
                      {/* <td colspan="12">
                        <Link className='btn btn-info btn-sm' style={{ float: 'right' }} onClick={feedbackOpenControl}>
                          +
                        </Link>
                      </td> */}
                    </tr>
                    <tr style={{ fontWeight: '700' }}>
                      <td>S.No</td>
                      <td>Enq ID</td>
                      <td>Lead Id</td>
                      <td>Package Id</td>
                      <td>Reason</td>
                      <td>Comment</td>
                      <td>Created Date</td>
                    </tr>

                    {feedbackDetails && feedbackDetails.map((fd, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{fd.enq_id}</td>
                        <td>{fd.lead_id}</td>
                        <td>{fd.package_id}</td>
                        <td>{fd.reason_name}</td>
                        <td>{fd.comment}</td>
                        <td>{fd.created_at}</td>


                      </tr>
                    ))
                    }

                    <tr>
                      <td colspan="7">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={number}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link-style"}
                            breakLinkClassName={"break-link-style"}
                            previousLinkClassName={"prev-link-style"}
                            nextLinkClassName={"next-link-style"}
                          />
                        </Pagination>
                      </td>
                    </tr>
                  </table>
                </div>
              }


              {active === 'Documents' &&
                <div className='card-body' id="documentDiv" style={{ overflowY: "hidden" }}>
                  <table className="table table-bordered table-hovered">
                    <tr>
                      <td>S.No</td>
                      <td>Document No</td>
                      <td>Document</td>
                      <td>Status</td>
                    </tr>
                    {newKycDoc && newKycDoc.map((kyc, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{kyc.document_name} <br /> {kyc.doc_number}</td>
                        <td>
                          <div className="row">
                            {kyc.doc_url && kyc.doc_url.split(',').map((item) =>
                              <div className="col-sm-4">
                                <iframe style={{ margin: '20px', padding: '10px;' }} src={docurl() + item} title="document" width="250px" height="250px"></iframe>
                                <a href={docurl() + item} target='_blank' className="btn btn-primary">View</a>
                              </div>
                            )}
                          </div>

                        </td>
                        <td>
                          {kyc.status == 0 && <span style={{ color: 'red' }}>Pending</span>}
                          {kyc.status == 1 && <span style={{ color: 'green' }}>Approved</span>}
                          {kyc.status == 2 && <span style={{ color: 'red' }}>Rejected</span>}
                        </td>

                      </tr>
                    ))
                    }
                  </table>
                </div>
              }





              {active === 'Company Details' &&
                <>
                  <div className='card-body' id="companyDetails" style={{ overflowY: "hidden" }}>
                    <table className="table table-bordered table-hovered">
                      <tr>
                        <td colspan="12">
                          <Link className='btn btn-info btn-sm' style={{ float: 'right' }} onClick={addNewCompany}>
                            +
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>S.No</td>
                        <td>Business Name</td>
                        <td>Email</td>
                        <td>Mobile No</td>
                        <td>City</td>
                        <td>State</td>
                        <td>Zipcode</td>
                        <td>Address</td>
                        <td>Executive Id</td>
                        <td>Created date</td>
                      </tr>

                      {companyDetails && companyDetails.map((cd, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{cd.business_name}</td>
                          <td>{cd.email}</td>
                          <td>{cd.mobile_no}</td>
                          <td>{cd.city}</td>
                          <td>{cd.gst_state}</td>
                          <td>{cd.zipcode}</td>
                          <td>{cd.address}</td>
                          <td>{cd.emp_name}</td>
                          <td>{cd.created_at}</td>
                        </tr>
                      ))
                      }
                    </table>
                  </div>
                </>
              }

              {active === 'Client Details' &&
                <>
                  <div className='card-body' id="clientDetails" style={{ overflowY: "hidden" }}>
                    <table className="table table-bordered table-hovered">
                      <tr>
                        <td>S.No</td>
                        <td>Client Name</td>
                        <td>Business Name</td>
                        <td>Email</td>
                        <td>Mobile No</td>
                        <td>City</td>
                        <td>State</td>
                        <td>Pin No</td>
                        <td>Address</td>
                        <td>Organization Type</td>
                        <td>Executive Id</td>
                        <td>Created date</td>
                      </tr>

                      {matureClientDetails && matureClientDetails.map((mcd, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{mcd.client_name}</td>
                          <td>{mcd.business_name}</td>
                          <td>{mcd.email}</td>
                          <td>{mcd.mobile_no}</td>
                          <td>{mcd.city}</td>
                          <td>{mcd.state}</td>
                          <td>{mcd.pin_no}</td>
                          <td>{mcd.address}</td>
                          <td>{mcd.pan_card}</td>
                          <td>{mcd.exe_id}</td>
                          <td>{mcd.created_at}</td>

                        </tr>
                      ))
                      }
                    </table>
                  </div>
                </>
              }

              {active === 'Packages' &&
                <div className='card-body' id="soldPackageDiv" style={{ overflowY: "hidden" }}>
                  <table className="table table-bordered table-hovered">

                    <tr style={{ fontWeight: '700' }}>
                      <td>S.No</td>
                      <td>Package Name</td>
                      <td>Package Amount</td>
                      <td>Paid Amount</td>
                      <td>Balance Amount</td>
                      <td>Total Lead</td>
                      <td>Sent Lead</td>
                      <td>Status</td>
                      <td>Start Date</td>
                      <td>Action</td>
                    </tr>

                    {soldPackages && soldPackages.map((sd, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{sd.package_name}</td>
                        <td>{sd.package_amount}</td>
                        <td>{sd.paid_amount}</td>
                        <td>{sd.due_amount}</td>
                        <td>{sd.total_lead}</td>
                        <td>{sd.sent_lead}</td>
                        <td>
                          <p>

                            Admin : {sd.admin_status === 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Pending</span>} <br />
                            Finance : {sd.finance_status === 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Pending</span>} <br />
                            Package : {sd.package_status === 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Pending</span>} <br />
                          </p>
                        </td>

                        <td>{sd.package_start_date}</td>


                        <td>
                          {sd.package_status != 1 &&
                            <button className="btn btn-primary btn-sm" style={{ margin: '5px' }}
                              onClick={() => salePackageNew(sd.id, sd.category_name, sd.service_name_list, sd.duration_name, sd.package_price, sd.package_type_name, sd.total_lead, sd.is_partial_payment, sd.package_name, sd.city_id, sd.group_id)}>Renew</button>
                          }

                          {sd.invoice_url && sd.invoice_url.split(',').map((item, index) => <a target="_blank" className="btn btn-sm btn-info" style={{ margin: '5px' }} href={item}>Invoice {index + 1}</a>)}

                          {sd.due_status == 1 &&
                            <span className="btn btn-sm btn-info" style={{ margin: '5px' }} onClick={() => duePaid(sd.id, sd.package_name, sd.due_amount, sd.total_lead, sd.package_id, sd.due_status, sd.due_amount, sd.due_lead, sd.paid_amount)}>Due Pay</span>
                          }

                          <span className="btn btn-sm btn-primary" style={{ margin: '5px' }} onClick={() => packageProfile(sd.package_id)}>View</span>

                        </td>
                      </tr>
                    ))
                    }
                  </table>
                </div>
              }

              {active === 'Package Profile' | active === 'Package Area' | active === 'Package Status' | active === 'Leads Details' | active === 'Compititor' | active === 'To Location' &&
                <>
                  {pkgProfile && pkgProfile.map((pkgProfile, index) => (
                    <div className='card-body' id="packageProfileDiv" style={{ overflowY: "hidden" }}>
                      <div className='row'>
                        <table className="table table-bordered table-hovered col-12">
                          <tr>
                            <td>
                              <b>Package Info</b> {pkgProfile.package_status == 1 ? <span style={{ color: "green", fontWeight: 'bold', float: 'right' }}>Active</span> : <span style={{ color: "Red", fontWeight: 'bold', float: 'right' }}>Deactive</span>}
                            </td>
                            <td>
                              <b>Payment/Invoice Info</b>
                            </td>
                            <td>
                              Action
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <tr>Product: {pkgProfile.product_name}</tr>
                              <tr>Service : {pkgProfile.service_name}</tr>
                              <tr>Category : {pkgProfile.category_name}</tr>
                              <tr>Package Name: {pkgProfile.package_name}</tr>
                              <tr>Commited Lead: {pkgProfile.total_lead}</tr>
                              <tr>Sent Lead : {pkgProfile.sent_lead}</tr>
                              <tr>Balance Lead : {pkgProfile.due_amount}</tr>
                            </td>

                            <td>
                              {pkgProfile.invoiceInfo.map((invInfo, indx) => (
                                <tr>
                                  INR : {invInfo.paid_amount}
                                  <a target="_blank" href={invInfo.invoice_name}> &nbsp;&nbsp; {invInfo.invoice_number}</a>
                                </tr>
                              ))}

                            </td>
                            <td>
                              <button className="btn btn-primary btn-sm" style={{ margin: '10px' }} onClick={() => { packageArea() }}>Area Info</button>

                              <button className="btn btn-primary btn-sm" style={{ margin: '10px' }} onClick={() => leadsDetails(pkgProfile.package_id)}>Lead Details</button>

                              <button className="btn btn-primary btn-sm" style={{ margin: '10px' }} onClick={() => { packageStatus(pkgProfile.package_id) }}>Package Status</button>

                              <button className="btn btn-primary btn-sm" style={{ margin: '10px' }} onClick={() => { setCompititor(pkgProfile.package_id) }} >Set Compititor</button>

                              <button className="btn btn-primary btn-sm" style={{ margin: '10px' }} onClick={() => { setToLocaton(pkgProfile.package_id) }} >To Location</button>


                            </td>
                          </tr>

                          {active === 'Package Area' &&
                            <tr>
                              <td colspan="3">

                                <h5>Area Details :</h5>
                                {pkgProfile.groupInfo.map((gInfo) => (
                                  <div key={gInfo.group_id} style={{ border: '1px solid silver', margin: '10px', padding: '20px' }}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        id={`groupCheckbox${gInfo.group_id}`}
                                        checked={selectedAreaGroup.includes(gInfo.group_id)}
                                        onChange={() => handleCheckboxGroup(gInfo.group_id)}
                                      />
                                      <span className='px-1'>
                                        {gInfo.group_name}
                                      </span>
                                    </label>
                                    {gInfo.city_info.map((cInfo) => (
                                      <div className='d-flex flex-wrap' key={cInfo.locality_id} style={{ margin: '10px 0', border: '1px solid silver', padding: '10px' }}>
                                        <div className='col-12 mb-2 p-0'>
                                          <label>
                                            <b className='px-1'>Locality of: {cInfo.locality_name}</b>
                                            <input
                                              type="checkbox"
                                              id={`localityCheckbox${cInfo.locality_id}`}
                                              checked={selectedAreaCity.includes(cInfo.locality_id)}
                                              onChange={(e) => {
                                                handleCheckboxCity(cInfo.locality_id);
                                                handleLocalityToggle(cInfo, e.target.checked);
                                              }}
                                            />
                                          </label>
                                        </div>
                                        {cInfo.localityInfo.map((lInfo) => (
                                          <div key={lInfo.city_id} style={{ padding: '10px' }}>
                                            <label>
                                              <input
                                                type="checkbox"
                                                id={`localityCheckbox${lInfo.city_id}`}
                                                checked={selectedAreaLocality.includes(lInfo.city_id)}
                                                onChange={() => handleCheckboxLocality(lInfo.city_id)}
                                              />
                                              <span className='px-1'>{lInfo.city_name}</span>
                                            </label>

                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                                <button
                                  className="btn btn-sm btn-primary"
                                  style={{ float: 'right' }}
                                  onClick={() => updatePackageArea(pkgProfile.package_id)}
                                >
                                  Update Area
                                </button>
                              </td>
                            </tr>
                          }


                          {active === 'To Location' &&
                            <>
                              {pkgProfile.to_location == 1 ?
                                <tr>
                                  <td colspan="3">
                                    <h5 style={{ margin: '10px', }}>To Location City :</h5>

                                    <div className="card-body p-0 py-2">
                                      {toLocation &&
                                        toLocation.map((gInfo) => (
                                          <>
                                            {gInfo.groupInfo.map((grpInfo) => (
                                              <div key={grpInfo.group_id} style={{ border: '1px solid silver', margin: '10px', paddingRight: '20px' }}>
                                                <div className='col-12' style={{ margin: '20px 10px' }}>
                                                  <label className='p-1' >
                                                    <input
                                                      type="checkbox"
                                                      checked={toLocationGroup.includes(grpInfo.group_id)}
                                                      onChange={() => handleToLocationGroup(grpInfo.group_id)}
                                                    />
                                                    <span className='px-1' > {grpInfo.group_name}</span>

                                                  </label>

                                                </div>

                                                <div className="row" style={{ margin: '10px' }}>
                                                  {grpInfo.city_details.map((cInfo) => (
                                                    <div className="col-sm-3" key={cInfo.city_id}>
                                                      <label className='p-1' >
                                                        <input
                                                          type="checkbox"
                                                          checked={toLocationCity.includes(cInfo.city_id)}
                                                          onChange={() => handleToLocationCity(cInfo.city_id)}
                                                        />
                                                        <span className='px-1' >    {cInfo.city_name}</span>

                                                      </label>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            ))}
                                          </>
                                        ))}
                                      <button
                                        className="btn btn-sm btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={() => {
                                          updateToLocationPackageArea(pkgProfile.package_id);
                                        }}
                                      >
                                        Add To Location Area
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                : <tr><td colspan="3" style={{ color: 'red' }}><h4> This package does not have to location benefits..</h4></td></tr>
                              }
                            </>
                          }

                          {active === 'Package Status' &&
                            <tr>
                              <td colspan='3'>
                                <h5>Package History :</h5>
                                <tr>
                                  <div className="col-sm-12">
                                    <button className="btn btn-primary btn-sm" style={{ margin: '10px', float: 'right' }} onClick={() => { packageStatusModal(pkgProfile.package_id, pkgProfile.package_status) }}>Package Status</button>
                                  </div>

                                </tr>

                                <tr>
                                  <td>Status</td>
                                  <td>Action By</td>
                                  <td>Package Stop Date</td>
                                  <td>Package Start Date</td>
                                  <td>Package Remarks </td>
                                  <td>Created By</td>
                                </tr>
                                {packageHistory && packageHistory.map((history) => (
                                  <tr>
                                    <td>{history.package_status == 1 ? 'Stop' : 'Start'}</td>
                                    <td>{history.status_type == 1 ? 'Sales' : history.status_type == 2 ? 'Client' : history.status_type == 3 ? 'Admin' : history.status_type == 4 ? 'System' : 'Others'}</td>
                                    <td>{history.created_date}</td>
                                    <td>{history.package_start_date}</td>

                                    <td>{history.remarks} </td>
                                    <td>{history.created_by}</td>
                                  </tr>
                                ))}

                              </td>
                            </tr>
                          }

                          {active === 'Compititor' &&

                            <tr style={{ width: '100%' }}>
                              <td colspan='3'>
                                <h5>Compititor Details :</h5>
                                <tr>
                                  <td colspan="5">
                                    <div style={{ display: 'flex' }}>
                                      <input type="text" className='form-control' style={{ width: '250px' }} value={compititorNumber} onChange={(e) => { setCompititorNumber(e.target.value) }} placeholder='Enter company mobile number.' />
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <button className="btn btn-primary" onClick={() => { searchCompititorDetails() }}>Search</button>
                                    </div>
                                  </td>
                                </tr>
                                {compCompititorDetails ? (
                                  <tr>
                                    <td>{compCompititorDetails.business_name}</td>
                                    <td>{compCompititorDetails.mobile_no}</td>
                                    <td>{compCompititorDetails.email}</td>
                                    <td>
                                      <button onClick={() => { setCompititorForThisClient(compCompititorDetails.comp_id, compCompititorDetails.package_id, pkgProfile.comp_id, pkgProfile.package_id) }} className="btn btn-info btn-sm">Set Compititor</button>
                                    </td>
                                  </tr>
                                ) : (
                                  ''
                                )}

                                {!compititorDetails ? (
                                  <tr>
                                    <td colspan="4" style={{ color: 'red' }}>This package currently does not have any competitors.</td>
                                  </tr>
                                ) : ('')}

                                {compititorDetails && compititorDetails.map((compititorInfo) => (
                                  <tr>
                                    <td>
                                      {compititorInfo.business_name}
                                    </td>

                                    <td>
                                      {compititorInfo.mobile_no}
                                    </td>
                                    <td>
                                      {compititorInfo.email}
                                    </td>

                                    <td>
                                      <button className="btn btn-primary btn-sm" onClick={() => { removeCompititor(compititorInfo.comp_id, pkgProfile.package_id, pkgProfile.comp_id) }}>Remove Compititor</button>
                                    </td>
                                  </tr>

                                ))}
                              </td>
                            </tr>
                          }

                          {active === 'Leads Details' &&
                            <tr>
                              <td colspan='3'>
                                <h5>Leads History :</h5>
                                <tr>
                                  <td>id</td>
                                  <td>Customer Name</td>
                                  {/* <td>Mobile No/Email</td> */}
                                  <td>City From/City To</td>
                                  <td>Sent Date </td>
                                  <td>Status</td>
                                </tr>
                                {packageLeadHistory && packageLeadHistory.map((history, index) => (
                                  <tr>
                                    <td>{index++}</td>
                                    <td>{history.name}</td>
                                    {/* <td>{history.primary_no}<br />{history.email}</td> */}
                                    <td>{history.city_from} - {history.city_to}</td>
                                    <td>{history.sent_date}</td>
                                    <td>{history.status == 1 ? <span style={{ color: 'green' }}>Sent</span> : history.status == 2 ? <span style={{ color: 'red' }}>'Return'</span> : history.status == 3 ? 'Approved' : 'Not Approved'}</td>
                                  </tr>
                                ))}

                              </td>
                            </tr>
                          }

                        </table>

                      </div>
                    </div>))
                  }
                </>
              }

              {active === 'Wallet History' &&
                <div className='card-body' id="walletHistoryDiv" style={{ overflowY: "hidden" }}>
                  <table className="table table-bordered table-hovered">

                    <tr style={{ fontWeight: '700' }}>
                      <td>S.No</td>
                      <td>Client Name</td>
                      <td>Bank Name</td>
                      <td>Payment Mode</td>
                      <td>Txn ID</td>
                      <td>Credit</td>
                      <td>Debit</td>
                      <td>Balance</td>
                      <td>Status</td>
                      <td>Transation Date</td>
                    </tr>

                    {walletHistory && walletHistory.map((wh, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{wh.client_name}</td>
                        <td>{wh.bank_name}</td>
                        <td>{wh.transaction_mode}</td>
                        <td>{wh.transaction_id}</td>
                        <td>{wh.credit}</td>
                        <td>{wh.debit}</td>
                        <td>{wh.balance}</td>
                        <td>{wh.status === 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Pending</span>}</td>
                        <td>{wh.transaction_date}</td>
                      </tr>
                    ))
                    }
                    <tr>
                      <td colspan="10">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={number}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link-style"}
                            breakLinkClassName={"break-link-style"}
                            previousLinkClassName={"prev-link-style"}
                            nextLinkClassName={"next-link-style"}
                          />
                        </Pagination>
                      </td>
                    </tr>
                  </table>


                </div>

              }

              {active === 'Add New Company' &&
                <div className='card-body' id="addNewCompany">
                  <table className="table table-bordered table-hovered">
                    <tr>
                      <td colspan="12">
                        <form onSubmit={handleFormSubmit}>
                          <ProgressBar now={(step / 2) * 100} />
                          {step === 1 && (
                            <div className='card-body' id="clientDiv">

                              <div className="row">
                                <div className="col-sm-12"><h4>Company Basic Info</h4></div>

                                <div className="col-sm-4">
                                  <label>Business Name</label>
                                  <input type="text" className="form-control" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                                  {validationErrors.businessName && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.businessName}</div>
                                  )}
                                </div>

                                <div className="col-sm-4">
                                  <label>Business Email</label>
                                  <input type="text" className="form-control" value={clientEmail} onChange={(e) => setClientEmail(e.target.value)} />
                                  {validationErrors.clientEmail && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientEmail}</div>
                                  )}
                                </div>
                                <div className="col-sm-4">
                                  <label>Business Mobile No</label>
                                  <input type="text" className="form-control" value={clientMobile} onChange={(e) => setClientMobile(e.target.value)} />
                                  {validationErrors.clientMobile && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientMobile}</div>
                                  )}
                                </div>



                                <div className="col-sm-4">
                                  <label>Business City</label>
                                  <input type="text" className="form-control" value={clientCity} onChange={(e) => setClientCity(e.target.value)} />
                                  {validationErrors.clientCity && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientCity}</div>
                                  )}
                                </div>

                                <div className="col-sm-4">
                                  <label>Business State</label>
                                  <input type="text" className="form-control" value={clientState} onChange={(e) => setClientState(e.target.value)} />
                                  {validationErrors.clientState && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientState}</div>
                                  )}
                                </div>

                                <div className="col-sm-4">
                                  <label>Business Address</label>
                                  <input type="text" className="form-control" value={clientAddress} onChange={(e) => setClientAddress(e.target.value)} />
                                  {validationErrors.clientAddress && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientAddress}</div>
                                  )}
                                </div>

                                <div className="col-sm-4">
                                  <label>Zipcode </label>
                                  <input type="text" className="form-control" value={clientPinNo} onChange={(e) => setClientPinNo(e.target.value)} />
                                  {validationErrors.clientPinNo && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientPinNo}</div>
                                  )}
                                </div>

                                <div className="col-sm-4">
                                  <label>Business Group</label>
                                  <select className="form-control" value={selectedGroup} onChange={(e) => { setSelectedGroup(e.target.value) }}>
                                    <option value="">Select Group</option>
                                    {groupList && groupList.map((group) => (
                                      <option value={group.group_id}>{group.name}</option>
                                    ))}
                                  </select>
                                  {validationErrors.selectedGroup && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedGroup}</div>
                                  )}
                                </div>

                                <div className="col-sm-4">
                                  <label>Pan Card Number</label>
                                  <input type="text" className="form-control" value={clientPanCardNo} onChange={(e) => setPanCardNo(e.target.value)} onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()} />
                                  {validationErrors.clientPanCardNo && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientPanCardNo}</div>
                                  )}
                                </div>

                                <div className="col-sm-4">
                                  <label>Organization Type</label>
                                  <select className="form-control" value={orgTypeId} onChange={(e) => setOrgTypeId(e.target.value)}>
                                    <option value="">Select Organization Type</option>
                                    {orgType && orgType.map((orgnaType) => (
                                      <option value={orgnaType.id}>{orgnaType.org_type}</option>
                                    ))
                                    }

                                  </select>
                                  {validationErrors.orgTypeId && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.orgTypeId}</div>
                                  )}
                                </div>

                                <div className="col-sm-4">
                                  <label>Salect Product</label>
                                  <select className="form-control" value={selectedProduct}
                                    onChange={(e) => setSelectedProduct(e.target.value)}>
                                    <option value=''>Select Product</option>
                                    {product.map((pro) => (
                                      <option key={pro.id} value={pro.id}>
                                        {pro.product_name}
                                      </option>
                                    ))}
                                  </select>
                                  {validationErrors.selectedProduct && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                                  )}
                                </div>

                                {selectedProduct && (
                                  <div className="col-sm-4">
                                    <label>Select Service</label>
                                    <select className="form-control"
                                      value={selectedService}
                                      onChange={(e) => setSelectedService(e.target.value)}
                                    >
                                      <option value=''>Select Service</option>
                                      {service.map((ser) => (
                                        <option key={ser.id} value={ser.id}>
                                          {ser.service_name}
                                        </option>
                                      ))}
                                    </select>
                                    {validationErrors.selectedService && (
                                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                                    )}
                                  </div>
                                )}


                              </div>
                            </div>
                          )}

                          {step === 2 && (
                            <div className='card-body' id="clientDiv">
                              <div className="col-sm-12"><h4>Company Document</h4></div>
                              <div className="row">

                                <div className="col-sm-3">
                                  <select className="form-control" value={selectedDocumentType} onChange={(e) => setSelectedDocumentType(e.target.value)} >
                                    <option value="">Select Document </option>
                                    {documentType && documentType.map((docType) => (
                                      <option value={docType.id}> {docType.document_name}</option>
                                    ))}
                                  </select>
                                  {validationErrors.selectedDocumentType && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedDocumentType}</div>
                                  )}
                                </div>

                                <div className="col-sm-3">
                                  <input type="text" className="form-control" value={selectedDocumentNo} placeholder='Enter Document No' onChange={(e) => setSelectedDocument(e.target.value)} />
                                  {validationErrors.selectedDocumentNo && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedDocumentNo}</div>
                                  )}
                                </div>

                                <div className="col-sm-3">

                                  <input className="form-control" type="file" multiple onChange={handleFileChange} />

                                  {validationErrors.documentFile && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.documentFile}</div>
                                  )}
                                </div>

                                <div className="col-sm-3">
                                  <span className="btn-primary btn-sm" onClick={uplodeDocument}>
                                    Upload!
                                  </span>
                                </div>
                              </div>

                              <div className="row" style={{ marginTop: '30px' }}>

                                {clientDocDetails && clientDocDetails.map((docDetails) => (
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <h5>{docDetails.document_name} ({docDetails.doc_number})</h5>
                                    </div>

                                    {docDetails.doc_url && docDetails.doc_url.split(',').map((item, index) =>
                                      <div className="col-sm-3" style={{ margin: '10px' }}>
                                        <iframe src={item} title="company policy" width="200px" height="200px"></iframe>
                                      </div>
                                    )}
                                  </div>
                                ))}

                              </div>

                            </div>
                          )}

                          <div className='card-body' id="clientDiv">
                            <div className="d-flex justify-content-between">
                              {step > 1 && (
                                <Button variant="secondary" onClick={handlePrevious}>
                                  Previous
                                </Button>
                              )}

                              {step < 2 ? (
                                <Link className="btn btn-primary" onClick={handleNext}>
                                  Next
                                </Link>
                              ) : (
                                <>
                                  {documentType.length == clientDocDetails.length &&

                                    <Link className="btn btn-primary" type="button" onClick={handleFormSubmit}>
                                      Submit
                                    </Link>
                                  }
                                </>
                              )}
                            </div>
                          </div>

                        </form>
                      </td>
                    </tr>

                  </table>

                </div>
              }

              {active === 'Services' &&
                <>
                  <div>


                    <div className='card-body' style={{ overflowY: "hidden" }}>
                      <div className="row">

                        <div className="col-sm-3">
                          <select value={prePackageId} onChange={(e) => { setPrePackageId(e.target.value) }} className="form-control">
                            <option value=''>Select Category</option>
                            {packageCategory && packageCategory.map((pc) => (
                              <option value={pc.id}> {pc.name}</option>
                            ))}
                          </select>
                        </div>

                        <div class="col-sm-3">
                          <select className="form-control"
                            value={selectedCity}
                            onChange={(e) => setSelectedCity(e.target.value)}
                          >
                            <option value=''>Select City (Group)</option>
                            {cityList.map((city) => (
                              <option key={city.group_id} value={city.group_id}>
                                {city.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-sm-3">
                          <select className="form-control" value={selectedProduct}
                            onChange={(e) => setSelectedProduct(e.target.value)}>
                            <option value=''>Select Product</option>
                            {product.map((pro) => (
                              <option key={pro.id} value={pro.id}>
                                {pro.product_name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-sm-3">
                          <select className="form-control"
                            value={selectedService}
                            onChange={(e) => setSelectedService(e.target.value)}
                          >
                            <option value=''>Select Service</option>
                            {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                                {ser.service_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap">

                        {/*   {activeMenu1 ==='Lmart Packages' && */}
                        <>

                          {prePackages && prePackages.map((pp) => (
                            <>
                              <div className="col-sm-4 col-12 px-sm-2 px-0 py-2">
                                <div className='border p-2 rounded'>
                                  <div style={{ padding: '10px 20px', backgroundColor: 'rgb(29 95 163)', fontWeight: '600', textAlign: 'center', color: 'white' }}>
                                    {pp.package_name} {pp.active_offers > 0 ? <span style={{ color: 'red', fontSize: '25px', fontWeight: '600' }}>offer * </span> : ''}
                                  </div>

                                  <div style={{ textAlign: 'center', fontWeight: '600', fontSize: '15px' }}>
                                    {pp.category_name_list && pp.category_name_list.split(',').join(" + ")}
                                  </div>

                                  <div>
                                    <h5>&nbsp;&nbsp;
                                      {pp.duration_name} ({pp.package_type_name} {pp.total_lead} Lead)
                                    </h5>
                                  </div>

                                  <div>

                                  </div>
                                  <div>

                                    <table style={{ width: '100%' }}>
                                      {pp.assign_cat.map((cat) => (
                                        <tr>
                                          <td> &nbsp;&nbsp;✔ &nbsp; {cat.name}</td>
                                          <td style={{ float: 'rigth' }}>{cat.type_status === 1 ? '✅' : "❌"}
                                            &nbsp;&nbsp;
                                          </td>
                                        </tr>
                                      ))}
                                    </table>
                                  </div>

                                  <div>
                                    <h6>
                                      &nbsp;&nbsp; Is Partial Payment : {pp.is_partial_payment == 1 ? 'Yes' : 'No'}
                                    </h6>
                                  </div>

                                  <div>
                                    <h5> &nbsp;&nbsp;Package Price : {pp.package_price}</h5>
                                  </div>

                                  <div className='d-flex justify-content-between align-items-center'>

                                    {loading && selectedProposalId == pp.id ? (
                                      <p style={{ align: 'center' }}>
                                        <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
                                      </p>
                                    ) : (
                                      <button className="btn btn-primary btn-sm d-flex align-items-center justify-content-center" style={{ float: 'right', margin: '5px', width: "30px", height: "30px" }} onClick={() => sentPurposal(pp.id)}>
                                        <span class="material-icons-outlined" title="Send Proposal">share</span>
                                      </button>
                                    )}

                                    <button className="btn btn-primary btn-sm d-flex align-items-center justify-content-center" style={{ float: 'right', margin: '5px', width: "30px", height: "30px" }} onClick={() => viewOfferDetails(pp.id, pp.category_name, pp.category_name_list, pp.duration_name, pp.package_price, pp.package_type_name, pp.total_lead, pp.is_partial_payment, pp.package_name, pp.city_id, pp.group_id)}>
                                      <span class="material-icons-outlined" title="View Package Details">visibility</span>
                                    </button>

                                     {loading && selectedSalePackageId == pp.id ? (
                                      <p style={{ align: 'center' }}>
                                        <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
                                      </p>
                                    ) : (

                                      <button className="btn btn-primary btn-sm d-flex align-items-center justify-content-center" style={{ float: 'right', margin: '5px', width: "30px", height: "30 px" }} onClick={() => salePackageNew(pp.id, pp.category_name, pp.category_name_list, pp.duration_name, pp.package_price, pp.package_type_name, pp.total_lead, pp.is_partial_payment, pp.package_name, pp.city_id, pp.group_id)}>
                                        <span class="material-icons-outlined small" title="Sale Package">shopping_cart</span>
                                      </button>
                                    )}

                                  </div>
                                </div>
                              </div>
                            </>
                          ))}

                        </>
                        
                      </div>
                    </div>
                  </div>
                </>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />



      <Dialog open={packageby} PaperProps={{ style: { padding: '20px', maxWidth: '90%', width: '900px' } }}>
        <DialogTitle>Package Details</DialogTitle>
        <DialogContent style={{ height: 'auto' }}>
          <div>
            Package Name: <b>{packagedetails.package_name}</b> <br />
            Services: <b>{packagedetails.service_name}</b> <br />
            Package Duration: <b>{packagedetails.duration_name}</b> <br />
            Total Lead: <b>{packagedetails.total_lead}</b> <br />
            Package Price with Tax Include : <b>{packagedetails.price}</b> <br />
            Tax: <b>{packagedetails.taxAmount}</b> <br />
          </div>

          <table className="table table-bordered table-hovered">
            <thead>
              <tr>
                <td colSpan="7"><b>Offer Details</b></td>
              </tr>
              <tr>
                <td>S.No</td>
                <td>Offer Title</td>
                <td>Offer Type</td>
                <td>Percent</td>
                <td>Valid To</td>
              </tr>
            </thead>
            <tbody>
              {packagedetails.offerDetails &&
                packagedetails.offerDetails.map((od, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={checkedOffers === od.id}
                        onChange={() => handleOfferCheck(od.id)}
                      />
                    </td>
                    <td>{od.offer_title}</td>
                    <td>
                      {od.offer_discount_type.split(',').map((type, idx) => {
                        const isPrice = type.trim() === '1';
                        const isLead = type.trim() === '2';

                        return (
                          <div key={idx}>
                            <input
                              type="radio"
                              id={`offerType_${od.id}_${type}`}
                              name={`offerType_${od.id}`}
                              value={type.trim()}
                              disabled={checkedOffers !== od.id}
                              checked={selectedOfferTypes[od.id] === type.trim()}
                              onChange={() => handleOfferTypeChange(od.id, type.trim())}
                            />
                            <label htmlFor={`offerType_${od.id}_${type}`}>
                              {isPrice ? 'Price' : isLead ? 'Lead' : ''}
                            </label>
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {selectedOfferTypes[od.id] === '1' &&
                        od.offer_price_percent.split(',').map((pricePercent, idx) => (
                          <div key={idx}>

                            <input
                              type="radio"
                              id={`pricePercent_${od.id}_${pricePercent}`}
                              name={`percent_${od.id}`}
                              value={pricePercent.trim()}
                              checked={selectedPercent[od.id] === pricePercent.trim()}
                              onChange={() => handlePercentChange(od.id, pricePercent.trim())}
                            />
                            <label htmlFor={`pricePercent_${od.id}_${pricePercent}`}>
                              {pricePercent.trim() == 1 ? ' 5 %' : pricePercent == 2 ? '10 %' : pricePercent == 3 ? '15 %' : ''}
                            </label>
                          </div>
                        ))}

                      {selectedOfferTypes[od.id] === '2' &&
                        od.offer_lead_percent.split(',').map((leadPercent, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              id={`leadPercent_${od.id}_${leadPercent}`}
                              name={`percent_${od.id}`}
                              value={leadPercent.trim()}
                              checked={selectedPercent[od.id] === leadPercent.trim()}
                              onChange={() => handlePercentChange(od.id, leadPercent.trim())}
                            />
                            <label htmlFor={`leadPercent_${od.id}_${leadPercent}`}>
                              {leadPercent.trim() == 4 ? ' 5%' : leadPercent.trim() == 5 ? ' 10%' : leadPercent.trim() == 6 ? ' 15%' : leadPercent.trim() == 7 ? ' 20%' : ''}
                            </label>
                          </div>
                        ))}
                    </td>
                    <td>{od.valid_to}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          {discountPercent && selectedOfferTypes && discountType == 2 &&
            <div className="row">

              <div className="col-sm-6">
                <label>Discount % </label>
                <input type="text" className='form-control' value={discountPercent} onChange={(e) => { setApplyDiscountPercent(e.target.value) }} readOnly />
              </div>
              <div className="col-sm-6">
                <label>Extra Lead </label>
                <input type="text" className='form-control' value={Math.round(packagedetails.total_lead * discountPercent / 100)} readOnly />
              </div>

              <div className="col-sm-6">
                <label>After Discount Lead</label>
                {/* <input type="text" className='form-control' value={Math.round(Number(packagedetails.total_lead) + Number(packagedetails.total_lead * discountPercent / 100))} onChnage={(e)=>{setAfterDiscountLead(e.target.value)}} readOnly/> */}

                <input
                  type="text"
                  className="form-control"
                  value={afterDiscountLead}
                  readOnly
                />
              </div>


            </div>
          }



          {discountPercent && selectedOfferTypes && discountType === 1 && (
            <div className="row">


              <div className="col-sm-6">
                <label>Discount %</label>
                <input type="text" className="form-control" value={discountPercent} onChange={(e) => { setApplyDiscountPercent(e.target.value) }} readOnly />
              </div>



              <div className="col-sm-6">
                <label>Offer Payable Amount</label>
                <input
                  type="text"
                  className="form-control"
                  value={paybleAmountAfterDiscount}
                  readOnly
                />
              </div>
            </div>
          )}


          <div className="row">
            {packagedetails.isPartialPayment === '1' ? (
              <div className="col-sm-6">
                <label>Is Partial Payment:</label>
                <br />
                <input
                  type="radio"
                  name="isPartialPayment"
                  onChange={(e) => handlePartialPaymentChange(1)} 
                />
                Yes &nbsp; &nbsp; &nbsp; &nbsp;

                <input
                  type="radio"
                  name="isPartialPayment"
                  onChange={(e) => handlePartialPaymentChange(0)} 
                />
                No &nbsp; &nbsp; &nbsp; &nbsp;
                {validationErrors.partialPayment && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.partialPayment}
                  </div>
                )}
              </div>


            ) : (
              <div className="col-sm-6">
                <label>Is Partial Payment:</label>
                <br />
                <input
                  type="radio"
                  name="isPartialPayment"
                  checked
                  onChange={() => handlePartialPaymentChange(0)}
                />
                No &nbsp; &nbsp; &nbsp; &nbsp;
               
              </div>
              
            )}

            <div className="col-sm-6">
              <label>Select Cities</label>
              <MultiSelect
                options={cityDetails}
                value={selectedPackageCity}
                onChange={setSelectedPackageCity}
                labelledBy="Select Cities"
              />
              {validationErrors.citiesValue && (
                <div className="text-danger" style={{ fontSize: '15px' }}>
                  {validationErrors.citiesValue}
                </div>
              )}
            </div>

            <div className="col-sm-6">
              <label>Select Company</label>
              <select
                className="form-control"
                value={selectedComp}
                onChange={(e) => setSelectedComp(e.target.value)}
              >
                <option value="">Select Company</option>
                {compDetails &&
                  compDetails.map((comp) => (
                    <option key={comp.comp_id} value={comp.comp_id}>
                      {comp.business_name}
                    </option>
                  ))}
              </select>
              {validationErrors.compDetails && (
                <div className="text-danger" style={{ fontSize: '15px' }}>
                  {validationErrors.compDetails}
                </div>
              )}
            </div>

            <div className="col-sm-6">
              <label>Registration Amount</label>
              <input type="text" className="form-control" value={regAmount} readonly />
            </div>

            <div className="col-sm-6">
              <label>Client Type</label>
              <input type="text" className="form-control" value={companyType} readonly />
            </div>
            
            <div className="col-sm-6">
              <label>Payable Amount</label>
              <input
                type="text"
                className="form-control"
                value={payblePackageAmount}
                readOnly
              />
            </div>


            <div className="col-sm-6">
              <label>Deposit Amount</label>
              <input
                type="text"
                className="form-control"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
                style={{ width: '250px' }}
              />
              {validationErrors.depositAmount && (
                <div className="text-danger" style={{ fontSize: '15px' }}>
                  {validationErrors.depositAmount}
                </div>
              )}
            </div>


            <div className="col-sm-6">
              <span
                className="btn btn-primary btn-sm"
                style={{ marginTop: '35px' }}
                onClick={() => {
                  // Define variables for arguments based on conditions
                  let price = payblePackageAmount;
                  let totalLead = packagedetails.total_lead;
                  let partial = partialPayment;

                  // Check conditions to update the arguments
                  if (discountType === 1 && checkedOffers) {

                    price = payblePackageAmount;
                    totalLead = packagedetails.total_lead;
                    partial = partialPayment;
                  } else if (discountType === 2 && checkedOffers) {
                    price = payblePackageAmount;
                    totalLead = afterDiscountLead;
                    partial = partialPayment;
                  }
                  // Call the function with the determined arguments
                  checkTaxAmount(price, totalLead, partial, regAmount);
                }}
              >
                Calculate Tax Amount
              </span>
            </div>
          </div>

          <br />

          <table className="table table-bordered table-hovered">
            <thead>
              <tr>
                <td>Tax Amount</td>
                <td>Balance Amount</td>
                <td>Due Leads</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={taxAmount}
                    style={{ width: '150px' }}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={balanceAmount}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={dueLeads}
                    style={{ width: '150px' }}
                    readOnly
                  />
                </td>
              </tr>

              
            </tbody>
          </table>
        </DialogContent>
        <p style={{color:'red'}}>{discountType ==1 ?<h5>You will save ₹ {packagedetails.price - (payblePackageAmount-regAmount)} on this package.</h5>:discountType ==2?<h5>You will get an extra {afterDiscountLead - packagedetails.total_lead} leads with this package.</h5>:''}</p>
        <DialogActions>

        

          {packageloading ? (
            <p style={{ color: 'red' }}>Please do not refresh the page while the package creation process is in progress. <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img></p>
          ) : (
            <>
            

              <button className="btn btn-info btn-sm" onClick={closePackageModel}>
                Close
              </button>
              &nbsp;&nbsp;&nbsp;
              {viewOfferDetailsStatus != 1 &&

                <button className="btn btn-primary btn-sm" style={{ float: 'right', marginRight: '10px' }} onClick={() => submitPackageForm(packagedetails.package_id)}>Sale Now</button>

              }
            </>
          )}



        </DialogActions>
      </Dialog>

      {/* <Dialog open={follwoupModal} fullWidth PaperProps={{ style: { padding: '20px' } }}>
        <DialogTitle>Client Followup</DialogTitle>
        <DialogContent style={{ height: "350px" }}>
          <form>
            <div className="row">


              <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                <p style={{ margin: "10px" }}>Followup Details</p>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
              </div>

              <div class="col-sm-6">
                <label>Select City (Group)</label>
                <select className="form-control"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <option value=''>Select City</option>
                  {cityList.map((city) => (
                    <option key={city.group_id} value={city.group_id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-6">
                <label>Salect Product</label>
                <select className="form-control" value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}>
                  <option value=''>Select Product</option>
                  {product.map((pro) => (
                    <option key={pro.id} value={pro.id}>
                      {pro.product_name}
                    </option>
                  ))}
                </select>
              </div>
              {selectedProduct && (
                <div className="col-sm-6">
                  <label>Select Service</label>
                  <select className="form-control"
                    value={selectedService}
                    onChange={(e) => setSelectedService(e.target.value)}
                  >
                    <option value=''>Select Service</option>
                    {service.map((ser) => (
                      <option key={ser.id} value={ser.id}>
                        {ser.service_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {category != '' && selectedService && (
                <div className="col-sm-6">
                  <label>Select Category</label>
                  <select className="form-control"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value=''>Select Category</option>
                    {category.map((cate) => (
                      <option key={cate.id} value={cate.id}>
                        {cate.category_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}




              <div className="col-sm-6">
                <label>Call Status</label>
                <select className="form-control"
                  value={selectedFollowCallStatus}
                  onChange={(e) => setFollCallStatus(e.target.value)}
                >
                  <option value=''>Select Call Status</option>
                  <option value='Connected'>Connected</option>
                  <option value='Not_Connected'>Not Connected</option>
                </select>
              </div>







              {selectedFollowCallStatus && (
                <div className="col-sm-6">
                  <label>Followup Status</label>
                  <select className="form-control"
                    value={selectedFollowStatus}
                    onChange={(e) => setFollStatus(e.target.value)}
                  >
                    <option value=''>Select Followup Status</option>
                    {followup.map((follo) => (
                      <option key={follo.id} value={follo.id}>
                        {follo.activity_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {selectedCity && (selectedFollowStatus == 5 || selectedFollowStatus == 6 || selectedFollowStatus == 7 || selectedFollowStatus == 9) && (
                <div className="col-sm-12">
                  <label>Select Package</label>
                  <select className="form-control"
                    value={selectedPackage}
                    onChange={(e) => setSelectedPackage(e.target.value)}
                  >
                    <option value=''>Select Package Details</option>
                    {prePackageList.map((prePkg) => (
                      <option key={prePkg.id} value={prePkg.id}>
                        {prePkg.package_name} ({prePkg.package_price})
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {selectedFollowStatus != 19 && (
                <div className="col-sm-6">
                  <label>Next Followup Date</label><br />

                  <DatePicker className="form-control" style={{ position: 'absolute' }}
                    minDate={new Date()}
                    maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                    selected={nxtFollowupDate}
                    onChange={(date) => setNxtFollowupDate(date)}
                    dateFormat="dd/MM/YYYY"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
              )}

              {selectedFollowStatus == 19 && (
                <div className="col-sm-12">
                  <label> Package Details</label>
                  <table className="table table-bordered table-hovered">

                    <tr>
                      <td>Wallet Balance Amount</td>
                      <td colspan="">{walletDetails && walletDetails.balance_amount}</td>
                      <td>Total Lead</td>
                      <td colspan="3">{salePackageInfo && salePackageInfo.total_lead}</td>
                    </tr>

                    <tr>
                      <td><b>Particulars</b></td>
                      <td><b>Amount</b></td>
                      <td><b>Tax {selectedGstPercent} % </b></td>
                      <td><b>Total</b></td>
                    </tr>

                    <tr>
                      <td>Package Amount</td>
                      <td>{salePackageInfo && salePackageInfo.package_price}</td>
                      <td>{salePackageInfo && salePackageInfo.package_price * selectedGstPercent / 100}</td>
                      <td>{parseInt((salePackageInfo && salePackageInfo.package_price * selectedGstPercent / 100)) + parseInt(salePackageInfo && salePackageInfo.package_price)} </td>
                    </tr>

                    <tr>
                      <td>Reg Amount</td>
                      <td>{selectedRegAmount}</td>
                      <td>{selectedRegAmount * selectedGstPercent / 100}</td>
                      <td>{parseInt((selectedRegAmount * selectedGstPercent / 100)) + parseInt(selectedRegAmount)} </td>
                    </tr>

                    <tr>
                      <td>Total Amount</td>
                      <td>{parseInt(salePackageInfo && salePackageInfo.package_price) + parseInt(selectedRegAmount)}</td>

                      <td>{parseInt(salePackageInfo && salePackageInfo.package_price * selectedGstPercent / 100) + parseInt((selectedRegAmount * selectedGstPercent / 100))}</td>

                      <td colspan="1">{(parseInt(selectedRegAmount * selectedGstPercent / 100) + parseInt(selectedRegAmount)) + parseInt((salePackageInfo && salePackageInfo.package_price * selectedGstPercent / 100)) + parseInt((salePackageInfo && salePackageInfo.package_price))}
                      </td>

                    </tr>



                    {parseInt(walletDetails && walletDetails.balance_amount) <= parseInt(salePackageInfo.package_price) + parseInt(selectedRegAmount) &&
                      <tr>
                        <td colspan="4">
                          <span style={{ color: 'red', fontSize: '13px' }}>
                            You don't have sufficient balance to buy this package
                          </span>
                        </td>
                      </tr>
                    }

                    {walletDetails && walletDetails.balance_amount >= salePackageInfo.package_price &&
                      <tr>
                        <td>
                          Deposit Amount
                        </td>
                        <td>
                          <input type="text" className="form-control" style={{ width: '150px' }} value={deposit_amount} onChange={(e) => setDepositAmountByClient(e.target.value)} />
                        </td>
                      </tr>
                    }
                  </table>
                </div>
              )}



              {selectedFollowStatus && (
                <div className="col-sm-12">
                  <label>Remarks</label>
                  <TextField type="text" className="form-control" value={followupRemarks} onChange={(e) => setFollowupRemarks(e.target.value)}></TextField>
                </div>
              )}

            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={closeFollowupControl}>Close</Link>
          {selectedFollowStatus && selectedFollowStatus != 19 && (
            <Link className="btn btn-success btn-sm" onClick={submitFollowupForm}>Create Followup</Link>
          )}
          {selectedFollowStatus && selectedFollowStatus == 19 && (
            <Link className="btn btn-success btn-sm" onClick={() => submitPackageForm()}>Create Package</Link>
          )}
        </DialogActions>
      </Dialog> */}

      <Dialog open={follwoupModal} fullWidth PaperProps={{ style: {padding: '20px' } }}>
        <DialogTitle>Client Followup</DialogTitle>
          <DialogContent style={{height:"450px"}}>
            <form>
                <div className="row" style={{overflowY: "hidden"}}>

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Followup Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div class="col-sm-6">
                    <label>Select City (Group)</label>
                    <select className="form-control"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        >
                        <option value=''>Select City</option>
                        {cityList.map((city) => (
                        <option key={city.group_id} value={city.group_id}>
                        {city.name}
                        </option>
                        ))}
                    </select>
                  
                  </div>

                  <div className="col-sm-6">
                    <label>Salect Product</label>
                    <select className="form-control" value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}>
                          <option value=''>Select Product</option>
                          {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                    </select>
                    
                  </div>
                  {selectedProduct &&(
                  <div className="col-sm-6">
                      <label>Select Service</label>
                      <select className="form-control"
                              value={selectedService}
                              onChange={(e) => setSelectedService(e.target.value)}
                              >
                              <option value=''>Select Service</option>
                              {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                              {ser.service_name}
                              </option>
                              ))}
                      </select>
                    
                  </div>
                  
                  )}

                  {category != '' && selectedService &&(
                  <div className="col-sm-6">
                      <label>Select Category</label>
                      <select className="form-control"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          >
                          <option value=''>Select Category</option>
                          {category.map((cate) => (
                          <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                          </option>
                          ))}
                      </select>
                      
                  </div>
                )}




                  <div className="col-sm-6">
                      <label>Call Status</label>
                      <select className="form-control"
                          value={selectedFollowCallStatus}
                          onChange={(e) => setFollCallStatus(e.target.value)}
                          >
                          <option value=''>Select Call Status</option>
                          <option value='Connected'>Connected</option>
                          <option value='Not_Connected'>Not Connected</option>
                      </select>
                     
                  </div>
            
                  {selectedFollowCallStatus &&(
                    <div className="col-sm-6">
                        <label>Followup Status</label>
                        <select className="form-control"
                          value={selectedFollowStatus}
                          onChange={(e) => setFollStatus(e.target.value)}
                          >
                          <option value=''>Select Followup Status</option>
                          {followup.map((follo) => (
                          <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                          </option>
                          ))}
                        </select>
                        
                    </div>
                  )}

                  {selectedCity && (selectedFollowStatus ==5 || selectedFollowStatus ==6 || selectedFollowStatus ==7 || selectedFollowStatus ==9) &&(
                    <div className="col-sm-12">
                        <label>Select Package</label>
                        <select className="form-control"
                          value={selectedPackage}
                          onChange={(e) => setSelectedPackage(e.target.value)}
                          >
                          <option value=''>Select Package Details</option>
                          {prePackageList.map((prePkg) => (
                          <option key={prePkg.id} value={prePkg.id}>
                          {prePkg.package_name} ({prePkg.package_price})
                          </option>
                          ))}
                        </select>
                        {validationErrors.selectedPackage && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPackage}</div>
                        )}
                    </div>
                    
                  )}
            
                
                  <div className="col-sm-6">
                      <label>Next Followup Date</label><br />
                      
                      <DatePicker className="form-control" style={{position: 'absolute'}}
                        minDate={new Date()}
                        maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                        selected={nxtFollowupDate}
                        onChange={(date) => setNxtFollowupDate(date)}
                        dateFormat="dd/MM/YYYY"
                        onKeyDown={(e) => {
                          e.preventDefault();
                      }}
                      />
                      {validationErrors.nxtFollowupDate && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.nxtFollowupDate}</div>
                      )}
                  </div>
               

                
              
                {selectedFollowStatus  &&(
                  <div className="col-sm-12">
                    <label>Remarks</label>
                    <TextField type="text" className="form-control" value={followupRemarks} onChange={(e) => setFollowupRemarks(e.target.value)}></TextField> 
                  </div>
                )}
                </div>
            </form>
          </DialogContent>

          <DialogActions>
              <Link className="btn btn-primary btn-sm" onClick={closeFollowupControl}>Close</Link>

              <Link className="btn btn-success btn-sm" onClick={submitFollowupForm}>Create Followup</Link>
          </DialogActions>
      </Dialog>

      

      <Dialog open={feedbackModal} fullWidth PaperProps={{ style: { padding: '20px' } }}>
        <DialogTitle>Client Feedback</DialogTitle>
        <DialogContent style={{ height: "350px" }}>
          <form>
            <div className="row">


              <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                <p style={{ margin: "10px" }}>Feedback Details</p>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
              </div>

              <div class="col-sm-6">
                <label>Select City (Group)</label>
                <select className="form-control"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <option value=''>Select City</option>
                  {cityList.map((city) => (
                    <option key={city.group_id} value={city.group_id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-6">
                <label>Salect Product</label>
                <select className="form-control" value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}>
                  <option value=''>Select Product</option>
                  {product.map((pro) => (
                    <option key={pro.id} value={pro.id}>
                      {pro.product_name}
                    </option>
                  ))}
                </select>
              </div>
              {selectedProduct && (
                <div className="col-sm-6">
                  <label>Select Service</label>
                  <select className="form-control"
                    value={selectedService}
                    onChange={(e) => setSelectedService(e.target.value)}
                  >
                    <option value=''>Select Service</option>
                    {service.map((ser) => (
                      <option key={ser.id} value={ser.id}>
                        {ser.service_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {category != '' && selectedService && (
                <div className="col-sm-6">
                  <label>Select Category</label>
                  <select className="form-control"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value=''>Select Category</option>
                    {category.map((cate) => (
                      <option key={cate.id} value={cate.id}>
                        {cate.category_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}




              <div className="col-sm-6">
                <label>Call Status</label>
                <select className="form-control"
                  value={selectedFollowCallStatus}
                  onChange={(e) => setFollCallStatus(e.target.value)}
                >
                  <option value=''>Select Call Status</option>
                  <option value='Connected'>Connected</option>
                  <option value='Not_Connected'>Not Connected</option>
                </select>
              </div>

              {selectedFollowCallStatus && (
                <div className="col-sm-6">
                  <label>Followup Status</label>
                  <select className="form-control"
                    value={selectedFollowStatus}
                    onChange={(e) => setFollStatus(e.target.value)}
                  >
                    <option value=''>Select Followup Status</option>
                    {followup.map((follo) => (
                      <option key={follo.id} value={follo.id}>
                        {follo.activity_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {selectedCity && (selectedFollowStatus == 5 || selectedFollowStatus == 6 || selectedFollowStatus == 7 || selectedFollowStatus == 9) && (
                <div className="col-sm-12">
                  <label>Select Package</label>
                  <select className="form-control"
                    value={selectedPackage}
                    onChange={(e) => setSelectedPackage(e.target.value)}
                  >
                    <option value=''>Select Package Details</option>
                    {prePackageList.map((prePkg) => (
                      <option key={prePkg.id} value={prePkg.id}>
                        {prePkg.package_name} ({prePkg.package_price})
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {selectedFollowStatus != 19 && (
                <div className="col-sm-6">
                  <label>Next Followup Date</label><br />

                  <DatePicker className="form-control" style={{ position: 'absolute' }}
                    minDate={new Date()}
                    maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                    selected={nxtFollowupDate}
                    onChange={(date) => setNxtFollowupDate(date)}
                    dateFormat="dd/MM/YYYY"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
              )}

              {selectedFollowStatus == 19 && (
                <div className="col-sm-12">
                  <label> Package Details</label>
                  <table className="table table-bordered table-hovered">

                    <tr>
                      <td>Wallet Balance Amount</td>
                      <td colspan="">{walletDetails && walletDetails.balance_amount}</td>
                      <td>Total Lead</td>
                      <td colspan="3">{salePackageInfo && salePackageInfo.total_lead}</td>
                    </tr>

                    <tr>
                      <td><b>Particulars</b></td>
                      <td><b>Amount</b></td>
                      <td><b>Tax {selectedGstPercent} % </b></td>
                      <td><b>Total</b></td>
                    </tr>

                    <tr>
                      <td>Package Amount</td>
                      <td>{salePackageInfo && salePackageInfo.package_price}</td>
                      <td>{salePackageInfo && salePackageInfo.package_price * selectedGstPercent / 100}</td>
                      <td>{parseInt((salePackageInfo && salePackageInfo.package_price * selectedGstPercent / 100)) + parseInt(salePackageInfo && salePackageInfo.package_price)} </td>
                    </tr>

                    <tr>
                      <td>Reg Amount</td>
                      <td>{selectedRegAmount}</td>
                      <td>{selectedRegAmount * selectedGstPercent / 100}</td>
                      <td>{parseInt((selectedRegAmount * selectedGstPercent / 100)) + parseInt(selectedRegAmount)} </td>
                    </tr>

                    <tr>
                      <td>Total Amount</td>
                      <td>{parseInt(salePackageInfo && salePackageInfo.package_price) + parseInt(selectedRegAmount)}</td>

                      <td>{parseInt(salePackageInfo && salePackageInfo.package_price * selectedGstPercent / 100) + parseInt((selectedRegAmount * selectedGstPercent / 100))}</td>

                      <td colspan="1">{(parseInt(selectedRegAmount * selectedGstPercent / 100) + parseInt(selectedRegAmount)) + parseInt((salePackageInfo && salePackageInfo.package_price * selectedGstPercent / 100)) + parseInt((salePackageInfo && salePackageInfo.package_price))}
                      </td>

                    </tr>



                    {parseInt(walletDetails && walletDetails.balance_amount) <= parseInt(salePackageInfo.package_price) + parseInt(selectedRegAmount) &&
                      <tr>
                        <td colspan="4">
                          <span style={{ color: 'red', fontSize: '13px' }}>
                            You don't have sufficient balance to buy this package
                          </span>
                        </td>
                      </tr>
                    }

                    {walletDetails && walletDetails.balance_amount >= salePackageInfo.package_price &&
                      <tr>
                        <td>
                          Deposit Amount
                        </td>
                        <td>
                          <input type="text" className="form-control" style={{ width: '150px' }} value={deposit_amount} onChange={(e) => setDepositAmountByClient(e.target.value)} />
                        </td>
                      </tr>
                    }
                  </table>
                </div>
              )}



              {selectedFollowStatus && (
                <div className="col-sm-12">
                  <label>Remarks</label>
                  <TextField type="text" className="form-control" value={followupRemarks} onChange={(e) => setFollowupRemarks(e.target.value)}></TextField>
                </div>
              )}

            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={closeFeedbackControl}>Close</Link>
          {selectedFollowStatus && selectedFollowStatus != 19 && (
            <Link className="btn btn-success btn-sm" onClick={submitFollowupForm}>Create Followup</Link>
          )}
          {selectedFollowStatus && selectedFollowStatus == 19 && (
            <Link className="btn btn-success btn-sm" onClick={() => submitPackageForm()}>Create Package</Link>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={duePackagePay} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Due Payment Details</DialogTitle>
        <DialogContent style={{ height: "auto" }}>
          <>
            Package Name : <b>{duePackagedetails.package_name} </b> <br />
            Package Price : <b>{duePackagedetails.price} </b><br />
            Paid Amount : <b>{duePackagedetails.paid_amount} </b><br />
            Balance Amount : <b>{duePackagedetails.due_amount} </b><br />
            Total Lead : <b>{duePackagedetails.total_lead} </b><br />
          </>

          <br />
          <div>
            Deposit Amount : &nbsp; &nbsp; &nbsp;
            <input type="text" className="form-control" value={depositAmount} style={{ width: '250px' }} readOnly />
          </div>
          < br />

          <table className="table table-bordered table-hovered">
            <tr>
              <td>Tax Amount</td>
              <td>Balance Amount</td>
              <td>Due Leads</td>
            </tr>
            <tr>
              <td>
                <input type="text" className="form-control" value={taxAmount} style={{ width: '150px' }} readOnly />
              </td>
              <td>
                <input type="text" className="form-control" value={balanceAmount} readOnly />
              </td>
              <td>
                <input type="text" className="form-control" value={dueLeads} style={{ width: '150px' }} readOnly />
              </td>
            </tr>
          </table>


        </DialogContent>
        <DialogActions>
          {duePaymentLoading ? (
            <p style={{ color: 'red' }}>Please do not refresh the page while the payment process is in progress. Loading...</p>
          ) : (
            <>
              <Link className="btn btn-info btn-sm" onClick={closeDuePackageModel}>Close</Link>

              <Link className="btn btn-primary btn-sm" onClick={() => submitDuePackageForm(duePackagedetails.package_id)}>Due Payment</Link>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* <Dialog open={packageModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Package Status</DialogTitle>
        <DialogContent style={{ height: "auto" }}>
          <div>
            <div className="col-sm-6">
              <label>Package Start Date</label>
              <input type="date" className="form-control" value={packageStartDate} onChange={(e) => { setPackageStartDate(e.target.value) }} />
              {validationErrors.packageStartDate && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageStartDate}</div>
              )}
            </div>
            <div className="col-sm-12">
              <label>Remarks</label>
              <input type="text" className="form-control" value={packageStopRemark} onChange={(e) => { setPackageStopRemarks(e.target.value) }} />
              {validationErrors.packageStopRemark && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageStopRemark}</div>
              )}
            </div>

            <div className="col-sm-12">
              <p><br /><input type="checkbox" checked={areYouSure} onChange={(e) => { setAreYouSure(e.target.checked) }} />
                Are you sure,Due you want to {currentPackageStatus == 1 ? 'Deactive' : 'Active'} this package
              </p>
              {validationErrors.areYouSure && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.areYouSure}</div>
              )}
            </div>

          </div>
        </DialogContent>
        <DialogActions>
          <Link className="btn btn-info btn-sm" onClick={closePackageStatusModal}>Close</Link>

          <Link className="btn btn-primary btn-sm" onClick={() => submitPackageActiveDeactive()}>Yes Sure</Link>
        </DialogActions>
      </Dialog> */}

      <Dialog open={packageModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Package Status</DialogTitle>
        <DialogContent style={{ height: 'auto' }}>
          <div>
            <div className="col-sm-6">
              <label>Package Start Date</label>
              <input
                type="date"
                className="form-control"
                value={packageStartDate}
                min={minDate}
                max={maxDate}
                onChange={(e) => setPackageStartDate(e.target.value)}
              />
              {validationErrors.packageStartDate && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageStartDate}</div>
              )}
            </div>
            <div className="col-sm-12">
              <label>Remarks</label>
              <input
                type="text"
                className="form-control"
                value={packageStopRemark}
                onChange={(e) => setPackageStopRemarks(e.target.value)}
              />
              {validationErrors.packageStopRemark && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageStopRemark}</div>
              )}
            </div>

            <div className="col-sm-12">
              <p>
                <br />
                <input type="checkbox" checked={areYouSure} onChange={(e) => setAreYouSure(e.target.checked)} />
                Are you sure, Do you want to {currentPackageStatus === 1 ? <span style={{ color: 'red', fontWeight: 'bold' }}>Deactivate</span> : <span style={{ color: 'green', fontWeight: 'bold' }}>Activate</span>} this package
              </p>
              {validationErrors.areYouSure && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.areYouSure}</div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Link className="btn btn-info btn-sm" onClick={closePackageStatusModal}>
            Close
          </Link>

          <Link className="btn btn-primary btn-sm" onClick={submitPackageActiveDeactive}>
            Yes Sure
          </Link>
        </DialogActions>
      </Dialog>


    </DashboardLayout>
  )
}
export default MatureClientProfile;
