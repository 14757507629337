import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function WorkingDay() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [workingday, getworkingday] = useState([]);
  const [loading, setLoading] = useState(true);
  const[permission,Setpermission] = useState();
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
        fetchworkingday()
    },[])
   const fetchworkingday = async () => {
    try {
      const response = await dashboardApi.get('working-day-list');
      if (response.data.status === 200) {
        getworkingday(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = `get-permission/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        Setpermission(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
  const canAddemptype = permission && permission.some(item => item.module_name === "add-emptype");
  const canchangestatus = permission && permission.some(item => item.module_name === "change-status");
 const toggleStatus = async (dayId) => {
  const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`get-working-day-status/${dayId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('working-day-status', {
        working_day_id : dayId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        getworkingday((prevState) =>
          prevState.map((dayObj) =>
            dayObj.id === dayId ? { ...dayObj, status: newStatus } : dayObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <div style={{float:'right'}}>
              <Link className='btn btn-primary mb-2 float-end' to={"/create-working-day"}>
                   +
                </Link>

              </div>
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Date</td>
                  <td>Reason</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {workingday.map((working, index) => (
                <tr key={working.id}>
                  <td>{index + 1}</td>
                  <td>{working.date}</td>
                  <td>{working.reason}</td>
                  <td><button
                  type="button"
                  className={`btn btn-${working.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(working.id)}
                  >
                  {working.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                  <td>
                  <Link to={`/edit-working-day/${working.id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>
                  
                  </td>
               </tr>
              ))}

            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default WorkingDay;
