import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import ProductDetailsHeader from 'layouts/common_components/product_details_header';

import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import  secureLocalStorage  from  "react-secure-storage";

function Product_details()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  //const department = secureLocalStorage.getItem('department');
  const [new_product_name, setProductName] = useState('');
  const [product, setProduct] = useState();
  
  const [openModal,setOpenModal] =useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const permission = secureLocalStorage.getItem('permission');
  const parsedPermission = permission ? JSON.parse(permission) : [];

  useEffect(() =>
  {
    if(!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  
  useEffect(()=>
  {
      fetchState() 
  },[])
    
  const fetchState = async () =>
  {
    try
    {
      let type='all';
      const response = await dashboardApi.get(`sales-product-list/${type}`);
      if (response.data.status === 200)
      {
        setProduct(response.data.data);
      }
      else
      {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }
    catch (error)
    {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };

  //modal open function
  const handleOpenModal = () =>{
    setOpenModal(true);
  };

  // modal close function
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  
  // Add New product
  const handlesubmit = async() =>
  {
    const errors = {};
    

    if(!new_product_name)
    {
      errors.new_product_name = 'Product name is required.';
    }

    if(new_product_name)
    {
      const chkProduct = await dashboardApi.get(`check-product-details/${new_product_name}`);
      if(chkProduct.data==1)
      {
        errors.new_product_name = 'Product already exist in syatem.';
      }
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0)
    {
      return; 
    }

    const formData = new FormData();
    formData.append('product_name',new_product_name);
    formData.append('created_by',emp_id);
   
    try {
      const response = await dashboardApi.post('add-product-details', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/product-details");
        handleCloseModal();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    } 
  }
 

    const toggleStatus = async (id) =>
    {
        const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text:  "You want to update this product status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it'
          }).then((result) => {
            return result.isConfirmed
        });

        if(!isConfirm){
          return;
        }
        const response = await dashboardApi.get(`sales-product-status/${id}`);
    
        if(response.data.status === 200)
        {
          const currentStatus = response.data.data;
          //console.log(response.data.data);
          // Calculate the new status (toggle)
          const newStatus = currentStatus === 1 ? 0 : 1;
          // Make an API call to update the status
          const updateResponse =await dashboardApi.post('sales-product-change-status', {
            id: id,
            status: newStatus,
          });
          
          if(updateResponse.data.status === 200)
          {
            // Update the local state with the new status
            setProduct((prevProduct) =>
            prevProduct.map((productObj) =>
            productObj.id === id ? { ...productObj, status: newStatus } : productObj
              )
            );
            Swal.fire({
              icon: 'success',
              text: updateResponse.data.message,
            });
          } 
          else
          {
            console.error('Error updating status:', updateResponse.data.message);
          }
        }
        else
        {
          console.error('Error fetching current status:', response.data.message);
        }
    };
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row">
                    <div class="col-sm-11">
                    <ProductDetailsHeader></ProductDetailsHeader>
                    </div>
                    <div class="col-sm-1">
                      <Link className='btn btn-info' onClick={() => handleOpenModal()}>
                        +
                      </Link>
                    </div>
                </div>
                
                {loading ? (
                  <p>Loading...</p>
                ) : (
                <div style={{overflowY: "hidden"}}>
                  <table className="table table-bordered table-hovered">
                    <tr>
                      <td>S.No</td>
                      <td>Product Name</td>
                      <td>Created By</td>
                      <td>Created Date</td>
                      <td>Status</td>
                    </tr>
                  {product.map((product, index) => (
                    <tr key={product.id}>
                      <td>{index + 1}</td>
                      <td>{product.product_name}</td>
                      <td>{product.created_by}</td>
                      <td>{product.created_date}</td>
                      
                      <td>
                        <button
                          type="button"
                          className={`btn btn-${product.status === 1 ? 'success' : 'danger'}`}
                          onClick={() => toggleStatus(product.id)}
                          >
                          {product.status === 1 ? 'Active' : 'Inactive'}
                        </button>
                      </td>
                    </tr>
                  ))
                  }

                </table>
              </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>Add New Product</DialogTitle>
          <DialogContent>
            <form>
              <div>
                <label>Enter Product Name</label>
                <TextField fullWidth type="text" value={new_product_name} onChange={(e) =>setProductName(e.target.value)}></TextField>
                {validationErrors.new_product_name && (
                  <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.new_product_name}</div>
                )}
              </div>

              
        
              
            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick = {handleCloseModal}>
              Close
            </Link>
            <Link className="btn btn-info"  onClick = {handlesubmit}>
            Add
            </Link>
          </DialogActions>
      </Dialog>
  
    </DashboardLayout>
  );
}
export default Product_details;
