import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";

function AppCrudHeader() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  // Function to set active tab when clicked
  const handleTabClick = (tabPath) => {
    setActiveTab(tabPath);
  };

  return (
    <Card>
      <div className="card-body">
        <div className="container-fluid">
          <div className="row">
           
              <Link
                to='/otp-template'
                className={`btn btn-sm ${activeTab === '/otp-template' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/otp-template')}
              >
               Otp Template
              </Link>
             &nbsp; &nbsp;
           
            
              <Link
                to='/app-version'
                className={`btn btn-sm ${activeTab === '/app-version' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/app-version')}
              >
                App Version
              </Link>
             &nbsp; &nbsp;
            
          
              <Link
                to='/item-type'
                className={`btn btn-sm ${activeTab === '/item-type' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/item-type')}
              >
                Product Item Type
              </Link>
             &nbsp; &nbsp;
          
          
              <Link
                to='/item-info'
                className={`btn btn-sm ${activeTab === '/item-info' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/item-info')}
              >
                Item Info
              </Link>
             &nbsp; &nbsp;
          
           
              <Link
                to='/transport-charges-type'
                className={`btn btn-sm ${activeTab === '/transport-charges-type' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/transport-charges-type')}
              >
                transport charges type
              </Link>
             &nbsp; &nbsp;
           
          
              <Link
                to='/genric-change-type'
                className={`btn btn-sm ${activeTab === '/genric-change-type' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/genric-change-type')}
              >
                Genric Change Type
              </Link>
           
           &nbsp; &nbsp;
            
              <Link
                to='/support-links-info'
                className={`btn btn-sm ${activeTab === '/support-links-info' ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTabClick('/support-links-info')}
              >
               Support Links Info
              </Link>
           

            
          </div>
          <hr/>

          <div className="row">
           
           <Link
             to='/locality'
             className={`btn btn-sm ${activeTab === '/locality' ? 'btn-primary' : 'btn-outline-primary'}`}
             onClick={() => handleTabClick('/locality')}
           >
            Locality
           </Link>
          &nbsp; &nbsp;
        
         
           <Link
             to='/base-points-info'
             className={`btn btn-sm ${activeTab === '/base-points-info' ? 'btn-primary' : 'btn-outline-primary'}`}
             onClick={() => handleTabClick('/base-points-info')}
           >
            Base Point Info
           </Link>
          &nbsp; &nbsp;
         
       
           <Link
             to='/source-from'
             className={`btn btn-sm ${activeTab === '/source-from' ? 'btn-primary' : 'btn-outline-primary'}`}
             onClick={() => handleTabClick('/source-from')}
           >
             Source From
           </Link>
          &nbsp; &nbsp;
       
       
           <Link
             to='/complain-status'
             className={`btn btn-sm ${activeTab === '/complain-status' ? 'btn-primary' : 'btn-outline-primary'}`}
             onClick={() => handleTabClick('/complain-status')}
           >
             Complaint Status
           </Link>
          &nbsp; &nbsp;
       
        
           <Link
             to='/payment-gateway'
             className={`btn btn-sm ${activeTab === '/payment-gateway' ? 'btn-primary' : 'btn-outline-primary'}`}
             onClick={() => handleTabClick('/payment-gateway')}
           >
             Payment Gateway Info
           </Link>
          &nbsp; &nbsp;

          <Link
             to='/milestone-cancel-reason'
             className={`btn btn-sm ${activeTab === '/milestone-cancel-reason' ? 'btn-primary' : 'btn-outline-primary'}`}
             onClick={() => handleTabClick('/milestone-cancel-reason')}
           >
             Milestone Cancel Reason
           </Link>
          &nbsp; &nbsp;

        

         
       </div>
       <hr/>
       <div className='row'>
       <Link
             to='/app-call-log-reason'
             className={`btn btn-sm ${activeTab === '/app-call-log-reason' ? 'btn-primary' : 'btn-outline-primary'}`}
             onClick={() => handleTabClick('/app-call-log-reason')}
           >
             App Call Log Reasons
           </Link>
          &nbsp; &nbsp;

          <Link
             to='/support-services'
             className={`btn btn-sm ${activeTab === '/support-services' ? 'btn-primary' : 'btn-outline-primary'}`}
             onClick={() => handleTabClick('/support-services')}
           >
             Support Services
           </Link>
          &nbsp; &nbsp;
       </div>

        </div>
      </div>
    </Card>
  );
}

export default AppCrudHeader;
