import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import './filter.css'

function DepartmentTarget() {
 // alert();
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
 // alert(dept_id);
  const token = secureLocalStorage.getItem('token');
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [targetList, settargetlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(5);
  const [loading,setLoading] = useState(false);
  const [grouplist,setgrouplist] = useState([]);
  const [group,setgroup] = useState('');
  const [categorylist,setcategorylist] = useState([]);
  const [category,setcategory] = useState('');
  const [attribute,setattribute] = useState([]);
  const [selectedattribute,setselectedattribute] = useState('');
  const [openModal,setopenModal] = useState(false);
  const [openeditmodel,setopeneditmodel] = useState(false);
  const [department,setdepartment] = useState('')
  const [employee,setemployee] = useState([]);
  const [selectedemployee,setselectedemployee] = useState('');
  const [remark,setremark] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [value,setvalue] = useState('');
  const [assigngroup,setassigngroup] = useState('');
  const [assigncategory,setassigncategory] = useState('');
  const [assignattribute,setassignattribute] = useState('');
  const [selectedmonth,setmonth] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [financial_year,set_financialyear] = useState('');
  const [selected_financialyear,setselected_financialyear] = useState('');
  const [id,setid] = useState('');
  const [groupid,setgroupid] = useState('');
  const [categoryid,setcategoryid] = useState('')
  const [lead ,setlead] = useState('');
  const [selectedgroup,setselectedgroup] = useState('');
  const [additional,setadditional] = useState('');
  const [total,settotal] = useState('');
  const [remaining,getremaining] = useState('');
  const [assignmonth,setassignmonth] = useState('');
  const [assignyear,setassignyear] = useState('');
  const [source,setsource] = useState('');
  const [productlist,setproductlist] = useState([]);
  const [product,setproduct] = useState('');
  const [assignsource,setassignsource] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedchild_attribute,setselectedchildattribute] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const[employeedetails,setemployeedetails] = useState([]);
   const [inputData, setInputData] = useState({});
  const filteredGroups = searchText
    ? employee.filter(row => row.emp_fname.toLowerCase().includes(searchText.toLowerCase()))
    : employee;
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var startYear, endYear;
if (currentDate.getMonth() < 3) {
    startYear = currentYear - 1;
    endYear = currentYear;
} else {
    startYear = currentYear;
    endYear = currentYear + 1;
}
const curr_fyear =  startYear + " - " + endYear;
const month = currentDate.toLocaleString('default', { month: 'long' });
//alert(month);

   const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  //console.log(remaining);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-product-list';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setproductlist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(()=>{
    if(selectedchild_attribute.length>0){
       const fetchData = async () => {
       try {
          const response = await dashboardApi.get('emp-data', {
              params: {
               emp_ids:selectedchild_attribute
                //emp_id:empId,
              }
            });
          if (response.data.status === 200) {
           setemployeedetails(response.data.data)
               
          } else {
            console.error('Error fetching data:', response.data.error);
          }
          //setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
         // setLoading(false);
        }
  
    }
      fetchData();
  
  }
  
  },[selectedchild_attribute])



  
  //const [attribute,setattribute]= useState('');

  //alert(dept_id);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchStock();
    //fetchtarget();
  },[])

  useEffect(() => {
        fetchTarget(currentPage);
    }, [category, selectedgroup, selectedmonth, financial_year, additional, currentPage]);


  useEffect(() => {
    const getCurrentFinancialYear = () => {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();

      const financialYearStart = currentMonth >= 4 ? currentYear : currentYear - 1;
      const financialYearEnd = financialYearStart + 1;

      return `${financialYearStart}-${financialYearEnd}`;
    };


    const generateFinancialYears = () => {
      const currentFinancialYear = getCurrentFinancialYear();
      const nextFinancialYears = [];

      for (let i = 1; i <= 5; i++) {
        const startYear = parseInt(currentFinancialYear.split('-')[0]) + i;
        const endYear = startYear + 1;
        nextFinancialYears.push(`${startYear}-${endYear}`);
      }
      const allFinancialYears = [currentFinancialYear, ...nextFinancialYears];
      setFinancialYears(allFinancialYears);
    };

    generateFinancialYears();
  }, []);
 const fetchTarget = async (page = 0) => {
        try {
            setLoading(true);
            const response = await dashboardApi.get('get-monthly-lead-data', {
                params: {
                    per_page: postPerPage,
                    page: page + 1, 
                    category: category,
                    group: selectedgroup,
                    month: selectedmonth,
                    financial_year: financial_year,
                    additional:additional,
                }
            });
           // console.log(response);
           if(response.data.sum){
            settotal(response.data.sum);
           }
            if (response.data.status === 200) {
                const responseData = response.data.data;
                const stockdata = responseData.data || responseData;
                settargetlist(stockdata);
                setNumber(response.data.last_page);
                setLoading(false);

            } else {
                console.error('Error fetching data:', response.data.error);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };




  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchTarget(selected.selected); 
};



  const fetchStock = async (page = 0) => {
      try {
        const response = await dashboardApi.get('get-monthly-lead-data', {
            params: {
              per_page: postPerPage,
              page: page + 1,
              //emp_id:empId,
            }
          });
        if (response.data.status === 200) {
             const responseData = response.data.data;
             const stockdata = responseData.data || responseData;
             settargetlist(stockdata);
             setNumber(response.data.last_page);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'group-details';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
            setgrouplist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    if(product){
    const fetchCategories = async () => {
      try {
        const endpoint = `get-category-list-by-product-id/${product}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setcategorylist(response.data.data);
        } else {
          console.error('Error fetching categories:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }
  }, [product]);
  //alert(department);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const endpoint = 'dm-department-employees';
          const response = await dashboardApi.get(endpoint);
          console.log(response);
  
          if (response.data.status === 200) {
            setemployee(response.data.data);
          } else {
            console.error('Error fetching states data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching states data:', error);
        }
      };
  
      fetchData();
  }, [dept_id]);

  const assign_to_team = async(id,group,category,month,financial_year,source)=>{
    //alert(financial_year);
    try {
          const endpoint = `count-assign-lead-data/${id}/${group}/${category}/${source}`;
          const response = await dashboardApi.get(endpoint);
          //console.log(response);
  
          if (response.data.status === 200) {
            getremaining(response.data.data);
          } else {
            console.error('Error fetching:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    setassigngroup(group);
    setassigncategory(category);
     setassignmonth(month);
    setassignyear(financial_year);
    setsource(source);
    setopenModal(true);
    }
 const handleclose = ()=>{
    setopenModal(false);
    setassigngroup('');
    setassigncategory('');
    setValidationErrors('');
    setselectedemployee('');
    setremark('');
    setvalue('');
    setassignmonth('');
    setassignyear('');
    setsource('');
    setselectedchildattribute([]);
    setemployeedetails([]);
    setAssignedTasks([]);
   //filteredGroups([]);



 }
 //alert(year);
 const editrow   = async (group,category,id,source)=>{
    setopeneditmodel(true);
    setgroupid(group);
    setcategoryid(category);
    setid(id);
    setassignsource(source);


    try {
      const endpoint = `lead-edit/${id}/${group}/${category}`;
      const response = await dashboardApi.get(endpoint);
      console.log(response);

      if (response.data.status === 200) {
        setlead(response.data.data);
      } else {
        console.error('Error fetching:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching', error);
    }

}
const updatedata = async(e)=>{
  e.preventDefault();
  //alert();
  const formData = new FormData()
  formData.append('lead', lead)
  formData.append('source',assignsource);
  try {
    const response = await dashboardApi.post(`save-edit-lead/${id}/${groupid}/${categoryid}`, formData);
    //console.log(response);

    if (response.data.status === 200) {
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
      fetchStock();
      //setopeneditmodel(false);
      handleeditclose();
    } else if (response.data.status === 400) {
      // Display validation errors below the input fields
      setValidationErrors(response.data.messages);
    } else {
      Swal.fire({
        icon: 'error',
        text: response.data.message || 'An error occurred',
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'An error occurred',
    });
  }



}
//alert();
//alert(assignyear);
 const handlesave = async (e) => {
 // alert()
  e.preventDefault();
  const errors = {};
  setValidationErrors(errors);
 if (Object.keys(errors).length === 0) {
  const formData = new FormData()
  formData.append('emp_id',empId);
  formData.append('department',dept_id);
  formData.append('group',assigngroup);
  formData.append('category',assigncategory);
  formData.append('employee',selectedemployee);
  formData.append('remark',remark);
  formData.append('value',value);
  formData.append('year',assignyear);
  formData.append('month',assignmonth);
  formData.append('source',source);
  formData.append('emp_type','0');
  formData.append('emp_ids',JSON.stringify(assignedTasks));
  //const total_data =  JSON.stringify({assignedTasks }),

  try {
    const response = await dashboardApi.post('save-assign-target', formData);

    if (response.data.status === 200) {
      //console.log('iiii');
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
      fetchStock();
      handleclose();

    } else if (response.data.status === 400) {
      // Display validation errors below the input fields
      setValidationErrors(response.data.messages);
    }
    else if (response.data.status === 201) {
      Swal.fire({
        icon: 'error',
        text: response.data.message,
      });
    }
     else {
      Swal.fire({
        icon: 'error',
        text: response.data.message || 'An error occurred',
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'An error occurred while creating the stock',
    });
  }
};
}
const handleeditclose = ()=>{
  setlead('');
  setValidationErrors('');
  setopeneditmodel(false);
}

const assigntask = (e,id)=>{
  //alert(e);
  
  const existingIndex = assignedTasks.findIndex(task => task.emp_id === id);

   if (existingIndex !== -1) {
     const updatedTasks = [...assignedTasks];
     updatedTasks[existingIndex] = { emp_id:id, value:e };
     setAssignedTasks(updatedTasks);
   } else {
     setAssignedTasks([...assignedTasks, { emp_id:id, value:e }]);
   }

}
//console.log(assignedTasks);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <div className="row mb-2">
              <div className="col">
                <Link to="/assign-target" className="btn btn-info btn-sm">View Assign List</Link>
                </div>
                <div className="col">
                <Link to="/assign-to-groups" className="btn btn-info btn-sm">Assign Leads To Groups</Link>
                </div>

                <div className="col">
                <Link to="/target-list" className="btn btn-info btn-sm">Department Based Target</Link>
                </div>

                <div className="col">
                <Link to="/assign-emp-group" className="btn btn-info btn-sm">Assign Group To Emp</Link>
                </div>

              </div>

                <div className="row mb-3">
                <div className="col">
                     <label htmlFor="department">Product:</label>
                     <select
                    class="form-select"
                    classname="form-control"
                    value={product}
                    onChange={(e) => setproduct(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Product</option>
                    {productlist.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.product_name}
                      </option>
                    ))}
                   
                  </select>
                  </div>
                  <div className="col">
                     <label htmlFor="department">Category:</label>
                    <select
                      value={category}
                      onChange={(e) => setcategory(e.target.value)}
                      style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                    >
                      <option value="">Select Category</option>
                      {categorylist.map(row => (
                        <option key={row.id} value={row.id}>
                          {row.category_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col">
                     <label htmlFor="department">Group:</label>
                     <select
                         value={selectedgroup}
                          onChange={(e) => setselectedgroup(e.target.value)}
                            style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                            >
                            <option value="">Select Group</option>
                             {grouplist.map(row => (
                               <option key={row.group_id} value={row.group_id}>
                                {row.name}
                                  </option>
                                ))}
                              </select>

                  </div>
                  <div className="col">
                     <label htmlFor="department">Year:</label>
                      <select
                         value={financial_year}
                         onChange={(e) => set_financialyear(e.target.value)}
                         style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                        >
                        <option value ="">Select year</option>
                        {financialYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                     ))}
                  </select>
                  </div>

                   <div className="col">
                     <label htmlFor="department"> Month:</label>
                       <select id="months"
                         value={selectedmonth}
                          onChange={(e) => setmonth(e.target.value)}
                          style={{ width: '100%', padding: '8px', fontSize: '14px' }}

                          >
                            <option value ="">Select Month</option>
                             {months.map((month, index) => (
                             <option key={index} value={month}>{month}</option>
                            ))}
                         </select>

                  </div>
                </div>

                <div style={{float:'right'}}>
                </div>

                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Group</td>
                        <td>Category</td>
                        {!total &&(
                       <React.Fragment>
                          <td>Source</td>
                          <td>Lead</td>
                       </React.Fragment>
                        )}
                        {total &&(
                          <td>Total</td>
                          )}
                        <td>Month</td>
                        <td>Year</td>
                         <td>Achevied</td>
                         <td>Remaining</td>
                        {!total && dept_id==10 &&( 
                        <td>Action</td>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {targetList.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.group}</td>
                          <td>{row.category}</td>
                          {!total && (
                            <React.Fragment>
                           <td>{row.source}</td>
                           <td>{row.no_of_monthly_lead}</td>
                         </React.Fragment>
                          )}
                          {total && (
                          <td>{total}</td>
                           )}
                          <td>{row.month}</td>
                          <td>{row.financial_year}</td>
                          <td>{row.acheived}</td>
                          <td>{row.remaining}</td>
                           {dept_id==10 &&
                           <td><button className="btn btn-info btn-sm" 
                             onClick={() =>assign_to_team(row.id,row.group_id,row.category_id,row.month,row.financial_year,row.source_id)}>Assign</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                             <button className="btn btn-info btn-sm" 
                             onClick={() =>editrow(row.group_id,row.category_id,row.id,row.source_id)}>Edit</button></td>
                              }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {targetList.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}
              </div>
            </Card>
            <Dialog
        open={openModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'3200x'} }}
      >
        <DialogTitle>Assign Lead To Team</DialogTitle>
       <DialogContent>
          <form>
            <div className="container">
              <span style = {{color:'red',fontSize:'20px'}}>No Of Remaining Lead For Assign: {remaining}</span>
              <div className="row">
                 <div className="col-md-12">
      <label htmlFor="department">Select Employee:</label>
      <div style={{ position: 'relative' }}>
        <input
          type="text"
          placeholder="Employee"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClick={() => setShowAttributeDropdown(!showAttributeDropdown)}
          style={{ width: '100%', padding: '8px', fontSize: '14px' }}
        />
        {showAttributeDropdown && filteredGroups && filteredGroups.length > 0 && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              border: '1px solid #ced4da',
              borderRadius: '4px',
              maxHeight: '200px',
              overflowY: 'auto',
              zIndex: 999,
              backgroundColor: '#fff',
            }}
          >
            {filteredGroups.map(row => (
              <div key={row.emp_id} style={{ padding: '5px' }}>
                <input
                  type="checkbox"
                  id={row.emp_id}
                  value={row.emp_id}
                  checked={selectedchild_attribute.includes(row.emp_id)}
                  onChange={() => {
                    setselectedchildattribute(prevState =>
                      prevState.includes(row.emp_id)
                        ? prevState.filter(id => id !== row.emp_id)
                        : [...prevState, row.emp_id]
                    );
                  }}
                />
                <label htmlFor={row.emp_id} style={{ marginLeft: '5px' }}>{row.emp_fname} {row.emp_lame}</label>
              </div>
            ))}
          </div>
        )}
      </div>
      </div>
      </div>
      <div className="row">
                
                {employeedetails.map(row => (
                 <div className="col-md-6" key={row.emp_id}>

                 <label><h5>Assign To {row.emp_fname}</h5></label>
                 <input
                  type = "text"
                  className = "form-control"
                  onChange={(e) => assigntask(e.target.value,row.emp_id)}
                  />
                  </div>
                    
                   ))}
               
               
             </div>
             <br/>

              <div className="row">
                  <label>Remark:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={remark}
                    onChange={(e) => setremark(e.target.value)}
                    placeholder="Remark"
                  />
                  {validationErrors.remark && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                  )}
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={handleclose}>
            Close
          </button>
          <button className="btn btn-info" onClick={handlesave}>
            Save
          </button>
        </DialogActions>
       </Dialog>

       <Dialog
        open={openeditmodel}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Edit Data</DialogTitle>
        <DialogContent>
          <form>
            <div className="row">
                  <label>Number:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={lead}
                    onChange={(e) => setlead(e.target.value)}
                    placeholder="Remark"
                  />
                  {validationErrors.lead && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.lead}</div>
                  )}
              </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={handleeditclose}>
            Close
          </button>
          <button className="btn btn-info" onClick = {updatedata}>
            Save
          </button>
        </DialogActions>
       </Dialog>

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DepartmentTarget;