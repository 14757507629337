/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
//import Button from 'react-bootstrap/Button'

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import Swal from 'sweetalert2';
import { useNavigate,useParams,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function EditBranchType() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const navigate = useNavigate();
  const[branchType, setbranchType] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
   useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
useEffect(()=>{
    fetchBranchType()
  },[])
  const fetchBranchType = async () => {
    await dashboardApi.get(`edit-branch-type/${id}`).then(({data})=>{
      const {branch_type} = data.branch_type
      setbranchType(branch_type)
    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }
  const updatebranchtype = async (e) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('branch_type', branchType)
    try {
      const response =  await dashboardApi.post(`update-branch-type/${id}`, formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/branch-type-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div style={{float:'right'}}>
                <Link to='/branch-type-list' className="btn btn-info">Back</Link>

              </div>
              <div className="card-body">
                <form onSubmit={updatebranchtype}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Branch Type Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="branch-type-name"
                            placeholder="Enter Branch Type Name"
                            value = {branchType}
                            onChange={(event)=>{
                              setbranchType(event.target.value)}}
                          />
                           {validationErrors.branch_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.branch_type}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                      <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                           Update
                        </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditBranchType;
