import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";

function Addmanager() {
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate();
  const [manager, getmanager] = useState([]);
  const [managerid, getmanagerid] = useState('');
  const [newmanager, getnewmanager] = useState([]);
  const [manageremp, getmanageremp] = useState([]);
  const [newmanagerid, setnewmanagerid] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [department,getdepartment] = useState([]);
  const [selecteddepartment,setselecteddepartment] = useState('');
  const [deptemployee,setdeptemployee] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCheckboxChange = (e, empId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedEmployees((prevSelected) => [...prevSelected, empId]);
    } else {
      setSelectedEmployees((prevSelected) =>
        prevSelected.filter((id) => id !== empId)
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-all-manager';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          getmanager(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchdepartment = async () => {
      try {
        const endpoint = 'get-active-department';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          getdepartment(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchdepartment();
  }, []);

  useEffect(() => {
    if (managerid) {
      const fetchData = async () => {
        try {
          const endpoint = `get-all-manager-employee/${managerid}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            getmanageremp(response.data.data);
          } else {
            console.error('Error fetching states data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching states data:', error);
        }
      };

      fetchData();
    }
  }, [managerid]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-all-manager';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          getnewmanager(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    fetchdeptemp();
  }, [selecteddepartment]);

  const fetchdeptemp = async () => {
    try {
      //const endpoint = `leave-list`;
      //const response = await dashboardApi.get(endpoint);
      const response =  await dashboardApi.post('department-based-employee', {
       id:selecteddepartment.split(),
        
      });
      
      if (response.data.status === 200) {
        setdeptemployee(response.data.data);
      } else {
        console.error('Error fetching employee:', response.data.message);
      }
      
    } catch (error) {
      console.error('Error fetching employee:', error);
    }
  };
  const clearfields = ()=>{
    setselecteddepartment('');
    setSelectedEmployees([]);
    setnewmanagerid('');
    getmanagerid('');


  }

  const savemanager = async (e) => {
    e.preventDefault();
    if (selectedEmployees.length === 0) {
      alert('Please select employees');
      return;
    }
    if (!newmanagerid) {
      alert('Please select new manager.');
      return;
    }
    setIsSubmitting(true);
    try {
      const endpoint = 'save-emp-manager';
      const response = await dashboardApi.post(endpoint, {
        managerId: newmanagerid,
        selectedEmployees: selectedEmployees,
      });

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/reporting-info");
        clearfields();
      } else {
        Swal.fire('Error', 'Failed to save data', 'error');
      }
    } catch (error) {
      console.error('Error saving data:', error);
      Swal.fire('Error', 'Failed to save data', 'error');
    }
    finally {
      setIsSubmitting(false);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <form onSubmit={savemanager}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                       
                      <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px'}}>Select Manager</label>
                          <select className="form-control"
                           value={managerid}
                           onChange={(e) => getmanagerid(e.target.value)}
                            >
                            <option value=''>Select Manager</option>
                            {manager.map((option) => (
                             <option key={option.id} value={option.emp_id}>
                              {option.emp_fname}  {option.emp_lame}
                              </option>
                              ))}
                              
                          </select>
                        </div>
                        
                      </div>
                    </div>
                    {managerid && (
                      <div className="row">
                        <div className="col-md-12">
                          <FormControl fullWidth margin="normal">
                            <Grid container spacing={2}>
                              {manageremp.map((option) => (
                                <Grid item xs={12} sm={6} md={3} key={option.id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={selectedEmployees.includes(option.emp_id)}
                                        onChange={(e) => handleCheckboxChange(e, option.emp_id)}
                                        name={option.emp_fname}
                                      />
                                    }
                                    label={`${option.emp_fname} ${option.emp_lame}`}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </FormControl>
                        </div>
                      </div>
                    )}
                    {managerid && (
                      <div className="row">

                        <div className="col-md-4">
                        <div className="form-group"> 
                        <label htmlFor="country" style={{ fontSize: '16px'}}>Select Department</label>
                          <select
                            value={selecteddepartment}
                            onChange={(e) => setselecteddepartment(e.target.value)}
                            className="form-control"
                          >
                            <option value="">
                              Select Department
                            </option>
                            {department.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.department_name}
                              </option>
                              ))}
                          </select>
                          </div>
                        
                      </div>
                        <div className="col-md-4">
                        <div className="form-group"> 
                          
                        <label htmlFor="country" style={{ fontSize: '16px'}}>Select Manager</label>
                            <select
                              value={newmanagerid}
                              onChange={(e) => setnewmanagerid(e.target.value)}
                              className="form-control"
                            >
                              <option value="">
                                Select Manager
                              </option>
                              {deptemployee.map((option) => (
                                <option key={option.emp_id} value={option.emp_id}>
                                  {option.emp_fname} {option.emp_lame}
                                </option>
                              ))}
                            </select>
                          
                        </div>
                        </div>
                      </div>
                    )}
                    {validationErrors.newmanagerid && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.newmanagerid}</div>
                    )}
                  </div>
                  <div className="row">
                  <div className="col-md-12 text-center mt-5">
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                  </div>
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Addmanager;
