import Grid from '@mui/material/Grid';
import { useState, useEffect} from 'react';
import {useParams } from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ReportsBarChart from 'theme_layout/Charts/BarCharts/ReportsBarChart';
import ReportsLineChart from 'theme_layout/Charts/LineCharts/ReportsLineChart';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';

// Data
import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './sales_funnel_details.css';

// Icons
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PaymentIcon from '@mui/icons-material/Payment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Typography from '@mui/material/Typography';

import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";
const empId = secureLocalStorage.getItem('emp_id');
const dept_id = secureLocalStorage.getItem('department_id');

function MissingFollowup() {
  const { sales, tasks } = reportsLineChartData;
  const [product, setproduct] = useState('');
  const [productlist,setproductlist] = useState([]);
  const [servicelist, setservicelist] = useState([]);
  const [service, setservice] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [emp_list, getemp_list] = useState([]);
  const [selectedmanagers, setselectedmanagers] = useState();
  const [sales_data, getsales_data] = useState({});
  const [managersteam,getmanagersteam] = useState([]);
  const [selectedemp,setselectedemp] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const [followup_status,get_followup_details] = useState([]);
  const {details} = useParams();
  const [inner_page_details,get_inner_page_details]  = useState([]);
  const [sum,get_sum] = useState();
  const [new_amount,get_newamount] = useState('');
   const [manager_team,get_manager_team] = useState([]);
  const[checkmanager,setcheckmanager] = useState('');
 // const [renew_amount,get_renewamount] = useState('');
  //alert(details);

  useEffect(() => {
    productdetails();
   // fetchdata();
    get_emp_list();
    sales_inner_page_details();
     get_sales_manager_team();
  }, []);

   useEffect(() => {
    sales_inner_page_filter();
  }, [product, end_date, start_date, service,  selectedmanagers,selectedemp]);


   const handleDownload = async () => {
          //emp_id: empId,

    try {
      const response = await dashboardApi.get('sales-upcoming-renewal-details', {
       params: {
         download: 'csv',
         emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: service,
          manager:selectedmanagers,
          employee:selectedemp,
          type:details
        },
       responseType: 'blob',
    });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `sales_data_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }
  };

   const get_sales_manager_team = async()=>{
     try {
      const endpoint = `sales-dashboard-show-manager-team/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        get_manager_team(response.data.data);
        setcheckmanager(response.data.reporting_manager);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }




  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_emp_list = async () => {
    try {
      const endpoint = 'get-sales-managers';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getemp_list(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

  useEffect(() => {
    if (selectedmanagers) {
      setselectedemp([]);

      const fetchData = async () => {
        try {
          const endpoint = `get-all-manager-employee/${selectedmanagers}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            getmanagersteam(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [selectedmanagers]);


  const sales_inner_page_details = async () => {
    try {
      const response = await dashboardApi.get('count-sales-followup', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: service,
          manager:selectedmanagers,
          employee:selectedemp,
          type:details
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.details;
          get_inner_page_details(responseData);
          //get_renewamount(response.data.renew_amount);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const sales_inner_page_filter = async () => {
    try {
      const response = await dashboardApi.get('count-sales-followup', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: service,
          manager:selectedmanagers,
          employee:selectedemp,
          type:details
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.details;
        get_inner_page_details(responseData);
        //get_renewamount(response.data.renew_amount);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
    <div className="container-fluid mt-4">
        <div className="card">
        <div style={{float:'right'}}>
                </div>
            <div className="card-body">
                <table className="table">
                    <thead>
                        <tr>
                            <td>S.No</td>
                            <td>Client</td>
                            <td>Emp Name</td>
                            <td>Manager</td>
                            <td>Date</td>
                            <td>Emp Remark</td>
                            <td>Manager Remark</td>
                            <td>CEO Remark</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {inner_page_details.map((row, index) => (
                            <tr key={row.id}>
                                <td>{index + 1}</td>
                                <td>{row.client_name}</td>
                                <td>{row.emp_name}</td>
                                <td>{row.manager}</td>
                                <td>{row.date}</td>
                                <td><button className='btn btn-info btn-sm'>Remark</button></td>
                              
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default MissingFollowup;
