
import React, {useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import {useNavigate,useParams,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";


function EditDepartment() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams()
  const navigate = useNavigate();
  const [department, setDepartment] = useState("")
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchDepartment()
  },[])
  const fetchDepartment = async () => {
    await dashboardApi.get(`edit-department/${id}`).then(({data})=>{
      const {department_name } = data.department
      setDepartment(department_name)
    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }
  const updatedepartment = async (e) => {
    e.preventDefault();
    //alert();
    const formData = new FormData()
    formData.append('name', department)
    try {
      const response = await dashboardApi.post(`update-department/${id}`, formData);
      //console.log(response);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/department-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <Link className='btn btn-info' to='/department-list'>
                  Back
                </Link>
                <form onSubmit={updatedepartment}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Department Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="department-name"
                            placeholder="Enter Department Name"
                            value = {department}
                            onChange={(event)=>{
                              setDepartment(event.target.value)}}

                          />
                           {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                           Update
                        </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditDepartment;
