// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from '@mui/material/FormControl';
import  secureLocalStorage  from  "react-secure-storage";

import Button from "@mui/material/Button";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';

function PositionOpen() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const dept = secureLocalStorage.getItem('department_id');
  const token = secureLocalStorage.getItem('token');
  const [receivelist, setreceivelist] = useState([])
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openEmpDetails,setopenEmpDetails] = useState(false);
  const [department, setdepartment] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState('');
  const [employee,setemployee] = useState([]);
  const [selectedemployee,setselectedemployee] = useState('');
  const [opening_type,setopening_type] = useState('');
  const [title,settitle] = useState('');
  const [exprience,setexprience] = useState('');
  const [salary_range,setsalary_range] = useState('');
  const [skill,setskill] = useState('');
  const [remark,setremark] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emp_details,set_empdetails] = useState({});
  const [end_date,expected_end_date] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchData();

  },[])
    useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-department';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setdepartment(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     //setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      //setLoading(false);
    }
  };

  fetchData();
}, []);

 const fetchData = async () => {
    try {
      const endpoint = `job-request-list/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setreceivelist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
 //console.log(receivelist);

 useEffect(() => {
  if (selectedDepartmentId) {
    const fetchData = async () => {
      try {
        const endpoint = `department-employee/${dept}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setemployee(response.data.data);
           //setLoading(false);
        } else {
          console.error('Error fetching states data:', response.data.message);
        }
        //setLoading(false);
      } catch (error) {
        console.error('Error fetching states data:', error);
        //setLoading(false);
      }
    };

    fetchData();
  }
}, [selectedDepartmentId]);

useEffect(() => {
  if (selectedemployee) {
    const fetchData = async () => {
      try {
        const endpoint = `emp-details/${selectedemployee}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          set_empdetails(response.data.data);
           //setLoading(false);
        } else {
          console.error('Error fetching states data:', response.data.message);
        }
        //setLoading(false);
      } catch (error) {
        console.error('Error fetching states data:', error);
        //setLoading(false);
      }
    };

    fetchData();
  }
}, [selectedemployee]);
//console.log(emp_details);


const handleOpenModal = async () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    settitle('');
    setopening_type('');
    setskill('');
    setremark('');
    settitle('');
    setsalary_range('');
    setSelectedDepartmentId('');
    setexprience('');
    setValidationErrors('');
    setselectedemployee('');
    setOpenModal(false);
   // se
  };
  const handlesave = async (e) => {
    e.preventDefault();
    const errors = {};
    //setValidationErrors(errors);

    //if (Object.keys(errors).length > 0) {
     // return; 
    //}

    const formData = new FormData();
    formData.append('title',title);
    formData.append('department_from',selectedDepartmentId);
    formData.append('opening_type',opening_type);
    formData.append('replace_to',selectedemployee);
    formData.append('required_exprience',exprience);
    formData.append('salary',salary_range);
    formData.append('skill',skill);
    formData.append('remark',remark);
    formData.append('emp_id',emp_id);
    formData.append('end_date',end_date);

    setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-job-open-request', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         fetchData();
        handleCloseModal();
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const empdetails = async (e) => {
    e.preventDefault();
    setopenEmpDetails(true);

  }
  const CloseEmpDetails = async()=>{
    setopenEmpDetails(false);
  }
  const closestatus = async (id)=>{
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are You Want To Close Request!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!'
    }).then((result) => {
      return result.isConfirmed;
    });
  
    if (!isConfirm) {
      return;
    }
  
      try {
        const endpoint = `close-job-request/${id}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
           })
           //setLoading(false);
        } else {
          console.error('Error fetching states data:', response.data.message);
        }
        //setLoading(false);
      } catch (error) {
        console.error('Error fetching states data:', error);
        //setLoading(false);
      }

   

  }

//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <Link className='btn btn-primary mb-2 float-end' onClick={handleOpenModal}>
                   +
                  </Link>
                   {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Department From</td>
                  <td>Opening Type</td>
                  <td>Skill</td>
                  <td>Title</td>
                  <td>Approved Status</td>
                  <td>Action</td>
               </tr>
              {receivelist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.department_name}</td>
                  <td>{row.opening_type}</td>
                  <td>{row.skill}</td>
                  <td>{row.title}</td>
                  <td>{row.status === 1 ?
                   'Approved' : row.status === 2 ? 'Rejected' : row.status === 3 ? 'Closed': 'Pending'}</td>
                   {
                    row.close_status ==0 && 
                   
                   <td>
                    
                   <Link to = {`/view-description/${row.id}`} className="btn btn-info btn-sm">View</Link>&nbsp;

                   <button onClick={() => closestatus(row.id)} className="btn btn-danger btn-sm">Closed</button>&nbsp;

                   </td>
                  }
                  {
                    row.close_status ==1 && 
                   
                   <td>

                   <button  className="btn btn-danger btn-sm">Closed</button>&nbsp;
                   <Link to = {`/view-description/${row.id}`} className="btn btn-info btn-sm">View</Link>&nbsp;

                   </td>
                  }



                  </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
       <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth='md'
        PaperProps={{ style: { maxHeight: '80vh', padding: '20px' } }}
      >
        <DialogTitle>Send Request</DialogTitle>
        <DialogContent>
          <form>
            <div class="container">
            <div class = "row">
            <div class = "col-md-6">
              <label>Select Department:</label>
              <select className="form-control"
                             value={selectedDepartmentId}
                             onChange={(e) => setSelectedDepartmentId(e.target.value)}
                              >
                            <option value=''>Select Department</option>
                             {department.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.department_name}
                              </option>
                              ))}
                          </select>
                          {validationErrors.department_from && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department_from}</div>
                             )}
                       </div>
              <div className="col-md-6">
             <label>Exprience:</label>
              <input
                type="text"
                 value={exprience}
                 onChange={(e) => setexprience(e.target.value)}
                 placeholder = "exprience"
                 className='form-control'
              />
               {validationErrors.required_exprience && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.required_exprience}</div>
                             )}
            </div>

            </div>
            <br/>

            <div class = "row">
            <div class = "col-md-6">
              <label>Select Opening Type:</label>
              <select className="form-control"
                             value={opening_type}
                             onChange={(e) => setopening_type(e.target.value)}
                              >
                            <option value=''>Select Opening Type</option>
                            <option value='new'>NEW</option>
                            <option value='replace'>Replace</option>
                          </select>
                           {validationErrors.opening_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.opening_type}</div>
                             )}
                       </div>
             {opening_type ==='replace'&&(
              <div className="col-md-6">
             <label>Select Employee:</label>
              <select className="form-control"
                             value={selectedemployee}
                             onChange={(e) => setselectedemployee(e.target.value)}
                              >
                            <option value=''>Select Employee</option>
                             {employee.map((option) => (
                             <option key={option.emp_id} value={option.emp_id}>
                              {option.emp_fname}
                              </option>
                              ))}
                          </select>
                          <br/>
                          {selectedemployee&&(
                          <button className = "btn btn-info btn-sm" onClick={empdetails}>View More</button>
                          )}
            </div>
          )}

            </div>
            <br/>
            <div class = "row">
            <div class = "col-md-6">
              <label>Required Skill:</label>
              <input
               type = "text"
               fullWidth
               placeholder = "required Skill"
               className='form-control'
               value={skill}
                  onChange={(e) => setskill(e.target.value)}
                 />
                 {validationErrors.skill && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.skill}</div>
                             )}
              </div>
              <div className="col-md-6">
             <label>Salary Range:</label>
              <input
               type = "text"
               
               placeholder = "Salary Range"
               className='form-control'
               value={salary_range}
                  onChange={(e) => setsalary_range(e.target.value)}
                 />
                  {validationErrors.salary && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.salary}</div>
                             )}
            </div>

            </div>
            <br/>
             <label>Job Title:</label>
              <input
               type = "text"
               
               placeholder = "Title"
               className='form-control'
               value={title}
                  onChange={(e) => settitle(e.target.value)}
                />
                {validationErrors.title && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.title}</div>
                )}
              <br/>

               <div className="row">
            <div className="form-group col-sm-6">
            <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Reason</label>
            <textarea rows={5} cols={5} style={{height:'50px'}}
            value={remark}
            onChange={(e) => setremark(e.target.value)}
            className = "form-control"
            placeholder="Reason"
           />
           {validationErrors.remark && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                             )}
            </div>
            <div className="form-group col-sm-6">
            <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Expected End date</label>
            <input type ='date'
             className='form-control'
             value={end_date}
             onChange={(e) => expected_end_date(e.target.value)}
            
            />
              

            </div>
            </div>


            </div>

            

          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick = {handlesave}>
            Save
          </Link>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEmpDetails}
        onClose={CloseEmpDetails}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Emp Details</DialogTitle>
        <DialogContent>
          <table className="table">
            <tr>
              <th>Emp Name:{emp_details.emp_name}</th>
              <th>Department:{emp_details.emp_department}</th>
            </tr>
            <tr>
            <th>Designation:{emp_details.emp_designation}</th>
            <th>Branch:{emp_details.emp_branch}</th>
           </tr>
            <tr>
              <th>CTC:{emp_details.emp_package}</th>
              <th>Date of Join:{emp_details.doj}</th>
            </tr>

          </table>
         
        </DialogContent>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default PositionOpen;