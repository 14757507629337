import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function AddDesignation() {
  const navigate = useNavigate();
  const token = secureLocalStorage.getItem('token');
  const [options, setOptions] = useState([]);
  const [selectedDepartment, setselectedDepartment] = useState('');
  const [designationName, setDesignationName] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

   useEffect(() => {
   const fetchData = async () => {
    try {
      const endpoint = 'department-list'; 
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
       setOptions(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
  const savedesignation = async (e) => {
    e.preventDefault();
    //alert();
    const formData = new FormData()
    formData.append('name', designationName)
    formData.append('department', selectedDepartment)
     setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('save-designation', formData);
      //console.log(response);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/designation-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the designation',
      });
    }
    finally {
        setIsSubmitting(false);
      }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <Link className='btn btn-primary mb-2 float-end' to={"/designation-list"}>
                    Back
                </Link>
                <form onSubmit={savedesignation}>
                  <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Select Department</label>
                          <select className="form-control"
                           value = {selectedDepartment}
                           onChange={(e) => setselectedDepartment(e.target.value)}

                           >
                            <option>Select Department</option>
                            {options.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.department_name}
                              </option>
                              ))}
                          </select>
                          {validationErrors.department && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Designation Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="designation-name"
                            value = {designationName}
                            onChange={(e) => setDesignationName(e.target.value)}
                            placeholder="Enter Designation Name"
                          />
                          {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddDesignation;
