import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import StockDetailsHeader from "layouts/common_components/stock_details_header";

function RaiseAssetRequest() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [requestList, setRequestList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [editmodal,seteditmodal] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [remark, setRemark] = useState('');
  const [brandName, setBrandName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [id,setid] = useState('');
  const[activebranch,getactivebranch] = useState([]);
  const[selectedbranch,setselectedbranch] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchStock();

  },[])

    const fetchStock = async () => {
      try {
        const endpoint = 'raise-asset-request-list';
        const response = await dashboardApi.get(endpoint);
        if (response.data.status === 200) {
          setRequestList(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'active-category';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setCategoryList(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const branchlist = async () => {
    try {
      const endpoint = 'get-active-branch';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getactivebranch(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  branchlist();
}, []);

  const openModalHandler = () => {
    setOpenModal(true);
  }

  const closeModalHandler = () => {
    setSelectedCategory('');
    setValidationErrors('');
    setRemark('');
    setBrandName('');
    setQuantity('');
    setOpenModal(false);
  }

  const saveHandler = async(e) => {
    e.preventDefault();
    const errors = {};
    const formData = new FormData();
    formData.append('category',selectedCategory);
    formData.append('brand_name',brandName);
    formData.append('quantity',quantity);
    formData.append('emp_id',empId);
    formData.append('remark',remark);
    formData.append('branch',selectedbranch);


    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('raise-asset-request', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchStock();
        closeModalHandler();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }
  }

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  }
  const openeditmodal = async(id)=>{
    setid(id)
;
     try {
        const endpoint = `edit-raise-asset-request/${id}`;
        const response = await dashboardApi.get(endpoint);
       if (response.data.status === 200) {
        //console.log(response);
          //alert(response.data.data[0].approval_remark);
      //const assignToValues = response.data.data.map(item => item.assign_to);
      setRemark(response.data.data.admin_remark);
      setSelectedCategory(response.data.data.category_id);
      setBrandName(response.data.data.brand_name);
      setQuantity(response.data.data.quantity);
      setselectedbranch(response.data.data.branch_id)
      //setselectedemployee(assignToValues);
        } else {
          console.error('Error fetching data:');
        }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    seteditmodal(true);



   // alert(id)
;

  }
  const closeeditmodal=()=>{
  setSelectedCategory('');
  setValidationErrors('');
  setRemark('');
  setBrandName('');
  setQuantity('');
  seteditmodal(false);

  }
  const updateHandler = async(e)=>{
    e.preventDefault();
    const errors = {};
    const formData = new FormData();
    formData.append('category',selectedCategory);
    formData.append('brand_name',brandName);
    formData.append('quantity',quantity);
    formData.append('emp_id',empId);
    formData.append('remark',remark);
    formData.append('branch',selectedbranch);

    //setIsSubmitting(true);

    try {
     const response =  await dashboardApi.post(`update-raise-asset-request/${id}`, formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchStock();
        closeeditmodal();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }

    


  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <StockDetailsHeader/>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={openModalHandler}>
                  +
                </Link>
                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Category</td>
                        <td>Brand Name</td>
                        <td>Branch</td>
                        <td>Quantity</td>
                        <td>Status</td>
                        <td>Admin Remark</td>
                        <td>Finance Remark</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {requestList.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.category_name}</td>
                          <td>{row.brand_name}</td>
                          <td>{row.branch_name}</td>
                          <td>{row.quantity}</td>
                          <td>
                            {row.status === 1 && "Approved"}
                            {row.status === 2 && "Rejected"}
                            {row.status === 0 && "Pending"}
                          </td>
                          <td>{row.admin_remark}</td>
                          <td>{row.finance_remark}</td>
                          <td><button className="btn btn-info"  onClick={() => openeditmodal(row.id)}>Edit</button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        onClose={closeModalHandler}
       
         maxWidth="md"
        PaperProps={{ style: {padding: '40px', maxHeight: '2500px'} }}
      >
        <DialogTitle>Send Request</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <label>Select Category:</label>
                  <select
                    className="form-control"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value=''>Select Category</option>
                    {categoryList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.category_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.category && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.category}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Brand Name:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                    placeholder="Brand Name"
                  />
                  {validationErrors.brand_name && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.brand_name}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>Quantity:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    placeholder="Quantity"
                  />
                  {validationErrors.quantity && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.quantity}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Remark:</label>
                  <TextField
                    type="text"
                    fullWidth
                    placeholder="Remark"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                  {validationErrors.remark && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                  )}
                </div>
              </div>
              <div className='row'>
              <div className='col-md-6'>
              <label>Branch:</label>
              <select
                    className="form-control"
                    value={selectedbranch}
                    onChange={(e) => setselectedbranch(e.target.value)}
                  >
                    <option value=''>Select Branch</option>
                    {activebranch.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.branch && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.branch}</div>
                  )}

              </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closeModalHandler}>
            Close
          </button>
          <button className="btn btn-info" onClick={saveHandler}>
            Save
          </button>
        </DialogActions>
      </Dialog>

       <Dialog
        open={editmodal}
        onClose={closeeditmodal}
        fullWidth
         maxWidth="md"
        PaperProps={{ style: {padding: '40px', maxHeight: '2500px'} }}
      >
        <DialogTitle>Send Request</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <label>Select Category:</label>
                  <select
                    className="form-control"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value=''>Select Category</option>
                    {categoryList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.category_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.category && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.category}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Brand Name:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                    placeholder="Brand Name"
                  />
                   {validationErrors.brand_name && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.brand_name}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>Quantity:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    placeholder="Quantity"
                  />
                  {validationErrors.quantity && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.quantity}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Remark:</label>
                  <TextField
                    type="text"
                    fullWidth
                    placeholder="Remark"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                  {validationErrors.remark && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                  )}
                </div>
              </div>
              <div className='row'>
              <div className='col-md-6'>
              <label>Branch:</label>
              <select
                    className="form-control"
                    value={selectedbranch}
                    onChange={(e) => setselectedbranch(e.target.value)}
                  >
                    <option value=''>Select Branch</option>
                    {activebranch.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.branch && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.branch}</div>
                  )}

              </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closeeditmodal}>
            Close
          </button>
          <button className="btn btn-info" onClick={updateHandler}>
            Update
          </button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default RaiseAssetRequest;