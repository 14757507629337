import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import  secureLocalStorage  from  "react-secure-storage";
import JobpositionRequestHR from "layouts/postion_opening/job_opening_list_hr";
import AddFollowup from "layouts/postion_opening/add-followup";
//const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
//const permission = localStorage.getItem('permission');

const Candidate_followup = [
    {
    route: "/position-request",
    component: (token!=null) ? <JobpositionRequestHR/> : <SignIn/>,
    },
    {
        route: "/followup-details",
        component: (token!=null) ? <AddFollowup/> : <SignIn/>,
        },
 
];

export default Candidate_followup;