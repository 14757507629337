import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import "layouts/superadmin/components/global.css";

import { useNavigate, useParams } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import CalanderLeave from 'layouts/yearly_leave';
import EmpBirthday from 'layouts/emp_birthday';

function BirthdayAndHolidays() {
 const navigate = useNavigate();
  const token = secureLocalStorage.getItem('token');
  if(!token){
    navigate('/sign-in');
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <CalanderLeave />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
               <EmpBirthday />
            </Grid>
          </Grid>
        </MDBox>
            
              
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default  BirthdayAndHolidays;