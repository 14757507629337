// @mui material components
import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import  secureLocalStorage  from  "react-secure-storage";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
//import { secureLocalStorage } from "react-secure-storage";
import { Link, useNavigate, useParams } from 'react-router-dom';


// Data
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";

function JobDescription() {
  const { id } = useParams();
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [receivelist, setReceivelist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState([]);
  const [remark, setRemark] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState(null);
  const [resource, setResource] = useState('');
  const [position, setPosition] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [replaceEmp, setReplaceEmp] = useState({});
  const [followupDetails, setFollowupDetails] = useState([]);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchData();
    fetchDetails();
  }, []);

  const fetchData = async () => {
    try {
      const endpoint = `candidate-list/${id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setReceivelist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchDetails = async () => {
    try {
      const endpoint = `job-position-details/${id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setPosition(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (position.replace_to) {
      const fetchReplaceEmp = async () => {
        try {
          const endpoint = `emp-details/${position.replace_to}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setReplaceEmp(response.data.data);
          } else {
            console.error('Error fetching replace employee data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching replace employee data:', error);
        }
      };

      fetchReplaceEmp();
    }
  }, [position.replace_to]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setRemark('');
    setValidationErrors('');
    setOpenModal(false);
    setEmail('');
    setMobile('');
    setName('');
    setImage('');
    setResource('');
    setStatus('');
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const errors = {};
    const formData = new FormData();
    formData.append('name', name);
    formData.append('remark', remark);
    formData.append('job_id', id);
    formData.append('emp_id', emp_id);
    formData.append('mobile', mobile);
    formData.append('email', email);
    formData.append('image', image);
    formData.append('status', status);
    formData.append('resource', resource);

    try {
      const response = await dashboardApi.post('save-followup-details', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchData();
        handleCloseModal();
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    }
  };

  useEffect(() => {
    const fetchFollowupDetails = async () => {
      try {
        const endpoint = 'active-followup-attribute';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setFollowupDetails(response.data.data);
        } else {
          console.error('Error fetching follow-up details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching follow-up details:', error);
      }
    };

    fetchFollowupDetails();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-8">
                    <Typography variant="h4" align="center">Job Description</Typography>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td><strong>Department:</strong> {position.department_name}</td>
                          <td><strong>Required Experience:</strong> {position.exprience}</td>
                        </tr>
                        <tr>
                          <td><strong>Salary Range:</strong> {position.salary}</td>
                          <td><strong>Skill:</strong> {position.skill}</td>
                        </tr>
                        <tr>
                          <td><strong>Position Opening Date:</strong> {position.date}</td>
                          <td><strong>Title:</strong> {position.title}</td>
                        </tr>
                          <tr>
                          <td style={{maxWidth:'12px'}}><strong>Remark:</strong> {position.remark}</td>
                          <td style={{maxWidth:'12px'}}><strong>Branch:</strong> {position.branch}</td>
                        </tr>
                        <tr>
                          <td style={{maxWidth:'12px'}}><strong>Assigned Person:</strong> {position.assign_member}</td>
                          <td style={{maxWidth:'12px'}}><strong>Expected End Date For This Position :</strong> {position.end_date}</td>
                        </tr>
                        <tr>
                        <td style={{maxWidth:'12px'}}><strong>Close Date:</strong> {position.close_date}</td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <Typography variant="h4" align="center">Replacement</Typography>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td><strong>Emp Name:</strong> {replaceEmp.emp_name}</td>
                          <td><strong>Department:</strong> {replaceEmp.emp_department}</td>
                        </tr>
                        <tr>
                          <td><strong>Designation:</strong> {replaceEmp.emp_designation}</td>
                          <td><strong>Branch:</strong> {replaceEmp.emp_branch}</td>
                        </tr>
                        <tr>
                          <td><strong>CTC:</strong> {replaceEmp.emp_package}</td>
                          <td><strong>Date of Join:</strong> {replaceEmp.doj}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {loading ? (
                  <Typography>Loading...</Typography>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Name</td>
                        <td>Mobile</td>
                        <td>Email</td>
                        <td>CV</td>
                        <td>Followup Status</td>
                        <td>Resource From</td>
                        <td>Remark</td>
                        <td>Final Status</td>
                        <td>Created By</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {receivelist.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.candidate_name}</td>
                          <td>{row.mobile}</td>
                          <td>{row.email}</td>
                          <td>
                            <a href={`http://192.168.1.253:4000/candidate_followup/${row.resume}`} download="cv">
                              <FontAwesomeIcon icon={faDownload} /> Download CV
                            </a>
                          </td>
                          <td>{row.name}</td>
                          <td>{row.resource_from}</td>
                          <td>{row.hr_remark}</td>
                          <td>{row.final_status}</td>
                          <td>{row.emp_fname}</td>
                          <td>
                            <Link to={`/candidate-profile/${row.id}/${id}`} className="btn btn-info">Profile</Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}
      >
        <DialogTitle>Add Followup</DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Candidate Name"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={!!validationErrors.name}
                  helperText={validationErrors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Mobile"
                  fullWidth
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Resource From"
                  fullWidth
                  value={resource}
                  onChange={(e) => setResource(e.target.value)}
                  error={!!validationErrors.resource}
                  helperText={validationErrors.resource}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="file"
                  fullWidth
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  label="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  SelectProps={{ native: true }}
                  fullWidth
                  error={!!validationErrors.status}
                  helperText={validationErrors.status}
                >
                  <option value="">Change Status</option>
                  {followupDetails.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Remark"
                  fullWidth
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  error={!!validationErrors.remark}
                  helperText={validationErrors.remark}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Close
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default JobDescription;
