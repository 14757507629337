import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate, useParams,Link } from 'react-router-dom';
import EmployeeDetailsHeader from "layouts/common_components/emp_details_header";
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";

function ModulePermission() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState('');
  const [module, setModule] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [submitted ,setsubmitted] = useState([]);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'department-list';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setOptions(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedDepartments) {
      const fetchModule = async () => {
        try {
          const response = await dashboardApi.post('department-based-module', {
            department: selectedDepartments,
            per_page: 50,
            page: currentPage + 1,
          });

          if (response.data.status === 200) {
            const responseData = response.data.data;
            const employeeData = responseData.data || responseData;
            setModule(employeeData);
            setNumber(response.data.last_page);
          } else {
            console.error("Error fetching employee:", response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching employee:", error);
          setLoading(false);
        }
      };

      fetchModule();
    }
  }, [selectedDepartments, currentPage]);
   useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = `submitted-module/${id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        const submittedModules = response.data.data;
        setsubmitted(submittedModules);

        const submittedModuleIds = Array.from(
          new Set(submittedModules.map((module) => module.module_name))
        );

        setSelectedCheckboxes(submittedModuleIds);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [id]);

//console.log(selectedCheckboxes);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };

  const handleCheckboxChange = (moduleId) => {
  setSelectedCheckboxes((prevCheckboxes) => {
    const updatedCheckboxes = [...prevCheckboxes];
    const index = updatedCheckboxes.indexOf(moduleId);
    if (index === -1) {
      updatedCheckboxes.push(moduleId);
    } else {
      updatedCheckboxes.splice(index, 1);
    }
    return updatedCheckboxes;
  });
};

  const handleSave = async () => {
    // Send the selected checkboxes data to the server for saving
    try {
      const response = await dashboardApi.post('save-permission', {
        modules: selectedCheckboxes,
        emp_id:id,
      });

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate('/employee-list');
      } 
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while saving',
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EmployeeDetailsHeader id={id} />
            <Card>
            <div style={{width:'30px'}}>
            <Link className='btn btn-primary mb-2 float-end' to={"/employee-list"}>
                  Back
                </Link>
           </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="selectedDepartments" style={{ fontSize: '16px' }}>Select Departments</label>
                      <select
                        className="form-control"
                        value={selectedDepartments}
                        onChange={(e) => setSelectedDepartments(e.target.value)}
                      >
                        <option>Select department</option>
                        {options.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.department_name}
                          </option>
                        ))}
                      </select>
                      {validationErrors.department && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    {module && module.map((modules) => (
                      <div key={modules.id} className="col-md-4">
                        <input
                          type="checkbox"
                          id={`moduleCheckbox${modules.id}`}
                          checked={selectedCheckboxes.includes(modules.id)}
                          onChange={() => handleCheckboxChange(modules.id)}
                        />
                        &nbsp;
                        <label htmlFor={`moduleCheckbox${modules.id}`}>{modules.module}</label>
                      </div>
                    ))}
                  </div>
                  <button className="btn btn-info" onClick={handleSave}>
                    Save Module Permissions
                  </button>
                </div>
                <Pagination className="custom-pagination">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={number}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link-style"}
                    breakLinkClassName={"break-link-style"}
                    previousLinkClassName={"prev-link-style"}
                    nextLinkClassName={"next-link-style"}
                  />
                </Pagination>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ModulePermission;
