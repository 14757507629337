import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import FinanceDetailsHeader from 'layouts/common_components/finance_details_header';
import Button from 'react-bootstrap/Button';
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import { parseISO, isBefore, format } from 'date-fns';

function LedgerDetails() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  
  const [ledgerReports, setLedgerReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [postPerPage] = useState(10);
  const [selectFromDate, setFilterDateFrom] = useState('');
  const [selectToDate, setFilterDateTo] = useState('');
  const [dateError, setDateError] = useState('');
  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState(1);
  
  const [totalCreditAmount, setTotalCreditAmount] = useState(0);
  const [totalDebitAmount, setTotalDebitAmount] = useState(0);
  const [totalGSTAmount, setTotalGSTAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [carryForwardAmount, setCarryForwardAmount] = useState(0);

  useEffect(() => {
    getLedgerReports();
  }, []);

  const handleFromDateChange = (e) => {
    setFilterDateFrom(e.target.value);
    validateDates(e.target.value, selectToDate);
  };

  const handleToDateChange = (e) => {
    setFilterDateTo(e.target.value);
    validateDates(selectFromDate, e.target.value);
  };

  const validateDates = (from, to) => {
    if (from && to) {
      const fromDateObj = parseISO(from);
      const toDateObj = parseISO(to);

      if (isBefore(toDateObj, fromDateObj)) {
        setDateError('To date cannot be before from date.');
        setFilterDateTo('');
      } else {
        setDateError('');
      }
    }
  };

  const getLedgerReports = async () => {
    try {
      const response = await dashboardApi.get(`get-ledger-details-reports`, {
        params: {
          emp_id,
          from_date: selectFromDate,
          to_date: selectToDate,
          per_page: postPerPage,
        }
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const exeData = responseData.data || responseData;
        setLedgerReports(exeData);

        // Calculate totals
        let creditTotal = 0;
        let debitTotal = 0;
        let gstTotal = 0;
        let runningBalance = carryForwardAmount || 0;

        exeData.forEach((item) => {
          const amount = parseFloat(item.invoice_amount);
          const gst = parseFloat(item.gst_amount);
          
          if (item.type === 'Credit') {
            creditTotal += amount;
            runningBalance += amount;
          } else if (item.type === 'Debit') {
            debitTotal += amount;
            runningBalance -= amount;
          }
          gstTotal += gst;
        });

        // Handling negative balance and ensuring totals are numbers
        setTotalCreditAmount(creditTotal > 0 ? creditTotal : 0);
        setTotalDebitAmount(debitTotal > 0 ? debitTotal : 0);
        setTotalGSTAmount(gstTotal > 0 ? gstTotal : 0);
        setBalance(runningBalance > 0 ? runningBalance : '-');
        setCarryForwardAmount(runningBalance > 0 ? runningBalance : '-');
      } else {
        console.error('Error fetching payment reports:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching payment reports:', error);
      setLoading(false);
    }
  }

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  const determineTransactionType = (type) => {
    return type === "Credit" ? "Credit" : "Debit";
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <FinanceDetailsHeader />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="fromDate">From Date:</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="form-control"
                      onChange={handleFromDateChange}
                      value={selectFromDate}
                    />
                  </div>

                  <div className="col-sm-2">
                    <label htmlFor="toDate">To Date:</label>
                    <input
                      type="date"
                      id="toDate"
                      className="form-control"
                      onChange={handleToDateChange}
                      value={selectToDate}
                    />
                    {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                  </div>

                  <div className="col-sm-2">
                    <br />
                    <Button className="btn btn-primary" onClick={getLedgerReports}>Search</Button>
                  </div>
                </div>

                {loading ? (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }} alt="Loading..." />
                  </div>
                ) : (
                  <div style={{ overflowY: "hidden", marginTop: '20px' }}>
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Company Name</th>
                          <th>Voucher No</th>
                          <th>Particulars</th>
                          <th>Transaction Type</th>
                          <th>Credit Amount</th>
                          <th>Debit Amount</th>
                          <th>Balance</th>
                          <th>Created Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ledgerReports && ledgerReports.map((lr, index) => {
                          const transactionType = determineTransactionType(lr.type);
                          let rowBalance = balance;
                          
                          if (transactionType === 'Credit') {
                            rowBalance += parseFloat(lr.invoice_amount);
                          } else if (transactionType === 'Debit') {
                            rowBalance -= parseFloat(lr.invoice_amount);
                          }

                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{lr.business_name}</td>
                              <td>{lr.voucher_no}</td>
                              <td>{lr.particulars}</td>
                              <td>{transactionType}</td>
                              <td>{transactionType === 'Credit' ? lr.invoice_amount : ''}</td>
                              <td>{transactionType === 'Debit' ? lr.invoice_amount : ''}</td>
                              <td>{rowBalance > 0 ? rowBalance : '-'}</td>
                              <td>{format(parseISO(lr.approved_date), 'yyyy-MM-dd')}</td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td colSpan="5"><strong>Total</strong></td>
                          <td><strong>{totalCreditAmount > 0 ? totalCreditAmount : '-'}</strong></td>
                          <td><strong>{totalDebitAmount > 0 ? totalDebitAmount : '-'}</strong></td>
                          <td colSpan="2"><strong>Carry Forward: {carryForwardAmount > 0 ? carryForwardAmount : '-'}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LedgerDetails;
