import React, { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import Swal from 'sweetalert2';
import { useNavigate,Link } from 'react-router-dom'
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";


function AddLeaveType() {
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate();
  const [leave, setleave] = useState("");
  const [days, setdays] = useState("");
  const [atAtime, setatAtime] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checked,setchecked] = useState('');
  const [checkedprobation,setcheckedprobation] = useState('');
  //const [checkedquartly,setcheckedquartly] = useState('');
  const [checkedconfirmation,setcheckedconfirmation] = useState('');
 //alert(checked);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  const saveleave = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!leave) {
      errors.leave = 'Leave is required';
    }
    if (!atAtime) {
      errors.atAtime = 'At A Time is required';
    }
    if (!days) {
      errors.days = 'Days is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('leave', leave);
    formData.append('days', days);
    formData.append('atAtime', atAtime);
    formData.append('is_checked', checked ? 1 : 0);
    formData.append('probation_leave',checkedprobation ? 1 : 0);
    formData.append('confirm_leave',checkedconfirmation ? 1 : 0);
    setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('create-leave-type', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/leave-type-list");
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
    finally {
        setIsSubmitting(false);
      }
  };
}


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div style={{float:'right'}}>
               <Link to='/leave-type-list' className='btn btn-info'>Back</Link>

              </div>
              <div className="card-body">
                <form onSubmit={saveleave}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Leave</label>
                          <input
                            type="text"
                            className="form-control"
                            value = {leave}
                            onChange={(event)=>{
                              setleave(event.target.value)}}
                            placeholder="Enter Leave Type"
                          />
                           {validationErrors.leave && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.leave}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>No Of Leave Days(In Year)</label>
                          <input
                            type="text"
                            className="form-control"
                            value = {days}
                            onChange={(event)=>{
                              setdays(event.target.value)}}
                            placeholder="Enter Days"
                          />
                           {validationErrors.days && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.days}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>At A Month How MUch Leave Can Be Apply </label>
                          <input
                            type="text"
                            className="form-control"
                            value = {atAtime}
                            onChange={(event)=>{
                              setatAtime(event.target.value)}}
                             placeholder="Enter Days"
                          />
                           {validationErrors.atAtime && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.atAtime}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Assign Leave on Emp Request</label>
                          <input
                            type="checkbox"
                            className="form-control"
                             checked={checked}
                             onChange={(e) => setchecked(e.target.checked)}
                           />
                          
                        </div>
                      </div>
                     
                        <div className="col-md-3 form-group">
                        <label htmlFor="first name" style={{ fontSize: '16px' }}>Is Probation Period Leave</label>
                          <input
                            type="checkbox"
                            className="form-control"
                             checked={checkedprobation}
                             onChange={(e) => setcheckedprobation(e.target.checked)}
                           />
                      </div>


                        <div className="col-md-3 form-group">
                        <label htmlFor="first name" style={{ fontSize: '16px' }}>Is confirmation leave</label>
                          <input
                            type="checkbox"
                            className="form-control"
                             checked={checkedconfirmation}
                             onChange={(e) => setcheckedconfirmation(e.target.checked)}
                           />
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddLeaveType;
