// @mui material components
import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate, useParams } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TicketRaiseHeader from 'layouts/common_components/ticket_raise_header';


function ViewTicketStatus() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const [loading, setLoading] = useState(true);
  const [ticketstatus, setticketstatus] = useState([]);
  const [ticketdetails, getticketdetails] = useState({});
  const [assign_details,setassign_details] = useState();
  const[solve_status,setsolve_status] = useState([]);
  const[status,selectedstatus] = useState('');
  const[remark,setremark] = useState('');
  const [openModal,setopenModal] = useState(false);
  const[validationErrors,setvalidationErrors] = useState({})
  const [openstatusmodal,setopenstatusmodal] = useState(false);
  const [ids,setids] = useState('');
  const [ticket_id,setticketid]  = useState('');
  const [assign_array,setassign_array] = useState('');
  const [assign_from_array,setassign_from_array] = useState('');
  const [raise_by,setraise_by] = useState('');
  const [product,setproduct] = useState('');
  const [ticket_raise_by,set_ticket_raise_by] = useState('');
  const [new_time,setnew_time] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(()=>{
    fetchDatas();


  },[id])

    const fetchDatas = async () => {
  try {
    const params = {
      emp_id: emp_id
    };
    const endpoint = `view-ticket-status/${id}`;
    
    // Pass the params object as the second argument
    const response = await dashboardApi.get(endpoint, { params });

    if (response.data.status === 200) {
      setticketstatus(response.data.data);
    } else {
      console.error('Error fetching data:');
    }
    setLoading(false);
  } catch (error) {
    console.error('Error fetching data:', error);
    setLoading(false);
  }
};


  const  handleCloseModal = ()=>{
    selectedstatus('');
    setopenModal(false);
    setremark('')
    setvalidationErrors({});
    setopenstatusmodal(false);
    setids('');
    setticketid('');
    fetchDatas();
    //fetchData();

  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `get-ticket-details/${id}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          getticketdetails(response.data.data);
           setassign_details(response.data.assign)
           const assignArray = response.data.assign_array.split(',');
           const assign_from =  response.data.assign_from.split(',');
           setassign_array(assignArray);
           setassign_from_array(assign_from);
           setraise_by(response.data.raise_by);
           setproduct(response.data.product)
           set_ticket_raise_by(response.data.ticket_raise_by)
           //setproduct(response.data.product);
           setnew_time(response.data.new_time);
        } else {
          console.error('Error fetching data:');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);
  const empExists = assign_array.includes(emp_id.toString());
  const assignexists = assign_from_array.includes(emp_id.toString());
  //alert(raise_by);

  const handlesubmit = async() =>{
    const errors = {};
    if(!status){
      errors.status = 'Status is required'
    }
    setvalidationErrors(errors);
   // console.log(selectedOptions);
    if (Object.keys(errors).length === 0) {
    const formData = new FormData();
    formData.append('status',status);
    formData.append('ticket_id',id);
    formData.append('remark',remark);
    formData.append('emp_id',emp_id);
   // setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('assign-ticket-update', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //navigate("/ticket-receive");
        handleCloseModal();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } 



}
}
const addremark = (id,ticket_id)=>{
  setopenstatusmodal(true);
  setids(id);
  setticketid(ticket_id);
}
const updateremark = async ()=>{
   const errors = {};
    if(!remark){
      errors.remark = 'Remark is required'
    }
    setvalidationErrors(errors);
   // console.log(selectedOptions);
    if (Object.keys(errors).length === 0) {
    const formData = new FormData();
    formData.append('id',ids);
    formData.append('ticket_id',ticket_id);
    formData.append('remark',remark);
    formData.append('emp_id',emp_id);
   // setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('update-remark-of-ticket', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        handleCloseModal();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } 



}



}




  useEffect(() => {
    const fetchstatus = async () => {
      try {
        const endpoint = 'get-ticket-solve-status';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setsolve_status(response.data.data);
         
        } else {
          console.error('Error fetching data:');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchstatus();
  }, [id]);
  


  const addstatus = ()=>{
   setopenModal(true);

  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <TicketRaiseHeader/>
              <MDBox p={3}>
                <Typography variant="h4" mb={2}>Ticket Details</Typography>
                <Grid container spacing={2}>
                {ticketdetails.url &&
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">URL:</Typography>
                    <Typography variant="body1">{ticketdetails.url}</Typography>
                  </Grid>
                }
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">Description:</Typography>
                    <Typography variant="body1">{ticketdetails.description}</Typography>
                  </Grid>
                  {ticketdetails.group_names &&
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">Group:</Typography>
                    <Typography variant="body1">{ticketdetails.group_names}</Typography>
                  </Grid>
                }
                 <Grid item xs={12} md={6}>
                    <Typography variant="h6">Ticket Raise Date:</Typography>
                    <Typography variant="body1">{ticketdetails.date}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">Close Date:</Typography>
                    <Typography variant="body1">{ticketdetails.close_date}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">Assigned Member:</Typography>
                    <Typography variant="body1">{assign_details}</Typography>
                  </Grid>
                   <Grid item xs={12} md={6}>
                    <Typography variant="h6">Ticket Raise By:</Typography>
                    <Typography variant="body1">{ticket_raise_by}</Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">Product:</Typography>
                    <Typography variant="body1">{product}</Typography>
                  </Grid>
                   <Grid item xs={12} md={6}>
                    <Typography variant="h6">Updated Hours To Solve Ticket(Hours):</Typography>
                    <Typography variant="body1">{new_time}</Typography>
                  </Grid>
                </Grid>
                
              </MDBox>
               {empExists &&
               <button style={{margin:'18px',width:'190px'}} className="btn btn-info" onClick={() =>addstatus()}>Add Your Work Status</button>
              }
              <MDBox p={3}>
                {loading ? (
                  <Grid container justifyContent="center">
                    <CircularProgress />
                  </Grid>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Status</td>
                        <td>Remark</td>
                        <td>Manager Remark</td>
                        <td>Ticket Raiser Remark</td>
                        <td>Created By</td>
                        <td>Date</td>
                         {
                           (assignexists || raise_by === emp_id) && (
                           <td>Action</td>
                            )}
                      </tr>
                    </thead>
                    <tbody>
                      {ticketstatus.map((ticket, index) => (
                        <tr key={ticket.id}>
                          <td>{index + 1}</td>
                          <td>{ticket.status}</td>
                          <td>{ticket.remark}</td>
                          <td>{ticket.manager_remark}</td>
                          <td>{ticket.ticket_raiser_remark}</td>
                          <td>{ticket.emp_fname}</td>
                           <td>{ticket.created_date}</td>
                          {
  (ticket.reporting_manager === emp_id || raise_by === emp_id) && (
    <td>
      <button className="btn btn-info" onClick={() => addremark(ticket.id, ticket.ticket_id)}>Add Remark</button>
    </td>
  )
}

                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
       <Dialog
        open={openModal}
       // onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}
      >
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <form>
            <div>
             <label>Select Status:</label>
              <select className="form-control"
                             value={status}
                             onChange={(e) => selectedstatus(e.target.value)}
                              >
                            <option value=''>Select Status</option>
                             {solve_status.map((item) => (
                             <option key={item.name} value={item.name}>
                              {item.name}
                              </option>
                              ))}
                          </select>
            
              {validationErrors.status&& (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                )}
                           </div>

                    <div className ="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Remark</label>
                           <textarea rows={5} cols={5} style={{height:'50px'}}
                            value={remark}
                            onChange={(e) => setremark(e.target.value)}
                            className = "form-control"


                           />
                    </div>
                    </div>
          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick={handlesubmit}>
           Update
          </Link>
        </DialogActions>
      </Dialog>

       <Dialog
        open={openstatusmodal}
       // onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}
      >
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <form>

            <div className ="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Remark</label>
                           <textarea rows={5} cols={5} style={{height:'50px'}}
                            value={remark}
                            onChange={(e) => setremark(e.target.value)}
                            className = "form-control"


                           />
                {validationErrors.remark&& (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                )}
                       
                    </div>
                    </div>
          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick={updateremark}>
           Update
          </Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default ViewTicketStatus;
