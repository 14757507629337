import React from 'react';
import { Link, useLocation } from "react-router-dom";
function PackageDetailsHeader() 
{
    let location = useLocation();
    let currentPage = location.pathname;

  return (
    
    <div>
      <Link style={currentPage === "/dure-and-pkg-fact" ||currentPage=== "/package-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/dure-and-pkg-fact">
      Duration & Package Type Factor
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/location-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/location-details">
      Location Factor
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/category-factor" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/category-factor">
      Category Factor
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/locality-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/locality-details">
      Locality Factor
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/to-location-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}}  className="btn btn-primary mb-2 float-end" to="/to-location-details">
      To Location Factor
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/day-range-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/day-range-details">
      Days Wise Factor
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/lead-range-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/lead-range-details">
      Lead wise Factor
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/package-creation" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/package-creation">
      Package Creation
      </Link>
      &nbsp;&nbsp;&nbsp;
   

    </div>
  );
}


export default PackageDetailsHeader;
