import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FinanceDetailsHeader from '../common_components/finance_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Finance_details() 
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [newRecivedAmount,setNewRecivedAmount] = useState([]);
  const [approvalModal,setApprovalModal] = useState(false);
  const [selectedApprovalDetails,setNewApproveAmount] = useState({});
  
  const [selectedApprovalStatus,setApprovalStatus] = useState();
  const [remarks,setRemarks] = useState();

  const approvalOpenControl= async (payment_id)=>{
    try{
      const response = await dashboardApi.get(`get-recived-amount-by-id/${payment_id}`);
      if(response.data.status === 200)
      {

        setNewApproveAmount(response.data.data);
        setApprovalModal(true);
      }
      else
      {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error)
    {
      console.error('Error fetching countries:', error);
    }
    
  }
  
  const closeApprovalControl = ()=>
  {
    setApprovalModal(false);
  }



    useEffect(() => {
      getNewRecivedAmount();
    }, []);

    const getNewRecivedAmount = async () => 
    {
      try{
        const response = await dashboardApi.get(`get-recived-amount`);
        if(response.data.status === 200)
        {
          setNewRecivedAmount(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
      }
    };

    

    const approveCreditamount = async (payment_id)=>{
      const response = await dashboardApi.get('new-payment-amount',{
        params:{
          payment_id:payment_id,
          status:selectedApprovalStatus,
          remarks:remarks,
          created_by:emp_id
        }
      });
      if (response.data.status === 200)
      {
        setApprovalModal(false);
        getNewRecivedAmount();
        setFormFildsEmpty();
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //setLmartPrePackageDetails(response.data.data);
      } 
    }

    const setFormFildsEmpty = ()=>{
      setNewApproveAmount('');
      setRemarks();
    }
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <FinanceDetailsHeader></FinanceDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                      <Link className='btn btn-info'>
                          +
                      </Link>
                      </div>
                  </div>

             
                  <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                    <tr>
                      <td>S.No</td>
                      <td>Client Name</td>
                      <td>Contact Info</td>
                      <td>Payment Type</td>
                      <td>Transaction & UTR No</td>
                      <td>Credit Amount</td>
                      <td>Transation Date</td>
                      <td>Action</td>
                    </tr>
                    {newRecivedAmount && newRecivedAmount.map((wh,index)=>(
                      <tr>
                        <td>{index + 1}</td>
                        <td>{wh.client_name}</td>
                        <td>{wh.business_name}</td>
                        <td>{wh.payment_type}</td>
                        <td>Txn: {wh.transaction_id}<br/>UTR: {wh.utr_no}</td>
                        <td>{wh.credit}</td>
                        <td>{wh.transaction_date}</td>
                        <td>
                          <Link className='btn btn-info' onClick={() => approvalOpenControl(wh.id) }>
                            View
                          </Link>&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                      </tr>
                      
                    ))
                    }
                    
                  </table>
            
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={approvalModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Payment Details</DialogTitle>
          <DialogContent style={{height:"350px"}}>
              <form>
                <div className="row">
                  
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Payment Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-12">
                    <table className="table table-bordered table-hovered">
                      <tr>
                        <td>
                          <b>Client Name:</b> {selectedApprovalDetails.client_name} <br/>
                          <b>Business Name:</b> {selectedApprovalDetails.business_name}
                        </td>
                        <td>
                          <b>Balance Amount :</b> {selectedApprovalDetails.balance} <br/>
                          <b>New Amount :</b> {selectedApprovalDetails.credit}
                        </td>
                      </tr>
                      <></>
                    </table>
                  </div>
            
                  <div className="col-sm-6">
                      <label>Amount</label>
                      <select className="form-control"
                          value={selectedApprovalStatus}
                          onChange={(e) => setApprovalStatus(e.target.value)}
                          >
                          <option value=''>Select Approval Status</option>
                          <option value='1'>Approved</option>
                          <option value='2'>Rejected</option>
                          
                      </select>
                  </div>

                  <div className="col-sm-6">
                      <label>Remarks</label>
                      <input type="text" className="form-control" value={remarks} onChange={(e)=>setRemarks(e.target.value)} />
                  </div>
                </div>
            </form>
          </DialogContent>

          <DialogActions>
              <Link className="btn btn-primary btn-sm" onClick={closeApprovalControl}>Close</Link>
             
              <Link className="btn btn-success btn-sm" onClick={() => approveCreditamount(selectedApprovalDetails.id)}>Submit</Link>
           
          </DialogActions>
      </Dialog>

    </DashboardLayout>
  );
}
export default Finance_details;
