import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

function CompanyTargetList() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [targetlist, settargetlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [productList, setproductList] = useState([]);
  const [product, setProduct] = useState('');
  const [remark, setRemark] = useState('');
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [target_per_month, setTargetPerMonth] = useState('');
  const [target_per_year,settarget_per_year] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [openEditModal,setopenEditModal] = useState(false);
  const [id,setid] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchtarget();
    fetchProducts();
  }, []);

  const fetchtarget = async () => {
    try {
      const endpoint = 'target-list';
      const response = await dashboardApi.get(endpoint);
      if (response.data.status === 200) {
        settargetlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchProducts = async () => {
    try {
      const endpoint = 'active-product';
      const response = await dashboardApi.get(endpoint);
      if (response.data.status === 200) {
        setproductList(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (target_per_month) {
      settarget_per_year(target_per_month*12);
     
    }
      
  }, [target_per_month]);

  const openModalHandler = () => {
    setOpenModal(true);
  };

  const closeModalHandler = () => {
    setProduct('');
    setValidationErrors({});
    setRemark('');
    setStartDate('');
    setEndDate('');
    setTargetPerMonth('');
    setOpenModal(false);
    settarget_per_year('');
    //setValidationErrors(null);
  };

  const closeEditModalHandler = () => {
    setProduct('');
    setValidationErrors({});
    setRemark('');
    setStartDate('');
    setEndDate('');
    setTargetPerMonth('');
    setopenEditModal(false);
    settarget_per_year('');
    //setValidationErrors(null);
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('product', product);
    formData.append('start_date', start_date);
    formData.append('end_date', end_date);
    formData.append('target_per_month', target_per_month);
    formData.append('remark', remark);
    formData.append('emp_id',empId);

    try {
      const response = await dashboardApi.post('save-target', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchtarget();
        closeModalHandler();
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    }
  };

  const handleProductChange = (e) => {
    setProduct(e.target.value);
  };
  const targetedit = async(target_id)=>{
        setid(target_id);
         try {
            const endpoint = `target-edit/${target_id}`;
            const response = await dashboardApi.get(endpoint);
           if (response.data.status === 200) {
            setRemark(response.data.data.remark);
            setProduct(response.data.data.product_id);
            setStartDate(response.data.data.date_from);
            setEndDate(response.data.data.date_to);
            setTargetPerMonth(response.data.data.target_per_month);
            //set
            } else {
              console.error('Error fetching data:');
            }
           setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
          setopenEditModal(true);

     }
  const toggleStatus = async (targetId) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`get-target-status/${targetId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('update-target-status', {
        target_id:targetId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        settargetlist((prevState) =>
          prevState.map((targetObj) =>
          targetObj.id === targetId ? { ...targetObj, status: newStatus } : targetObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  const UpdateHandler = async(e)=>{
    e.preventDefault();
    const errors = {};
    const formData = new FormData();
    formData.append('product', product);
    formData.append('start_date', start_date);
    formData.append('end_date', end_date);
    formData.append('target_per_month', target_per_month);
    formData.append('remark', remark);
    formData.append('emp_id',empId);

    //setIsSubmitting(true);

    try {
     const response =  await dashboardApi.post(`update-target/${id}`, formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchtarget();
        setopenEditModal(false);

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while updating the target',
      });
    } finally {
      //setIsSubmitting(false);
    }

    


  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className="btn btn-info" onClick={openModalHandler}>+</Link>
                </div>
                &nbsp;&nbsp;
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Product</td>
                        <td>Date From</td>
                        <td>Date To</td>
                        <td>Target Per Month</td>
                        <td>Target Of Year</td>
                        <td>Achieved Target</td>
                        <td>Remark</td>
                        <td>Status</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {targetlist.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.product_name}</td>
                          <td>{row.date_from}</td>
                          <td>{row.date_to}</td>
                          <td>{row.target_per_month}</td>
                          <td>{row.target_per_year}</td>
                          <td>{row.achieved_target}</td>
                          <td>{row.remark}</td>
                          <td><button
                           type="button"
                           className={`btn btn-${row.status === 1 ? 'success' : 'danger'}`}
                           onClick={() => toggleStatus(row.id)}
                          >
                         {row.status === 1 ? 'Active' : 'Inactive'}
                         </button></td>
                         <td>
                          <Link className='btn btn-success me-2' onClick={() => targetedit(row.id)}>
                              Edit
                          </Link>
                        </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog
        open={openModal}
        onClose={closeModalHandler}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ backgroundColor: '#f8f9fa', color: '#212529', borderBottom: '1px solid #dee2e6' }}>Add Target</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row mb-3">
                <div className="col-md-6">
                  <label>Select Product:</label>
                  <select
                    className="form-control"
                    value={product}
                    onChange={handleProductChange}
                  >
                    <option value=''>Select Product</option>
                    {productList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.product_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.product && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Start Date:</label>
                  <TextField
                    type="date"
                    fullWidth
                    value={start_date}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                   {validationErrors.start_date && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.start_date}</div>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label>End Date:</label>
                  <TextField
                    type="date"
                    fullWidth
                    value={end_date}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                  />
                   {validationErrors.end_date && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.end_date}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Target Per Month:</label>
                  <TextField
                    type="number"
                    fullWidth
                    value={target_per_month}
                    onChange={(e) => setTargetPerMonth(e.target.value)}
                    required
                  />
                  {target_per_month &&
                     <span style={{color:'red'}}>Year Target:{target_per_year}</span>
                  }
                   
                   {validationErrors.target_per_month && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.target_per_month}</div>
                  )}
                </div>
              </div>
              <div>
                <label>Remark:</label>
                <TextField
                  type="text"
                  fullWidth
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
                {validationErrors.remark && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                  )}
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-info"  onClick={closeModalHandler} style={{ marginRight: '10px' }}>Cancel</button>
          <Button variant="contained" onClick={saveHandler} style={{ backgroundColor: '#007bff', color: '#fff' }}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditModal}
        onClose={closeEditModalHandler}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ backgroundColor: '#f8f9fa', color: '#212529', borderBottom: '1px solid #dee2e6' }}>Edit Target</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row mb-3">
                <div className="col-md-6">
                  <label>Select Product:</label>
                  <select
                    className="form-control"
                    value={product}
                    onChange={handleProductChange}
                  >
                    <option value=''>Select Product</option>
                    {productList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.product_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.product && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Start Date:</label>
                  <TextField
                    type="date"
                    fullWidth
                    value={start_date}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                   {validationErrors.start_date && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.start_date}</div>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label>End Date:</label>
                  <TextField
                    type="date"
                    fullWidth
                    value={end_date}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                  />
                   {validationErrors.end_date && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.end_date}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Target Per Month:</label>
                  <TextField
                    type="number"
                    fullWidth
                    value={target_per_month}
                    onChange={(e) => setTargetPerMonth(e.target.value)}
                    required
                  />
                  {target_per_month &&
                     <span style={{color:'red'}}>Year Target:{target_per_year}</span>
                  }
                   
                   {validationErrors.target_per_month && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.target_per_month}</div>
                  )}
                </div>
              </div>
              <div>
                <label>Remark:</label>
                <TextField
                  type="text"
                  fullWidth
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
                {validationErrors.remark && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                  )}
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-info"  onClick={closeModalHandler} style={{ marginRight: '10px' }}>Cancel</button>
          <Button variant="contained" onClick={UpdateHandler} style={{ backgroundColor: '#007bff', color: '#fff' }}>Update</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default CompanyTargetList;
