import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link,useParams } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import docurl from 'layouts/common_components/doc_base_url'; 
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AdminDetailsHeader from 'layouts/common_components/admin_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


function Return_Renewal() 
{
  const navigate = useNavigate();
  const { clientId } = useParams();
  const emp_id = localStorage.getItem('emp_id');
  const token = localStorage.getItem('token');
  const department = localStorage.getItem('department');
  const [newKycDoc,setNewKycDoc] = useState([]);

    const closeApprovalControl = ()=>
    {
      setApprovalModal(false);
    }
    useEffect(() => {
      ReturnLeadDetails();
    }, []);

    const ReturnLeadDetails = async () => 
    {
      try
      {
        const response = await dashboardApi.get(`get-return-leads`);
        if(response.data.status === 200)
        {
          setNewKycDoc(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
      }
    }
    const handleApprove = async (packageId) => {
      try {
        const response = await dashboardApi.post('approved-return-leads', {
          package_id: packageId,
        });
    
        console.log('Success:', response.data);
        
        // Show success alert
        Swal.fire({
          title: 'Approved!',
          text: 'The package has been successfully approved.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
        
        // Handle success (e.g., update state, show a success message)
      } catch (error) {
        console.error('Error:', error);
        
        // Show error alert
        Swal.fire({
          title: 'Error!',
          text: 'There was an issue approving the package. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        
      }
    };
    
    
    
    console.log(newKycDoc);
 
    return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <h4 className="mb-3">Package List</h4>
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover" style={{ width: '100%' }}>
                        <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Client Name</th>
                            <th>Company Name</th>
                            <th>Mobile Number</th>
                            <th>Package Start Date</th>
                            <th>Package End Date</th>
                            <th>Total Lead </th>
                            <th>Lead Send</th>
                            <th>Remaning Lead</th>
                            <th>Package category</th>
                            <th>Group</th>
                            <th>Executive Name</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {newKycDoc.map((item,index) => (
                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.client_name}</td>
                                <td>{item.business_name}</td>
                                <td>{item.mobile_no}</td>
                                <td>{item.package_start_date}</td>
                                <td>{item.package_end_date}</td>
                                <td>{item.total_lead}</td>
                                <td>{item.sent_lead}</td>
                                <td>{item.total_lead - item.sent_lead}</td>
                                <td>
                                {item.category_name}
                                </td>
                                <td>{item.group}</td>
                                <td>{item.created_by}</td>
                                <td>
                                    {item.action === 1 ? (
                                      <button
                                        className="btn btn-sm btn-success"
                                        id={`approved-popup-${item.package_id}`}
                                        onClick={() => {
                                          if (window.confirm("Are you sure you want to approve?")) {
                                            handleApprove(item.package_id);
                                          } else {
                                            console.log("Approved action canceled");
                                          }
                                        }}
                                      >
                                        Approved
                                      </button>
                                    ) : (
                                      <span></span>
                                    )}
                                  </td>
                                {/* Add more data cells if needed */}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>

              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    
    </DashboardLayout>
  );
}
export default Return_Renewal;
