import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import AppCrudHeader from 'layouts/common_components/app_crud_header';

function BasePointsInfo() {
  const navigate = useNavigate();
  const [Leave, setLeave] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = secureLocalStorage.getItem("token");
  const [openModal, setOpenModal] = useState(false);
  const [editopenModal,seteditopenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const emp_id = secureLocalStorage.getItem('emp_id');
  const [countrydetails,setcountrydetails] = useState([]);
  const [selectedcountry,setselectedcountry] = useState('');
  const [list,get_list_details] = useState([]);
  const [id,setid] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;
  const [locality,setlocality] =   useState('')
  const [productlist,setproductlist] = useState([]);
  const [selectedproduct,setselectedproduct] = useState('');
  const [currency_type,set_currency_type] = useState('');
  const [per_points,set_per_points] = useState('');
  const [per_points_price,set_per_points_price] = useState('');
  //const [OpeneditModal,seteditopenModal] = useState(false);
  const [base_point_details,get_base_point_details] = useState('');

 
  useEffect(() => {
       if (!token) {
        navigate("/sign-in");
     }
    }, [token, navigate]);

    useEffect(()=>{
        country_info();
         get_base_point_list();
        productdetails();


    },[])

    useEffect(() => {
       get_base_point_list(currentPage);
    }, [currentPage]);

    const productdetails = async () => {
        try {
          const endpoint = 'get-product-list';
          const response = await dashboardApi.get(endpoint);
    
          if (response.data.status === 200) {
            setproductlist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    const country_info = async () => {
        try {
          const endpoint = 'get-active-country';
          const response = await dashboardApi.get(endpoint);
    
          if (response.data.status === 200) {
            setcountrydetails(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const changecountryfunction = async(id)=>{
        setselectedcountry(id);
        try {
          const endpoint = `get-country-currency-type/${id}`;
          const response = await dashboardApi.get(endpoint);
    
          if (response.data.status === 200) {
            set_currency_type(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }

       

      }

      const get_base_point_list = async (page) => {
        try {
          // const endpoint = 'otp-template-list';
          // const response = await dashboardApi.get(endpoint);

          const response = await dashboardApi.get('base-points-list', {
            params: {
              per_page: postsPerPage,
              page: page + 1, 
            }
          });
    
          if (response.data.status === 200) {
            const enqData = response.data.data.data || response.data.data;
            get_base_point_details(enqData);
            setTotalPages(response.data.last_page);
          } else {
            console.error('Error fetching data:', response.data.error);
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.error('Error fetching data:', error);
        }
      };

      const handlePageClick = (selected) => {
        setCurrentPage(selected.selected);
      };
   const handleCloseModal = () => {
    setValidationErrors('');
    setOpenModal(false);
    seteditopenModal(false);
    setselectedcountry('');
    set_currency_type('');
    set_per_points('');
    set_per_points_price('');
    setselectedproduct('');
   
  };
  const handleOpenModal = async () => {
    setOpenModal(true);
  };
  const editviewopen = async(base_point_id)=>{
     setid(base_point_id);
      try {
      const endpoint = `get-base-points-details/${base_point_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
         setselectedproduct(response.data.data.product_id);
         setselectedcountry(response.data.data.country_id);
         set_currency_type(response.data.data.currency_type);
         set_per_points_price(response.data.data.per_point_price);
         set_per_points(response.data.data.per_point);
        //seturl(response.data.data.links_url);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
    seteditopenModal(true);

  }
  
  const handlesave = async (e)=>{
   e.preventDefault();
    const formData = new FormData();
    formData.append('country',selectedcountry);
    formData.append('product',selectedproduct);
    formData.append('emp_id',emp_id);
    formData.append('currency_type',currency_type);
    formData.append('per_point',per_points)
    formData.append('per_point_price',per_points_price)

    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-base-point-info', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        handleCloseModal();
       get_base_point_list();
      } 

      else if (response.data.status === 201) {
        setValidationErrors(response.data.messages);
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });

        handleCloseModal();
      }
      
       else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the followup',
      });
    }

  }
  const handleupdate = async (e)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append('country',selectedcountry);
    formData.append('product',selectedproduct);
    formData.append('emp_id',emp_id);
    formData.append('currency_type',currency_type);
    formData.append('per_point',per_points)
    formData.append('per_point_price',per_points_price);
    formData.append('id',id);
 
     try {
       const response = await dashboardApi.post('update-base-points', formData);
 
       if (response.data.status === 200) {
         Swal.fire({
           icon: 'success',
           text: response.data.message,
         });
         get_base_point_list()
         handleCloseModal();
       } else if (response.data.status === 400) {
         setValidationErrors(response.data.messages);
       } else {
         Swal.fire({
           icon: 'error',
           text: response.data.message || 'An error occurred',
         });
       }
     } catch (error) {
       Swal.fire({
         icon: 'error',
         text: 'An error occurred while updating',
       });
     }
 
   }

   const toggleStatus = async (id) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`get-base-points-details/${id}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data.status;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('update-base-points-status', {
        id:id,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
       get_base_point_list((prevState) =>
          prevState.map((followupObj) =>
           followupObj.id === id ? { ...followupObj, status: newStatus } : followupObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppCrudHeader></AppCrudHeader>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={handleOpenModal}>
                   +
                </Link>

                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Country</td>
                  <td>Product</td>
                  <td>Currency Type</td>
                  <td>Per Points</td>
                  <td>Per Points Price</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {base_point_details.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.product_name}</td>
                  <td>{row.currency_type}</td>
                  <td>{row.per_point}</td>
                  <td>{row.per_point_price}</td>
                  <td><button
                  type="button"
                  className={`btn btn-sm btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 <td>
                 <button className='btn btn-primary btn-sm mb-2 float-end'onClick={() => editviewopen(row.id)}>
                    Edit
                </button>
                 </td>
               </tr>
              ))}

            </table>
             )}
             {list.length > 0 && (
            <Pagination className="custom-pagination">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link-style"}
                breakLinkClassName={"break-link-style"}
                previousLinkClassName={"prev-link-style"}
                nextLinkClassName={"next-link-style"}
              />
            </Pagination>
          )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>Add Base Point</DialogTitle>
          <DialogContent>
            <form>
              <div>
                  <label>Select country</label>
                  <select className="form-control"
                        value={selectedcountry}
                        onChange={(e) => changecountryfunction(e.target.value)}
                        >
                        <option value=''>Select Country</option>
                        {countrydetails.map((country) => (
                          <option key={country.id} value={country.id}>
                          {country.name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.country && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.country}</div>
                  )}
              </div>

              <div>
                  <label>Select Product</label>
                  <select className="form-control"
                        value={selectedproduct}
                        onChange={(e) => setselectedproduct(e.target.value)}
                        >
                        <option value=''>Select Product</option>
                        {productlist.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.product && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.product}</div>
                  )}
              </div>

              <div>
                  <label>Currency Type</label>
                  <input
                    type='text'
                    className="form-control"
                    value={currency_type}
                    readOnly
                  />
              </div>

              <div>
                  <label>Per Points</label>
                  <input
                    type='number'
                    className="form-control"
                    value={per_points}
                    onChange={(e) => set_per_points(e.target.value)}
                    min='1'
                    max = '9'
                    step="1"
                  />
                   {validationErrors.per_point && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.per_point}</div>
                  )}
              </div>

              <div>
                  <label>Per point Price</label>
                  <input
                    type='text'
                    className="form-control"
                    value={per_points_price}
                    onChange={(e) => set_per_points_price(e.target.value)}
                  />
                   {validationErrors.per_point_price && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.per_point_price}</div>
                  )}
              </div>

            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick={handleCloseModal}>
              Close
            </Link>
            &nbsp;
            <button className="btn btn-info" onClick={handlesave}>
             Save
            </button>
          </DialogActions>
      </Dialog>

      <Dialog open={editopenModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>Edit Base Points</DialogTitle>
          <DialogContent>
          <form>
              <div>
                  <label>Select country</label>
                  <select className="form-control"
                        value={selectedcountry}
                        onChange={(e) => changecountryfunction(e.target.value)}
                        >
                        <option value=''>Select Country</option>
                        {countrydetails.map((country) => (
                          <option key={country.id} value={country.id}>
                          {country.name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.country && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.country}</div>
                  )}
              </div>

              <div>
                  <label>Select Product</label>
                  <select className="form-control"
                        value={selectedproduct}
                        onChange={(e) => setselectedproduct(e.target.value)}
                        >
                        <option value=''>Select Product</option>
                        {productlist.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.product && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.product}</div>
                  )}
              </div>

              <div>
                  <label>Currency Type</label>
                  <input
                    type='text'
                    className="form-control"
                    value={currency_type}
                    readOnly
                  />
              </div>

              <div>
                  <label>Per Points</label>
                  <input
                    type='number'
                    className="form-control"
                    value={per_points}
                    onChange={(e) => set_per_points(e.target.value)}
                    min='1'
                    max = '9'
                    step="1"
                  />
                   {validationErrors.per_point && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.per_point}</div>
                  )}
              </div>

              <div>
                  <label>Per point Price</label>
                  <input
                    type='text'
                    className="form-control"
                    value={per_points_price}
                    onChange={(e) => set_per_points_price(e.target.value)}
                  />
                   {validationErrors.per_point_price && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.per_point_price}</div>
                  )}
              </div>

            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick={handleCloseModal}>
              Close
            </Link>
            &nbsp;
            <button className="btn btn-info" onClick={handleupdate}>
             Save
            </button>
          </DialogActions>
      </Dialog>

    </DashboardLayout>
  );
}

export default BasePointsInfo;