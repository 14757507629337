import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';

import OfferDetailsHeader from 'layouts/common_components/offer_details_header';


import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import { parseISO, isBefore, format } from 'date-fns';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select from 'react-select';
function Offers_details()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offerDetails, setOfferDetails] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [typeDetails, setTypeDetails] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);

  
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [validationErrors, setValidationErrors] = useState({});
  const [offerModal,setOfferModal]=useState(false);

  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  const [service, setService] = useState([]);
  const [selectedService, setSelectedService] = useState('');

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const[offerId,setSelectedOfferType]=useState('');
  const[selectedCategoryId,setSelectedOfferCategory]=useState('');
  const[discountDetails,setOfferDiscount]=useState([]);


  const[selectedPriceDiscount,setPriceDiscount]=useState('');
  const[selectedLeadDiscount,setLeadDiscount]=useState('');

  
  
  const[offersTitle,setOffersTitle]=useState('');
  const[offersDescription,setOffersDescription]=useState('');
  const[offerValidFrom,setOffersValidFrom]=useState('');
  const[offerValidTo,setOffersValidTo]=useState('');
 
  const[prePackageDetails,setPrePackageDetails]=useState([]);
  const [clientsIds, setClientsIds] = useState('');
  const [clientDetails, setClientDetails] = useState([]);
  const [error, setError] = useState('');

  const [isPriceBasedChecked, setIsPriceBasedChecked] = useState(false);
  const [isLeadBasedChecked, setIsLeadBasedChecked] = useState(false);

  const [selectedPrePackage, setSelectedPrePackage] = useState([]);

  const options = prePackageDetails.map((ppd) => ({
    value: ppd.id,
    label: ppd.package_name,
  }));

  const [selectedPriceDiscounts, setSelectedPriceDiscounts] = useState([]);
  const [selectedLeadDiscounts, setSelectedLeadDiscounts] = useState([]);
  
  
  const handlePriceDiscountChange = (e) => {
    const value = e.target.value;
    setSelectedPriceDiscounts(prev => 
      prev.includes(value) ? prev.filter(id => id !== value) : [...prev, value]
    );
  };

  // Handle checkbox selection for lead discounts
  const handleLeadDiscountChange = (e) => {
    const value = e.target.value;
    setSelectedLeadDiscounts(prev => 
      prev.includes(value) ? prev.filter(id => id !== value) : [...prev, value]
    );
  };

  const handleChange = (selectedOptions) => {
    setSelectedPrePackage(selectedOptions || []);
  };

  const handleSelectAll = () => {
    setSelectedPrePackage(options);
  };

  const handleDeselectAll = () => {
    setSelectedPrePackage([]);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      setClientsIds(value);
      setError('');
    } else {
      setError('Please enter only numbers.');
    }
  };

  const addClientId = () => {
    if (clientsIds.trim() === '')
    {
      setError('Input cannot be empty.');
      return;
    }
    const idsArray = clientsIds.split(',').map(id => id.trim());
    // Add new valid IDs to the clientDetails array
    setClientDetails(prevDetails => [...prevDetails, ...idsArray]);
    // Clear the input field
    setClientsIds('');
  };
  
  const [editMode, setEditMode] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [status, setStatus] = useState(1);
  
  const handleEdit = (category) =>
  {
    setOfferModal(true);
    setEditMode(true);
    setEditCategoryId(category.id);
    setSelectedOfferType(category.offer_type_id);
  };

  useEffect(()=>{
    getOfferType();
    getProduct();
    getDiscountRate();
  },[]);

  useEffect(()=>{
    getOfferCategory();
  },[currentPage])

  const getOfferCategory = async () =>
  {
      try {
        const response = await dashboardApi.get(`get-offer-details`,{
          params:{
            emp_id:emp_id,
            per_page: postPerPage,
            page: currentPage + 1,
          }
        });

        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const exeData = responseData.data || responseData;
          setOfferDetails(exeData);
          setNumber(responseData.last_page);
       
        } else {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

    const getDiscountRate = async () =>
    {
        try{
          const response = await dashboardApi.get(`get-offer-discount-details`);
  
          if(response.data.status === 200)
          {
            setOfferDiscount(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }


    const getOfferType = async () => 
    {
      try{
        const response = await dashboardApi.get(`get-offer-type`);
        if (response.data.status === 200) {
          setTypeDetails(response.data.data);
        } else {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }catch (error){
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

    useEffect(()=>{
      if(offerId)
      {
        const getCategoryTypeId = async () => 
        {
          try{
            const response = await dashboardApi.get(`get-offer-category-by-offer-id/${offerId}`);
            if (response.data.status === 200) {
              setCategoryDetails(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          }catch (error){
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        getCategoryTypeId();
      }
    },[offerId]);

    const getProduct = async () => {
      try {
        const response = await dashboardApi.get(`sales-product-list/active`);
        if (response.data.status === 200) {
          setProduct(response.data.data);
        } else {
          console.error('Error fetching product list:', response.data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product list:', error);
        setLoading(false);
      }
    };
  
    
  
    useEffect(() => {
      if (selectedProduct) {
        const fetchService = async () => {
          try {
            const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
            if (response.data.status === 200) {
              setService(response.data.data);
            } else {
              console.error('Error fetching service list:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching service list:', error);
            setLoading(false);
          }
        };
        fetchService();
      }
    }, [selectedProduct]);

    useEffect(() => {
      if (selectedService) {
        const fetchCategory = async () => {
          try {
            const response = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
            if (response.data.status === 200) {
              setCategory(response.data.data);
            } else {
              console.error('Error fetching category list:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching category list:', error);
            setLoading(false);
          }
        };
        fetchCategory();
      }
    }, [selectedService]);

    useEffect(() =>
    {
      if(offerId==4) {
        const fetchPrePackage = async () => {
          try {
            const response = await dashboardApi.get(`get-pre-package-details`);
            if (response.data.status === 200)
            {
              setPrePackageDetails(response.data.data);
            }
            else
            {
              console.error('Error fetching category list:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching category list:', error);
            setLoading(false);
          }
        };
        fetchPrePackage();
      }
    }, [offerId==4]);


    

    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };

    const openOfferTypeModal = () =>
    {
      setOfferModal(true);
    }
    const closeOfferModal= ()=>{
      resetOfferFields();

      setOfferModal(false);
    }

    const resetOfferFields = ()=>{
      setEditCategoryId('');
      setEditMode(false);
      setSelectedOfferType('');
      
    }

    const formSubmit = async()=>
    {
      const selectedIds = selectedPrePackage.map(option => option.value);

      const errors = {};
      
      
      if(!offerId)
      {
        errors.offerId = 'Offer id is required.';
      }
      
      if(!selectedProduct)
      {
        errors.selectedProduct = 'Product is required.';
      }

      if(!selectedService)
      {
        errors.selectedService = 'Service is required.';
      }

      if(!selectedCategory)
      {
        errors.selectedCategory = 'Category is required.';
      }

      if(!selectedCategoryId)
      {
        errors.selectedCategoryId = 'Offer Category is required.';
      }

      if(!offersTitle)
      {
        errors.offersTitle = 'Offer title is required.';
      }

      if(!offersDescription)
      {
        errors.offersDescription = 'Offer description is required.';
      }



      if (offerId === 3 && (!clientsIds || clientsIds.length === 0)) {
        errors.clientsIds = 'Minimum one client ID is required.';
      }
      
      if (offerId === 4 && (!selectedPrePackage || selectedPrePackage.length === 0)) {
         errors.selectedPrePackage = 'Minimum one pre-package is required.';
      }
      

      const from = new Date(offerValidFrom);
      const to = new Date(offerValidTo);
      

      if(!offerValidFrom)
      {
        errors.offerValidFrom = 'Valid From date is required';
      }

      if(!offerValidTo)
      {
        errors.offerValidTo = 'Valid To date is required';
      } else if (offerValidFrom && to < from) {
        errors.offerValidTo = 'Valid To date cannot be earlier than Valid From date';
      }
      
      setValidationErrors(errors);
      console.log(errors);
      
      //console.log(Object.keys(errors).length);
      let offerDiscountType = '';

      if (isPriceBasedChecked && isLeadBasedChecked) {
        offerDiscountType = '1,2';
      } else if (isPriceBasedChecked) {
        offerDiscountType = '1';
      } else if (isLeadBasedChecked) {
        offerDiscountType = '2';
      } else {
        offerDiscountType = '';
      }

      
        if(!offerDiscountType) {
          errors.offerDiscountType = 'Offer discount type is required.';
        }
      
      
      if(isPriceBasedChecked)
      {
        if(selectedPriceDiscounts.length < 1)
        {
          errors.selectedPriceDiscounts = 'Offer Price discount is required.';
        }
      }

      if(isLeadBasedChecked)
      {
        if(selectedLeadDiscounts.length < 1)
        {
          errors.selectedLeadDiscounts = 'Offer Lead discount type is required.';
        }
      }
      setValidationErrors(errors);
      if(Object.keys(errors).length > 0)
      {
        return; 
      }

      const formData = new FormData();
      formData.append('created_by',emp_id);
      formData.append('product',selectedProduct);
      formData.append('service',selectedService);
      formData.append('category',selectedCategory);
      formData.append('offerId',offerId);
      formData.append('categoryId',selectedCategoryId);
      formData.append('offersTitle',offersTitle);
      formData.append('offersDescription',offersDescription);
      formData.append('offersPriceDiscount',selectedPriceDiscounts);
      formData.append('offersLeadDiscount',selectedLeadDiscounts);
      formData.append('offerDiscountType',offerDiscountType);
      formData.append('offerValidFrom',offerValidFrom);
      formData.append('offerValidTo',offerValidTo);
      formData.append('selectedPrePackage',selectedIds);
      formData.append('clientDetails',clientDetails);
      
      try
      {
        const url = editMode ? `update-offer-details` : 'add-offer-details';
        const response = await dashboardApi.post(url, formData);
        if(response.data.status === 200)
        {
          setOfferModal(false);
          resetOfferFields();
          setEditMode(false);
         // getOfferCategory();
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
        }
        else
        {
          setOfferModal(false);
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      }
      catch (error)
      {
        setOfferModal(false);
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while submitting the form',
        });
      }
    }

    const handleStatusToggle = async (category) =>
    {
      const newStatus = category.status === 1 ? 0 : 1;
      try
      {
        const response = await dashboardApi.post(`update-offer-category-status/${category.id}`, {
          status: newStatus,
        });
    
        if (response.data.status === 200) {
          //getOfferCategory(); // Refresh the list
          Swal.fire({
            icon: 'success',
            text: `Offer category has been ${newStatus === 1 ? 'activated' : 'deactivated'}.`,
          });
        } else {
          Swal.fire({
            icon: 'error',
            text: 'An error occurred while updating the status.',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while updating the status.',
        });
      }
    };

  return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                    <div class="col-sm-11">
                        <OfferDetailsHeader></OfferDetailsHeader>
                    </div>
                    <div class="col-sm-1">
                      <Link className='btn btn-info' onClick={openOfferTypeModal}>
                          +
                      </Link>
                    </div>
                  </div>
                  <div className='row'>
                    
                  </div>


                  {loading ? (
                  <p style={{align:'center'}}>
                  <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                  </p>
                  ) : (
                  <div style={{overflowY: "hidden"}}>
                    <table className="table table-bordered table-hovered" style = {{marginTop:'20px'}}>
                    <tr>
                      <th>S.No</th>
                      <th>Offer Type</th>
                      <th>Category</th>
                      <th>Offer Title</th>
                      <th>Status</th>
                      <th>Discount</th>
                      <th>Price %</th>
                      <th>Lead %</th>

                      
                      <th>Valid From</th>
                      <th>Valid To</th>
                      {/* <th>Action</th> */}
                    </tr>
                      {offerDetails && offerDetails.map((od,index)=>(
                        <tr>
                          <td>{index + 1}</td>
                          <td>{od.type_name}</td>
                          <td>{od.category_name}</td>
                          <td>{od.offer_title}</td>
                          <td>{od.status==1?'Active':'Inactive'}</td>
                          <td>{od.offer_discount_type}</td>
                          <td>{od.offer_price_percent}</td>
                          <td>{od.offer_lead_percent}</td>

                          <td>{od.valid_from}</td>
                          <td>{od.valid_to}</td>

                          {/* <td>
                            <button className="btn btn-primary btn-sm" onClick={() => handleEdit(oc)}>
                              Edit
                            </button> 
                          </td> */}
                        </tr>
                        ))
                      }
                    <tr>
                      <td colspan="10">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={number}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link-style"}
                          breakLinkClassName={"break-link-style"}
                          previousLinkClassName={"prev-link-style"}
                          nextLinkClassName={"next-link-style"}
                          forcePage={currentPage} // Add this line
                        />
                        </Pagination>
                      </td>
                    </tr>
                </table>
                </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={offerModal} fullWidth PaperProps={{ style: { maxWidth: '60%', padding: '20px' } }}>
      <DialogTitle>{editMode?'Update Offer Details':'Add Offer Details'}</DialogTitle>
        <DialogContent style={{height:"450px"}}>
            <form>
              <div className="row">
                
                <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                    <p style={{ margin: "0 10px" }}>Offer Details</p>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                </div>

                <div className="col-sm-4">
                  <label>Select Product </label>
                  <select className="form-control" value={selectedProduct} onChange={(e)=>{setSelectedProduct(e.target.value)}}>
                      <option value="">Select Product</option>
                      {product && product.map((p)=>(
                        <option value={p.id}>{p.product_name}</option>
                      ))}
                  </select>
                  {validationErrors.selectedProduct && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                  )}
                </div>

                <div className="col-sm-4">
                  <label>Select Service </label>
                  <select className="form-control" value={selectedService} onChange={(e)=>{setSelectedService(e.target.value)}}>
                      <option value="">Select Service</option>
                      {service && service.map((s)=>(
                        <option value={s.id}>{s.service_name}</option>
                      ))}
                  </select>
                  {validationErrors.selectedService && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                  )}
                </div>

                <div className="col-sm-4">
                  <label>Select Category </label>
                  <select className="form-control" value={selectedCategory} onChange={(e)=>{setSelectedCategory(e.target.value)}}>
                      <option value="">Select Service</option>
                      {category && category.map((c)=>(
                        <option value={c.id}>{c.category_name}</option>
                      ))}
                  </select>
                  {validationErrors.selectedCategory && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCategory}</div>
                  )}
                </div>

                
                <div className="col-sm-4">
                  <label>Offer Type</label>
                  <select className="form-control" value={offerId} onChange={(e)=>{setSelectedOfferType(e.target.value)}}>
                      <option value="">Select Type</option>
                      {typeDetails && typeDetails.map((td)=>(
                        <option value={td.id}>{td.offer_title}</option>
                      ))}
                  </select>
                  {validationErrors.offerId && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.offerId}</div>
                  )}
                </div>
                <div className="col-sm-4">
                  <label>Select Offer Category </label>
                  <select className="form-control" value={selectedCategoryId} onChange={(e)=>     {setSelectedOfferCategory(e.target.value)}}>
                      <option value="">Select Offer Category</option>
                      {categoryDetails && categoryDetails.map((cd)=>(
                        <option value={cd.id}>{cd.category_name}</option>
                      ))}
                  </select>
                  {validationErrors.selectedCategoryId && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCategoryId}</div>
                  )}
                </div>

                {offerId && offerId== 3 &&(
                  <>
                  <div className="col-sm-4">
                    
                    <label>Insert Client id</label>
                    <div style={{display:'flex'}}>
                    <input 
                      className="form-control" 
                      type="text" 
                      value={clientsIds} 
                      onChange={handleInputChange} 
                    />
                    {error && <span className="text-danger">{error}</span>}
                    
                    <span className="btn btn-primary" onClick={addClientId}>
                      Add
                    </span>
                    {validationErrors.clientsIds && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientsIds}</div>
                    )}
                    </div>
                  </div>
                    
                  <div className="client-details mt-3  col-sm-12" style={{backgroundColor:'red',color:'white'}}>
                    <label><b>Client id's:</b></label>
                    {clientDetails.map((id, index) => (
                      <span key={index} className="client-id">{id}, </span>
                    ))}
                  </div>
                  </>
                )}

                {offerId && offerId== 4 &&(
                  <div className="col-12">
                    <label>Select Pre Package</label>
                    <div className="mb-2">
                      <button type="button" className='btn btn-success' onClick={handleSelectAll}>
                        Select All
                      </button>
                      <button type="button" className='btn btn-danger' onClick={handleDeselectAll} style={{ marginLeft: '10px' }}>
                        Deselect All
                      </button>
                    </div>
                    <Select
                      isMulti
                      isSearchable
                      value={selectedPrePackage}
                      onChange={handleChange}
                      options={options}
                      className="form-control h-auto"
                      placeholder="Select Pre Package"
                    />
                    {validationErrors.selectedPrePackage && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPrePackage}</div>
                    )}
                  </div>
                )}
                


                <div className="col-sm-4">
                  <label>Offer Title</label>
                  <TextField type="text" className="form-control" value={offersTitle} onChange={(e) => setOffersTitle(e.target.value)}></TextField>
                  {validationErrors.offersTitle && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.offersTitle}</div>
                  )}
                </div>

                <div className="col-sm-4">
                  <label>Offer Description</label>
                  <TextField type="text" className="form-control" value={offersDescription} onChange={(e) => setOffersDescription(e.target.value)}></TextField>
                  {validationErrors.offersDescription && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.offersDescription}</div>
                  )}
                </div>

               

                <div className="col-sm-4">
                  <label>Offer Discount Type</label>
                  <div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={isLeadBasedChecked}
                        onChange={(e) => setIsLeadBasedChecked(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="leadBased">
                        Lead Based
                      </label>
                      
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        
                        checked={isPriceBasedChecked}
                        onChange={(e) => setIsPriceBasedChecked(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="priceBased">
                        Price Based
                      </label>

                      
                    </div>
                      {validationErrors.offerDiscountType && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.offerDiscountType}</div>
                      )}
                  </div>
                </div>

                {isPriceBasedChecked && (
                <div className="col-sm-4">
                  <label>Select Price Discount %</label>
                  <div className="form-check" style={{display:'flex'}}>
                  {discountDetails.price && discountDetails.price.map((dp) => (
                    <div key={dp.id} className="form-check" style={{display:'flex'}}>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={dp.id}
                        checked={selectedPriceDiscounts.includes(dp.id.toString())}
                        onChange={handlePriceDiscountChange}
                      />
                      <label className="form-check-label">
                        {dp.discount_percent} %
                      </label>
                      
                    </div>
                  ))}

                  {validationErrors.selectedPriceDiscounts && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPriceDiscounts}</div>
                  )}
                  </div>
                </div>
              )}

              {isLeadBasedChecked && (
                <div className="col-sm-4">
                  <label>Select Lead Discount %</label>
                  <div className="form-check" style={{display:'flex'}}>
                  {discountDetails.lead && discountDetails.lead.map((dl) => (
                    <div key={dl.id} className="form-check" style={{display:'flex'}}>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={dl.id}
                        checked={selectedLeadDiscounts.includes(dl.id.toString())}
                        onChange={handleLeadDiscountChange}
                      />
                      <label className="form-check-label">
                        {dl.discount_percent} %
                      </label>
                      
                    </div>
                  ))}

                  {validationErrors.selectedLeadDiscounts && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedLeadDiscounts}</div>
                  )}
                  </div>
                </div>
              )}

                <div className="col-sm-4">
                  <label>Valid From</label>
                  <TextField
                    type="date"
                    className="form-control"
                    value={offerValidFrom}
                    onChange={(e) => setOffersValidFrom(e.target.value)}
                  />
                  {validationErrors.offerValidFrom && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>
                      {validationErrors.offerValidFrom}
                    </div>
                  )}
                </div>

                <div className="col-sm-4">
                  <label>Valid To</label>
                  <TextField
                    type="date"
                    className="form-control"
                    value={offerValidTo}
                    onChange={(e) => setOffersValidTo(e.target.value)}
                  />
                  {validationErrors.offerValidTo && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>
                      {validationErrors.offerValidTo}
                    </div>
                  )}
                </div>
              </div>
              
            
              
              </form>
            </DialogContent>

            <DialogActions>
                <Link className="btn btn-primary btn-sm" onClick={closeOfferModal}>Close</Link>
                {editMode?
                  <Link className="btn btn-success btn-sm" onClick={formSubmit}>Update Details</Link>
                  :
                  <Link className="btn btn-success btn-sm" onClick={formSubmit}>Add Details</Link>
                }
                
            </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
  
}
export default Offers_details;