import Country from "layouts/superadmin/components/country";
import AddCountry from "layouts/superadmin/components/country/add-country";
import EditCountry from "layouts/superadmin/components/country/edit-country";
import State from "layouts/superadmin/components/state";
import AddState from "layouts/superadmin/components/state/add-state";
import EditState from "layouts/superadmin/components/state/edit-state";
import Department from "layouts/superadmin/components/department";
import AddDepartment from "layouts/superadmin/components/department/add-department";
import EditDepartment from "layouts/superadmin/components/department/edit-department";
import Designation from "layouts/superadmin/components/designation";
import AddDesignation from "layouts/superadmin/components/designation/add-designation";
import EditDesignation from "layouts/superadmin/components/designation/edit-designation";
import Company from "layouts/superadmin/components/company";
import AddCompany from "layouts/superadmin/components/company/add-company";
import EditCompany from "layouts/superadmin/components/company/edit-company";
import BranchType from "layouts/superadmin/components/branch_type";
import AddBranchType from "layouts/superadmin/components/branch_type/add-branchtype";
import EditBranchType from "layouts/superadmin/components/branch_type/edit-branchtype";
import Branch from "layouts/superadmin/components/branches";
import AddBranch from "layouts/superadmin/components/branches/add-branch";
import EditBranch from "layouts/superadmin/components/branches/edit-branch";
import Product from "layouts/superadmin/components/product";
import AddProduct from "layouts/superadmin/components/product/add-product";
import EditProduct from "layouts/superadmin/components/product/edit-product";
import Service from "layouts/superadmin/components/services";
import AddService from "layouts/superadmin/components/services/add-service";
import EditService from "layouts/superadmin/components/services/edit-service";
import EmpType from "layouts/superadmin/components/emp_type";
import AddEmpType from "layouts/superadmin/components/emp_type/add-emptype";
import EditEmpType from "layouts/superadmin/components/emp_type/edit-emp_type";
import DocumentType from "layouts/superadmin/components/document_type";
import AddDocumentType from "layouts/superadmin/components/document_type/add-document_type";
import EditDocumentType from "layouts/superadmin/components/document_type/edit-document_type";
import AddSalaryAttribute from "layouts/superadmin/components/salary_structure/add-attribute";
import Attribute from "layouts/superadmin/components/salary_structure";
import EditAttribute from "layouts/superadmin/components/salary_structure/edit-attribute";
import AddLeaveType from "layouts/superadmin/components/leave_type/create-leave_type";
import EditLeaveType from "layouts/superadmin/components/leave_type/edit-leave_type";
import LeaveType from "layouts/superadmin/components/leave_type";
import AddModule from "layouts/superadmin/components/module/add_module";
import ModuleList from "layouts/superadmin/components/module";
import EditModule from "layouts/superadmin/components/module/edit_module";
import Enquiry from "layouts/superadmin/components/Enquiry";
import AddPriority from "layouts/superadmin/components/ticket_priority/add-priority";
import EditPriority from "layouts/superadmin/components/ticket_priority/edit-priority";
import AttributeList from "layouts/superadmin/components/ticket_priority";
import  secureLocalStorage  from  "react-secure-storage";
import ComapnyTargetList from "layouts/superadmin/components/company_target";
import Utm from "layouts/superadmin/components/utm";
import Question from "layouts/superadmin/components/enquiry_question";
import QuestionOption from "layouts/superadmin/components/enquiry_question/question_option";
import FollowupStatus from "layouts/superadmin/components/followup_status";
import TicketSubattribute from "layouts/ticket_subattribute";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import OtpTemplate from "layouts/superadmin/components/app_crud_details/otp_template";
import AppVersion from "layouts/superadmin/components/app_crud_details/app_version";
import ItemType from "layouts/superadmin/components/app_crud_details/item_type";
import ItemInfo from "layouts/superadmin/components/app_crud_details/item_info";
import TransportChargesType from "layouts/superadmin/components/app_crud_details/transport_charges_type";
import  GenricChangeType from "layouts/superadmin/components/app_crud_details/genric_change_type";
import SupportLinksInfo from "layouts/superadmin/components/app_crud_details/support_links_info";
import Locality from "layouts/superadmin/components/app_crud_details/locality";
import BasePointsInfo from "layouts/superadmin/components/app_crud_details/base_points_info";
import  SourceFrom from "layouts/superadmin/components/app_crud_details/source_from";
import ComplaintStatus from "layouts/superadmin/components/app_crud_details/complaint_status";
import PaymentGateway from "layouts/superadmin/components/app_crud_details/payment_gateway"
import MilestoneCancelReason from 'layouts/superadmin/components/app_crud_details/milestone_cancel_reason';
import AppCallLogReasons from 'layouts/superadmin/components/app_crud_details/app_call_log_reasons';
import SupportServices from 'layouts/superadmin/components/app_crud_details/support_services';
import SupportCategory from "layouts/superadmin/components/app_crud_details/support_category";





import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];
const add_country = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-country");
const edit_country = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-country");
const country_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "country-list");
const add_state = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-state");
const edit_state = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-state");
const state_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "state-list");
const add_department = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-department");
const edit_department = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-department");
const department_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "department-list");
const add_designation = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-designation");
const edit_designation = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-designation");
const designation_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "designation-list");
const add_company = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-company");
const edit_company = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-company");
const company_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "company-list");
const add_branch_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-branch-type");
const edit_branch_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-branch-type");
const branch_type_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "branch-type-list");
const add_branch = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-branch");
const edit_branch = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-branch");
const branch_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "branch-list");
const add_product = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-product");
const edit_product = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-product");
const product_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "product-list");
const add_service = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-service");
const edit_service = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-service");
const service_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "service-list");
const add_emp_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-employee-type");
const edit_emp_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-employee-type");
const emp_type_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "employee-type-list");
const add_document_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-document-type");
const edit_document_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-document-type");
const document_type_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "document-type-list");
const add_salary_attribute = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-salary-attribute");
const edit_salary_attribute = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-salary-attribute");
const salary_attribute_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "salary-attribute-list");
const add_leave_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-leave-type");
const edit_leave_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-leave-type");
const leave_type_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "leave-type-list");
const add_module = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-module");
const module_list = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "module-list");
let nested = [];
if(country_list){
  nested.push({
    type: "collapse",
    name: "Country",
    key: "Country",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/country-list",
    component: (token!=null) ? <Country/> : <SignIn/> ,
 });
}
if(state_list){
  nested.push({
    type: "collapse",
    name: "State",
    key: "State",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/state-list",
    component: (token!=null) ? <State/> : <SignIn/> ,
 });
}
if(department_list){
  nested.push({
    type: "collapse",
    name: " Department",
    key: "Department",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/department-list",
    component: (token!=null) ? <Department/> : <SignIn/> ,
 });
}
if(designation_list){
  nested.push({
    type: "collapse",
    name: " Designation",
    key: "Designation",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/designation-list",
    component: (token!=null) ? <Designation/>  :  <SignIn/> ,
 });
}
if(company_list){
  nested.push({
    type: "collapse",
    name: " Company",
    key: "Company",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/company-list",
    component: (token!=null) ? <Company/> : <SignIn/> ,
 });
}
if(branch_type_list){
  nested.push({
    type: "collapse",
    name: " Branch Type",
    key: "Branch Type",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/branch-type-list",
    component: (token!=null) ?  <BranchType/> :  <SignIn/> ,
 });
}
if(salary_attribute_list){
  nested.push({
    type: "collapse",
    name: "Salary Attribute",
    key: "Salary Attribute",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/salary-attribute-list",
    component: (token!=null) ? <Attribute/> : <SignIn/> ,
 });
}
if(branch_list){
  nested.push({
    type: "collapse",
    name: " Branch ",
    key: "Branch ",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/branch-list",
    component: (token!=null) ?  <Branch/> : <SignIn/> ,
 });
}
if(product_list){
  nested.push({
    type: "collapse",
    name: " Product ",
    key: "Product",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/product-list",
    component: (token!=null) ? <Product/> : <SignIn/> ,
 });
}
if(service_list){
  nested.push({
    type: "collapse",
    name: " Service ",
    key: "Service",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/service-list",
    component: (token!=null) ? <Service/> : <SignIn/> ,
 });
}
if(emp_type_list){
  nested.push({
    type: "collapse",
    name: " Employee Type",
    key: "Employee Type",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/emp-type-list",
    component: (token!=null) ?  <EmpType/> : <SignIn/> ,
 });
}
if(document_type_list){
  nested.push({
    type: "collapse",
    name: " Document Type",
    key: "Document Type",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/document-type-list",
    component: (token!=null) ?  <DocumentType/> : <SignIn/> ,
 });
}
if(leave_type_list){
  nested.push({
    type: "collapse",
    name: "Leave Type",
    key: "Leave Type",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/leave-type-list",
    component: (token!=null) ? <LeaveType/> : <SignIn/> ,
 });
}
if(module_list){
  nested.push({
    type: "collapse",
    name: "Module",
    key: "Module",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/module-list",
    component: (token!=null) ? <ModuleList/> : <SignIn/> ,
 });
}
if(module_list){
  nested.push({
    type: "collapse",
    name: "Ticket Attribute",
    key: "Ticket",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ticket-attribute",
    component: (token!=null) ? <AttributeList/> : <SignIn/> ,
 });
}

// nested.push({
//   type: "collapse",
//   name: "UTM",
//   key: "utm",
//   icon: <Icon fontSize="small">table_view</Icon>,
//   route: "/utm-list",
//   component: (token!=null) ? <Utm/> : <SignIn/> ,
// });

nested.push({
  type: "collapse",
  name: "App Related Crud",
  key: "App Related Crud",
  icon: <Icon fontSize="small">table_view</Icon>,
  route: "/otp-template",
  component: (token!=null) ? <Utm/> : <SignIn/> ,
});


const superadminroute = [

  {
    type: "collapse",
    name: "Superadmin",
    key: "superadmin",
    icon: <Icon fontSize="small">admin_panel_settings</Icon>,
    nested: nested
  },

];

if(add_country){
  superadminroute.push({
    route: "/add-country",
    component: (token!=null) ? <AddCountry/> : <SignIn/> ,
});
}

superadminroute.push({
  route: "/utm-list",
  component: (token!=null) ? <Utm/> : <SignIn/> ,
  
});

superadminroute.push({
  route: "/question-list",
  component: (token!=null) ? <Question/> : <SignIn/> ,
  
});
superadminroute.push({
  route: "/option-list",
  component: (token!=null) ? <QuestionOption/> : <SignIn/> ,

})
superadminroute.push({
  route: "/followup-status",
  component: (token!=null) ? <FollowupStatus/> : <SignIn/> ,

})

if(add_country){
  superadminroute.push({
    route: "/target-list",
    component: (token!=null) ? <ComapnyTargetList/> : <SignIn/> ,
});

}
if(edit_country){
  superadminroute.push({
    route: "/edit-country/:id",
    component: (token!=null) ? <EditCountry/> :  <SignIn/>  ,
});

}
if(country_list){
  superadminroute.push({
    route: "/country-list",
    component: (token!=null) ?  <Country/> : <SignIn/> ,
});

}

if(add_state){
  superadminroute.push({
    route: "/add-state",
    component: (token!=null) ? <AddState/> : <SignIn/> ,
});

}
if(edit_state){
  superadminroute.push({
    route: "/state-edit/:id",
    component: (token!=null) ? <EditState/> : <SignIn/> ,
});

}
if(state_list){
  superadminroute.push({
    route: "/state-list",
    component: (token!=null) ? <State/> : <SignIn/> ,
});

}

if(add_department){
  superadminroute.push({
    route: "/add-department",
    component: (token!=null) ? <AddDepartment/> : <SignIn/> ,
});

}
if(edit_department){
  superadminroute.push({
    route: "/edit-department/:id",
    component: (token!=null) ? <EditDepartment/> : <SignIn/> ,
});

}
if(department_list){
  superadminroute.push({
    route: "/department-list",
    component: (token!=null) ? <Department/> : <SignIn/> ,
});

}

if(add_designation){
  superadminroute.push({
    route: "/add-designation",
    component: (token!=null) ? <AddDesignation/> : <SignIn/> ,
});

}
if(edit_designation){
  superadminroute.push({
    route: "/edit-designation/:id",
    component: (token!=null) ? <EditDesignation/> : <SignIn/> ,
});

}
if(designation_list){
  superadminroute.push({
    route: "/designation-list",
    component: (token!=null) ? <Designation/> : <SignIn/> ,
});

}

if(add_company){
  superadminroute.push({
    route: "/add-company",
    component: (token!=null) ? <AddCompany/> :  <SignIn/> ,
});

}
if(edit_company){
  superadminroute.push({
    route: "/edit-company/:id",
    component: (token!=null) ?  <EditCompany/> : <SignIn/> ,
});

}
if(company_list){
  superadminroute.push({
    route: "/company-list",
    component: (token!=null) ? <Company/> : <SignIn/> ,
});

}
if(company_list){
  superadminroute.push({
    route: "/ticket-attribute",
    component: (token!=null) ? <AttributeList/> : <SignIn/> ,
});

}
if(company_list){
  superadminroute.push({
    route: "/add-ticket-attribute",
    component: (token!=null) ? <AddPriority/> : <SignIn/> ,
});

}
if(company_list){
  superadminroute.push({
    route: "/edit-ticket-attribute/:id",
    component: (token!=null) ? <EditPriority/> : <SignIn/> ,
});

}


  superadminroute.push({
    route: "/add-branch-type",
    component: (token!=null) ? <AddBranchType/> :  <SignIn/> ,
});

if(edit_branch_type){
  superadminroute.push({
    route: "/edit-branch-type/:id",
    component: (token!=null) ?  <EditBranchType/> : <SignIn/> ,
});

}
if(branch_type_list){
  superadminroute.push({
    route: "/branch-type-list",
    component: (token!=null) ?  <BranchType/> : <SignIn/> ,
});

}

if(add_branch){
  superadminroute.push({
    route: "/add-branch",
    component: (token!=null) ? <AddBranch/> : <SignIn/> ,
});

}
if(edit_branch){
  superadminroute.push({
    route: "/edit-branch/:id",
    component: (token!=null) ?  <EditBranch/> : <SignIn/> ,
});

}
if(branch_list){
  superadminroute.push({
    route: "/branch-list",
    component: (token!=null) ? <Branch/> : <SignIn/>,
});

}

if(add_product){
  superadminroute.push({
    route: "/add-product",
    component: (token!=null) ? <AddProduct/> : <SignIn/> ,
});

}
if(edit_product){
  superadminroute.push({
    route: "/edit-product/:id",
    component: (token!=null) ? <EditProduct/> : <SignIn/> ,
});

}
if(product_list){
  superadminroute.push({
    route: "/product-list",
    component: (token!=null) ? <Product/> :  <SignIn/> ,
});

}

if(add_service){
  superadminroute.push({
    route: "/add-service",
    component: (token!=null) ? <AddService/> : <SignIn/> ,
});

}
if(edit_service){
  superadminroute.push({
    route: "/edit-service/:id",
    component: (token!=null) ?  <EditService/> : <SignIn/> ,
});

}
if(service_list){
  superadminroute.push({
    route: "/service-list",
    component: (token!=null) ? <Service/> : <SignIn/> ,
});

}

if(add_emp_type){
  superadminroute.push({
    route: "/add-emp_type",
    component: (token!=null) ? <AddEmpType/> : <SignIn/> ,
});

}
if(edit_emp_type){
  superadminroute.push({
    route: "/edit-emp_type/:id",
    component:  (token!=null) ? <EditEmpType/> : <SignIn/> ,
});

}
if(emp_type_list){
  superadminroute.push({
    route: "/emp-type-list",
    component: (token!=null) ? <EmpType/> : <SignIn/> ,
});

}
if(add_document_type){
  superadminroute.push({
    route: "/add-document_type",
    component: (token!=null) ?  <AddDocumentType/> : <SignIn/> ,
});

}
if(edit_document_type){
  superadminroute.push({
     route: "/edit-document_type/:id",
     component: (token!=null) ? <EditDocumentType/> : <SignIn/> ,
});

}
if(document_type_list){
  superadminroute.push({
    route: "/document-type-list",
    component: (token!=null) ?  <DocumentType/> : <SignIn/> ,
});

}

if(add_salary_attribute){
  superadminroute.push({
    route: "/add-attribute",
    component: (token!=null) ? <AddSalaryAttribute/> : <SignIn/> ,
});

}
if(edit_salary_attribute){
  superadminroute.push({
     route: "/edit-attribute/:id",
     component: (token!=null) ? <EditAttribute/> :  <SignIn/> ,
});

}
if(salary_attribute_list){
  superadminroute.push({
    route: "/salary-attribute-list",
    component: (token!=null) ? <Attribute/> :  <SignIn/> ,
});

}

if(add_leave_type){
  superadminroute.push({
    route: "/create-leave-type",
    component: (token!=null) ?  <AddLeaveType/> : <SignIn/> ,
});

}
if(edit_leave_type){
  superadminroute.push({
     route: "/edit-leave-type/:id",
     component: (token!=null) ?  <EditLeaveType/> : <SignIn/> ,
});

}
if(leave_type_list){
  superadminroute.push({
    route: "/leave-type-list",
    component: (token!=null) ?  <LeaveType/> : <SignIn/> ,
});

}
if(add_module){
  superadminroute.push({
    route: "/add-module",
    component: (token!=null) ?  <AddModule/> : <SignIn/> ,
});

}
if(module_list){
  superadminroute.push({
    route: "/module-list",
    component: (token!=null) ? <ModuleList/> : <SignIn/> ,
});

}
if(add_country){
    superadminroute.push({
    route: "/module-list",
    component: (token!=null) ? <ModuleList/> : <SignIn/> ,
});
}
// if(branch_type_list){
//   superadminroute.push({
//     type: "collapse",
//     name: " Send Enquiry",
//     key: "Send Enquiry",
//     icon: <Icon fontSize="small">table_view</Icon>,
//     route: "/send-enquiry",
//     component: (token!=null) ?  <Enquiry/> :  <SignIn/> ,
//     nested:[],
//  });
// }
superadminroute.push({
  route: "/ticket-subattribute",
  component: (token!=null) ? <TicketSubattribute/> : <SignIn/> ,
});

superadminroute.push({
  route: "/otp-template",
  component: (token!=null) ? <OtpTemplate/> : <SignIn/> ,
});

superadminroute.push({
  route:"/app-version",
  component: (token!=null) ? <AppVersion/> : <SignIn/> ,


})

superadminroute.push({
  route:"/item-type",
  component: (token!=null) ? <ItemType/> : <SignIn/> ,


})

superadminroute.push({
  route:"/item-info",
  component: (token!=null) ? <ItemInfo/> : <SignIn/> ,


})

superadminroute.push({
  route:"/transport-charges-type",
  component: (token!=null) ? <TransportChargesType/> : <SignIn/> ,


})

superadminroute.push({
  route:"/genric-change-type",
  component: (token!=null) ? <GenricChangeType/> : <SignIn/> ,


})

superadminroute.push({
  route:"/support-links-info",
  component: (token!=null) ? <SupportLinksInfo/> : <SignIn/> ,


})
superadminroute.push({
  route:"/locality",
  component: (token!=null) ? <Locality/> : <SignIn/> ,


})

superadminroute.push({
  route:"/base-points-info",
  component: (token!=null) ? <BasePointsInfo/> : <SignIn/> ,


})

superadminroute.push({
  route:"/source-from",
  component: (token!=null) ? <SourceFrom/> : <SignIn/> ,


})

superadminroute.push({
  route:"/complain-status",
  component: (token!=null) ? <ComplaintStatus/> : <SignIn/> ,


})

superadminroute.push({
  route:"/payment-gateway",
  component: (token!=null) ? <PaymentGateway/> : <SignIn/> ,


})

superadminroute.push({
  route:"/milestone-cancel-reason",
  component: (token!=null) ? <MilestoneCancelReason/> : <SignIn/> ,


})

superadminroute.push({
  route:"/app-call-log-reason",
  component: (token!=null) ? <AppCallLogReasons/> : <SignIn/> ,


})

superadminroute.push({
  route:"/support-services",
  component: (token!=null) ? <SupportServices/> : <SignIn/> ,


})

superadminroute.push({
  route:"/support-category",
  component: (token!=null) ? <SupportCategory/> : <SignIn/> ,


})













export default superadminroute;