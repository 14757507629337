
import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate,useParams,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";



//const [loading, setLoading] = useState(true);

function EditEmpType() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const navigate = useNavigate();
  const [emptype, setemptype] = useState('');
  const [shortemptype,setshortemptype] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  //console.log(selectedCountry);
  useEffect(()=>{
    fetchemptype()
  },[])
  const fetchemptype = async () => {
   await dashboardApi.get(`edit-emp_type/${id}`).then(({data})=>{
      //console.log('hi');
      const {emp_type,short_name} = data.emp_type
      setemptype(emp_type);
      setshortemptype(short_name);
     
      //console.log(ShortName);



    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }
  const updateemptype = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!emptype) {
      errors.emp_type = 'Emp Type is required';
    }
   if (!shortemptype) {
      errors.short_name = 'Short Name is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(emptype)) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      emp_type: 'Emp Type should contain only letters.',
    }));
    return;
  }
    const formData = new FormData()
    formData.append('emp_type', emptype);
    formData.append('short_name',shortemptype);
    try {
      const response = await dashboardApi.post(`update-emp_type/${id}`, formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/emp-type-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
  };
}


  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div style={{float:'right'}}>
               <Link to='/emp-type-list' className='btn btn-info'>Back</Link>

              </div>
              <div className="card-body">
                <form onSubmit={updateemptype}>
                  <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Employee Type</label>
                          <input
                            type="text"
                            className="form-control"
                            id="state-name"
                            placeholder="Enter State Name"
                            value={emptype}
                            onChange={(e) => setemptype(e.target.value)}
                          />
                          {validationErrors.emp_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.emp_type}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Short Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="state-name"
                            placeholder="Enter Short Name"
                            value={shortemptype}
                            onChange={(e) => setshortemptype(e.target.value)}
                          />
                          {validationErrors.short_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.short_name}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                           Update
                        </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditEmpType;
