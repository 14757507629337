// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
import TicketRaiseHeader from 'layouts/common_components/ticket_raise_header';


function NewTimeRequest() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [raiselist, setraiselist] = useState([])
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(5);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
 useEffect(() => {
   fetchData();
  }, []);
   const fetchData = async () => {
    try {
    const endpoint = 'get-new-time-request';
    const response = await dashboardApi.get(endpoint, {
     params: {
       emp_id: emp_id,
      }
     });

      if (response.data.status === 200) {
        //setraiselist(response.data.data);
        const responseData = response.data.data;
        const ticketdata = responseData.data || responseData;
        setraiselist(ticketdata);
        //setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchData(selected.selected);
    //fetchData(selected.selected);
   // console.log(selected);


  };
  
//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <TicketRaiseHeader/>
              <div className="card-body">
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                 <div className="table-responsive">
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Ticket Id</td>
                  <td>New Time(in Hour)</td>
                  <td>Remark</td>
                  <td>Request By</td>
                  <td>Status</td>
               </tr>
               {raiselist.map((ticket, index) => (
                <tr key={ticket.id}>
                  <td>{index + 1}</td>
                  <td>{ticket.ticket_id}</td>
                  <td>{ticket.required_time_in_hour}</td>
                  <td>{ticket.remark}</td>
                  <td>{ticket.emp_fname}</td>
                  <td>
                   {ticket.status === 0
                   ? 'Pending'
                   : ticket.status === 1
                   ? 'Approved'
                   : ticket.status === 2
                    ? 'Rejected'
                   : 'Unknown Status'}
                  </td>

               </tr>
              ))}
            </table>
            </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewTimeRequest;