import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,Link } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2';


function AssignGroups() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id  = secureLocalStorage.getItem('department_id');
  const [categorylist, setCategoryList] = useState([]);
  const [category, setCategory] = useState('');
  const [assignGroupList, setAssignGroupList] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState('');
  const [inputValues, setInputValues] = useState({});
  const [financialYears, setFinancialYears] = useState([]);
  const [financial_year,set_financialyear] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [search_financial_year,set_search_financial_year] = useState('');
  const [remainingLeads,setRemainingLeads] = useState([]);
  const [employee,setemployee] = useState([]);
  const [productlist,getproductlist] = useState([]);
  const [product,setproduct] = useState('');
 const [grouplist,setgrouplist] = useState([]);
 const [saveddata,setsaveddata] = useState([]);
 //const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
 const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
 const [selectedchild_attribute,setselectedchildattribute] = useState('');
 const [searchText, setSearchText] = useState('');
  const filteredGroups = searchText
    ? grouplist.filter(row => row.name.toLowerCase().includes(searchText.toLowerCase()))
    : grouplist;
 //console.log(selectedchild_attribute);

  useEffect(() => {
    const getCurrentFinancialYear = () => {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();

      const financialYearStart = currentMonth >= 4 ? currentYear : currentYear - 1;
      const financialYearEnd = financialYearStart + 1;

      return `${financialYearStart}-${financialYearEnd}`;
    };

    const generateFinancialYears = () => {
      const currentFinancialYear = getCurrentFinancialYear();
      const nextFinancialYears = [];

      for (let i = 1; i <= 5; i++) {
        const startYear = parseInt(currentFinancialYear.split('-')[0]) + i;
        const endYear = startYear + 1;
        nextFinancialYears.push(`${startYear}-${endYear}`);
      }
      const allFinancialYears = [currentFinancialYear, ...nextFinancialYears];
      setFinancialYears(allFinancialYears);
    };

    generateFinancialYears();
  }, []);

   const senddatatoapi = async (data)=>{
    //console.log(data);
   //const total_data =  JSON.stringify({ data }),
   const formData = new FormData();
    formData.append('valuesdata',JSON.stringify(data));
    try {
      const response = await dashboardApi.post('calculate', formData);
      console.log(response.data.data);

      if (response.data.status === 200) {
         setRemainingLeads(response.data.data);
       // navigate("/ticket-receive");
        //handleCloseModal();
        //blank_attribute();

      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error.message || 'An error occurred ',
      });
    } 

 

}
useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getproductlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
 const calculateSumData = () => {
  const sumData = [];

  // Iterate over inputValues
  Object.values(inputValues).forEach(entry => {
    const { id, catId, value } = entry;
    const sumIndex = sumData.findIndex(item => item.id === id && item.cat_id === catId);

    // If an object with the same id and cat_id already exists, update its sum
    if (sumIndex !== -1) {
      sumData[sumIndex].sum += parseInt(value || 0);
    } 
    // Otherwise, create a new object
    else {
      sumData.push({ id, cat_id: catId, sum: parseInt(value || 0) });
    }
  });

  //return sumData;
  senddatatoapi(sumData);
};

useEffect(() => {
  calculateSumData(); // Move the function call inside the useEffect hook
}, [inputValues]);

//console.log(sumData);



  useEffect(() => {
    fetchData();
    //assignGroups();
  }, [category, empId,search_financial_year]);
  //let sum = '';
  const handleInputselectedcategory = (e, id, group_id, cat_id,source_leads) => {
  const { value } = e.target;

  setInputValues(prevState => ({
    ...prevState,
    [`${id}-${cat_id}-${group_id}`]: {
      id:id,
      value: value,
      catId: cat_id,
      finalValue: group_id,
      source:source_leads
    }
  }));
};

// Function to calculate the sum of values for each category
const calculateCategorySum = () => {
  const categorySums = {};

  // Calculate the sum for each category
  Object.values(inputValues).forEach(entry => {
    const { catId, value } = entry;
    categorySums[catId] = (categorySums[catId] || 0) + parseInt(value || 0);
  });

  return categorySums;
};

// Function to check if the sum exceeds total_leads for any category and show alert if needed
const checkTotalLeadsExceeded = () => {
const categorySums = calculateCategorySum();


  // Check if the sum exceeds total_leads for any category
  Object.entries(categorySums).forEach(([catId, sum]) => {
    const totalLeads = assignGroupList.find(group => group.cat_id === parseInt(catId))?.total_leads;
    if (sum > totalLeads) {
      alert('Total leads exceeded for category');
    }
  });
};

// Call the checkTotalLeadsExceeded function whenever input values change
useEffect(() => {
  checkTotalLeadsExceeded();
}, [inputValues]);

  
  const categoryattribute = Object.entries(inputValues).map(([key, value]) => ({
    id: parseInt(key),
    value: value.value,
    catId: value.catId,
    finalValue: value.finalValue,
  }));
  //console.log(financial_year);


    const savedata = async()=>{
      if (!financial_year) {
         alert('financial Year Required')
         
      }
    
      if (Object.keys(inputValues).length === 0) {
          alert('Please Fill Group Data');
        return;
      }
    

    const formData = new FormData();
    formData.append('valuesdata',categorydatas);
    formData.append('emp_id',empId);
    formData.append('financial_year',financial_year);
    try {
      const response = await dashboardApi.post('save-assign-groups-leads', formData);

      if (response.data.status === 200) {
        //alert('kkk');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
       navigate("/department-target");
        //handleCloseModal();
        //blank_attribute();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error.message || 'An error occurred ',
      });
    } 



    }
    useEffect(() => {
      const fetchData = async () => {
        try {
          const endpoint = 'group-details';
          const response = await dashboardApi.get(endpoint);
  
          if (response.data.status === 200) {
              setgrouplist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const endpoint = 'show-group-saved-data';
          const response = await dashboardApi.get(endpoint);
  
          if (response.data.status === 200) {
             setsaveddata(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);

  
  
 
 const categorydatas = JSON.stringify(categoryattribute);
 const [dataobject,setdataobject] = useState(categorydatas);
  //console.log(categorydatas);
  const fetchData = async () => {
  if(category && search_financial_year && empId ){
    try {
      const endpoint = `show-assign-data/${category}/${empId}/${search_financial_year}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setData(response.data.data);
        setTotal(response.data.total);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
}
let selectedgrouparray = '';
if(selectedchild_attribute.length>0){
   selectedgrouparray = selectedchild_attribute;
}
else{
  selectedgrouparray = 0;
}
useEffect(() => {
  if(product && (selectedgrouparray==0 || selectedgrouparray)){
  const assignGroups = async () => {
    try {
      const endpoint = `assign-groups/${product}/${selectedgrouparray}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setAssignGroupList(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  assignGroups();
}
}, [product,selectedgrouparray]);
  const department_employee = async()=>{
     try {
      const endpoint = 'department-employee';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setAssignGroupList(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  useEffect(() => {
    const token = secureLocalStorage.getItem('token');
    if (!token) {
      navigate("/sign-in");
    }
  }, [navigate]);

  useEffect(() => {
    if(product){
    const fetchCategories = async () => {
      try {
        const endpoint = `get-category-list-by-product-id/${product}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setCategoryList(response.data.data);
        } else {
          console.error('Error fetching categories:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }
  }, [product]);
  //alert(search_financial_year);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card variant="outlined" style={{ padding: '20px', borderRadius: '10px' }}>
            <div>
              <Link className='btn btn-info' to='/department-target'>Back</Link>
              <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>Assign Lead To Groups</h2>

              {/* <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                <label htmlFor="category">Select Product:</label>
                <select
                    class="form-select"
                    classname="form-control"
                    value={product}
                    onChange={(e) => setproduct(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Product</option>
                    {productlist.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.product_name}
                      </option>
                    ))}
                   
                  </select>
              </div> */}
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                  <label htmlFor="category">Select Product:</label>
                 <select
                    class="form-select"
                    classname="form-control"
                    value={product}
                    onChange={(e) => setproduct(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Product</option>
                    {productlist.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.product_name}
                      </option>
                    ))}
                   
                  </select>

                  </div>
                  <div className = "col-md-6">
                  <label htmlFor="category">Select Category:</label>
                  <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="form-control"
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Select Category</option>
                  {categorylist.map(row => (
                    <option key={row.id} value={row.id}>
                      {row.category_name}
                    </option>
                  ))}
                </select>


                  </div>
                </div>
                <div className = "row">
                  <div className="col-md-6">
                  <label htmlFor="category">Select Financial Year:</label>
                   <select id="financialYear"
                         value={search_financial_year}
                         onChange={(e) => set_search_financial_year(e.target.value)}
                         style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                        >
                        <option value = ''>Select Financial year</option>
                        {financialYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                     ))}
                  </select>


                  </div>
                  <div className="col-md-6">
      <label htmlFor="department">Select Group:</label>
      <div style={{ position: 'relative' }}>
        <input
          type="text"
          placeholder="Group"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClick={() => setShowAttributeDropdown(!showAttributeDropdown)}
          style={{ width: '100%', padding: '8px', fontSize: '14px' }}
        />
        {showAttributeDropdown && filteredGroups && filteredGroups.length > 0 && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              border: '1px solid #ced4da',
              borderRadius: '4px',
              maxHeight: '200px',
              overflowY: 'auto',
              zIndex: 999,
              backgroundColor: '#fff',
            }}
          >
            {filteredGroups.map(row => (
              <div key={row.group_id} style={{ padding: '5px' }}>
                <input
                  type="checkbox"
                  id={row.group_id}
                  value={row.group_id}
                  checked={selectedchild_attribute.includes(row.group_id)}
                  onChange={() => {
                    setselectedchildattribute(prevState =>
                      prevState.includes(row.group_id)
                        ? prevState.filter(id => id !== row.group_id)
                        : [...prevState, row.group_id]
                    );
                  }}
                />
                <label htmlFor={row.group_id} style={{ marginLeft: '5px' }}>{row.name}</label>
              </div>
            ))}
          </div>
        )}
      </div>


                  </div>
                  
                </div>

              </div>

              {/* <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                <label htmlFor="category">Select Category:</label>
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="form-control"
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Select Category</option>
                  {categorylist.map(row => (
                    <option key={row.id} value={row.id}>
                      {row.category_name}
                    </option>
                  ))}
                </select>
              </div> */}


              {/* <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                <label htmlFor="category">Select Financial Year:</label>
                <select id="financialYear"
                         value={search_financial_year}
                         onChange={(e) => set_search_financial_year(e.target.value)}
                         style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                        >
                        <option value = ''>Select Financial year</option>
                        {financialYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                     ))}
                  </select>
              </div> */}

            </div>
            <hr />
            <div>
              <h3>Total: {total}</h3>
              {data.map(dt => (
                <div key={dt.id} style={{ marginBottom: '10px' }}>
                  <span>{dt.sub_attributes}: {dt.value}</span>
                </div>
              ))}
            </div>
            <hr />
            <div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
  {assignGroupList.map((group, index) => {
     const savedGroupData = saveddata.find(savedGroup => 
    savedGroup.group_id === group.group_id &&
    savedGroup.category_id === group.cat_id &&
    savedGroup.source_id === group.id
  );

    

    return (
      <div key={`${group.id}-${category}-${index}`} style={{ width: '50%', marginBottom: '20px' }} className="col-md-6">
        <label><h5>{group.group_name}</h5></label>
        <br />
        <label htmlFor={`${group.id}-${group.cat_id}-${group.group_id}`}>
          {group.name}({group.category_name})
        </label>
        <input
          id={`${group.id}-${category}-${index}`}
        // Inside the JSX, where you set the value of the input field
          value={inputValues[`${group.id}-${group.cat_id}-${group.group_id}`]?.value === '' 
  ? '' 
  : inputValues[`${group.id}-${group.cat_id}-${group.group_id}`]?.value 
    || (savedGroupData ? savedGroupData.value : '')
}


          onChange={e => handleInputselectedcategory(e, group.id, group.group_id, group.cat_id, group.source_leads)}
          className="form-control"
          type="number"
          required
          style={{ marginBottom: '8px' }}
        />
        <span style={{ color:'red' }}>Remaining Leads: {
          remainingLeads.find(item => item.id === group.id && item.cat_id === group.cat_id)?.remainingLeads
        }</span>
      </div>
    );
  })}
</div>


              <div style={{ marginBottom: '16px' }}>
                 <label>Financial Year:</label>
                      <select id="financialYear"
                         value={financial_year}
                         onChange={(e) => set_financialyear(e.target.value)}
                         style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                        >
                        <option value = '0'>Select Financial year</option>
                        {financialYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                     ))}
                  </select>
                  {/* {validationErrors.financial_year && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.financial_year}</div>
                  )} */}
                 </div>

              <button color="primary" fullWidth className="btn btn-info" onClick = {savedata}>
                     Save
              </button>
            </div>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default AssignGroups;