import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import ProductDetailsHeader from 'layouts/common_components/product_details_header';
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import  secureLocalStorage  from  "react-secure-storage";

function Package_category()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  
  const [packageCategory, setPackageCategory] = useState([]);
  const [new_category_name,setCategoryName]= useState();

  const [openModal,setOpenModal] =useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const permission = secureLocalStorage.getItem('permission');

  const [product,setProduct]=useState([]);
  const [selectedProduct,setSelectedProduct] = useState();

  const parsedPermission = permission ? JSON.parse(permission) : [];
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
       // fetchState();
        fetchProduct();
        getCategoryDetails();
    },[])

    const getCategoryDetails = async () =>
    {
        const response = await dashboardApi.get(`get-package-category-listing`);
        if(response.data.status === 200)
        {
          setPackageCategory(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
    };
    

    const fetchProduct = async () => 
    {
      try{
        const response = await dashboardApi.get(`sales-product-list/active`);
        if(response.data.status === 200)
        {
          setProduct(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    };

   
    const changeCategoryStatus = async(id,status)=>
      {
        const isConfirm = await Swal.fire({
        title: 'Are you sure?',
        text:  "You want to update this category status!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Update it'
        }).then((result) => {
          return result.isConfirmed
        });
  
          if(!isConfirm)
          {
            return;
          }
          const response = await dashboardApi.get(`update-package-category-status/${id}/${status}`);
          if (response.data.status === 200)
          {
  
            Swal.fire({
              icon: 'success',
              text: response.data.message,
            });
            getCategoryDetails();
  
          }
          else
          {
            console.error('Error fetching current status:', response.data.message);
          }
      }
  

  
  //modal open function
  const handleOpenModal = () =>{
    setOpenModal(true);
  };

  // modal close function
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  
  // Add New product
  
  const handlesubmit = async() =>
  {
    const errors = {};
    if(!selectedProduct)
    {
      errors.selectedProduct = 'Product name is required.';
    }

    if(!new_category_name)
    {
      errors.new_category_name = 'Category name is required.';
    }

    if(selectedProduct && new_category_name)
    {
      const chkcategory = await dashboardApi.get(`check-package-category-details/${selectedProduct}/${new_category_name}`);
      if(chkcategory.data==1)
      {
        errors.new_category_name = 'Package category already exists in system for selected product.';
      }
    }
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0)
    {
      return; 
    }
    const formData = new FormData();
    formData.append('product_id',selectedProduct);
    formData.append('new_category_name',new_category_name);
    formData.append('created_by',emp_id);
   
    try {
      const response = await dashboardApi.post('add-package-category-details', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/package-category");
        handleCloseModal();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    } 
  }

  

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row">
                    <div class="col-sm-11">
                    <ProductDetailsHeader></ProductDetailsHeader>
                    </div>
                    <div class="col-sm-1">
                      <Link className='btn btn-info' onClick={() => handleOpenModal()}>
                        +
                        </Link>
                    </div>
                </div>
                
                {loading ? (
                  <p>Loading...</p>
                ) : (
                <div style={{overflowY: "hidden"}}>
                  <table className="table table-bordered table-hovered">
                    <tr>
                      <td>S.No</td>
                      <td>Product</td>
                      <td>Name</td>
                      {/* <td>Created By</td> */}
                      <td>Created Date</td>
                      <td>Action</td>
                    </tr>
                {packageCategory.map((category, index) => (
                  <tr key={category.id}>
                    <td>{index + 1}</td>
                    <td>{category.product_name}</td>
                    <td>{category.name}</td>
                   
                    {/* <td>{category.created_by}</td> */}
                    <td>{category.created_date}</td>
                    <td>
                      <button
                        type="button" onClick={()=>{changeCategoryStatus(category.id,category.status)}}
                        className={`btn btn-${category.status === 1 ? 'success' : 'danger'}`}
                        >
                        {category.status === 1 ? 'Active' : 'Inactive'}
                      </button>
                    </td>
                  </tr>
                ))
                }

              </table>
              </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>Add New Package Category</DialogTitle>
          <DialogContent>
            <form>
              
              <div>
                <label>Select Product</label>
                <select className="form-control" onChange={(e)=> setSelectedProduct(e.target.value)}>
                  <option value="">Select Product</option>
                  {product && product.map((pro)=>(
                    <option value={pro.id}>{pro.product_name}</option>
                  ))}
                </select>
                {validationErrors.selectedProduct && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedProduct}</div>
                  )}
              </div>

              <div>
                <label>Category Name</label>
                <TextField fullWidth type="text" value={new_category_name} onChange={(e) => setCategoryName(e.target.value)}></TextField>
                {validationErrors.new_category_name && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.new_category_name}</div>
                  )}
              </div>
            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick = {handleCloseModal}>
              Close
            </Link>
            <Link className="btn btn-info"  onClick = {handlesubmit}>
            Add
            </Link>
          </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
export default Package_category;
