import { useState, useEffect } from "react";
import  secureLocalStorage  from  "react-secure-storage";
import Pusher from "pusher-js";
import { ToastContainer, toast } from 'react-toastify';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
function WfhNotification(){
const emp_id = secureLocalStorage.getItem('emp_id')
const [showToast, setShowToast] = useState(true);
const navigate = useNavigate();
const handleViewClick = (data,type)=>{
  if(type=='wfh'){
    navigate('/wfh-list');
  }
  

}
useEffect(() => {
    const pusher = new Pusher("95196b7509f2295a4958", {
      cluster: "ap2",
      encrypted: true,
    });
    const channel = pusher.subscribe(`wfh-channel-${emp_id}`);
    channel.bind("wfh-event", (data) => {
     //alert(JSON.stringify(data.message));
     toast.success(
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{data.message}</span>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          style: { background: 'green', color: 'white', width: '700px' },
          closeButton: false,
          onClick: () => handleViewClick(data.message,data.type),
        }
      );

    });
    return () => {
      pusher.unsubscribe(`wfh-channel-${emp_id}`);
      pusher.disconnect();
    };
  }, [emp_id]);
  return (
    <>
      <ToastContainer closeButton={false} />
    </>
  );


}
export default WfhNotification;