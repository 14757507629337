import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CsDetailsHeader from 'layouts/common_components/cs_details_header';

import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";

function Customer_support() 
{
  const navigate = useNavigate();
  const emp_id = localStorage.getItem('emp_id');
  const token = localStorage.getItem('token');
  const department = localStorage.getItem('department');
  const [loading, setLoading] = useState(true);
  const [callConnecting, setCallLoading] = useState(false);
  
  const [notSentEnq,setNotSentEnq] = useState([]);
  const [approvalModal,setApprovalModal] = useState(false);
  const [selectedApprovalStatus,setApprovalStatus] = useState();
  const [remarks,setRemarks] = useState();
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [callingMobileNo,setCallingMobileNumber] = useState('');

  

    const closeApprovalControl = ()=>
    {
      setApprovalModal(false);
    }

  
    useEffect(() => {
      getNotSentEnq();
    }, [currentPage]);

    const getNotSentEnq = async () => 
    {
      
      try{
        const response = await dashboardApi.get(`get-not-sent-enquiry`,{
          params:{
            emp_id:emp_id,
            per_page: postPerPage,
            page: currentPage + 1,
          }
          
        });
        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const customerData = responseData.data || responseData;
          setNumber(responseData.last_page);
          setNotSentEnq(customerData);
          setLoading(false);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
      }
    }

    
      const makeCallToCustomer = async (mobile_no) => {
        setCallingMobileNumber(mobile_no);
        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
        const form = new FormData();
        form.append("emp_id",emp_id);
        form.append("mobile_number",mobile_no);
        form.append("agent_mobile_number",mobile_no);
        form.append("api_id", "1");
        form.append("secure_key", "d53e5ccd3d0ab16e6c29103beb806bc1");
        try {
          setCallLoading(true);
          
          const response = await dashboardApi.post('dialer/make-a-call', form);
          if(response.data.status === 200)
          {
            const responseData = response.data.data;
            const customerData = responseData.data || responseData;
            setCallLoading(false);
          }
          else
          {
            console.error('Error fetching countries:', response.data.message);
            setCallLoading(false);
          }
        } catch (error) {
          console.error('Error sending POST request', error);
          setCallLoading(false);
        }
      };
  
      
    
  

    

    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };
    //console.log(callingMobileNo);

    return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <CsDetailsHeader></CsDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                        <Link className='btn btn-info' target="_blank" to="/add-enquiry">
                            +
                        </Link>
                      </div>
                  </div>
                  {loading ? (
                  <p style={{align:'center'}}>
                  <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                  </p>
                  ) : (
                  <div style={{overflowY: "hidden"}}>
             
                    <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                      <tr>
                        <td>S.No</td>
                        <td>Customer Name</td>
                        <td>Contact No</td>
                        <td>From/To city</td>
                        <td>Service/Category</td>
                        <td>Recived Date</td>
                        <td>Event Date</td>
                        <td>No Of Attempt</td>
                        <td>Otp Verified</td>
                        <td>CS Verified</td>
                        <td>Action</td>
                      </tr>
                      {notSentEnq && notSentEnq.map((enq,index)=>(
                        <tr>
                          <td>{index + 1}</td>
                          <td>{enq.name}</td>
                          <td>{enq.primary_no}</td>
                          <td>{enq.city_from} - {enq.city_to}</td>
                          <td>{enq.service_name} ({enq.category_name})</td>
                          <td>{enq.recived_date}</td>
                          <td>{enq.event_date}</td>
                          <td>{enq.no_of_attempt}</td>
                          <td>{enq.otp_verified}</td>
                          <td>{enq.cs_verified}</td>
                          <td className='d-flex'>
                              
                              {callConnecting && callingMobileNo == enq.primary_no ?(
                                <p style={{align:'center'}}>
                                <img className="btn btn-primary btn-sm mx-1" src={`${process.env.PUBLIC_URL}/callConnecting.gif`} style=   {{height:'40px'}}></img>
                                  </p>
                              ):(
                                <>
                              <span class="material-icons-outlined btn btn-primary btn-sm mx-1" onClick={()=>   {makeCallToCustomer(enq.primary_no)}}>
                                call
                              </span>
                              </>
                              )}
                             

                              <Link  target="_blank" to={`/edit-enquiry/${enq.id}`}>
                              <span class="material-icons-outlined btn btn-info btn-sm mx-1">
                                edit_note
                              </span>
                              </Link>
                          </td>
                        </tr>
                        
                      ))
                      }
                      <tr>
                      <td colspan="11">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={number}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link-style"}
                          breakLinkClassName={"break-link-style"}
                          previousLinkClassName={"prev-link-style"}
                          nextLinkClassName={"next-link-style"}
                        />
                        </Pagination>
                      </td>
                    </tr>
                    </table>
                  </div>
                  )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default Customer_support;
