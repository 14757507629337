import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import authorsTableData from "layouts/employee/data/authorsTableData";
import projectsTableData from "layouts/employee/data/projectsTableData";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

function FollowupDetails() {
  const navigate = useNavigate();
  const [Leave, setLeave] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attributelist,setattributelist] = useState('');
  const token = secureLocalStorage.getItem("token");
  const [openModal, setOpenModal] = useState(false);
  const [editopenModal,seteditopenModal] = useState(false);
  const [name,setname] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const emp_id = secureLocalStorage.getItem('emp_id');
  const [id,setid] = useState('');
  useEffect(() => {
       if (!token) {
        navigate("/sign-in");
     }
    }, [token, navigate]);
useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'followup-attribute-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setattributelist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  fetchData();
}, []);
const toggleStatus = async (attribute_id) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`get-followup-status/${attribute_id}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('followup-status', {
        followup_id:attribute_id,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setattributelist((prevState) =>
          prevState.map((followupObj) =>
           followupObj.id === attribute_id ? { ...followupObj, status: newStatus } : followupObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };
   const handleCloseModal = () => {
    setname('');
    setValidationErrors('');
    setOpenModal(false);
  };
  const handleOpenModal = async () => {
    setOpenModal(true);
  };
  const OpeneditModal = async(attribute_id)=>{
     setid(attribute_id);
      try {
      const endpoint = `edit-followup-attribute/${attribute_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        //alert();
        //console.log(response);
        //alert(response.data.data[0].approval_remark);
        setname(response.data.data.name);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
    seteditopenModal(true);

  }
  const CloseeditModal = ()=>{
    setid('');
    setname('');
    seteditopenModal(false);

  }
  const handlesave = async (e)=>{
   e.preventDefault();
    const formData = new FormData();
    formData.append('name',name);
    formData.append('emp_id',emp_id);
    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-followup-attribute', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        handleCloseModal();
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the followup',
      });
    }

  }
  const updatedata = async(e)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append('name',name);
    formData.append('emp_id',emp_id);
     try {
        const response = await dashboardApi.post(`followup-attribute-update/${id}`, formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        CloseeditModal();
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the followup',
      });
    }

  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={handleOpenModal}>
                   +
                </Link>

                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Name</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {attributelist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.name}</td>
                  <td><button
                  type="button"
                  className={`btn btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 <td>
                 <Link className='btn btn-primary mb-2 float-end'onClick={() => OpeneditModal(row.id)}>
                    Edit
                </Link>
                 </td>
               </tr>
              ))}

            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Followup Details</DialogTitle>
        <DialogContent>
          <form>
            <div>
              <label>Attribute Name:</label>
              <TextField
                type="text"
                placeholder = "Enter Name"
                fullWidth
                 value={name}
                onChange={(e) => setname(e.target.value)}
              />
               {validationErrors.name&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                )}
            </div>

          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick={handlesave}>
            Save
          </Link>
        </DialogActions>
      </Dialog>

       <Dialog
        open={editopenModal}
        onClose={CloseeditModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Edit Followup Details</DialogTitle>
        <DialogContent>
          <form>
            <div>
              <label>Attribute Name:</label>
              <TextField
                type="text"
                placeholder = "Enter Name"
                fullWidth
                 value={name}
                onChange={(e) => setname(e.target.value)}
              />
               {validationErrors.name&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                )}
            </div>

          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={CloseeditModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick={updatedata}>
            Save
          </Link>
        </DialogActions>
      </Dialog>

    </DashboardLayout>
  );
}

export default FollowupDetails;