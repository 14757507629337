import axios from 'axios';

//const docBaseUrl = 'http://localhost:8000/';
const docBaseUrl = 'https://apiworkforce.r-ims.com/';

const docurl = () => {
  return docBaseUrl;
}

export default docurl;

