// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import  secureLocalStorage  from  "react-secure-storage";
import { Link } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Data
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import TextField from "@mui/material/TextField";
function CandidateProfile() {
  const { id } = useParams();
  const { job_id} = useParams();
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [receivelist, setreceivelist] = useState({});
  //const [ids,setid] = useState('');
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [followup_round, setfollowup_round] = useState('');
  const [proccess_status, setproccess_status] = useState('');
  const [proccess_date,setproccess_date] = useState('');
  const [proccess_time,setproccess_time] = useState('');
  const [final_status,setfinal_status] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [history,sethistory] = useState([]);
  const [followup_details,setfollowup_details] = useState([]);
  const [created_by_id,set_created_by_id] = useState('');
  const [remark,setremark] = useState('');
  //const [join_date,setjoin_date] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchData();
    fetchhistory();
   // fetchemployee();
   },[])
  const fetchData = async () => {
    try {
      const endpoint = `candidate-details/${id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setreceivelist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchhistory = async () => {
    try {
      const endpoint = `candidate-followup-history/${id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        sethistory(response.data.data);
        set_created_by_id(response.data.created_by_id)
        //setjoin_date(response.data.data.date_of_join);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  //console.log(join_date);

const handleOpenModal = async () => {
  //alert(request_id);
    //setid(request_id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setfollowup_round('');
    setproccess_status('');
    setproccess_date('');
    setproccess_time('');
    setfinal_status('');
    setremark('');
    setOpenModal(false);
  };
  const handlesave = async(e)=>{
    e.preventDefault();
    const errors = {};
    const formData = new FormData();
    formData.append('candidate_id',id);
    formData.append('proccess_status',followup_round);
    formData.append('status',proccess_status);
    formData.append('date',proccess_date);
    formData.append('time',proccess_time);
    formData.append('final_status',final_status);
    formData.append('emp_id',emp_id);
    formData.append('job_id',job_id);
    formData.append('remark',remark);

    try {
      const response = await dashboardApi.post('candidate-followup-save', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchData();
        handleCloseModal();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }

 }
 useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'active-followup-attribute';
      const response = await dashboardApi.get(endpoint);
      //console.log(response);

      if (response.data.status === 200) {
        setfollowup_details(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
const download_offer = async(id,job_id)=>{
   const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "Are You Want To Generate Offer Latter!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      return result.isConfirmed
    });
    
    if (!isConfirm) {
      return;
    }      
   try {
            setLoading(true);
            const response = await dashboardApi.get('download-offer-letter', {
                params: {
                    id: id,
                    job_id: job_id,
                },
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'offer_latter.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the report:', error);
        } finally {
            setLoading(false);
        }
  

 
}

//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
               <div className="card-body">
               <Link className="btn btn-info btn-sm" to='/view-position-details/'>
                      Back
                 </Link> 
                 &nbsp;
                <Link className="btn btn-info btn-sm" onClick={handleOpenModal}>
                      Add Followup
                 </Link>
                  
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  {
                    created_by_id == emp_id &&
                      <Link className="btn btn-info btn-sm" onClick={() => download_offer(id,job_id)} style={{float:'right'}}>
                      Generate Offer Latter
                 </Link>
                  }
                
                   <br/> <br/>
                   {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Process Round</td>
                  <td>Status</td>
                  <td>Proccess Date</td>
                  <td>Time</td>
                  <td>Final Status</td>
                  <td>Remark</td>
                  <td>created By</td>
                  <td>Created Date</td>
               </tr>
               {history.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.proccess_status}</td>
                  <td>{row.status}</td>
                  <td>{row.date}</td>
                  <td>{row.time}</td>
                  <td>{row.final_status}</td>
                  <td>{row.remark}</td>
                  <td>{row.created_by}</td>
                  <td>{row.created_date}</td>
                </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Add Followup</DialogTitle>
        <DialogContent>
        <form>
            <div class="container">
            <div class = "row">
            <div class = "col-md-6">
              <label>Followup Round:</label>
              <select className="form-control" value={followup_round} onChange={(e) => setfollowup_round(e.target.value)} fullWidth>
                <option value=" ">Change Status</option>
                {followup_details.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.name}
                              </option>
                              ))}
              </select>
               
             </div>
              <div className="col-md-6">
              <label>Process Status:</label>
              <select className="form-control" value={proccess_status} onChange={(e) => setproccess_status(e.target.value)} fullWidth>
                <option value=" ">Select Process Status</option>
                <option value="pending">Pending</option>
                <option value="selected">Selected</option>
                <option value="rejected">Rejected</option>
                <option value="scheduled">scheduled</option>
              </select>
            </div>

            </div>

            <div class = "row">
            <div class = "col-md-6">
              <label>Process Date:</label>
              <TextField
                type="date"
                fullWidth
                 value={proccess_date}
                onChange={(e) => setproccess_date(e.target.value)}
                 placeholder = "date"
              />
            </div>
              <div className="col-md-6">
             <label>Proccess Time:</label>
              <TextField
                type="time"
                fullWidth
                 value={proccess_time}
                onChange={(e) => setproccess_time(e.target.value)}
                 placeholder = "Resource"
              />
            </div>
            </div>
            <div class = "row">
            <div class = "col-md-6">
              <label>Final Status:</label>
              <select className="form-control" value={final_status} onChange={(e) => setfinal_status(e.target.value)} fullWidth>
                <option value=" ">Final Status</option>
                <option value="hold">Hold</option>
                <option value="selected">Selected</option>
                <option value="rejected">Rejected</option>
                <option value="processing">Processing</option>
              </select>
              </div>

            </div>
           <div className='row'>
            <div className="form-group col-sm-12">
            <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Remark</label>
            <textarea rows={5} cols={5} style={{height:'50px'}}
            value={remark}
            onChange={(e) => setremark(e.target.value)}
            className = "form-control"
            placeholder="Remark"
           />
            </div>

            </div>
            </div>

          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick ={handlesave}>
            Save
          </Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default CandidateProfile;