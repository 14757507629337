import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import  secureLocalStorage  from  "react-secure-storage";
import KYCDetails from "layouts/admin_process/kyc_details";
//const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
//const permission = localStorage.getItem('permission');

const AdminProcess = [
    {
        type: "collapse",
        name: "Admin Process",
        key: "Admin Process",
        icon: <Icon fontSize="small">person</Icon>,
        route: "/kyc-details",
        component: (token!=null) ?  <KYCDetails /> : <SignIn/> ,
        nested:[],
      },
  ];

export default AdminProcess;