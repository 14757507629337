import { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,Link } from "react-router-dom";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import PackageDetailsHeader from "layouts/common_components/package_details_header";
import Button from 'react-bootstrap/Button';
import  secureLocalStorage  from  "react-secure-storage";
// for modalbox
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import Form from 'react-bootstrap/Form';

function PackageCreation()
{
    const navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({});
    const emp_id = secureLocalStorage.getItem('emp_id');
    const token = secureLocalStorage.getItem('token');
    const [durationListing,setDurationListing] = useState([]);
    
    const [typeListing,setTypeListing] = useState([]);
    const [selectedPackageType,setSelectedPackageType] = useState();
    const [locationListing,setlocationListing] = useState([]);
    const [checked, setChecked] = useState([]);
    const [checkedLocality, setCheckedLocality] = useState([]);
    const [localityListing,setlocalityListing] = useState([]);
    const [selectedLocaity,setSelectedLocality] = useState();

    const [selectProduct,setProduct] = useState();
    const [activeProduct, setActiveProduct] = useState();
    
    const[selectService,setService] = useState();
    const[selectedService,setSelectedService] = useState();
    
    const[selectCategory,setCategory] = useState();
    const[selectedCategory,setSelectedCategory] = useState('');
    
    const [selecetedDuration,setSelectedDuration] = useState();
    const [selectedToLocation,setSelectedToLocation] = useState();

    const [packageDetails,setPackageDetails] = useState({});

    const [noOfLead,setNoOfLead] = useState();
    
    const checkPacakegeDetails = async() =>
    {
        const formData = new FormData();
        formData.append('product_id',activeProduct);
        formData.append('service_id',selectedService);
        formData.append('category_id',selectedCategory);
        formData.append('duration_id',selecetedDuration);
        formData.append('is_to_location',selectedToLocation);
        formData.append('package_type_id',selectedPackageType);
        formData.append('location_id',checkedItems);
        formData.append('noOfLead',noOfLead);
       
        try {
            const response = await dashboardApi.post('check-package-price-details', formData);
            if(response.data.status === 200)
            {
            console.log(response.data.data);
            setPackageDetails(response.data.data);

            navigate("/package-creation");
            

        } else if (response.data.status === 400) {
            setValidationErrors(response.data.messages);
        } else {
            Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
            });
        }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                text: 'An error occurred while creating the product',
            });
        } 

        
        
    }
    //alert(selectedCategory);

    useEffect(()=>{
        if(!token)
        {
            navigate('/sign-in');
        }
        else
        {
            getProductList();
            getDurationList();
            getTypeList();
            getLocationList();
          
        }
    },[token, navigate]);

    const Tab = styled.button`
        padding: 10px 30px;
        cursor: pointer;
        opacity: 0.6;
        background: white;
        border: 0;
        outline: 0;
        border-bottom: 2px solid transparent;
        transition: ease border-bottom 250ms;
        ${({ activeProduct }) =>
            activeProduct &&
            `
            border-bottom: 2px solid #17a2b8;
            opacity: 1;
        `}
        `;

    
   
    const getDurationList = async() =>{
        let type=1;
        const durationResponce = await dashboardApi.get(`get-duration-type-fact-list/${type}`);
        if(durationResponce.status==200)
        {
            setDurationListing(durationResponce.data.data);
        }
        else
        {
            console.log('something went wrong,Please Try Again');
        }
    }

    const getTypeList = async() =>{
        let type=2;
        const typeResponce = await dashboardApi.get(`get-duration-type-fact-list/${type}`);
        if(typeResponce.status==200)
        {
            setTypeListing(typeResponce.data.data);
        }
        else
        {
            console.log('something went wrong,Please Try Again');
        }
    }

    const getLocationList = async() =>{
       
        const locationResponce = await dashboardApi.get(`get-location-fact-list`);
        if(locationResponce.status==200)
        {
            setlocationListing(locationResponce.data.data);
            
        }
        else
        {
            console.log('something went wrong,Please Try Again');
        }
    }

    const getProductList = async() =>{
        const productResponce = await dashboardApi.get(`get-product-list`);
        if(productResponce)
        {
            setProduct(productResponce.data.data);
        }
        else
        {
            console.log('Something went wrong, Please try again.');
        }
    }

    useEffect(()=>{
    if(activeProduct)
    {
        const getServiceList = async() =>{
            const serviceResponce = await dashboardApi.get(`get-service-list-by-product-id/${activeProduct}`);
            if(serviceResponce)
            {
                setService(serviceResponce.data.data);
            }
            else
            {
                console.log('Something went wrong, Please try again.');
            }
        }
        getServiceList();
    }
    },[activeProduct]);

    
    useEffect(()=>{
        if(selectedService || activeProduct)
        {
            const getCategoryList = async() =>{
                const categoryResponce = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
                if(categoryResponce)
                {
                    setCategory(categoryResponce.data.data);
                }
                else
                {
                    console.log('Something went wrong, Please try again.');
                }
            }
            getCategoryList();
        }
        },[selectedService,activeProduct]);
    
    const checklocality = async() => 
    {
        if(checkedItems)
        {
            const localityResponce = await dashboardApi.get(`get-location-wise-locality-fact/${checkedItems}`);
            if(localityResponce.status==200)
            {
                setlocalityListing(localityResponce.data.data);
               
            }
            else
            {
                console.log('something went wrong,Please Try Again');
            }
        }
        else
        {
            alert('Please select atleast one or more location');
        }
    }


    const handleCheck = (event) => {
        var updatedList = [...checked];
        if(event.target.checked)
        {
          updatedList = [...checked, event.target.value];
        }else {
          updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };
    

    const handleCheckLocality = (event) => {
        var updatedList = [...checkedLocality];
        if (event.target.checked) {
          updatedList = [...checkedLocality, event.target.value];
        } else {
          updatedList.splice(checkedLocality.indexOf(event.target.value), 1);
        }
      
        setCheckedLocality(updatedList);
    };

    const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

    

    const checkedLocalityItem = checkedLocality.length ? 
        checkedLocality.reduse((totallocality, localityItem)=>{
        return totallocality +"," + localityItem;
    }):"";

    const handleChangeLocality = () => {
        setCheckedLocality(current => !current);
        
    };
    
    
    return(
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <PackageDetailsHeader></PackageDetailsHeader>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                                    
                                        <p style={{ margin: "0 10px" }}>Package Creation</p>
                                    
                                        <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                                    </div>
                                    
                                    <div className="row">
                                        <div class="col-sm-12">
                                            <label>Product :</label>
                                                {selectProduct && selectProduct.map((pro) => (
                                                <Tab
                                                    key={pro.id}
                                                    activeProduct={activeProduct === pro.id}
                                                    onClick={() => setActiveProduct(pro.id)}
                                                >
                                                    {pro.product_name}
                                                </Tab>
                                                ))}
                                        </div>

                                        
                                        <div class="col-sm-12">
                                            <label>Service :</label>
                                                {selectService && selectService.map((serv) => (
                                                <Button variant="outline-info" style={{width:'auto',margin:'1%', textAlignLast: 'left'}}>
                                                    <input type="radio" style={{width:'40px',height:'20px'}} name="service" value={serv.id} onChange={(e)=>{setSelectedService(e.target.value)}}/>
                                                    {serv.service_name}
                                                </Button>
                                                ))}
                                        </div>

                                        <div class="col-sm-12">
                                            <label>Category :</label>
                                                {selectCategory && selectCategory.map((cate) => (
                                                <Button variant="outline-info" style={{width:'auto',margin:'1%', textAlignLast: 'left'}}>
                                                    <input type="radio" style={{width:'40px',height:'20px'}} name="category" value={cate.id} onChange={(e)=>{setSelectedCategory(e.target.value)}}/>
                                                    {cate.category_name}
                                                </Button>
                                                ))}
                                        </div>
                                        {activeProduct ===1 && 
                                            <div className="col-sm-12">
                                                <label className="formControl">Package Type : </label>
                                                {typeListing && typeListing.map((type)=>(
                                                    <Button Button variant="outline-info" style={{width:'auto',margin:'1%',textAlignLast: 'left'}}>
                                                        <input type="radio" style={{width:'40px',height:'20px'}} name="packageType" value={type.id} onChange={(e)=>{setSelectedPackageType(e.target.value)}}/>
                                                        {type.name}
                                                    </Button>
                                                ))}
                                            </div>
                                        }


                                        <div className="col-sm-6">
                                            <label>Duration</label>
                                            {durationListing && durationListing.map((dura)=>(
                                                <Button Button variant="outline-info" style={{width:'auto',margin:'1%', textAlignLast: 'left'}}>
                                                    <input type="radio" style={{width:'40px',height:'20px'}} name="durationType" value={dura.id} onChange={(e)=>{setSelectedDuration(e.target.value)}}/>
                                                    {dura.name}
                                                </Button>
                                            ))}

                                        </div>
                                        {activeProduct ===1 &&
                                        <div className="col-sm-12">
                                            <label className="formControl">Is to Location ? : </label>
                                                <Button Button variant="outline-info" style={{width:'auto',margin:'1%',
                                                textAlignLast: 'left'}}>
                                                    <input type="radio" style={{width:'40px',height:'20px'}} name="isToLocation" defaultChecked={true} value="0" onChange={(e)=>{setSelectedToLocation(e.target.value)}}/>
                                                    No
                                                </Button>

                                                <Button Button variant="outline-info" style={{width:'auto',margin:'1%',margin: '2%',textAlignLast: 'left'}}>
                                                    <input type="radio" style={{width:'40px',height:'20px'}} name="isToLocation" value="1" onChange={(e)=>{setSelectedToLocation(e.target.value)}}/>
                                                    Yes
                                                </Button>
                                        </div>
                                        }
                                        
                                       
                                        <div className="col-sm-12">
                                            <label className="formControl">Location : </label>

                                            {locationListing && locationListing.map((loc)=>(
                                                <Button Button variant="outline-info" style={{width:'auto',margin:'1%',textAlignLast: 'left'}}>
                                                    <input style={{width:'40px',height:'20px'}} value={loc.location_id} type="checkbox" onChange={handleCheck} />
                                                    <span>{loc.name}</span>
                                                </Button>
                                            ))}
                                            <div style={{textAlign: 'end'}}>
                                                <Button className="btn btn-primary" onClick={checklocality}>Check Locality</Button>
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            {localityListing && localityListing.map((locName)=>{
                                                
                                                return <div key={locName.loc_id}>
                                                    <h5> Locality of {locName.loc_name}</h5>
                                                   
                                                    {locName.locality.map((localityDetails) => {

                                                    return <Button Button variant="outline-primary" style={{width:'auto',margin: '1%',textAlignLast: 'left'}}>

                                                    <input style={{width:'30px',height:'15px'}} name="locality" value={locName.loc_id + localityDetails.locality_id} type="checkbox" defaultChecked={true} onChange={handleCheckLocality} />

                                                    <span>{localityDetails.locality_name}</span>
                                                </Button>
                                                
                                                
                                                    })}
                                                </div>

                                                
                                            })}
                                        </div>

                                        {selectedToLocation ===1 &&
                                        <div className="col-sm-12">
                                            <label className="formControl">To Location : </label>

                                            {locationListing && locationListing.map((loc)=>(
                                                <Button Button variant="outline-info" style={{width:'auto',margin:'1%',textAlignLast: 'left'}}>
                                                    <input style={{width:'40px',height:'20px'}} value={loc.location_id} type="checkbox" name="toLocation"  />
                                                    <span>{loc.name}</span>
                                                </Button>
                                            ))}
                                            <div style={{textAlign: 'end'}}>
                                                <Button className="btn btn-primary" onClick={checklocality}>Check To Locality</Button>
                                            </div>
                                        </div>
                                        }

                                        {selectedToLocation ===1 &&
                                        <div className="col-sm-12">
                                        {localityListing && localityListing.map((locName)=>{
                                            
                                            return <div key={locName.loc_id}>
                                                <h5> Locality of {locName.loc_name}</h5>
                                            
                                                {locName.locality.map((localityDetails) => {

                                                return <Button Button variant="outline-primary" style={{width:'auto',margin: '1%',textAlignLast: 'left'}}>

                                                <input style={{width:'30px',height:'15px'}} name="locality" value={locName.loc_id + localityDetails.locality_id} type="checkbox" defaultChecked={true} onChange={handleCheckLocality} />

                                                <span>{localityDetails.locality_name}</span>
                                            </Button>
                                            
                                            
                                                })}
                                            </div>
                                            })}
                                        </div>
                                        }

                                        <div className="col-sm-12">
                                            <div className="row">

                                                <div className="col-sm-6">
                                                    <label className="formControl">No Of Lead</label>
                                                    <input className="form-control" type="text" name="no_of_lead" id="no_of_lead" value={noOfLead} onChange={(e)=>{setNoOfLead(e.target.value)}} />
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <br/>
                                            <button className="btn btn-primary" onClick={checkPacakegeDetails}>Check Package</button>
                                        </div>
                                        

                                    </div>
                                        {packageDetails !=='' &&
                                        <div className="col-sm-12">
                                            <div className="col-sm-6">
                                            Package Price {packageDetails.totalPrice}
                                            </div>
                                         
                                        </div>
                                    }
                                      
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />

              
            </DashboardLayout>
    );
}
export default PackageCreation;