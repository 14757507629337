import React, { useState,useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import dashboardApi from "layouts/common_components/apibase_url";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Swal from 'sweetalert2';
function ClientProfileHeader() 
{
  const empId = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { clientGroup } =useParams();
  const [validationErrors, setValidationErrors] = useState({});
  const [clientDetails,setClientDetails] = useState({});
  const [packageCount,setPackageCount] = useState({});
  const [walletHistory,setWalletDetails] = useState({});
  const [paymentModal,setPaymentModal] = useState(false);

  const [newAmount,setNewAmount] = useState();
  const [paymentType,setPaymentType] = useState();
  const [bankName,setBankName] = useState();
  const [cashType,setCashType] = useState();
  const [utrNo,setUtrNo] = useState();
  const [transactionId,setTransactionId] = useState();
  const [paymentDate,setPaymentDate] = useState();
  const [remarks,setRemarks] = useState();
  const [wallet_id,setWalletId] = useState();
  //const [wallet_amount,setWalletAmount] = useState();
  let clientType = 0;//for guest user
  const addWalletAmount = async(e) =>
  {
    e.preventDefault();
    const errors = {};
    if (Object.keys(errors).length > 0)
    {
      return;
    }

    const formSubmit = new FormData();
    formSubmit.append('amount',newAmount);
    formSubmit.append('payment_type',paymentType);
    formSubmit.append('bank_name',bankName);
    formSubmit.append('cash_type',cashType);
    formSubmit.append('utr_no',utrNo);
    formSubmit.append('transaction_id',transactionId);
    formSubmit.append('payment_date',paymentDate);
    formSubmit.append('remarks',remarks);
    formSubmit.append('created_by',empId);
    formSubmit.append('client_id',clientId);
    formSubmit.append('wallet_id',wallet_id);
    const clear_data = ()=>{

    }
    
      
    try {
      const response = await dashboardApi.post('add-new-wallet-amount', formSubmit);
      //console.log('kkk',response.data.status);
      if(response.data.status === 200)
      {
          Swal.fire({
              icon: 'success',
              text: response.data.message,
          });
          setPaymentModal(false);
        //navigate("client-profile/1");
      }
      else if (response.data.status === 201)
      {
        //alert();
        setPaymentModal(false);
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });
        //setValidationErrors(response.data.messages);
      } 
      else
      {
          Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
          });
      }
    }
    catch (error)
    {
      Swal.fire({
          icon: 'error',
          text: 'An error occurred while creating the product',
      });
    } 
  }

  const paymentOpenControl=()=>{
    setPaymentModal(true);
  }
  
  const closePaymentControl = ()=>
  {
    setPaymentModal(false);
  }
  
  useEffect(()=>{
    checkClientDetails();
    getClientPackage();
    getWalletDetails();
  },[])
  
    const checkClientDetails = async () =>
    {
      try {
          const response = await dashboardApi.get(`check-client-details/${clientId}`);
          if (response.data.status === 200)
          {
            setClientDetails(response.data.data);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        }
    }
    const getClientPackage = async () =>
    {
      try {
          const response = await dashboardApi.get(`get-package-count/${clientId}`);
          if(response.data.status === 200)
          {
            setPackageCount(response.data.data);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        }
    }

    const getWalletDetails = async () =>
    {
      try{
          const response = await dashboardApi.get(`get-wallet-details/${clientId}/${clientType}`);
          if(response.data.status === 200)
          {
            setWalletDetails(response.data.data);
            setWalletId(response.data.data.wallet_id);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        }
    }

    return(
      <>
        <div className="d-flex flex-wrap" style={{width:'100%',overflowY: "hidden"}}>
            <div className="col-sm-4 col-12 p-2">
              <div className="p-2 h-100 border rounded"  style={{backgroundColor: "#f0f2f5"}}>  
                <span style={{display:'flex'}}><h5>Client Information</h5><input type="radio" color="success"  name="" value="" style={{backgroundColor:'green',border:'green',marginLeft: '50%',color:'green'}} checked/> 
                </span>
                <h6><b>Client Type :</b> {clientDetails.status === 0 ? 'Guest Client':'Mature Client'}</h6>
                <h6><b>Name : </b> {clientDetails.name}</h6>
                <h6><b>Email Id: </b>{clientDetails.email_id}</h6>
                <h6><b>Mobile No : </b>{clientDetails.mobile_no}</h6>
                <h6><b>City : </b>{clientDetails.city_name} ({clientDetails.state})</h6>
                <h6><b>Address : </b>{clientDetails.address} ({clientDetails.zipcode})</h6>
              </div>
            </div>
              
            <div className="col-sm-4 col-12 p-2">
              <div className="p-2 h-100 border rounded"  style={{backgroundColor: "#f0f2f5"}}>
                <span style={{display:'flex'}}><h5>Package Information</h5><input type="radio" color="success"  name="" value="" style={{backgroundColor:'green',border:'green',marginLeft: '45%',color:'green'}} checked/> </span>
                <h6><b>Total Packages :  </b> {packageCount.total_package}  </h6>
                <h6><b>Active Package :  </b> {packageCount.active_package}</h6>
                <h6><b>Deactive Packages : </b> {packageCount.deactive_package}</h6> 
              </div>
            </div>

            <div className="col-sm-4 col-12 p-2" >
              <div className="p-2 h-100 border rounded"  style={{backgroundColor: "#f0f2f5"}}>
                <h6><b>Account & Wallet Information</b></h6>
                <h6><b>IFSC Code :</b> ICIC0000104</h6>
                <h6><b>Branch Name :</b> Mumbai</h6>
                <h6><b>Wallet Id :</b> RIMS-0{walletHistory && walletHistory.client_id}-0{walletHistory && walletHistory.wallet_id}</h6>
                <h6><b>Credit Amount : </b> {walletHistory && walletHistory.credit_amount} </h6>
                <h6><b>Debit Amount : </b> {walletHistory && walletHistory.debit_amount} </h6>
                <h6><b>Balance Amount : </b> {walletHistory && walletHistory.balance_amount} </h6>
              </div>
            </div>
        </div>

        <Dialog open={paymentModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Add Amount</DialogTitle>
          <DialogContent style={{height:"350px"}}>
            <form onSubmit={addWalletAmount}>
                <div className="row">
                  
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Add Wallet Amount</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-6">
                      <label>Enter Amount</label>
                      <input type="text" name="enter_amount" className="form-control" value={newAmount} onChange={(e)=>setNewAmount(e.target.value)} required />
                  </div>

                  <div className="col-sm-6">
                      <label>Payment Type</label>
                      <select className="form-control"
                        value={paymentType} onChange={(e)=>setPaymentType(e.target.value)} required>
                          <option value=''>Select Payment Type</option>
                          <option value='Cash'>Cash</option>
                          <option value='Cheque'>Cheque</option>
                          <option value='DD'>DD</option>
                          <option value='Online'>Online</option>
                          <option value='PayU'>PayU</option>
                          <option value='Razor Pay'>Razor Pay</option>
                      </select>
                  </div>

                  {paymentType ==='Cash' &&
                    <div className="col-sm-6">
                        <label>Select Cash Type</label>
                        <select className="form-control" value={cashType} onChange={(e)=>setCashType(e.target.value)}>
                            <option value=''>Select Cash Type</option>
                            <option value='cash_in_hand'>Cash In Hand</option>
                            <option value='Bank'>Bank Deposit</option>
                        </select>
                    </div>
                  }

                {(cashType==='Bank' || paymentType !=='Cash') &&
                  <div className="col-sm-6">
                      <label>Select Bank Name</label>
                      <select className="form-control" value={bankName} onChange={(e)=>setBankName(e.target.value)}>
                          <option value=''>Select Bank Name</option>
                          <option value='icici'>ICICI</option>
                          <option value='sbi'>SBI</option>
                          <option value='pnb'>PNB</option>
                          <option value='phone pay'>Phone Pay</option>
                          <option value='g_pay'>Google Pay</option>
                      </select>
                  </div>
                }
                  

                  <div className="col-sm-6">
                      <label>Enter UTR No</label>
                      <input type="text" name="utr_no" className="form-control" value={utrNo} onChange={(e)=>setUtrNo(e.target.value)} />
                  </div>

                  <div className="col-sm-6">
                      <label>Enter Transaction Id</label>
                      <input type="text" name="txn_no" className="form-control" value={transactionId} onChange={(e)=>setTransactionId(e.target.value)} required/>
                  </div>

                  <div className="col-sm-6">
                      <label>Payment Date</label>
                      <input type="date" name="txn_date" className="form-control" value={paymentDate} onChange={(e)=>setPaymentDate(e.target.value)} required/>
                  </div>

                  <div className="col-sm-6">
                      <label>Remarks</label>
                      <input type="text" name="remarks" className="form-control" value={remarks} onChange={(e)=>setRemarks(e.target.value)} required/>
                  </div>
            
                  
                </div>
            </form>
          </DialogContent>

          <DialogActions>
              <Link className="btn btn-primary btn-sm" onClick={closePaymentControl}>Close</Link>
            
              <Link className="btn btn-success btn-sm" onClick={addWalletAmount} >Add Payment</Link>
           
          </DialogActions>
      </Dialog>
      </>
    );
}
export default ClientProfileHeader;
