// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import Switch from 'react-switch';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function Country() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [country, setCountry] = useState([])
  const [loading, setLoading] = useState(true);
 const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];
const add_country = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-country");
const edit_country = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-country");
const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
   useEffect(()=>{
        fetchCountry() 
    },[])
   const fetchCountry = async () => {
    try {
      const response =  await dashboardApi.get('country-list');
      if (response.data.status === 200) {
        setCountry(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };
const toggleStatus = async (countryId) => {
   const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response =  await dashboardApi.get(`country-status/${countryId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      //console.log(response.data.data);
      // Calculate the new status (toggle)
      const newStatus = currentStatus === 1 ? 0 : 1;
      // Make an API call to update the status
      const updateResponse =  await dashboardApi.post('country-change-status', {
        country_id: countryId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setCountry((prevState) =>
          prevState.map((countryObj) =>
            countryObj.id === countryId ? { ...countryObj, status: newStatus } : countryObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
             {add_country && (
              <Link className='btn btn-primary mb-2 float-end' to={"/add-country"}>
                    Add Country
                </Link>
                )}
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Name</td>
                  <td>Short Name</td>
                  {change_status && (
                  <td>Status</td>
                 )}
                 {edit_country && (
                  <td>Action</td>
                )}
               </tr>
               {country.map((countryObj, index) => (
                <tr key={countryObj.id}>
                  <td>{index + 1}</td>
                  <td>{countryObj.name}</td>
                  <td>{countryObj.short_name}</td>
                 {change_status && (
                  <td><button
                  type="button"
                  className={`btn btn-${countryObj.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(countryObj.id)}
                  >
                  {countryObj.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 )}
                {edit_country && (
                  <td>
                  <Link to={`/edit-country/${countryObj.id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>
            
                  </td>
                  )}
               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Country;
