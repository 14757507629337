import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate,useParams } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import ReactPaginate from "react-paginate";
import secureLocalStorage from "react-secure-storage";
import EmpHistoryHeader from 'layouts/common_components/emp_profile_header';

function EmpLeaveHistory() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const navigate = useNavigate();
  const [loading,setloding] = useState(false);
  const { id } = useParams();
  const [list,setlist] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `emp-leave-history/${id}`;
        const response = await dashboardApi.get(endpoint);
  
        if (response.data.status === 200) {
            setlist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              {/* <div className="container-fluid">
                <div className="row">
                  <div className="col-md-2">
                  <button className="btn btn-info">Salary History</button> 
                  </div>
                  <div className="col-md-2">
                  <button className="btn btn-info">Leave History</button> 
                  </div>
                  <div className="col-md-2">
                  <button className="btn btn-info">Wfh History</button> 
                  </div>
                  <div className="col-md-2">
                  <button className="btn btn-info">Stock History</button> 
                  </div>
                  <div className="col-md-2">
                  <button className="btn btn-info">Target History</button> 
                  </div>
                  <div className="col-md-2">
                  <button className="btn btn-info">Reporting</button> 
                  </div>

                </div>

              </div> */}
              <EmpHistoryHeader emp_id = {id}></EmpHistoryHeader>
              <br/>
               
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered table-striped">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Leave Type</td>
                        <td>Date From</td>
                        <td>Date To</td>
                        <td>No Of Days </td>
                        <td>Half Day </td>
                        <td>Message</td>
                        <td>Rejection reason</td>
                        <td>Action By</td>
                        <td>Status</td>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.leave_name}</td>
                          <td>{row.date_from}</td>
                          <td>{row.date_to}</td>
                          <td>{row.no_of_days}</td>
                          {
                            row.is_half_day == 0 && 
                            <td>No</td>
                          }
                          {
                            row.is_half_day == 1 && 
                            <td>Yes</td>
                          }
                           <td>{row.message}</td>
                           <td>{row.rejection_reason}</td>
                           <td>{row.emp_fname}</td>
                           {
                            row.status ==0 && 
                            <td>Pending</td>
                           }
                          {
                            row.status ==1 && 
                            <td>Approved</td>
                           }
                           {
                            row.status ==2 && 
                            <td>Rejected</td>
                           }
                           
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmpLeaveHistory;
