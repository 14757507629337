import React, { useEffect, useState, Component  } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import { useNavigate,Link } from "react-router-dom";
import Footer from "theme_layout/Footer";
import PackageHeader from "layouts/common_components/package_header";
import dashboardApi from "layouts/common_components/apibase_url";
import styled from "styled-components";
import Swal from 'sweetalert2';
import { MultiSelect } from "react-multi-select-component";
//import LocalStorage from "react-secure-storage";
import { YouTube } from "@mui/icons-material";
import DescriptionPopup from "layouts/common_components/description_popup";
import CreateIcon from '@mui/icons-material/Create';
import { useRowSelect } from "react-table";
import  secureLocalStorage  from  "react-secure-storage";

function LeadBasedPackage()
{
    const navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({});
    const emp_id = secureLocalStorage.getItem('emp_id');
    const token = secureLocalStorage.getItem('token');

    const [productList,setProductList] = useState();
    const [selectedProduct,setSelectedProduct] = useState();
    const [serviceList,setServiceList] = useState();
    const [selectedService,setSelectedService] = useState();

    const[categoryList,setCategoryList] = useState([]);
    const[selectedCategory,setSelectedCategory] = useState('');
    const [loading, setLoading] = useState(true);

    const [attributeDetails,setAttributeDetails]=useState([]);
    const [selectedAttributeDetails,setSelectedAttributeDetails]=useState([]);

    const [attributePrice, setAttributePrice] = useState();

    const attributeValue = selectedAttributeDetails.map(e => e.value).join(',');

    //const [pkgTypeList,setPkgTypeList] = useState();
   // const [selectedPkgType,setSelectedPkgType]= useState();

    const [groupList,setGroupList] = useState();
    const [selectedGroup,setSelectedGroup]= useState();

    const [localityList,setLocalityList] = useState({});
    const [selectedLocality,setSelectedLocality]= useState([]);
    const [selectedToLocation,setSelectedToLocation]= useState();
    
    
    const citiesValue = selectedLocality.map(e => e.value).join(',');
    
    const[leadBasePrice,setLeadBasePrice]= useState();
    const[leadDevidePercent,setLeadDevidePercent]= useState();
    const[singleCatPriceInc,setSingleCatPriceInc]= useState();
    const[minimumLeadCount,setMinimumLeadCount]= useState();
    const[singleCatMinLeadCount,setSingleCatMinLeadCount]= useState();
    const[dropAfterLeadCount,setDropAfterLeadCount]= useState();
    const[dropLeadRateBetween,setDropLeadRateBetween]= useState();
    const[dropRatePrice,setDropRatePrice]= useState();
    const[maximumLeadDrop,setMaximumLeadDrop]= useState();
    const[toLocationPrice,setToLocationPrice]= useState();
    const[perDayLead,setPerDayLead] = useState();
    const[minimumDaysCount,setMinimumDaysCount] = useState();
    const[singleCatMinDaysCount,setSingleCatMinDaysCount]= useState();
    const[locationPrice,setLocationPrice] = useState();

    const [leadBasedDetails, setLeadBasedDetails] = useState();
    const [unlimitedBasedDetails, setUnlimitedBasedDetails] = useState();
    
    const [prePackageDetails, setPrePackageDetails] = useState();
    const [packageDuration, setPackageDuration] = useState();
    const [selectedDuration, setSelectedPkgDuration] = useState();

    const [leadBasedLocationFactor,setLeadBasedLocationFactor] = useState([]);

    const [unlimitedLocationFactor,setUnlimitedLocationFactor]= useState([]);
    
    const [packageCategory,setPackageCategory] = useState();

    const [selectedPackageCategory,setSelectedPackageCategory] = useState();
    const [hoveredRowData, setHoveredRowData] = useState(null);

    const [editStatus,setEditStatus] =useState();
    const [factorEditStatus,setFactorEditStatus] = useState();
    const [unlimitedFactorEditStatus,setUnlimitedFactorEditStatus]=useState();

    const [editPackageId,setEditPackageId] =useState();
    const [leadbaseFactorId,setLeadbaseFactorId]=useState();
    const [unlimitedFactorId,setUnlimitedFactorId]=useState();

    const [editUnlimitedStatus, setEditUnlimitedStatus] = useState();
    const [editunlimitedPackageId,setEditunlimitedPackageId] = useState();

    const [selectedPartialPayment,setPartialPayment]=useState();

    const [taxAndCharges,settaxAndCharges] = useState({});

    const [selectedPackageCategoryType,setSelectedPackageCategoryType] = useState();
    const [packageSelectedService,setPackageSelectedService] = useState([]);
    const [totalLead , setTotalLead]=useState();
    const [serviceData,setServiceData] = useState([]);
    const [totalPackagePrice, setPackagePrice] = useState();

    const [attributePriceDetails,setAttributeProiceDetails] = useState([]);

    const [taxAmount, setTaxAmount] = useState(0);
    const [serviceAmount, setServiceAmount] = useState(totalPackagePrice * (taxAndCharges.service_charges/100));
    const [totalAmount, setTotalAmount] = useState();
    const [packageTitle, setPackageTitle] = useState();
    const Tab = styled.button`
    font-size: 16px;
    padding: 10px 10px;
    margin-right:10px;
    cursor: pointer;
    opacity: 0.6;
    color:black;
    border: 0;
    outline: 0;
    ${({ active }) => active && `border-bottom: 4px solid #0e3a6a; opacity: 1;`}`;

    const ButtonGroup = styled.div`display: flex;`;
    const topMenuBar = ["Lead Based Package","Lead Based Group Factor","Unlimited Package","Unlimited Group Factor","Attribute Details","Add New Package","Package Details"];
    const [activeMenu, setActive] = useState(topMenuBar[2]);
    const array = 
  ['When a package is created with multiple categories, the leads are distributed among the selected categories based on the percentage allocation. For instance, if there are a total of 100 leads, the distribution among the household category is determined by its allocated percentage',
  'In a single-category package, the price increase for the selected category is specified after reaching a certain lead threshold. For example, once 50 leads are reached, the price will increase by 10 rupees',
  'When a package includes multiple categories, the minimum lead count for the package is specified when a specific category is selected. For example, if household is chosen, the minimum package lead count is set to 50',
  'When creating a single-category package, a minimum lead count is specified. For example, a household package must have a minimum of 40 leads, no fewer',
  'The base price for leads decreases after reaching the specified number.This is minimum number where price drop starts.For example, if the base price for household leads is initially 260, it will decrease to 255 after 60 leads',
  'The base price for leads decreases after (EVERY) specified number of leads increase. For example, if the base price for household leads is initially 260, it will decrease to 255 after 60, it will then decrease to 250 after 70,so after every 10 leads the base price will decrease by 5 rupees',
  'The amount by which the base price decreases after each drop in lead count is specified. For example, if the base price for household leads is initially 260, it will decrease to 255 after 60 leads. The amount of decrease, in this case, is 5 rupees',
  'The maximum number of leads up to which the base price will decrease is specified. For example, the base price of household leads will decrease up to 300 leads. After that point, there will be no further drop in the base price',
  'The increment in base price for each lead is specified when the TO location is selected. For example, if the base price for household leads is initially 260 and a TO location package is selected, the base price will increase by 10 rupees for every lead'];

    const unlimitedPackages = ['When a package is created with multiple categories, the leads are distributed among the selected categories based on the percentage allocation. For instance, if there are a total of 100 leads, the distribution among the household category is determined by its allocated percentage',
    'In a single-category package, the price increase for the selected category is specified after reaching a certain lead threshold. For example, once 50 leads are reached, the price will increase by 10 rupees',
    'When a package includes multiple categories, the minimum lead count for the package is specified when a specific category is selected. For example, if household is chosen, the minimum package lead count is set to 50',
    'When creating a single-category package, a minimum lead count is specified. For example, a household package must have a minimum of 40 leads, no fewer',
    'The base price for leads decreases after reaching the specified number.This is minimum number where price drop starts.For example, if the base price for household leads is initially 260, it will decrease to 255 after 60 leads',
    'The base price for leads decreases after (EVERY) specified number of leads increase. For example, if the base price for household leads is initially 260, it will decrease to 255 after 60, it will then decrease to 250 after 70,so after every 10 leads the base price will decrease by 5 rupees',
    'The amount by which the base price decreases after each drop in lead count is specified. For example, if the base price for household leads is initially 260, it will decrease to 255 after 60 leads. The amount of decrease, in this case, is 5 rupees',
    'The maximum number of leads up to which the base price will decrease is specified. For example, the base price of household leads will decrease up to 300 leads. After that point, there will be no further drop in the base price',
    'The increment in base price for each lead is specified when the TO location is selected. For example, if the base price for household leads is initially 260 and a TO location package is selected, the base price will increase by 10 rupees for every lead'];


    const onmouseover = (e)=>{
        setHoveredRowData(e);
    }
    const onmouseleave = (e)=>{
        setHoveredRowData('');
    }    
    
    useEffect(()=>{
        if(selectedProduct && selectedService && selectedCategory && activeMenu=='Attribute Details')
        {
          const fetchAttribute = async () => {
            try {
              const response = await dashboardApi.get(`get-attribute-list-by-id/${selectedProduct}/${selectedService}/${selectedCategory}`);
              if (response.data.status === 200) {
                setAttributeDetails(response.data.data);
              } else {
                console.error('Error fetching countries:', response.data.message);
              }
              setLoading(false);
            } catch (error) {
              console.error('Error fetching countries:', error);
              setLoading(false);
            }
          }
          fetchAttribute();
        }
      },[selectedProduct,selectedService,selectedCategory,activeMenu=='Attribute Details']);



    const serviceChekedHandler = (id) => {
        
        const newServiceChecked = [...packageSelectedService];
        const index = newServiceChecked.indexOf(id);
        if (index === -1)
        {
            newServiceChecked.push(id);
        } else {
            newServiceChecked.splice(index, 1);
        }
        setPackageSelectedService(newServiceChecked);
    };
    //var checked_category_id_list = category_check_list.toString();
    
    const checkPackagePrice = async(e)=>{
        e.preventDefault();
        const errors = {};
        
        //formDataSubmit.append('package_price',totalPackagePrice);

        if(!selectedProduct){
            errors.selectedProduct = 'Product is required';
        }

        if(!selectedService){
            errors.service = 'Service is required';
        }

        

        if(packageSelectedService.length < 1){
            errors.packageSelectedService = 'Minimum 1 category is required';
        }
        if(!selectedGroup){
            errors.selectedGroup = 'Group is required';
        }
        

        if(!selectedToLocation){
            errors.selectedToLocation = 'To location is required';
        }
        

        if(!selectedDuration){
            errors.selectedDuration = 'Duration is required';
        }

        if(!totalLead){
            errors.totalLead = 'Total lead is required';
        }


        if(!selectedPackageCategory){
            errors.selectedPackageCategory = 'Package category is required';
        }

        if(citiesValue.length < 1){
            errors.citiesValue = 'Minimum 1 cities is required';
        }
        
        setValidationErrors(errors);
        if(Object.keys(errors).length > 0)
        {
          return; 
        }
        
        const formDataCheck = new FormData();
        formDataCheck.append('product_id',selectedProduct);
        formDataCheck.append('services',selectedService);
        formDataCheck.append('category',packageSelectedService);
        formDataCheck.append('group_id',selectedGroup);
        //formDataCheck.append('package_type',selectedPkgType);
        formDataCheck.append('to_location',selectedToLocation);
        formDataCheck.append('package_duration',selectedDuration);
        formDataCheck.append('total_leads',totalLead);
        //let element = '';
        
        try
        {
            const response = await dashboardApi.post('check-package-price', formDataCheck);
            if(response.data.status === 200)
            {
                setPackagePrice(response.data.total_price);
                setTaxAmount(response.data.total_price * taxAndCharges.gst_percent/100);
                setServiceAmount(response.data.total_price * taxAndCharges.service_charges/100);
                setTotalAmount(response.data.total_price + (response.data.total_price * taxAndCharges.gst_percent/100) + (response.data.total_price * taxAndCharges.service_charges/100));

                setTotalLead(response.data.total_leads);
                setServiceData(JSON.stringify(response.data.data));
                const dataArray = response.data.data;
                
                Object.keys(dataArray).map(function(key)
                {
                    const element = document.getElementById("leadCount_"+key);
                    //element.innerHTML = document.getElementById("leadCount_"+key);
                    element.innerHTML = ' &nbsp;&nbsp; Lead : '+dataArray[key].total_lead+', &nbsp;&nbsp;&nbsp; Price : '+dataArray[key].price;
                });
                //element.innerHTML ='';
            }
            else if (response.data.status === 400)
            {
              
                Swal.fire({
                    icon: 'error',
                    text: response.data.messages,
                    });
            } 
            else
            {
                Swal.fire({
                icon: 'error',
                text: response.data.messages || 'An error occurred',
                });
            }
        } catch (error) {
          Swal.fire({
              icon: 'error',
              text: 'An error occurred while check the category base price',
          });
        } 
    }

    const createPrePackage = async(e)=>{
        e.preventDefault();
        const errors = {};
        if(!selectedPartialPayment){
            errors.selectedPartialPayment = 'Partial payment is required';
        }

        if(!packageTitle){
            errors.packageTitle = 'Package title is required';
        }
        setValidationErrors(errors);
        if (Object.keys(errors).length > 0)
        {
          return; 
        }
        
        const formDataSubmit = new FormData();
        formDataSubmit.append('product_id',selectedProduct);
        formDataSubmit.append('service_id',selectedService);
        formDataSubmit.append('category_id',packageSelectedService);
        formDataSubmit.append('group_id',selectedGroup);
        formDataSubmit.append('city_id',citiesValue);
        //formDataSubmit.append('package_type',selectedPkgType);
        formDataSubmit.append('to_location',selectedToLocation);
        formDataSubmit.append('package_duration',selectedDuration);
        formDataSubmit.append('service_details',serviceData);
        formDataSubmit.append('package_category',selectedPackageCategory);
        formDataSubmit.append('total_leads',totalLead);
        formDataSubmit.append('package_price',totalPackagePrice);
        formDataSubmit.append('created_by',emp_id);
        formDataSubmit.append('is_partial_payment',selectedPartialPayment);
        formDataSubmit.append('tax_amount',taxAmount);
        formDataSubmit.append('service_charges',serviceAmount);
        formDataSubmit.append('total_amount',totalAmount);
        formDataSubmit.append('package_title',packageTitle);

         
        try
        {
          const response = await dashboardApi.post('create-pre-package', formDataSubmit);
          //console.log(response);
            if(response.data.status === 200)
            {
                Swal.fire({
                    icon: 'success',
                    text: response.data.message,
                });
            } 
            else if (response.data.status === 400)
            {
                Swal.fire({
                    icon: 'success',
                    text: response.data.message,
                });
              //setValidationErrors(response.data.messages);
            } 
          else
          {
              Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
              });
          }
        } catch (error) {
          Swal.fire({
              icon: 'error',
              text: 'An error occurred while creating the product',
          });
        }
    }
    console.log(serviceList);
    const [basePrice, setBasePrice]=useState();
    const handleLeadFormSubmit = async(e) =>
    {
       
        e.preventDefault();
        const errors = {};
        if(!selectedProduct)
        {
            errors.product = 'Product is required';
        }
        if(!selectedService)
        {
            errors.service = 'Service is required';
        }
        

        if(!leadBasePrice){
            errors.lead_base_price = 'Base price is required';

        }
        if(!leadDevidePercent){
            errors.lead_devide_percent = 'Lead devide percent is required';
        }
        if(!singleCatPriceInc){
            errors.single_cat_price = 'Single cat price is required';
        }
        if(!minimumLeadCount)
        {
            errors.minimum_lead_count = 'Minimum lead count is required';
        }
        if(!singleCatMinLeadCount){
            errors.single_cat_minimum_lead = 'Single cat minimum Lead is required';

        }
        if(!dropAfterLeadCount){
            errors.drop_after_lead_count = ' Drop after lead count is required';

        }
        if(!dropLeadRateBetween){
            errors.drop_lead_rate = 'Drop lead rate between is required';

        }
        if(!dropRatePrice){
            errors.drop_rate_price = 'Drop rate price is required';

        }
        if(!maximumLeadDrop){
            errors.maximum_lead_drop = 'Maximum lead drop is required';

        }
        if(selectedProduct==1)
        {
            if(!toLocationPrice){
                errors.to_location_price = 'To location price is required';
            }
        }
        
        
        setValidationErrors(errors);
        
        if(Object.keys(errors).length == 0)
        {
          
            const formDataClient = new FormData();
            formDataClient.append('product_id',selectedProduct);
            formDataClient.append('service_id',selectedService);
            formDataClient.append('category_id',selectedCategory);
            formDataClient.append('base_price',leadBasePrice);
            formDataClient.append('lead_devide_percent',leadDevidePercent);
            formDataClient.append('single_cat_price_inc',singleCatPriceInc);
            formDataClient.append('minimum_lead_count',minimumLeadCount);
            formDataClient.append('single_cat_min_lead_count',singleCatMinLeadCount);
            formDataClient.append('drop_after_lead_count',dropAfterLeadCount);
            formDataClient.append('drop_lead_rate_between',dropLeadRateBetween);
            formDataClient.append('drop_rate_price',dropRatePrice);
            formDataClient.append('maximum_lead_drop',maximumLeadDrop);
            formDataClient.append('to_location_price',toLocationPrice);
            //formDataClient.append('package_type',selectedPkgType);
            formDataClient.append('created_by',emp_id);
            formDataClient.append('detailsId',editPackageId);
        
        if(editStatus==1)
        {
            try {
                const response = await dashboardApi.post('update-lead-package-factor', formDataClient);
                console.log(response);
                if(response.data.status === 200)
                {
                    Swal.fire({
                        icon: 'success',
                        text: response.data.message,
                    });
                    navigate("/package");
                } else if (response.data.status === 400) {
                    //setValidationErrors(response.data.messages);
                } 
                else
                {
                    Swal.fire({
                    icon: 'error',
                    text: response.data.message || 'An error occurred',
                    });
                }
                } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'An error occurred while creating the product',
                });
                }
        }
        else
        {
            try {
            const response = await dashboardApi.post('add-lead-package-factor', formDataClient);
            console.log(response);
            if(response.data.status === 200)
            {
                Swal.fire({
                    icon: 'success',
                    text: response.data.message,
                });
                navigate("/package");
            } else if (response.data.status === 400) {
                //setValidationErrors(response.data.messages);
            } 
            else
            {
                Swal.fire({
                icon: 'error',
                text: response.data.message || 'An error occurred',
                });
            }
            } catch (error) {
            Swal.fire({
                icon: 'error',
                text: 'An error occurred while creating the product',
            });
            }
        } 
    }
}
    
    

   const chnagePackageStatus = async(id) =>{
        const statusResponce = await dashboardApi.get(`package-status-change/${id}`);
        if(statusResponce.data ==1)
        {
            Swal.fire({
                icon: 'success',
                text: 'Status updated successfully',
            });
            getLeadBasedPackage();
        }
        else
        {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong, Please try again.',
            });
        }
    }

    const chnageUnlimitedPackageStatus = async(id) =>{
        const statusResponce = await dashboardApi.get(`unlimited-package-status-change/${id}`);
        if(statusResponce.data ==1)
        {
            Swal.fire({
                icon: 'success',
                text: 'Status updated successfully',
            });
            //getUnlimitedBasedPackage()
            //getLeadBasedPackage();
        }
        else
        {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong, Please try again.',
            });
        }
    }

    const chnageLeadbaseFactorStatus = async(id) =>{
        const statusResponce = await dashboardApi.get(`leadbase-factor-package-status-change/${id}`);
        if(statusResponce.data ==1)
        {
            Swal.fire({
                icon: 'success',
                text: 'Status updated successfully',
            });
           // getLeadBasedLocationFactor();
        }
        else
        {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong, Please try again.',
            });
        }
    }

    const chnageUnlimitedFactorStatus = async(id) =>{
        const statusResponce = await dashboardApi.get(`unlimited-factor-package-status-change/${id}`);
        if(statusResponce.data ==1)
        {
            Swal.fire({
                icon: 'success',
                text: 'Status updated successfully',
            });
           // getLeadBasedLocationFactor();
        }
        else
        {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong, Please try again.',
            });
        }
    }

    const changePrePackageStatus = async(id,status)=>
    {
        const isConfirm = await Swal.fire({
        title: 'Are you sure?',
        text:  "You want to update this package status!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Update it'
        }).then((result) => {
        return result.isConfirmed
        });

        if(!isConfirm)
        {
            return;
        }
        const response = await dashboardApi.get(`update-pre-package-status/${id}/${status}`);
        if (response.data.status === 200)
        {

            Swal.fire({
            icon: 'success',
            text: response.data.message,
            });
            getPrePackageDetails();

        }
        else
        {
            console.error('Error fetching current status:', response.data.message);
        }
    }

    

    const viewLeadBasedPackageDetails = async(id)=>{
        const leadDetail = await dashboardApi.get(`view-leadbased-package-details-by-id/${id}`);
        if(leadDetail.data.status==200)
        {
            const leadDetails = leadDetail.data.data;
            setEditStatus(1);
            setEditPackageId(leadDetails.id);
            setSelectedProduct(leadDetails.product_id);
            setSelectedService(leadDetails.service_id);
            setSelectedCategory(leadDetails.category_id);
            //setSelectedPkgType(leadDetails.package_type);
            setLeadBasePrice(leadDetails.base_price);
            setLeadDevidePercent(leadDetails.lead_devide_percent);
            setSingleCatPriceInc(leadDetails.for_single_cat_price_inc);
            setMinimumLeadCount(leadDetails.min_lead_count);
            setSingleCatMinLeadCount(leadDetails.single_cat_lead_min_count);
            setDropAfterLeadCount(leadDetails.drop_price_after_lead);
            setDropLeadRateBetween(leadDetails.drop_rate_between);
            setDropRatePrice(leadDetails.drop_rate_price);
            setMaximumLeadDrop(leadDetails.max_lead_drop);
            setToLocationPrice(leadDetails.to_location_price);
        }
    }

    const viewUnlimitedPackageDetails = async(id)=>{
        const leadDetail = await dashboardApi.get(`view-unlimited-package-details-by-id/${id}`);
        if(leadDetail.data.status==200)
        {
            const leadDetails = leadDetail.data.data;
            setEditUnlimitedStatus(1);
            setEditunlimitedPackageId(leadDetails.id);
            setSelectedProduct(leadDetails.product_id);
            setSelectedService(leadDetails.service_id);
            setSelectedCategory(leadDetails.category_id);
           // setSelectedPkgType(leadDetails.package_type);
            setLeadBasePrice(leadDetails.base_price);
            setLeadDevidePercent(leadDetails.lead_devide_percent);
            setSingleCatPriceInc(leadDetails.for_single_cat_price_inc);
            setMinimumDaysCount(leadDetails.min_days_count);
            setSingleCatMinDaysCount(leadDetails.single_cat_days_min_count);
            setDropAfterLeadCount(leadDetails.drop_price_after_lead);
            setDropLeadRateBetween(leadDetails.drop_rate_between);
            setDropRatePrice(leadDetails.drop_rate_price);
            setMaximumLeadDrop(leadDetails.max_lead_drop);
            setToLocationPrice(leadDetails.to_location_price);
            setPerDayLead(leadDetails.per_day_lead);
        }
    }

    const viewLeadBasedFactorDetails = async(id)=>{
        const factorDetail = await dashboardApi.get(`view-leadbased-factor-details-by-id/${id}`);
        if(factorDetail.data.status==200)
        {
            const factorDetails = factorDetail.data.data;
            setFactorEditStatus(1);
            setEditPackageId(factorDetails.id);
            setSelectedProduct(factorDetails.product_id);
            setSelectedService(factorDetails.service_id);
            setSelectedCategory(factorDetails.category_id);
            setSelectedGroup(factorDetails.include_group);
            //setSelectedLocality(factorDetails.requried_childs);
            setLocationPrice(factorDetails.condition_value);
            setLeadbaseFactorId(factorDetails.id);
            
        }
    }

    const viewUnlimitedFactorDetails = async(id)=>{
        const factorDetail = await dashboardApi.get(`view-unlimited-factor-details-by-id/${id}`);
        if(factorDetail.data.status==200)
        {
            const factorDetails = factorDetail.data.data;
            setUnlimitedFactorEditStatus(1);
            setEditPackageId(factorDetails.id);
            setSelectedProduct(factorDetails.product_id);
            setSelectedService(factorDetails.service_id);
            setSelectedCategory(factorDetails.category_id);
            setSelectedGroup(factorDetails.include_group);
            //setSelectedLocality(factorDetails.requried_childs);
            setLocationPrice(factorDetails.condition_value);
            setUnlimitedFactorId(factorDetails.id);
            
        }
    }

    
    

    const handleUnlimitedFormSubmit = async(e) =>
    {
        e.preventDefault();
        const errors = {};
        if(!selectedProduct)
        {
            errors.product = 'Product is required.';
        }
        if(!selectedService)
        {
            errors.service = 'Service is required.';
        }

        if(!selectedCategory)
        {
            errors.selectedCategory = 'Category is required.';
        }

        
        
        if(!leadBasePrice)
        {
            errors.lead_base_price = 'Base price is required.';
        }

        if(!leadDevidePercent)
        {
            errors.lead_devide_percent = 'Lead divide percent is required';
        }
        
        if(!singleCatPriceInc)
        {
            errors.single_cat_price = 'Single category price is required';
        }
        if(!minimumDaysCount)
        {
            errors.minimumDaysCount = 'Minimum days is required';
        }
        if(!singleCatMinDaysCount)
        {
            errors.singleCatMinDaysCount = 'Single cat minimum days is required';
        }
        if(!dropAfterLeadCount)
        {
            errors.drop_after_lead_count = 'Drop after lead count is required';
        }
        if(!dropLeadRateBetween)
        {
            errors.drop_lead_rate = 'Drop lead rate between is required';
        }
        if(!dropRatePrice)
        {
            errors.drop_rate_price = 'Drop rate price is required';
        }
        if(!maximumLeadDrop)
        {
            errors.maximum_lead_drop = 'Maximum lead drop is required';
        }
        if(selectedProduct ==1)
        {
            if(!toLocationPrice)
            {
                errors.to_location_price = 'To location price is required';
            }
        }
        
        if(!perDayLead)
        {
            errors.per_day_lead = 'Per day lead is required';
        }
        setValidationErrors(errors);
        
        if (Object.keys(errors).length ==0)
        {
            const formDataUnlimited = new FormData();
            formDataUnlimited.append('product_id',selectedProduct);
            formDataUnlimited.append('service_id',selectedService);
            formDataUnlimited.append('category_id',selectedCategory);
            //formDataUnlimited.append('package_type',selectedPkgType);
            formDataUnlimited.append('base_price',leadBasePrice);
            formDataUnlimited.append('lead_devide_percent',leadDevidePercent);
            formDataUnlimited.append('single_cat_price_inc',singleCatPriceInc);
            formDataUnlimited.append('min_days_count',minimumDaysCount);
            formDataUnlimited.append('single_cat_min_days_count',singleCatMinDaysCount);
            formDataUnlimited.append('drop_after_lead_count',dropAfterLeadCount);
            formDataUnlimited.append('drop_lead_rate_between',dropLeadRateBetween);
            formDataUnlimited.append('drop_rate_price',dropRatePrice);
            formDataUnlimited.append('maximum_lead_drop',maximumLeadDrop);
            formDataUnlimited.append('to_location_price',toLocationPrice);
            formDataUnlimited.append('per_day_lead',perDayLead);
            formDataUnlimited.append('created_by',emp_id);
            formDataUnlimited.append('id',editunlimitedPackageId);
           
            try {
                    let response='';
                    if(editUnlimitedStatus==1)
                    {
                         response = await dashboardApi.post('update-unlimited-package-factor', formDataUnlimited);
                    }
                    else
                    {
                         response = await dashboardApi.post('add-unlimited-package-factor', formDataUnlimited);
                    }
                    //console.log(response);
                    if(response.data.status === 200)
                    {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                        });
                        navigate("/package");
                    } else if (response.data.status === 400) {
                        //setValidationErrors(response.data.messages);
                    } 
                    else
                    {
                        Swal.fire({
                        icon: 'error',
                        text: response.data.message || 'An error occurred',
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        text: 'An error occurred while creating the product',
                    });
                }
            }
        }

    const handleLeadLocationFormSubmit = async(e) =>{
        e.preventDefault();
        const errors = {};
        if(!selectedProduct){
            errors.product = 'Product is required.';
        }
        if(!selectedService){
            errors.service = 'Service is required.';
        }
        if(!selectedCategory){
            errors.selectedCategory = 'Category is required.';
        }
        if(!selectedGroup){
            errors.group = 'Group is required';
        }
        if(!locationPrice){
            errors.location = 'Location price is required';
        }
        if(locationPrice)
        {
            if(locationPrice < 1)
            {
                errors.location = 'To location price more than  1 ';
            }
        }

        if(factorEditStatus ==1)
        {
            if(selectedProduct && selectedService && selectedGroup)
            {
                const chkResponse = await dashboardApi.get(`check-leadbased-location-price-by-id/${selectedProduct}/${selectedService}/${selectedGroup}/${leadbaseFactorId}`);
                if(chkResponse.data == 1)
                {
                    errors.group = 'Location price already exists in system for this group.';
                }
            }
        }
        else
        {
            if(selectedProduct && selectedService && selectedGroup)
            {
                const chkResponse = await dashboardApi.get(`check-leadbased-location-price/${selectedProduct}/${selectedService}/${selectedGroup}`);
                if(chkResponse.data == 1)
                {
                    errors.group = 'Location price already exists in system for this group.';
                }
            }
        }
        //console.log(selectedLocality);
        if(selectedLocality.length < 1)
        {
            errors.cities = 'Cities is required';
        }
        setValidationErrors(errors);

        if(Object.keys(errors).length == 0)
        {
          
        const formDataLeadLocation = new FormData();
        formDataLeadLocation.append('product_id',selectedProduct);
        formDataLeadLocation.append('service_id',selectedService);
        formDataLeadLocation.append('category_id',selectedCategory);
        formDataLeadLocation.append('group_id',selectedGroup);
        formDataLeadLocation.append('locality_id',citiesValue);
        formDataLeadLocation.append('location_price',locationPrice);
        formDataLeadLocation.append('created_by',emp_id);
        formDataLeadLocation.append('leadbaseFactorId',leadbaseFactorId);
        
        if(factorEditStatus ==1)
        {
            try {
                const response = await dashboardApi.post('update-lead-location-price', formDataLeadLocation);
                console.log(response);
                if(response.data.status === 200)
                {
                    Swal.fire({
                        icon: 'success',
                        text: response.data.message,
                    });
                    navigate("/package");
                } else if (response.data.status === 400) {
                    //setValidationErrors(response.data.messages);
                } 
                else
                {
                    Swal.fire({
                    icon: 'error',
                    text: response.data.message || 'An error occurred',
                    });
                }
                } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'An error occurred while creating the product',
                });
                }
        }
        else
        {
            try {
            const response = await dashboardApi.post('add-lead-location-price', formDataLeadLocation);
            console.log(response);
            if(response.data.status === 200)
            {
                Swal.fire({
                    icon: 'success',
                    text: response.data.message,
                });
                navigate("/package");
            } else if (response.data.status === 400) {
                //setValidationErrors(response.data.messages);
            } 
            else
            {
                Swal.fire({
                icon: 'error',
                text: response.data.message || 'An error occurred',
                });
            }
            } catch (error) {
            Swal.fire({
                icon: 'error',
                text: 'An error occurred while creating the product',
            });
            }
        } 
    }
}


    const handleAttributeFormSubmit = async(e) =>{
        e.preventDefault();
        const errors = {};
        if(!selectedProduct){
            errors.product = 'Product is required.';
        }
        if(!selectedService){
            errors.service = 'Service is required.';
        }
        if(!selectedCategory){
            errors.selectedCategory = 'Category is required.';
        }
        
        if(!attributePrice){
            errors.attributePrice = 'Attribute price is required';
        }
        if(attributePrice)
        {
            if(attributePrice < 1)
            {
                errors.attributePrice = 'Attribute price more than  1 ';
            }
        }

        if(attributeValue.length < 1)
        {
            errors.attributeValue = 'Minimum 1 attriute is required.';
        }

        
        //console.log(selectedLocality);
        if(selectedAttributeDetails.length < 1)
        {
            errors.cities = 'Attribute is required';
        }
        setValidationErrors(errors);

        if(Object.keys(errors).length == 0)
        {
        
            const formDataLeadLocation = new FormData();
            formDataLeadLocation.append('product_id',selectedProduct);
            formDataLeadLocation.append('service_id',selectedService);
            formDataLeadLocation.append('category_id',selectedCategory);
            formDataLeadLocation.append('attribute_id',attributeValue);
            formDataLeadLocation.append('attribute_price',attributePrice);
            formDataLeadLocation.append('created_by',emp_id);
        
        
            try {
            const response = await dashboardApi.post('add-attribute-price', formDataLeadLocation);
            console.log(response);
            if(response.data.status === 200)
            {
                Swal.fire({
                    icon: 'success',
                    text: response.data.message,
                });
                navigate("/package");
            } else if (response.data.status === 400) {
                //setValidationErrors(response.data.messages);
            } 
            else
            {
                Swal.fire({
                icon: 'error',
                text: response.data.message || 'An error occurred',
                });
            }
            } catch (error) {
            Swal.fire({
                icon: 'error',
                text: 'An error occurred while creating the product',
            });
            }
        
        }
    }

    const handleUnlimitedLocationFormSubmit = async(e) =>{
        e.preventDefault();
        const errors = {};
        if(!selectedProduct){
            errors.product = 'Product is required.';
        }
        if(!selectedService){
            errors.service = 'Service is required.';
        }
        if(!selectedGroup){
            errors.group = 'Group is required.';
        }
        if(!locationPrice){
            errors.location = 'Location price is required.';
        }

        if(selectedLocality.length < 1)
        {
            errors.city_locality = 'City locality is required.';
        }

        if(selectedProduct && selectedService && selectedGroup)
        {
            let chkResponse='';
            if(unlimitedFactorEditStatus == 1)
            {
                 chkResponse = await dashboardApi.get(`check-unlimited-location-price-by-id/${selectedProduct}/${selectedService}/${selectedGroup}/${unlimitedFactorId}`);
                if(chkResponse.data ==1)
                {
                    errors.group = 'Location price already exists in system for this group.';
                }
            }
            else
            {
                 chkResponse = await dashboardApi.get(`check-unlimited-location-price/${selectedProduct}/${selectedService}/${selectedGroup}`);
                if(chkResponse.data ==1)
                {
                    errors.group = 'Location price already exists in system for this group.';
                }
            }
            
        }

        setValidationErrors(errors);
        if (Object.keys(errors).length == 0)
        {
          
        const formDataLeadLocation = new FormData();
        formDataLeadLocation.append('product_id',selectedProduct);
        formDataLeadLocation.append('service_id',selectedService);
        formDataLeadLocation.append('category_id',selectedCategory);
        formDataLeadLocation.append('group_id',selectedGroup);
        formDataLeadLocation.append('locality_id',citiesValue);
        formDataLeadLocation.append('location_price',locationPrice);
        formDataLeadLocation.append('created_by',emp_id);
        formDataLeadLocation.append('id',unlimitedFactorId);
        
        try {
            let response='';
            if(unlimitedFactorEditStatus ==1)
            {
                 response = await dashboardApi.post('update-unlimited-location-price', formDataLeadLocation);
            }
            else
            {
                 response = await dashboardApi.post('add-unlimited-location-price', formDataLeadLocation);
            }
          
         // console.log(response);
          if(response.data.status === 200)
          {
              Swal.fire({
                  icon: 'success',
                  text: response.data.message,
              });
            navigate("/package");
          } else if (response.data.status === 400) {
              //setValidationErrors(response.data.messages);
          } 
          else
          {
              Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
              });
          }
        } catch (error) {
          Swal.fire({
              icon: 'error',
              text: 'An error occurred while creating the product',
          });
        } 
    }
}
    
    useEffect(()=>{
        if(!token)
        {
            navigate('/sign-in');
        }
        else
        {
            getProductList();
          
            getGroupList();
            getLeadBasedPackage();
            getPackageDuration();
            getPrePackageDetails();
        }
    },[token, navigate]);
    //console.log(servicelist);

    useEffect(()=>{
        if(selectedProduct)
        {
            const getServiceList = async() =>{
                const serviceResponce = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
                if(serviceResponce)
                {
                    setServiceList(serviceResponce.data.data);

                    //setSelectedCategory('');
                }
                else
                {
                    console.log('Something went wrong, Please try again.');
                }
            }
            getServiceList();
        }
    },[selectedProduct]);

    useEffect(()=>{
        if(selectedPackageCategory)
        {
            const getPackageCategoryType = async() =>{
                const serviceResponce = await dashboardApi.get(`get-package-category-type-by-category-id/${selectedProduct}/${selectedPackageCategory}`);
                if(serviceResponce)
                {
                    setSelectedPackageCategoryType(serviceResponce.data.data);
                    //setSelectedCategory('');
                }
                else
                {
                    console.log('Something went wrong, Please try again.');
                }
            }
            getPackageCategoryType();
        }
    },[selectedPackageCategory]);

    

    useEffect(()=>{
        if(selectedProduct)
        {
            const getTaxAndService = async() =>{
                const taxAndService = await dashboardApi.get(`get-tax-and-service-charges/${selectedProduct}`);
                if(taxAndService)
                {
                    settaxAndCharges(taxAndService.data.data);
                    //setSelectedCategory('');
                }
                else
                {
                    console.log('Something went wrong, Please try again.');
                }
            }
            getTaxAndService();
        }
    },[selectedProduct]);
    //console.log(taxAndCharges);

    useEffect(()=>{
        if(selectedProduct)
        {
            const getPackageCategory = async() =>{
                const serviceResponce = await dashboardApi.get(`get-package-category-by-product-id/${selectedProduct}`);
                if(serviceResponce)
                {
                    setPackageCategory(serviceResponce.data.data);
                    //setSelectedCategory('');
                }
                else
                {
                    console.log('Something went wrong, Please try again.');
                }
            }
            getPackageCategory();
        }
    },[selectedProduct]);

    useEffect(()=>{
        if(selectedService)
        {
            const getCategoryList = async() =>{
                const categoryResponce = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
                if(categoryResponce)
                {
                    setCategoryList(categoryResponce.data.data);
                    
                }
                else
                {
                    console.log('Something went wrong, Please try again.');
                }
            }
            getCategoryList();
        }
        },[selectedService]);

        useEffect(()=>{
            if( selectedProduct && selectedService && selectedCategory  && activeMenu =='Lead Based Package')
            {
                const checkBasePriceDetails = async() =>{
                    let dataCheck='';
                    if(editStatus !=1)
                    {
                         dataCheck = await dashboardApi.get(`check-service-base-price-details/${selectedProduct}/${selectedService}/${selectedCategory}`);
                    }
                    else
                    {
                         dataCheck = await dashboardApi.get(`check-service-base-price-details-update/${selectedProduct}/${selectedService}/${selectedCategory}/${editPackageId}`);
                    }
                    
                    
                    if(dataCheck.data ===1)
                    {
                        const errors = {};
                        errors.chkBasePriceError = 'Base price already exist for this service and category';
                        setSelectedCategory('');
                        setValidationErrors(errors);
                    }
                    else
                    {
                        setValidationErrors('');
                    }
                    
                }
                checkBasePriceDetails();
            }
        },[selectedProduct,selectedService,selectedCategory,activeMenu =='Lead Based Package']);
    
        useEffect(()=>{
            if(selectedProduct && selectedService && selectedCategory && activeMenu=='Unlimited Package')
            {
                const checkUnlimitedBasePriceDetails = async() =>{
                    let dataCheck='';
                    if(editUnlimitedStatus !=1)
                    {
                         dataCheck = await dashboardApi.get(`check-unlimited-service-base-price-details/${selectedProduct}/${selectedService}/${selectedCategory}`);
                    }
                    else
                    {
                         dataCheck = await dashboardApi.get(`check-unlimited-service-base-price-details-update/${selectedProduct}/${selectedService}/${editunlimitedPackageId}/${selectedCategory}`);
                    }
                    
                    
                    if(dataCheck.data ===1)
                    {
                        const errors = {};
                        errors.chkBasePriceError = 'Base price already exist for this service and category';
                        setSelectedCategory('');
                        setValidationErrors(errors);
                    }
                    else
                    {
                        setValidationErrors('');
                    }
                    
                }
                checkUnlimitedBasePriceDetails();
            }
        },[selectedProduct,selectedService,selectedCategory,activeMenu =='Unlimited Package']);

    const getProductList = async () =>
    {
      try {
          var type ="active";
          const response = await dashboardApi.get(`sales-product-list/${type}`);
          if (response.data.status === 200)
          {
            setProductList(response.data.data);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        
        }
    }

    const getPackageDuration = async () =>
    {
      try {
          const response = await dashboardApi.get(`get-package-duration`);
          if (response.data.status === 200)
          {
            setPackageDuration(response.data.data);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        
        }
    }

    
    // const getPackageType = async () =>
    // {
    //     try
    //     {
    //          const response = await dashboardApi.get(`get-package-type`);
    //         if (response.data.status === 200)
    //         {
    //             setPkgTypeList(response.data.data);
    //         } 
    //     }
    //     catch(error)
    //     {
    //         console.error('Error fetching countries:', error);
    //     }
    // }

    const getGroupList = async () =>
    {
        try
        {
             const response = await dashboardApi.get(`get-group-list`);
            if (response.data.status === 200)
            {
                setGroupList(response.data.data);
            } 
        }
        catch(error)
        {
            console.error('Error fetching countries:', error);
        }
    }

    const getLeadBasedPackage = async() =>{
        
        const response = await dashboardApi.get(`get-leadbased-packages-list`);
        if (response.data.status === 200)
        {
            setLeadBasedDetails(response.data.data);
        } 
    
    }

    useEffect(()=>{
        if(activeMenu=='Attribute Details')
        {
            const getAttributeDetails = async() =>{
                const response = await dashboardApi.get(`get-attribute-price-details`);
                if (response.data.status === 200)
                {
                    setAttributeProiceDetails(response.data.data);
                } 
            }
            getAttributeDetails();
        }
    },[activeMenu=='Attribute Details'])

    


    useEffect(()=>{
        if(activeMenu=='Unlimited Group Factor')
        {
            const getUnlimitedLocationFactor = async() =>{
                const response = await dashboardApi.get(`get-unlimited-location-factor`);
                if (response.data.status === 200)
                {
                    setUnlimitedLocationFactor(response.data.data);
                } 
            }
            getUnlimitedLocationFactor();
        }
    },[activeMenu=='Unlimited Group Factor'])

    useEffect(()=>{
        
            const getUnlimitedBasedPackage = async() =>{
                if(activeMenu=='Unlimited Package')
                {
                    const response = await dashboardApi.get(`get-unlimitedbased-packages-list`);
                    if (response.data.status === 200)
                    {
                        setUnlimitedBasedDetails(response.data.data);
                    }
                }
            }
           getUnlimitedBasedPackage();
        
    },[activeMenu=='Unlimited Package'])

    const getPrePackageDetails = async() =>{
        const response = await dashboardApi.get(`get-pre-packages-list`);
        if (response.data.status === 200)
        {
            setPrePackageDetails(response.data.data);
        } 
    }

    
    useEffect(()=>{
        if(selectedGroup)
        {
            const getLocalityList = async() =>{
            const localityResponce = await dashboardApi.get(`get-locality-list/${selectedGroup}`);
                if(localityResponce)
                {
                    setLocalityList(localityResponce.data.data);
                }
                else
                {
                    console.log('Something went wrong, Please try again.');
                }
            }
            getLocalityList();
        }
    },[selectedGroup]);

   
    

    return(
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                        <div className="card-body">
                            <div className="row" style={{overflowY: "hidden"}}>
                                <ButtonGroup>
                                {topMenuBar.map((type) => (
                                    <Tab
                                    key={type}
                                    active={activeMenu === type}
                                    onClick={() => setActive(type)}
                                    className="btn btn-primary"
                                    style={{width: 'max-content'}}
                                    >
                                    {type}
                                    </Tab>
                                ))}
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="card-body">
                            {activeMenu === 'Lead Based Package' &&
                            
                                <div className="row">
                                    <div className="col-sm-12"><h4>{editStatus==1 && <span>Update </span> }Lead Base Package</h4></div>
                                    <div className="col-sm-4">
                                        <label>Select Product</label>
                                        <select className="form-control" value={selectedProduct} onChange={(e)=>{setSelectedProduct(e.target.value)}}>
                                            <option value="">Select Product</option>
                                            {productList && productList.map((pro)=>(
                                                <option value={pro.id}>{pro.product_name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.product && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product}</div>
                                        )}
                                    </div>
                                    

                                    {selectedProduct &&
                                        <div className="col-sm-4">
                                            <label>Select Service</label>
                                            <select className="form-control" value={selectedService} onChange={(e)=>{setSelectedService(e.target.value)}}>
                                                <option value="">Select Service</option>

                                                {serviceList && serviceList.map((ser)=>(
                                                    <option value={ser.id}>{ser.service_name}</option>
                                                ))}
                                            </select>
                                            {validationErrors.service && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.service}</div>
                                           )}
                                        </div>
                                    }

                                    {categoryList.length>0 &&
                                        <div className="col-sm-4">
                                            <label>Select Category</label>
                                            <select className="form-control" value={selectedCategory} onChange={(e)=>{setSelectedCategory(e.target.value)}}>
                                                <option value="">Select Category</option>

                                                {categoryList && categoryList.map((cat)=>(
                                                    <option value={cat.id}>{cat.category_name}</option>
                                                ))}
                                            </select>
                                            {validationErrors.selectedCategory && (
                                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCategory}</div>
                                            )}

                                            {validationErrors.chkBasePriceError && (
                                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.chkBasePriceError}</div>
                                            )}
                                        </div>
                                    }


                                    {/* {selectedService && selectedProduct ==1 &&
                                    <div className="col-sm-4">
                                        <label>Select Package Type</label>
                                        <select className="form-control" value={selectedPkgType}  onChange={(e)=>{setSelectedPkgType(e.target.value)}}>
                                            <option value="">Package Type</option>
                                            {pkgTypeList && pkgTypeList.map((pkgType)=>(
                                                <option value={pkgType.id}>{pkgType.name}</option>
                                            ))}
                                        </select>

                                        {validationErrors.selectedPkgType && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPkgType}</div>
                                        )}
                                    </div>
                                    } */}

                                    

                                    

                                    {selectedService &&
                                        <>
                                            <div className="col-sm-4">
                                                <label>Base Price</label>
                                                <input type="text" className="form-control" value={leadBasePrice} onChange={(e)=>{setLeadBasePrice(e.target.value)}} />
                                                {validationErrors.lead_base_price && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.lead_base_price}</div>
                                           )}
                                            </div>

                                            <div className="col-sm-4">
                                                <label>Lead Divide Percent %</label>
                                                &nbsp;&nbsp;&nbsp;
                                                <span class="material-icons-outlined" onMouseEnter={() => onmouseover(array[0])}  onMouseLeave={onmouseleave}>
                                                info
                                                </span>
                                                {
                                                    hoveredRowData && hoveredRowData == array[0] &&
                                                    <DescriptionPopup data={hoveredRowData} />
                                                }
                                                  
                                               
                                                <input type="text" className="form-control" onChange={(e)=>{setLeadDevidePercent(e.target.value)}} value={leadDevidePercent} />
                                                {validationErrors.lead_devide_percent && (
                                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.lead_devide_percent}</div>
                                                )}
                                            </div>

                                            

                                            <div className="col-sm-4">
                                                <label>For Single Cat Price Inc</label>
                                                &nbsp;&nbsp;&nbsp;
                                                <span class="material-icons-outlined" onMouseEnter={() => onmouseover(array[1])}  onMouseLeave={onmouseleave}>
                                                info
                                                </span>
                                                 {
                                                    hoveredRowData && hoveredRowData == array[1] &&
                                                    <DescriptionPopup data={hoveredRowData} />
                                                 }
                                                <input type="text" className="form-control" onChange={(e)=>{setSingleCatPriceInc(e.target.value)}} value={singleCatPriceInc} />
                                                {validationErrors.single_cat_price && (
                                                <div className="text-danger" style={{ fontSize: '15px' }}>     {validationErrors.single_cat_price}</div>
                                                )}
                                            </div>

                                            <div className="col-sm-4">
                                                <label>Minimum Lead Count</label>
                                                &nbsp;&nbsp;&nbsp;
                                                <span class="material-icons-outlined" onMouseEnter={() => onmouseover(array[2])}  onMouseLeave={onmouseleave}>
                                                info
                                                </span>
                                                {
                                                    hoveredRowData && hoveredRowData == array[2] &&
                                                    <DescriptionPopup data={hoveredRowData} />
                                                 }
                                                <input type="text" className="form-control" onChange={(e)=>{setMinimumLeadCount(e.target.value)}} value={minimumLeadCount} />
                                                {validationErrors.minimum_lead_count && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.minimum_lead_count}</div>
                                           )}
            
                                            </div>

                                            <div className="col-sm-4">
                                                <label>Single Cat Min Lead Count</label>
                                                &nbsp;&nbsp;&nbsp;
                                                <span class="material-icons-outlined" onMouseEnter={() => onmouseover(array[3])}  onMouseLeave={onmouseleave}>
                                                info
                                                </span>
                                                {
                                                    hoveredRowData && hoveredRowData == array[3] &&
                                                    <DescriptionPopup data={hoveredRowData} />
                                                }
                                                <input type="text" className="form-control" onChange={(e)=>{setSingleCatMinLeadCount(e.target.value)}} value={singleCatMinLeadCount} />
                                                {validationErrors.single_cat_minimum_lead && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.single_cat_minimum_lead}</div>
                                           )}
                    
                                            </div>

                                            <div className="col-sm-4">
                                                <label>Drop After Lead Count</label>
                                                &nbsp;&nbsp;&nbsp;
                                                <span class="material-icons-outlined" onMouseEnter={() => onmouseover(array[4])}  onMouseLeave={onmouseleave}>
                                                info
                                                </span>
                                                {
                                                    hoveredRowData && hoveredRowData == array[4] &&
                                                    <DescriptionPopup data={hoveredRowData} />
                                                 }
                                                <input type="text" className="form-control" onChange={(e)=>{setDropAfterLeadCount(e.target.value)}} value={dropAfterLeadCount} />
                                                {validationErrors.drop_after_lead_count && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.drop_after_lead_count}</div>
                                           )}
                                            </div>

                                            <div className="col-sm-4">
                                                <label>Drop Lead Rate Between</label>
                                                &nbsp;&nbsp;&nbsp;
                                                <span class="material-icons-outlined" onMouseEnter={() => onmouseover(array[5])}  onMouseLeave={onmouseleave}>
                                                info
                                                </span>
                                                {
                                                    hoveredRowData && hoveredRowData == array[5] &&
                                                    <DescriptionPopup data={hoveredRowData} />
                                                 }
                                                <input type="text" className="form-control" onChange={(e)=>{setDropLeadRateBetween(e.target.value)}} value={dropLeadRateBetween} />
                                                {validationErrors.drop_lead_rate && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.drop_lead_rate}</div>
                                           )}
                                            </div>

                                            <div className="col-sm-4">
                                                <label>Drop Rate Price</label>
                                                &nbsp;&nbsp;&nbsp;
                                                <span class="material-icons-outlined" onMouseEnter={() => onmouseover(array[6])}  onMouseLeave={onmouseleave}>
                                                info
                                                </span>
                                                {
                                                    hoveredRowData && hoveredRowData == array[6] &&
                                                    <DescriptionPopup data={hoveredRowData} />
                                                 }
                                                <input type="text" className="form-control" onChange={(e)=>{setDropRatePrice(e.target.value)}} value={dropRatePrice} />
                                                {validationErrors.drop_rate_price && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.drop_rate_price}</div>
                                           )}
                                                
                                            </div>
                                            

                                            <div className="col-sm-4">
                                                <label>Maximum Lead Drop</label>
                                                &nbsp;&nbsp;&nbsp;
                                                <span class="material-icons-outlined" onMouseEnter={() => onmouseover(array[7])}  onMouseLeave={onmouseleave}>
                                                info
                                                </span>
                                                {
                                                    hoveredRowData && hoveredRowData == array[7] &&
                                                    <DescriptionPopup data={hoveredRowData} />
                                                }
                                                <input type="text" className="form-control" onChange={(e)=>{setMaximumLeadDrop(e.target.value)}} value={maximumLeadDrop}/>
                                                {validationErrors.maximum_lead_drop && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.maximum_lead_drop}</div>
                                           )}
                                            </div>

                                            
                                            {selectedProduct ==1 &&
                                                <div className="col-sm-4">
                                                    <label>To Location Price</label>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <span class="material-icons-outlined" onMouseEnter={() => onmouseover(array[8])}  onMouseLeave={onmouseleave}>
                                                    info
                                                    </span>
                                                    {
                                                        hoveredRowData && hoveredRowData == array[8] &&
                                                        <DescriptionPopup data={hoveredRowData} />
                                                    }
                                                    <input type="text" className="form-control" onChange={(e)=>{setToLocationPrice(e.target.value)}} value={toLocationPrice} />
                                                    {validationErrors.to_location_price && (
                                                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.to_location_price}</div>

                                                        
                                                    )}
                                                </div>
                                            }
                                            
                                            <div className="col-sm-4">
                                                {editStatus!=1 &&
                                                <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={handleLeadFormSubmit}>Submit</Link>
                                                }

                                                {editStatus==1 &&
                                                    <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={handleLeadFormSubmit}>Update Details</Link>
                                                }
                                            </div>
                                        </>
                                    }

                                </div>
                            }

                            {activeMenu === 'Unlimited Package' &&
                                <div className="row">
                                <div className="col-sm-12"><h4>{editUnlimitedStatus ==1 && <span>Update </span> }Unlimited Package</h4></div>
                                <div className="col-sm-4">
                                    <label>Select Product</label>
                                    <select className="form-control" value={selectedProduct} onChange={(e)=>{setSelectedProduct(e.target.value)}}>
                                        <option value="">Select Product</option>
                                        {productList && productList.map((pro)=>(
                                            <option value={pro.id}>{pro.product_name}</option>
                                        ))}
                                    </select>
                                    {validationErrors.product && (
                                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product}</div>
                                    )}
                                </div>
                                

                                {selectedProduct &&
                                    <div className="col-sm-4">
                                        <label>Select Service</label>
                                        <select className="form-control" value={selectedService} onChange={(e)=>{setSelectedService(e.target.value)}}>
                                            <option value="">Select Service</option>

                                            {serviceList && serviceList.map((ser)=>(
                                                <option value={ser.id}>{ser.service_name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.service && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.service}</div>
                                           )}
                                    </div>
                                }

                                {categoryList.length>0 &&
                                    <div className="col-sm-4">
                                        <label>Select Category</label>
                                        <select className="form-control" value={selectedCategory} onChange={(e)=>{setSelectedCategory(e.target.value)}}>
                                            <option value="">Select Category</option>

                                            {categoryList && categoryList.map((cat)=>(
                                                <option value={cat.id}>{cat.category_name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.selectedCategory && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCategory}</div>
                                        )}

                                            {validationErrors.chkBasePriceError && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.chkBasePriceError}</div>
                                        )}


                                    </div>
                                }

                                {/* {selectedService && selectedProduct !=2 &&
                                    <div className="col-sm-4">
                                        <label>Select Package Type</label>
                                        <select className="form-control" value={selectedPkgType} onChange={(e)=>    {setSelectedPkgType(e.target.value)}}>
                                            <option value="">Package Type</option>
                                            {pkgTypeList && pkgTypeList.map((pkgType)=>(
                                                <option value={pkgType.id}>{pkgType.name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.selectedPkgType && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPkgType}</div>
                                        )}
                                    </div>
                                } */}

                                {selectedService &&
                                    <>
                                        <div className="col-sm-4">
                                            <label>Base Price</label>
                                            <input type="text" value={leadBasePrice} className="form-control" onChange={(e)=>{setLeadBasePrice(e.target.value)}} />
                                            {validationErrors.lead_base_price && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.lead_base_price}</div>
                                           )}
                                        </div>

                                        <div className="col-sm-4">
                                            <label>Lead Divide Percent %</label>
                                            <input type="text" className="form-control" value={leadDevidePercent} onChange={(e)=>{setLeadDevidePercent(e.target.value)}} />
                                            {validationErrors.lead_devide_percent && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.lead_devide_percent}</div>
                                           )}
                                        </div>

                                        <div className="col-sm-4">
                                            <label>Per Day Lead</label>
                                            <input type="text" className="form-control" value={perDayLead} onChange={(e)=>{setPerDayLead(e.target.value)}} />
                                            {validationErrors.per_day_lead && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.per_day_lead}</div>
                                           )}
                                        </div>

                                        <div className="col-sm-4">
                                            <label>For Single Cat Price Inc</label>
                                            <input type="text" className="form-control" value={singleCatPriceInc} onChange={(e)=>{setSingleCatPriceInc(e.target.value)}} />
                                            {validationErrors.single_cat_price && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.single_cat_price}</div>
                                           )}
                                        </div>

                                        <div className="col-sm-4">
                                            <label>Minimum Days Count</label>
                                            <input type="text" className="form-control" value={minimumDaysCount} onChange={(e)=>{setMinimumDaysCount(e.target.value)}} />
                                            {validationErrors.minimumDaysCount && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.minimumDaysCount}</div>
                                           )}
                                        </div>

                                        <div className="col-sm-4">
                                            <label>Single Cat Min Days Count</label>
                                            <input type="text" className="form-control" value={singleCatMinDaysCount} onChange={(e)=>{setSingleCatMinDaysCount(e.target.value)}} />
                                            {validationErrors.singleCatMinDaysCount && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.singleCatMinDaysCount}</div>
                                           )}
                                        </div>

                                        <div className="col-sm-4">
                                            <label>Drop After Lead Count</label>
                                            <input type="text" className="form-control" value={dropAfterLeadCount} onChange={(e)=>{setDropAfterLeadCount(e.target.value)}} />
                                            {validationErrors.drop_after_lead_count && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.drop_after_lead_count}</div>
                                           )}
                                        </div>

                                        <div className="col-sm-4">
                                            <label>Drop Lead Rate Between</label>
                                            <input type="text" className="form-control" value={dropLeadRateBetween} onChange={(e)=>{setDropLeadRateBetween(e.target.value)}} />
                                            {validationErrors.drop_lead_rate && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.drop_lead_rate}</div>
                                           )}
                                        </div>

                                        <div className="col-sm-4">
                                            <label>Drop Rate Price</label>
                                            <input type="text" className="form-control" value={dropRatePrice} onChange={(e)=>{setDropRatePrice(e.target.value)}} />
                                            {validationErrors.drop_rate_price && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.drop_rate_price}</div>
                                           )}
                                        </div>

                                        <div className="col-sm-4">
                                            <label>Maximum Lead Drop</label>
                                            <input type="text" className="form-control" value={maximumLeadDrop} onChange={(e)=>{setMaximumLeadDrop(e.target.value)}} />
                                            {validationErrors.maximum_lead_drop && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.maximum_lead_drop}</div>
                                           )}
                                        </div>

                                        
                                        {selectedProduct ==1 &&
                                            <div className="col-sm-4">
                                                <label>To Location Price</label>
                                                <input type="text" className="form-control" value={toLocationPrice} onChange={(e)=>{setToLocationPrice(e.target.value)}} />
                                                {validationErrors.to_location_price && (
                                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.to_location_price}</div>
                                            )}
                                            </div>
                                        }
                                       
                                        {editUnlimitedStatus ==1 &&
                                        <div className="col-sm-4">
                                            <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={handleUnlimitedFormSubmit}>Update Details</Link>
                                        </div>
                                        }

                                        {editUnlimitedStatus !=1 &&
                                        <div className="col-sm-4">
                                            <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={handleUnlimitedFormSubmit}>Submit</Link>
                                        </div>
                                        }
                                    </>
                                }
                                </div>
                            }

                            {activeMenu === 'Lead Based Group Factor' &&
                                <div className="row">
                                <div className="col-sm-12"><h4>{factorEditStatus==1 && <span>Update &nbsp;&nbsp;</span>}Lead Based Group Factor</h4></div>
                                <div className="col-sm-4">
                                    <label>Select Product</label>
                                    <select className="form-control" value={selectedProduct} onChange={(e)=>{setSelectedProduct(e.target.value)}}>
                                        <option value="">Select Product</option>
                                        {productList && productList.map((pro)=>(
                                            <option value={pro.id}>{pro.product_name}</option>
                                        ))}
                                    </select>
                                    
                                    {validationErrors.selectedProduct && (
                                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                                    )}
                                </div>
                                

                                {selectedProduct &&
                                    <div className="col-sm-4">
                                        <label>Select Service</label>
                                        <select className="form-control" value={selectedService} onChange={(e)=>{setSelectedService(e.target.value)}}>
                                            <option value="">Select Service</option>

                                            {serviceList && serviceList.map((ser)=>(
                                                <option value={ser.id}>{ser.service_name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.selectedService && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                                        )}
                                    </div>
                                }

                                {categoryList.length > 0 &&
                                    <div className="col-sm-4">
                                        <label>Select Category</label>
                                        <select className="form-control" value={selectedCategory} onChange={(e)=>{setSelectedCategory(e.target.value)}}>
                                            <option value="">Select Category</option>

                                            {categoryList && categoryList.map((cat)=>(
                                                <option value={cat.id}>{cat.category_name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.selectedCategory && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCategory}</div>
                                        )}

                                       


                                    </div>
                                }


                                {selectedService && 
                                    <>
                                        <div className="col-sm-4">
                                            <label>Select Group</label>
                                            <select className="form-control" value={selectedGroup} onChange={(e)=>{setSelectedGroup(e.target.value)}}>
                                                <option value="">Select Group</option>
                                                {groupList && groupList.map((group)=>(
                                                    <option value={group.group_id}>{group.name}</option>
                                                ))}
                                            </select>
                                            {validationErrors.group && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.group}</div>
                                           )}
                                        </div>
                                        {selectedGroup && 
                                            <div className="col-sm-4">
                                                <label>Select Cities</label>
                                                <MultiSelect
                                                    options={localityList}
                                                    value={selectedLocality}
                                                    onChange={setSelectedLocality}
                                                    labelledBy={localityList}
                                                    checked
                                                />
                                                {validationErrors.cities && (
                                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.cities}</div>
                                            )}
                                            </div>
                                        }

                                        <div className="col-sm-4">
                                            <label>Location Price</label>
                                            <input type="text" value={locationPrice} className="form-control" onChange={(e)=>{setLocationPrice(e.target.value)}} />
                                            {validationErrors.location && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.location}</div>
                                           )}
                                        </div>
                                        
                                        <div className="col-sm-4">
                                            {factorEditStatus ==1 &&
                                                <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={handleLeadLocationFormSubmit}>Update Details</Link>
                                            }

                                            {!factorEditStatus &&
                                                <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={handleLeadLocationFormSubmit}>Submit</Link>
                                            }

                                            
                                        </div>
                                    </>
                                }

                                </div>
                            }


                            {activeMenu === 'Unlimited Group Factor' &&
                                <div className="row">
                                <div className="col-sm-12"><h4> {unlimitedFactorEditStatus==1 && <span>Update </span>}Unlimited Group Factor</h4></div>
                                <div className="col-sm-4">
                                    <label>Select Product</label>
                                    <select className="form-control" value={selectedProduct} onChange={(e)=>{setSelectedProduct(e.target.value)}}>
                                        <option value="">Select Product</option>
                                        {productList && productList.map((pro)=>(
                                            <option value={pro.id}>{pro.product_name}</option>
                                        ))}
                                    </select>
                                    {validationErrors.product && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product}</div>
                                           )}
                                </div>
                                

                                {selectedProduct &&
                                    <div className="col-sm-4">
                                        <label>Select Service</label>
                                        <select className="form-control" value={selectedService} onChange={(e)=>{setSelectedService(e.target.value)}}>
                                            <option value="">Select Service</option>

                                            {serviceList && serviceList.map((ser)=>(
                                                <option value={ser.id}>{ser.service_name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.service && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.service}</div>
                                           )}
                                    </div>
                                }

                                {categoryList.length>0 &&
                                    <div className="col-sm-4">
                                        <label>Select Category</label>
                                        <select className="form-control" value={selectedCategory} onChange={(e)=>{setSelectedCategory(e.target.value)}}>
                                            <option value="">Select Category</option>

                                            {categoryList && categoryList.map((cat)=>(
                                                <option value={cat.id}>{cat.category_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                }


                                {selectedService &&
                                    <>
                                        <div className="col-sm-4">
                                            <label>Select Group</label>
                                            <select className="form-control" value={selectedGroup} onChange={(e)=>{setSelectedGroup(e.target.value)}}>
                                                <option value="">Select Group</option>
                                                {groupList && groupList.map((group)=>(
                                                    <option value={group.group_id}>{group.name}</option>
                                                ))}
                                            </select>
                                            {validationErrors.group && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.group}</div>
                                           )}
                                        </div>

                                        <div className="col-sm-4">
                                            <label>Select Cities</label>
                                            <MultiSelect
                                                options={localityList}
                                                value={selectedLocality}
                                                onChange={setSelectedLocality}
                                                labelledBy={localityList}
                                            />
                                            {validationErrors.city_locality && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.city_locality}</div>
                                           )}

                                        </div>

                                        <div className="col-sm-4">
                                            <label>Location Price</label>
                                            <input type="text" className="form-control" value={locationPrice} onChange={(e)=>{setLocationPrice(e.target.value)}} />
                                            {validationErrors.location && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.location}</div>
                                           )}
                                        </div>
                                        {unlimitedFactorEditStatus ==1 &&
                                            <div className="col-sm-4">
                                                <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={handleUnlimitedLocationFormSubmit}>Update Details</Link>
                                            </div>
                                        }

                                        {unlimitedFactorEditStatus !=1 &&
                                            <div className="col-sm-4">
                                                <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={handleUnlimitedLocationFormSubmit}>Submit</Link>
                                            </div>                                  
                                        }
                                        
                                        
                                    </>
                                }

                                </div>
                            }

                            {activeMenu === 'Add New Package' &&
                            
                                <div className="row">
                                    <div className="col-sm-12"><h4>Create New Package</h4></div>
                                    <div className="col-sm-4">
                                        <label>Select Product</label>
                                        <select className="form-control" value={selectedProduct} onChange={(e)=>{setSelectedProduct(e.target.value)}}>
                                            <option value="">Select Product</option>
                                            {productList && productList.map((pro)=>(
                                                <option value={pro.id}>{pro.product_name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.selectedProduct && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                                        )}
                                    </div>

                                    {selectedProduct &&
                                        <div className="col-sm-4">
                                            <label>Select Service</label>
                                            <select className="form-control" value={selectedService} onChange={(e)=>{setSelectedService(e.target.value)}}>
                                                <option value="">Select Service</option>

                                                {serviceList && serviceList.map((ser)=>(
                                                    <option value={ser.id}>{ser.service_name}</option>
                                                ))}
                                            </select>
                                            {validationErrors.service && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.service}</div>
                                           )}
                                        </div>
                                    }
                                    
                                    <div className="col-sm-4">
                                        <label>Select Package Duration</label>
                                        <select className="form-control" value={selectedDuration} onChange={(e)=>{setSelectedPkgDuration(e.target.value)}}>
                                            <option value="">Package Duration</option>
                                            {packageDuration && packageDuration.map((pkgDur)=>(
                                                <option value={pkgDur.id}>{pkgDur.name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.selectedDuration && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedDuration}</div>
                                        )}
                                    </div>
                                    
                                    {/* {selectedProduct ==1 &&
                                    <div className="col-sm-4">
                                        <label>Select Package Type</label>
                                        <select className="form-control" value={selectedPkgType} 
                                        onChange={(e)=>{setSelectedPkgType(e.target.value)}}>
                                            <option value="">Package Type</option>
                                            {pkgTypeList && pkgTypeList.map((pkgType)=>(
                                                <option value={pkgType.id}>{pkgType.name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.selectedPkgType && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPkgType}</div>
                                        )}
                                    </div>
                                    } */}

                                    <div className="col-sm-4">
                                        <label>Select Group</label>
                                        <select className="form-control" value={selectedGroup} onChange={(e)=>{setSelectedGroup(e.target.value)}}>
                                            <option value="">Select Group</option>
                                            {groupList && groupList.map((group)=>(
                                                <option value={group.group_id}>{group.name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.selectedGroup && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedGroup}</div>
                                        )}
                                    </div>
                                    
                                    {selectedProduct ==1 &&
                                    <div className="col-sm-4">
                                        <label>To Location</label>
                                        <select className="form-control" value={selectedToLocation} onChange={(e)=>{setSelectedToLocation(e.target.value)}}>
                                            <option value="">Select To Location</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        {validationErrors.selectedToLocation && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedToLocation}</div>
                                        )}
                                    </div>
                                    }

                                    <div className="col-sm-4">
                                        <label>Select Cities</label>
                                        <MultiSelect
                                            options={localityList}
                                            value={selectedLocality}
                                            onChange={setSelectedLocality}
                                            labelledBy={localityList}
                                        />
                                        {validationErrors.citiesValue && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.citiesValue}</div>
                                        )}
                                    </div>

                                    <div className="col-sm-12" style={{marginTop:'35px',marginBottom:'35px;'}}>
                                        <label>Choose Category : &nbsp; &nbsp;</label>
                                        {categoryList && categoryList.map((category)=>(
                                            <div>
                                                <input type="checkbox" value={category.id} onChange={(e)=>serviceChekedHandler(category.id)}/>{category.category_name} &nbsp;&nbsp;&nbsp;
                                                { <span key={category.id} id={`leadCount_${category.id}`} style={{color:'red'}}></span> }
                                            
                                            </div>
                                        ))}

                                        {validationErrors.packageSelectedService && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageSelectedService}</div>
                                        )}
                                    </div>

                                    <div className="col-sm-4">
                                        <label>Select Package Category</label>
                                        <select className="form-control" value={selectedPackageCategory} onChange={(e)=>{setSelectedPackageCategory(e.target.value)}}>
                                            <option value="">Select Category</option>
                                            {packageCategory && packageCategory.map((pkgCat)=>(
                                                <option value={pkgCat.id}>{pkgCat.name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.selectedPackageCategory && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPackageCategory}</div>
                                        )}
                                    </div>

                                    {<div className="col-sm-8">
                                        <label>Package Category Type</label>
                                        <table className="table table-bordered table-hovered">
                                            <tr style={{fontSize:'13px'}}>
                                                <td></td>
                                                <td>Name</td>
                                                <td>Amount</td>
                                                <td>Tax amount</td>
                                                <td>Total Amount</td>
                                                <td>Status</td>
                                            </tr>
                                            {selectedPackageCategoryType && selectedPackageCategoryType.map((catType)=>(
                                                <tr style={{fontSize:'12px'}}>
                                                    <td>✔</td>
                                                    <td>{catType.name}</td>
                                                    <td>{catType.amount}</td>
                                                    <td>{catType.tax_amount}</td>
                                                    <td>{catType.total_amount}</td>
                                                    <td>{catType.type_status==1 ?'✅':'❌'}</td>
                                                </tr>
                                            ))}

                                        </table>
                                        </div> 
                                    }

                                    {selectedDuration == '1' &&
                                    <div className="col-sm-4">
                                        <label>Total Lead</label>
                                        <input type='text' value={totalLead} className="form-control" onChange={(e)=>setTotalLead(e.target.value)} />
                                        {validationErrors.totalLead && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.totalLead}</div>
                                        )}
                                    </div>

                                    }

                                    {selectedDuration == '2' &&
                                    <div className="col-sm-4">
                                        <label>Total Days</label>
                                        <input type='text' className="form-control" onChange={(e)=>setTotalLead(e.target.value)} />
                                    </div>
                                    }


                                    <div className="col-sm-4">
                                        <label>Package Price</label>
                                        <input type='text' className="form-control" value={totalPackagePrice} readOnly/>
                                    </div>

                                    <div className="col-sm-4">
                                        <label>Tax Amount</label>
                                        <input type='text' className="form-control" value={taxAmount} readOnly/>
                                    </div>
                                    <div className="col-sm-4">
                                        <label>Service Charges</label>
                                        <input type='text' className="form-control" value={serviceAmount} readOnly/>
                                    </div>

                                    <div className="col-sm-4">
                                        <label>Package Total Amount</label>
                                        <input type='text' className="form-control"  value={totalAmount} readOnly />
                                    </div>

                                    <div className="col-sm-4">
                                        <label>Package Name/Title</label>
                                        <input type='text' className="form-control" value={packageTitle}  onChange={(e)=>setPackageTitle(e.target.value)}/>
                                        {validationErrors.packageTitle && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageTitle}</div>
                                        )}
                                    </div>

                                    <div className="col-sm-4">
                                        <label>Is Partial Payment</label>
                                        <select className="form-control" onChange={(e)=>setPartialPayment(e.target.value)}>
                                            <option value="">Select Partial Payment</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        {validationErrors.selectedPartialPayment && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPartialPayment}</div>
                                        )}
                                    </div>

                                    <div className="col-sm-4">

                                    <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={checkPackagePrice}>Check Package Price</Link> &nbsp;&nbsp;&nbsp;
                                    

                                    {totalPackagePrice &&
                                        <Link id="create_package" className="btn btn-primary" style={{marginTop:'38px'}} onClick={createPrePackage}>Create Package</Link>
                                    }
                                    

                                    </div>
                                </div>
                            }

                        </div>
                        </Card>

                            {activeMenu === 'Lead Based Package' &&
                                <Card style={{marginTop:'30px'}}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12" style={{overflowY: "hidden"}}>
                                                <table className="table table-bordered table-hovered">
                                                    <tr>
                                                        <td colSpan={6}><h4 style={{textAlign:'center'}}>Lead Base Price Details</h4></td>
                                                    </tr>
                                                    <tr>
                                                        <td>S.No</td>
                                                        <td>Product</td>
                                                        <td>Service Name</td>
                                                        <td>Category Name</td>
                                                        <td>Base Price</td>
                                                        <td>Status</td>
                                                    </tr>
                                                    {leadBasedDetails && leadBasedDetails.map((leadDetails,index)=>(

                                                        <tr>
                                                            <td>{index +1}</td>
                                                            <td>{leadDetails.product_name}</td>
                                                            <td>{leadDetails.service_name}</td>
                                                            <td>{leadDetails.category_name}</td>
                                                            <td>{leadDetails.base_price}</td>
                                                            <td>
                                                                <button type="button" onClick={() => chnagePackageStatus(leadDetails.id)} className={`btn btn-sm btn-${leadDetails.status === 1 ? 'success' : 'danger'}`} >
                                                                {leadDetails.status === 1 ? 'Active' : 'Inactive'}
                                                                </button>&nbsp;&nbsp;

                                                                <button type="button" className="btn btn-sm btn-primary" onClick={()=>viewLeadBasedPackageDetails(leadDetails.id)}>View </button>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            }

                            

                            {activeMenu === 'Lead Based Group Factor' &&
                                <Card style={{marginTop:'30px'}}>
                                    <div className="card-body" >
                                        <div className="row">
                                            <div className="col-sm-12" style={{overflowY: "hidden"}}>
                                                <table className="table table-bordered table-hovered">
                                                    <tr>
                                                        <td colSpan={6}><h4 style={{textAlign:'center'}}>Lead Based Group Factor</h4></td>
                                                    </tr>
                                                    <tr>
                                                        <td>S.No</td>
                                                        <td>Product</td>
                                                        <td>Service Name</td>
                                                        <td>Group Name</td>
                                                        <td>Cities</td>
                                                        <td>Location Price</td>
                                                        <td>Action</td>
                                                    </tr>
                                                    {leadBasedLocationFactor && leadBasedLocationFactor.map((factorDetails,index)=>(

                                                        <tr>
                                                            <td>{index +1}</td>
                                                            <td>{factorDetails.product_name}</td>
                                                            <td>{factorDetails.service_name}</td>
                                                            <td>{factorDetails.group_name}</td>
                                                            <td>{factorDetails.city_name}</td>
                                                            <td>{factorDetails.location_price}</td>
                                                            <td>
                                                                <button type="button" onClick={() => chnageLeadbaseFactorStatus(factorDetails.id)} className={`btn btn-sm btn-${factorDetails.status === 1 ? 'success' : 'danger'}`} >
                                                                {factorDetails.status === 1 ? 'Active' : 'Inactive'}
                                                                </button>&nbsp;&nbsp;&nbsp;&nbsp;

                                                                <button type="button" className="btn btn-primary btn-sm" onClick={()=>viewLeadBasedFactorDetails(factorDetails.id)}>View</button>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            }

                            
                            
                            {activeMenu === 'Attribute Details' &&
                               <Card style={{marginTop:'30px'}}>
                                <div className="row card-body">
                                <div className="col-sm-12"><h4>{factorEditStatus==1 && <span>Update &nbsp;&nbsp;</span>}Attribute Details</h4></div>
                                <div className="col-sm-4">
                                    <label>Select Product</label>
                                    <select className="form-control" value={selectedProduct} onChange={(e)=>{setSelectedProduct(e.target.value)}}>
                                        <option value="">Select Product</option>
                                        {productList && productList.map((pro)=>(
                                            <option value={pro.id}>{pro.product_name}</option>
                                        ))}
                                    </select>
                                    
                                    {validationErrors.selectedProduct && (
                                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                                    )}
                                </div>
                                

                                {selectedProduct &&
                                    <div className="col-sm-4">
                                        <label>Select Service</label>
                                        <select className="form-control" value={selectedService} onChange={(e)=>{setSelectedService(e.target.value)}}>
                                            <option value="">Select Service</option>

                                            {serviceList && serviceList.map((ser)=>(
                                                <option value={ser.id}>{ser.service_name}</option>
                                            ))}
                                        </select>
                                        {validationErrors.selectedService && (
                                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                                        )}
                                    </div>
                                }

                                {categoryList.length>0 &&
                                    <div className="col-sm-4">
                                        <label>Select Category</label>
                                        <select className="form-control" value={selectedCategory} onChange={(e)=>{setSelectedCategory(e.target.value)}}>
                                            <option value="">Select Category</option>

                                            {categoryList && categoryList.map((cat)=>(
                                                <option value={cat.id}>{cat.category_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                }

                                


                                {selectedService && 
                                    <>

                                    {attributeDetails.length > 0 &&
                                        <div className="col-sm-4">
                                            
                                            <label>Select Attribute</label>
                                            <MultiSelect
                                                options={attributeDetails}
                                                value={selectedAttributeDetails}
                                                onChange={setSelectedAttributeDetails}
                                                labelledBy={attributeDetails}
                                                checked
                                            />
                                            {validationErrors.attributeDetails && (
                                            <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.attributeDetails}</div>
                                            )}
                                        </div>
                                    }
                                        

                                        <div className="col-sm-4">
                                            <label>Attribute Price</label>
                                            <input type="text" value={attributePrice} className="form-control" onChange={(e)=>{setAttributePrice(e.target.value)}} />
                                            {validationErrors.attributePrice && (
                                             <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.attributePrice}</div>
                                           )}
                                        </div>
                                        
                                        <div className="col-sm-4">
                                            {factorEditStatus ==1 &&
                                                <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={handleAttributeFormSubmit}>Update Details</Link>
                                            }

                                            {!factorEditStatus &&
                                                <Link  className="btn btn-primary" style={{marginTop:'38px'}} onClick={handleAttributeFormSubmit}>Submit</Link>
                                            }

                                            
                                        </div>
                                    </>
                                }

                                </div>


                                    <div className="card-body" >
                                        <div className="row">
                                            <div className="col-sm-12 " style={{overflowY: "hidden"}}>
                                                <table className="table table-bordered table-hovered">
                                                    <tr>
                                                        <td colSpan={6}><h4 style={{textAlign:'center'}}>Attribute Details</h4></td>
                                                    </tr>
                                                    <tr>
                                                        <td>S.No</td>
                                                        <td>Product</td>
                                                        <td>Service Name</td>
                                                        <td>Category Name</td>
                                                        <td>Attribute Name</td>
                                                        <td>Attribute Price</td>
                                                        <td>Action</td>
                                                    </tr>
                                                    {attributePriceDetails && attributePriceDetails.map((attributeDetails,index)=>(

                                                        <tr>
                                                            <td>{index +1}</td>
                                                            <td>{attributeDetails.product_name}</td>
                                                            <td>{attributeDetails.service_name}</td>
                                                            <td>{attributeDetails.category_name}</td>
                                                            
                                                            <td>{attributeDetails.attribute_name}</td>
                                                            <td>{attributeDetails.attribute_price}
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => chnageLeadbaseFactorStatus(attributeDetails.id)} className={`btn btn-sm btn-${attributeDetails.status === 1 ? 'success' : 'danger'}`} >
                                                                {attributeDetails.status === 1 ? 'Active' : 'Inactive'}
                                                                </button>&nbsp;&nbsp;&nbsp;&nbsp;

                                                                {/* <button type="button" className="btn btn-primary btn-sm" onClick={()=>viewLeadBasedFactorDetails(factorDetails.id)}>View</button> */}
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            }

                            {activeMenu === 'Unlimited Package' &&
                                <Card style={{marginTop:'30px'}}>
                                    <div className="card-body" >
                                        <div className="row">
                                            <div className="col-sm-12" style={{overflowY: "hidden"}}>
                                                <table className="table table-bordered table-hovered">
                                                    <tr>
                                                        <td colSpan={6}><h4 style={{textAlign:'center'}}>Unlimited Base Price Details</h4></td>
                                                    </tr>
                                                    <tr>
                                                        <td>S.No</td>
                                                        <td>Product</td>
                                                        <td>Service Name</td>
                                                        <td>Category Name</td>
                                                        <td>Base Price</td>
                                                        <td>Status</td>
                                                    </tr>
                                                    {unlimitedBasedDetails && unlimitedBasedDetails.map((leadDetails,index)=>(

                                                        <tr>
                                                            <td>{index +1}</td>
                                                            <td>{leadDetails.product_name}</td>
                                                            <td>{leadDetails.service_name}</td>
                                                            <td>{leadDetails.category_name}</td>
                                                            <td>{leadDetails.base_price}</td>
                                                            <td>
                                                                <button type="button" onClick={() => chnageUnlimitedPackageStatus(leadDetails.id)} className={`btn btn-sm btn-${leadDetails.status === 1 ? 'success' : 'danger'}`} >
                                                                {leadDetails.status === 1 ? 'Active' : 'Inactive'}
                                                                </button> &nbsp;&nbsp;

                                                                <button type="button" className="btn btn-sm btn-primary" onClick={()=>viewUnlimitedPackageDetails(leadDetails.id)}>View </button>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            }
                            
                            {activeMenu === 'Unlimited Group Factor' &&
                                <Card style={{marginTop:'30px'}}>
                                    <div className="card-body" >
                                        <div className="row">
                                            <div className="col-sm-12" style={{overflowY: "hidden"}}>
                                                <table className="table table-bordered table-hovered">
                                                    <tr>
                                                        <td colSpan={6}><h4 style={{textAlign:'center'}}> Unlimited Group Factor</h4></td>
                                                    </tr>
                                                    <tr>
                                                        <td>S.No</td>
                                                        <td>Product</td>
                                                        <td>Service Name</td>
                                                        <td>Group Name</td>
                                                        <td>Cities</td>
                                                        <td>Location Price</td>
                                                        <td>Action</td>
                                                    </tr>
                                                    {unlimitedLocationFactor && unlimitedLocationFactor.map((factorDetails,index)=>(

                                                        <tr>
                                                            <td>{index +1}</td>
                                                            <td>{factorDetails.product_name}</td>
                                                            <td>{factorDetails.service_name}</td>
                                                            <td>{factorDetails.group_name}</td>
                                                            <td>{factorDetails.city_name}</td>
                                                            <td>{factorDetails.location_price}</td>
                                                            <td>
                                                                <button type="button" onClick={() => chnageUnlimitedFactorStatus(factorDetails.id)} className={`btn btn-sm btn-${factorDetails.status === 1 ? 'success' : 'danger'}`} >
                                                                {factorDetails.status === 1 ? 'Active' : 'Inactive'}
                                                                </button>&nbsp;&nbsp;&nbsp;&nbsp;

                                                                <button type="button" className="btn btn-primary btn-sm" onClick={()=>viewUnlimitedFactorDetails(factorDetails.id)}>View</button>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            }
                            
                            {activeMenu === 'Package Details' &&
                                <Card style={{marginTop:'30px'}}>
                                    <div className="card-body" >
                                        <div className="row">
                                            <div className="col-sm-12" style={{overflowY: "hidden"}}>
                                                <table className="table table-bordered table-hovered">
                                                    <tr>
                                                        <td colSpan={11}><h4 style={{textAlign:'center'}}>Package Details</h4></td>
                                                    </tr>
                                                    <tr style={{fontWeight:'900'}}>
                                                        <td>S.No</td>
                                                        <td>Product</td>
                                                        <td>Package name</td>
                                                        <td>Service</td>
                                                        <td>Category</td>
                                                        <td>Duration</td>
                                                        <td>Pkg type</td>
                                                        <td>Location</td>
                                                        <td>Group </td>
                                                        <td>Total Lead </td>
                                                        <td>Total Price </td>
                                                        <td>Is Partial Payment</td>
                                                        <td>Created By</td>
                                                        <td>Status</td>
                                                    </tr>
                                                    {prePackageDetails && prePackageDetails.map((prePackage,index)=>(
                                                        <>
                                                        <tr>
                                                            <td>{index +1}</td>
                                                            <td>{prePackage.product_name}</td>
                                                            <td>{prePackage.package_name}</td>

                                                            <td>{prePackage.service_name_list}</td>
                                                            <td>{prePackage.category_name_list}</td>

                                                            
                                                            <td>{prePackage.duration_name}</td>
                                                            <td>{prePackage.package_type_name}</td>
                                                            <td>
                                                                {prePackage.to_location === 1 ? 'Yes' : 'No'}
                                                            </td>
                                                            <td>{prePackage.group_name}</td>
                                                            
                                                            <td>{prePackage.total_lead}</td>
                                                            <td>{prePackage.package_price}</td>
                                                            <td>{prePackage.is_partial_payment==1 ?'Yes':'No'}</td>
                                                            <td>{prePackage.created_by}</td>
                                                            <td>
                                                                <button type="button" onClick={()=>{changePrePackageStatus(prePackage.id,prePackage.status)}} className={`btn btn-${prePackage.status === 1 ? 'success' : 'danger'}`} >
                                                                {prePackage.status === 1 ? 'Active' : 'Inactive'}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td colSpan={10} style={{fontSize:'14px',width:'90%',color:'red'}}><p> City: 
                                                            
                                                            {prePackage.city_name && prePackage.city_name.split(',').map(( item) => <>, &nbsp;{ item }</>)}
                                                            </p>
                                                            </td>
                                                        </tr>
                                                        </>
                                                    ))}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            }
                        </Grid>
                    </Grid>
                </MDBox>
            <Footer />
      </DashboardLayout>
    );
}
export default LeadBasedPackage;