import React from 'react';
import { Link, useLocation } from "react-router-dom";
function AdminDetailsHeader() 
{
    let location = useLocation();
    let currentPage = location.pathname;

  return (
    <div>
      <Link style={currentPage === "/admin" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/admin">
        New Document Kyc
      </Link>
      &nbsp;&nbsp;&nbsp;
    </div>
  );
}


export default AdminDetailsHeader;
