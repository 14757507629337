import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import Offers_details from "layouts/offers";
import Offer_type from "layouts/offers/offer-type";
import Offer_Category from "layouts/offers/offer-catetory";
import Offer_grab_history from "layouts/offers/offer-grab-history";
import  secureLocalStorage  from  "react-secure-storage";
const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];

const offersroute = [
{
      type: "collapse",
      name: "Offer Panel",
      key: "Offer Panel",
      icon: <Icon fontSize="small">account_tree</Icon>,
      nested: [
        {
            route: "/offers",
            name: "Offer Panel",
            key: "Offer Panel",
            icon: <Icon fontSize="small">published_with_changes</Icon>,
            component: (token!=null) ? <Offers_details name ='finance'/> : <SignIn/>,
        }
      ]
    },
    {
      route: "/offers",
      component: (token!=null) ? <Offers_details/> :  <SignIn/>,
    },
    
    {
      route: "/offer-type",
      component: (token!=null) ? <Offer_type/> :  <SignIn/>,
    },
    
    {
      route: "/offer-category",
      component: (token!=null) ? <Offer_Category/> :  <SignIn/>,
    },
    {
      route: "/offer-grab-history",
      component: (token!=null) ? <Offer_grab_history/> :  <SignIn/>,
    },

    
];
export default offersroute;