import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import authorsTableData from "layouts/employee/data/authorsTableData";
import projectsTableData from "layouts/employee/data/projectsTableData";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";

function LeaveDetails() {
  const navigate = useNavigate();
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [Leave, setLeave] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = secureLocalStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [selectyear,setselectyear] = useState('');
  useEffect(()=>{
        fetchleave() 
    },[currentPage])
    useEffect(() => {
       if (!token) {
        navigate("/sign-in");
     }
    }, [token, navigate]);
   const fetchleave = async () => {
    try {
      //const endpoint = `leave-list`;
      //const response = await dashboardApi.get(endpoint);
      const response =  await dashboardApi.post('leave-list', {
        per_page: postPerPage,
        page: currentPage + 1,
        
      });
      
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        setLeave(employeeData);
        setNumber(responseData.last_page);
      } else {
        console.error('Error fetching employee:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employee:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaveData(currentPage);
  }, [currentPage,selectyear]);

  const fetchLeaveData = async (page = 0) => {
    setLoading(true);
    try {
      const response = await dashboardApi.post('leave-list', {
        per_page: postPerPage,
        page: currentPage + 1,
        year:selectyear,

      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        setLeave(employeeData);
        setTotalPages(response.data.last_page);
      } else {
        console.error("Error fetching leave data:", response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching leave data:", error);
      setLoading(false);
    }
  };
  const toggleStatus = async (leaveId) => {
  const isConfirm = await Swal.fire({
    title: 'Are you sure?',
    text: 'Are You Want To Change Status!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Change it!'
  }).then((result) => {
    return result.isConfirmed;
  });

  if (!isConfirm) {
    return;
  }

  try {
    const response =  await dashboardApi.get(`get-leave-status/${leaveId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      // Calculate the new status (toggle)
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse =  await dashboardApi.post('leave-status', {
        leave_id: leaveId,
        status: newStatus,
      });

      if (updateResponse.data.status === 200) {
        // Update the UI state with the new status
        setLeave((prevState) =>
          prevState.map((leaveObj) =>
            leaveObj.id === leaveId ? { ...leaveObj, status: newStatus } : leaveObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current leave:', response.data.message);
    }
  } catch (error) {
    console.error('Error while toggling status:', error);
  }
};
const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <Link className='btn btn-primary mb-2 float-end' to={"/add-leave"}>
                   +
                  </Link>
                <div style={{float:'right'}}>
                <select
                 className="form-group form-control"
                 value = {selectyear}
                 onChange={(e) => setselectyear(e.target.value)}
                 >
                  <option>Select Year</option>
                  <option>2022</option>
                  <option>2023</option>
                  <option>2024</option>
                  <option>2025</option>
                  <option>2026</option>
                  </select>

              </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Leave Type</td>
                  <td>Branch Name</td>
                  <td>Name</td>
                  <td>Date</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {Leave.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.leave_type}</td>
                  <td>{row.branch_name}</td>
                  <td>{row.leave_name}</td>
                  <td>{row.date}</td>
                  <td><button
                  type="button"
                  className={`btn btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 <td>
                  <Link to={`/edit-leave/${row.id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>

                 </td>
                  
               </tr>
              ))}

            </table>
             )}
              {Leave.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LeaveDetails;