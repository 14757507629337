import React from 'react';
import './description_popup.css';

const DescriptionPopup = ({ data }) => {
  //alert(data);

  return (
    <div className="small-cards-popup-s">
      <div className="cards-popup" style={{width: "18rem",padding:"5px",backgroundColor:'#17a2b8',borderRadius:"5px" }}>
        <center>{data}</center>
      </div>
    </div>
  );
};

export default DescriptionPopup;
