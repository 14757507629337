import {useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import authorsTableData from "layouts/employee/data/authorsTableData";
import projectsTableData from "layouts/employee/data/projectsTableData";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import Switch from 'react-switch';
import { useNavigate } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
//import ToggleButton from 'react-bootstrap/ToggleButton';
//import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

function Branch() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [branch, setbranch] = useState([])
  const [loading, setLoading] = useState(true);
  const permission = secureLocalStorage.getItem('permission');
  const parsedPermission = permission ? JSON.parse(permission) : [];
  const add_branch = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-branch");
  const edit_branch = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-branch");
  const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
   useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(()=>{
        fetchbranch() 
    },[])
   const fetchbranch = async () => {
    try {
      const response =  await dashboardApi.get('branch-list');
      if (response.data.status === 200) {
        setbranch(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };
  const toggleStatus = async (branchId) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response =  await dashboardApi.get(`branch-status/${branchId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      console.log(response.data.data);
      // Calculate the new status (toggle)
      const newStatus = currentStatus === 1 ? 0 : 1;
      // Make an API call to update the status
      const updateResponse =  await dashboardApi.post('branch-change-status', {
        branch_id: branchId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setbranch((prevState) =>
          prevState.map((branchObj) =>
            branchObj.id === branchId ? { ...branchObj, status: newStatus } : branchObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
             {add_branch && (
              <Link className='btn btn-primary mb-2 float-end' to={"/add-branch"}>
                    Add Branch
                </Link>
              )}
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Branch Name</td>
                  <td>Company</td>
                  <td>Branch Type</td>
                  <td>Country</td>
                  <td>State</td>
                  <td>Branch Email</td>
                  <td>Branch Phone</td>
                  {change_status && (
                  <td>Status</td>
                 )}
                  {edit_branch && (
                  <td>Action</td>
                 )}
               </tr>
               {branch.map((branches, index) => (
                <tr key={branches.id}>
                  <td>{index + 1}</td>
                  <td>{branches.branch_name}</td>
                  <td>{branches.business_name}</td>
                  <td>{branches.branch_type}</td>
                  <td>{branches.name}</td>
                  <td>{branches.state_name}</td>
                  <td>{branches.branch_email}</td>
                  <td>{branches.branch_number}</td>

                 {change_status && (
                  <td>
                  <button
                  type="button"
                  className={`btn btn-${branches.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(branches.id)}
                  >
                  {branches.status === 1 ? 'Active' : 'Inactive'}
                 </button>
                  </td>
                 )}
                 {edit_branch && (
                   <td>
                    <Link to={`/edit-branch/${branches.id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>
                      
                   </td>
                )}
               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Branch;
