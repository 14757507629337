import EmpLeave from "layouts/emp_leave/leave-details";
import TeamDetails from "layouts/team_details/";
import LeaveData from "layouts/team_details/leavedata.js";
import WFH from "layouts/Work_from_home/";
import TeamWFH from "layouts/team_details/wfh.js";
import Roster from "layouts/Roster/create_roster";
import EmpRoster from "layouts/Roster/emp_roster_details";
import RosterChangeRequest from "layouts/Roster/roster_change_request";
import ChangePassword from "layouts/employee_details/components/change_password";
import SalaryComponent from "layouts/payslips";
import Icon from "@mui/material/Icon";
import RaiseList from "layouts/ticket_rise/raiselist.js";
import TicketRise from "layouts/ticket_rise/create_ticket.js";
import ReceivedList from "layouts/ticket_rise/ticket_received.js";
import AssignList from "layouts/ticket_rise/ticket_assign.js";
import SignIn from "layouts/authentication/sign-in";
import ViewTicketStatus from "layouts/ticket_rise/view_status";
import  secureLocalStorage  from  "react-secure-storage";
import PositionOpen from 'layouts/postion_opening';
import JobpositionRequest from "layouts/postion_opening/job_position_request";
import ViewpositionDetails from "layouts/postion_opening/view-job-position-details";
import JobDescription from "layouts/postion_opening/job-description";
import CandidateProfile from "layouts/postion_opening/view-profile";
import RaiseAssetRequestFinance from "layouts/stock_details/asset-request-finance";
import EmployeeTarget from 'layouts/company_target/emp_target_list';
import WfhTask from 'layouts/team_details/wfh_task_description';
import EmpSalaryHistory from 'layouts/emp_history/emp_salary_history';
import EmpLeaveHistory from 'layouts/emp_history/emp_leave_history';
import EmpWfhHistory from 'layouts/emp_history/emp_wfh_history';
import EmpRosterHistory from 'layouts/emp_history/emp_roster_history';
import EmpReportingHistory from 'layouts/emp_history/emp_reporting_history';
import AttendanceRegularize from 'layouts/attendance_ regularize';
import AttendanceApproval from "layouts/attendance_for_approval";
import ListOfOrgnisation from "layouts/ticket_rise/ticket_list_of_orgnisation";
import NewTimeRequest from "layouts/ticket_rise/ticket_new_time_request_list";
import NewTimeRequestApproval from "layouts/ticket_rise/ticket_ne_time_for_approval";
import SalesFunnelDetils from "layouts/dashboard/components/sales_funnel_details";
import SalesDescription from "layouts/dashboard/components/sales_description_page";
import UpcomingRenewal from "layouts/dashboard/components/upcoming_renewal";
import SalesDashboard from "layouts/dashboard/sales_dashboard";
import IdleAndBreakTime from "layouts/idle_and_break_time";
import RegularClient from  "layouts/dashboard/components/regular_client";
import CsDashboard from "layouts/dashboard/cs_dashboard";
import DmDashboard from "layouts/dashboard/digital_dashboard";
import ZeroDaysPayment from "layouts/dashboard/components/zero_days_description";
import Guest_client_details from "layouts/sales/moving";
import MissingFollowup from 'layouts/dashboard/components/missing_followup';
import FollowupDetails from 'layouts/dashboard/components/followups_details';
import CarryForward from 'layouts/dashboard/components/carry_forward_followups';
import Missingkrakpi from 'layouts/dashboard/components/missing_kra_kpi_history';
import EmpResign from "layouts/employee_details/components/emp_resign";
import TeamResign from "layouts/employee_details/components/team_resign_list";
import ResignDescription from 'layouts/employee_details/components/resign_description'
import BirthdayAndHolidays from 'layouts/employee_details/birthday_and_holidays';
import EnquiryList from 'layouts/dashboard/components/dm_dashboard_inner_page/enquiry_list';
import OverrunningList from 'layouts/dashboard/components/dm_dashboard_inner_page/package_overrunning';
import HrDashboard from "layouts/dashboard/hr_dashboard";
import Leadfeedback from 'layouts/dashboard/components/dm_dashboard_inner_page/lead_feadback';
import EnquiryQuality from 'layouts/dashboard/components/dm_dashboard_inner_page/quality_related_enquiry';
import Enquiry_escalation_data from "layouts/dashboard/components/enquiry_escalation_data";
import CsEnquiryDetails from "layouts/dashboard/components/cs_enquiry_details"
import RenewalGone from 'layouts/dashboard/components/dm_dashboard_inner_page/renewal_gone_due_to_leads';
//import Enquiry_escalation_data from "layouts/dashboard/components/enquiry_escalation_data";
//import CsEnquiryDetails from "layouts/dashboard/components/cs_enquiry_details";
import EnquiryDescription from "layouts/dashboard/components/enquiry_description";
const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');

const parsedPermission = permission ? JSON.parse(permission) : [];
const view_team = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "view-team");
const view_team_wfh = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "team-wfh-list");
const create_roster = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-roster");
const team_leave = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "team-leave-request");
const roster_change_request = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "roster-change-request");
const empprofileroutes = [
   {
    route: "/change-password",
    component: <ChangePassword empid={emp_id}/>,

  },
  {
   route: "/view-position-details/:id",
   component: <ViewpositionDetails/>,

  },
  {
   route: "/finance-asset-request",
   component: <RaiseAssetRequestFinance/>,
  },
  {

   route: "/view-description/:id",
   component: <JobDescription/>,
  },
  {

   route: "/candidate-profile/:id/:job_id",
   component: <CandidateProfile/>,
  },
   {
      route: "/emp-leave",
      component: <EmpLeave/>,
   },
   {
      route: "/job-opening-request",
      component: <JobpositionRequest/>,
   },
   {
      route: "/raise-details",
      component: <RaiseList/>,
   },
   {
   route: "/ticket-receive",
   component: <ReceivedList/>
  },
  {
    route:"/view-ticket-status/:id",
    component:<ViewTicketStatus/>
  },
  {
    route:"/org-ticket-list",
    component:<ListOfOrgnisation/>


  },
   {
    route:"/ticket-new-time-request",
    component:<NewTimeRequest/>


  },
  {
    route:"/new-time-request-approval",
    component:<NewTimeRequestApproval/>

  },
   {
   route: "/ticket-assign",
   component: <AssignList/>
  },
   {
      route: "/apply-wfh",
      component: <WFH/>,

   },
  {
      route: "/emp-roster",
      component: <EmpRoster/>,


  },
  {
   route:'/emp-target-list',
   component:<EmployeeTarget/>
  },
  {
   route:'/view-wfh-description/:id',
   component:<WfhTask/>

  },
  {
   route: "/opening-request",
   component: <PositionOpen/>,
},
//   {
//     type: "collapse",
//     name: "Raise Ticket",
//     key: "Raise Ticket",
//     icon: <Icon fontSize="small">password</Icon>,
//     route: "/generate-ticket",
//     component: <TicketRise abc ={document.referrer}/>,
//     nested:[],
//   },
  {
   route: "/generate-ticket",
   component: <TicketRise abc ={document.referrer}/>,
    nested:[],
  },
  {
   route: "/change-password",
   component: <ChangePassword empid ={emp_id}/>,
   nested:[],
 },
  {
    type: "collapse",
    name: "Payslip",
    key: "Payslip",
    icon: <Icon fontSize="small">password</Icon>,
    route: "/payslip",
    component: <SalaryComponent empid ={emp_id}/>,
    nested:[],
  },
 

];
if(view_team){
empprofileroutes.push({
   route: "/view-team",
   component: (token!=null) ? <TeamDetails/> : <SignIn/> ,

});
}
if(view_team_wfh){
empprofileroutes.push({
   route: "/wfh-list",
   component: (token!=null) ? <TeamWFH/> : <SignIn/>,

});
}
if(create_roster){
empprofileroutes.push({
   route: "/create-roster",
   component: (token!=null) ? <Roster/> : <SignIn/>,

});
}
if(team_leave){
empprofileroutes.push({
    route: "/leave-details",
    component: (token!=null) ? <LeaveData/> : <SignIn/>,

});
}
if(roster_change_request){
empprofileroutes.push({
    route: "/roster-change-request",
    component: (token!=null) ? <RosterChangeRequest/> : <SignIn/> ,

});
}
empprofileroutes.push({
   route: "/emp-salary-history/:id",
   component: (token!=null) ? <EmpSalaryHistory/> : <SignIn/> ,
 
 });

 empprofileroutes.push({
  route: "/emp-leave-history/:id",
  component: (token!=null) ? <EmpLeaveHistory/> : <SignIn/> ,

});

empprofileroutes.push({
  route: "/emp-wfh-history/:id",
  component: (token!=null) ? <EmpWfhHistory/> : <SignIn/> ,

});

empprofileroutes.push({
  route: "/emp-roster-history/:id",
  component: (token!=null) ? <EmpRosterHistory/> : <SignIn/> ,

});

empprofileroutes.push({
  route: "/emp-reporting-history/:id",
  component: (token!=null) ? <EmpReportingHistory/> : <SignIn/> ,

});


 



 empprofileroutes.push({
   route:'/attendance-regularize',
   component: (token!=null) ? <AttendanceRegularize/> : <SignIn/>,


 })
 empprofileroutes.push({
   route:'/attendance-for-approval',
   component: (token!=null) ? <AttendanceApproval/> : <SignIn/>,


 })

 empprofileroutes.push({
   route:'/sales-funnel-details',
   component: (token!=null) ? <SalesFunnelDetils/> : <SignIn/>,


 })

  empprofileroutes.push({
   route:'/sales-description/:details',
   component: (token!=null) ? <SalesDescription/> : <SignIn/>,


 })

empprofileroutes.push({
   route:'/upcoming-renewal-details/:details',
   component: (token!=null) ? <UpcomingRenewal/> : <SignIn/>,


 })

empprofileroutes.push({
   route:'/sales-dashboard',
   component: (token!=null) ? <SalesDashboard/> : <SignIn/>,


 })

empprofileroutes.push({
  route:'/break-and-idle-time',
  component: (token!=null) ? <IdleAndBreakTime/> : <SignIn/>,

})
empprofileroutes.push({
  route:'/regular-client/:details',
  component: (token!=null) ? <RegularClient/> : <SignIn/>,
})
empprofileroutes.push({
  route:'/cs-dashboard',
  component: (token!=null) ? <CsDashboard/> : <SignIn/>,
})
empprofileroutes.push({
  route:'/digital-dashboard',
  component: (token!=null) ? <DmDashboard/> : <SignIn/>,
})

empprofileroutes.push({
  route:'/zero-days-payment/:details',
  component:(token!=null)?<ZeroDaysPayment/> : <SignIn/>,

})

empprofileroutes.push({
  route:'/add-followup-details/:details/:id',
  component:(token!=null)?<Guest_client_details/> : <SignIn/>,

})

empprofileroutes.push({
  route:'/missing_followup',
  component:(token!=null)?<MissingFollowup/> : <SignIn/>,

})
empprofileroutes.push({
  route:'/missing-kra-kpi',
  component:(token!=null)?<Missingkrakpi/> : <SignIn/>,

})
empprofileroutes.push({
  route:'/emp-resign',
  component:(token!=null)?<EmpResign/> : <SignIn/>,

})
empprofileroutes.push({
  route:'/team-resign-list',
  component:(token!=null)?<TeamResign/> : <SignIn/>,

})

empprofileroutes.push({
  route:'/resign-description/:id/:employee_id',
  component:(token!=null)?<ResignDescription/> : <SignIn/>,

})

empprofileroutes.push({
  route:'/birthday-and-holidays',
  component:(token!=null)?<BirthdayAndHolidays/> : <SignIn/>,

})
empprofileroutes.push({
  route:'/enquiry-details-list/:total_enquiry',
  component:(token!=null)?<EnquiryList/> : <SignIn/>,

})

empprofileroutes.push({
  route:'/package-overrunning',
  component:(token!=null)?<OverrunningList
/> : <SignIn/>,


})

empprofileroutes.push({
  route:'/lead-feadback',
  component:(token!=null)?<Leadfeedback
/> : <SignIn/>,


})

empprofileroutes.push({
  route:'/hr-dashboard',
  component:(token!=null)?<HrDashboard
/> : <SignIn/>,
})

empprofileroutes.push({
  route:'/enquiry-data',
  component:(token!=null)?<Enquiry_escalation_data
/> : <SignIn/>,


})

empprofileroutes.push({
  route:'/cs-enquiry-details',
  component:(token!=null)?<CsEnquiryDetails
/> : <SignIn/>,


})

empprofileroutes.push({
  route:'/enq-details/:group_id/:date',
  component:(token!=null)?<EnquiryDescription
/> : <SignIn/>,


})



empprofileroutes.push({
  route:'/enquiry-quality',
  component:(token!=null)?<EnquiryQuality
/> : <SignIn/>,


})

empprofileroutes.push({
  route:'/renewal-gone-due-to-leads',
  component:(token!=null)?<RenewalGone
/> : <SignIn/>,


})

empprofileroutes.push({
  route:'/followup-details/:details',
  component:(token!=null)?<FollowupDetails
/> : <SignIn/>,


})

empprofileroutes.push({
  route:'/carry-forward/:details',
  component:(token!=null)?<CarryForward
/> : <SignIn/>,


})




export default empprofileroutes;
