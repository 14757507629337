import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link,useParams } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import docurl from 'layouts/common_components/doc_base_url'; 
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AdminDetailsHeader from 'layouts/common_components/admin_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


import Modal from 'react-bootstrap/Modal';

function Add_Renewal() 
{
  const navigate = useNavigate();
  const { clientId } = useParams();
  const emp_id = localStorage.getItem('emp_id');
  const token = localStorage.getItem('token');
  const department = localStorage.getItem('department');
  const [newKycDoc,setNewKycDoc] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(''); 
  const [mobile, setMobile] = useState('');  
  const [Packagedetail, setPackage] = useState([]);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    const closeApprovalControl = ()=>
    {
      setApprovalModal(false);
    }
    useEffect(() => {
      ReturnLeadDetails();
    }, []);

    const ReturnLeadDetails = async () => 
    {
      try
      {
        const response = await dashboardApi.get(`active-product`);
        if(response.data.status === 200)
        {
          setNewKycDoc(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
      }
    }
    const CheckDetail = async () => {
        try {
          const response = await dashboardApi.post('get-return-package-detail', {
            product_id: selectedProduct,
            mobile: mobile,
          });
    
          if (response.data.status === 200) {
           // console.log('Client Details:', response.data);
            setPackage(response.data.data);
            $('#Package_detail_table').show();
          } else {
            $('#Package_detail_table').hide();
            console.error('Error fetching client details:', response.data.message);
          }
        } catch (error) {
            $('#Package_detail_table').hide();
          console.error('Error fetching client details:', error);
        }
      };
      const ManualCreatereturn = async (packageId) => {
        try {
          const response = await dashboardApi.post('details-Return-lead-package', {
            package_id: packageId,
          });
          setModalData(response.data.data); // Store response data
          handleShow(); // Open modal
          console.log('Response Data:', response.data.data);
        } catch (error) {
          if (error.response) {
            console.error('Server Error:', error.response.data);
          } else if (error.request) {
            console.error('Network Error:', error.request);
          } else {
            console.error('Error:', error.message);
          }
        }
      };
      
    console.log('new:',modalData);
    const handleApprovedLeadChange = (index, value) => {
      const newApprovedLead = parseInt(value, 10);
    
      // Calculate the total approved_lead including the new input value
      const updatedData = [...modalData];
      updatedData[index].approved_lead = newApprovedLead;
    
      const totalApprovedLeads = updatedData.reduce(
        (sum, item) => sum + (parseInt(item.approved_lead || 0, 10)),
        0
      );
      
      const totalLeads = updatedData.reduce(
        (sum, item) => sum + (parseInt(item.total_lead || 0, 10)),
        0
      );
    
      // Check if total approved_lead exceeds 10% of total_lead
      if (totalApprovedLeads <= totalLeads * 0.1) {
        setModalData(updatedData); // Allow update if within limit
      } else {
        alert("Total approved leads cannot exceed 10% of total leads");
      }
    };
    
    // const handleSubmit = (e) => {
    //   e.preventDefault(); // Prevent default form submission behavior
    
    //   const formData = new FormData(e.target);
    
    //   const submittedData = modalData.map((item, index) => ({
    //     package_id: formData.get(`package_id_${index}`),
    //     category_id: formData.get(`cat_id_${index}`),
    //     approved_lead: formData.get(`approved_lead_${index}`)
    //   }));
    
    //   console.log(submittedData);
    
    //   // Here you can send the data to your API using a POST request
    //   dashboardApi.post('submit-manual-return-package', submittedData)
    //     .then(response => {
    //       Swal.fire('Success', 'Leads approved successfully!', 'success');
    //       handleClose();
    //     })
    //     .catch(error => {
    //       Swal.fire('Error', 'Failed to approve leads.', 'error');
    //     });
    // };
    const handleSubmit = (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      
      const submittedData = modalData.map((item, index) => ({
        package_id: formData.get(`package_id_${index}`),
        category_id: formData.get(`cat_id_${index}`),
        approved_lead: formData.get(`approved_lead_${index}`)
      }));
      const totalApprovedLeads = modalData.reduce((sum, item) => sum + parseInt(item.approved_lead || 0, 10), 0);
      const payload = {
        submittedData, 
        total_approved_lead: totalApprovedLeads 
      };
      console.log(payload);
      dashboardApi.post('submit-manual-return-package', payload)
        .then(response => {
          Swal.fire('Success', 'Leads approved successfully!', 'success');
          handleClose();
        })
        .catch(error => {
          Swal.fire('Error', 'Failed to approve leads.', 'error');
        });
    };
    
    return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <h4 className="mb-3">Add Return</h4>
                
                    <div className='row'>
                        <div className='col-6'>
                            <label className='form-label'>Select Product</label>
                            <select className="form-control"   onChange={(e) => setSelectedProduct(e.target.value)}>
                                <option value="" disabled selected>Select a product</option>
                                {newKycDoc.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.product_name}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div className='col-6'>
                            <label className='form-label'>Client Mobile</label>
                            <input type='text' name='mobile' className='form-control'  onChange={(e) => setMobile(e.target.value)}/>
                        </div>
                        <div className='col-6 mt-2'>
                        <button type='button'
                            className="btn btn-sm btn-success"
                            id={``}
                            onClick={CheckDetail}
                            >
                            check
                        </button>
                        </div>
                    </div>
                  <div className="table-responsive mt-3" id='Package_detail_table' style={{display:'none'}}>
                    <table className="table table-bordered table-hover" style={{ width: '100%' }}>
                        <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Client Name</th>
                            <th>Company Name</th>
                            <th>Mobile Number</th>
                            <th>Package category</th>
                            <th>Package Start Date</th>
                            <th>Package End Date</th>
                            <th>Total Lead </th>
                            <th>Lead Send</th>
                            <th>Group</th>
                            <th>Executive Name</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Packagedetail.map((item,index) => (
                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.client_name}</td>
                                <td>{item.business_name}</td>
                                <td>{item.mobile_no}</td>
                                <td>
                                {item.category_name}
                                </td>
                                <td>{item.package_start_date}</td>
                                <td>{item.package_end_date}</td>
                                <td>{item.total_lead}(offer leads)</td>
                                <td>{item.sent_lead}</td>
                                <td>{item.group}</td>
                                <td>{item.created_by}</td>
                                <td>
                                {item.action === 1 ? (
                                      <button
                                        className="btn btn-sm btn-success"
                                        id={`manual-return-pac-detail-${item.package_id}`}
                                        onClick={() => {
                                            ManualCreatereturn(item.package_id);
                                        }}
                                      >
                                        Approved
                                      </button>
                                    ) : (
                                      <span></span>
                                    )}
                                </td>
                                
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
              </div>
              <Modal show={show} onHide={handleClose} animation={false} className="mt-5">
                <Modal.Header closeButton>
                  <Modal.Title>Lead Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {modalData && modalData.length > 0 ? (
                    <form onSubmit={handleSubmit}>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Total Leads</th>
                            <th>Return Leads</th>
                          </tr>
                        </thead>
                        <tbody>
                          {modalData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.category_name}</td>
                              <td>{item.total_lead}</td>
                              <td>
                                <input type="hidden" name={`package_id_${index}`} value={item.package_id} />
                                <input type="hidden" name={`cat_id_${index}`} value={item.category_id} />
                                <input
                                  type="number"
                                  className="form-control"
                                  value={item.approved_lead || 0}
                                  name={`approved_lead_${index}`}
                                  onChange={(e) => handleApprovedLeadChange(index, e.target.value)}
                                />
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan="1" style={{ fontWeight: 'bold' }}>Total Leads</td>
                            <td style={{ fontWeight: 'bold' }}>
                              {modalData.reduce((sum, item) => sum + parseInt(item.total_lead || 0, 10), 0)}
                            </td>
                            <td style={{ fontWeight: 'bold' }}>
                              {modalData.reduce((sum, item) => sum + parseInt(item.approved_lead || 0, 10), 0)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="danger" type="submit">
                          Save
                        </Button>
                      </Modal.Footer>
                    </form>
                  ) : (
                    <p>No data available.</p>
                  )}
                </Modal.Body>
              </Modal>


            </Card>
            
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    
    </DashboardLayout>
  );
}
export default Add_Renewal;
