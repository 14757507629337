import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate,useParams } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";
import EmpHistoryHeader from 'layouts/common_components/emp_profile_header';
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';

function EmpRosterHistory() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const navigate = useNavigate();
  const [loading,setloding] = useState(false);
  const { id } = useParams();
  const [list,setlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);
  useEffect(()=>{
    emp_roster();

  },[currentPage])
    const emp_roster = async () => {
        try {
          const response =  await dashboardApi.post('show-emp-roster', {
                per_page: postPerPage,
                page: currentPage + 1,
                emp_id:id,
            
          });
    
          if (response.data.status === 200) {
            const responseData = response.data.data;
            const rosterdata = responseData.data || responseData;
             setlist(rosterdata);
             setNumber(responseData.last_page);
          } else {
            console.error('Error fetching employee:', response.data.message);
          }
          
        } catch (error) {
          console.error('Error fetching employee:', error);
         
        }
    }

  
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              {/* <div className="container-fluid">
                <div className="row">
                  <div className="col-md-2">
                  <button className="btn btn-info">Salary History</button> 
                  </div>
                  <div className="col-md-2">
                  <button className="btn btn-info">Leave History</button> 
                  </div>
                  <div className="col-md-2">
                  <button className="btn btn-info">Wfh History</button> 
                  </div>
                  <div className="col-md-2">
                  <button className="btn btn-info">Stock History</button> 
                  </div>
                  <div className="col-md-2">
                  <button className="btn btn-info">Target History</button> 
                  </div>
                  <div className="col-md-2">
                  <button className="btn btn-info">Reporting</button> 
                  </div>

                </div>

              </div> */}
              <EmpHistoryHeader emp_id = {id}></EmpHistoryHeader>
              <br/>
               
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered table-striped">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>week Off</td>
                        <td>Shift Start Time</td>
                        <td>Shift End Time </td>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.week_off}</td>
                          <td>{row.start_time}</td>
                          <td>{row.end_time}</td>
                    </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"paging__link--active"} 
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmpRosterHistory;
