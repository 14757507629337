import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,Link } from "react-router-dom";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import PackageDetailsHeader from "layouts/common_components/package_details_header";
import  secureLocalStorage  from  "react-secure-storage";

// for modalbox
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import Select from "@mui/material";



function DayRangeDetails()
{
    const navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({});
    const emp_id = secureLocalStorage.getItem('emp_id');
    const token = secureLocalStorage.getItem('token');
    const [daysFact,setDaysFact] = useState('');
    
    // const [selectLocation,setSelectLocation] = useState('');
    const [range_from,setRangeFrom] = useState('');
    const [range_to,setRangeTo] = useState('');
    const [price,setPrice] = useState('');
    const [per_day_lead,setPerDayLead] = useState('');
    const [locationModal,setDayModal] = useState(false);
    const [loading, setLoading] = useState(true);
    
    
    
    useEffect(()=>{
        if(!token)
        {
            navigate('/sign-in');
        }
        else
        {
          getDaysFact();
        }
    },[token, navigate]);

    const dayModalOpenControl = ()=>{
        setDayModal(true);
    }
    
    const dayModalCloseControl = ()=>{
        setDayModal(false);
    }

    // Add New Daya wise factor
    const daySubmit = async() =>
    {
        const errors = {};
        if (Object.keys(errors).length > 0) {
        return; 
        }
        const formData = new FormData();
        formData.append('range_from',range_from);
        formData.append('range_to',range_to);
        formData.append('per_day_lead',per_day_lead);
        formData.append('price',price);
        formData.append('created_by',emp_id);
       
        try {
        const response = await dashboardApi.post('add-days-factor', formData);
            if(response.data.status === 200)
            {
                Swal.fire({
                icon: 'success',
                text: response.data.message,
            });

            navigate("/day-range-details");
            dayModalCloseControl();

        } else if (response.data.status === 400) {
            setValidationErrors(response.data.messages);
        } else {
            Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
            });
        }
        } catch (error) {
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
        } 
    }

    const getDaysFact = async() =>{
        const dayResponce = await dashboardApi.get(`get-day-fact-list`);
        if(dayResponce.status==200)
        {
            setDaysFact(dayResponce.data.data);
        }
        else
        {
            console.log('something went wrong,Please Try Again');
        }
    }

    return(
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-sm-11">
                                            <PackageDetailsHeader></PackageDetailsHeader>
                                        </div>
                                        <div class="col-sm-1">
                                        <Link className='btn btn-info' onClick={()=>dayModalOpenControl()}>
                                            +
                                        </Link>
                                        </div>
                                    </div>
                                    
                                    <div className="row" style={{marginTop:'1%'}}>
                                        <div class="col-sm-12"><h4>Days Wise Factor Details</h4></div>
                                    </div>

                                    <table className="table table-bordered table-hovered">
                                        <tr>
                                            <td>S.No</td>
                                            <td>Range From</td>
                                            <td>Range To</td>
                                            <td>Price</td>
                                            <td>Lead Per Day</td>
                                            <td>Status</td>
                                            <td>Created By</td>
                                            <td>Action</td>
                                        </tr>
                                        
                                        {daysFact && daysFact.map((daysFact,index)=>(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{daysFact.range_from}</td>
                                            <td>{daysFact.range_to}</td>
                                            <td>{daysFact.price}</td>
                                            <td>{daysFact.lead_per_day}</td>
                                            <td>{daysFact.status === 1 ?'Active':'Deactive'}</td>
                                            <td>{daysFact.created_by}</td>
                                            <td><button className="btn btn-primary btn-sm">View</button></td>
                                        </tr>
                                        ))
                                        }
                                    </table>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />

                <Dialog open={locationModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '20px' } }}>
                    <DialogTitle>Add New Days Factor</DialogTitle>
                        <DialogContent>
                            <form>
                                <div className="col-sm-12" style={{marginTop:'5px'}}>
                                    <label>Range From</label>
                                    <TextField type="text" className="form-control" value={range_from} onChange={(e) =>setRangeFrom(e.target.value)}></TextField>
                                </div>

                                <div className="col-sm-12" style={{marginTop:'5px'}}>
                                    <label>Range To</label>   
                                    <TextField type="text" className="form-control" value={range_to} onChange={(e) =>setRangeTo(e.target.value)}></TextField>
                                </div>

                                <div className="col-sm-12" style={{marginTop:'5px'}}>
                                    <label>Price</label>   
                                    <TextField type="text" className="form-control" value={price} onChange={(e) =>setPrice(e.target.value)}></TextField>
                                </div>

                                <div className="col-sm-12" style={{marginTop:'5px'}}>
                                    <label>Per Day Lead</label>   
                                    <TextField type="text" className="form-control" value={per_day_lead} onChange={(e) =>setPerDayLead(e.target.value)}></TextField>
                                </div>

                            </form>
                        </DialogContent>

                        <DialogActions>
                            <Link className="btn btn-primary btn-sm" onClick={dayModalCloseControl}>Close</Link>
                            <Link className="btn btn-success btn-sm" onClick={daySubmit}>Add Days Factor</Link>
                        </DialogActions>
                   
                </Dialog>
            </DashboardLayout>
    );
}
export default DayRangeDetails;