import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";

import Followup_details from "layouts/sales_admin/followup_status";
import Add_followup_details from "layouts/sales_admin/followup_status/add-followup-status";
import Edit_followup_details from "layouts/sales_admin/followup_status/edit-followup-status";

import Product_details from "layouts/sales_admin/product_details";
import Service_details from "layouts/sales_admin/product_details/service_details";
import Category_details from "layouts/sales_admin/product_details/category_details";
//package factor details
import Package_details from "layouts/sales_admin/package_details";
import LocationDetails from "layouts/sales_admin/package_details/location_factor";
import LocalityDetails from "layouts/sales_admin/package_details/locality_factor";
import ToLocationFactor from "layouts/sales_admin/package_details/to_location_factor";
import PackageCreation from "layouts/sales_admin/package_details/package-creation";
import DayRangeDetails from "layouts/sales_admin/package_details/days_range_factor";
import LeadRangeDetails from "layouts/sales_admin/package_details/lead_range_factor";
import CategoryFactor from "layouts/sales_admin/package_details/category_factor";

import Attribute_details from "layouts/sales_admin/product_details/attribute_details";

import Package_category from "layouts/sales_admin/product_details/package_category";
import Package_category_type from "layouts/sales_admin/product_details/package_category_type";
import LeadBasedPackage from "layouts/sales_admin/package";
import  secureLocalStorage  from  "react-secure-storage";
import SalesDoc from "layouts/sales_admin/sales_document";
import SalesDocumenttype from 'layouts/sales_admin/sales_document_type';


const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];

const salesadminroute = [
  {
      type: "collapse",
      name: "Sales Admin",
      key: "Sales Admin",
      icon: <Icon fontSize="small">account_tree</Icon>,
      nested: [
        {
            route: "/followup-details",
            name: "Followup Details",
            key: "Folloowp Details",
            icon: <Icon fontSize="small">published_with_changes</Icon>,
            component: (token!=null) ? <Followup_details/> : <SignIn/> ,
        },
        {
          route: "/product-details",
          name: "Product Details",
          key: "Product Details",
          icon: <Icon fontSize="small">select_all</Icon>,
          component: (token!=null) ? <Product_details/> : <SignIn/> ,
        }
        // ,
        // {
        //   route: "/package-details",
        //   name: "Package Details",
        //   key: "Package Details",
        //   icon: <Icon fontSize="small">reorder</Icon>,
        //   component: (token!=null) ? <Package_details/> : <SignIn/> ,
        // }
        ,
        {
          route: "/sales-document",
          name: " Document Type",
          key: "Document Type",
          icon: <Icon fontSize="small">reorder</Icon>,
          component: (token!=null) ? <SalesDoc/> : <SignIn/> ,
        },
        {
          route: "/package",
          name: "Package",
          key: "Package",
          icon: <Icon fontSize="small">reorder</Icon>,
          component: (token!=null) ? <LeadBasedPackage/> : <SignIn/> ,
        },
      ]
    },
    {
      route: "/followup-details",
      component: (token!=null) ? <Followup_details/> :  <SignIn/>,
    },
    {
      route: "/add-followup-details",
      component: (token!=null) ? <Add_followup_details/> :  <SignIn/>,
    },
    {
      route: "/edit-followup-details/:id",
      component: (token!=null) ? <Edit_followup_details/> :  <SignIn/>,
    },
    {
      route: "/product-details",
      component: (token!=null) ? <Product_details/> :  <SignIn/>,
    },
    {
      route: "/sales-product-status",
      component: (token!=null) ? <Product_details/> :  <SignIn/>,
    },
    {
      route: "/service-details",
      component: (token!=null) ? <Service_details/> :  <SignIn/>,
    },
    {
      route: "/category-details",
      component: (token!=null) ? <Category_details/> :  <SignIn/>,
    },
    {
      route:"/package-details",
      component: (token!=null) ? <Package_details/> : <SignIn/>,
    },
    {
      
      route:"/dure-and-pkg-fact",
      component: (token!=null) ? <Package_details/> : <SignIn/>,
    }
    ,
    {
      route:"/location-details",
      component: (token!=null) ? <LocationDetails/> : <SignIn/>,
    },
    {
      route:"/locality-details",
      component: (token!=null) ? <LocalityDetails/> : <SignIn/>,
    },
    {
      route:"/to-location-details",
      component: (token!=null) ? <ToLocationFactor/> : <SignIn/>,
    },
    {
      route:"/package-creation",
      component: (token!=null) ? <PackageCreation/> : <SignIn/>,
    },
    {
      route:"/day-range-details",
      component: (token!=null) ? <DayRangeDetails/> : <SignIn/>,
    }
    ,
    {
      route:"/lead-range-details",
      component: (token!=null) ? <LeadRangeDetails/> : <SignIn/>,
    }
    ,
    {
      route:"/category-factor",
      component: (token!=null) ? <CategoryFactor/> : <SignIn/>,
    }
    ,
    {
      route:"/package",
      component: (token!=null) ? <LeadBasedPackage/> : <SignIn/>,
    }
    ,
    {
      route:"/package-category",
      component: (token!=null) ? <Package_category/> : <SignIn/>,
    },
    {
      route:"/package-category-type",
      component: (token!=null) ? <Package_category_type/> : <SignIn/>,
    }
    ,
    {
      route:"/attribute-details",
      component: (token!=null) ? <Attribute_details/> : <SignIn/>,
    },
    {
      route: "/sales-document",
      component: (token!=null) ? <SalesDoc/> :  <SignIn/>,
    },
    {
      route:'/sales-document-type',
      component:(token!=null) ? <SalesDocumenttype/> :  <SignIn/>,
    },
    
    
    
    
  ];
  
  
export default salesadminroute;