import React from 'react';
import { Link, useLocation } from "react-router-dom";
function ClientDetailsHeader() 
{
    let location = useLocation();
    let currentPage = location.pathname;

  return (
    
    <div>
      <Link style={currentPage === "/guest-client-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/guest-client-details">
        Guest Client
      </Link>
      &nbsp;&nbsp;&nbsp;

      <Link style={currentPage === "/verified-client-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/verified-client-details">
        Verified Client
      </Link>
      &nbsp;&nbsp;&nbsp;

      <Link style={currentPage === "/active-client" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/active-client">
        Active Client
      </Link>

      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/inactive-client" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/inactive-client">
        Inactive Client
      </Link>
      
      &nbsp;&nbsp;&nbsp;
      
      <Link style={currentPage === "/expire-client" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/expire-client">
        Expire Client
      </Link> 
      &nbsp;&nbsp;&nbsp;

      <Link style={currentPage === "/today-followup" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/today-followup">
        Today Followup
      </Link>

      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/left-followup" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/left-followup">
        Left Followup.
      </Link>

      
   

    </div>
  );
}


export default ClientDetailsHeader;
