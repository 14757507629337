import { useState } from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import { Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import { useRef } from 'react';
import  secureLocalStorage  from  "react-secure-storage";

//import DownloadButton from './downloadbutton';

function SalaryComponent() {
  const containerRef = useRef();
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
 // const navigate = useNavigate();
  const [salary, setsalary] = useState([])
  const [loading, setLoading] = useState(true);
   useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = `download-salary-data/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setsalary(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
       setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
     setLoading(false);
  };

  fetchData();
}, []);
   const handleDownload = async (months) => {
    const month = months;
    try {
        const response = await fetch(`https://test.biz2bizmart.com/api/salary/generate-pdf/${emp_id}/${month}`);

        if (!response.ok) {
            throw new Error(`Failed to fetch HTML: ${response.statusText}`);
        }

        const html = await response.text();

        // Open a new window with the HTML content
        const newWin = window.open('', '_blank');
        newWin.document.write(html);
        newWin.document.close();
        newWin.location.href = `https://test.biz2bizmart.com/api/salary/generate-pdf/${emp_id}/${month}`;
    } catch (error) {
        console.error('Error fetching HTML:', error.message);
    }
};



 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Total Salary</th>
                        <th>Get Salary</th>
                        <th>Deduction</th>
                        <th>Month</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salary.map((row, index) => (
                        <tr key={row.month_year}>
                          <td>{index + 1}</td>
                          <td>{row.actual_salary}</td>
                          <td>{row.net_salary}</td>
                          <td>{row.total_deduction}</td>
                          <td>{row.month_year}</td>
                          <td>
                            <button
                              className="btn btn-info"
                              onClick={() => handleDownload(row.month_year)}
                            >
                              Download Salary Slip
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

            </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SalaryComponent;