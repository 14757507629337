import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import dashboardApi from 'layouts/common_components/apibase_url';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import CircularProgress from '@mui/material/CircularProgress';

function Notifications() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const department = secureLocalStorage.getItem('department_id');
  const navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const [loading, setLoading] = useState(true);
  const [enquiry, setEnquiry] = useState([]);

  useEffect(() => {
    if (!token) {
      // Redirect to sign-in if not authenticated
      navigate('/sign-in');
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchEnquiry();
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchEnquiry = async () => {
    try {
      const response = await dashboardApi.post('enquiry-list', {
        employee: emp_id,
        department: department,
        emp_id: emp_id
      });
      if (response.data.status === 200) {
        setEnquiry(response.data.data);
      } else {
        console.error('Error fetching enquiry:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching enquiry:', error);
      setLoading(false);
    }
  };

  const renderEnquiry = () => {
    if (loading) {
      return (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </MDBox>
      );
    }

    if (enquiry.length === 0) {
      return (
        <MDBox p={3} textAlign="center">
          <MDTypography variant="h6" color="textSecondary">
            No Data Found.
          </MDTypography>
        </MDBox>
      );
    }

    return enquiry.map((enq, index) => (
      <Card key={index} className="mb-3">
        <MDBox p={2}>
          {enq.message && (
           
           <MDTypography variant="body1" style={{ backgroundColor: '#17a2b8', color: 'white', padding: '5px', borderRadius: '4px' }}>
            {enq.message} &nbsp; &nbsp; &nbsp;   BY-{enq.enquiry_from}
          </MDTypography>
          )}
          {enq.notification && (
          <MDTypography variant="body1" style={{ backgroundColor: '#17a2b8', color: 'white', padding: '5px', borderRadius: '4px' }}>
            {enq.notification}   &nbsp; &nbsp; &nbsp;   BY-{enq.action_by}
          </MDTypography>
          )}
        </MDBox>
      </Card>
    ));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            {renderEnquiry()}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Notifications;
