// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import  secureLocalStorage  from  "react-secure-storage";
import { Link } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Data
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import TextField from "@mui/material/TextField";
function JobpositionRequest() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [receivelist, setreceivelist] = useState([]);
  const [id,setid] = useState('');
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [status, setstatus] = useState([]);
  const [remark,setremark] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchData();
   },[])
  const fetchData = async () => {
    try {
      const endpoint = 'job-position-request';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setreceivelist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
const handleOpenModal = async (request_id) => {
  //alert(request_id);
    setid(request_id);
    try {
      const endpoint = `opening-status-edit/${request_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        //alert(response.data.data[0].approval_remark);
        setremark(response.data.data[0].approval_remark);
        setstatus(response.data.data[0].status);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setid('');
    setstatus('');
    setremark('');
    setValidationErrors('');
    setOpenModal(false);
   // se
  };

 const handlesave = async()=>{
  const errors = {};
    if (!remark) {
        errors.remark = 'Please Enter Reason';
      }
      if (!status) {
        errors.status = 'Please Enter Status';
      }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }
     const formData = new FormData();
    formData.append('status',status);
    formData.append('remark',remark);
    formData.append('id',id);
    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('opening-request-status', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchData();
        handleCloseModal();

      }else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }

 }
 //console.log(receivelist);

//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                   {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered table-stripted">
                 <tr>
                  <td>S.No</td>
                  <td>From</td>
                  <td>Opening Type</td>
                  <td>Exprience</td>
                  <td>Title</td>
                  <td>Status</td>
                  <td>Action</td>
                  <td>Details</td>
               </tr>
              {receivelist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.department_name}</td>
                  <td>{row.opening_type}</td>
                  <td>{row.required_experience}</td>
                  <td>{row.title}</td>
                  <td>{row.status === 1 ?
                   'Approved' : row.status === 2 ? 'Rejected' : 'Pending'}</td>
                   {row.status ==1 && row.close_status==0 ?(
                    <td>
                      <Link>
                        Accepted
                      </Link>
                    </td>
                  ) : row.close_status === 1 ? (
                    <td>
                      <Link>
                       <button className='btn btn-danger btn-sm'>Closed</button>
                      </Link>
                    </td>
                  ) : (
                    <td>
                      <Link className="btn btn-info btn-sm" onClick={() => handleOpenModal(row.id)}>
                        Action
                      </Link>
                      &nbsp;
                    </td>
                  )}
                  
                   
                    <td>
                    <Link to = {`/view-description/${row.id}`} className="btn btn-info btn-sm">View</Link>&nbsp;
                    </td>
                  </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Request Status</DialogTitle>
        <DialogContent>
          <form>
             <div>
              <label>Status</label>
              <select className="form-control" value={status} onChange={(e) => setstatus(e.target.value)} fullWidth>
                <option value=" ">Change Status</option>
                <option value="1">Accept</option>
                <option value="2">Reject</option>
              </select>
            </div>
             {validationErrors.status&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                )}
            <div>
              <label>Reason:</label>
              <TextField
                type="text"
                placeholder = "Enter reason"
                fullWidth
                 value={remark}
                onChange={(e) => setremark(e.target.value)}
              />
               {validationErrors.remark&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                )}
            </div>

          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick = {handlesave}>
            Save
          </Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default JobpositionRequest;