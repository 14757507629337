import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function EmpResign() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const[reason,setreason] = useState('');
  const [openmodal,setopenmodal] = useState('');
  const [validationErrors,setValidationErrors] = useState({});
  const[list,setlist] = useState([]);
  const openview = ()=>{
    setopenmodal(true);

  }

  const savedata = async ()=>{
    const errors = {};
    if(!reason){
        errors.reason = 'Reason is Required';

    }
    
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
        const formData = new FormData();
        formData.append('emp_id',emp_id);
        formData.append('reason',reason);
        try {
            const response =  await dashboardApi.post('save-resign', formData);
          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
            });
            closemodal();
            resigndetails();
           }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating',
          });
        } finally {
          //setIsSubmitting(false);
        }

     }

 }

 useEffect(()=>{
    resigndetails() 
},[])
const resigndetails = async () => {
try {
   const response = await dashboardApi.get(`emp-resign-list/${emp_id}`);

  if (response.data.status === 200) {
    setlist(response.data.data);
  } else {
    console.error('Error fetching branch type:', response.data.message);
  }
  setLoading(false);
} catch (error) {
  console.error('Error fetching branch type:', error);
  setLoading(false);
}
};
const closemodal = ()=>{
    setopenmodal(false);
    setreason('');

}

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div className='row'>
                <div className='col-md-4'>
                <Link to='/profile' className='btn btn-info btn-sm'>Back</Link>
                </div>

              </div>
              <div className="card-body">
              <div style={{float:'right'}}>
              <Link className='btn btn-primary mb-2 float-end' onClick={openview}>
                   +
                </Link>

              </div>
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Resign Date</td>
                  <td>Reason</td>
                  <td>Manager Status</td>
                  <td>Manager Remark</td>
                  <td>Hr Status</td>
                  <td>Hr Remark</td>
                  <td>Action</td>
               </tr>

               {list.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.created_date}</td>
                  <td>{row.reason}</td>
                  {
                    row.manager_status ==0 &&
                    <td>Pending</td>
                  }
                   {
                    row.manager_status ==1 &&
                    <td>Accepted</td>
                  }
                   {
                    row.manager_status ==2 &&
                    <td>Rejected</td>
                  }
                  <td>{row.manager_remark}</td>
                  {
                    row.hr_status ==0 &&
                    <td>Pending</td>
                  }
                   {
                    row.hr_status ==1 &&
                    <td>Accepted</td>
                  }
                   {
                    row.hr_status ==2 &&
                    <td>Rejected</td>
                  }
                  <td>{row.hr_remark}</td>
                  <td><Link className='btn btn-info btn-sm' to={`/resign-description/${row.id}/${row.emp_id}`}>
                     view
                 </Link></td>

               </tr>
              ))}

            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openmodal}
        //onClose={closeModalHandler}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'450px'} }}
      >
        <DialogTitle>Resign</DialogTitle>
        <DialogContent>
          <form>
           <div className='container-fluid'>
           <div className="row">
            <div className="form-group col-md-12">
            <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Reason</label>
            <textarea rows={5} cols={5} style={{height:'50px'}}
            value={reason}
            onChange={(e) => setreason(e.target.value)}
            className = "form-control"
            placeholder="Reason"
           />
            </div>
            </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodal} >
            Close
          </button>
          <button className="btn btn-info" onClick={savedata}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default EmpResign;
