import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

//const [loading, setLoading] = useState(true);

function ChangePassword(props) {
  const emp_id = props.empid;
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate();
  const [newPassword, setnewPassword] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
 useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);
   const changepassword = async (e) => {
    //alert();
    e.preventDefault();
    const errors = {};

    if (!newPassword) {
      errors.password = 'Password is required';
    }
    if (!confirmPassword) {
      errors.confirm_password= 'Confirm Password  is required';
    }
    setValidationErrors(errors);

  if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('password', newPassword);
    formData.append('confirm_password', confirmPassword);
    formData.append('emp_id',emp_id);
    try {
        const response =await dashboardApi.post('change-password', formData);

        if (response.data.status === 200) {
          Swal.fire({
          icon: 'success',
          text: response.data.message,
        });

         
        } else if (response.data.status === 400) {
          // Display validation errors below the input fields
          setValidationErrors(response.data.messages);
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while creating the employee',
        });
      }
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className='row'>
                <div className='col-md-4'>
                <Link to='/profile' className='btn btn-info btn-sm'>Back</Link>
                </div>

              </div>
            <form onSubmit={changepassword}>
              <div className="card-body">
                <div class="container-fluid">
                <p style={{color:'red',fontSize:'20px'}}>You Must Need To Change Password Every 30 Days</p>
                <div class="row">
                <div class="col-md-6">
                
                <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>New Password</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter New Password"
                            value={newPassword}
                            onChange={(e) => setnewPassword(e.target.value)}
                           />
                        {validationErrors.password && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.password}</div>
                             )}
                        </div>
               </div>
               <div class="col-md-6">
                <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Confirm New Password</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setconfirmPassword(e.target.value)}
                           />
                         {validationErrors.confirm_password && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.confirm_password}</div>
                             )}
                        </div>
               </div>
               </div>
               </div>
    <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                          Update
                        </button>
                      </div>
                    </div>
              </div>
             </form>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ChangePassword;
