import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function Leavepermission() {
  const { id } = useParams();
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [leave, setleave] = useState([])
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
   useEffect(()=>{
        fetchleave();
    },[])
   const fetchleave = async () => {
    try {
      //const response = await dashboardApi.get('leave-permission/${id}');
        const endpoint = `leave-permission/${id}`;
        const response = await dashboardApi.get(endpoint);
      if (response.data.status === 200) {
        setleave(response.data.data);
      } else {
        console.error('Error fetching leave:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching leave:', error);
      setLoading(false);
    }
  };
  //console.log(leave);
   const handleCheckboxChange = async (leaveId) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
     //const assignValue = row.assign_on_request == 1 ? 0 : 1;
      const response = await dashboardApi.post('update-leave-permission', {
        leave_id: leaveId,
        emp_id:id,
      });
       if(response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         fetchleave(); 

        
        };
         };
   

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
             <Card>
              <div className="card-body">
              <div style={{width:'30px'}}>
            <Link className='btn btn-primary mb-2 float-end' to={"/employee-list"}>
                  Back
                </Link>
             </div>
                <table className="table table-bordered table-hovered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Leave Name</th>
                      <th>Assign Leave Request</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leave.map((row, index) => (
                      <tr key={row.id}>
                        <td>{index + 1}</td>
                        <td>{row.leave_name}</td>
                        <td>
                           <input
                            type="checkbox"
                            checked={row.assign_status == 1}
                            onChange={() => handleCheckboxChange(row.id)}
                          />
                          
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Leavepermission;
