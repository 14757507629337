import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
//import authorsTableData from "layouts/employee/data/authorsTableData";
//import projectsTableData from "layouts/employee/data/projectsTableData";
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function IdleAndBreakTime()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [followup, setFollowup] = useState([])
  const [loading, setLoading] = useState(true);
  const permission = secureLocalStorage.getItem('permission');
  const parsedPermission = permission ? JSON.parse(permission) : [];
  const [departmentlist,setdepartmentlist] = useState([]);
  const [emplist,getemplist] = useState([]);
  const [selecteddepartment,setselecteddepartment] = useState('');
  const [selectedemp,setselectedemp] = useState('');
  const [type,settype] = useState('');
  const [start_date,setstart_date] = useState('');
  const [end_date,setend_date] = useState('');
  const [data,get_data] = useState([]);
  const [reporting_manager,check_reporting_manager] = useState({});
  const [manager_team,get_manager_team] = useState([]);
  //  const add_state = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-state");
  //  const edit_state = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-state");
  //  const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
       department_list();
       check_emp_manager();
       manager_team_list();
    },[])
  useEffect(()=>{
       department_based_emp();
    },[selecteddepartment])

  useEffect(()=>{
    fetchlist()

  },[selecteddepartment,selectedemp,type,start_date,end_date])
  console.log(reporting_manager);

  const department_list = async () => {
    try {
      const endpoint = 'get-active-department';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
          setdepartmentlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const department_based_emp = async()=>{
    try {
      const endpoint = `department-employee/${selecteddepartment}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
          getemplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
}

const check_emp_manager= async()=>{
    try {
      const endpoint = `check-reporting-manager/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
          check_reporting_manager(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
}

const manager_team_list = async()=>{
  try {
      const endpoint = `get-all-manager-employee/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
          get_manager_team(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

}

const fetchlist = async () => {
    try {
      const response = await dashboardApi.get('get-emp-idle-time', {
        params: {
          emp_id: emp_id,
          start_date: start_date,
          end_date: end_date,
          employee: selectedemp,
          type:type,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        get_data(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <br/>
             <div className="container-fluid">
             <div className="row">
             {
              emp_id =='RIMS1' &&
             
             <div className="col-md-2">
             <select className="form-control"
              value={selecteddepartment}
              onChange={(e) => setselecteddepartment(e.target.value)}

             >
             <option>Select Department</option>
                {departmentlist.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.department_name}
                  </option>
                ))}
             </select>

             </div>
           }

           { emp_id == 'RIMS1' &&

            <div className="col-md-2">
            <select className="form-control"
              value={selectedemp}
              onChange={(e) => setselectedemp(e.target.value)}

            >
             <option>Select Team</option>
             {emplist.map((row) => (
                  <option key={row.emp_id} value={row.emp_id}>
                    {row.emp_fname} {row.emp_lame}
                  </option>
                ))}
             </select>

             </div>
           }

           {reporting_manager == 'Yes' &&

           <div className="col-md-2">
            <select className="form-control"
              value={selectedemp}
              onChange={(e) => setselectedemp(e.target.value)}

            >
             <option>Select Team</option>
             {manager_team.map((row) => (
                  <option key={row.emp_id} value={row.emp_id}>
                    {row.emp_fname} {row.emp_lame}
                  </option>
                ))}
             </select>

             </div>
            }

            <div className="col-md-2">
             <select className="form-control"
               value={type}
               onChange={(e) => settype(e.target.value)}

             >
             <option>Select Type</option>
             <option value='idle'>Idle Time</option>
             <option value = 'break'>Break</option>
             </select>

             </div>

              <div className="col-md-2">
               <input type="date"
               className="form-control"
                 value={start_date}
                 onChange={(e) => setstart_date(e.target.value)}

               />

             </div>

              <div className="col-md-2">
                  <input type="date"
                  className="form-control"
                  value={end_date}
                  onChange={(e) => setend_date(e.target.value)}

                  />

             </div>
             </div>
             </div>
              <div className="card-body">
               <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Name</td>
                  <td>Date</td>
                  <td>Idle/Break hours</td>
                  <td>Type</td>
                </tr>
                 
                  {data.map((row, index) => (
                    <tr key={row.id}>
                    <td>{index+1}</td>
                    <td>{row.emp_name}</td>
                    <td>{row.created_date}</td>
                    <td>{row.total_time}</td>
                    <td>{row.type}</td>
                  </tr>
                  ))}
                 
                </table>
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default IdleAndBreakTime;
