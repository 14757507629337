import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CsDetailsHeader from 'layouts/common_components/cs_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";

function Social_enquiry() {
  const navigate = useNavigate();
  const emp_id = localStorage.getItem('emp_id');
  const token = localStorage.getItem('token');
  const department = localStorage.getItem('department');
  const [loading, setLoading] = useState(true);
  const [callConnecting, setCallLoading] = useState(false);
  const [socialEnq, setSocailEnq] = useState([]);
  const [approvalModal, setApprovalModal] = useState(false);
  const [selectedApprovalStatus, setApprovalStatus] = useState();
  const [remarks, setRemarks] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);
  const [callingMobileNo, setCallingMobileNumber] = useState('');
  const [addCommentModal, setCommentModal] = useState(false);
  const [callId, setCallId] = useState();

  const closeApprovalControl = () => {
    setApprovalModal(false);
  }
  const onCommentModal = (id) => {
    setCallId(id);
    setCommentModal(true);
  }

  const ofCommentModal = () => {
    setCommentModal(false);
  }

  useEffect(() => {
    getSocialEnq();
  }, [currentPage]);

  const getSocialEnq = async () => {
    try {
      const response = await dashboardApi.get(`get-social-enquiry`, {
        params: {
          emp_id: emp_id,
          per_page: postPerPage,
          page: currentPage + 1,
        }
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const customerData = responseData.data || responseData;
        setNumber(responseData.last_page);
        setSocailEnq(customerData);
        setLoading(false);
      } else {
        console.error('Error fetching data:', response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }

  const updateComment = async () => {
    const formData = new FormData();
    formData.append('enqId', callId);
    try {
      const response = await dashboardApi.post('update-social-enq-status', formData);
      if (response.data.status === 200) {
        setCommentModal(false);
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        getSocialEnq();
      } else {
        setCommentModal(false);
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      setCommentModal(false);
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  const makeCallToCustomer = async (mobile_no) => {
    setCallingMobileNumber(mobile_no);
    const token = localStorage.getItem('token');
    const form = new FormData();
    form.append("emp_id", emp_id);
    form.append("mobile_number", mobile_no);
    form.append("agent_mobile_number", mobile_no);
    form.append("api_id", "1");
    form.append("secure_key", "d53e5ccd3d0ab16e6c29103beb806bc1");
    try {
      setCallLoading(true);

      const response = await dashboardApi.post('dialer/make-a-call', form);
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const customerData = responseData.data || responseData;
        setCallLoading(false);
      } else {
        console.error('Error fetching data:', response.data.message);
        setCallLoading(false);
      }
    } catch (error) {
      console.error('Error sending POST request', error);
      setCallLoading(false);
    }
  };

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row">
                  <div class="col-sm-11">
                    <CsDetailsHeader></CsDetailsHeader>
                  </div>
                  <div class="col-sm-1">
                    <Link className='btn btn-info' target="_blank" to="/add-enquiry">
                      +
                    </Link>
                  </div>
                </div>
                {loading ? (
                  <p style={{ align: 'center' }}>
                    <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
                  </p>
                ) : (
                  <div style={{ overflowY: "hidden" }}>

                    <table className="table table-bordered table-hovered" style={{ width: '100%' }}>

                      <tr>
                        <td>S.No</td>
                        <td>Customer Name</td>
                        <td>Contact Info</td>
                        <td>From/To city</td>
                        <td>Service</td>
                        <td>Status</td>
                        <td>Recived Date</td>
                        <td>Action</td>
                      </tr>
                      {socialEnq && socialEnq.map((enq, index) => (
                        <tr key={enq.id}>
                          <td>{index + 1}</td>
                          <td>{enq.name}</td>
                          <td>Mobile No : {enq.phone.startsWith('+91') ? enq.phone.slice(3) : enq.phone} <br /> Email :  {enq.email}</td>
                          <td>From : {enq.from_city} <br />To : {enq.to_city}</td>
                          <td>{enq.sub_service}</td>
                          <td>{enq.status == 1 ? <span style={{ color: "green" }}>Attempted</span> : <span style={{ color: "red" }}>Pending</span>}</td>
                          <td>{enq.created_time}</td>

                          <td className='d-flex'>
                            <span class="material-icons-outlined btn btn-info btn-sm mx-1" onClick={() => { onCommentModal(enq.id)}}>
                              add_comment
                            </span>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="8">
                          <Pagination className="custom-pagination">
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={number}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link-style"}
                              breakLinkClassName={"break-link-style"}
                              previousLinkClassName={"prev-link-style"}
                              nextLinkClassName={"next-link-style"}
                            />
                          </Pagination>
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={addCommentModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Add Comment</DialogTitle>
        <DialogContent style={{ height: "auto" }}>
          <form>
            <div className="row">
              <div className="col-sm-12">
                <p>Are you sure you have attempted this enquiry..?</p>
              </div>
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={ofCommentModal}>Close</Link>
          <Link className="btn btn-success btn-sm" onClick={updateComment}>Yes</Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
export default Social_enquiry;