import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import authorsTableData from "layouts/employee/data/authorsTableData";
import projectsTableData from "layouts/employee/data/projectsTableData";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import AppCrudHeader from 'layouts/common_components/app_crud_header';

function OtpTemplate() {
  const navigate = useNavigate();
  const [Leave, setLeave] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = secureLocalStorage.getItem("token");
  const [openModal, setOpenModal] = useState(false);
  const [editopenModal,seteditopenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const emp_id = secureLocalStorage.getItem('emp_id');
  const [productlist,setproductlist] = useState([]);
  const [selectedproduct,setselectedproduct] = useState('');
  const [use_as,set_use_as] = useState('');
  const [otp_for,set_otp_for] = useState('')
  const [api_key,set_api_key] = useState('');
  const [sender,setsender] = useState('');
  const [template_id,set_template_id] = useState('');
  const [remark,setremark] = useState('');
  const [message,setmessage] = useState('');
  const [template,settemplate] = useState('');
  const [template_list,get_template_list] = useState([]);
  const [id,setid] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;
  //const [OpeneditModal,seteditopenModal] = useState(false);

 
  useEffect(() => {
       if (!token) {
        navigate("/sign-in");
     }
    }, [token, navigate]);

    useEffect(()=>{
        productdetails();
        otp_template_list();


    },[])

    useEffect(() => {
      otp_template_list(currentPage);
    }, [currentPage]);
    const productdetails = async () => {
        try {
          const endpoint = 'get-product-list';
          const response = await dashboardApi.get(endpoint);
    
          if (response.data.status === 200) {
            setproductlist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const otp_template_list = async (page) => {
        try {
          // const endpoint = 'otp-template-list';
          // const response = await dashboardApi.get(endpoint);

          const response = await dashboardApi.get('otp-template-list', {
            params: {
              per_page: postsPerPage,
              page: page + 1, 
            }
          });
    
          if (response.data.status === 200) {
            const enqData = response.data.data.data || response.data.data;
            get_template_list(enqData);
            setTotalPages(response.data.last_page);
          } else {
            console.error('Error fetching data:', response.data.error);
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.error('Error fetching data:', error);
        }
      };

      const handlePageClick = (selected) => {
        setCurrentPage(selected.selected);
      };

    

    
    
const toggleStatus = async (temp_id) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`get-otp-template-status/${temp_id}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('update-otp-template-status', {
        id:temp_id,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        get_template_list((prevState) =>
          prevState.map((followupObj) =>
           followupObj.id === temp_id ? { ...followupObj, status: newStatus } : followupObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };
   const handleCloseModal = () => {
    setValidationErrors('');
    setOpenModal(false);
    seteditopenModal(false);
    set_api_key('');
    set_otp_for('');
    set_template_id('');
    settemplate('');
    setremark('');
    setmessage('')
    set_use_as('');
    setsender('');
  };
  const handleOpenModal = async () => {
    setOpenModal(true);
  };
  const editviewopen = async(temp_id)=>{
     setid(temp_id);
      try {
      const endpoint = `edit-otp-template/${temp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        set_api_key(response.data.data.apikey);
        set_otp_for(response.data.data.otp_for);
        set_template_id(response.data.data.template_id);
        settemplate(response.data.data.template);
        setremark(response.data.data.remarks);
        setmessage(response.data.data.message);
        set_use_as(response.data.data.use_as);
        setsender(response.data.data.sender);
        setselectedproduct(response.data.data.product_id)
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
    seteditopenModal(true);

  }
  
  const handlesave = async (e)=>{
   e.preventDefault();
    const formData = new FormData();
    formData.append('api_key',api_key);
    formData.append('sender',sender);
    formData.append('template_id',template_id);
    formData.append('template',template);
    formData.append('message',message);
    formData.append('use_as',use_as);
    formData.append('product',selectedproduct);
    formData.append('otp_for',otp_for);
    formData.append('remark',remark);
    formData.append('emp_id',emp_id)

    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-otp-template', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        handleCloseModal();
        otp_template_list();
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the followup',
      });
    }

  }
  const handleupdate = async (e)=>{
    e.preventDefault();
     const formData = new FormData();
     formData.append('api_key',api_key);
     formData.append('sender',sender);
     formData.append('template_id',template_id);
     formData.append('template',template);
     formData.append('message',message);
     formData.append('use_as',use_as);
     formData.append('product',selectedproduct);
     formData.append('otp_for',otp_for);
     formData.append('remark',remark);
     formData.append('emp_id',emp_id);
     formData.append('id',id);
 
     //setIsSubmitting(true);
 
     try {
       const response = await dashboardApi.post('update-otp-template', formData);
 
       if (response.data.status === 200) {
         Swal.fire({
           icon: 'success',
           text: response.data.message,
         });
         handleCloseModal();
         otp_template_list()
       } else if (response.data.status === 400) {
         setValidationErrors(response.data.messages);
       } else {
         Swal.fire({
           icon: 'error',
           text: response.data.message || 'An error occurred',
         });
       }
     } catch (error) {
       Swal.fire({
         icon: 'error',
         text: 'An error occurred while creating the followup',
       });
     }
 
   }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppCrudHeader></AppCrudHeader>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={handleOpenModal}>
                   +
                </Link>

                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Product</td>
                  <td>Use As</td>
                  <td>Otp For</td>
                  <td>Api Key</td>
                  <td>Sender</td>
                  <td>Template</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {template_list.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.product_name}</td>
                  <td>{row.use_as_type}</td>
                  <td>{row.otp_for_type}</td>
                  <td>{row.apikey}</td>
                  <td>{row.sender}</td>
                  <td>{row.template}</td>
                  
                  <td><button
                  type="button"
                  className={`btn btn-sm btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 <td>
                 <button className='btn btn-primary btn-sm mb-2 float-end'onClick={() => editviewopen(row.id)}>
                    Edit
                </button>
                 </td>
               </tr>
              ))}

            </table>
             )}
             {template_list.length > 0 && (
            <Pagination className="custom-pagination">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link-style"}
                breakLinkClassName={"break-link-style"}
                previousLinkClassName={"prev-link-style"}
                nextLinkClassName={"next-link-style"}
              />
            </Pagination>
          )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>OTP Template</DialogTitle>
          <DialogContent>
            <form>
              <div>
                  <label>Select Product</label>
                  <select className="form-control"
                        value={selectedproduct}
                        onChange={(e) => setselectedproduct(e.target.value)}
                        >
                        <option value=''>Select Product</option>
                        {productlist.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.product && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.product}</div>
                  )}
              </div>
              <div>
                  <label>Use as</label>
                  <select className="form-control"
                    value={use_as}
                    onChange={(e) => set_use_as(e.target.value)}
                        >
                        <option value=''>Select Use As</option>
                        <option value='0'>Site</option>
                        <option value='1'>App</option>
                  </select>
                  {validationErrors.use_as && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.use_as}</div>
                  )}
              </div>

              <div>
                  <label>Otp For</label>
                  <select className="form-control"
                   value={otp_for}
                   onChange={(e) => set_otp_for(e.target.value)}
                        >
                        <option value=''>Otp For</option>
                        <option value='0'>Vendor</option>
                        <option value='1'>Customer</option>
                  </select>
                  {validationErrors.otp_for && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.otp_for}</div>
                  )}
              </div>

              <div>
              <label>Api Key</label>
              <input
              type='text'
              className="form-control"
              value={api_key}
              onChange={(e) => set_api_key(e.target.value)}
             />
              {validationErrors.api_key && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.api_key}</div>
                  )}
             </div>

             <div>
              <label>Sender</label>
              <input
              type='text'
              className="form-control"
              value={sender}
              onChange={(e) => setsender(e.target.value)}
             />
             </div>

             <div>
              <label>Template id</label>
              <input
              type='text'
              className="form-control"
              value={template_id}
              onChange={(e) => set_template_id(e.target.value)}
             />
             </div>

             <div>
              <label htmlFor="messageTextarea">Template</label>
              <textarea
              className="form-control"
              value={template}
              onChange={(e) => settemplate(e.target.value)}
              rows="4" 
              style={{ width: '100%', padding: '8px', fontSize: '14px' }}
              />
             </div>
             {validationErrors.template && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.template}</div>
                  )}

             <div>
              <label>Remarks</label>
              <input
              type='text'
              className="form-control"
              value={remark}
              onChange={(e) => setremark(e.target.value)}
             />
             </div>

             <div>
              <label htmlFor="messageTextarea">Message</label>
              <textarea
              id="messageTextarea"
              className="form-control"
              value={message}
              onChange={(e) => setmessage(e.target.value)}
              rows="4" 
              style={{ width: '100%', padding: '8px', fontSize: '14px' }}
              />
               {validationErrors.message && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.message}</div>
                  )}
             </div>






              
        
              
            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick={handleCloseModal}>
              Close
            </Link>
            &nbsp;
            <button className="btn btn-info" onClick={handlesave}>
             Save
            </button>
          </DialogActions>
      </Dialog>

      <Dialog open={editopenModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>OTP Template Edit</DialogTitle>
          <DialogContent>
            <form>
              <div>
                  <label>Select Product</label>
                  <select className="form-control"
                        value={selectedproduct}
                        onChange={(e) => setselectedproduct(e.target.value)}
                        >
                        <option value=''>Select Product</option>
                        {productlist.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.product && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.product}</div>
                  )}
              </div>
              <div>
                  <label>Use as</label>
                  <select className="form-control"
                    value={use_as}
                    onChange={(e) => set_use_as(e.target.value)}
                        >
                        <option value=''>Select Use As</option>
                        <option value='0'>Site</option>
                        <option value='1'>App</option>
                  </select>
                  {validationErrors.use_as && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.use_as}</div>
                  )}
              </div>

              <div>
                  <label>Otp For</label>
                  <select className="form-control"
                   value={otp_for}
                   onChange={(e) => set_otp_for(e.target.value)}
                        >
                        <option value=''>Otp For</option>
                        <option value='0'>Vendor</option>
                        <option value='1'>Customer</option>
                  </select>
                  {validationErrors.otp_for && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.otp_for}</div>
                  )}
              </div>

              <div>
              <label>Api Key</label>
              <input
              type='text'
              className="form-control"
              value={api_key}
              onChange={(e) => set_api_key(e.target.value)}
             />
              {validationErrors.api_key && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.api_key}</div>
                  )}
             </div>

             <div>
              <label>Sender</label>
              <input
              type='text'
              className="form-control"
              value={sender}
              onChange={(e) => setsender(e.target.value)}
             />
             </div>

             <div>
              <label>Template id</label>
              <input
              type='text'
              className="form-control"
              value={template_id}
              onChange={(e) => set_template_id(e.target.value)}
             />
             </div>

             <div>
              <label htmlFor="messageTextarea">Template</label>
              <textarea
              className="form-control"
              value={template}
              onChange={(e) => settemplate(e.target.value)}
              rows="4" 
              style={{ width: '100%', padding: '8px', fontSize: '14px' }}
              />
             </div>
             {validationErrors.template && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.template}</div>
                  )}

             <div>
              <label>Remarks</label>
              <input
              type='text'
              className="form-control"
              value={remark}
              onChange={(e) => setremark(e.target.value)}
             />
             </div>

             <div>
              <label htmlFor="messageTextarea">Message</label>
              <textarea
              id="messageTextarea"
              className="form-control"
              value={message}
              onChange={(e) => setmessage(e.target.value)}
              rows="4" 
              style={{ width: '100%', padding: '8px', fontSize: '14px' }}
              />
               {validationErrors.message && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.message}</div>
                  )}
             </div>






              
        
              
            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick={handleCloseModal}>
              Close
            </Link>
            &nbsp;
            <button className="btn btn-info" onClick={handleupdate}>
             Save
            </button>
          </DialogActions>
      </Dialog>

    </DashboardLayout>
  );
}

export default OtpTemplate;