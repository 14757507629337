import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';
import { useNavigate,Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function EmpRoster() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const [roster, setRoster] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState('');
  const [reason, setReason] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [olddate,setolddate] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const isRequestButtonVisible = (weekOffDate) => {
    const currentDateTime = new Date();
    const weekOffDateTime = new Date(weekOffDate);

    // Compare dates
    return weekOffDateTime > currentDateTime;
  };

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchRoster();
  }, [currentPage]);

  const fetchRoster = async () => {
    try {
      const response = await dashboardApi.post('show-emp-roster', {
        emp_id: emp_id,
        per_page: 2,  // Assuming you always want 2 items per page
        page: currentPage + 1,
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        setRoster(employeeData);
        setNumber(response.data.last_page);
      } else {
        console.error("Error fetching:", response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching:", error);
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };

  const openview = (row) => {
    setSelectedRow(row.emp_id);
    setolddate(row.week_off);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const changeRequest = async (e) => {
    e.preventDefault();
    if (selectedRow) {
      const errors = {};
    if (!date) {
      errors.date = 'Date is required';
    }
    if (!reason) {
      errors.reason = 'Reason is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('old_date', olddate);
    formData.append('date', date);
    formData.append('reason', reason);
    formData.append('emp_id',emp_id);
    try {
      const response = await dashboardApi.post('request-to-change-roster', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         closeModal();
         setDate('');
         setReason('');
         navigate("/emp-roster");
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
  };

    } else {
      console.error('row is not defined');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Week Off</th>
                        <th>Shift Start Time</th>
                        <th>Shift End Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roster.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.week_off}</td>
                          <td>{row.start_time}</td>
                          <td>{row.end_time}</td>
                         
                            <td>
                              <button className="btn btn-info" onClick={() => openview(row)}>
                                Request To Change
                              </button>
                            </td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {roster.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
       <Dialog
        open={openModal}
        onClose={closeModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}
      >
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <form>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="example"> New  Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  {validationErrors.date&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.date}</div>
                             )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="example">Enter Reason</label>
                  <textarea
                    rows={4}
                    cols={50}
                    placeholder="Type something here..."
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                  {validationErrors.reason&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.reason}</div>
                             )}
                </div>
              </div>
            </div>
          </div>
           
          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick = {closeModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick = {changeRequest}>
           Update
          </Link>
        </DialogActions>
      </Dialog>

      
    </DashboardLayout>
  );
}

export default EmpRoster;
