import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,Link } from "react-router-dom";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import PackageDetailsHeader from "layouts/common_components/package_details_header";
import  secureLocalStorage  from  "react-secure-storage";

// for modalbox
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import Select from "@mui/material";



function ToLocationFactor()
{
    const navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({});
    const emp_id = secureLocalStorage.getItem('emp_id');
    const token = secureLocalStorage.getItem('token');
    // const [location_name,setLocationName] = useState('');
    const [toLocationFact,setToLocationFact] = useState('');
    // const [selectLocation,setSelectLocation] = useState('');
    const [location_price,setLocationPrice] = useState('');
    const [locationModal,setLocationModal] = useState(false);
    const [loading, setLoading] = useState(true);
    
    useEffect(()=>{
        if(!token)
        {
            navigate('/sign-in');
        }
        else
        {
            getToLocationFact();
           
        }
    },[token, navigate]);

    const locationModalOpenControl = ()=>{
        setLocationModal(true);
    }
    
    const locationModalCloseControl = ()=>{
        setLocationModal(false);
    }

    // Add New Location Attribute
    const toLocationSubmit = async() =>
    {
        const errors = {};
        if (Object.keys(errors).length > 0) {
        return; 
        }
        const formData = new FormData();
        //formData.append('location_id',selectedLocation);
        formData.append('price',location_price)
        formData.append('created_by',emp_id);
       
        try {
        const response = await dashboardApi.post('add-location-factor', formData);
            if(response.data.status === 200)
            {
                Swal.fire({
                icon: 'success',
                text: response.data.message,
            });

            navigate("/location-details");
            locationModalCloseControl();

        } else if (response.data.status === 400) {
            setValidationErrors(response.data.messages);
        } else {
            Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
            });
        }
        } catch (error) {
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
        } 
    }

    const getToLocationFact = async() =>{
        const locationResponce = await dashboardApi.get(`get-to-location-fact-list`);
        if(locationResponce.status==200)
        {
            setToLocationFact(locationResponce.data.data);
        }
        else
        {
            console.log('something went wrong,Please Try Again');
        }
    }

    

    return(
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>

                            <Card>
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-sm-11">
                                            <PackageDetailsHeader></PackageDetailsHeader>
                                        </div>
                                        <div class="col-sm-1">
                                        <Link className='btn btn-info' onClick={()=>locationModalOpenControl()}>
                                            +
                                        </Link>
                                        </div>
                                    </div>
                                    
                                    <div className="row" style={{marginTop:'1%'}}>
                                        <div class="col-sm-12"><h4>To Location Factor Details</h4></div>
                                    </div>

                                    <table className="table table-bordered table-hovered">
                                        <tr>
                                            <td>S.No</td>
                                            <td>Location</td>
                                            <td>Price</td>
                                            <td>Status</td>
                                            <td>Created By</td>
                                        </tr>
                                        
                                        {toLocationFact && toLocationFact.map((toLocationFact,index)=>(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>To Location</td>
                                            <td>{toLocationFact.price}</td>
                                            <td>{toLocationFact.status}</td>
                                            <td>{toLocationFact.created_by}</td>
                                        </tr>
                                        ))
                                        }
                                    </table>
                                </div>
                            </Card>
                            
                            
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />

                <Dialog open={locationModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '20px' } }}>
                    <DialogTitle>Add New Location Factor</DialogTitle>
                        <DialogContent>
                            <form>
                                <div className="col-sm-12" style={{marginTop:'5px'}}>
                                    <label>Price</label>   
                                    <TextField type="text" className="form-control" value={location_price} onChange={(e) =>setLocationPrice(e.target.value)}></TextField>
                                </div>
                            </form>
                        </DialogContent>

                        <DialogActions>
                            <Link className="btn btn-primary btn-sm" onClick={locationModalCloseControl}>Close</Link>
                            <Link className="btn btn-success btn-sm" onClick={toLocationSubmit}>Add Location</Link>
                        </DialogActions>
                   
                </Dialog>
            </DashboardLayout>
    );
}
export default ToLocationFactor;