import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

function AdditionalTarget() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
 // alert(dept_id);
  const token = secureLocalStorage.getItem('token');
  const [targetList, settargetlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(5);
  const [loading,setLoading] = useState(false);
  const [grouplist,setgrouplist] = useState([]);
  const [group,setgroup] = useState('');
  const [categorylist,setcategorylist] = useState([]);
  const [category,setcategory] = useState('');
  const [attribute,setattribute] = useState([]);
  const [selectedattribute,setselectedattribute] = useState('');
  const [openModal,setopenModal] = useState(false);
  const [openeditmodel,setopeneditmodel] = useState(false);
  const [department,setdepartment] = useState('')
  const [employee,setemployee] = useState([]);
  const [selectedemployee,setselectedemployee] = useState('');
  const [remark,setremark] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [value,setvalue] = useState('');
  const [assigngroup,setassigngroup] = useState('');
  const [assigncategory,setassigncategory] = useState('');
  const [assignattribute,setassignattribute] = useState('');
  const [selectedmonth,setmonth] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [financial_year,set_financialyear] = useState('');
  const [selected_financialyear,setselected_financialyear] = useState('');
  const [id,setid] = useState('');
  const [groupid,setgroupid] = useState('');
  const [categoryid,setcategoryid] = useState('')
  const [lead ,setlead] = useState('');
  const [selectedgroup,setselectedgroup] = useState('');
  const [additional,setadditional] = useState('');
  const [total,settotal] = useState('');
  const [remaining,getremaining] = useState('');
  const [assignmonth,setassignmonth] = useState('');
  const [assignyear,setassignyear] = useState('');
  const [goalattribute,setgoalattribute] = useState([]);
  const [goal,setselectedgoal] = useState('');
  const [source,setsource] = useState('');
  const [goalsubattribute,setgoalsubattribute] = useState([]);
  const [selectedgoalsubattribute,setselectedgoalsubattribute] = useState('');
  const [goalsubsubattribute,setgoalsubsubattribute] = useState([]);
  const [selectedgoalsubsubattribute,setselectedgoalsubsubattribute] = useState('');
  const [child_attribute,setchildattribute] = useState([]);
  const [selectedchild_attribute,setselectedchildattribute] = useState('');
  const [attributefilter,setattributefilter] = useState([]);
  const [valuefilter,setvaluefilter] = useState({});
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false); 
  var startYear, endYear;
if (currentDate.getMonth() < 3) {
    startYear = currentYear - 1;
    endYear = currentYear;
} else {
    startYear = currentYear;
    endYear = currentYear + 1;
}
const curr_fyear =  startYear + " - " + endYear;
const month = currentDate.toLocaleString('default', { month: 'long' });
//alert(month);

   const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
 // console.log(remaining);



  
  //const [attribute,setattribute]= useState('');

  //alert(dept_id);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchtarget();
    //fetchtarget();
  },[])
  useEffect(()=>{
    fetchattributelist();

  },[])

  useEffect(() => {
    const getCurrentFinancialYear = () => {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();

      const financialYearStart = currentMonth >= 4 ? currentYear : currentYear - 1;
      const financialYearEnd = financialYearStart + 1;

      return `${financialYearStart}-${financialYearEnd}`;
    };


    const generateFinancialYears = () => {
      const currentFinancialYear = getCurrentFinancialYear();
      const nextFinancialYears = [];

      for (let i = 1; i <= 5; i++) {
        const startYear = parseInt(currentFinancialYear.split('-')[0]) + i;
        const endYear = startYear + 1;
        nextFinancialYears.push(`${startYear}-${endYear}`);
      }
      const allFinancialYears = [currentFinancialYear, ...nextFinancialYears];
      setFinancialYears(allFinancialYears);
    };

    generateFinancialYears();
  }, []);
 




  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchTarget(selected.selected);
};
const fetchattributelist = async () => {
       try {
        const endpoint = 'active-attribute';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setgoalattribute(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
     }

 useEffect(() => {
if (goal && dept_id){
   //setInputValues('');
    const fetchData = async () => {
      try {
        const endpoint = `goal-based-attribute/${goal}/${dept_id}`;
        //const endpoint = `count-stock/${stock}`;
        const response = await dashboardApi.get(endpoint);
       // console.log(response);

        if (response.data.status === 200) {
          setgoalsubattribute(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
}
  }, [goal,dept_id]);
useEffect(() => {
 if (selectedgoalsubattribute && dept_id){
   //setInputValues('');
    const fetchData = async () => {
      try {
        const endpoint = `goal-based-subattribute/${selectedgoalsubattribute}/${dept_id}`;
        //const endpoint = `count-stock/${stock}`;
        const response = await dashboardApi.get(endpoint);
        //console.log(response);

        if (response.data.status === 200) {
          setgoalsubsubattribute(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
}
  }, [selectedgoalsubattribute,dept_id]);



useEffect(() => {
if (selectedgoalsubsubattribute && dept_id){
  //alert();
   //setInputValues('');
    const fetchData = async () => {
      try {
        const endpoint = `goal-based-sub-subattribute/${selectedgoalsubsubattribute}/${dept_id}`;
        //const endpoint = `count-stock/${stock}`;
        const response = await dashboardApi.get(endpoint);
        //console.log(response);

        if (response.data.status === 200) {
         setchildattribute(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
}
  }, [selectedgoalsubsubattribute,dept_id]);



  const fetchtarget = async (page = 0) => {
      try {
        const response = await dashboardApi.get('get-department-based-target', {
            params: {
              per_page: postPerPage,
              page: page + 1,
              dept_id:dept_id
              //emp_id:empId,
            }
          });
        if (response.data.status === 200) {
             const responseData = response.data.data;
             const stockdata = responseData.data || responseData;
             settargetlist(stockdata);
             setNumber(response.data.last_page);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'group-details';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
            setgrouplist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const endpoint = 'category-details';
  //       const response = await dashboardApi.get(endpoint);
  
  //       if (response.data.status === 200) {
  //           setcategorylist(response.data.data);
  //       } else {
  //         console.error('Error fetching data:', response.data.message);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   fetchData();
  // }, []);
  //alert(department);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const endpoint = `department-employee/${dept_id}`;
          const response = await dashboardApi.get(endpoint);
          //console.log(response);
  
          if (response.data.status === 200) {
            setemployee(response.data.data);
          } else {
            console.error('Error fetching states data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching states data:', error);
        }
      };
  
      fetchData();
  }, [dept_id]);

  const assign_to_team = async(id,group,category,month,financial_year,source)=>{
    //alert(financial_year);
    try {
          const endpoint = `count-assign-lead-data/${id}/${group}/${category}/${source}`;
          const response = await dashboardApi.get(endpoint);
          //console.log(response);
  
          if (response.data.status === 200) {
            getremaining(response.data.data);
          } else {
            console.error('Error fetching:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    setassigngroup(group);
    setassigncategory(category);
     setassignmonth(month);
    setassignyear(financial_year);
    setsource(source);
    setopenModal(true);
    }
 const handleclose = ()=>{
    setopenModal(false);
    setassigngroup('');
    setassigncategory('');
    setValidationErrors('');
    setselectedemployee('');
    setremark('');
    setvalue('');
    setassignmonth('');
    setassignyear('');
    setsource('');


 }
 //alert(year);
 const editrow   = async (group,category,id)=>{
    setopeneditmodel(true);
    setgroupid(group);
    setcategoryid(category);
    setid(id);


    try {
      const endpoint = `lead-edit/${id}/${group}/${category}`;
      const response = await dashboardApi.get(endpoint);
      //console.log(response);

      if (response.data.status === 200) {
        setlead(response.data.data);
      } else {
        console.error('Error fetching:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching', error);
    }

}
const updatedata = async(e)=>{
  e.preventDefault();
  //alert();
  const formData = new FormData()
  formData.append('lead', lead)
  try {
    const response = await dashboardApi.post(`save-edit-lead/${id}/${groupid}/${categoryid}`, formData);
    //console.log(response);

    if (response.data.status === 200) {
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
      fetchStock();
      //setopeneditmodel(false);
      handleeditclose();
    } else if (response.data.status === 400) {
      // Display validation errors below the input fields
      setValidationErrors(response.data.messages);
    } else {
      Swal.fire({
        icon: 'error',
        text: response.data.message || 'An error occurred',
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'An error occurred',
    });
  }



}
//alert(assignyear);
 const handlesave = async (e) => {
  e.preventDefault();
  const errors = {};
  setValidationErrors(errors);
 if (Object.keys(errors).length === 0) {
  const formData = new FormData()
  formData.append('emp_id',empId);
  formData.append('department',dept_id);
  formData.append('group',assigngroup);
  formData.append('category',assigncategory);
  formData.append('employee',selectedemployee);
  formData.append('remark',remark);
  formData.append('value',value);
  formData.append('year',assignyear);
  formData.append('month',assignmonth);
  formData.append('source',source)
  try {
    const response = await dashboardApi.post('save-assign-target', formData);

    if (response.data.status === 200) {
      //console.log('iiii');
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
      fetchStock();
      handleclose();

    } else if (response.data.status === 400) {
      // Display validation errors below the input fields
      setValidationErrors(response.data.messages);
    }
    else if (response.data.status === 201) {
      Swal.fire({
        icon: 'error',
        text: response.data.message,
      });
    }
     else {
      Swal.fire({
        icon: 'error',
        text: response.data.message || 'An error occurred',
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'An error occurred while creating the stock',
    });
  }
};
}
const handleeditclose = ()=>{
  setlead('');
  setValidationErrors('');
  setopeneditmodel(false);
}
//console.log(selectedattribute);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <div className="row mb-2">

              </div>

               

                <div style={{float:'right'}}>
                </div>

                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Product</td>
                        <td>Goal</td>
                        <td>Attribute</td>
                        <td>Subattribute</td>
                        <td>Child Attribute</td>
                        <td>Numbers</td>
                        <td>Per Month</td>
                        <td>Per Day</td>
                        <td>Financial Year</td>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {targetList.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.product}</td>
                          <td>{row.goal}</td>
                          <td>{row.attribute}</td>
                           <td>{row.subattribute}</td>
                          <td>{row.child_attribute}</td>
                          <td>{row.attribute_value}</td>
                          <td>{row.per_month}</td>
                          <td>{row.per_day}</td>
                          <td>{row.finanacial_year}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {targetList.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}
              </div>
            </Card>
            <Dialog
        open={openModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Assign</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <span style = {{color:'red',fontSize:'20px'}}>remaining : {remaining}</span>
              <div className="row">
                <div className="col-md-6">
                  <label>Select Employee:</label>
                  <select
                    className="form-control"
                    value={selectedemployee}
                    //onChange={setselecteddepartment}
                    onChange={(e) => setselectedemployee(e.target.value)}
                  >
                    <option value="">Select Employee</option>
                    {employee.map(dept => (
                      <option key={dept.emp_id} value={dept.emp_id}>
                        {dept.emp_fname}
                      </option>
                    ))}
                  </select>
                  {validationErrors.employee && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.employee}</div>
                  )}
                </div>
                <div className="col-md-6">
                <label>Assign Data:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={value}
                    onChange={(e) => setvalue(e.target.value)}
                    placeholder="Attribute"
                  />
                  {validationErrors.attribute && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.attribute}</div>
                  )}
                </div>
              </div>

              <div className="row">
                  <label>Remark:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={remark}
                    onChange={(e) => setremark(e.target.value)}
                    placeholder="Remark"
                  />
                  {validationErrors.remark && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                  )}
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={handleclose}>
            Close
          </button>
          <button className="btn btn-info" onClick={handlesave}>
            Save
          </button>
        </DialogActions>
       </Dialog>

       <Dialog
        open={openeditmodel}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Edit Data</DialogTitle>
        <DialogContent>
          <form>
            <div className="row">
                  <label>Number:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={lead}
                    onChange={(e) => setlead(e.target.value)}
                    placeholder="Remark"
                  />
                  {validationErrors.lead && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.lead}</div>
                  )}
              </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={handleeditclose}>
            Close
          </button>
          <button className="btn btn-info" onClick = {updatedata}>
            Save
          </button>
        </DialogActions>
       </Dialog>

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AdditionalTarget;