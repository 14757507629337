import React,{useState,useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Form, Button, ProgressBar } from 'react-bootstrap';
// Data
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";  
import ClientDetailsHeader from 'layouts/common_components/client_details_header';
import { TextField } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tab from 'assets/theme/components/tabs/tab';

import styled from "styled-components";
import ClientProfileHeader from '../../common_components/client_profile_header';
import { scale } from 'chroma-js';
import docurl from 'layouts/common_components/doc_base_url';

function ClientProfile()
{
  const { clientId } = useParams();
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
 // const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addModal,setAddModal]=useState(false);
  const [clientFollowup, setClientFollowup] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  
  //const [clientDetails,setClientDetails] = useState();
  const [documentType,setDocumentType] = useState([]);
  const [orgType,setOrgType] = useState();
  const [matureClientDetails,setMatureClientDetails] = useState();
  const [soldPackages,setSoldPackages] =useState('');

  const [orgTypeId,setOrgTypeId]=useState('');
  const [clientName,setClientName]=useState('');
  const [businessName,setBusinessName]=useState('');
  const [clientEmail,setClientEmail]=useState('');
  const [clientMobile,setClientMobile]=useState('');
  const [clientCity,setClientCity]=useState('');
  const [clientState,setClientState]=useState('');
  //const [clientPinNo,setClientPinNo]=useState('');
  const [clientAddress,setClientAddress]=useState('');
  const [clientPanCardNo,setPanCardNo]=useState('');

 
  const [clientZipcode, setZipCode] = useState('');
  const [selectedProposalId, setProposalId] = useState('');

  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search input value
  //const [selectedPackage, setSelectedPackage] = useState(''); // State for selected package
  const [showDropdown, setShowDropdown] = useState(false);
  
  // const handleChange = (e) => {
  //   setZipCode(e.target.value);
  // };

  const handleChange = (e) => {
    const value = e.target.value;

    // Allow only numbers
    if (/^\d{0,6}$/.test(value)) {
      setZipCode(value);
      setValidationErrors({ ...validationErrors, clientZipcode: '' });
    }

    // Validate if the entered value is not exactly 6 digits
    if (value.length > 0 && value.length !== 6) {
      setValidationErrors({ ...validationErrors, clientZipcode: 'ZIP code must be exactly 6 digits.' });
    } else {
      setValidationErrors({ ...validationErrors, clientZipcode: '' });
    }
  };

  const handleCompanyZipcode = (e) => {
    const value = e.target.value;

    // Allow only numbers and restrict to 6 digits
    if (/^\d{0,6}$/.test(value)) {
      setCompanyZipcode(value);
      
      // Set validation error if not exactly 6 digits
      if (value.length === 6 || value.length === 0) {
        setValidationErrors({ ...validationErrors, companyZipcode: '' });
      } else {
        setValidationErrors({ ...validationErrors, companyZipcode: 'ZIP code must be exactly 6 digits.' });
      }
    }
  };

  // Function to validate ZIP code
  const validateZipCode = (zip) => {
    // Regular expression to match 5-digit or 5-4 digit US ZIP codes
    const zipRegex = /^\d{6}(\d{4})?$/;
    return zipRegex.test(zip);
  };  

  const [isDivShow,setDivShow] = useState(false);
  const [followup,setFollowup] = useState([]);
  const [nextFollowupDate, setNextFollowupDate] = useState('');
 
  const [follwoupModal,setFollowupModal] = useState(false);
  const [selectedFollowCallStatus,setFollCallStatus] = useState(''); 
  const [selectedFollowStatus,setFollStatus] = useState('');
  const [followupRemarks , setFollowupRemarks] = useState('');
  const [nextDays,setNextDays] = useState(0);
  
  const [nxtFollowupDate, setNxtFollowupDate] = useState('');
  const myDate = new Date(nxtFollowupDate);
  const nxtFolDate= myDate.toLocaleDateString();

  const [packageby,setpackageby] = useState(false);
  const [prePackageDetails, setLmartPrePackageDetails] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(prePackageDetails);
  const [packagedetails,setpackagedetails] = useState({});

  const [walletHistory, setWalletHistory] =  useState();

  const [cityList,setCityList] = useState([]);
  const [selectedCity,setSelectedCity] = useState();

  const [product,setProduct]=useState([]);
  const [selectedProduct,setSelectedProduct] = useState('');

  const [service,setService]=useState([]);
  const [selectedService,setSelectedService] = useState('');
    
  const [category,setCategory]=useState([]);
  const [selectedCategory,setSelectedCategory] = useState('');

  const [prePackageList,setPackageList] = useState([]);
  const [selectedPackage,setSelectedPackage] = useState('');
  
  const [salePackageInfo,setSalePackageList] = useState({});
  const [walletDetails,setWalletDetails] = useState({});

  const [selectedRegAmount,setRegistrationAmount] = useState('');
  const [selectedGstPercent,setGstPercent] = useState('');
  const [groupList,setGroupList] = useState('');
  const [selectedGroup,setSelectedGroup]= useState('');

  const [selectedDocumentType,setSelectedDocumentType]=useState('');
  const [selectedDocumentNo,setSelectedDocument]=useState('');
  const [documentFile,setDocumentFile]=useState([]);
  const [clientDocDetails,setUplodedDocuments]=useState([]);

  const [businessEmail,setBusinessEmail]=useState('');
  const [businessMobile,setBusinessMobile]=useState('');
  const [companyCity,setCompanyCity]=useState('');
  const [companyState,setCompanyState]=useState('');
  const [companyAddress,setCompanyAddress]=useState('');
  const [companyPanCardNo,setCompanyPanCardNo]=useState('');
  const [companyZipcode,setCompanyZipcode]=useState('');
  
  
  const [packageCategory,setPackageCategory]=useState([]);
  const [prePackageId, setPrePackageId]=useState('');
  const [prePackages,setPrePackages]=useState('');
  const [gstStateCode, setGstStateCode] = useState("");
  //console.log(clientDocDetails.length);
  const handleFileChange = (e) => {
    setDocumentFile(e.target.files);
  };

  const[gstNumber,setGstNumber] = useState('');

  const [pkgTypeList,setPkgTypeList] = useState();
  const [selectedPkgType,setSelectedPkgType]= useState();
  //const [selectedDocument,setSelectedDocument] = useState([]);
  //const [selectedDoc, setDocNo] = useState();

  const [gstOption, setGstOption] = useState(""); 



    const [sameAsClientInfo, setSameAsClientInfo] = useState(false);
  
  
    // Handle the checkbox toggle
    const handleSameAsClientInfo = (e) => {
      const isChecked = e.target.checked;
      setSameAsClientInfo(isChecked);
  
      // If checked, copy permanent address to communication address
      if(isChecked) {
        setBusinessName(clientName);
        setBusinessEmail(clientEmail);
        setBusinessMobile(clientMobile);
        setCompanyCity(selectedCity);
        setCompanyState(clientState);
        setCompanyAddress(clientAddress);
        setCompanyPanCardNo(clientPanCardNo);
        setCompanyZipcode(clientZipcode);
        setBusinessEmail(clientEmail);
        setGstStateCode(gstStateCode);
      }
      else
      {
        setBusinessName('');
        setBusinessEmail('');
        setBusinessMobile('');
        setCompanyCity('');
        setCompanyState('');
        setCompanyAddress('');
        setCompanyPanCardNo('');
        setCompanyZipcode('');
        setBusinessEmail('');
        setGstStateCode('');
      }
    };

  
  

  const getPackageCategory = async()=>
  {
    const response = await dashboardApi.get('get-package-category',{
      params:{
        product_id:1
      }})

      if(response.data.status === 200)
      {
        setPackageCategory(response.data.data);
      }
      else
      {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
  }

  useEffect(()=>
  { 
    const getPackageCategoryById =async()=>
    {
      const response = await dashboardApi.get('get-pre-package-by-category',{
        params:{
          product_id:selectedProduct,
          category_id:prePackageId,
          group:selectedCity,
          service:selectedService,
        }})

        if(response.data.status === 200)
        {
          setPrePackages(response.data.data);
        }
        else{
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
    }
    getPackageCategoryById();
  },[prePackageId,selectedProduct,selectedCity,selectedService])

  

  const handleFilter = (event) => {
    const value = event.target.value;
    const filtered = prePackageDetails.filter(category => category.category_name.includes(value));
      setFilteredUsers(filtered);
  };

  const categoryNameFilter = prePackageDetails.map(q => q.category_name);
  categoryNameFilter.filter((q, idx) => categoryNameFilter.indexOf(q) !== idx);
  const [depositAmount,setDepositAmount]= useState('');

  const [deposit_amount , setDepositAmountByClient] = useState('');

    const getGroupList = async () =>
    {
        try
        {
             const response = await dashboardApi.get(`get-group-list`);
            if (response.data.status === 200)
            {
                setGroupList(response.data.data);
            } 
        }
        catch(error)
        {
            console.error('Error fetching countries:', error);
        }
    }

    // const handleStateChange = (e) =>
    // {
    //   const selectedState = e.target.value;
    //   setCompanyState(selectedState);
    
     
    //   console.log(stateList);
    //   const selectedStateObj = stateList.find(
        
    //     (stateInfo) => stateInfo.id === selectedState
    //   );
    //   console.log(selectedStateObj);
    
    //   if(selectedStateObj)
    //   {
    //     alert(selectedStateObj.gst_state_code);
    //     setGstStateCode(selectedStateObj.gst_state_code);
    //   } else {
    //     setGstStateCode(""); // Clear GST code if no state is selected
    //   }
    // };

    const handleStateChange = (e) => {
      const selectedState = e.target.value;
      setCompanyState(selectedState);
      setClientState(selectedState);
      // Find the GST code based on the selected state
      const selectedStateObj = stateList.find(
        (stateInfo) => String(stateInfo.id) === selectedState  // Convert both to string for comparison
      );
    
      if (selectedStateObj) {
        console.log("Selected State GST Code:", selectedStateObj.gst_state_code);
        setGstStateCode(selectedStateObj.gst_state_code);
      } else {
        setGstStateCode(""); // Clear GST code if no valid state is selected
      }
    };

    const getClientUplodedDocument = async () =>
    {
        try
        {
              const response = await dashboardApi.get(`get-client-uploded-document/${clientId}`);
            if (response.data.status === 200)
            {
                setUplodedDocuments(response.data.data);
            } 
        }
        catch(error)
        {
            console.error('Error fetching countries:', error);
        }
    }
    console.log(documentFile);

    const uplodeDocument = async(e) =>
    {
          e.preventDefault();
          const errors = {};
          if(!selectedDocumentType)
          {
            errors.selectedDocumentType = 'Document type is required.';
          }

          if(!selectedDocumentNo)
          {
            errors.selectedDocumentNo = 'Document no is required.';
          }

          if(!documentFile)
          {
            errors.documentFile = 'Document file is required.';
          }
          setValidationErrors(errors);

          if(Object.keys(errors).length > 0)
          {
            return;
          }

          const formData = new FormData();
          for (let i = 0; i < documentFile.length; i++) {
            formData.append('document_file[]', documentFile[i]);
          }

          formData.append('document_type',selectedDocumentType);
          formData.append('document_no',selectedDocumentNo);
         // formData.append('document_file',JSON.stringify(documentFile));
          formData.append('client_id',clientId);
          formData.append('created_by',emp_id);
          try 
          {
              const response = await dashboardApi.post('uplode-client-document', formData);
                if(response.data.status === 200)
                {
                  Swal.fire({
                  icon: 'success',
                  text: response.data.message,
                  });
                  setSelectedDocument('');
                  setDocumentFile('');
                  setSelectedDocumentType('');
                  getClientUplodedDocument();
                }
                else
                {
                  Swal.fire({
                  icon: 'error',
                  text: response.data.message || 'An error occurred',
                  });
                }
          }catch (error){
            Swal.fire({
                icon: 'error',
                text: 'An error occurred while creating the product',
            });
          }
      }

    const emptyFollowupFields = ()=>
    {
        setFollCallStatus('');
        setFollStatus('');
        setNxtFollowupDate('');
        setFollowupRemarks('');
        setSelectedPackage('');
        setSelectedProduct('');
        setSelectedService('');
        setSelectedCategory('');
        setSelectedCity('');
       
    }

    const submitFollowupForm = async () => {
      const errors = {};
    
      // Validate remarks
      if (!followupRemarks) {
        errors.followupRemarks = 'Remarks are required';
      }
    
      // Validate package if the follow-up status matches certain values
      if ([5, 6, 7, 9].includes(parseInt(selectedFollowStatus))) {
        if (!selectedPackage) {
          errors.selectedPackage = 'Package is required';
        }
      }
    
      // Validate next follow-up date if it is not empty
      if (!nxtFollowupDate) {
        errors.nxtFollowupDate = 'Next Follow-up Date is required';
      }
    
      // Check if there are validation errors
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);  // Set errors in state to display them in the UI
        return; // Return early to stop form submission
      }
    
      // If no errors, clear the validation state
      setValidationErrors({});
    
      // Prepare form data for submission
      const formData = new FormData();
      formData.append('disposition', selectedFollowCallStatus);
      formData.append('followup_status', selectedFollowStatus);
      formData.append('next_followup_date', nxtFolDate);  // Ensure this is a date string
      formData.append('remarks', followupRemarks);
      formData.append('refer_package_id', selectedPackage);
      formData.append('client_id', clientId);
      formData.append('created_by', emp_id);
      formData.append('client_type', 1);
      formData.append('product_id', selectedProduct);
      formData.append('service_id', selectedService);
      formData.append('category_id', selectedCategory);
      formData.append('city_id', selectedCity);
    
      try {
        // Make the API call to submit the form data
        const response = await dashboardApi.post('add-client-followup', formData);
        
        // Check the response status
        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
          closeFollowupControl();
          checkFollowupDetails(); // Refresh follow-up details if necessary
          emptyFollowupFields();  // Clear form fields after submission
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while creating the follow-up',
        });
      }
    };
    

    // const submitFollowupForm = async() =>
    // {
    //     const errors = {};

    //     if(!followupRemarks)
    //     {
    //       errors.followupRemarks = 'Remarks is required';
    //     }

    //     if(selectedFollowStatus ==5 | selectedFollowStatus ==6 | selectedFollowStatus ==7 | selectedFollowStatus == 9)
    //     {
    //       if(!selectedPackage)
    //       {
    //         errors.selectedPackage = 'Package is required';
    //       }
    //     }

    //     if(Object.keys(errors).length > 0)
    //     {
    //       return;
    //     }

    //     const formData = new FormData();
    //     formData.append('disposition',selectedFollowCallStatus);
    //     formData.append('followup_status',selectedFollowStatus);
    //     formData.append('next_followup_date',nxtFolDate);
    //     formData.append('remarks',followupRemarks);
    //     formData.append('refer_package_id',selectedPackage);
    //     formData.append('client_id',clientId);
    //     formData.append('created_by',emp_id);
    //     formData.append('client_type',1);
    //     formData.append('product_id',selectedProduct);
    //     formData.append('service_id',selectedService);
    //     formData.append('category_id',selectedCategory);
    //     formData.append('city_id',selectedCity);
        
    //     try
    //     {
    //         const response = await dashboardApi.post('add-client-followup', formData);
    //           if(response.data.status === 200)
    //           {
    //               Swal.fire({
    //               icon: 'success',
    //               text: response.data.message,
    //               });
    //             closeFollowupControl();
    //             checkFollowupDetails();
    //             emptyFollowupFields();
    //           }
    //           else
    //           {
    //             closeFollowupControl(false);
    //             Swal.fire({
    //             icon: 'error',
    //             text: response.data.message || 'An error occurred',
    //             });
    //           }
    //     }catch (error){
    //       Swal.fire({
    //           icon: 'error',
    //           text: 'An error occurred while creating the product',
    //       });
    //     } 
    // }

    const submitCreatePackageForm = async() =>
    {
      const errors = {};
      if (Object.keys(errors).length > 0)
      {
        return; 
      }
      const formData = new FormData();
      formData.append('disposition',selectedFollowCallStatus);
      formData.append('followup_status',selectedFollowStatus);
      formData.append('next_followup_date',nxtFolDate);
      formData.append('remarks',followupRemarks);
      formData.append('refer_package_id',selectedPackage);
      formData.append('client_id',clientId);
      formData.append('created_by',emp_id);
      formData.append('client_type',1);
      formData.append('product_id',selectedProduct);
      formData.append('service_id',selectedService);
      formData.append('category_id',selectedCategory);
      formData.append('city_id',selectedCity);
       
      try 
      {
          const response = await dashboardApi.post('create-client-package', formData);
            if(response.data.status === 200)
            {
                Swal.fire({
                icon: 'success',
                text: response.data.message,
                });
              closeFollowupControl();
              checkFollowupDetails();
            }
            else
            {
              Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
              });
            }
      }catch (error){
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
      } 
    }
 
    const submitPackageForm = async ()=>{
      
      const response = await dashboardApi.get('buy-new-package',{
        params:{
          client_id:clientId,
          package_id:selectedPackage,
          depositAmount:deposit_amount,
          created_by:emp_id
        }
      });
      if (response.data.status === 200)
      {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
          });
        
      } 
    }

    const getWalletDetails = async () =>
    {
        try {
            const response = await dashboardApi.get(`get-wallet-details/${clientId}`);
            if(response.data.status === 200)
            {
              setWalletDetails(response.data.data);
            } 
          }
          catch(error)
          {
            console.error('Error fetching countries:', error);
          }
    }
  
  
    const getCityDetails= async()=>
    {
      try {
          const response = await dashboardApi.get(`get-city-details`);
          if (response.data.status === 200)
          {
            setCityList(response.data.data);
          } 
          else
          {
           console.log('Data not found');
          }
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
    }

    const getPrePackageDetails = async() =>{
      const response = await dashboardApi.get(`get-lmart-pre-packages-list`);
      if (response.data.status === 200)
      {
        setLmartPrePackageDetails(response.data.data);
      } 
    }
  useEffect(()=>{
    checkClientDetails();
    getCityDetails();
    getProduct();
    getWalletDetails();
    getGroupList();
    getPackageCategory();
  },[])

  const getProduct = async () => 
  {
    try{
      const response = await dashboardApi.get(`sales-product-list/active`);
      if(response.data.status === 200)
      {
        setProduct(response.data.data);
      }
      else
      {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }
    catch (error)
    {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Ensure gstNumber is a string
    const gstNumberStr = gstNumber || '';

    if (gstOption === 'Yes') {
      if (gstNumberStr.length === 0) {
        setValidationErrors({ ...validationErrors, gstNumber: 'GST Number is required.' });
      } else if (gstNumberStr.length !== 3) {
        setValidationErrors({ ...validationErrors, gstNumber: 'Enter exactly 3 characters.' });
      } else {
        setValidationErrors({ ...validationErrors, gstNumber: '' });
      }
    } else {
      setValidationErrors({ ...validationErrors, gstNumber: '' });
    }
  }, [gstOption, gstNumber]);

  

  const handleGstNumberChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (value.length <= 3) {
      setGstNumber(value);
    }
  };

    useEffect(()=>{
      if(selectedProduct)
      {
        const fetchService = async () => {
          try {
            const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
            if (response.data.status === 200) {
              setService(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchService();
      }
    },[selectedProduct]);

    useEffect(()=>{
      if(selectedProduct && selectedCity && selectedService)
      {
        const fetchService = async () => {
          try {
            const response = await dashboardApi.get(`get-pre-package-list/${selectedProduct}/${selectedCity}/${selectedService}`);
            if (response.data.status === 200) {
              setPackageList(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchService();
      }
    },[selectedCity,selectedCity,selectedService]);

    useEffect(()=>{
      if(selectedPackage && selectedFollowStatus==19)
      {
        const fetchPrePackageDetails = async () => {
          try {
            const response = await dashboardApi.get(`get-sale-package-details/${selectedPackage}`);
            if (response.data.status === 200) {
              setSalePackageList(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchPrePackageDetails();
      }
    },[selectedPackage,selectedFollowStatus]);

    useEffect(()=>{
      if(selectedPackage && selectedFollowStatus==19)
      {
        const fetchTaxRegDetails = async () => {
          try {
            const response = await dashboardApi.get(`get-tax-and-reg-details/${selectedProduct}`);
            if (response.data.status === 200) {
              setRegistrationAmount(response.data.data.reg_amount);
              setGstPercent(response.data.data.gst_percent);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchTaxRegDetails();
      }
    },[selectedPackage,selectedFollowStatus]);


    
  
      useEffect(()=>{
          if(selectedService)
          {
          const fetchCategory = async () => {
            try {
              const response = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
              if (response.data.status === 200) {
                  setCategory(response.data.data);
              } else {
                  console.error('Error fetching countries:', response.data.message);
              }
              setLoading(false);
              } catch (error) {
              console.error('Error fetching countries:', error);
              setLoading(false);
              }
          }
          fetchCategory();
          }
      },[selectedService]);

    useEffect(()=>{
      if(selectedFollowCallStatus)
      {
        const getFollowupStatus = async () => {
          try {
          const response = await dashboardApi.get(`get-followup-list/${selectedFollowCallStatus}`);
          if (response.data.status === 200) {
            setFollowup(response.data.data);
          } else {
              console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
          } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
          }
      }
      getFollowupStatus();
      }
    },[selectedFollowCallStatus]);
 

  useEffect(()=>{
    if(selectedFollowStatus)
    {
      const getNextFollowupDate = async () => {
          try {
          const response = await dashboardApi.get(`get-next-followup-date/${selectedFollowStatus}`);
          if (response.data.status === 200)
          {
            setNextFollowupDate('');
            setNextDays(response.data.data);
            
          } else {
              console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
      }
      getNextFollowupDate();
    }
  },[selectedFollowStatus]);

  const handleFormSubmit = async(e) =>
  {
      e.preventDefault();
      const errors = {};
      if (Object.keys(errors).length > 0)
      {
        return; 
      }
      const formDataClient = new FormData();
      
      //client basic info
      formDataClient.append('clientName',clientName);
      formDataClient.append('clientEmail',clientEmail);
      formDataClient.append('clientMobile',clientMobile);
      formDataClient.append('clientCity',clientCity);
      formDataClient.append('clientState',clientState);
      formDataClient.append('clientZipcode',clientZipcode);
      formDataClient.append('clientAddress',clientAddress);
      formDataClient.append('clientPanCardNo',clientPanCardNo);
      formDataClient.append('created_by',emp_id);
      formDataClient.append('guestClientId',clientId);

      //company details
      formDataClient.append('businessName',businessName);
      formDataClient.append('businessEmail',businessEmail);
      formDataClient.append('businessMobile',businessMobile);
      formDataClient.append('companyCity',companyCity);
      formDataClient.append('companyState',companyState);
      formDataClient.append('companyAddress',companyAddress);
      formDataClient.append('companyPanCardNo',companyPanCardNo);
      formDataClient.append('companyZipcode',companyZipcode);
      formDataClient.append('clientGroup',selectedGroup);
      formDataClient.append('orgTypeId',orgTypeId);
      formDataClient.append('clientProduct',selectedProduct);
      formDataClient.append('clientService',selectedService);

      try {
        const response = await dashboardApi.post('add-mature-client-details', formDataClient);
        console.log(response);
        if(response.data.status === 200)
        {
            Swal.fire({
                icon: 'success',
                text: response.data.message,
            });
            navigate("/verified-client-details");
        }
        else if (response.data.status === 400) {
            //setValidationErrors(response.data.messages);
        } 
        else
        {
            Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
            });
        }
      } catch (error) {
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
      } 
  }
  
  const Tab = styled.button`
  font-size: 16px;
  padding: 10px 10px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid blue;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const types = ["Followup","Client Verifiy","Services"];
const [active, setActive] = useState(types[0]);

const [stateList,setStateList] = useState('');
const [cityDetails,setCityDetails] = useState([]);



styled.td`
  padding: .1rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  margin-bottom: .1rem;
  `;
useEffect(()=>{
  checkFollowupDetails();
  getOrganizationType();
  getStateList();
  getPrePackageDetails();
  getClientUplodedDocument();
},[])


  const checkFollowupDetails = async () =>
  {
    try {
        const response = await dashboardApi.get(`check-client-followup-details/${clientId}`);
        if (response.data.status === 200)
        {
          setClientFollowup(response.data.data);
        } 
      }
      catch(error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
  }

  // useEffect(()=>{
  //   if(orgTypeId && selectedProduct && selectedService)
  //   {
     
  //     const getDocumentType = async () =>
  //     {
  //       try {
  //           const response = await dashboardApi.get(`get-document-type/${orgTypeId}/${selectedProduct}/${selectedService}`);
  //           if(response.data.status === 200)
  //           {
  //             setDocumentType(response.data.data);
  //           } 
  //         }
  //         catch(error)
  //         {
  //           console.error('Error fetching countries:', error);
  //           setLoading(false);
  //         }
  //     }
  //     getDocumentType();
  //   }
      
  // },[orgTypeId,selectedProduct,selectedService]);

  useEffect(() => {
    console.log("Dependencies:", { orgTypeId, selectedProduct, selectedService });
  
    if (orgTypeId && selectedProduct && selectedService) {
      const getDocumentType = async () => {
        setLoading(true); // Optionally set loading to true here
        try {
          const response = await dashboardApi.get(`get-document-type/${orgTypeId}/${selectedProduct}/${selectedService}`);
          
          if (response.data.status === 200) {
            setDocumentType(response.data.data);
          } else {
            console.error('Error: Response status not 200', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching document type:', error);
        } finally {
          setLoading(false); // Make sure loading is reset even in case of error
        }
      };
  
      getDocumentType();
    }
  }, [orgTypeId, selectedProduct, selectedService]);

  const getOrganizationType = async () =>
  {
    try {
        const response = await dashboardApi.get(`get-organization-type`);
        if(response.data.status === 200)
        {
          setOrgType(response.data.data);
        } 
      }
      catch(error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
  }

  const getStateList = async () =>
  {
    try {
        const response = await dashboardApi.get(`get-state-list`);
        if(response.data.status === 200)
        {
          setStateList(response.data.data);
        } 
      }
      catch(error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
  }

  // useEffect(()=>{
  //   if(clientState,companyState)
  //   {
  //     let citySearch='';
  //     if(clientState)
  //     { 
  //       citySearch =clientState;
  //     }
  //     else
  //     {
  //       citySearch =companyState;
  //     }
  //     const getCityList = async() => {
  //       const response = await dashboardApi.get(`get-city-by-state-name/${citySearch}`);
  //       if (response.data.status === 200)
  //       {
  //         setCityDetails(response.data.data);
  //       }
  //       else
  //       {
  //         Swal.fire({
  //           icon: 'error',
  //           text: response.data.message,
  //         });
  //       } 
  //     }
  //     getCityList();
  //   }
  // },[companyState,clientState]);

  useEffect(() => {
    // Check if either clientState or companyState is set
    if (clientState || companyState) {
      let citySearch = ''; // Use 'let' for reassigning values
  
      // Set citySearch based on which state is selected
      if (clientState) {
        citySearch = clientState;
      } else {
        citySearch = companyState;
      }
  
      // Fetch city list based on the selected state
      const getCityList = async () => {
        try {
          const response = await dashboardApi.get(`get-city-by-state-name/${citySearch}`);
          if (response.data.status === 200) {
            setCityDetails(response.data.data);
          } else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'Failed to fetch city list. Please try again.',
          });
        }
      };
  
      getCityList();
    }
  }, [companyState, clientState]);
  
  useEffect(()=>{
    if(active ==='Wallet History')
    {
      const getWalletHistory = async() => {
        const response = await dashboardApi.get(`get-wallet-history/${clientId}`);
        if (response.data.status === 200)
        {
          setWalletHistory(response.data.data);
        } 
      }
      getWalletHistory();
    }
    
  },[active ==='Wallet History']);

    const salePackageNew = async(id,category_name,service_name,duration_name,price,name,total_lead)=>{
      try {
        const response = await dashboardApi.get('check-client-wallet-balance', {
          params: {
              id:id,
              category_name:category_name,  
              service_name:service_name,
              duration_name:duration_name,
              price:price,
              name:name,
              total_lead:total_lead,
              client_id:clientId,
            }
        });
        if(response.data.status === 200)
        {
            setpackagedetails(response.data.data)
            setpackageby(true);
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
            });
        }
        console.log(packagedetails);
    } 
    catch (error) 
    {
      console.error('Error fetching data:', error);
    }
      
    }

    const sentPurposal = async(id)=>
    {
      setProposalId(id);
      setLoading(true);
      try
      {
        const response = await dashboardApi.get('sent-purposal-to-client', {
          params: {
              id:id,
              client_id:clientId,
              emp_id:emp_id,
              user_type:'guest'
            }
        });
        if(response.data.status === 200)
        {
          setLoading(false);
          setProposalId('');
          Swal.fire({
            icon: 'success',
            text: response.data.message || 'An error occurred',
            });
        }
        else
        {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
            });
        }
        //console.log(packagedetails);
      } 
      catch (error) 
      {
        setLoading(false);
        console.error('Error fetching data:', error);
      } 
    }

    const closePackageModel=()=>{
      setpackageby(false);
    }

    


  const [step, setStep] = useState(1);
  //const [formData, setFormData] = useState({});
  //console.log(formData);

  // const handleNext = async() => {
  //   const errors = {};

  //   if (!clientName) {
  //     errors.clientName = 'Name is required';
  //   }
  
  //   if (!clientEmail) {
  //     errors.clientEmail = 'Email is required';
  //   }
  //   if (!selectedGroup) {
  //     errors.selectedGroup = 'Group is required';
  //   }
  //   if (!clientMobile) {
  //     errors.clientMobile = 'Mobile Number is required';
  //   }
    
  //   if (!clientCity) {
  //     errors.clientCity = 'City is required';
  //   }
  //   if (!clientState) {
  //     errors.clientState = 'State is required';
  //   }
  //   if(!clientZipcode)
  //   {
  //     errors.clientZipcode = 'Zipcode is required';
  //   }

  //   if(gstOption === "")
  //   {
  //     errors.gstOption ="Please select an option for GST.";
  //   } else {
  //     errors.gstOption="";
  //   }



  //   if (!clientAddress)
  //   {
  //     errors.clientAddress = 'Address is required';
  //   }
  //  // alert(clientPanCardNo);
  //   if (!clientPanCardNo)
  //   {
  //     errors.clientPanCardNo = 'Pancard No is required';
  //   }

  //   if(clientPanCardNo !='')
  //   {
  //     const response = await dashboardApi.get(`check-client-pancard-number/${clientPanCardNo}/${clientId}`);
  //     if(response.data === 1)
  //     {
  //       setPanCardNo('');
  //       errors.clientPanCardNo = 'Pancard already added in your system. ( ' + clientPanCardNo +' )';
  //     } 
  //   }

  //   //company details 
  //   if(!businessName)
  //   {
  //     errors.businessName = 'Business Name is required';
  //   }
    
  //   if(!businessEmail)
  //   {
  //     errors.businessEmail = 'Business email is required';
  //   }

  //   if(!businessMobile)
  //   {
  //     errors.businessMobile = 'Business mobile is required';
  //   }

  //   if(!companyCity)
  //   {
  //     errors.companyCity = 'Company city is required';
  //   }

  //   if(!companyState)
  //   {
  //     errors.companyState = 'Company state is required';
  //   }

  //   if(!companyAddress)
  //   {
  //     errors.companyAddress = 'Company address is required';
  //   }

  //   if(!companyPanCardNo)
  //   {
  //     errors.companyPanCardNo = 'Company pan card is required';
  //   }

  //   if(!companyZipcode)
  //   {
  //     errors.companyZipcode = 'Company zipcode is required';
  //   }

  //   if(!orgTypeId)
  //   {
  //     errors.orgTypeId = 'Org Type is required';
  //   }
    
  //   if(!selectedProduct)
  //   {
  //     errors.selectedProduct = 'Product is required';
  //   }
    
  //   if(!selectedService)
  //   {
  //     errors.selectedService = 'Service is required';
  //   }
        
  //   setValidationErrors(errors);
  //   if (Object.keys(errors).length === 0) {
  //   setStep(step + 1);
  //   }
  // };

  const handleNext = async () => {
    const errors = {};
  
    // Client Details Validation
    if (!clientName) {
      errors.clientName = 'Name is required';
    }
  
    if (!clientEmail) {
      errors.clientEmail = 'Email is required';
    }
  
    if (!selectedGroup) {
      errors.selectedGroup = 'Group is required';
    }
  
    if (!clientMobile) {
      errors.clientMobile = 'Mobile Number is required';
    }
  
    if (!clientCity) {
      errors.clientCity = 'City is required';
    }
  
    if (!clientState) {
      errors.clientState = 'State is required';
    }
  
    if (!clientZipcode) {
      errors.clientZipcode = 'Zipcode is required';
    }
  
    if (gstOption === "") {
      errors.gstOption = "Please select an option for GST.";
    }
  
    if (!clientAddress) {
      errors.clientAddress = 'Address is required';
    }
  
    if (!clientPanCardNo) {
      errors.clientPanCardNo = 'Pancard No is required';
    } else if (clientPanCardNo) {
      // Check if PAN Card is already in the system
      const response = await dashboardApi.get(`check-client-pancard-number/${clientPanCardNo}/${clientId}`);
      if (response.data === 1) {
        errors.clientPanCardNo = `Pancard already added in your system ( ${clientPanCardNo} ).`;
      }
    }
  
    // Company Details Validation
    if (!businessName) {
      errors.businessName = 'Business Name is required';
    }
  
    if (!businessEmail) {
      errors.businessEmail = 'Business email is required';
    }
  
    if (!businessMobile) {
      errors.businessMobile = 'Business mobile is required';
    }
  
    if (!companyCity) {
      errors.companyCity = 'Company city is required';
    }
  
    if (!companyState) {
      errors.companyState = 'Company state is required';
    }
  
    if (!companyAddress) {
      errors.companyAddress = 'Company address is required';
    }
  
    if (!companyPanCardNo) {
      errors.companyPanCardNo = 'Company PAN card is required';
    }
  
    if (!companyZipcode) {
      errors.companyZipcode = 'Company zipcode is required';
    }
  
    // Organization Type Validation
    if (!orgTypeId) {
      errors.orgTypeId = 'Org Type is required';
    }
  
    // Product and Service Validation
    if (!selectedProduct) {
      errors.selectedProduct = 'Product is required';
    }
  
    if (!selectedService) {
      errors.selectedService = 'Service is required';
    }
  
    // Set validation errors
    setValidationErrors(errors);
  
    // Proceed to the next step only if no errors
    if (Object.keys(errors).length === 0) {
      setStep(step + 1);
    }
  };
  
  const handlePrevious = () => {
    setStep(step - 1);
  };

  const followupOpenControl=()=>{
    setFollowupModal(true);
  }
  
  const closeFollowupControl = ()=>
  {
    setFollowupModal(false);
  }

  const closeModalOpenControl = ()=>
  {
    setAddModal(false);
  }
  const checkClientDetails = async () =>
  {
    try {
        const response = await dashboardApi.get(`check-client-details/${clientId}`);
        if(response.data.status === 200)
        {
          setClientName(response.data.data.name);
          setBusinessName(response.data.data.business_name)
          setClientEmail(response.data.data.email_id);
          setClientMobile(response.data.data.mobile_no)
          setClientCity(response.data.data.city_name);
          setClientState(response.data.data.state);
          setZipCode(response.data.data.zipcode);
          setClientAddress(response.data.data.address);
          setSelectedGroup(response.data.data.group_id);
          setSelectedProduct(response.data.data.product_id);
          //setSelectedService(response.data.data.service_id);
        } 
      }
      catch(error)
      {
        console.error('Error fetching countries:', error);
      }
  }

    const Tab1 = styled.button`
    font-size: 16px;
    padding: 10px 10px;
    margin-right:10px;
    cursor: pointer;
    opacity: 0.6;
    color:black;
    border: 0;
    outline: 0;
    ${({ active }) => active && `border-bottom: 4px solid #0e3a6a; opacity: 1;`}`;

    const ButtonGroup1 = styled.div`display: flex;`;
    const topMenuBar1 = ["Lmart Packages","Zoopgo Packages","Others Packages","Website Packages"];
    const [activeMenu1, setActive1] = useState(topMenuBar1[0]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <ClientProfileHeader></ClientProfileHeader>
                <div className="row">
                  <ButtonGroup>
                  {types.map((type) => (
                    <Tab
                      key={type}
                      active={active === type}
                      onClick={() => setActive(type)}
                    >
                      {type}
                    </Tab>
                  ))}
                </ButtonGroup>
                </div>
              </div>

                {active ==='Followup' &&
                  <div className='card-body' id="followupDiv" style={{overflowY: "hidden"}}>
                      <table className="table table-bordered table-hovered">
                        <tr>
                          <td colspan="12">
                            <Link className='btn btn-info btn-sm' style={{float:'right'}} onClick={followupOpenControl}>
                              +
                            </Link>
                          </td>
                        </tr>
                        <tr style={{fontWeight:'700'}}>
                          <td>S.No</td>
                          <td>Client Type</td>
                          <td>City Name</td>
                          <td>Refer Package Name</td>
                          <td>Package Price</td>
                          <td>Disposition</td>
                          <td>Followup Info</td>
                          <td>Followup Status</td>
                          <td>Remark</td>
                          <td>Followup Date</td>
                          <td>Created By</td>
                          <td>Created Date</td>
                        </tr>

                        {clientFollowup && clientFollowup.map((cd,index)=>(
                          <tr>
                              <td>{index + 1}</td>
                              <td>{cd.client_type  === 1 ?'Guest':'Mature'}</td>
                              <td>{cd.city_name}</td>
                              <td>{cd.package_name}</td>
                              <td>{cd.package_price}</td>
                              <td>{cd.disposition}</td>
                              <td>{cd.product_name}<br/>{cd.service_name}<br/>{cd.category_name}</td>
                              <td>{cd.activity_name}</td>
                              <td>{cd.remark}</td>
                              <td>{cd.next_followup_date}</td>
                              <td>{cd.created_by}</td>
                              <td>{cd.created_date}</td>
                          </tr>
                          ))
                        }
                      </table>
                  </div>
                }

                {active ==='Client Verifiy' &&
                <>
                  <div>
                  <form onSubmit={handleFormSubmit}>
                      <ProgressBar now={(step / 2) * 100} />
                      {step === 1 && (
                        <div className='card-body' id="clientDiv" style={{overflowY: "hidden"}}>
                        
                        <div className="row">
                          {/* <div className="col-sm-12"><h4>Client Basic Info</h4></div> */}
                          <div className="col-sm-12 " style={{ display: "flex", alignItems: "center" }}>
                              <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                              <strong style={{ margin: "0 10px" }}>Client Basic Details</strong>
                              <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                          </div>
                          <div className="col-sm-4">
                            <label for="client_name">Client Name</label>
                             <input type="text" value={clientName} className="form-control" onChange={(e) =>setClientName(e.target.value)}/>
                             {validationErrors.clientName && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientName}</div>
                             )}
                          </div>

                          

                          <div className="col-sm-4">
                            <label>Email</label>
                             <input type="text" className="form-control" value={clientEmail} onChange={(e) =>setClientEmail(e.target.value)} />
                             {validationErrors.clientEmail && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientEmail}</div>
                             )}
                          </div>
                          <div className="col-sm-4">
                            <label>Mobile No</label>
                             <input type="text" className="form-control" value={clientMobile} onChange={(e) =>setClientMobile(e.target.value)} readOnly/>
                             {validationErrors.clientMobile && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientMobile}</div>
                             )}
                          </div>
                          
                         

                          <div className="col-sm-4">
                            <label>State</label>
                            <select
                              className="form-control"
                              value={clientState}
                              onChange={handleStateChange}
                              //onChange={(e) =>setClientState(e.target.value)}
                            >
                              <option value="">Select State</option>
                              {stateList && stateList.map((sl) => (
                                <option key={sl.id} value={sl.id}>
                                  {sl.state_name}
                                </option>
                              ))}
                            </select>

                            {validationErrors.clientState && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientState}</div>
                             )}
                          </div>

                          <div className="col-sm-4">
                            <label>City</label>
                              <select
                                className="form-control"
                                value={clientCity} 
                                onChange={(e) =>setClientCity(e.target.value)}
                              >
                                <option value="">Select City</option>
                                {cityDetails && cityDetails.map((cd) => (
                                  <option key={cd.id} value={cd.id}>
                                    {cd.city_name}
                                  </option>
                                ))}
                              </select>
                             
                              {validationErrors.clientCity && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientCity}</div>
                             )}
                          </div>

                         

                          
                          {/* <div className="col-sm-4">
                            <label>Zipcode</label>
                            <input
                              type="text"
                              id="zipCode"
                              value={clientZipcode}
                              onChange={handleChange}
                              placeholder="Enter ZIP code"
                              className="form-control"
                            />
                            {validationErrors.clientZipcode && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientZipcode}</div>
                             )}
                          </div> */}

                          <div className="col-sm-4">
                            <label>Zipcode</label>
                            <input
                              type="text"
                              id="zipCode"
                              value={clientZipcode}
                              onChange={handleChange}
                              placeholder="Enter ZIP code"
                              className="form-control"
                              maxLength="6" // Ensures only 6 digits can be entered
                            />
                            {validationErrors.clientZipcode && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>
                                {validationErrors.clientZipcode}
                              </div>
                            )}
                          </div>
    
                          <div className="col-sm-4">
                            <label>Address</label>
                             <input type="text" className="form-control" value={clientAddress} onChange={(e) =>setClientAddress(e.target.value)}/>
                             {validationErrors.clientAddress && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientAddress}</div>
                             )}
                          </div>
    
                          {/* <div className="col-sm-4">
                            <label>Pan Card Number</label>
                             <input type="text" className="form-control" value={clientPanCardNo} onChange={(e) =>setPanCardNo(e.target.value)} onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}/>
                             {validationErrors.clientPanCardNo && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientPanCardNo}</div>
                             )}
                          </div> */}

                          <div className="col-sm-4">
                            <label>Pan Card Number</label>
                            <input 
                              type="text" 
                              className="form-control" 
                              value={clientPanCardNo} 
                              onChange={(e) => {
                                const value = e.target.value.toUpperCase();
                                setPanCardNo(value);
                                // Simple regex to check PAN card format
                                const panCardPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                                if (value.length !== 10 || !panCardPattern.test(value)) {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    clientPanCardNo: "Please enter a valid 10-character PAN card number (e.g., ABCDE1234F)"
                                  }));
                                } else {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    clientPanCardNo: ""
                                  }));
                                }
                              }}
                              maxLength="10" // This limits the input to 10 characters
                              onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                            />
                            {validationErrors.clientPanCardNo && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>
                                {validationErrors.clientPanCardNo}
                              </div>
                            )}
                          </div>


                          

                            <div className="col-sm-4 ">
                              <label>Do you have GST No?</label>
                              <div>
                              <span className='px-3' >
                                <input
                                style={{transform:"scale(1.5)"}}
                                  type="radio"
                                  id="yes"
                                  name="gst"
                                  value="Yes"
                                  checked={gstOption === "Yes"}
                                  onChange={(e) => setGstOption(e.target.value)}
                                />
                                <label className='pl-2' htmlFor="yes"> Yes</label>
                              </span>
                              <span  className='px-3' >
                                <input
                                style={{transform:"scale(1.5)"}}
                                  type="radio"
                                  id="no"
                                  name="gst"
                                  value="No"
                                  checked={gstOption === "No"}
                                  onChange={(e) => setGstOption(e.target.value)}
                                />
                                <label className='pl-2' htmlFor="no"> No</label>
                              </span>
                              </div>
                              {validationErrors.gstOption && (
                                <div className="text-danger" style={{ fontSize: "15px" }}>
                                  {validationErrors.gstOption}
                                </div>
                              )}
                            </div>

                            <div className=" p-2  my-2 col-12 px-2 ">
                              <label className='d-flex align-items-center p-2'>
                                <input 
                                style={{transform:"scale(1.5)"}}
                                  type="checkbox"
                                  checked={sameAsClientInfo}
                                  onChange={handleSameAsClientInfo}
                                />
                                <span className='pl-2 text-success'> Same as Client Basic Details</span>
                               
                              </label>
                            </div>
                          </div>
                          {gstOption &&
                          <div className="row">
                            <div className="col-sm-12 fw-bold" style={{ display: "flex", alignItems: "center" }}>
                              <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                              <strong style={{ margin: "0 10px" }}>Company Details</strong>
                              <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                            </div>

                          
                       

                          <div className="col-sm-4">
                            <label>Business Name</label>
                            <input type="text" className="form-control" value={businessName} onChange={(e) =>setBusinessName(e.target.value)} />
                            {validationErrors.businessName && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.businessName}</div>
                             )}
                          </div>

                          <div className="col-sm-4">
                            <label>Business Email</label>
                             <input type="text" className="form-control" value={businessEmail} onChange={(e) =>setBusinessEmail(e.target.value)} />
                             {validationErrors.businessEmail && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.businessEmail}</div>
                             )}
                          </div>
                          {/* <div className="col-sm-4">
                            <label>Business Mobile No</label>
                             <input type="text" className="form-control" value={businessMobile} onChange={(e) =>setBusinessMobile(e.target.value)}/>
                             {validationErrors.businessMobile && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.businessMobile}</div>
                             )}
                          </div> */}

                          <div className="col-sm-4">
                            <label>Business Mobile No</label>
                            <input
                              type="text"
                              className="form-control"
                              value={businessMobile}
                              onChange={(e) => {
                                const value = e.target.value;
                                // Only allow numeric input
                                if (/^\d*$/.test(value)) {
                                  setBusinessMobile(value);
                                }
                                // Validate if the mobile number has 10 digits
                                if (value.length !== 10) {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    businessMobile: "Mobile number must be exactly 10 digits",
                                  }));
                                } else {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    businessMobile: "",
                                  }));
                                }
                              }}
                              maxLength="10" // Limits the input to 10 characters
                            />
                            {validationErrors.businessMobile && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>
                                {validationErrors.businessMobile}
                              </div>
                            )}
                          </div>

                          {/* <div className="col-sm-4">
                            <label>Company Pan Card No</label>
                            <input type="text" className="form-control" value={companyPanCardNo} onChange={(e) =>setCompanyPanCardNo(e.target.value)} onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}/>
                            {validationErrors.companyPanCardNo && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.companyPanCardNo}</div>
                            )}
                          </div> */}

                          <div className="col-sm-4">
                            <label>Company Pan Card No</label>
                            <input 
                              type="text" 
                              className="form-control" 
                              value={companyPanCardNo} 
                              onChange={(e) => {
                                const value = e.target.value.toUpperCase();
                                setCompanyPanCardNo(value);
                                // PAN card validation pattern: 5 letters, 4 digits, 1 letter
                                const panCardPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                                if (value.length !== 10 || !panCardPattern.test(value)) {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    companyPanCardNo: "Please enter a valid 10-character PAN card number (e.g., ABCDE1234F)"
                                  }));
                                } else {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    companyPanCardNo: ""
                                  }));
                                }
                              }}
                              maxLength="10" 
                              onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                            />
                            {validationErrors.companyPanCardNo && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>
                                {validationErrors.companyPanCardNo}
                              </div>
                            )}
                          </div>
                            
                        
                          <div className="col-sm-4">
                              <label>Company GST State</label>
                              <select
                                className="form-control"
                                value={companyState}
                                onChange={handleStateChange}
                              >
                                <option value="">Select State</option>
                                {stateList && stateList.map((sl) => (
                                  <option key={sl.id} value={sl.id}>
                                    {sl.state_name}
                                  </option>
                                ))}
                              </select>

                              {validationErrors.companyState && (
                                <div className="text-danger" style={{ fontSize: "15px" }}>
                                  {validationErrors.companyState}
                                </div>
                              )}
                            </div>

                            {/* {gstOption === "Yes" && (
                              <div className="col-sm-4">
                                <label>GST State Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={gstStateCode}
                                  readOnly
                                />

                                {validationErrors.gstStateCode && (
                                  <div className="text-danger" style={{ fontSize: "15px" }}>
                                    {validationErrors.gstStateCode}
                                  </div>
                                )}

                              </div>
                            )} */}

                            {gstOption === "Yes" && (
                              <div className="col-sm-4">
                                <label>GST State Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={gstStateCode}
                                  readOnly
                                />

                                {validationErrors.gstStateCode && (
                                  <div className="text-danger" style={{ fontSize: "15px" }}>
                                    {validationErrors.gstStateCode}
                                  </div>
                                )}
                              </div>
                            )}
                          {gstOption =='Yes' &&
                          <div className="col-sm-4">
                            <label>GST Number</label>
                            <div style={{display:'flex'}}>
                             <input type="text" className="form-control" value={gstStateCode + companyPanCardNo} />
                             {/* <input type="text" className="form-control" value={gstNumber} onChange={(e)=>{setGstNumber(e.target.value)}} placeholder='Enter last 3 digit of gst'/> */}
                             <input
                                type="text"
                                className="form-control"
                                value={gstNumber}
                                onChange={handleGstNumberChange}
                                placeholder='Enter last 3 digits of GST'
                                maxLength="3" // Restricts input to 3 characters
                              />
                            </div>
                              {validationErrors.gstNumber && (
                                <div className="text-danger" style={{ fontSize: '15px' }}>
                                  {validationErrors.gstNumber}
                                </div>
                              )}
                          </div>
                        }

                          <div className="col-sm-4">
                            <label>Company City</label>
                              <select
                                className="form-control"
                                value={companyCity}
                                onChange={(e)=>{setCompanyCity(e.target.value)}}
                              >
                                <option value="">Select City</option>
                                {cityDetails && cityDetails.map((cd) => (
                                  <option key={cd.id} value={cd.id}>
                                    {cd.city_name}
                                  </option>
                                ))}
                              </select>
                             
                             {validationErrors.companyCity && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.companyCity}</div>
                             )}
                          </div>

                          
                          {/* <div className="col-sm-4">
                            <label>Company Zipcode</label>
                             <input type="text" className="form-control" value={companyZipcode} onChange={(e) =>setCompanyZipcode(e.target.value)}/>
                             {validationErrors.companyZipcode && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.companyZipcode}</div>
                             )}
                          </div> */}

                          <div className="col-sm-4">
                            <label>Company Zipcode</label>
                            <input
                              type="text"
                              className="form-control"
                              value={companyZipcode}
                              onChange={handleCompanyZipcode}
                              placeholder="Enter ZIP code"
                              maxLength="6" // Ensures only up to 6 digits can be entered
                            />
                            {validationErrors.companyZipcode && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>
                                {validationErrors.companyZipcode}
                              </div>
                            )}
                          </div>
    
                          <div className="col-sm-4">
                            <label>Company Address</label>
                            <input type="text" className="form-control" value={companyAddress} onChange={(e) =>setCompanyAddress(e.target.value)}/>
                              {validationErrors.clientAddress && (
                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientAddress}</div>
                              )}
                          </div>
    


                          
                          <div className="col-sm-4">
                            <label>Select Group</label>
                            <select className="form-control" value={selectedGroup} onChange={(e)=>{setSelectedGroup(e.target.value)}}>
                                <option value="">Select Group</option>
                                {groupList && groupList.map((group)=>(
                                  <option value={group.group_id}>{group.name}</option>
                                ))}
                            </select>
                            {validationErrors.selectedGroup && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedGroup}</div>
                            )}
                          </div>

                          <div className="col-sm-4">
                            <label>Organization Type</label>
                            <select className="form-control" value={orgTypeId} onChange={(e) =>setOrgTypeId(e.target.value)}>
                              <option value="">Select Organization Type</option>
                              {orgType && orgType.map((orgnaType)=>(
                                <option value={orgnaType.id}>{orgnaType.org_type}</option>
                              ))
                              }
                              
                            </select>
                            {validationErrors.orgTypeId && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.orgTypeId}</div>
                             )}
                          </div>

                            <div className="col-sm-4">
                                <label>Salect Product</label>
                                <select className="form-control" value={selectedProduct}
                                      onChange={(e) => setSelectedProduct(e.target.value)}>
                                      <option value=''>Select Product</option>
                                      {product.map((pro) => (
                                      <option key={pro.id} value={pro.id}>
                                      {pro.product_name}
                                      </option>
                                      ))}
                                </select>
                                {validationErrors.selectedProduct && (
                                  <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                                )}
                              </div>

                              {selectedProduct &&(
                                <div className="col-sm-4">
                                    <label>Select Service</label>
                                    <select className="form-control"
                                            value={selectedService}
                                            onChange={(e) => setSelectedService(e.target.value)}
                                            >
                                            <option value=''>Select Service</option>
                                            {service.map((ser) => (
                                            <option key={ser.id} value={ser.id}>
                                            {ser.service_name}
                                            </option>
                                            ))}
                                    </select>
                                    {validationErrors.selectedService && (
                                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                                    )}
                                </div>
                                )}
                            </div>
                          }
                      </div>
                      )}

                      {step === 2 && (
                          <div className='card-body' id="clientDiv" style={{overflowY: "hidden"}}>
                              <div className="col-sm-12"><h4>Client Document</h4></div>
                              <div className="row">

                                  <div className="col-sm-3 col-12">
                                    <select className="form-control" value={selectedDocumentType} onChange={(e) => setSelectedDocumentType(e.target.value)} >
                                      <option value="">Select Document </option>
                                      {documentType && documentType.map((docType)=>(
                                        <option value= {docType.id}> {docType.document_name}</option>
                                      ))}
                                    </select>
                                    {validationErrors.selectedDocumentType && (
                                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedDocumentType}</div>
                                    )}
                                  </div>

                                  <div className="col-sm-3 col-12">
                                    <input type="text" className="form-control" value={selectedDocumentNo} placeholder='Enter Document No'  onChange={(e) => setSelectedDocument(e.target.value)} onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}/>
                                    {validationErrors.selectedDocumentNo && (
                                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedDocumentNo}</div>
                                    )}
                                  </div>

                                  <div className="col-sm-3 col-12">

                                    <input className="form-control" type="file" multiple onChange={handleFileChange} />

                                    {validationErrors.documentFile && (
                                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.documentFile}</div>
                                    )}
                                  </div>

                                  <div className="col-sm-3 col-12">
                                    <span className="btn-primary btn-sm" onClick={uplodeDocument}>
                                      Upload!
                                    </span>
                                  </div>
                              </div>

                              <div className="row" style={{marginTop:'30px'}}>
                                
                                  {clientDocDetails && clientDocDetails.map((docDetails)=>(
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>{docDetails.document_name} ({docDetails.doc_number})</h5>
                                        </div>

                                        {docDetails.doc_url && docDetails.doc_url.split(',').map((item,index) => 
                                          <div className="col-sm-3" style={{margin:'10px'}}>
                                            <iframe src={docurl() + item} title="company policy" width="200px" height="200px"></iframe>
                                          </div>
                                        )}
                                      </div>
                                  ))}
                                
                              </div>
                                  
                            </div>
                        )}
                      
                      <div className='card-body' id="clientDiv" style={{overflowY: "hidden"}}>
                        <div className="d-flex justify-content-between">
                          {step > 1 && (
                            <Button variant="secondary" onClick={handlePrevious}>
                              Previous
                            </Button>
                          )}

                          {step < 2 ? (
                            <Link  className="btn btn-primary" onClick={handleNext}>
                              Next
                            </Link>
                          ) : (
                            <>
                            {documentType && documentType.length === clientDocDetails.length &&
                              
                              <Link className ="btn btn-primary" type="button" onClick={handleFormSubmit}>
                                Submit
                              </Link>
                            }
                            </>
                          )}
                        </div>
                      </div>
                      </form>
                    </div>
                </>
                }

                {active ==='Company Details' &&
                  <>
                    <div className='card-body' id="clientDetails" style={{overflowY: "hidden"}}>
                      <table className="table table-bordered table-hovered">
                        <tr>
                          <td>S.No</td>
                          <td>Client Name</td>
                          <td>Business Name</td>
                          <td>Email</td>
                          <td>Mobile No</td>
                          <td>City</td>
                          <td>State</td>
                          <td>Zipcode</td>
                          <td>Address</td>
                          <td>Organization Type</td>
                          <td>Executive Id</td>
                          <td>Created date</td>
                        </tr>

                        {matureClientDetails && matureClientDetails.map((mcd,index)=>(
                          <tr>
                              <td>{index + 1}</td>
                              <td>{mcd.client_name}</td>
                              <td>{mcd.business_name}</td>
                              <td>{mcd.email}</td>
                              <td>{mcd.mobile_no}</td>
                              <td>{mcd.city}</td>
                              <td>{mcd.state}</td>
                              <td>{mcd.pin_no}</td>
                              <td>{mcd.address}</td>
                              <td>{mcd.pan_card}</td>
                              <td>{mcd.exe_id}</td>
                              <td>{mcd.created_at}</td>
                              
                          </tr>
                          ))
                        }
                      </table>
                    </div>
                  </>
                }

                
              {active ==='Services' &&
                <>
                    <div>
                        <div className='card-body' style={{overflowY: "hidden"}}>
                            <div className="row">

                              <div className="col-sm-3">
                                <select value={prePackageId} onChange={(e)=>{setPrePackageId(e.target.value)}} className="form-control">
                                  <option value=''>Select Category</option>
                                  {packageCategory && packageCategory.map((pc)=>( 
                                    <option value= {pc.id}> {pc.name}</option>
                                  ))} 
                                </select>
                              </div>

                              <div class="col-sm-3">
                                  <select className="form-control"
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.target.value)}
                                    >
                                    <option value=''>Select City (Group)</option>
                                    {cityList.map((city) => (
                                    <option key={city.group_id} value={city.group_id}>
                                    {city.name}
                                    </option>
                                    ))}
                                </select>
                              </div>

                              <div className="col-sm-3">
                                <select className="form-control" value={selectedProduct}
                                      onChange={(e) => setSelectedProduct(e.target.value)}>
                                      <option value=''>Select Product</option>
                                      {product.map((pro) => (
                                      <option key={pro.id} value={pro.id}>
                                      {pro.product_name}
                                      </option>
                                      ))}
                                </select>
                              </div>
                              
                              <div className="col-sm-3">
                                <select className="form-control"
                                    value={selectedService}
                                    onChange={(e) => setSelectedService(e.target.value)}
                                    >
                                    <option value=''>Select Service</option>
                                    {service.map((ser) => (
                                    <option key={ser.id} value={ser.id}>
                                    {ser.service_name}
                                    </option>
                                    ))}
                                </select>
                            </div>
                            </div>
                            <div className="d-flex flex-wrap">
                           
                             
                                  <>
                                 
                                  {prePackages && prePackages.map((pp)=>(
                                    <>
                                    <div className="col-sm-4 col-12 px-sm-2 px-0 py-2">
                                      <div className='border p-2 rounded'>
                                        <div style={{padding: '10px 20px',backgroundColor: 'rgb(29 95 163)',fontWeight: '600',textAlign: 'center',color:'white'}}> 
                                           {pp.package_name}
                                        </div>

                                        <div style={{textAlign:'center',fontWeight: '600',fontSize:'15px'}}>
                                          {pp.category_name_list && pp.category_name_list.split(',').join(" + ")}
                                        </div>

                                        <div> 
                                            <h5>&nbsp;&nbsp;
                                            {pp.duration_name} ( {pp.package_type_name} {pp.total_lead} Lead)
                                            </h5>
                                        </div>

                                        <div>
                                           
                                        </div>
                                        <div>
                                        
                                        <table style={{width:'100%'}}>
                                          {pp.assign_cat.map((cat)=>(
                                            <tr>
                                              <td> &nbsp;&nbsp;✔ &nbsp; {cat.name}</td>
                                              <td style={{float:'rigth'}}>{cat.type_status===1?'✅':"❌"}
                                              &nbsp;&nbsp;
                                              </td>
                                            </tr>
                                          ))}
                                          </table>	
                                        </div>

                                        <div>
                                          <h6>
                                            &nbsp;&nbsp; Is Partial Payment : {pp.is_partial_payment==1 ? 'Yes':'No'}
                                          </h6>
                                        </div>
                                        
                                        <div>
                                          <h5> &nbsp;&nbsp;Package Price : {pp.package_price}</h5>
                                        </div>

                                        <div className='d-flex justify-content-end' >
                                         {loading && selectedProposalId == pp.id? (
                                              <p style={{align:'center'}}>
                                              <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                                              </p>
                                          ) : (
                                          <button className="btn btn-primary btn-sm d-flex align-items-center justify-content-center" style={{float:'right',margin:'10px',width:"40px",height:"40px"}} onClick={() => sentPurposal(pp.id)} >
                                            <span class="material-icons-outlined" alt="" >share</span>
                                           </button> 
                                        )}
                                        </div>
                                      </div>
                                    </div>
                                    </>
                                  ))}
                                  </>
                          </div>
                        </div>
                    </div>
                </>
                }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={follwoupModal} fullWidth PaperProps={{ style: {padding: '20px' } }}>
        <DialogTitle>Client Followup</DialogTitle>
          <DialogContent style={{height:"450px"}}>
            <form>
                <div className="row" style={{overflowY: "hidden"}}>

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Followup Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div class="col-sm-6">
                    <label>Select City (Group)</label>
                    <select className="form-control"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        >
                        <option value=''>Select City</option>
                        {cityList.map((city) => (
                        <option key={city.group_id} value={city.group_id}>
                        {city.name}
                        </option>
                        ))}
                    </select>
                  
                  </div>

                  <div className="col-sm-6">
                    <label>Salect Product</label>
                    <select className="form-control" value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}>
                          <option value=''>Select Product</option>
                          {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                    </select>
                    
                  </div>
                  {selectedProduct &&(
                  <div className="col-sm-6">
                      <label>Select Service</label>
                      <select className="form-control"
                              value={selectedService}
                              onChange={(e) => setSelectedService(e.target.value)}
                              >
                              <option value=''>Select Service</option>
                              {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                              {ser.service_name}
                              </option>
                              ))}
                      </select>
                    
                  </div>
                  
                  )}

                  {category != '' && selectedService &&(
                  <div className="col-sm-6">
                      <label>Select Category</label>
                      <select className="form-control"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          >
                          <option value=''>Select Category</option>
                          {category.map((cate) => (
                          <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                          </option>
                          ))}
                      </select>
                      
                  </div>
                )}




                  <div className="col-sm-6">
                      <label>Call Status</label>
                      <select className="form-control"
                          value={selectedFollowCallStatus}
                          onChange={(e) => setFollCallStatus(e.target.value)}
                          >
                          <option value=''>Select Call Status</option>
                          <option value='Connected'>Connected</option>
                          <option value='Not_Connected'>Not Connected</option>
                      </select>
                     
                  </div>
            
                  {selectedFollowCallStatus &&(
                    <div className="col-sm-6">
                        <label>Followup Status</label>
                        <select className="form-control"
                          value={selectedFollowStatus}
                          onChange={(e) => setFollStatus(e.target.value)}
                          >
                          <option value=''>Select Followup Status</option>
                          {followup.map((follo) => (
                          <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                          </option>
                          ))}
                        </select>
                        
                    </div>
                  )}

                  {selectedCity && (selectedFollowStatus ==5 || selectedFollowStatus ==6 || selectedFollowStatus ==7 || selectedFollowStatus ==9) &&(
                    <div className="col-sm-12">
                        <label>Select Package</label>
                        <select className="form-control"
                          value={selectedPackage}
                          onChange={(e) => setSelectedPackage(e.target.value)}
                          >
                          <option value=''>Select Package Details</option>
                          {prePackageList.map((prePkg) => (
                          <option key={prePkg.id} value={prePkg.id}>
                          {prePkg.package_name} ({prePkg.package_price})
                          </option>
                          ))}
                        </select>
                        {validationErrors.selectedPackage && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPackage}</div>
                        )}
                    </div>
                    // <div className="col-sm-12">
                    //     <label>Select Package</label>

                        
                    //     <input
                    //       type="text"
                    //       className="form-control mb-2"
                    //       placeholder="Search Package"
                    //       value={searchTerm}
                    //       onFocus={() => setShowDropdown(true)}
                    //       onChange={(e) => setSearchTerm(e.target.value)} 
                    //     />

                        
                    //     {showDropdown && (
                    //       <div className="custom-dropdown">
                    //         <ul className="list-group" style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                    //           {prePackageList
                    //             .filter((prePkg) =>
                    //               prePkg.package_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    //               prePkg.package_price.toString().includes(searchTerm)
                    //             )
                    //             .map((prePkg) => (
                    //               <li
                    //                 key={prePkg.id}
                    //                 className="list-group-item"
                    //                 onClick={() => {
                    //                   setSelectedPackage(prePkg.id); 
                    //                   setShowDropdown(false);
                    //                   setSearchTerm(prePkg.package_name); 
                    //                 }}
                    //               >
                    //                 {prePkg.package_name} ({prePkg.package_price})
                    //               </li>
                    //             ))}
                    //         </ul>
                    //       </div>
                    //     )}

                      
                    //     <input
                    //       type="hidden"
                    //       value={selectedPackage}
                    //     />

                        
                    //     {validationErrors.selectedPackage && (
                    //       <div className="text-danger" style={{ fontSize: '15px' }}>
                    //         {validationErrors.selectedPackage}
                    //       </div>
                    //     )}
                    //   </div>
                  )}
            
                
                  <div className="col-sm-6">
                      <label>Next Followup Date</label><br />
                      
                      <DatePicker className="form-control" style={{position: 'absolute'}}
                        minDate={new Date()}
                        maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                        selected={nxtFollowupDate}
                        onChange={(date) => setNxtFollowupDate(date)}
                        dateFormat="dd/MM/YYYY"
                        onKeyDown={(e) => {
                          e.preventDefault();
                      }}
                      />
                      {validationErrors.nxtFollowupDate && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.nxtFollowupDate}</div>
                      )}
                  </div>
               

                {/* {selectedFollowStatus == 19 &&(
                  <div className="col-sm-12">
                  <label> Package Details</label>
                  <table className="table table-bordered table-hovered">
                  
                    <tr>
                      <td>Wallet Balance Amount</td>
                      <td colspan="">{walletDetails && walletDetails.balance_amount}</td>
                      <td>Total Lead</td>
                      <td colspan="3">{salePackageInfo && salePackageInfo.total_lead}</td>
                    </tr>

                    <tr>
                      <td><b>Particulars</b></td>
                      <td><b>Amount</b></td>
                      <td><b>Tax { selectedGstPercent} % </b></td>
                      <td><b>Total</b></td>
                    </tr>

                    <tr>
                      <td>Package Amount</td>
                      <td>{salePackageInfo && salePackageInfo.package_price}</td>
                      <td>{salePackageInfo && salePackageInfo.package_price * selectedGstPercent /100}</td>
                      <td>{parseInt((salePackageInfo && salePackageInfo.package_price * selectedGstPercent /100)) + parseInt(salePackageInfo && salePackageInfo.package_price) } </td>
                    </tr>

                    <tr>
                      <td>Reg Amount</td>
                      <td>{selectedRegAmount}</td>
                      <td>{selectedRegAmount * selectedGstPercent /100}</td>
                      <td>{parseInt((selectedRegAmount * selectedGstPercent /100)) + parseInt(selectedRegAmount) } </td>
                    </tr>

                    <tr>
                      <td>Total Amount</td>
                      <td>{parseInt(salePackageInfo && salePackageInfo.package_price) + parseInt(selectedRegAmount)}</td>

                      <td>{parseInt(salePackageInfo && salePackageInfo.package_price * selectedGstPercent /100) + parseInt((selectedRegAmount * selectedGstPercent /100)) }</td>

                      <td colspan="1">{(parseInt(selectedRegAmount * selectedGstPercent /100) + parseInt(selectedRegAmount)) + parseInt((salePackageInfo && salePackageInfo.package_price * selectedGstPercent /100)) + parseInt((salePackageInfo && salePackageInfo.package_price)) }
                      </td>

                    </tr>



                    {parseInt(walletDetails && walletDetails.balance_amount) <= parseInt(salePackageInfo.package_price) + parseInt(selectedRegAmount) &&
                    <tr>
                      <td colspan="4">
                        <span style={{color:'red',fontSize:'13px'}}>
                          You don't have sufficient balance to buy this package
                        </span>
                      </td>
                    </tr>
                    }

                    {walletDetails && walletDetails.balance_amount >= salePackageInfo.package_price &&
                    <tr>
                      <td>
                        Deposit Amount
                      </td>
                      <td>
                        <input type="text" className="form-control" style={{width:'150px'}}  value={deposit_amount} onChange={(e)=>setDepositAmountByClient(e.target.value)} />
                      </td>
                    </tr>
                    }
                  </table>
                  </div>
                )} */}
              
                {selectedFollowStatus  &&(
                  <div className="col-sm-12">
                    <label>Remarks</label>
                    <TextField type="text" className="form-control" value={followupRemarks} onChange={(e) => setFollowupRemarks(e.target.value)}></TextField> 
                  </div>
                )}
                </div>
            </form>
          </DialogContent>

          <DialogActions>
              <Link className="btn btn-primary btn-sm" onClick={closeFollowupControl}>Close</Link>

              <Link className="btn btn-success btn-sm" onClick={submitFollowupForm}>Create Followup</Link>

              {/* {selectedFollowStatus && selectedFollowStatus != 19 &&(
              <Link className="btn btn-success btn-sm" onClick={submitFollowupForm}>Create Followup</Link>
              )} */}
              {/* {selectedFollowStatus && selectedFollowStatus == 19 &&(
              <Link className="btn btn-success btn-sm" onClick={()=> submitPackageForm()}>Create Package</Link>
              )} */}
          </DialogActions>
      </Dialog>

      <Dialog open={packageby} fullWidth PaperProps={{ style: {padding: '20px' } }}>
          <DialogTitle>Package Details</DialogTitle>
          <DialogContent style={{height:"auto"}}>
            Package Category : {packagedetails.category_name} ({packagedetails.service_name}) <br/>
            Package Name : {packagedetails.package_name} <br/>
            Package Duration : {packagedetails.duration_name} <br/>
            Total Lead : {packagedetails.total_lead}<br/>
            Price : {packagedetails.price}<br/>
            Deposit Amount: <input type="text" className="form-control" value={depositAmount} onChange={(e)=>setDepositAmount(e.target.value)}/><br/>
           
          </DialogContent>
          <DialogActions>
            <Link className="btn btn-info btn-sm" onClick={closePackageModel}>Close</Link>
            <Link className="btn btn-primary btn-sm"   onClick={() => submitPackageForm(packagedetails.package_id)}>Package Sale</Link>
          </DialogActions>
      </Dialog>
      </DashboardLayout>
    )
}

export default ClientProfile;
