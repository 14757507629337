import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CsDetailsHeader from 'layouts/common_components/cs_details_header';

import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";

function Return_leads() 
{
  const navigate = useNavigate();
  const emp_id = localStorage.getItem('emp_id');
  const token = localStorage.getItem('token');
  const department = localStorage.getItem('department');
  const [loading, setLoading] = useState(true);
  const [callConnecting, setCallLoading] = useState(false);
  const [returnEnq,setReturnEnq] = useState([]);
  const [returnLead,setReturnModal] = useState(false);
  const [returnLeadId,setReturnLeadId]=useState('');
  const [returnLeadsInfo,setReturnLeadInfo]=useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [remarks,setRemarks] = useState();
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [callingMobileNo,setCallingMobileNumber] = useState('');

  const [isFakeLead,setIsFakeLead]=useState('');
  const [returnStatus,setReturnStatus]=useState('');
  const [returnComment,setReturnComment]=useState('');
  const [checkedItems, setCheckedItems] = useState([]);

  const [selectedStatus,setSelectedStatus] = useState('');
  const [selectedComment,setSelectedComment] = useState('');

  const [viewPackageModal,setViewPackageLeadModal] = useState(false);
  const [viewPackageId,setViewPackageId] = useState('');
  const [leadInfo,setPackageLeadInfo] = useState();
  


    
    const onReturnLeadModal = (id)=>
    {
      setReturnLeadId(id);
      setReturnModal(true);
    }

    const ofReturnLeadModal = ()=>
    {
      setReturnModal(false);
    }

    

    const handleCheckboxChange = (leadId) => {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.includes(leadId)
          ? prevCheckedItems.filter(id => id !== leadId)
          : [...prevCheckedItems, leadId]
      );
    };

  
    useEffect(() => {
      getReturnEnquiry();

    }, [currentPage]);

    const handleRadioChange = (e) => {
      setIsFakeLead(e.target.value);
    };

    const getReturnEnquiry = async () => 
    {
      
      try{
        const response = await dashboardApi.get(`get-return-lead`,{
          params:{
            emp_id:emp_id,
            per_page: postPerPage,
            page: currentPage + 1,
          }
          
        });
        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const customerData = responseData.data || responseData;
          setNumber(responseData.last_page);
          setReturnEnq(customerData);
          setLoading(false);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
          setLoading(false);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

    useEffect(() =>
    {
      if(returnLeadId)
      {
        const getReturnLeadById = async () => 
        {
          try
          {
            const response = await dashboardApi.get(`get-return-lead-by-return-id`,{
              params:{
                emp_id:emp_id,
                return_id:returnLeadId,
              }
            });
            if(response.data.status === 200)
            {
              setReturnLeadInfo(response.data.data);
              setLoading(false);
            }
            else
            {
              console.error('Error fetching countries:', response.data.message);
              setLoading(false);
            }
          }
          catch (error)
          {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        getReturnLeadById();
      }
    },[returnLeadId]);

    useEffect(() =>
    {
        if(viewPackageId)
        {
          const getReturnLeadById = async () => 
          {
            try
            {
              const response = await dashboardApi.get(`view-package-lead-details`,{
                params:{
                  emp_id:emp_id,
                  viewPackageId:viewPackageId,
                }
              });
              if(response.data.status === 200)
              {
                setPackageLeadInfo(response.data.data);
                setLoading(false);
              }
              else
              {
                console.error('Error fetching countries:', response.data.message);
                setLoading(false);
              }
            }
            catch (error)
            {
              console.error('Error fetching countries:', error);
              setLoading(false);
            }
          }
          getReturnLeadById();
        }
    },[viewPackageId]);

    

    useEffect(() =>
    {
      if(isFakeLead)
      {
        const getReturnStatus = async()=>
        { 
          try
          {
            const response = await dashboardApi.get(`get-return-lead-status`,{
              params:{
                emp_id:emp_id,
                is_fake_lead:isFakeLead,
              }
            });
            if(response.data.status === 200)
            {
              setReturnStatus(response.data.reason_status);
              setReturnComment(response.data.comment_status)
              setLoading(false);
            }
            else
            {
              console.error('Error fetching countries:', response.data.message);
              setLoading(false);
            }
          }
          catch (error)
          {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        getReturnStatus();
      }
      
    },[isFakeLead])
    
    const makeCallToCustomer = async (mobile_no) => {
      setCallingMobileNumber(mobile_no);
      const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
      const form = new FormData();
      form.append("emp_id",emp_id);
      form.append("mobile_number",mobile_no);
      form.append("agent_mobile_number",mobile_no);
      form.append("api_id", "1");
      form.append("secure_key", "d53e5ccd3d0ab16e6c29103beb806bc1");
      try {
        setCallLoading(true);
        
        const response = await dashboardApi.post('dialer/make-a-call', form);
        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const customerData = responseData.data || responseData;
          setCallLoading(false);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
          setCallLoading(false);
        }
      } catch (error) {
        console.error('Error sending POST request', error);
        setCallLoading(false);
      }
    };
      
    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };

    const submitReturnForm = async()=>{
      const errors = {};
      if(checkedItems.length < 1)
      {
        errors.checkedItems = 'Atleast 1 client checked is required.';
      }

      if(!isFakeLead)
      {
        errors.isFakeLead = 'Is Facked Checked is Required.';
      }

      if(!selectedStatus)
      {
        errors.selectedStatus = 'Status is Required.';
      }

      if(!selectedComment)
      {
        errors.selectedComment = 'Reason is Required.';
      }

      if(!remarks)
      {
        errors.remarks = 'Remarks is Required.';
      }
      setValidationErrors(errors);
      if (Object.keys(errors).length > 0)
      {
        return; 
      }

    const formData = new FormData();
    formData.append('lead_id',checkedItems);
    formData.append('is_fake_lead',isFakeLead);
    formData.append('remarks',remarks);
    formData.append('returnComment',selectedComment);
    formData.append('returnStatus',selectedStatus);
    formData.append('created_by',emp_id);
   
      try {
        const response = await dashboardApi.post('update-return-lead-status', formData);

        if (response.data.status === 200) {
          getReturnEnquiry();
          setReturnModal(false);
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
          
          

        } else {
          setReturnModal(false);
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        setReturnModal(false);
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while creating the product',
        });
      } 
    }

    const viewPackageLeadDetails =(pkgId)=>{
      setViewPackageLeadModal(true);
      setViewPackageId(pkgId);
    }

    return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <CsDetailsHeader></CsDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                        <Link className='btn btn-info' target="_blank" to="/add-enquiry">
                            +
                        </Link>
                      </div>
                  </div>
                  {loading ? (
                  <p style={{align:'center'}}>
                  <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                  </p>
                  ) : (
                  <div style={{overflowY: "hidden"}}>
             
                  <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
             
                    <tr>
                      <td>S.No</td>
                      <td>Customer Info</td>
                      <td>From/To city</td>
                      <td>Service/Category</td>
                      <td>Date</td>
                      <td>Verified By</td>
                     
                      <td>Action</td>
                    </tr>
                    {returnEnq && returnEnq.map((enq,index)=>(
                      <tr>
                        <td>{index + 1}</td>
                        <td>{enq.customer_name} <br/>{enq.customer_mobile}<br/>{enq.customer_email}</td>
                        <td>{enq.city_from} - {enq.city_to}</td>
                        <td>{enq.product_name}<br/>{enq.service_name} ({enq.category_name})</td>
                        <td>Recived: {enq.recived_date} <br/> Event: {enq.event_date} <br/> Return</td>
                        <td>Otp : {enq.otp_verified==1?'Yes':'No'}<br/> CS: {enq.cs_verified==1?'Yes':'No'}</td>
                        <td className='d-flex'>
                              
                            {callConnecting && callingMobileNo == enq.customer_mobile ?(
                              <p style={{align:'center'}}>
                              <img className="btn btn-primary btn-sm mx-1" src={`${process.env.PUBLIC_URL}/callConnecting.gif`} style=   {{height:'40px'}}></img>
                                </p>
                            ):(
                              <>
                                <span class="material-icons-outlined btn btn-primary btn-sm mx-1" onClick={()=>   {makeCallToCustomer(enq.customer_mobile)}}>
                                  call
                                </span>
                              </>
                            )}
                            
                            <span class="material-icons-outlined btn btn-info btn-sm mx-1" onClick={()=>{onReturnLeadModal(enq.return_id)}}>
                              edit_note
                            </span>
                              
                        </td>
                      </tr>
                    ))
                    }
                    <tr>
                      <td colspan="11">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={number}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link-style"}
                          breakLinkClassName={"break-link-style"}
                          previousLinkClassName={"prev-link-style"}
                          nextLinkClassName={"next-link-style"}
                        />
                      </Pagination>
                    </td>
                  </tr>
                  </table>
                </div>
              )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={viewPackageModal} fullWidth PaperProps={{ style: { maxWidth: '40%', padding: '20px' } }}>
        <DialogTitle>Package Lead</DialogTitle>
        <DialogContent style={{ height: "auto" }}>
          <form>
            <div className="row">
              <div className="form-control">
                <table>
                    <tr>
                      <td>Package Name</td>
                      <td>Total Lead</td>
                      <td>Sent Lead</td>
                      <td>Return Lead</td>
                      <td>Approve Lead</td>
                      <td>Not Approve Lead</td>
                      <td>Approved %</td>
                    </tr>

                    {returnLeadsInfo && returnLeadsInfo.map((rli)=>(
                      <tr>
                        <td>Package Name</td>
                        <td>Total Lead</td>
                        <td>Sent Lead</td>
                        <td>Return Lead</td>
                        <td>Approve Lead</td>
                        <td>Not Approve Lead</td>
                        <td>Approved %</td>
                      </tr>
                    ))}

                </table>
              </div>
             
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={ofReturnLeadModal}>Close</Link>
          <Link className="btn btn-success btn-sm" onClick={submitReturnForm}>Update</Link>
        </DialogActions>
      </Dialog>

      <Dialog open={returnLead} fullWidth PaperProps={{ style: { maxWidth: '90%', padding: '20px' } }}>
        <DialogTitle>Return Lead Information</DialogTitle>
        <DialogContent style={{ height: "auto" }}>
          <form>
            <div className="row">
              {returnLeadsInfo && returnLeadsInfo.map((rli)=>(
                <>
                  <div className="col-12 p-2 border rounded d-flex flex-wrap">
                    <div className="col-sm-6">
                      <h5>Customer Info</h5>
                      Customer Name: {rli.customer_name}<br/>
                      Mobile No:{rli.customer_mobile}<br />
                      Email Id:{rli.customer_email}<br />
                    </div>
                    <div className="col-sm-6">
                      <h5>Enquiry Info</h5>
                      Address From: {rli.address_from} ({rli.city_from})<br/>
                      Address To: {rli.address_to} ({rli.city_to})<br />
                      Recived Date:{rli.recived_date}<br />
                      Return Date:{rli.return_date}<br />
                      Verified: Otp - {rli.otp_verified==1?'Yes':'No'} CS - {rli.cs_verified==1?'Yes':'No'}<br />
                    </div>
                  </div>

                  <div className="col-12 p-2 border rounded d-flex flex-wrap">
                    <div className="col-sm-12">
                      <h5>Sent To</h5>
                      {rli.client_info.map((sentTo,index)=>(
                        <tr className="p-2 border rounded">
                          <td>{index + 1} <input type="checkbox" checked style={{backgroundColor:'green'}} /> </td>
                          <td style={{color:'red'}}>&nbsp;&nbsp;{sentTo.business_name}</td>
                        </tr>
                      ))}
                    </div>
                    <div className="col-sm-12 p-2 border rounded d-flex flex-column">
                      <h5 >Return By</h5>
                      {rli.return_info.map((returnBy, index) => (
                        <tr key={index} className="p-2 border rounded">
                          <td>
                            <input
                              type="checkbox"
                              value={returnBy.lead_id}
                              onChange={() => handleCheckboxChange(returnBy.lead_id)}
                              checked={checkedItems.includes(returnBy.lead_id)}
                            />
                          </td>
                          <td style={{ color: 'red' }}>
                            &nbsp;&nbsp;{returnBy.business_name}
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;
                            <b>Status :</b> {returnBy.title},
                            &nbsp;&nbsp; <b>Remarks :</b> {returnBy.client_remarks}
                          </td>
                          <td>
                            <button onClick={()=>{viewPackageLeadDetails(rli.package_id)}}>View Lead Details</button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colspan="4"> 
                          {validationErrors.checkedItems && (
                            <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.checkedItems}</div>
                          )}
                        </td>
                      </tr>
                    </div>

                    <div className="col-sm-12 p-2 border rounded d-flex flex-wrap">
                      <div className="col-sm-4">
                        <label>Is Fake Lead</label><br/>
                        <input
                            type="radio"
                            value="yes"
                            checked={isFakeLead === 'yes'}
                            onChange={handleRadioChange}
                          /> Yes &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            value="no"
                            checked={isFakeLead === 'no'}
                            onChange={handleRadioChange}
                          /> No
                          {validationErrors.isFakeLead && (
                            <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.isFakeLead}</div>
                          )}
                      </div>

                      <div className="col-sm-4">
                        <label>Status</label><br/>
                        <select className="form-control" value={selectedStatus} onChange={(e)=>{setSelectedStatus(e.target.value)}}>
                          <option value="">Select Status</option>
                          {returnStatus && returnStatus.map((rs)=>(
                            <option value={rs.reason_id}>{rs.reason_title}</option>
                          ))}
                        </select>
                        {validationErrors.selectedStatus && (
                            <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedStatus}</div>
                          )}
                      </div>

                      <div className="col-sm-4">
                        <label>Reason</label><br/>
                        <select className="form-control" value={selectedComment} onChange={(e)=>{setSelectedComment(e.target.value)}}>
                          <option value="">Select Status</option>
                          {returnComment && returnComment.map((rc)=>(
                            <option value={rc.comment_id}>{rc.comment_title}</option>
                          ))}
                        </select>
                          {validationErrors.selectedStatus && (
                            <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedStatus}</div>
                          )}
                      </div>

                      <div className="col-sm-4">
                        <label>Remarks</label><br/>
                        <input type="text" className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}/>
                          {validationErrors.remarks && (
                            <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.remarks}</div>
                          )}
                      </div>
                    </div>
                  </div>
                </>


              ))}
             
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <Link className="btn btn-primary btn-sm" onClick={ofReturnLeadModal}>Close</Link>
          <Link className="btn btn-success btn-sm" onClick={submitReturnForm}>Update</Link>
        </DialogActions>
      </Dialog>

    </DashboardLayout>
  );
}
export default Return_leads;
