import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function CalanderLeave() {
  const navigate = useNavigate();
  const [calanderleave, getcalanderleave] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = secureLocalStorage.getItem("token");
  const branch = secureLocalStorage.getItem("branch");

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `current-year-leave/${branch}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          getcalanderleave(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Card>
      <MDBox display="flex" flexDirection="column" p={3}>
        <span class="material-icons">holiday_village</span>
        <ul style = {{listStyleType:'none'}}>
          {calanderleave.map((calander, index) => (
            <li key={calander.id} >
              <div>{calander.date}</div>
              <div>
                <span style={{ color: 'green' }}>{calander.name}</span><span style={{ float: 'right' }}>{calander.diff}</span>
              </div>
              {index < calanderleave.length - 1 && <hr />}
            </li>
          ))}
        </ul>
      </MDBox>
    </Card>
  );
}

export default CalanderLeave;
