import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import './filter.css';

function LeadTargert() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [targetList, settargetlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [loading,setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [grouplist,setgrouplist] = useState([]);
  const [group,setgroup] = useState('');
  const [categorylist,setcategorylist] = useState([]);
  const [category,setcategory] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [attribute,setattribute] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [financial_year,set_financialyear] = useState('');
  const [postPerPage] = useState(5);
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false); 
  const [attributefilter,setattributefilter] = useState([]);
  const [valuefilter,setvaluefilter] = useState({});
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [showGroupDropdown, setShowGroupDropdown] = useState(false);
  const [groupfilter,setgroupfilter] = useState([]);
  const [groupvaluefilter,setgroupvaluefilter] = useState({});
  const [goalattribute,setgoalattribute] = useState([]);
  const [goal,setselectedgoal] = useState('');
  const [goalsubattribute,setgoalsubattribute] = useState([]);
  const [selectedgoalsubattribute,setselectedgoalsubattribute] = useState('');
  const [goalsubsubattribute,setgoalsubsubattribute] = useState([]);
  const [selectedgoalsubsubattribute,setselectedgoalsubsubattribute] = useState('');
  const [child_attribute,setchildattribute] = useState([]);
  const [selectedchild_attribute,setselectedchildattribute] = useState('');
  const [service,getservice] = useState([]);
  const [selectedservice,setselectedservice] = useState('');
   const [productlist,setproductlist] = useState([]);
  const [product,setproduct] = useState('');
  
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchStock();
    fetchattributelist();
    groupdetails();
  },[])

  useEffect(() => {
    const getCurrentFinancialYear = () => {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();

      const financialYearStart = currentMonth >= 4 ? currentYear : currentYear - 1;
      const financialYearEnd = financialYearStart + 1;

      return `${financialYearStart}-${financialYearEnd}`;
    };

    const generateFinancialYears = () => {
      const currentFinancialYear = getCurrentFinancialYear();
      const nextFinancialYears = [];

      for (let i = 1; i <= 5; i++) {
        const startYear = parseInt(currentFinancialYear.split('-')[0]) + i;
        const endYear = startYear + 1;
        nextFinancialYears.push(`${startYear}-${endYear}`);
      }
      const allFinancialYears = [currentFinancialYear, ...nextFinancialYears];
      setFinancialYears(allFinancialYears);
    };

    generateFinancialYears();
  }, []);
  useEffect(()=>{
     if(selectedgoalsubsubattribute){
      setselectedchildattribute('');

     }

  },[selectedgoalsubsubattribute])

  //console.log(attributefilter);

  // useEffect(() => {
  //   if (selectedDepartment || category || group || selectedAttributes || financial_year) {
  //     const fetchtarget = async (page = 0) => {
  //         try {
  //             const response = await dashboardApi.get('target-details', {
  //                 params: {
  //                     per_page: postPerPage,
  //                     page: page + 1,
  //                     department: selectedDepartment,
  //                     category: category,
  //                     group: group,
  //                     attribute:selectedAttributes,
  //                     financial_year:financial_year,
  //                 }
  //             });
  //             if (response.data.status === 200) {
  //                 const responseData = response.data.data;
  //                 const stockdata = responseData.data || responseData;
  //                  //console.log(stockdata[0]?.attributes || []);
  //                   if (!selectedAttributes.length) {
  //                      setattributefilter(stockdata[0]?.attributes ?? []);
  //                      setvaluefilter(stockdata[0]?.values ?? [])
  //                   } else {
  //                    setattributefilter(stockdata[0]?.attributes);
  //                    setvaluefilter(stockdata[0]?.values)
  //                    //console.log(stockdata[0]?.values);

  //                   }

  //                   if (!group.length) {
  //                      setgroupfilter(stockdata[0]?.group ?? []);
  //                      setgroupvaluefilter(stockdata[0]?.group_data ?? [])
  //                   } else {
  //                    setgroupfilter(stockdata[0]?.group);
  //                    setgroupvaluefilter(stockdata[0]?.group_data)
  //                    //console.log(stockdata[0]?.group_data);

  //                   }
  //                 // setattributefilter(stockdata[0]?.attribute || []);
  //                 // setvaluefilter(stockdata[0].values);
  //                 settargetlist(stockdata);
  //                 setNumber(response.data.last_page);
  //             } else {
  //                 console.error('Error fetching data:', response.data.error);
  //             }
  //             setLoading(false);
  //         } catch (error) {
  //             console.error('Error fetching data:', error);
  //             setLoading(false);
  //         }
  //     };
  //     fetchtarget();
  //   }
  // }, [selectedDepartment, category, group,selectedAttributes,financial_year]);
  useEffect(() => {
    fetchTarget(currentPage);
}, [product,financial_year, category, currentPage,group]);


  const fetchTarget = async (page = 0) => {
   //alert();
    try {
        setLoading(true);
        const response = await dashboardApi.get('get-lead-based-data', {
            params: {
                financial_year:financial_year,
                category:category,
                product:product,
                group:group,
                //emp_id:empId

            }
        });
        if (response.data.status === 200) {
            const responseData = response.data.data;
            const stockdata = responseData.data || responseData;
            settargetlist(stockdata);
            setNumber(response.data.last_page);
            setLoading(false);

        } else {
            console.error('Error fetching data:', response.data.error);
            setLoading(false);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
    }
};
//console.log(valuefilter);

  const fetchStock = async (page = 0) => {
    try {
      const response = await dashboardApi.get('get-target-save-data', {
          params: {
            per_page: postPerPage,
            page: page + 1,
          }
        });
      if (response.data.status === 200) {
           const responseData = response.data.data;
           const stockdata = responseData.data || responseData;
          // console.log(stockdata);
           settargetlist(stockdata);
           setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchattributelist = async () => {
    try {
     const endpoint = 'active-attribute';
     const response = await dashboardApi.get(endpoint);

     if (response.data.status === 200) {
       setgoalattribute(response.data.data);
     } else {
       console.error('Error fetching data:', response.data.message);
     }
   } catch (error) {
     console.error('Error fetching data:', error);
   }
  }
  useEffect(() => {
    if (goal && selectedDepartment){
       //setInputValues('');
        const fetchData = async () => {
          try {
            const endpoint = `goal-based-attribute/${goal}/${selectedDepartment}`;
            //const endpoint = `count-stock/${stock}`;
            const response = await dashboardApi.get(endpoint);
           // console.log(response);
    
            if (response.data.status === 200) {
              setgoalsubattribute(response.data.data);
            } else {
              console.error('Error fetching data:', response.data.message);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
    }
      }, [goal,selectedDepartment]);

      useEffect(() => {
        if (selectedgoalsubattribute && selectedDepartment){
          //setInputValues('');
           const fetchData = async () => {
             try {
               const endpoint = `goal-based-subattribute/${selectedgoalsubattribute}/${selectedDepartment}`;
               //const endpoint = `count-stock/${stock}`;
               const response = await dashboardApi.get(endpoint);
               //console.log(response);
       
               if (response.data.status === 200) {
                 setgoalsubsubattribute(response.data.data);
               } else {
                 console.error('Error fetching data:', response.data.message);
               }
             } catch (error) {
               console.error('Error fetching data:', error);
             }
           };
       
           fetchData();
       }
         }, [selectedgoalsubattribute,selectedDepartment]);

         useEffect(() => {
          if (selectedgoalsubsubattribute && selectedDepartment){
            //alert();
             //setInputValues('');
              const fetchData = async () => {
                try {
                  const endpoint = `goal-based-sub-subattribute/${selectedgoalsubsubattribute}/${selectedDepartment}`;
                  //const endpoint = `count-stock/${stock}`;
                  const response = await dashboardApi.get(endpoint);
                  //console.log(response);
          
                  if (response.data.status === 200) {
                   setchildattribute(response.data.data);
                  } else {
                    console.error('Error fetching data:', response.data.message);
                  }
                } catch (error) {
                  console.error('Error fetching data:', error);
                }
              };
          
              fetchData();
          }
            }, [selectedgoalsubsubattribute,selectedDepartment]);          

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-active-department';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setDepartments(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

   useEffect(() => {
    if(product){
       const fetchData = async () => {
      try {
        const endpoint = `get-category-list-by-product-id/${product}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
            getservice(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    }
  }, [product]);

   const groupdetails = async () => {
    try {
      const endpoint = 'group-details';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setgrouplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAttributeNameById = (attributeId) => {
  const selectedAttribute = attribute.find((attr) => attr.id === attributeId);
  return selectedAttribute ? selectedAttribute.attribute_name : '';
};

const getTargetValueByAttributeId = (targetId, attributeId) => {
  const target = targetList.find((target) => target.id === targetId);
  return target && target.values ? target.values[attributeId] || '-' : '-';
};


  useEffect(() => {
    if(selectedDepartment){
      setSelectedAttributes([]);
      setattributefilter([]);
      setvaluefilter([]);
        const fetchData = async () => {
          try {
            const endpoint = `department-subattribute/${selectedDepartment}`;
            const response = await dashboardApi.get(endpoint);
    
            if (response.data.status === 200) {
                setattribute(response.data.data);
            } else {
              console.error('Error fetching data:', response.data.message);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
    }
  }, [selectedDepartment]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-product-list';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setproductlist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const endpoint = 'category-details';
  //       const response = await dashboardApi.get(endpoint);
  
  //       if (response.data.status === 200) {
  //           setcategorylist(response.data.data);
  //       } else {
  //         console.error('Error fetching data:', response.data.message);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchStock(selected.selected);
  };
  
  const download_excel = async()=>{
    try {
      const response = await dashboardApi.get('export-target',{
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'target.xlsx');
      document.body.appendChild(link);
      
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <Link className='btn btn-info' to='/target-details'>Back</Link>
              <div className='row'>
               <div className='col-md-3'>
                    <div className="filter-group">
                     <label style={{fontSize:'15px'}}>product:</label>
                    <div className="filter-input">
                    <select
                      value={product}
                    onChange={(e) => setproduct(e.target.value)}
                      style={{ width: '100%', padding: '8px',fontSize:'15px' }}
                       className="form-select form-control filter-select"
                    >
                      <option value="">Select Product</option>
                      {productlist.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.product_name}
                      </option>
                    ))}
                    </select>
                  </div>
                  </div>
                  </div>
                  
 <div className='col-md-3'>
  <div className="filter-group">
  <label style={{fontSize:'15px'}}>Group:</label>
  <div className="filter-input">
    <select
      value={group}
      onChange={(e) => setgroup(e.target.value)}
      className="form-select form-control filter-select"
       style={{ width: '100%', padding: '8px',fontSize:'15px' }}
    >
      <option value="">Select Group</option>
      {grouplist.map((row) => (
                  <option key={row.group_id} value={row.group_id}>
                    {row.name}
                  </option>
                ))}
    </select>
  </div>
</div>
</div>

 <div className='col-md-3'>
  <div className="filter-group">
  <label style={{fontSize:'15px'}}>Category:</label>
  <div className="filter-input">
    <select
      value={category}
      onChange={(e) => setcategory(e.target.value)}
      className="form-select form-control filter-select"
       style={{ width: '100%', padding: '8px',fontSize:'15px' }}
    >
      <option value="">Select category</option>
      {service.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.category_name}
                  </option>
                ))}
    </select>
  </div>
</div>
</div>

 &nbsp; &nbsp; 
 <div className='col-md-2'>
<div className="filter-group">
  <label style={{fontSize:'15px'}}>Year:</label>
  <div className="filter-input">
    <select
      value={financial_year}
      onChange={(e) => set_financialyear(e.target.value)}
      className="form-select form-control filter-select"
       style={{ width: '100%', padding: '8px',fontSize:'15px' }}
    >
      <option value="">Select year</option>
      {financialYears.map((year) => (
        <option key={year} value={year}>{year}</option>
      ))}
    </select>
  </div>
</div>
</div>
</div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered table-responsive">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Dept</td>
                        <td>Product</td>
                        <td>Category</td>
                        <td>Goal</td>
                        <td>Attribute</td>
                        <td>SubAttribute</td>
                        <td>Year</td>
                        <td>Total Lead</td>
                        <td>Acheived</td>
                        <td>Remaining</td>
                        {
                          group && 
                          <td>Group</td>
                        }
                      </tr>
                    </thead>
                    <tbody>
  {targetList.map((row, index) => (
    <tr key={row.id}>
      <td>{index + 1}</td>
      <td>{row.department}</td>
      <td>{row.product}</td>
      <td>{row.category}</td>
      <td>{row.goal}</td>
      <td>{row.attribute}</td>
      <td>{row.child_attribute}</td>
      <td>{row.year}</td>
      <td>{row.total}</td>
      <td>{row.achieved}</td>
      <td>{row.remaining}</td>
      {
        group && 

        <td>{row.group}</td>
      }
      </tr>
        ))}
       </tbody>
                  </table>
                )}
                {targetList.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LeadTargert;