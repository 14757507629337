import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import authorsTableData from "layouts/employee/data/authorsTableData";
import projectsTableData from "layouts/employee/data/projectsTableData";
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function State() {
   const navigate = useNavigate();
   const emp_id = secureLocalStorage.getItem('emp_id');
   const token = secureLocalStorage.getItem('token');
   const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([])
  const [loading, setLoading] = useState(true);
 const permission = secureLocalStorage.getItem('permission');
 const parsedPermission = permission ? JSON.parse(permission) : [];
 const add_state = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-state");
 const edit_state = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-state");
 const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
        fetchState() 
    },[])
   const fetchState = async () => {
    try {
      const response = await dashboardApi.get('state-list');
      if (response.data.status === 200) {
        setState(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };
 const toggleStatus = async (stateId) => {
   const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`state-status/${stateId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      //console.log(response.data.data);
      // Calculate the new status (toggle)
      const newStatus = currentStatus === 1 ? 0 : 1;
      // Make an API call to update the status
      const updateResponse =await dashboardApi.post('state-change-status', {
        state_id: stateId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setState((prevState) =>
          prevState.map((stateObj) =>
            stateObj.state_id === stateId ? { ...stateObj, status: newStatus } : stateObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                {add_state && (
                  <Link className="btn btn-primary mb-2 float-end" to="/add-state">
                    Add State
                  </Link>
                )}
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Name</td>
                  <td>Country Name</td>
                  <td>Short Name</td>
                  {change_status && (
                  <td>Status</td>
                 )}
                 {edit_state && (
                  <td>Action</td>
                 )}
               </tr>
               {state.map((states, index) => (
                <tr key={states.state_id}>
                  <td>{index + 1}</td>
                  <td>{states.state_name}</td>
                  <td>{states.country_name}</td>
                  <td>{states.state_short_name}</td>
                 {change_status && (
                  <td><button
                  type="button"
                  className={`btn btn-${states.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(states.state_id)}
                  >
                  {states.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                )}
                {edit_state && (
                  <td>
                  <Link to={`/state-edit/${states.state_id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>
                  </td>
                 )}
               </tr>
              ))}

            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default State;
