import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import  secureLocalStorage  from  "react-secure-storage";
import CompanyTargetList from "layouts/kra_and_kpi";
import TargetAttribute from "layouts/kra_and_kpi/attribute.js";
import TargetDetails from 'layouts/kra_and_kpi/target_list';
import SubTargetAttribute from "layouts/kra_and_kpi/subattribute.js";
import AttributeMap from 'layouts/kra_and_kpi/attribute_map.js';
import GoalSubAttribute from 'layouts/kra_and_kpi/goal_subattribute.js';
import SubSubAttribute from "layouts/kra_and_kpi/sub_sub_attribute";
import LeadTargert from "layouts/kra_and_kpi/lead_target";
//const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
//const permission = localStorage.getItem('permission');

const KraKpi = [
    {
        type: "collapse",
        name: "KRA & KPI",
        key: "KRA & KPI",
        icon: <Icon fontSize="small">ads_click</Icon>,
        route: "/target-details",
        component: (token!=null) ?  <TargetDetails /> : <SignIn/> ,
        nested:[],
      },
      {
        route: "/company-target",
        component: (token!=null) ?  <CompanyTargetList /> : <SignIn/> ,
        nested:[],

      },
      {
        route: "/company-goal-list",
        component: (token!=null) ?  <TargetAttribute /> : <SignIn/> ,
        nested:[],

      },
      {
        route: "/subattribute-list",
        component: (token!=null) ?  <SubTargetAttribute /> : <SignIn/> ,
        nested:[],

      },
      {
        route: "/attribute-map-list",
        component: (token!=null) ?  <AttributeMap /> : <SignIn/> ,
        nested:[],

      },
      {
        route: "/target-subattribute",
        component: (token!=null) ?  <GoalSubAttribute /> : <SignIn/> ,
        nested:[],

      },
      {
        route: "/target-child-attribute",
        component: (token!=null) ?  <SubSubAttribute /> : <SignIn/> ,
        nested:[],

      },

       {
        route: "/lead-target",
        component: (token!=null) ?  <LeadTargert/> : <SignIn/> ,
        nested:[],

      },


  ];

export default KraKpi;