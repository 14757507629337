import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";

function ModuleList() {
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate();
  const [modulelist, getmodulelist] = useState([]);
  const [loading, setLoading] = useState(true);
  const[permission,Setpermission] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
        fetchmodule() 
    },[currentPage])
   const fetchmodule = async () => {
     try {
      const response = await dashboardApi.post('module-list', {
        per_page:postPerPage,
        page: currentPage + 1,
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        getmodulelist(employeeData);
        setNumber(response.data.last_page);

      } else {
        console.error('Error fetching employee:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employee:', error);
      setLoading(false);
    }
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };
  const toggleStatus = async (moduleid) => {
  const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`module-status/${moduleid}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('update-module-status', {
        module_id: moduleid,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        getmodulelist((prevState) =>
          prevState.map((moduleobj) =>
            moduleobj.id === moduleid ? { ...moduleobj, status: newStatus } : moduleobj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };
 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <Link className='btn btn-primary mb-2 float-end' to={"/add-module"}>
                    Add Module
                </Link>
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                   <td>Module Name</td>
                   <td>Department</td>
                   <td>status</td>
               </tr>
               {modulelist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.module}</td>
                  <td>{row.department_name}</td>
                  <td><button
                  type="button"
                  className={`btn btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>

                
             </tr>
              ))}

            </table>
             )}
              {modulelist.length > 0 && (
                   <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ModuleList;
