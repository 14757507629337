import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";

function AddBranchType() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const[branchType, setbranchType] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);
  const savebranchtype = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!branchType) {
      errors.branch_type = 'Branch Type Name is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(branchType)) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      branch_type: 'Branch Type Name should contain only letters.',
    }));
    return;
  }
    const formData = new FormData()
    formData.append('branch_type', branchType);
    setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('save-branch-type', formData);


      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/branch-type-list");
      }
      else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      }
       else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the Branch',
      });
    }
    finally {
        setIsSubmitting(false);
      }
  };
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <form onSubmit={savebranchtype}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Branch Type Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="branch-type-name"
                            placeholder="Enter Branch Type Name"
                            value = {branchType}
                            onChange={(event)=>{
                              setbranchType(event.target.value)}}
                          />
                           {validationErrors.branch_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.branch_type}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                      <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddBranchType;
