import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import EditSalesDocument from "layouts/sales_document/edit_sales_document.js";
import  secureLocalStorage  from  "react-secure-storage";
const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
import SalesFunnel from "layouts/sales_funnel";
import SalesFunnelDetails from "layouts/sales_funnel/details";
//const permission = localStorage.getItem('permission');

const SalesFunnelRoutes = [
  {
    type: "collapse",
    name: "Sales Funnel",
    key: "Sales Funnel",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    route: "/sales-funnel",
    component: (token!=null) ? <SalesFunnel/> : <SignIn/>,
    nested:[],
  },
  {
    route: "/sales-details/:emp_id/:product_id",
    component: (token!=null) ? <SalesFunnelDetails/> : <SignIn/>,
    nested:[],

  },
    
 

];

export default SalesFunnelRoutes;