// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import  secureLocalStorage  from  "react-secure-storage";
import { Link } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Data
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import TextField from "@mui/material/TextField";
function AddFollowup() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [receivelist, setreceivelist] = useState([]);
  const [id,setid] = useState('');
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [status, setstatus] = useState([]);
  const [remark, setremark] = useState('');
  const [name,setname] = useState('');
  const [mobile,setmobile] = useState('');
  const [email,setemail] = useState('');
  const [image,setimage] = useState(null);
  const [resource,setresource] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [followup_details,setfollowup_details] = useState([]);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchData();
   // fetchemployee();
   },[])
  const fetchData = async () => {
    try {
      const endpoint = `followup-details/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setreceivelist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'active-followup-attribute';
        const response = await dashboardApi.get(endpoint);
        //console.log(response);
  
        if (response.data.status === 200) {
          setfollowup_details(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

const handleOpenModal = async (request_id) => {
  //alert(request_id);
    setid(request_id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setid('');
    setremark('');
    setValidationErrors('');
    setOpenModal(false);
    setemail('');
    setmobile('');
    setname('');
    setimage('');
    setresource('');
    setstatus('');
   // se
  };
  const handlesave = async(e)=>{
    e.preventDefault();
    const errors = {};
    const formData = new FormData();
    formData.append('name',name);
    formData.append('remark',remark);
    formData.append('job_id',id);
    formData.append('emp_id',emp_id);
    formData.append('mobile',mobile);
    formData.append('email',email);
    formData.append('image',image);
    formData.append('status',status);
    formData.append('resource',resource);
    
    //setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('save-followup-details', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchData();
        handleCloseModal();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }

 }

 //console.log(receivelist);

//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                   {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Department From</td>
                  <td>Skill</td>
                  <td>Salary Ranage</td>
                  <td>Exprience</td>
                  <td>Title</td>
                  <td>Remark</td>
                  <td>Action</td>
                  
               </tr>
              {receivelist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.department_name}</td>
                  <td>{row.skill}</td>
                  <td>{row.salary_range}</td>
                  <td>{row.required_experience}</td>
                  <td>{row.title}</td>
                  <td>{row.remark}</td>
                  
                  <td>
                  <Link to = {`/view-position-details/${row.request_id}`} className="btn btn-info btn-sm">View Details</Link>&nbsp;

                  </td>

                  </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Add Followup</DialogTitle>
        <DialogContent>
        <form>
            <div class="container">
            <div class = "row">
            <div class = "col-md-6">
              <label>Candidate Name:</label>
              <TextField
                type="text"
                fullWidth
                 value={name}
                onChange={(e) => setname(e.target.value)}
                 placeholder = "Name"
                  />
                {validationErrors.name && (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                )}
             </div>
              <div className="col-md-6">
             <label>Mobile:</label>
              <TextField
                type="text"
                fullWidth
                 value={mobile}
                onChange={(e) => setmobile(e.target.value)}
                 placeholder = "mobile"
              />
            </div>

            </div>

            <div class = "row">
            <div class = "col-md-6">
              <label>Email:</label>
              <TextField
                type="text"
                fullWidth
                 value={email}
                onChange={(e) => setemail(e.target.value)}
                 placeholder = "email"
              />
            </div>
              <div className="col-md-6">
             <label>Resource From:</label>
              <TextField
                type="text"
                fullWidth
                 value={resource}
                onChange={(e) => setresource(e.target.value)}
                 placeholder = "Resource"
              />
              {validationErrors.resource && (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.resource}</div>
                )}
            </div>
            </div>
            <div class = "row">
            <div class = "col-md-6">
              <label>Upload Cv:</label>
              <TextField
               type = "file"
               fullWidth
               onChange={(e) => setimage(e.target.files[0])}
                 />
              </div>
              <div className="col-md-6">
             <label>Status:</label>
             <select className="form-control" value={status} onChange={(e) => setstatus(e.target.value)} fullWidth>
             <option value=" ">Change Status</option>
                {followup_details.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.name}
                              </option>
                              ))}
              </select>
              {validationErrors.status && (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                )}
                  
            </div>

            </div>
             <label>Remark:</label>
              <TextField
               type = "text"
               fullWidth
               placeholder = "remark"
               value={remark}
                  onChange={(e) => setremark(e.target.value)}
                />
                {validationErrors.remark && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
             )}

            </div>

          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick ={handlesave}>
            Save
          </Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default AddFollowup;