import React, { useState,useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import dashboardApi from "layouts/common_components/apibase_url";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import  secureLocalStorage  from  "react-secure-storage";

function MatureClientProfileHeader()
{
  const empId = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { clientGroup } =useParams();
  const [validationErrors, setValidationErrors] = useState({});
  const [clientDetails,setClientDetails] = useState({});
  const [packageCount,setPackageCount] = useState({});
  const [walletHistory,setWalletDetails] = useState({});
  const [paymentModal,setPaymentModal] = useState(false);
  const [newAmount,setNewAmount] = useState('');
  const [paymentType,setPaymentType] = useState('');
  const [bankName,setBankName] = useState('');
  const [cashType,setCashType] = useState('');
  const [utrNo,setUtrNo] = useState('');
  const [transactionId,setTransactionId] = useState('');
  const [paymentDate,setPaymentDate] = useState('');
  const [remarks,setRemarks] = useState('');
  const [wallet_id,setWalletId] = useState('');
  //const [wallet_amount,setWalletAmount] = useState();
  let clientType = 1;
  // const addWalletAmount = async(e) =>
  // {
  //   e.preventDefault();
  //   const errors = {};

  //   if(!newAmount)
  //   {
  //     errors.newAmount = 'Amount is required';
  //   }

  //   if(newAmount && newAmount < 1)
  //   { 
  //     errors.newAmount = 'Minimum amount grater than 1';
  //   }
     
  //   if(!paymentType)
  //   { 
  //     errors.paymentType = 'Payment Type is required';
  //   }

  //   if(!bankName)
  //   { 
  //     errors.bankName = 'Bank is required';
  //   }

  //   if(!utrNo)
  //   { 
  //     errors.utrNo = 'UTR number is required';
  //   }

  //   if(!transactionId)
  //   { 
  //     errors.transactionId = 'UTR number is required';
  //   }

  //   setValidationErrors(errors);
  //   if (Object.keys(errors).length > 0)
  //   {
  //     return;
  //   }

  //   const formSubmit = new FormData();
  //   formSubmit.append('amount',newAmount);
  //   formSubmit.append('payment_type',paymentType);
  //   formSubmit.append('bank_name',bankName);
  //   formSubmit.append('cash_type',cashType);
  //   formSubmit.append('utr_no',utrNo);
  //   formSubmit.append('transaction_id',transactionId);
  //   formSubmit.append('payment_date',paymentDate);
  //   formSubmit.append('remarks',remarks);
  //   formSubmit.append('created_by',empId);
  //   formSubmit.append('client_id',clientId);
  //   formSubmit.append('wallet_id',wallet_id);

  //   console.log(formSubmit);
    
  //   try {
  //     const response = await dashboardApi.post('add-new-wallet-amount', formSubmit);
  //     //console.log('kkk',response.data.status);
  //     if(response.data.status === 200)
  //     {
  //         Swal.fire({
  //             icon: 'success',
  //             text: response.data.message,
  //         });
  //         setPaymentModal(false);
  //       //navigate("client-profile/1");
  //     }
  //     else if (response.data.status === 201)
  //     {
  //       //alert();
  //       setPaymentModal(false);
  //       Swal.fire({
  //         icon: 'error',
  //         text: response.data.message,
  //       });
  //       //setValidationErrors(response.data.messages);
  //     } 
  //     else
  //     {
  //         Swal.fire({
  //         icon: 'error',
  //         text: response.data.message || 'An error occurred',
  //         });
  //     }
  //   }
  //   catch (error)
  //   {
  //     Swal.fire({
  //         icon: 'error',
  //         text: 'An error occurred while creating the product',
  //     });
  //   } 
  // }

  const addWalletAmount = async (e) => {
    e.preventDefault();
    const errors = {};
  
    // Validation checks
    if (!newAmount) {
      errors.newAmount = 'Amount is required';
    } else if (!/^\d+$/.test(newAmount)) {
      errors.newAmount = 'Amount must be an integer';
    } else if (newAmount < 1) {
      errors.newAmount = 'Minimum amount must be greater than 1';
    }
  
    if (!paymentType) {
      errors.paymentType = 'Payment Type is required';
    }
  
    if (!bankName && (cashType === 'Bank' || paymentType !== 'Cash')) {
      errors.bankName = 'Bank is required';
    }
  
    if (!utrNo) {
      errors.utrNo = 'UTR number is required';
    }
  
    if (!transactionId) {
      errors.transactionId = 'Transaction ID is required';
    }

    // Check for duplicates
    const isDuplicateUTR = await checkDuplicateUTR(utrNo);
    const isDuplicateTransaction = await checkDuplicateTransaction(transactionId);

    if(isDuplicateUTR)
    {
      errors.utrNo = "UTR number is already used";
    }

    if(isDuplicateTransaction)
    {
      errors.transactionId = "Transaction ID is already used";
    }

    setValidationErrors(errors);
    if(Object.keys(errors).length > 0)
    {
      return; // Stop execution if there are validation errors
    }
  
    const formSubmit = new FormData();
    formSubmit.append('amount', newAmount);
    formSubmit.append('payment_type', paymentType);
    formSubmit.append('bank_name', bankName);
    formSubmit.append('cash_type', cashType);
    formSubmit.append('utr_no', utrNo.toUpperCase());
    formSubmit.append('transaction_id', transactionId.toUpperCase());
    formSubmit.append('payment_date', paymentDate);
    formSubmit.append('remarks', remarks);
    formSubmit.append('created_by', empId);
    formSubmit.append('client_id', clientId);
    formSubmit.append('wallet_id', wallet_id);
  
    try {
      const response = await dashboardApi.post('add-new-wallet-amount', formSubmit);
      if (response.data.status === 200) {
        resetFormFields(); // Reset fields on successful submission
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        setPaymentModal(false); // Close modal after successful submission
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while processing the request',
      });
    }
  };

  // Function to check for duplicate UTR numbers
  const checkDuplicateUTR = async (utrNo) => {
    try {
      const response = await dashboardApi.get(`/check-duplicate-utr/${utrNo}`);
      return response.data.isDuplicate;
    } catch (error) {
      console.error("Error checking duplicate UTR:", error);
      return false; // Handle error and assume not duplicate to allow submission
    }
  };

  // Function to check for duplicate Transaction IDs
  const checkDuplicateTransaction = async (transactionId) => {
    try {
      const response = await dashboardApi.get(`/check-duplicate-transaction/${transactionId}`);
      return response.data.isDuplicate;
    } catch (error) {
      console.error("Error checking duplicate Transaction ID:", error);
      return false; // Handle error and assume not duplicate to allow submission
    }
  };

  const paymentOpenControl=()=>{
    setPaymentModal(true);
  }
  
  const closePaymentControl = ()=>
  {
    setPaymentModal(false);
  }
  
  useEffect(()=>{
    checkClientDetails();
    getClientPackage();
    getWalletDetails();
  },[])
  
    const checkClientDetails = async () =>
    {
      try {
          const response = await dashboardApi.get(`mature-client-details/${clientId}`);
          if (response.data.status === 200)
          {
            setClientDetails(response.data.data);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        }
    }
    const getClientPackage = async () =>
    {
      try {
          const response = await dashboardApi.get(`get-package-count/${clientId}`);
          if(response.data.status === 200)
          {
            setPackageCount(response.data.data);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        }
    }

    const getWalletDetails = async () =>
    {
      try {
          const response = await dashboardApi.get(`get-wallet-details/${clientId}/${clientType}`);
          if(response.data.status === 200)
          {
            setWalletDetails(response.data.data);
            setWalletId(response.data.data.wallet_id);
          } 
        }
        catch(error)
        {
          console.error('Error fetching countries:', error);
        }
    }

    const handleAmountChange = (e) => {
      // Replace any non-digit characters with an empty string
      const value = e.target.value.replace(/\D/g, '');
      setNewAmount(value);
    };

    const resetFormFields = () => {
      setNewAmount('');
      setPaymentType('');
      setBankName('');
      setCashType('');
      setUtrNo('');
      setTransactionId('');
      setPaymentDate('');
      setRemarks('');
    };

    return(
      <>
         <div className="d-flex flex-wrap" style={{width:'100%',overflowY: "hidden"}}>
            <div className="col-sm-4 col-12 p-2">
              <div className="p-2 h-100 border rounded"  style={{backgroundColor: "#f0f2f5"}}>
                
                <span style={{display:'flex'}}><h5>Client Information</h5><input type="radio" color="success"  name="" value="" style={{backgroundColor:'green',border:'green',marginLeft: '50%',color:'green'}} checked/> 
                </span>
                <h6><b>Client Type :</b>{clientDetails.status_name}</h6>
                <h6><b>Name : </b> {clientDetails.client_name}</h6> 
                {/* <h6><b>Business Name : </b>{clientDetails.business_name}</h6> */}
                <h6><b>Pan Card No : </b>{clientDetails.pan_card}</h6>
                <h6><b>Email Id: </b>{clientDetails.email}</h6>
                <h6><b>Mobile No : </b>{clientDetails.mobile_no}</h6>
                <h6><b>City : </b>{clientDetails.city} ({clientDetails.state})</h6>
                <h6><b>Address : </b>{clientDetails.address} ({clientDetails.zipcode})</h6>
              </div>
            </div>
            <div className="col-sm-4 col-12 p-2">
              <div className="p-2 h-100 border rounded"  style={{backgroundColor: "#f0f2f5"}}>
                <span style={{display:'flex'}}><h5>Package Information</h5><input type="radio" color="success"  name="" value="" style={{backgroundColor:'green',border:'green',marginLeft: '45%',color:'green'}} checked/> </span>
                <h6><b>Total Packages :  </b> {packageCount.total_package}  </h6>
                <h6><b>Active Package :  </b> {packageCount.active_package}</h6>
                <h6><b>Deactive Packages : </b> {packageCount.deactive_package}</h6> 
              </div>
            </div>
            <div className="col-sm-4 col-12 p-2" >
              <div className="p-2 h-100 border rounded"  style={{backgroundColor: "#f0f2f5"}}>
                <h6><b>Account & Wallet Information</b>
                <Link className="btn btn-success btn-primary btn-sm" style={{float:'right'}} onClick={paymentOpenControl}>💷</Link></h6>
                
                <h6><b>IFSC Code :</b> ICIC0000104</h6>
                <h6><b>Branch Name :</b> Mumbai</h6>
                <h6><b>Wallet Id :</b> RIMS-0{walletHistory && walletHistory.client_id}-0{walletHistory && walletHistory.wallet_id}</h6>
                <h6><b>Credit Amount : </b> {walletHistory && walletHistory.credit_amount} </h6>
                <h6><b>Debit Amount : </b> {walletHistory && walletHistory.debit_amount} </h6>
                <h6><b>Balance Amount : </b> {walletHistory && walletHistory.balance_amount} </h6>
              </div>
            </div>
          </div>
       

        {/* <Dialog open={paymentModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Add Amount</DialogTitle>
          <DialogContent style={{height:"350px"}}>
            <form onSubmit={addWalletAmount}>
                <div className="row">
                  
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "10px" }}>Add Wallet Amount</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-6">
                      <label>Enter Amount</label>
                      <input type="text" name="enter_amount" className="form-control" value={newAmount} onChange={(e)=>setNewAmount(e.target.value)} required />

                      {validationErrors.newAmount && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.newAmount}</div>
                      )}
                  </div>

                  <div className="col-sm-6">
                      <label>Payment Type</label>
                      <select className="form-control"
                        value={paymentType} onChange={(e)=>setPaymentType(e.target.value)} required>
                          <option value=''>Select Payment Type</option>
                          <option value='Cash'>Cash</option>
                          <option value='Cheque'>Cheque</option>
                          <option value='DD'>DD</option>
                          <option value='Online'>Online</option>
                          <option value='PayU'>PayU</option>
                          <option value='Razor Pay'>Razor Pay</option>
                      </select>
                  </div>

                  {paymentType ==='Cash' &&
                    <div className="col-sm-6">
                        <label>Select Cash Type</label>
                        <select className="form-control" value={cashType} onChange={(e)=>setCashType(e.target.value)}>
                            <option value=''>Select Cash Type</option>
                            <option value='cash_in_hand'>Cash In Hand</option>
                            <option value='Bank'>Bank Deposit</option>
                        </select>
                    </div>
                  }

                {(cashType==='Bank' || paymentType !=='Cash') &&
                  <div className="col-sm-6">
                      <label>Select Bank Name</label>
                      <select className="form-control" value={bankName} onChange={(e)=>setBankName(e.target.value)}>
                          <option value=''>Select Bank Name</option>
                          <option value='icici'>ICICI</option>
                          <option value='sbi'>SBI</option>
                          <option value='pnb'>PNB</option>
                          <option value='phone pay'>Phone Pay</option>
                          <option value='g_pay'>Google Pay</option>
                      </select>
                  </div>
                }
                  

                  <div className="col-sm-6">
                      <label>Enter UTR No</label>
                      <input type="text" name="utr_no" className="form-control" value={utrNo} onChange={(e)=>setUtrNo(e.target.value)} />
                  </div>

                  <div className="col-sm-6">
                      <label>Enter Transaction Id</label>
                      <input type="text" name="txn_no" className="form-control" value={transactionId} onChange={(e)=>setTransactionId(e.target.value)} required/>
                  </div>

                  <div className="col-sm-6">
                      <label>Payment Date</label>
                      <input type="date" name="txn_date" className="form-control" value={paymentDate} onChange={(e)=>setPaymentDate(e.target.value)} required/>
                  </div>

                  <div className="col-sm-6">
                      <label>Remarks</label>
                      <input type="text" name="remarks" className="form-control" value={remarks} onChange={(e)=>setRemarks(e.target.value)} required/>
                  </div>
            
                  
                </div>
            </form>
          </DialogContent>

          <DialogActions>
              <Link className="btn btn-primary btn-sm" onClick={closePaymentControl}>Close</Link>
            
              <Link className="btn btn-success btn-sm" onClick={addWalletAmount} >Add Payment</Link>
           
          </DialogActions>
      </Dialog> */}

      <Dialog open={paymentModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Add Amount</DialogTitle>
        <DialogContent style={{ height: "350px" }}>
          <form id="walletForm" onSubmit={addWalletAmount}>
            <div className="row">
              <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                <p style={{ margin: "10px" }}>Add Wallet Amount</p>
                <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
              </div>

              <div className="col-sm-6">
                <label>Enter Amount</label>
                <input
                  type="text"
                  name="enter_amount"
                  className="form-control"
                  value={newAmount}
                  onChange={(e) => handleAmountChange(e)}
                  pattern="\d*"
                  inputMode="numeric"
                  required
                />
                {validationErrors.newAmount && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.newAmount}
                  </div>
                )}
              </div>

              <div className="col-sm-6">
                <label>Payment Type</label>
                <select
                  className="form-control"
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                  required
                >
                  <option value="">Select Payment Type</option>
                  <option value="Cash">Cash</option>
                  <option value="Cheque">Cheque</option>
                  <option value="DD">DD</option>
                  <option value="Online">Online</option>
                  <option value="PayU">PayU</option>
                  <option value="Razor Pay">Razor Pay</option>
                </select>
              </div>

              {paymentType === "Cash" && (
                <div className="col-sm-6">
                  <label>Select Cash Type</label>
                  <select
                    className="form-control"
                    value={cashType}
                    onChange={(e) => setCashType(e.target.value)}
                  >
                    <option value="">Select Cash Type</option>
                    <option value="cash_in_hand">Cash In Hand</option>
                    <option value="Bank">Bank Deposit</option>
                  </select>
                </div>
              )}

              {(cashType === "Bank" || paymentType !== "Cash") && (
                <div className="col-sm-6">
                  <label>Select Bank Name</label>
                  <select
                    className="form-control"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  >
                    <option value="">Select Bank Name</option>
                    <option value="icici">ICICI</option>
                    <option value="sbi">SBI</option>
                    <option value="pnb">PNB</option>
                    <option value="phone pay">Phone Pay</option>
                    <option value="g_pay">Google Pay</option>
                  </select>
                </div>
              )}

              <div className="col-sm-6">
                <label>Enter UTR No</label>
                <input
                  type="text"
                  name="utr_no"
                  className="form-control"
                  value={utrNo.toUpperCase()} // Capitalize input
                  onChange={(e) => setUtrNo(e.target.value.toUpperCase())} // Force uppercase input
                />
                 {validationErrors.utrNo && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.utrNo}
                  </div>
                )}
              </div>

              <div className="col-sm-6">
                <label>Enter Transaction Id</label>
                <input
                  type="text"
                  name="txn_no"
                  className="form-control"
                  value={transactionId.toUpperCase()} // Capitalize input
                  onChange={(e) => setTransactionId(e.target.value.toUpperCase())} // Force uppercase input
                />
                {validationErrors.transactionId && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.transactionId}
                  </div>
                )}
              </div>

              <div className="col-sm-6">
                <label>Payment Date</label>
                <input
                  type="date"
                  name="txn_date"
                  className="form-control"
                  value={paymentDate}
                  onChange={(e) => setPaymentDate(e.target.value)}
                  required
                />
                {validationErrors.paymentDate && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.paymentDate}
                  </div>
                )}
              </div>

              <div className="col-sm-6">
                <label>Remarks</label>
                <input
                  type="text"
                  name="remarks"
                  className="form-control"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  required
                />
                {validationErrors.remarks && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.remarks}
                  </div>
                )}
              </div>
            </div>
          </form>
        </DialogContent>

        <DialogActions>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={closePaymentControl}
          >
            Close
          </button>

          <button
            type="submit"
            form="walletForm"
            className="btn btn-success btn-sm"
          >
            Add Payment
          </button>
        </DialogActions>
      </Dialog>

      </>
    );
}
export default MatureClientProfileHeader;
