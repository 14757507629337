import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import AppCrudHeader from 'layouts/common_components/app_crud_header';

function MilestoneCancelReason() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const token = secureLocalStorage.getItem("token");
  const [openModal, setOpenModal] = useState(false);
  const [editopenModal,seteditopenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const emp_id = secureLocalStorage.getItem('emp_id');
  const [servicelist,setservicelist] = useState([]);
  const [selectedservice,setselectedservice] = useState('');
  const [reason_list,get_reason_list] = useState([]);
  const [id,setid] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;
  const [categorylist,setcategorylist] = useState([]);
  const [selectedcategory,setselectedcategory] = useState('');
  const [title,settitle] = useState('');
  const [use_as,set_use_as] = useState('');
  //const [OpeneditModal,seteditopenModal] = useState(false);

 
  useEffect(() => {
       if (!token) {
        navigate("/sign-in");
     }
    }, [token, navigate]);

    useEffect(()=>{
        servicedetails();


    },[])
    useEffect(()=>{
        categorydetails();

    },[selectedservice])

    useEffect(() => {
        milestone_reason_list(currentPage);
    }, [currentPage]);
    const servicedetails = async () => {
        try {
          const endpoint = 'get-active-service-list';
          const response = await dashboardApi.get(endpoint);
    
          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    const categorydetails = async()=>{
        try {
            const endpoint = `get-category-list-by-service-id/${selectedservice}`;
            const response = await dashboardApi.get(endpoint);
      
            if (response.data.status === 200) {
              setcategorylist(response.data.data);
            } else {
              console.error('Error fetching data:', response.data.error);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }

        
    }

    const handlecategory = (e)=>{
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setselectedcategory(selectedOptions);
        
    
      }
    


  const toggleStatus = async (id) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`milestone-reason-details/${id}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data.status;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('mileston-reason-status-update', {
        id:id,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        milestone_reason_list((prevState) =>
          prevState.map((followupObj) =>
           followupObj.id === id ? { ...followupObj, status: newStatus } : followupObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };



      const milestone_reason_list= async (page) => {
        try {
          // const endpoint = 'otp-template-list';
          // const response = await dashboardApi.get(endpoint);

          const response = await dashboardApi.get('get-milestone-cancel-reason-list', {
            params: {
              per_page: postsPerPage,
              page: page + 1, 
            }
          });
    
          if (response.data.status === 200) {
            const enqData = response.data.data.data || response.data.data;
             get_reason_list(enqData);
            setTotalPages(response.data.last_page);
          } else {
            console.error('Error fetching data:', response.data.error);
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.error('Error fetching data:', error);
        }
      };

      const handlePageClick = (selected) => {
        setCurrentPage(selected.selected);
      };
   const handleCloseModal = () => {
    setValidationErrors('');
    setOpenModal(false);
    seteditopenModal(false);
    setselectedcategory('');
    setselectedservice('')
    set_use_as('');
    settitle('');
   
  };
  const handleOpenModal = async () => {
    setOpenModal(true);
  };
  const editviewopen = async(reason_id)=>{
     setid(reason_id);
      try {
      const endpoint = `milestone-reason-details/${reason_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        settitle(response.data.data.title);
        set_use_as(response.data.data.use_as);
        setselectedservice(response.data.data.service_id);
        setselectedcategory(response.data.data.category_id);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
    seteditopenModal(true);

  }
  
  const handlesave = async (e)=>{
   e.preventDefault();
    const formData = new FormData();
    //const cleanedItemType = item_type.trim();
    formData.append('service',selectedservice);
    formData.append('title',title);
    formData.append('use_as',use_as)
    formData.append('emp_id',emp_id)

    const selectedcategory_ids = Array.isArray(selectedcategory)
    ? selectedcategory
    : [selectedcategory];

    selectedcategory_ids.forEach((Id) => {
    formData.append('category_array[]',Id);
    })

  try {
      const response = await dashboardApi.post('save-milestone-cancel-reason', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        handleCloseModal();
        milestone_reason_list();
      } 

      else if (response.data.status === 201) {
        setValidationErrors(response.data.messages);
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });

        handleCloseModal();
      }
      
       else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the followup',
      });
    }

  }
  const handleupdate = async (e)=>{
    e.preventDefault();
    const formData = new FormData();
   // const cleanedItemType = item_type.trim();
   formData.append('service',selectedservice);
   formData.append('title',title);
   formData.append('use_as',use_as)
   formData.append('emp_id',emp_id)
   formData.append('id',id);

   const selectedcategory_ids = Array.isArray(selectedcategory)
   ? selectedcategory
   : [selectedcategory];

   selectedcategory_ids.forEach((Id) => {
   formData.append('category_array[]',Id);
   })
 
     try {
       const response = await dashboardApi.post('update-milestone-reason-details', formData);
 
       if (response.data.status === 200) {
         Swal.fire({
           icon: 'success',
           text: response.data.message,
         });
         milestone_reason_list()
         handleCloseModal();
       } else if (response.data.status === 400) {
         setValidationErrors(response.data.messages);
       } else {
         Swal.fire({
           icon: 'error',
           text: response.data.message || 'An error occurred',
         });
       }
     } catch (error) {
       Swal.fire({
         icon: 'error',
         text: 'An error occurred while updating',
       });
     }
 
   }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppCrudHeader></AppCrudHeader>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={handleOpenModal}>
                   +
                </Link>

                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Service</td>
                  <td>category</td>
                  <td>Title</td>
                  <td>Use AS </td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {reason_list.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.service}</td>
                  <td>{row.category}</td>
                  <td>{row.title}</td>
                  <td>{row.use_as}</td>
                  <td><button
                  type="button"
                  className={`btn btn-sm btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>

                 <td>
                 <button className='btn btn-primary btn-sm mb-2 float-end'onClick={() => editviewopen(row.id)}>
                    Edit
                </button>
                 </td>
               </tr>
              ))}

            </table>
             )}
             {reason_list.length > 0 && (
            <Pagination className="custom-pagination">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link-style"}
                breakLinkClassName={"break-link-style"}
                previousLinkClassName={"prev-link-style"}
                nextLinkClassName={"next-link-style"}
              />
            </Pagination>
          )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>Add Milestone Cancel Reason</DialogTitle>
          <DialogContent>
            <form>
              <div>
                  <label>Select Service</label>
                  <select className="form-control"
                        value={selectedservice}
                        onChange={(e) => setselectedservice(e.target.value)}
                        >
                        <option value=''>Select Service</option>
                        {servicelist.map((ser) => (
                          <option key={ser.id} value={ser.id}>
                          {ser.service_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.service && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.service}</div>
                  )}
              </div>

              <div>
                  <label>Select category</label>
                  <select className="form-control"
                        value={selectedcategory}
                        onChange={handlecategory}
                        multiple
                        >
                        <option value=''>Select category</option>
                        {categorylist.map((cat) => (
                          <option key={cat.id} value={cat.id}>
                          {cat.category_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.category && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.category}</div>
                  )}
              </div>

              <div>
                  <label>Title</label>
                  <input
                    type='text'
                    className="form-control"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                   {validationErrors.title && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.title}</div>
                  )}
              </div>

              <div>
                  <label>Use As</label>
                  <select className="form-control"
                        value={use_as}
                        onChange={(e) => set_use_as(e.target.value)}
                        >
                        <option value=''>Select Use As</option>
                        <option value='0'>Customer Support</option>
                        <option value='1'>Vendor Side</option>
                          
                     </select>
                   {validationErrors.use_as && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.use_as}</div>
                  )}
              </div>
            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick={handleCloseModal}>
              Close
            </Link>
            &nbsp;
            <button className="btn btn-info" onClick={handlesave}>
             Save
            </button>
          </DialogActions>
      </Dialog>

      <Dialog open={editopenModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>Edit Milestone Cancel Reason</DialogTitle>
          <DialogContent>
          <form>
              <div>
                  <label>Select Service</label>
                  <select className="form-control"
                        value={selectedservice}
                        onChange={(e) => setselectedservice(e.target.value)}
                        >
                        <option value=''>Select Service</option>
                        {servicelist.map((ser) => (
                          <option key={ser.id} value={ser.id}>
                          {ser.service_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.service && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.service}</div>
                  )}
              </div>

              <div>
                  <label>Select category</label>
                  <select className="form-control"
                        value={selectedcategory}
                        onChange={handlecategory}
                        multiple
                        >
                        <option value=''>Select category</option>
                        {categorylist.map((cat) => (
                          <option key={cat.id} value={cat.id}>
                          {cat.category_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.category && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.category}</div>
                  )}
              </div>

              <div>
                  <label>Title</label>
                  <input
                    type='text'
                    className="form-control"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                   {validationErrors.title && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.title}</div>
                  )}
              </div>

              <div>
                  <label>Use As</label>
                  <select className="form-control"
                        value={use_as}
                        onChange={(e) => set_use_as(e.target.value)}
                        >
                        <option value=''>Select Use As</option>
                        <option value='0'>Customer Support</option>
                        <option value='1'>Vendor Side</option>
                          
                     </select>
                   {validationErrors.use_as && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.use_as}</div>
                  )}
              </div>
            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick={handleCloseModal}>
              Close
            </Link>
            &nbsp;
            <button className="btn btn-info" onClick={handleupdate}>
             Save
            </button>
          </DialogActions>
      </Dialog>

    </DashboardLayout>
  );
}

export default MilestoneCancelReason;