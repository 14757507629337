import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import { useNavigate,useParams } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function DocumentType() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [documenttype, setdocumenttype] = useState([])
  const [loading, setLoading] = useState(true);
   const permission = secureLocalStorage.getItem('permission');
   const parsedPermission = permission ? JSON.parse(permission) : [];
   const add_document_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-document-type");
   const edit_document_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-add-document-type");
   const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
        fetchdocumenttype() 
    },[])
   const fetchdocumenttype = async () => {
    try {
      const response =  await dashboardApi.get('document_type-list');
      if (response.data.status === 200) {
        setdocumenttype(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };
 const toggleStatus = async (documenttypeId) => {
  const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`document_type-status/${documenttypeId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse =  await dashboardApi.post('document_type-change-status', {
        document_type_id: documenttypeId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setdocumenttype((prevState) =>
          prevState.map((documenttypeObj) =>
            documenttypeObj.id === documenttypeId ? { ...documenttypeObj, status: newStatus } : documenttypeObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              
              <div className="card-body">
               {add_document_type && (
              <Link className='btn btn-primary mb-2 float-end' to={"/add-document_type"}>
                    Add DocumentType
                </Link>
               )}
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Document Type</td>
                 {change_status && (
                  <td>Status</td>
                 )}
                
                  <td>Action</td>
               </tr>
               {documenttype.map((documenttypes, index) => (
                <tr key={documenttypes.id}>
                  <td>{index + 1}</td>
                  <td>{documenttypes.document_name}</td>
                  {change_status && (
                  <td><button
                  type="button"
                  className={`btn btn-${documenttypes.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(documenttypes.id)}
                  >
                  {documenttypes.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 )}
              
                  <td>
                  <Link to={`/edit-document_type/${documenttypes.id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>
                  
                  </td>
               </tr>
              ))}

            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DocumentType;
