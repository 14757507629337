import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function LeaveType() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [leavetype, setleavetype] = useState([]);
  const [loading, setLoading] = useState(true);
  const permission = secureLocalStorage.getItem('permission');
 const parsedPermission = permission ? JSON.parse(permission) : [];
 const add_leave_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-leave-type");
 const edit_leave_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-leave-type");
 const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
        fetchleavetype() 
    },[])
   const fetchleavetype = async () => {
    try {
      const response = await dashboardApi.get('leave-type-list');
      if (response.data.status === 200) {
        setleavetype(response.data.data);
      } else {
        console.error('Error fetching :', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching :', error);
      setLoading(false);
    }
  };
 const toggleStatus = async (leaveId) => {
  const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`get-leave-type-status/${leaveId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('leave-type-status', {
        leave_id: leaveId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setleavetype((prevState) =>
          prevState.map((leaveObj) =>
            leaveObj.id === leaveId ? { ...leaveObj, status: newStatus } : leaveObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              {add_leave_type&&(
              <Link className='btn btn-primary mb-2 float-end' to={"/create-leave-type"}>
                    Add Leave Type
                </Link>
                )}
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                   <td>Leave Type</td>
                   <td>Days</td>
                   <td>At A Time</td>
                  {change_status&&(
                   <td>Status</td>
                  )}
                   {edit_leave_type&&(
                   <td>Action</td>
                  )}
               </tr>
               {leavetype.map((leave, index) => (
                <tr key={leave.id}>
                  <td>{index + 1}</td>
                  <td>{leave.leave_name}</td>
                  <td>{leave.leave_days}</td>
                  <td>{leave.at_a_time}</td>
                 {change_status&&(
                  <td><button
                  type="button"
                  className={`btn btn-${leave.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(leave.id)}
                  >
                  {leave.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 )}
                 {edit_leave_type&&(
                 <td>
                 <Link to={`/edit-leave-type/${leave.id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>

                 </td>
                 )}
                
             </tr>
              ))}

            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LeaveType;
