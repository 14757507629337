// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// Data
import { Link } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
import StockDetailsHeader from "layouts/common_components/stock_details_header";


function ReturnList() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const employee_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [returnlist, setreturnlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remark,setremark] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [id,setid] = useState('');
  const [stock,setstock] = useState('');
  const [assign,setassign] = useState('');
  const [returnkey,setreturnkey] = useState('');
  const [return_date,setreturn_date] = useState('');
  const [status,setstatus] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchData();
 },[])
  const handleAccept = async(id,stock_id,assign_to)=>{
    try {
      const endpoint = `admin-return-edit/${id}/${assign_to}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setremark(response.data.data.admin_return_remark);
        setreturn_date(response.data.data.return_date);
        setstatus(response.data.data.return_status);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }

    setOpenModal(true);
    setid(id);
    setstock(stock_id);
    setassign(assign_to);
    //setreturn_date()
}
 const handleCloseModal = ()=>{
    setOpenModal(false);
    setremark('');
    setid('');
    setstock('');
    setassign('');
    setreturn_date('')
 }
//alert(returnkey);
const handlesave = async()=>{
  //alert('kk');
 const errors = {};
    if (!remark) {
        errors.remark = 'Please Enter Reason';
      }
       if (!return_date) {
        errors.return_date = 'Return Date is required';
      }
       if (status==0) {
        errors.status = 'Status is required';
      }
     // alert(status);
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {

      return;
    }
     try {
      const endpoint = `return-stock-status/${id}/${assign}/${stock}/${return_date}/${status}/${remark}/${employee_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
          Swal.fire({
              icon: 'success',
              text: response.data.message,
            });
            fetchData();
            handleCloseModal();
          
        } 

      else {
        console.error('Error fetching data:');
      }
     //setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      //setLoading(false);
    }

}

  //const endpoint = `ticket-raise-notification/${emp_id}`;

  const fetchData = async (page = 0) => {
    try {
      const response = await dashboardApi.get('return-stock-list', {
        params: {
          per_page: postPerPage,
          page: page + 1,
        }
      });
  
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const stockdata = responseData.data || responseData;
        setreturnlist(stockdata);
        setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchData(selected.selected);
    //fetchData(selected.selected);
   // console.log(selected);


  };
  //alert(status);

//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <StockDetailsHeader/>
            <Card>
              <div className="card-body">
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Department</td>
                  <td>Emp Name</td>
                  <td>Category</td>
                  <td>Stock</td>
                  <td>Quantity</td>
                  <td>Assign Date</td>
                  <td>Return Date</td>
                  <td>Return Status</td>
                  <td>Return Remark</td>
                  <td>Action</td>
               </tr>
               {returnlist.map((assign, index) => (
                <tr key={assign.id}>
                  <td>{index + 1}</td>
                  <td>{assign.department_name}</td>
                  <td>{assign.emp_fname}</td>
                  <td>{assign.category_name}</td>
                  <td>{assign.brand_name}</td>
                  <td>{assign.quantity}</td>
                  <td>{assign.assign_date}</td>
                  <td>{assign.return_date}</td>
                   <td>{assign.return_status === 1 && (
                    <button className='btn btn-success'>
                      Returned
                    </button>
                  )} 
                  {assign.return_status ===0 && (
                    <button className='btn btn-info'>
                      Pending
                    </button>
                  )}
                   {assign.return_status ===2 && (
                    <button className='btn btn-info'>
                      Reject
                    </button>
                  )}

                  </td>
                  <td>{assign.emp_return_remark}</td>
                  {assign.return_status ===1 ?(
                    <td>
                      <Link className='btn btn-success me-2 btn-sm'>
                          Returned 
                    </Link>
                   </td>
                  ):(
                  <td>
                      <Link className='btn btn-success me-2 btn-sm' onClick={() => handleAccept(assign.id,assign.stock_id,assign.assign_to)}>
                            Change Status
                    </Link>
                    &nbsp;&nbsp;&nbsp;


                   </td>
                   )}
               </tr>
              ))}
            </table>
             )}
              {returnlist.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}
      >
        <DialogTitle>Return Stock</DialogTitle>
        <DialogContent>
          <form>
          <div>
          <label>Return Date</label>
          <input
           type='date'
            value={return_date}
            fullWidth
            className="form-control"
            onChange={(e) => setreturn_date(e.target.value)}
            />
             {validationErrors.return_date&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.return_date}</div>
                )}
          </div>
            <div>
              <label>Status</label>
              <select className="form-control" value={status} onChange={(e) => setstatus(e.target.value)} fullWidth>
                <option value="0">Change Status</option>
                <option value="1">Accept</option>
                <option value="2">Cancel</option>
              </select>
              {validationErrors.status && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
              )}
            </div>


            <div>
              <label>Reason:</label>
              <TextField
                type="text"
                fullWidth
                 value={remark}
                onChange={(e) => setremark(e.target.value)}
              />
             {validationErrors.remark&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                )}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
        <Link className="btn btn-success" onClick={handleCloseModal}>
            Close
          </Link>
       <Link className="btn btn-info"  onClick={() => handlesave()}>
           save
       </Link>

        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default ReturnList;