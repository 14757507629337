import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';

function TargetSubAttribute() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [targetList, settargetlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attribute,setattribute] = useState([]);
  const [selectedattribute,setselectedattribute] = useState('');
  const [subattribute_name,setsubattribute_name] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [id,setid] = useState('');
  const [openeditModal,setopeneditmodel] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(5);
  const [department,setdepartment] = useState([]);
  const [selecteddepartment,setselecteddepartment] = useState([]);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchStock();
    fetchattribute();
    fetchdepartment();

  },[])

  const fetchStock = async (page = 0) => {
      try {
       // const endpoint = 'target-attribute-list';
        //const response = await dashboardApi.get(endpoint);
        const response = await dashboardApi.get('target-subattribute-list', {
            params: {
              per_page: postPerPage,
              page: page + 1,
            }
          });
        if (response.data.status === 200) {
           // settargetlist(response.data.data);
             const responseData = response.data.data;
             const stockdata = responseData.data || responseData;
             settargetlist(stockdata);
             setNumber(response.data.last_page);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    

    const handlePageClick = (selected) => {
        setCurrentPage(selected.selected);
        fetchStock(selected.selected);
        //fetchData(selected.selected);
       // console.log(selected);
    
    
      };
    const toggleStatus = async (attribute_id) => {
        const isConfirm = await Swal.fire({
                 title: 'Are you sure?',
                 text: "Are You Want To Change Status!",
                 icon: 'warning',
                 showCancelButton: true,
                 confirmButtonColor: '#3085d6',
                 cancelButtonColor: '#d33',
                 confirmButtonText: 'Yes, Change it!'
               }).then((result) => {
                 return result.isConfirmed
               });
     
               if(!isConfirm){
                 return;
               }
         const response = await dashboardApi.get(`target-subattribute-status/${attribute_id}`);
         if (response.data.status === 200) {
           const currentStatus = response.data.data;
           const newStatus = currentStatus === 1 ? 0 : 1;
           const updateResponse =await dashboardApi.post('update-target-subatribute-status', {
             subattribute_id: attribute_id,
             status: newStatus,
           });
           if (updateResponse.data.status === 200) {
             settargetlist((prevState) =>
               prevState.map((attributeObj) =>
               attributeObj.id === attribute_id ? { ...attributeObj, status: newStatus } : attributeObj
               )
             );
           } else {
             console.error('Error updating status:', updateResponse.data.message);
           }
         } else {
           console.error('Error fetching current status:', response.data.message);
         }
       };
       const fetchdepartment = async () => {
        try {
         const endpoint = 'get-active-department';
         const response = await dashboardApi.get(endpoint);
 
         if (response.data.status === 200) {
           setdepartment(response.data.data);
         } else {
           console.error('Error fetching data:', response.data.message);
         }
       } catch (error) {
         console.error('Error fetching data:', error);
       }
      }
       const fetchattribute = async () => {
       try {
        const endpoint = 'active-attribute';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setattribute(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
     }
        const add_attribute = async()=>{
             setOpenModal(true);
    }

    const saveHandler = async(e) => {
        e.preventDefault();
        const errors = {};
        const formData = new FormData();
        formData.append('attribute',selectedattribute);
        formData.append('name',subattribute_name);
        formData.append('emp_id',empId);
        formData.append('department',selecteddepartment);
    
        //setIsSubmitting(true);
    
        try {
          const response = await dashboardApi.post('save-target-subattribute', formData);
          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
            });
            fetchStock();
            handleclose();
    
          }
          else if (response.data.status === 400) {
            setValidationErrors(response.data.messages);
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the enquiry',
          });
        } finally {
          //setIsSubmitting(false);
        }
      }
      const handleclose = ()=>{
        setselectedattribute('');
        setsubattribute_name('');
        setValidationErrors('');
        setOpenModal(false);
        setopeneditmodel(false);
        setselecteddepartment('');

      }
      const openeditmodal = async(attribute_id)=>{
        setid(attribute_id);
        try {
            const endpoint = `edit-target-subattribute/${attribute_id}`;
            const response = await dashboardApi.get(endpoint);
           if (response.data.status === 200) {
            //console.log(response.data.data.department_id);
            setselectedattribute(response.data.data.goal_id);
           setsubattribute_name(response.data.data.attribute_name);
           setselecteddepartment(response.data.data.department_id)
           //setQuantity(response.data.data.quantity);
          //setselectedemployee(assignToValues);
            } else {
              console.error('Error fetching data:');
            }
           setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
          setopeneditmodel(true);
        }
        const updattarget = async(e)=>{
            e.preventDefault();
            const errors = {};
           const formData = new FormData();
           formData.append('attribute',selectedattribute);
           formData.append('name',subattribute_name);
           formData.append('emp_id',empId);
           formData.append('department',selecteddepartment);
        
            //setIsSubmitting(true);
        
            try {
             const response =  await dashboardApi.post(`update-target-subattribute/${id}`, formData);
              if (response.data.status === 200) {
                Swal.fire({
                  icon: 'success',
                  text: response.data.message,
                });
                fetchStock();
                handleclose();
        
              }
              else if (response.data.status === 400) {
                setValidationErrors(response.data.messages);
              }
              else {
                Swal.fire({
                  icon: 'error',
                  text: response.data.message || 'An error occurred',
                });
              }
            } catch (error) {
              Swal.fire({
                icon: 'error',
                text: 'An error occurred while creating the enquiry',
              });
            }
         }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <Link className='btn btn-primary mb-2 float-end' to='/company-target'>Back</Link>
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={add_attribute}>
                  +
                </Link>
                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Goal</th>
                        <th>Attribute Name</th>
                        <th>Map Department</th>
                        <th>Status</th>
                        <th>Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {targetList.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.attribute}</td>
                          <td>{row.attribute_name}</td>
                          <td>{row.department}</td>
                          <td><button
                              type="button"
                              className={`btn btn-${row.status === 1 ? 'success' : 'danger'}`}
                              onClick={() => toggleStatus(row.id)}
                             >
                             {row.status === 1 ? 'Active' : 'Inactive'}
                            </button></td>
                            <td><button className="btn btn-info" onClick={() => openeditmodal(row.id)}>Edit</button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {targetList.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                
              </div>
            </Card>
            <Dialog
        open={openModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Add Target Attribute</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <label>Select Attribute:</label>
                  <select
                    className="form-control"
                    value={selectedattribute}
                    //onChange={selectedattribute}
                    onChange={(e) => setselectedattribute(e.target.value)}
                  >
                    <option value="">Select Goal</option>
                    {attribute.map(att => (
                      <option key={att.id} value={att.id}>
                        {att.attribute_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.attribute && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.attribute}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Attribute Name:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={subattribute_name}
                    onChange={(e) => setsubattribute_name(e.target.value)}
                    placeholder="Sub Attribute Name"
                  />
                  {validationErrors.name && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                  )}
              </div>
            </div>
                  <label>Map Department:</label>
                  <select
                   multiple
                    className="form-control"
                    value={selecteddepartment}
                    //onChange={setselecteddepartment}
                    onChange={(e) =>setselecteddepartment(Array.from(e.target.selectedOptions, (option) => option.value))}
                    
                  >
                    <option value="">Select Department</option>
                    {department.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.department_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.department && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                  )}
                </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={handleclose}>
            Close
          </button>
          <button className="btn btn-info" onClick={saveHandler}>
            Save
          </button>
        </DialogActions>
       </Dialog>

       <Dialog
        open={openeditModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Edit Target  Attribute</DialogTitle>
        <DialogContent>
         <form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <label>Select Goal:</label>
                  <select
                    className="form-control"
                    value={selectedattribute}
                    //onChange={selectedattribute}
                    onChange={(e) => setselectedattribute(e.target.value)}
                  >
                    <option value="">Select Goal</option>
                    {attribute.map(att => (
                      <option key={att.id} value={att.id}>
                        {att.attribute_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.attribute && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.attribute}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label>Attribute Name:</label>
                  <TextField
                    type="text"
                    fullWidth
                    value={subattribute_name}
                    onChange={(e) => setsubattribute_name(e.target.value)}
                    placeholder="Sub Attribute Name"
                  />
                  {validationErrors.name && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                  )}
              </div>
            </div>
            <label>Map Department:</label>
                  <select
                   multiple
                    className="form-control"
                    value={selecteddepartment}
                    //onChange={setselecteddepartment}
                    onChange={(e) =>setselecteddepartment(Array.from(e.target.selectedOptions, (option) => option.value))}
                    
                  >
                    <option value="">Select Department</option>
                    {department.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.department_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.department && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                  )}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={handleclose}>
            Close
          </button>
          <button className="btn btn-info" onClick={updattarget}>
            Save
          </button>
        </DialogActions>
       </Dialog>


          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TargetSubAttribute;