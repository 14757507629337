
import React, { useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
//import Button from 'react-bootstrap/Button'

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import  secureLocalStorage  from  "react-secure-storage";

import Swal from 'sweetalert2';
import { useNavigate,useParams,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
function EditProduct() {
   const token = secureLocalStorage.getItem('token');
   const { id } = useParams();
   const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [product, setproduct] = useState('');
  const [ShortName, setShortName] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-company'; // Replace with the actual endpoint
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setOptions(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
  useEffect(()=>{
        fetchProduct()
    },[])
  const fetchProduct = async () => {
    await dashboardApi.get(`edit-product/${id}`).then(({data})=>{
      //console.log('hi');
      const {product_name,company_id,short_name} = data.product
      setproduct(product_name);
      setShortName(short_name);
      setSelectedCompany(company_id);
      //console.log(ShortName);



    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }
  const updateproduct = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!product) {
      errors.product_name = 'Product Name is required';
    }
    if (!ShortName) {
      errors.short_name = 'Short Name is required';
    }
    if (!selectedCompany) {
      errors.company = 'Company is required';
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(product)) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      product_name: 'Product Name should contain only letters.',
    }));
    return;
  }
  if (!nameRegex.test(ShortName)) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      short_name: 'Short Name should contain only letters.',
    }));
    return;
  }
    const formData = new FormData()
    formData.append('product_name', product);
    formData.append('short_name', ShortName);
    formData.append('company', selectedCompany);
    try {
      const response = await dashboardApi.post(`update-product/${id}`, formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/product-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
  };
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div style={{float:'right'}}>
                <Link to='/product-list' className="btn btn-info">Back</Link>

              </div>
              <div className="card-body">
                <form onSubmit={updateproduct}>
                  <div className="container-fluid">
                    <div className="row">
                       <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Select Company</label>
                          <select className="form-control"
                          value={selectedCompany}
                          onChange={(e) => setSelectedCompany(e.target.value)}

                          >
                            <option value=''>Select Company</option>
                            {options.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.business_name}
                              </option>
                              ))}
                          </select>
                           {validationErrors.company && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.company}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="product name" style={{ fontSize: '16px' }}>Product Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="product-name"
                            placeholder="Enter Product Name"
                            value={product}
                            onChange={(e) => setproduct(e.target.value)}
                          />
                          {validationErrors.product_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product_name}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="product name" style={{ fontSize: '16px' }}>Short Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="short-name"
                            placeholder="Enter Product Short Name"
                            value={ShortName}
                            onChange={(e) => setShortName(e.target.value)}
                          />
                          {validationErrors.short_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.short_name}</div>
                             )}
                        </div>
                      </div>

                      </div>

                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                           Update
                        </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditProduct;
