// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import TicketRaiseHeader from 'layouts/common_components/ticket_raise_header';


function NewTimeRequestApproval() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [raiselist, setraiselist] = useState([])
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(5);
  const [openmodal,setopenmodal] = useState(false);
   const [validationErrors, setValidationErrors] = useState({});
   const[ticketid,setticketid] = useState('');
   const[status,setstatus] = useState('');
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
 useEffect(() => {
   fetchData();
  }, []);
   const fetchData = async () => {
    try {
    const endpoint = 'ticket-time-for-approval';
    const response = await dashboardApi.get(endpoint, {
     params: {
       emp_id: emp_id,
      }
     });

      if (response.data.status === 200) {
        //setraiselist(response.data.data);
        const responseData = response.data.data;
        const ticketdata = responseData.data || responseData;
        setraiselist(ticketdata);
        //setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchData(selected.selected);
    //fetchData(selected.selected);
   // console.log(selected);


  };
 const  openpopup = (id)=>{
  setticketid(id);
  setopenmodal(true);

 }
 const handleCloseModal = ()=>{
  setstatus('');
  setticketid('');
  setopenmodal(false);
 }

 const savedata = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!status) {
      errors.status = 'Status is required';
    //alert();
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return; 
    }
   // console.log(selectedOptions);

    const formData = new FormData();
    formData.append('status',status);
    formData.append('emp_id', emp_id);
    formData.append('ticket_id',ticketid);
   // setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('ticket-time-approval-status-update', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //navigate("/ticket-receive");
        handleCloseModal();
        fetchData();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } 
  };
  
//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <TicketRaiseHeader/>
              <div className="card-body">
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                 <div className="table-responsive">
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Ticket Id</td>
                  <td>New Time(in Hour)</td>
                  <td>Remark</td>
                  <td>Request By</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {raiselist.map((ticket, index) => (
                <tr key={ticket.id}>
                  <td>{index + 1}</td>
                  <td>{ticket.ticket_id}</td>
                  <td>{ticket.required_time_in_hour}</td>
                  <td>{ticket.remark}</td>
                  <td>{ticket.emp_fname}</td>
                  <td>
                   {ticket.status === 0
                   ? 'Pending'
                   : ticket.status === 1
                   ? 'Approved'
                   : ticket.status === 2
                    ? 'Rejected'
                   : 'Unknown Status'}
                  </td>
                  <td><button className="btn btn-info" onClick={() =>openpopup(ticket.ticket_id)}>Change Status</button></td>

               </tr>
              ))}
            </table>
            </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
       <Dialog
        open={openmodal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'500px' } }}
      >
        <DialogTitle>Action On Request</DialogTitle>
        <DialogContent>
          <form>
            <div>
              <label>Select Status:</label>
              <select
               className="form-control"
               value={status}
               onChange={(e) => setstatus(e.target.value)}

              >
              <option>Select Status </option>
               <option value="1"> Accept </option>
              <option value="2">Reject</option>

              </select>
              
            
              {validationErrors.status&& (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                )}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick={savedata} >
            Assign
          </Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default NewTimeRequestApproval;