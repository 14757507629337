import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import TicketRaiseHeader from 'layouts/common_components/ticket_raise_header';

function TicketRise(props) {
  const location = useLocation();
  const propData = location.state ? location.state.page_link : null;
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const department = secureLocalStorage.getItem('department_id');
  const navigate = useNavigate();
  const [departmentList, setDepartmentList] = useState([]);
  const [description, setdescription] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDepartmentTo, setSelectedDepartmentTo] = useState('');
  const [priorityList, setPriorityList] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState('');
  //const pageUrl = secureLocalStorage.getItem('current_url');
  const[url,seturl] = useState('');
  const[subattribute,setsubattribute] = useState([]);
  const [selectedsubattribute,setselectedsubattribute] = useState('');
  const [productlist,setproductlist] = useState([]);
  const [selectedproduct,setselectedproduct] = useState('');
  const[grouplist,setgrouplist] = useState([]);
  const [selectedgroup,setselectedgroup] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'dept-list-for-ticket-raise';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setDepartmentList(response.data.data);
        } else {
          console.error('Error fetching data:');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'priority-attribute';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setPriorityList(response.data.data);
        } else {
          console.error('Error fetching data:');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if(selectedPriority){
   const fetchData = async () => {
     try {
       const endpoint = `ticket-active-subattribute/${selectedPriority}`;
       //const endpoint = `count-stock/${stock}`;
       const response = await dashboardApi.get(endpoint);

       if (response.data.status === 200) {
         setsubattribute(response.data.data);
       } else {
         console.error('Error fetching data:', response.data.message);
       }
     } catch (error) {
       console.error('Error fetching data:', error);
     }
   };

   fetchData();
}
 }, [selectedPriority]);

 useEffect(() => {
  if(selectedDepartmentTo==3){
 const fetchData = async () => {
   try {
     const endpoint = 'group-details';
     //const endpoint = `count-stock/${stock}`;
     const response = await dashboardApi.get(endpoint);

     if (response.data.status === 200) {
       setgrouplist(response.data.data);
     } else {
       console.error('Error fetching data:', response.data.message);
     }
   } catch (error) {
     console.error('Error fetching data:', error);
   }
 };

 fetchData();
}
}, [selectedDepartmentTo==3]);


 useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);



  const ticketRise = async (e) => {
    e.preventDefault();
    const errors = {};
    if (selectedPriority.length === 0) {
      errors.attribute = 'Please select at least one Attribute';
    }
    if (selectedsubattribute.length === 0) {
      errors.subattribute = 'Please select at least one Subattribute';
    }
    if (selectedDepartmentTo.length===0) {
      errors.department = 'Please select  Department';
    }
    if (!description) {
      errors.description = 'Description is required';
    }
    if(selectedDepartmentTo==3 &&!selectedgroup){
      errors.group = 'Group is required';

    }
    
    setValidationErrors(errors);

   
    if (Object.keys(errors).length === 0) {
    const formData = new FormData();
    formData.append('department_to', selectedDepartmentTo);
    formData.append('emp_id', emp_id);
    formData.append('attribute', selectedPriority);
    formData.append('subattribute',selectedsubattribute);
    formData.append('url',url);
    formData.append('description',description);
    formData.append('group',selectedgroup);
    formData.append('product',selectedproduct);
    formData.append('dept_id',department);

    setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('ticket-raise', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/raise-details");
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      setIsSubmitting(false);
    }
  }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />   
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <div className='row'>
                <div className='col-md-4'>
                <Link to='/profile' className='btn btn-info btn-sm'>Back</Link>
                </div>

              </div>
                <form onSubmit={ticketRise}>
                  <div className="container-fluid">
                  <TicketRaiseHeader/>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="request-from" style={{ fontSize: '16px' }}>Select Attribute</label>
                          <select className="form-control"
                            value={selectedPriority}
                            onChange={(event) => setSelectedPriority(event.target.value)}
                          >
                            <option value=''>Select  Attribute</option>
                            {priorityList.map((priority) => (
                              <option key={priority.id} value={priority.id}>
                                {priority.attribute_name}
                              </option>
                            ))}
                          </select>
                          {validationErrors.attribute && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.attribute}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="url" style={{ fontSize: '16px' }}>Select Subattribute</label>
                          <select className="form-control"
                            value={selectedsubattribute}
                            onChange={(event) => setselectedsubattribute(event.target.value)}
                          >
                            <option value=''>Select  Subattribute</option>
                            {subattribute.map((priority) => (
                              <option key={priority.id} value={priority.id}>
                                {priority.name}
                              </option>
                            ))}
                          </select>
                          {validationErrors.subattribute && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.subattribute}</div>
                        )}
                        </div>
                      </div>
                      <div className="col-md-4">
                         <div className="form-group">
                          <label htmlFor="url" style={{ fontSize: '16px' }}>Send Ticket To Department</label>
                          <select className="form-control"
                            value={selectedDepartmentTo}
                            onChange={(event) => setSelectedDepartmentTo(event.target.value)}
                          >
                            <option value=''>Select Department</option>
                            {departmentList.map((department) => (
                              <option key={department.id} value={department.id}>
                                {department.department_name}
                              </option>
                            ))}
                          </select>
                          {validationErrors.department && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                        )}
                        </div>
                      </div>
                      {selectedDepartmentTo==3 && 
                      <div className="col-md-4">
                         <div className="form-group">
                          <label htmlFor="url" style={{ fontSize: '16px' }}>Select Group</label>
                          <select className="form-control"
                            value={selectedgroup}
                            onChange={(event) => setselectedgroup(event.target.value)}
                          >
                            <option value=''>Select Department</option>
                            {grouplist.map((row) => (
                              <option key={row.group_id} value={row.group_id}>
                                {row.name}
                              </option>
                            ))}
                          </select>
                          {validationErrors.group && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.group}</div>
                          )}
                        </div>
                      </div>
                      }
                      <div className="col-md-4">
                         <div className="form-group">
                          <label htmlFor="url" style={{ fontSize: '16px' }}>Select Product</label>
                          <select className="form-control"
                            value={selectedproduct}
                            onChange={(event) => setselectedproduct(event.target.value)}
                          >
                            <option value=''>Select product</option>
                            {productlist.map((row) => (
                              <option key={row.id} value={row.id}>
                                {row.product_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="enquiry" style={{ fontSize: '16px' }}>Page URL(if issue in any page)</label>
                           <input
                            type="text"
                            value={url}
                            style={{height:'43px'}}
                            onChange={(e) => seturl(e.target.value)}
                            className="form-control"
                            placeholder="Enter Url"
                          />
                        </div>
                      </div>
                      <div>
                      </div>

                      </div>
                    <div className="row">
                     <div className="form-group col-md-6">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Description</label>
                           <textarea rows={5} cols={5} style={{height:'50px'}}
                            value={description}
                            onChange={(e) => setdescription(e.target.value)}
                            className = "form-control"
                            placeholder="Description"


                           />
                     {validationErrors.description && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.description}</div>
                     )}
                    </div>

                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button
                          className="mt-2 btn btn-info"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                          )}
                          {isSubmitting ? 'Saving...' : 'Send'}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TicketRise;
