// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Swal from 'sweetalert2';
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
import StockDetailsHeader from "layouts/common_components/stock_details_header";

function AssignList() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [assignlist, setassignlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchData();

  },[]);
  const fetchData = async (page = 0) => {
  try {
    const response = await dashboardApi.get('assign-stock-list', {
      params: {
        per_page: postPerPage,
        page: page + 1,
      }
    });

    if (response.data.status === 200) {
      const responseData = response.data.data;
      const stockdata = responseData.data || responseData;
      setassignlist(stockdata);
      setNumber(response.data.last_page);
    } else {
      console.error('Error fetching data:');
    }
    setLoading(false);
  } catch (error) {
    console.error('Error fetching data:', error);
    setLoading(false);
  }
};

const handleFileChange = (e) => {
  e.preventDefault();
  setFile(e.target.files[0]);
};
const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchData(selected.selected);
    //fetchData(selected.selected);
   // console.log(selected);


  };
const handleUpload = async () => {
  if (!file) {
    alert('Please select a file.');
    return;
  }

  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await dashboardApi.post('import-stock', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
      setFile(null);
      const inputFile = document.getElementById('file-upload');
      inputFile.value = null;
      fetchData();

    } else {
      Swal.fire({
        icon: 'error',
        text: response.data.message,
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error.message,
    });
  }
};
const exportData = async()=>{
    try {
      const response = await dashboardApi.get('export-stock',{
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'stocks.xlsx');
      document.body.appendChild(link);
      
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting data:', error);
    }

}


//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <StockDetailsHeader/>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' to={"/assign-stock"}>
                    +
                </Link>
                </div>
                &nbsp;&nbsp;
                
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Department</td>
                  <td>Employee Name</td>
                  <td>Category</td>
                  <td>Stock</td>
                  <td>Quantity</td>
                  <td>Assign Date</td>
                  <td>Acceptance Status</td>
                  <td>Action</td>
               </tr>
               {assignlist.map((assign, index) => (
                <tr key={assign.id}>
                  <td>{index + 1}</td>
                  <td>{assign.department_name}</td>
                  <td>{assign.emp_fname}</td>
                  <td>{assign.category_name}</td>
                  <td>{assign.brand_name}</td>
                  <td>{assign.quantity}</td>
                  <td>{assign.assign_date}</td>
                  <td>
                   {assign.status === 1 ? (
                   <button className='btn btn-success btn-sm'>
                    Accepted
                   </button>
                 ) : assign.status === 2 ? (
                  <button className='btn btn-danger btn-sm'>
                   Rejected
                 </button>
                 ) : (
                   <button className='btn btn-info btn-sm'>
                   Pending
                  </button>
                  )}
                 </td>

                  <td>
                      <Link to={`/edit-assign-stock/${assign.id}`} className='btn btn-success btn-sm me-2'>
                             Edit
                    </Link>
                   </td>
               </tr>
              ))}
            </table>
             )}
             {assignlist.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AssignList;