import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import ProductDetailsHeader from 'layouts/common_components/product_details_header';
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import  secureLocalStorage  from  "react-secure-storage";

function Package_category_type()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  
  const [packageCategory, setPackageCategory] = useState([]);
  const [new_category_name,setCategoryName]= useState();

  const [openModal,setOpenModal] =useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const permission = secureLocalStorage.getItem('permission');

  const [product,setProduct]=useState([]);
  const [selectedProduct,setSelectedProduct] = useState();

  const [service,setService]=useState([]);
  const [selectedService,setSelectedService] = useState([]);
  const [selectedPackageType,setSelectedPackageType] = useState([]);

  const [selectedPkgCategory,setSelectedPkgCategory] = useState();
  const [inputvalues,setInputValues] = useState({});
  const [inputdata,setinputdata] = useState({});
  const parsedPermission = permission ? JSON.parse(permission) : [];
  const [amount,setServiceAmount] = useState();
  let  taxAmount = 0;
  let  totalAmount =0;
  if(amount)
  {
    taxAmount = parseInt(amount) * 18/100;
    totalAmount = parseInt(amount) + parseInt(taxAmount);
  }
  
  
  
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
       // fetchState();
        fetchProduct();
        getCategoryDetails();
        //getPkgCategory();
    },[])

    const getCategoryDetails = async () =>
    {
        const response = await dashboardApi.get(`get-package-category-type-list`);
        if(response.data.status === 200)
        {
          setPackageCategory(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
    };

    useEffect(()=>{
      if(selectedProduct)
      {

          const getPkgCategory = async () =>
          {
              const response = await dashboardApi.get(`get-package-category-list/${selectedProduct}`);
              if(response.data.status === 200)
              {
                setSelectedPkgCategory(response.data.data);
              }
              else
              {
                console.error('Error fetching countries:', response.data.message);
              }
              setLoading(false);
          };   
          getPkgCategory(); 
      }
      },[selectedProduct]);

    const fetchProduct = async () => 
    {
      try{
        const response = await dashboardApi.get(`sales-product-list/active`);
        if(response.data.status === 200)
        {
          setProduct(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    };

   
  useEffect(()=>{
    if(selectedProduct)
    {
      const fetchService = async () => {
        try {
          const response = await dashboardApi.get(`get-service-list-by-product-id-for-category/${selectedProduct}`);
          if (response.data.status === 200)
          {
            setService(response.data.data);
          }
          else
          {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchService();
    }
  },[selectedProduct]);
  

  
  //modal open function
  const handleOpenModal = () =>{
    setOpenModal(true);
  };

  // modal close function
  const handleCloseModal = () => {
    setOpenModal(false);
    setInputValues([]);
  };

  const savedata = (a,b)=>{
    const { value } = b;
    setInputValues(prevState => ({
      ...prevState,
      [a]: b,
    }));
  }


 
  const dataArray = Object.entries(inputvalues).map(([a,b]) => ({
    id: parseInt(a),
    value: b
  }));
  const service_details = JSON.stringify(dataArray);
  
  const chooseService = (name) => {
    const newService = [...selectedService];
    const index = newService.indexOf(name);
    if (index === -1) {
      newService.push(name);
    } else {
      newService.splice(index, 1);
    }
    setSelectedService(newService);
  }

  const choosePackageType = (id) =>{
    const newPackageType = [...selectedPackageType];
    const index = newPackageType.indexOf(id);
    if (index === -1)
    {
      newPackageType.push(id);
    } else {
      newPackageType.splice(index, 1);
    }
    setSelectedPackageType(newPackageType);
  }

  
  // Add New product
  
  const handlesubmit = async() =>
  {
    
    console.log(inputvalues);

    const errors = {};
    if(!selectedProduct)
    {
      errors.selectedProduct = 'Product is required.';
    }

    if(selectedPackageType.length <= 0)
    {
      errors.selectedPackageType = 'Package type is required.';
    }

    if(selectedService.length <= 0)
    {
      errors.selectedService = 'Service is required.';
    }

    if(!new_category_name)
    {
      errors.new_category_name = 'Category type name is required.';
    }

    

    if(dataArray.length <= 0)
    {
      errors.dataArray = 'Package category is required.';
    }

    if(!amount)
    {
      errors.amount = 'Amount is required.';
    }

    if(amount)
    {
      if(amount <= 99)
      errors.amount = 'Service amount more than 100';
    }

    if(selectedProduct && new_category_name)
    {
      const chkPackageCategory = await dashboardApi.get(`check-package-category-type-details/${selectedProduct}/${new_category_name}`);
      if(chkPackageCategory.data==1)
      {
        errors.new_category_name = 'Package category already exists in system for selected product.';
      }
    }

    setValidationErrors(errors);

      if (Object.keys(errors).length > 0)
      {
        return; 
      }
    const formData = new FormData();
    formData.append('created_by',emp_id);
    formData.append('new_category_name',new_category_name);
    formData.append('service_details',service_details);
    formData.append('amount',amount);
    formData.append('tax_amount',taxAmount);
    formData.append('total_amount',totalAmount);
    formData.append('product_id',selectedProduct);
    formData.append('package_type_id',selectedPackageType);
    formData.append('service_id',selectedService);
    

    try {
      const response = await dashboardApi.post('add-package-category-type-details', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/package-category-type");
        handleCloseModal();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    } 
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row">
                    <div class="col-sm-11">
                    <ProductDetailsHeader></ProductDetailsHeader>
                    </div>
                    <div class="col-sm-1">
                      <Link className='btn btn-info' onClick={() => handleOpenModal()}>
                        +
                        </Link>
                    </div>
                </div>
                
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div style={{overflowY: "hidden"}}>
                <table className="table table-bordered table-hovered">
                  <tr>
                    <td>S.No</td>
                    <td>Product</td>
                    <td>Name</td>
                    <td>Package Type</td>
                    <td>Service Name</td>
                    <td>Package Category</td>
                    <td>Price</td>
                    <td>Status</td>
                    
                    
                  </tr>
               {packageCategory.map((category, index) => (
                <tr key={category.id}>
                  <td>{index + 1}</td>
                  <td>{category.product_name}</td>
                  <td>{category.name}</td>
                  <td>{category.package_type.split( ',' ).map( ( item ) => <>✔ { item } <br /> </>) }</td>
                  <td>
                  { category.service_name.split( ',' ).map( ( item ) => <>✔ { item } <br /> </>) }
                  </td>
                  <td>
                    {category.assign_cat.map((assignCat)=>(
                      <tr><td>{assignCat.name}</td> <td>{assignCat.type_status ===1 ? ' ✅ ' : '❌' } </td><td><button className="btn btn-sm btn-primary">Edit</button></td></tr>
                    ))}
                  </td>
                  <td>{category.total_amount}</td>
                  <td>
                    <span>
                      {category.status === 1 ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  
                </tr>
              ))
              }

            </table>
            </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>Add Package Type Category</DialogTitle>
          <DialogContent>
            <form>
              
              <div>
                <label>Select Product</label>
                <select className="form-control" onChange={(e)=> setSelectedProduct(e.target.value)}>
                  <option value="">Select Product</option>
                  {product && product.map((pro)=>(
                    <option value={pro.id}>{pro.product_name}</option>
                  ))}
                </select>
                  {validationErrors.selectedProduct && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedProduct}</div>
                  )}
              </div>

              <div>
                <label>Choose Package Type</label>
                <div>
                    &nbsp;&nbsp;&nbsp;
                    <input type="checkbox" value="1" onChange={(e)=>choosePackageType(1)} />&nbsp;&nbsp;Local &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="checkbox" value="2" onChange={(e)=>choosePackageType(2)}/>&nbsp;&nbsp;Domestic &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="checkbox" value="3" onChange={(e)=>choosePackageType(2)}/>&nbsp;&nbsp; Combo
                </div>
                {validationErrors.selectedPackageType && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedPackageType}</div>
                  )}
              </div>

              <div>
                <label>Choose Service</label>
                
                {service && service.map((ser)=>(
                  <div>
                  <input type="checkbox" onChange={(e)=>chooseService(ser.id)} value={ser.id} /> <b>{ser.service_name}</b>
                  </div>
                  
                ))}

                {validationErrors.selectedService && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedService}</div>
                )}
                  
              
              </div>

              <div>
                <label>Category Type Name</label>
                <TextField fullWidth type="text" value={new_category_name} onChange={(e) => setCategoryName(e.target.value)}></TextField>

                {validationErrors.new_category_name && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.new_category_name}</div>
                  )}
              </div>

              

              <label>Choose Package Category</label>
              {selectedPkgCategory && selectedPkgCategory.map((cat)=>(
                <div>
                  <label>☑ &nbsp;</label>
                  {cat.name} : &nbsp;&nbsp;
                  <input type="radio" name={cat.id} value= '1'  onChange={(e) => savedata(cat.id,1)} required />&nbsp;Yes &nbsp;&nbsp;&nbsp;<input type="radio" name={cat.id} value="0" onChange={(e) => savedata(cat.id,0)} required />&nbsp;No &nbsp;&nbsp;&nbsp;
                </div>
              ))}
              {validationErrors.dataArray && (
                <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.dataArray}</div>
              )}

              <div>
                <label>Service Amount</label>
                <input type="text" className="form-control" name="price" value={amount} onChange={(e)=>{setServiceAmount(e.target.value)}}/>
                {validationErrors.amount && (
                <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.amount}</div>
              )}
              </div>
              <div>
                <label>Tax Amount</label>
                <input type="text" className="form-control" name="tax_amount" value={taxAmount} readOnly />
              </div>
              <div>
                <label>Total Amount</label>
                <input type="text" name="price" className="form-control" value={totalAmount} readOnly/>
              </div>
            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick = {handleCloseModal}>
              Close
            </Link>
            <Link className="btn btn-info"  onClick = {handlesubmit}>
            Add
            </Link>
          </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
export default Package_category_type;
