import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate,useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCut } from '@fortawesome/free-solid-svg-icons';
import EmployeeDetailsHeader from "layouts/common_components/emp_details_header";
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function DocumentInfo() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const [documentname, setdocumentname] = useState('');
  const [documentdetails, setdocumentdetails] = useState('');
  const [image, setimage] = useState('');
  const [option, setOption] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const[documentlist,setdocumentlist] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);
 useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-document-type'; // Replace with the actual endpoint
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setOption(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
  const clearFields = () => {
    setdocumentname(''); 
    setdocumentdetails('');
    const imageInput = document.querySelector('input[type="file"]');
     if (imageInput) {
       imageInput.value = '';
    }
  };
   useEffect(()=>{
        fetchdocument() 
    },[])
   const fetchdocument = async () => {
    try {
      const response = await dashboardApi.get(`document-list/${id}`);
      if (response.data.status === 200) {
        setdocumentlist(response.data.data);
      } else {
        console.error('Error fetching employee:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employee:', error);
      setLoading(false);
    }
  };
  const savedocument = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!documentname) {
      errors.document = 'document is required';
    }
    if (!documentdetails) {
      errors.document_details = 'document details  is required';
    }
    if (!image) {
      errors.image = 'image is required';
    }
    setValidationErrors(errors);

  if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('document', documentname);
    formData.append('document_details', documentdetails);
    formData.append('image', image);
    formData.append('emp_id', id);
    setIsSubmitting(true);
    try {
        const response =await dashboardApi.post('save-emp-document', formData);

        if (response.data.status === 200) {
          Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
          clearFields();
          fetchdocument() 

         
        } else if (response.data.status === 400) {
          setValidationErrors(response.data.messages);
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while creating the employee',
        });
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };
  const toggleStatus = async (docId) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`get-document-status/${docId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      // Make an API call to update the status
      const updateResponse = await dashboardApi.post('document-status', {
        id: docId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setdocumentlist((prevState) =>
          prevState.map((docobj) =>
            docobj.id === docId ? { ...docobj, status: newStatus } : docobj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
           <EmployeeDetailsHeader id={id}/>
            <Card>
              <div className="card-body">
              <Link className='btn btn-primary mb-2 float-end' to={"/employee-list"}>
                  Back
                </Link>
                <form onSubmit={savedocument}>
                  <div className="container-fluid">
                      <div  className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label  style={{ fontSize: '16px' }}>
                              Select Document
                            </label>
                            <select
                              className="form-control"
                              value = {documentname}
                              onChange={(e) => setdocumentname(e.target.value)}
                            >
                              <option value=''>Select Document</option>
                              {option.map((row) => (
                                <option key={row.id} value={row.id}>
                                  {row.document_name}
                                </option>
                              ))}
                            </select>
                            {validationErrors.document && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.document}</div>
                             )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label style={{ fontSize: '16px' }}>Document Details</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Document Details"
                              value = {documentdetails}
                              onChange={(e) => setdocumentdetails(e.target.value)}
                            />
                            {validationErrors.document_details && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.document_details}</div>
                             )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label style={{ fontSize: '16px' }}>Select file</label>
                            <input
                              type="file"
                              className="form-control"
                                onChange={(e) => setimage(e.target.files[0])}
                            />
                            {validationErrors.image && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.image}</div>
                             )}
                          </div>
                        </div>
                      </div>
                    <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                  </div>
                </form>

                <center>List of Documents</center>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Document Name</td>
                  <td>Image</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {documentlist.map((doc, index) => (
                <tr key={doc.id}>
                  <td>{index + 1}</td>
                  <td>{doc.document_name}</td>
                  <td>
                  <img src={doc.doc_url} alt="Document" style={{ maxWidth: '80px', height: '80px' }} />
                </td>
                <td><button
                  type="button"
                  className={`btn btn-${doc.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(doc.id)}
                  >
                  {doc.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 <td>
                  <Link to={`/edit-documents/${doc.id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>
                  </td>

               </tr>
              ))}

            </table>
             )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DocumentInfo;
