import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate,useParams } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function WfhTask() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const empid = secureLocalStorage.getItem("emp_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [permission, Setpermission] = useState(); 
  const { id } = useParams();
  const [task,settask] = useState([]);
  const [openmodal,setopenmodal] = useState('');
  const [editmodal,seteditmodal] = useState('');
  const [status,setstatus] = useState('');
  const[remark,setremark] = useState('');
  const[task_id,settask_id] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [employee_id,setemployee_id] = useState('');
  const [taskdetails,settaskdetails] = useState('');
  const [statusdetails,setstatusdetails] = useState('');
  //alert(id);
useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(()=>{
    fetchtask();

  },[id]);
  const closemodal = ()=>{
   // alert();
    setremark('');
    setstatus('');
    settask_id('');
    setopenmodal(false);
    setValidationErrors({});
    settaskdetails('');
    setstatusdetails('');
    seteditmodal(false);

  }
  const fetchtask = async ()=>{
    try {
        const endpoint = `get-wfh-task/${id}`;
        const response = await dashboardApi.get(endpoint);
      if (response.data.status == 200) {


          settask(response.data.data);
          setemployee_id(response.data.employee_id);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }
    //console.log(task);
    const edittask = async (id)=>{
        settask_id(id);
    try {
        const endpoint = `get-wfh-task-status/${id}`;
        const response = await dashboardApi.get(endpoint);
        //console.log(response.data.data);
       if (response.data.status === 200) {
        settaskdetails(response.data.data[0].task);
        setstatusdetails(response.data.data[0].status)
      
        } else {
          console.error('Error fetching data:');
        }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
        seteditmodal(true);

        

    }




  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `get-permission/${emp_id}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          Setpermission(response.data.data);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const updatestatus = async (id)=>{
    //alert(id);
    settask_id(id);
    try {
        const endpoint = `get-wfh-task-status/${id}`;
        const response = await dashboardApi.get(endpoint);
        //console.log(response.data.data);
       if (response.data.status === 200) {
        setremark(response.data.data[0].remark);
        setstatus(response.data.data[0].manager_status);
        settaskdetails(response.data.data[0].task);
        setstatusdetails(response.data.data[0].status)
      
        } else {
          console.error('Error fetching data:');
        }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }


    setopenmodal(true);



  }
  const savestatus =  async()=>{
    const errors = {};
    if(emp_id==employee_id){
       
        if(!statusdetails){
            errors.statusdetails = 'Status Is Required';
    
    
        }
        if(!taskdetails){
            errors.taskdetails = 'Task Is Required';
    
        }

    }
    else{
       
        if(!status || status == 'Select Status' ){
            errors.status = 'Status Is Required';
    
    
        }
        if(!remark){
            errors.remark = 'Remark Is Required';
    
        }

    }
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
    const formData = new FormData();
    if(emp_id == employee_id){
        formData.append('statusdetails',statusdetails);
        formData.append('taskdetails',taskdetails);
        formData.append('type','employee');

    }
    else{
        formData.append('status',status);
        formData.append('remark',remark);
        formData.append('type','manager');

    }


    //setIsSubmitting(true);

    try {
        const response =  await dashboardApi.post(`update-wfh-task-status/${task_id}`, formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchtask();
        closemodal();

      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }
}
}


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered">
                 <tr>
                  <td>S.No</td>
                  <td>Task Details</td>
                  <td>Status</td>
                  <td>Approval Status</td>
                  <td>Remark</td>
                  <td style={{minWidth:'160px'}}>Action</td>
               </tr>
               {task.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.task}</td>
                  <td>{row.status}</td>
                  <td>{row.manager_status}</td>
                  <td>{row.remark}</td>
                  {emp_id !=employee_id &&
                  <td><button className="btn btn-info btn-sm" onClick={(event) => updatestatus(row.id)}>Update</button></td>
                  }
                   {emp_id ==employee_id &&
                  <td><button className="btn btn-info btn-sm" onClick={(event) =>edittask(row.id)}>Edit</button></td>
                  }
                  </tr>
              ))}

            </table>
             )}
              </div>
                <hr/>
                
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openmodal}
        //onClose={closeModalHandler}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'600px'} }}
      >
        <DialogTitle>Task Status</DialogTitle>
        <DialogContent>
          <form>
           <div className='container'>
            <div className="row">
                <div className="col-md-12">
                   <select className="form-control"
                    value={status}
                    onChange={(e) => setstatus(e.target.value)}
                   
                   >
                    <option value=''>Select Status</option>
                    <option value='Approved'>Approved</option>
                    <option value = 'Reject'>Reject</option>

                   </select>
                   {validationErrors.status && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                     )}

                </div>

            </div>
            <br/>

            <div class ="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Reason</label>
                           <textarea rows={5} cols={5} style={{height:'50px'}}
                           value={remark}
                           onChange={(e) => setremark(e.target.value)}
                            className = "form-control"


                           />
                     {validationErrors.remark && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                     )}
                    </div>
                    </div>


           

           </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodal}>
            Close
          </button>
          <button className="btn btn-info" onClick={savestatus}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    

      <Dialog
        open={editmodal}
        //onClose={closeModalHandler}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'600px'} }}
      >
        <DialogTitle>Task Edit</DialogTitle>
        <DialogContent>
          <form>
           <div className='container'>
            <div className="row">
                <div className="col-md-12">
                <label  style={{ fontSize: '16px',height:'20px' }}>Status</label>
                  <input
                    type="text"
                    className="form-control"
                    value={statusdetails}
                    onChange={(e) => setstatusdetails(e.target.value)}
                  />
                   {validationErrors.statusdetails && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.statusdetails}</div>
                     )}

                </div>

            </div>
            <br/>

            <div class ="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Task</label>
                           <textarea rows={5} cols={5} style={{height:'50px'}}
                           value={taskdetails}
                           onChange={(e) => settaskdetails(e.target.value)}
                            className = "form-control"


                           />
                     {validationErrors.taskdetails && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.taskdetails}</div>
                     )}
                    </div>
                    </div>


           

           </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodal}>
            Close
          </button>
          <button className="btn btn-info" onClick={savestatus}>
            Save
          </button>
        </DialogActions>
      </Dialog>

    </DashboardLayout>
  );
}

export default WfhTask;