import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function TicketSubattribute() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [subattributelist, setsubattributelist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openmodal,setopenmodal] = useState(false);
  const [attribute_list,setattribute_list] = useState([]);
  const[attribute,setattribute] = useState('');
  const[subattribute,setsubattribute] = useState('');
  const[action_time,setaction_time] = useState('');
  const[completion_time,setcompletion_time] = useState('');
  const [validationerror,setvalidationerror] = useState({});
  const [openeditmodal,setopeneditmodal] = useState(false);
  const [attr_id,setid] = useState('');
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
        fetchattribute() 
        ticket_attribute()
    },[])
   const  fetchattribute = async () => {
    try {
      const response = await dashboardApi.get('ticket-subattribute-list');
      //console.log(response.data.status);
      if (response.data.status === 200) {
        setsubattributelist(response.data.data);
      } else {
        console.error('Error fetching attribute:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching attribute:', error);
      setLoading(false);
    }
  };

  const closemodal = ()=>{
    setattribute('');
    setsubattribute('');
    setaction_time('');
    setcompletion_time('');
    setvalidationerror({});
    setopenmodal(false)
    setopeneditmodal(false)

  }

  const  ticket_attribute = async () => {
    try {
      const response = await dashboardApi.get('get-active-ticket-attribute');
      //console.log(response.data.status);
      if (response.data.status === 200) {
        setattribute_list(response.data.data);
      } else {
        console.error('Error fetching attribute:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching attribute:', error);
      setLoading(false);
    }
  };
  //console.log('kk',subattributelist)
 const toggleStatus = async (Id) => {
  const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`ticket-subattribute-status/${Id}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('ticket-subattribute-status-update', {
        id: Id,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setsubattributelist((prevState) =>
          prevState.map((atrObj) =>
            atrObj.id === Id ? { ...atrObj, status: newStatus } : atrObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };
  const open_model = ()=>{
    setopenmodal(true);

  }
   const editmodal = async (id)=>{
    setid(id);
    try {
        const endpoint = `edit-ticket-subattribute/${id}`;
        const response = await dashboardApi.get(endpoint);
        //console.log(response.data.data);
       if (response.data.status === 200) {
        setattribute(response.data.data.attribute_id);
        setsubattribute(response.data.data.name);
        setaction_time(response.data.data.action_time);
        setcompletion_time(response.data.data.completion_time)
      
        } else {
          console.error('Error fetching data:');
        }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }


    setopeneditmodal(true);
    

   }
  const save_subattribute = async (e)=>{
    e.preventDefault();
    const errors = {}
    if(!attribute){
      errors.attribute = 'Attribute is required'


    }
    if(!subattribute){
      errors.subattribute = 'SubAttribute is required'
     }
    if(!action_time){
      errors.action_time = 'Action Time is required'
    }
    if(!completion_time){
      errors.completion_time = 'Completion Time  is required'
   }
   setvalidationerror(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData();
    formData.append('attribute',attribute);
    formData.append('name',subattribute);
    formData.append('action_time',action_time);
    formData.append('completion_time',completion_time);
    formData.append('emp_id',emp_id);

    try {
      const response =  await dashboardApi.post('save-ticket-subattribute', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchattribute();
         closemodal();

      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }
}

  }
  const update_subattribute = async (e)=>{
    e.preventDefault();
    const errors = {}
    if(!attribute){
      errors.attribute = 'Attribute is required'


    }
    if(!subattribute){
      errors.subattribute = 'SubAttribute is required'
     }
    if(!action_time){
      errors.action_time = 'Action Time is required'
    }
    if(!completion_time){
      errors.completion_time = 'Completion Time  is required'
   }
   setvalidationerror(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData();
    formData.append('attribute',attribute);
    formData.append('name',subattribute);
    formData.append('action_time',action_time);
    formData.append('completion_time',completion_time);
    formData.append('emp_id',emp_id);

    try {
      const response =  await dashboardApi.post(`update-ticket-subattribute/${attr_id}`, formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchattribute();
         closemodal();

      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }
  }

  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <Link className='btn btn-info' to="/ticket-attribute">Back</Link>
              <div>
              <div style={{float:'right'}}>
              <Link className='btn btn-primary mb-2 float-end' onClick={open_model}>
                    +
                </Link>
              </div>

              </div>
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Attribute Name</td>
                  <td>Subattribute Name</td>
                  <td>Action Time(in hours)</td>
                  <td>Completion Time(in hours)</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {subattributelist.map((attr, index) => (
                <tr key={attr.id}>
                  <td>{index + 1}</td>
                  <td>{attr.attribute_name}</td>
                  <td>{attr.name}</td>
                  <td>{attr.action_time}</td>
                  <td>{attr.completion_time}</td>
                  <td><button
                  type="button"
                  className={`btn btn-${attr.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(attr.id)}
                  >
                  {attr.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 <td>
                 <button className='btn btn-info' onClick={(e)=>editmodal(attr.id)}>
                  Edit
                 </button>

                 </td>
               </tr>
              ))}

            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openmodal}
        //onClose={closeModalHandler}
        fullWidth
        maxWidth = 'lg'
        PaperProps={{ style: {padding: '40px', height:'600px'} }}
      >
        <DialogTitle>Add Subattribute</DialogTitle>
        <DialogContent>
          <form>
           <div className='container'>
            <div className="row">
              <div className="col-md-6">
              <div className="form-group">
                  <label htmlFor="country" style={{ fontSize: '16px' }}>Select Attribute</label>
                  <select className="form-control"
                  value = {attribute}
                  onChange={(e) => setattribute(e.target.value)}
                  >
                  <option>Select Attribute</option>
                  {attribute_list.map((option) => (
                  <option key={option.id} value={option.id}>
                  {option.attribute_name}
                  </option>
                  ))}
                  </select>
                  {validationerror.attribute && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationerror.attribute}</div>
                     )}
                  </div>
                 </div>

                <div className="col-md-6">
                <div className="form-group">
                <label htmlFor="country" style={{ fontSize: '16px' }}>Subattribute Name</label>
                  <input
                  className='form-control'
                  placeholder="Enter Name"
                  value = {subattribute}
                  onChange={(e) => setsubattribute(e.target.value)}
                  
                  />
                  {validationerror.subattribute && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationerror.subattribute}</div>
                     )}
                  </div>


                </div>

                </div>
                <div className='row'>
                  <div className='col-md-6'>
                  <label htmlFor="country" style={{ fontSize: '16px' }}>Action Time(in hour)</label>
                  <input
                    type ="number"
                    className='form-control'
                    placeholder="Action Time"
                    value = {action_time}
                    onChange={(e) => setaction_time(e.target.value)}
                  />
                  {validationerror.action_time && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationerror.action_time}</div>
                     )}
                 </div>

                 <div className='col-md-6'>
                  <label htmlFor="country" style={{ fontSize: '16px' }}>Completion Time(in hour)</label>
                  <input
                    type ="number"
                    className='form-control'
                    placeholder="Completion Time"
                    value = {completion_time}
                    onChange={(e) => setcompletion_time(e.target.value)}
                  />
                   {validationerror.completion_time && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationerror.completion_time}</div>
                     )}
                 </div>

                </div>
                </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodal}>
            Close
          </button>
          <button className="btn btn-info" onClick={save_subattribute}>
            Save
          </button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openeditmodal}
        //onClose={closeModalHandler}
        fullWidth
        maxWidth = 'lg'
        PaperProps={{ style: {padding: '40px', height:'600px'} }}
      >
        <DialogTitle>Edit Subattribute</DialogTitle>
        <DialogContent>
          <form>
           <div className='container'>
            <div className="row">
              <div className="col-md-6">
              <div className="form-group">
                  <label htmlFor="country" style={{ fontSize: '16px' }}>Select Attribute</label>
                  <select className="form-control"
                  value = {attribute}
                  onChange={(e) => setattribute(e.target.value)}
                  >
                  <option>Select Attribute</option>
                  {attribute_list.map((option) => (
                  <option key={option.id} value={option.id}>
                  {option.attribute_name}
                  </option>
                  ))}
                  </select>
                  {validationerror.attribute && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationerror.attribute}</div>
                     )}
                  </div>
                 </div>

                <div className="col-md-6">
                <div className="form-group">
                <label htmlFor="country" style={{ fontSize: '16px' }}>Subattribute Name</label>
                  <input
                  className='form-control'
                  placeholder="Enter Name"
                  value = {subattribute}
                  onChange={(e) => setsubattribute(e.target.value)}
                  
                  />
                  {validationerror.subattribute && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationerror.subattribute}</div>
                     )}
                  </div>


                </div>

                </div>
                <div className='row'>
                  <div className='col-md-6'>
                  <label htmlFor="country" style={{ fontSize: '16px' }}>Action Time(in hour)</label>
                  <input
                    type ="number"
                    className='form-control'
                    placeholder="Action Time"
                    value = {action_time}
                    onChange={(e) => setaction_time(e.target.value)}
                  />
                  {validationerror.action_time && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationerror.action_time}</div>
                     )}
                 </div>

                 <div className='col-md-6'>
                  <label htmlFor="country" style={{ fontSize: '16px' }}>Completion Time(in hour)</label>
                  <input
                    type ="number"
                    className='form-control'
                    placeholder="Completion Time"
                    value = {completion_time}
                    onChange={(e) => setcompletion_time(e.target.value)}
                  />
                   {validationerror.completion_time && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationerror.completion_time}</div>
                     )}
                 </div>

                </div>
                </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodal}>
            Close
          </button>
          <button className="btn btn-info" onClick={update_subattribute}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default TicketSubattribute;
