import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function Attribute() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [attribute, setattribute] = useState([]);
  const [loading, setLoading] = useState(true);
   const permission = secureLocalStorage.getItem('permission');
   const parsedPermission = permission ? JSON.parse(permission) : [];
   const add_salary = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-salary-attribute");
   const edit_salary = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-salary-attribute");
   const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
        fetchattribute() 
    },[])
   const  fetchattribute = async () => {
    try {
      const response = await dashboardApi.get('attribute-list');
      if (response.data.status === 200) {
        setattribute(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };
 const toggleStatus = async (atrId) => {
  const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`get-attribute-status/${atrId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('attribute-status', {
        attribute_id: atrId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setattribute((prevState) =>
          prevState.map((atrObj) =>
            atrObj.id === atrId ? { ...atrObj, status: newStatus } : atrObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
               {add_salary &&(
              <Link className='btn btn-primary mb-2 float-end' to={"/add-attribute"}>
                    Add SalaryAttribute
                </Link>
                )}
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Company Name</td>
                  <td>Attribute</td>
                  <td>Percentage</td>
                 {change_status &&(
                  <td>Status</td>
                  )}
                  {edit_salary &&(
                  <td>Action</td>
                  )}
               </tr>
               {attribute.map((attr, index) => (
                <tr key={attr.id}>
                  <td>{index + 1}</td>
                  <td>{attr.business_name}</td>
                  <td>{attr.attribute}</td>
                  <td>{attr.percentage}</td>
                  {change_status &&(
                  <td><button
                  type="button"
                  className={`btn btn-${attr.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(attr.id)}
                  >
                  {attr.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 )}
                 {edit_salary &&(
                  <td>
                  <Link to={`/edit-attribute/${attr.id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>
                  
                  </td>
                   )}
               </tr>
              ))}

            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Attribute;
