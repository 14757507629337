import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate,useParams } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import ReactPaginate from "react-paginate";
import secureLocalStorage from "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function AttendanceApproval() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const dept =  secureLocalStorage.getItem("department_id");
  const navigate = useNavigate();
  const [loading,setloding] = useState(false);
  const { id } = useParams();
  const [list,setlist] = useState([]);
  const [openmodal,setopenmodal] = useState(false);
  const [status,setstatus] = useState('');
  const [remark,setremark] = useState('');
  const [validationErrors,setValidationErrors] = useState({});
  const [ids,setids] = useState();
  const [employeeid,setemployeeid] = useState();
  const [dates,setdates] = useState('');
  useEffect(()=>{
    fetchData();


  },[]);
  

  const fetchData = async () => {
    try {
        const endpoint = `show-regulrize-data/${dept}`;
        const response = await dashboardApi.get(endpoint);
  
        if (response.data.status === 200) {
            setlist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


  
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  const openpopup = ()=>{
    setopenmodal(true);
 }
 const closepopup = ()=>{
    setopenmodal(false);
    setdates('');
    setids('');
    setemployeeid('');
    setremark('');
    setstatus('');
    setValidationErrors('');
 }
  const saverequest = async ()=>{
    const errors = {};
    if(!status){
        errors.status = 'status is Required';

    }
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
        const formData = new FormData();
        formData.append('emp_id',employeeid);
        formData.append('date',dates);
        formData.append('ids',ids);
        formData.append('remark',remark);
        formData.append('status',status);
        try {
            const response =  await dashboardApi.post('save-attendance-regularize', formData);
          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
            });
            fetchData();
            closepopup();
    
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating',
          });
        } finally {
          //setIsSubmitting(false);
        }

     }

 }
 const updatestatus = (emp_id,date,id)=>{
    setopenmodal(true);
    setemployeeid(emp_id);
    setdates(date);
    setids(id);
   


 }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <br /> <br />
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered table-striped">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Emp Name</td>
                        <td>Date</td>
                        <td>Reason</td>
                        <td>status</td>
                        <td>Remark</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.emp_fname} {row.emp_lame}</td>
                          <td>{row.date}</td>
                          <td>{row.reason}</td>
                          <td>{row.status}</td>
                          <td>{row.remark}</td>
                          <td><button className="btn btn-info btn-sm" onClick={() => updatestatus(row.emp_id,row.date,row.id)}>Action</button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openmodal}
        //onClose={closeModalHandler}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'500px'} }}
      >
        <DialogTitle>Attendance Request</DialogTitle>
        <DialogContent>
          <form>
           <div className='container'>
            <div className="row">
                <div className="col-md-12">
                   <select
                   className="form-control"
                   value={status}
                   onChange={(e) => setstatus(e.target.value)}
                   
                   >
                  <option vallue=" ">Mark Attendance</option>
                  <option value = '1'>Present</option>
                  <option value= "0">Absent</option>
                  <option value = '2'>Half Day</option>


                   </select>
                   {validationErrors.status && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                     )}

                </div>

            </div>
            <br/>

            <div className ="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Remark</label>
                           <textarea rows={5} cols={5} style={{height:'50px'}}
                            value={remark}
                            onChange={(e) => setremark(e.target.value)}
                            className = "form-control"


                           />
                    </div>
                    </div>


           

           </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closepopup}>
            Close
          </button>
          <button className="btn btn-info" onClick={saverequest}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default AttendanceApproval;
