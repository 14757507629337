import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import StockDetailsHeader from "layouts/common_components/stock_details_header";
import ProfileHeader from "layouts/common_components/profileheader.js";

function AddStocck() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [stocklist, setStockList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);
  const [categorylist,setcategorylist] = useState([]);
  const[category,setcategory] = useState('');
  const[stock,setstock] = useState([]);
  const [selectedstock,setselectedstock] = useState('');
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchStock();
  }, []);
  useEffect(()=>{
    fetchStock_filter()

  },[category]);

  const fetchStock = async (page = 0) => {
    try {
      const response = await dashboardApi.get('stock-list', {
        params: {
          per_page: postPerPage,
          page: page + 1,
        }
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const stockdata = responseData.data || responseData;
        setStockList(stockdata);
        setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
  e.preventDefault();
  setFile(e.target.files[0]);
};


const handleUpload = async () => {
  if (!file) {
    alert('Please select a file.');
    return;
  }

  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await dashboardApi.post('import-stock-excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
      setFile(null);
      const inputFile = document.getElementById('file-upload');
      inputFile.value = null;
      fetchStock();

    } else {
      Swal.fire({
        icon: 'error',
        text: response.data.message,
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error.message,
    });
  }
};


  const fetchStock_filter = async (page = 0) => {
    try {
      const response = await dashboardApi.get('stock-list', {
        params: {
          per_page: postPerPage,
          page: page + 1,
          category:category,
        }
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const stockdata = responseData.data || responseData;
        setStockList(stockdata);
        setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchStock(selected.selected);
  };

  const toggleStatus = async (stockId) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "Are You Want To Change Status!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!'
    }).then((result) => {
      return result.isConfirmed
    });

    if (!isConfirm) {
      return;
    }

    try {
      const response = await dashboardApi.get(`stock-status/${stockId}`);
      if (response.data.status === 200) {
        const currentStatus = response.data.data;
        const newStatus = currentStatus === 1 ? 0 : 1;
        const updateResponse = await dashboardApi.post('change-stock-status', {
          stock_id: stockId,
          status: newStatus,
        });
        if (updateResponse.data.status === 200) {
          // Update the local state with the new status
          setStockList((prevState) =>
            prevState.map((stockObj) =>
              stockObj.id === stockId ? { ...stockObj, status: newStatus } : stockObj
            )
          );
        } else {
          console.error('Error updating status:', updateResponse.data.message);
        }
      } else {
        console.error('Error fetching current status:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

   const export_stock = async () => {
          //emp_id: empId,

    try {
      const response = await dashboardApi.get('export-avaliable-stock', {
       params: {
         download: 'csv',
         category:category
        },
       responseType: 'blob',
    });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `avaliable_stocks_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }
  };

  const export_branch = async () => {
          //emp_id: empId,

    try {
      const response = await dashboardApi.get('branch-export', {
       params: {
         download: 'csv',
        },
       responseType: 'blob',
    });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `branch_${new Date().toISOString()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the CSV file', error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
    try {
      const endpoint = 'active-category';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setcategorylist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);


  useEffect(() => {
    if (category) {
      const fetchData = async () => {
        try {
          const endpoint = `category-based-stock/${category}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setstock(response.data.data);
          } else {
            console.error('Error fetching states data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching states data:', error);
        }
      };

      fetchData();
    }
  }, [category]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StockDetailsHeader />
            <Card>
            <div className="container">
            <div className="row">
            <div className="col-md-3">
            <select className="form-control"
              value={category}
              onChange={(e) => setcategory(e.target.value)}
                >
              <option value=''>Select Category</option>
              {categorylist.map((option) => (
              <option key={option.id} value={option.id}>
              {option.category_name}
               </option>
              ))}
              </select>

            </div>

             <div className="col-md-1">
             <button className="btn btn-info btn-sm" onClick={export_stock}>Export</button>

            </div>

            <div className='col-md-4'>
            <input type='file'
             className='form-control'
             onChange={handleFileChange}
             id='file-upload'
            />

            </div>


             <div className='col-md-2'>
             <button  onClick={handleUpload} className='btn btn-info'>Import</button>

            </div>
            <div className="col-md-1">
             <button className="btn btn-info btn-sm" onClick={export_branch}>Branch</button>

            </div>
            </div>
            </div>
              <div className="card-body">
                <div style={{ float: 'right' }}>
                  <Link className='btn btn-primary mb-2 float-end' to={"/add-stock"}>
                    +
                  </Link>
                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-bordered table-hovered">
                      <thead>
                        <tr>
                          <td>S.No</td>
                          <td>Stock Id</td>
                          <td>Brand Name</td>
                          <td>Category</td>
                          <td>Vendor</td>
                          <td>Product Id</td>
                          <td>Price</td>
                          <td>Product Type</td>
                          <td>Device Id</td>
                          <td>Total Quantity</td>
                          <td>Available Quantity</td>
                          <td>Status</td>
                          <td>Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {stocklist.map((stock, index) => (
                          <tr key={stock.id}>
                            <td>{index + 1}</td>
                            <td>{stock.id}</td>
                            <td>{stock.brand_name}</td>
                            <td>{stock.category_name}</td>
                            <td>{stock.name}</td>
                            <td>{stock.product_id}</td>
                            <td>{stock.price}</td>
                            <td>{stock.product_type}</td>
                            <td>{stock.device_id}</td>
                            <td>{stock.quantity}</td>
                            <td>{stock.remaining_quantity}</td>
                            <td>
                              <button
                                type="button"
                                className={`btn btn-sm btn-${stock.status === 1 ? 'success' : 'danger'}`}
                                onClick={() => toggleStatus(stock.id)}
                              >
                                {stock.status === 1 ? 'Active' : 'Inactive'}
                              </button>
                            </td>
                            <td>
                              <Link to={`/edit-stock/${stock.id}`} className='btn btn-success btn-sm me-2'>
                                Edit
                              </Link>
                             
                             <Link to={`/stock-history/${stock.id}`} className='btn btn-success btn-sm me-2'>
                                History
                              </Link>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {stocklist.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddStocck;
