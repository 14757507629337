import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import ProductDetailsHeader from 'layouts/common_components/product_details_header';
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import  secureLocalStorage  from  "react-secure-storage";


function Service_details()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [product,setProduct]=useState([]);
  const [service, setService] = useState([]);
  const [new_service_name,setServiceName]= useState();
  const [openModal,setOpenModal] =useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const permission = secureLocalStorage.getItem('permission');
  const [selectedProduct,setselectedProduct] = useState();
  const parsedPermission = permission ? JSON.parse(permission) : [];
  
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(()=>{
        fetchService();
        fetchProduct();
    },[])


    

    const fetchProduct = async () => 
    {
      try{
        const response = await dashboardApi.get(`sales-product-list/active`);
        if(response.data.status === 200)
        {
          setProduct(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    };

   const fetchService = async () => {
    try {
      const response = await dashboardApi.get('sales-service-list/all');
      if (response.data.status === 200) {
        setService(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };

  
  //modal open function
  const handleOpenModal = () =>{
    setOpenModal(true);
  };

  // modal close function
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  
  // Add New product
  
  const handlesubmit = async() =>
  {
    const errors = {};
    if(!selectedProduct)
    {
      errors.selectedProduct = 'Product name is required.';
    }

    if(!new_service_name)
    {
      errors.new_service_name = 'Service name is required.';
    }

    if(selectedProduct && new_service_name)
    {
      const chkService = await dashboardApi.get(`check-service-details/${selectedProduct}/${new_service_name}`);
      if(chkService.data==1)
      {
        errors.new_service_name = 'Service already exists in system for this product.';
      }
    }


      setValidationErrors(errors);
    if (Object.keys(errors).length > 0)
    {
      return; 
    }
    const formData = new FormData();
    formData.append('product_id',selectedProduct);
    formData.append('created_by',emp_id);
    formData.append('new_service_name',new_service_name);
   
    try {
      const response = await dashboardApi.post('add-service-details', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //navigate("/service-details");
        fetchService();
        handleCloseModal();

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    } 
  }

  

    const toggleStatus = async (id) =>
    {
        const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text:  "You want to update this service status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it'
          }).then((result) => {
            return result.isConfirmed
        });

        if(!isConfirm){
          return;
        }
        const response = await dashboardApi.get(`sales-service-status/${id}`);
    
        if(response.data.status === 200)
        {
          const currentStatus = response.data.data;
          const newStatus = currentStatus === 1 ? 0 : 1;
          // Make an API call to update the status
          const updateResponse =await dashboardApi.post('sales-service-change-status', {
            id: id,
            status: newStatus,
          });
          
          if(updateResponse.data.status === 200)
          {
            setService((prevService) =>
            prevService.map((serviceObj) =>
            serviceObj.id === id ? { ...serviceObj, status: newStatus } : serviceObj));
            Swal.fire({
              icon: 'success',
              text: updateResponse.data.message,
            });
          } 
          else
          {
            console.error('Error updating status:', updateResponse.data.message);
          }
        }
        else
        {
          console.error('Error fetching current status:', response.data.message);
        }
    };
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row">
                    <div class="col-sm-11">
                    <ProductDetailsHeader></ProductDetailsHeader>
                    </div>
                    <div class="col-sm-1">
                      <Link className='btn btn-info' onClick={() => handleOpenModal()}>
                        +
                      </Link>
                    </div>
                </div>
                
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div style={{overflowY: "hidden"}}>
                  <table className="table table-bordered table-hovered">
                    <tr>
                      <td>S.No</td>
                      <td>Product Name</td>
                      <td>Service Name</td>
                      <td>Created By</td>
                      <td>Created Date</td>
                      <td>Status</td>
                    </tr>
                {service.map((service, index) => (
                  <tr key={service.id}>
                    <td>{index + 1}</td>
                    <td>{service.product_name}</td>
                    <td>{service.service_name}</td>
                    <td>{service.created_by}</td>
                    <td>{service.created_date}</td>
                    
                    <td>
                      <button
                        type="button"
                        className={`btn btn-${service.status === 1 ? 'success' : 'danger'}`}
                        onClick={() => toggleStatus(service.id)}
                        >
                        {service.status === 1 ? 'Active' : 'Inactive'}
                      </button>
                    </td>
                  </tr>
                ))
                }

              </table>
              </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>Add New Service</DialogTitle>
          <DialogContent>
            <form>
              <div>
                <label>Select Product</label>
                    <select className="form-control"
                          value={selectedProduct}
                          onChange={(e) => setselectedProduct(e.target.value)}
                          >
                          <option value=''>Select Product</option>
                          {product.map((option) => (
                            <option key={option.id} value={option.id}>
                            {option.product_name}
                            </option>
                            ))}
                    </select>
                    {validationErrors.selectedProduct && (
                        <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedProduct}</div>
                      )}
              </div>
              <div>
                <label>Enter Service Name</label>
                <TextField fullWidth type="text" value={new_service_name} onChange={(e) => setServiceName(e.target.value)}></TextField>
                {validationErrors.new_service_name && (
                  <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.new_service_name}</div>
                )}
                
              </div>

              
        
              
            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick = {handleCloseModal}>
              Close
            </Link>
            <Link className="btn btn-info"  onClick = {handlesubmit}>
            Add
            </Link>
          </DialogActions>
      </Dialog>


    </DashboardLayout>
  );
}
export default Service_details;
