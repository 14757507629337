import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { MultiSelect } from "react-multi-select-component";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import ProductDetailsHeader from 'layouts/common_components/product_details_header';

// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import  secureLocalStorage  from  "react-secure-storage";

function Attribute_details()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  
  const [attribute, setAttribute] = useState([]);
  const [new_attribute_name,setAttributeName]= useState();

  const [openModal,setOpenModal] =useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const permission = secureLocalStorage.getItem('permission');

  const [product,setProduct]=useState([]);
  const [selectedProduct,setSelectedProduct] = useState();

  const [service,setService]=useState([]);
  const [selectedService,setSelectedService] = useState();

  const [categoryDetails,setCategoryDetails]=useState([]);
  const [selectedCategory,setSelectedCategory]=useState([]);

  const categoryValue = selectedCategory.map(e => e.value).join(',');

  const parsedPermission = permission ? JSON.parse(permission) : [];
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
       // fetchState();
        fetchProduct();
        getAttributeDetails();
    },[])

    const getAttributeDetails = async () =>
    {
      const response = await dashboardApi.get(`get-attribute-list`);
        if(response.data.status === 200)
        {
          setAttribute(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
    };
    

    const fetchProduct = async () => 
    {
      try{
        const response = await dashboardApi.get(`sales-product-list/active`);
        if(response.data.status === 200)
        {
          setProduct(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    };

   
  useEffect(()=>{
    if(selectedProduct)
    {
      const fetchService = async () => {
        try {
          const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
          if (response.data.status === 200) {
            setService(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchService();
    }
  },[selectedProduct]);


  useEffect(()=>{
    if(selectedProduct && selectedService)
    {
      const fetchCategory = async () => {
        try {
          const response = await dashboardApi.get(`get-category-list-by-id/${selectedProduct}/${selectedService}`);
          if (response.data.status === 200) {
            setCategoryDetails(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchCategory();
    }
  },[selectedProduct,selectedService]);

  const changeAttributeStatus = async(id,status)=>
    {
      const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text:  "You want to update this attribute status!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it'
      }).then((result) => {
        return result.isConfirmed
      });

        if(!isConfirm)
        {
          return;
        }
        const response = await dashboardApi.get(`update-attribute-status/${id}/${status}`);
        if (response.data.status === 200)
        {

          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
          getAttributeDetails();

        }
        else
        {
          console.error('Error fetching current status:', response.data.message);
        }
    }
  

  
  //modal open function
  const handleOpenModal = () =>{
    setOpenModal(true);
  };

  // modal close function
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  
  // Add New product
  
  const handlesubmit = async() =>
  {
    const errors = {};
    if(!selectedProduct)
      {
        errors.selectedProduct = 'Product is required.';
      }
  
      if(!selectedService)
      {
        errors.selectedService = 'Service is required.';
      }

      if(categoryValue.length < 1)
        {
          errors.selectedCategory = 'Minimum one category is required.';
        }

      if(!new_attribute_name)
      {
        errors.new_attribute_name = 'Attribute name is required.';
      }
  
      if(selectedProduct && new_attribute_name)
      {
          const chkPriference = await dashboardApi.get('check-attribute-details', {
          params: {
            productId: selectedProduct,
            serviceId: selectedService,
            attributeName: new_attribute_name,
          },
        });
        
        if(chkPriference.data == 1)
        {
          errors.new_attribute_name = 'Attribute already exists in system for this product and service.';
        }
      }
      setValidationErrors(errors);
      if (Object.keys(errors).length > 0)
      {
        return; 
      }

    const formData = new FormData();
    formData.append('product_id',selectedProduct);
    formData.append('service_id',selectedService);
    formData.append('category_id',categoryValue);
    formData.append('new_attribute_name',new_attribute_name);
    formData.append('created_by',emp_id);
    
    try {
      const response = await dashboardApi.post('add-attribute-details', formData);

      if(response.data.status === 200){
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        handleCloseModal(false);
        setSelectedProduct('');
        setSelectedService('');
        setAttributeName('');
        getAttributeDetails();
        

      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    } 
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row">
                    <div class="col-sm-11">
                    <ProductDetailsHeader></ProductDetailsHeader>
                    </div>
                    <div class="col-sm-1">
                      <Link className='btn btn-info' onClick={() => handleOpenModal()}>
                        +
                        </Link>
                    </div>
                </div>
                
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div style={{overflowY: "hidden"}}>
                    <table className="table table-bordered table-hovered">
                    <tr>
                      <td>S.No</td>
                      <td>Product Name</td>
                      <td>Service Name</td>
                      <td>Category Name</td>
                      {/* <td>Created By</td> */}
                      <td>Created Date</td>
                      <td>Action</td>
                    </tr>
                    {attribute.map((attribute, index) => (
                      <tr key={attribute.id}>
                        <td>{index + 1}</td>
                        <td>{attribute.product_name}</td>
                        <td>{attribute.service_name}</td>
                        <td>{attribute.attribute_name}</td>
                       
                        {/* <td>{attribute.created_by}</td> */}
                        <td>{attribute.created_date}</td>
                        <td>
                          <button
                            type="button" onClick={()=>{changeAttributeStatus(attribute.id,attribute.status)}}
                            className={`btn btn-${attribute.status === 1 ? 'success' : 'danger'}`}
                            >
                            {attribute.status === 1 ? 'Active' : 'Inactive'}
                          </button>
                        </td>
                      </tr>
                    ))
                    }
                </table>
              </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>Add Attribute</DialogTitle>
          <DialogContent>
            <form>
              <div>
                  <label>Select Product</label>
                  <select className="form-control"
                        value={selectedProduct}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                        >
                        <option value=''>Select Product</option>
                        {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.selectedProduct && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedProduct}</div>
                  )}
              </div>

              <div>
                  <label>Select Service</label>
                  <select className="form-control"
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                        >
                        <option value=''>Select Service</option>
                        {service.map((ser) => (
                          <option key={ser.id} value={ser.id}>
                          {ser.service_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.selectedService && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedService}</div>
                  )}
              </div>

              {/* <div>
                  <label>Select Category</label>
                  <select className="form-control"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        >
                        <option value=''>Select Category</option>
                        {categoryDetails.map((cat) => (
                          <option key={cat.id} value={cat.id}>
                          {cat.category_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.selectedService && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedService}</div>
                  )}
              </div> */}

              <div className="col-sm-12">
                  <label>Select Category</label>
                  <MultiSelect
                      options={categoryDetails}
                      value={selectedCategory}
                      onChange={setSelectedCategory}
                      labelledBy={categoryDetails}
                      checked
                  />
                  {validationErrors.selectedCategory && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.selectedCategory}</div>
                  )}
              </div>


              <div>
                <label>Attribute Name</label>
                <TextField fullWidth type="text" value={new_attribute_name} onChange={(e) => setAttributeName(e.target.value)}></TextField>
                {validationErrors.new_attribute_name && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.new_attribute_name}</div>
                  )}
              </div>


            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick = {handleCloseModal}>
              Close
            </Link>
            <Link className="btn btn-info"  onClick = {handlesubmit}>
            Add
            </Link>
          </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
export default Attribute_details;
