import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,Link } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import Swal from 'sweetalert2';
import MDBox from "components/MDBox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";


function AssignGroupsToEmployee() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id  = secureLocalStorage.getItem('department_id');
  const [list,setlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productlist,getproductlist] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const[product,setproduct] = useState('');
  const[employee,setemployee] = useState([]);
  const[selectedemployee,setselectedemployee] = useState('');
  const [grouplist,setgrouplist] = useState([]);
  const[setgroup,group] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const [selectedchild_attribute,setselectedchildattribute] = useState('');
  

  useEffect(()=>{
    fetchbranch() 
},[])
const fetchbranch = async () => {
try {
  const response =  await dashboardApi.get('employee-group-list');
  if (response.data.status === 200) {
    setlist(response.data.data);
  } else {
    console.error('Error fetching:', response.data.message);
  }
  setLoading(false);
} catch (error) {
  console.error('Error fetching:', error);
  setLoading(false);
}
};
useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getproductlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'dm-department-employees';
      const response = await dashboardApi.get(endpoint);
     // console.log(response);

      if (response.data.status === 200) {
        setemployee(response.data.data);
      } else {
        console.error('Error fetching states data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching states data:', error);
    }
  };

  fetchData();
}, [dept_id]);
const closemodel = ()=>{
  setproduct('');
  setselectedemployee('');
  setselectedchildattribute('');
  setOpenModal(false);
}

const savedata = async (e) => {
   alert()
   e.preventDefault();
   const errors = {};
   setValidationErrors(errors);
  if (Object.keys(errors).length === 0) {
   const formData = new FormData()
   formData.append('emp_id',empId);
   formData.append('product',product);
   formData.append('employee',selectedemployee);
   formData.append('group_id',selectedchild_attribute);
   //const total_data =  JSON.stringify({assignedTasks }),
 
   try {
     const response = await dashboardApi.post('save-assign-group', formData);
 
     if (response.data.status === 200) {
       //console.log('iiii');
       Swal.fire({
         icon: 'success',
         text: response.data.message,
       });
       fetchbranch();
       closemodel();
 
     } else if (response.data.status === 400) {
       // Display validation errors below the input fields
       setValidationErrors(response.data.messages);
     }
     else if (response.data.status === 201) {
       Swal.fire({
         icon: 'error',
         text: response.data.message,
       });
     }
      else {
       Swal.fire({
         icon: 'error',
         text: response.data.message || 'An error occurred',
       });
     }
   } catch (error) {
     Swal.fire({
       icon: 'error',
       text: 'An error occurred ',
     });
   }
 };
 }

useEffect(() => {
  if(product){
  const fetchData = async () => {
    try {
      const endpoint = `get-active-groups/${product}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setgrouplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}
}, [product]);
//console.log(grouplist);


const assign_group = ()=>{
  setOpenModal(true);
}


 
  //alert(search_financial_year);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <Link to='/department-target' className='btn btn-info btn-sm'>Back</Link>
                 &nbsp;
            
              <button className='btn btn-primary mb-2 float-end btn-sm' onClick={assign_group}>
                    Assign Groups
                </button>
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Employee</td>
                  <td>Site</td>
                  <td>Group</td>
               </tr>
               {list.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.emp}</td>
                  <td>{row.product}</td>
                  <td>{row.group}</td>
               </tr>
              ))}
            </table>
             )}
                
              </div>
              </Card>
              <Dialog
        open={openModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'600px'} }}
      >
        <DialogTitle>Assign Group To DM Employee</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <label>Select Site:</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={product}
                    onChange={(e) => setproduct(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Site</option>
                    {productlist.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.product_name}
                      </option>
                    ))}
                   
                  </select>
                </div>
                <div className="col-md-6">
                  <label>Select Employee:</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedemployee}
                    onChange={(e) => setselectedemployee(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Employee</option>
                    {employee.map(dept => (
                      <option key={dept.emp_id} value={dept.emp_id}>
                        {dept.emp_fname} {dept.emp_lame}
                      </option>
                    ))}
                   
                  </select>
                </div>
            </div>
            <label htmlFor="department">Select Group:</label>
      <div style={{ position: 'relative' }}>
        <input
          type="text"
          placeholder="Group"
          //value={searchText}
          //onChange={(e) => setSearchText(e.target.value)}
          onClick={() => setShowAttributeDropdown(!showAttributeDropdown)}
          style={{ width: '100%', padding: '8px', fontSize: '14px' }}
        />
        {showAttributeDropdown && grouplist && grouplist.length > 0 && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              border: '1px solid #ced4da',
              borderRadius: '4px',
              maxHeight: '200px',
              overflowY: 'auto',
              zIndex: 999,
              backgroundColor: '#fff',
            }}
          >
            {grouplist.map(row => (
              <div key={row.group_id} style={{ padding: '5px' }}>
                <input
                  type="checkbox"
                  id={row.group_id}
                  value={row.group_id}
                  checked={selectedchild_attribute.includes(row.group_id)}
                  onChange={() => {
                    setselectedchildattribute(prevState =>
                      prevState.includes(row.group_id)
                        ? prevState.filter(id => id !== row.group_id)
                        : [...prevState, row.group_id]
                    );
                  }}
                />
                <label htmlFor={row.group_id} style={{ marginLeft: '5px' }}>{row.name}</label>
              </div>
            ))}
          </div>
        )}
      </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick ={closemodel} >
            Close
          </button>
          <button className="btn btn-info" onClick={savedata} >
            Save
          </button>
        </DialogActions>
       </Dialog>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AssignGroupsToEmployee;