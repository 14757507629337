import React, { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
import StockDetailsHeader from "layouts/common_components/stock_details_header";
//import axios from 'axios';


function AddStock() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [vendorlist, setvendorlist] = useState([]);
  const [categorylist, setcategorylist] = useState([]);
  const [brand_name, setbrand_name] = useState('');
  const [category,setcategory] = useState('');
  const [vendor, setvendor] = useState('');
  const [productId,setproductId] = useState('');
  const [price, setprice] = useState('');
  //const [productId,setproductId] = useState('');
  const [producttype,setproducttype] = useState('');
  const [deviceid,setdeviceid] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quantity,setquantity] = useState('');
  const[activebranch,getactivebranch] = useState([]);
  const[selectedbranch,setselectedbranch] = useState('');
  //const [data,setData] = useState([]);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

   useEffect(() => {
    const fetchData = async () => {
    try {
      const endpoint = 'active-vendor';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setvendorlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);



 useEffect(() => {
    const fetchData = async () => {
    try {
      const endpoint = 'active-category';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setcategorylist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);


 useEffect(() => {
    const branchlist = async () => {
    try {
      const endpoint = 'get-active-branch';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getactivebranch(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  branchlist();
}, []);
  const savestock = async (e) => {
    e.preventDefault();
    const errors = {};
    setValidationErrors(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('category', category);
    formData.append('brand_name', brand_name);
    formData.append('product_id', productId);
    formData.append('price', price);
    formData.append('vendor', vendor);
    formData.append('product_type', producttype);
    formData.append('device_id', deviceid);
    formData.append('emp_id',emp_id);
    formData.append('quantity',quantity);
    formData.append('branch',selectedbranch);
    setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('add-stock', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/stock-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      }
      else if (response.data.status ===201) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      }
       else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the stock',
      });
    }
   finally {
        setIsSubmitting(false);
      }
  };
}
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StockDetailsHeader/>
            <Card>
              <div className="card-body">
                <form onSubmit = {savestock}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Select category</label>
                         <select className="form-control"
                          value={category}
                          onChange={(e) => setcategory(e.target.value)}
                          >
                            <option value=''>Select Category</option>
                             {categorylist.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.category_name}
                              </option>
                              ))}
                          </select>
                          {validationErrors.category && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.category}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Brand Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value = {brand_name}
                            onChange={(event)=>{
                              setbrand_name(event.target.value)}}
                            placeholder="Enter Brand Name"
                          />
                          {validationErrors.brand_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.brand_name}</div>
                             )}
                        </div>
                      </div>
                       <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Product Id</label>
                          <input
                            type="text"
                            className="form-control"
                            value = {productId}
                            onChange={(event)=>{
                              setproductId(event.target.value)}}
                            placeholder="Enter Product Id"
                          />
                          {validationErrors.email && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.email}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      <div className="row">
                       <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Price</label>
                            <input
                            type="text"
                            className="form-control"
                            value = {price}
                            onChange={(event)=>{
                              setprice(event.target.value)}}
                           placeholder="Enter Price"
                          />
                            {validationErrors.address && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.address}</div>
                             )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Device Id</label>
                            <input
                            type="text"
                            className="form-control"
                            value = {deviceid}
                            onChange={(event)=>{
                              setdeviceid(event.target.value)}}
                           placeholder="Enter Price"
                          />

                            {validationErrors.address && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.address}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Product Type</label>
                            <select className="form-control"
                            value={producttype}
                            onChange={(event) => {
                              setproducttype(event.target.value)
                            }}
                          >
                            <option value=''>Select Product Type</option>
                            <option value='rims'>RIMS</option>
                            <option value='rental'>Rental</option>
                          </select>
                            {validationErrors.product_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product_type}</div>
                             )}
                        </div>
                      </div>
                  
                    {producttype === 'rental' && (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Select vendor</label>
                         <select className="form-control"
                          value={vendor}
                          onChange={(e) => setvendor(e.target.value)}
                          >
                            <option value=''>Select vendor</option>
                             {vendorlist.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.name}
                              </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      )}
                      <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Quantity</label>
                            <input
                            type="text"
                            className="form-control"
                            value = {quantity}
                            onChange={(event)=>{
                              setquantity(event.target.value)}}
                           placeholder="Enter Quantity"
                          />

                            {validationErrors.quantity && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.quantity}</div>
                             )}
                        </div>
                      </div>

                     

                      <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Branch</label>
                             <select className="form-control"
                            value={selectedbranch}
                            onChange={(e) => setselectedbranch(e.target.value)}
                            >
                            <option value=''>Select Branch</option>
                             {activebranch.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.branch_name}
                              </option>
                              ))}
                          </select>

                            {validationErrors.branch && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.branch}</div>
                             )}
                        </div>
                      </div>


                     </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <table>

      </table>
    </DashboardLayout>
  );
}

export default AddStock;