import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { useAbsoluteLayout } from 'react-table';
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { Bar } from 'react-chartjs-2';
//import totalcollection from '../assets/images/totalcollection.png';

const CsDashboard = () => {
  const navigate = useNavigate();
  const { sales } = reportsLineChartData;
  const [productlist, setProductlist] = useState([]);
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
  const [product, setproduct] = useState('');
  const [servicelist, setServicelist] = useState([]);
  const [service, setservice] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [grouplist, setgrouplist] = useState([]);
  const [group, setgroup] = useState('');
  const [csdata,getcsdata,] = useState('');
  const [compersionFilter, setCompersionFilter] = useState(false);
  const [cscompresion_data,getcs_compersion_data] = useState('');
  const [csemp,getcsemp] = useState([]);
  const [selectedemp,setselectedemp] = useState('');
  const [category_list,set_category_list] = useState([]);
  const [category,selectedcategory] = useState('');
  //const [color,setcolor] = useState('');
  let toastId = 0;

  const handleCheckboxChange = (checked) => {
    setCompersionFilter(checked);
  };

  useEffect(()=>{
    get_sent_percent_notification();

  },[dept_id,toastId==0])





  useEffect(()=>{
    groupdetails();
    productdetails();
    dashboard_data();
    cs_employee();
    verification_time_tat();
   

  },[])
  useEffect(()=>{
    dashboard_data_filter();

  },[product,service,group,start_date,end_date,selectedemp,category])

   useEffect(()=>{
    verification_time_tat();

  },[product,service,group,start_date,end_date,category])




  const groupdetails = async () => {
    try {
      const endpoint = 'group-details';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setgrouplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setProductlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setServicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

      const verification_time_tat = async () => {
        try {
          const response = await dashboardApi.get('lead-verification-tat', {
            params: {
              emp_id:empId,
             
            }
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }


   useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);

  const dashboard_data = async () => {
    try {
      const endpoint = 'cs-dashboard-count';
      const response = await dashboardApi.get(endpoint);


      if (response.data.status === 200) {
        getcsdata(response.data.data);



      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const cs_employee = async () => {
    try {
      const endpoint = 'cs-employee';
      const response = await dashboardApi.get(endpoint);


      if (response.data.status === 200) {
        getcsemp(response.data.data);



      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const dashboard_data_filter = async () => {
    if (!compersionFilter) {
    try {
      const response = await dashboardApi.get('cs-dashboard-count', {
        params: {
          start_date: start_date,
          end_date: end_date,
          product: product,
          service: service,
          group: group,
          emp:selectedemp,
          category:category,
        }
      });
      if (response.data.status === 200) {
        getcsdata(response.data.data);



      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  };

  const get_sent_percent_notification = async () => {
    if(toastId<1){
    try {

      toastId++;

      const endpoint = `get-sent-percent-calculation-lmart/${dept_id}/${'cs_dashboard'}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
          toastId = toast.error(response.data.message, {
              position: "top-center",
              autoClose: 30000, // 30 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              style: {
                  width: '800px',
                  maxWidth: '100%'
              }
          });
      }
  }  catch (error) {
       console.error('Error fetching data:');
      }
    }
    };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <div>
          <div className="d-flex flex-wrap">
            <div className="col-sm-4 col-6 p-1">
              <select
                className="form-control form-select"
                value={group}
                onChange={(e) => setgroup(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option>Select Group</option>
                {grouplist.map((row) => (
                  <option key={row.group_idid} value={row.group_id}>
                    {row.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-4 col-6 p-1">
              <select
                className="form-control form-select"
                value={product}
                onChange={(e) => setproduct(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option>Select Product</option>
                {productlist.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.product_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-4 col-6 p-1">
              <select
                className="form-control form-select"
                value={service}
                onChange={(e) => setservice(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Service</option>
                {servicelist.map((ser) => (
                  <option key={ser.id} value={ser.id}>
                    {ser.service_name}
                  </option>
                ))}
              </select>
            </div>



            <div className="col-sm-4 col-6 p-1">
              <select
                className="form-control form-select"
                value={category}
                onChange={(e) => selectedcategory(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Category</option>
                {category_list.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.category_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-4 col-6 p-1">
              <input
                type="date"
                value={start_date}
                onChange={(e) => setstart_date(e.target.value)}
                className="form-control"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              />
            </div>
            <div className="col-sm-4 col-6 p-1">
              <input
                type="date"
                value={end_date}
                onChange={(e) => setend_date(e.target.value)}
                className="form-control"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              />
            </div>

             <div className="col-sm-4 col-6 p-1">
              <select
                className="form-control form-select"
                value={selectedemp}
                onChange={(e) =>setselectedemp(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option>Select Emp</option>
                {csemp.map((row) => (
                  <option key={row.emp_id} value={row.emp_id}>
                    {row.emp_fname} {row.emp_lame}
                  </option>
                ))}
              </select>
            </div>



          </div>
        </div>
        <div className='d-flex flex-wrap my-3'>
          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
            <span className='btn btn-danger w-100' style={{ fontSize: '12px' }} >Call Lost : {csdata.call_count}</span>
          </div>

          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
            <span className='btn btn-danger w-100' style={{ fontSize: '12px' }} >Package Pending Return:{csdata.pending_return_lead}</span>
          </div>

          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
            <span className='btn btn-danger w-100' style={{ fontSize: '12px', }} >Float Missing: {csdata.float}</span>
          </div>

        </div>
          <div className="d-flex flex-wrap">

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
           
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/cs_dashboard/call_leads.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Call Leads</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.call_leads}</h5>
                </div>
                {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.call_leads}
                        </h5>
                      )
                    }
              </div>
             

            </div>


            <div className='col-sm-3 col-6 p-sm-2 p-1'>
           
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/totalcollection.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />

                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>CS Collection</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.collection}</h5>
                   
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.collection}
                        </h5>
                      )
                    }
                </div>
               
             

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
             
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/cs_dashboard/search.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Upcoming Float</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.float}</h5>
                   
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.float}
                        </h5>
                      )
                    }
                </div>
                
               
             </div>


            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/sale.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Lead Sale</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.lead_sale}</h5>
                   
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.lead_sale}
                        </h5>
                      )
                    }
                </div>
             

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
            
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/cs_dashboard/package_return.png`} style={{ maxWidth: '100px' }} alt="group loss" />
                  <div class="card-body text-center"> 
                      <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Package Pending Return</h5>
                      <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.pending_return_lead}</h5>
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.pending_return_lead}
                        </h5>
                      )
                    }
                </div>
             

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/cs_dashboard/verified.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>New Enquiry Verified/Return Verified</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.new_verified}/{csdata.return_verified}</h5>
                </div>
                {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.new_verified}/{cscompresion_data.return_verified}
                        </h5>
                      )
                    }
              </div>
             
            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
             

                <div className="card align-items-center pt-3 h-100" >
                  
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/call.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Call Accuracy</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}></h5>
                  </div>
                </div>
             

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/performance.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Improvement Factor</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}></h5>
                    
                  </div>
                </div>
             

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/cs_dashboard/phone-call.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Escalation Call</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.escalation_count}</h5>
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                         
                        </h5>
                      )
                    }
                </div>
              

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/dm_dashboard/marketing.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Social Media Enquiry</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.social_media_count}</h5>
                </div>
                {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.social_media_count}
                        </h5>
                      )
                    }
              </div>
            </div>

           

            <div className='col-sm-3 col-6 p-sm-2 p-1'> 
             
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/cs_dashboard/call_lost.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Call Missed</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.call_count}</h5>
                  </div>
                </div>
             
            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'> 
              
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/return.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Cs Verified Return Per Executive</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.per_executive_return}</h5>
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.per_executive_return}
                        </h5>
                      )
                    }
                </div>
              
            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'> 
              
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/sent.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Sent % Zoopgo/Lmart</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.zoopgo_percent}/{csdata.lmart_percent}</h5>
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.zoopgo_percent}/{cscompresion_data.lmart_percent}
                        </h5>
                      )
                    }
                </div>
             
            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'> 
             
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/cs_dashboard/avarage_time.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Avarage Lead Verification Time</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.verified_time_average}</h5>
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.verified_time_average}
                        </h5>
                      )
                    }
                </div>
              
            </div>

           <div className='col-sm-3 col-6 p-sm-2 p-1'> 
           <Link to='/cs-enquiry-details'>
             
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/cs_dashboard/avarage_time.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Per Day Lead Verication Time Details </h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{csdata.per_day_time}</h5>
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {cscompresion_data.per_day_time}
                        </h5>
                      )
                    }
                </div>
                </Link>
              
            </div>
            
          </div>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
    </DashboardLayout>
  );
}

export default CsDashboard;