import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import ClientDetailsHeader from 'layouts/common_components/client_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function TodayFollowup() 
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  
  const [postPerPage] = useState(10);
  const [selectFromDate, setFilterDateFrom] = useState('');
  const [selectToDate, setFilterDateTo] = useState('');
  const [dateError, setDateError] = useState('');

  const [exeDetails, setExeDetails] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);
  const [selectedMobileNo,setClientMobileNumber]=useState();
  const [selectedEmp, setSelectedEmp] = useState('');
  const [selectedDispostion, setDispostion] = useState('');
  const [followupStatus, setFollowupStatus] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  
  

  
  
  const getExeDetails = async () =>
  {
    try{
      const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
      if (response.data.status === 200) {
        setExeDetails(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }catch (error){
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

  useEffect(()=>
  {
    if(selectedDispostion)
    {
      const getFollowupStatus = async () =>
      {
        try
        {
          const response = await dashboardApi.get(`get-client-followup-status/${selectedDispostion}`);
          if (response.data.status === 200)
          {
            setFollowupStatus(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        }
        catch (error)
        {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getFollowupStatus();
    }
  },[selectedDispostion]);

  
  

    useEffect(() => {
      getExeDetails();
     
      if (!token) {
        navigate("/sign-in");
      }
    }, [token, navigate]);

   useEffect(()=>{
    if(emp_id)
    {
      const getClientDetails = async () => {
        try {
          const response = await dashboardApi.get(`get-today-followup-client-details`,{
            params: {
              emp_id,
              from_date: selectFromDate,
              to_date: selectToDate,
              per_page: postPerPage,
              page: currentPage + 1,
            }
            });
          if (response.data.status === 200) {
            const responseData = response.data.data;
            const clientData = responseData.data || responseData;
            setClientDetails(clientData);
            setNumber(responseData.last_page);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getClientDetails();
    }
  },[emp_id,currentPage]);

  useEffect(()=>{
    if(selectedEmp | selectedMobileNo | selectedStatus | currentPage)
    {
      const getFindClientDetails = async () => {
        try {
          const response = await dashboardApi.get(`get-today-followup-client-details`,{
            params:{
              emp_id:selectedEmp,
              mobileNo:selectedMobileNo,
              selectedStatus:selectedStatus,
              per_page: postPerPage,
              page: currentPage + 1,
            }
          });
          if (response.data.status === 200) {
            const responseData = response.data.data;
            const clientData = responseData.data || responseData;
            setNumber(responseData.last_page);
            setClientDetails(clientData);
           
          } else {
            console.error('Error fetching countries:',response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getFindClientDetails();
  
  }},[selectedEmp,selectedMobileNo,selectedStatus,currentPage]);


  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                    <div class="col-sm-12">
                      <ClientDetailsHeader></ClientDetailsHeader>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-2'>
                      <label>Enter Mobile No</label>
                      <input type="text" className='form-control' value={selectedMobileNo} onChange={(e)=>{setClientMobileNumber(e.target.value)}}/>
                    </div>
                    <div className='col-sm-2'>
                        <label>Select Eexecutive</label>
                        <select className="form-control"
                          value={selectedEmp}
                          onChange={(e) => setSelectedEmp(e.target.value)}
                          >
                          <option value=''>Select Eexecutive</option>
                          {exeDetails.map((ed) => (
                          <option key={ed.emp_id} value={ed.emp_id}>
                          {ed.emp_fname} {ed.emp_lname}
                          </option>
                          ))}
                        </select>
                    </div>

                    <div className='col-sm-2'>
                        <label>Dispostion</label>
                        <select className="form-control" value={selectedDispostion} onChange={(e)=>{setDispostion(e.target.value)}}>
                          <option value="">Select Dispostion</option>
                          <option value="Connected">Connected</option>
                          <option value="Not_Connected">Not Connected</option>
                        </select>
                    </div>

                    {selectedDispostion &&
                      <div className='col-sm-2'>
                        <label>Select Status</label>
                        <select className="form-control"
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e.target.value)}
                          >
                          <option value=''>Select Status</option>
                          {followupStatus && followupStatus.map((fs) => (
                          <option key={fs.id} value={fs.id}>{fs.activity_name}
                          </option>
                          ))}
                        </select>
                      </div>
                    }
                  </div>

                  {loading ? (
                    <p style={{align:'center'}}>
                    <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                    </p>
                ) : (
                  <div style={{overflowY: "hidden"}}>
                    <table className="table table-bordered table-hovered" style = {{marginTop:'20px'}}>
                    <tr>
                      <td>S.No</td>
                      <td>Client Name</td>
                      <td>Business Name</td>
                      <td>Mobile No</td>
                      <td>Email Id</td>
                      <td>Status</td>
                      {/* <td>City</td> */}
                      <td>Product</td>
                      <td>Service</td>
                      <td>Category</td>
                      <td>Status</td>
                      <td>Action</td>
                    </tr>

                    {clientDetails && clientDetails.map((cd,index)=>(
                      <tr>
                          <td>{index + 1}</td>
                          <td>{cd.name}</td>
                          <td>{cd.business_name}</td>
                          <td>{cd.mobile_no}</td>
                          <td>{cd.email_id}</td>
                          <td>{cd.activity_name}</td>
                          {/* <td>{cd.group_name}</td> */}
                          <td>{cd.product_name}</td>
                          <td>{cd.service_name}</td>
                          <td>{cd.category_name}</td>
                          <td>{cd.status === 1 ?'Active':'Deactive'}</td>
                          <td>
                            {cd.client_type == 1 &&
                            <Link className="btn btn-primary mb-2 float-end"  to={`/client-profile/${cd.id}`}>
                              <span class="material-icons-outlined" alt='view guest profile'>manage_accounts</span>
                            </Link>
                            }
                            
                            {cd.client_type == 2 &&
                              <Link className="btn btn-primary mb-2 float-end"  to={`/mature-client-profile/${cd.client_id}`}>
                                <span class="material-icons-outlined" alt='view mature profile'>manage_accounts</span>
                              </Link>
                            }

                            {/* <Link className='btn btn-info' onClick={followupOpenControl}>
                              Followup
                            </Link> */}
                          </td>
                      </tr>
                      ))
                    }
                    <tfoot>
                      <tr>
                        <td colSpan="12">
                          <Pagination className="custom-pagination">
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={number}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link-style"}
                              breakLinkClassName={"break-link-style"}
                              previousLinkClassName={"prev-link-style"}
                              nextLinkClassName={"next-link-style"}
                              forcePage={currentPage}
                            />
                          </Pagination>
                        </td>
                      </tr>
                    </tfoot>
                </table>
              </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TodayFollowup;
