
import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate,useParams,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

//const [loading, setLoading] = useState(true);

function EditState() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams()
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [stateName, setStateName] = useState('');
  const [stateShortName, setStateShortName] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  //console.log(selectedCountry);
   useEffect(() => {
   const fetchData = async () => {
    try {
      const endpoint = 'get-active-country'; 
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setOptions(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
    useEffect(()=>{
      fetchState()
  },[])
  const fetchState = async () => {
    await dashboardApi.get(`state-edit/${id}`).then(({data})=>{
      const {state_name,country_id, state_short_name } = data.state
      setStateName(state_name)
      setStateShortName(state_short_name)
      setSelectedCountry(country_id);


    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }
  const updatestate = async (e) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('name', stateName)
    formData.append('country', selectedCountry)
    formData.append('short_name', stateShortName)
    try {
      const response = await dashboardApi.post(`update-state/${id}`, formData);
      //console.log(response);

      if (response.data.status === 200) {
        //alert();
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/state-list")
        //console.log('Success: Popup displayed'); //
         //navigate("/state-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the state',
      });
    }
  };

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div style={{float:'right'}}>
              <Link className="btn btn-info" to="/state-list">Back</Link>

            </div>
              <div className="card-body">
                <form onSubmit={updatestate}>
                  <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Select Country</label>
                          <select className="form-control"
                          value={selectedCountry}
                          onChange={(e) => setSelectedCountry(e.target.value)}
                          >
                            <option value=''>Select Country</option>
                             {options.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.name}
                              </option>
                              ))}
                          </select>
                          {validationErrors.country && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.country}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>State Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="state-name"
                            placeholder="Enter State Name"
                            value={stateName}
                            onChange={(e) => setStateName(e.target.value)}
                          />
                          {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>State Short Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="state-short-name"
                            placeholder="Enter State Short Name"
                            value={stateShortName}
                            onChange={(e) => setStateShortName(e.target.value)}
                           />
                           {validationErrors.short_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.short_name}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                           Update
                        </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditState;
