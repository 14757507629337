import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate, useNavigate,useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import Card from "@mui/material/Card";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';

const RegularClient = () => {
  const navigate = useNavigate();
  const { sales, tasks } = reportsLineChartData;
  const empId = secureLocalStorage.getItem('emp_id');
  const [loading, setLoading] = useState(true);
  const [product, setproduct] = useState('');
  const [emp_list, getemp_list] = useState([]);
  const [selectedmanagers, setselectedmanagers] = useState();
  const [sales_data, getsales_data] = useState({});
  const [managersteam,getmanagersteam] = useState([]);
  const [selectedemp,setselectedemp] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const [followup_status,get_followup_details] = useState([]);
  const {details} = useParams();
  const [inner_page_details,get_inner_page_details]  = useState([]);
  const [sum,get_sum] = useState();
  const [manager_team,get_manager_team] = useState([]);
  const[checkmanager,setcheckmanager] = useState('');
  const [grouplist, setgrouplist] = useState([]);
  const [group, setgroup] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;

  useEffect(() => {
    productdetails();
   // fetchdata();
    get_emp_list();
    sales_inner_page_details();
     get_sales_manager_team();
     groupdetails();
  }, []);

   useEffect(() => {
    sales_inner_page_filter();
  }, [selectedmanagers,selectedemp,group]);

  const get_sales_manager_team = async()=>{
     try {
      const endpoint = `sales-dashboard-show-manager-team/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        get_manager_team(response.data.data);
        setcheckmanager(response.data.reporting_manager);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  const groupdetails = async () => {
    try {
      const endpoint = 'group-details';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setgrouplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_emp_list = async () => {
    try {
      const endpoint = 'get-sales-managers';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getemp_list(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

  useEffect(() => {
    if (selectedmanagers) {
      setselectedemp([]);

      const fetchData = async () => {
        try {
          const endpoint = `get-all-manager-employee/${selectedmanagers}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            getmanagersteam(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [selectedmanagers]);


  const sales_inner_page_details = async (page=0) => {
    setLoading(true);
    try {
      const response = await dashboardApi.get('get-regular-client-details', {
        params: {
          emp_id: empId,
          manager:selectedmanagers,
          employee:selectedemp,
          type:details,
          group:group,
          page: page + 1
        },
      });
      if (response.data.status === 200) {
        //const responseData = response.data.data;
          get_inner_page_details(response.data.data.data || response.data.data);
          setTotalPages(response.data.data.last_page || 1);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const sales_inner_page_filter = async (page=0) => {
    setLoading(true)
    try {
      const response = await dashboardApi.get('get-regular-client-details', {
        params: {
          emp_id: empId,
          manager:selectedmanagers,
          employee:selectedemp,
          type:details,
          group:group,
          page: page + 1
        },
      });
      if (response.data.status === 200) {
          get_inner_page_details(response.data.data.data || response.data.data);
          setTotalPages(response.data.data.last_page || 1);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handlePageClick = (selected) => {
    console.log(selected);
    setCurrentPage(selected.selected);
  };

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <div>
            <div className="d-flex flex-wrap">
            {empId === 'RIMS1' && (
               <>
             <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={selectedmanagers}
                  onChange={(e) => setselectedmanagers(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Managers</option>
                   {emp_list.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                     {row.emp_fname} {row.emp_lame}
                    </option>
                    ))}
                  </select>
                  </div>

                  <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={selectedemp}
                  onChange={(e) => setselectedemp(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Team</option>
                   {managersteam.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                     {row.emp_fname} {row.emp_lame}
                    </option>
                    ))}
                  </select>
                  </div>
                 </>
                 )}

               {checkmanager === 1 && (
                <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={selectedemp}
                  onChange={(e) => setselectedemp(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Team</option>
                   {manager_team.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                     {row.emp_fname} {row.emp_lame}
                    </option>
                    ))}
                  </select>
                  </div>

               )}


            </div>
          </div>

          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered table-hovered">
                  <thead>
                    <tr>
                      <td style ={{fontSize:'12px'}}>Client ID</td>
                      <td style={{fontSize:'12px'}}>Group</td>
                      <td style={{fontSize:'12px'}}>Client Name</td>
                      <td style={{fontSize:'12px'}}>No Of Packages</td>
                      <td style={{fontSize:'12px'}}>Last Package Buy Date</td>
                      <td style={{fontSize:'12px'}}>Exce Name</td>
                      <td style={{fontSize:'12px'}}>Manager</td>
                      <td style={{fontSize:'12px'}}>Client Created date</td>
                    </tr>
                  </thead>
                  <tbody>
                    {inner_page_details.map((row, index) => (
                      <tr key={row.id}>
                        <td style={{fontSize:'12px'}}>{row.client_id}</td>
                        <td style={{fontSize:'12px'}}>{row.group}</td>
                        <td style={{fontSize:'12px'}}>{row.client_name}</td>
                        <td style={{fontSize:'12px'}}>{row.package_count}</td>
                        <td style={{fontSize:'12px'}}>{row.last_package_date}</td>
                        <td style={{fontSize:'12px'}}>{row.exe_fname} {row.exe_lname}</td>
                        <td style={{fontSize:'12px'}}>{row.manager_fname} {row.manager_lname}</td>
                        <td style={{fontSize:'12px'}}>{row.created_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {inner_page_details.map.length > 0 && (
            <Pagination className="custom-pagination">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link-style"}
                breakLinkClassName={"break-link-style"}
                previousLinkClassName={"prev-link-style"}
                nextLinkClassName={"next-link-style"}
              />
            </Pagination>
          )}
        </Card>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default RegularClient;
