import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import Swal from 'sweetalert2';
import { useNavigate,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function AddCompany() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const[options, setOptions] = useState([]);
  const[selectedCountry, setselectedCountry] = useState('');
  const[states, setStates] = useState([]);
  const[permission,Setpermission] = useState([]);
  const[selectedState,setselectedState] = useState('');
  const[businessName,setbusinessName] = useState('');
  const[clientName,setclientName] = useState('');
  const[firstmobile,setfirstmobile] = useState('');
  const[secondmobile,setsecondmobile] = useState('');
  const[firstemail,setfirstemail] = useState('');
  const[secondemail,setsecondemail] = useState('');
  const[zipcode,setzipcode] = useState('');
  const[address,setaddress] = useState('');
  const[url,seturl] = useState('');
  const[shortname,setshortname] = useState('');
  const[logo,setlogo] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-country'; // Replace with the actual endpoint
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setOptions(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

 useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = `get-permission/${emp_id}`;
      const response = await dashboardApi.get(endpoint);
      console.log(response);

      if (response.data.status === 200) {
        Setpermission(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

  useEffect(() => {
  if (selectedCountry) {
    const fetchData = async () => {
      try {
        const endpoint = `country-based-state/${selectedCountry}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setStates(response.data.data);
        } else {
          console.error('Error fetching states data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching states data:', error);
      }
    };

    fetchData();
  }
}, [selectedCountry]);
  const savecompany = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!businessName) {
      errors.business_name = 'Business Name is required';
    }
    if (!clientName) {
      errors.client_name = 'Client Name is required';
    }
    if (!firstmobile) {
      errors.first_mobile_no = 'Mobile no is required';
    }
    if (!firstemail) {
      errors.first_email_id = 'Email is required';
    }
     if (!zipcode) {
      errors.zip_code = 'Zip code is required';
    }
     if (!address) {
      errors.full_address = 'Address is required';
    }
     if (!url) {
      errors.website_url = 'Url is required';
    }
    if(!logo){
      errors.company_logo = 'Logo is required';

    }
    if(!shortname){
      errors.short_name = 'Short Name is required';

    }
    if(!selectedCountry){
      errors.country_id = 'Country is required';

    }
    if(!selectedState){
      errors.state_id = 'State is required';

    }

    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('business_name', businessName);
    formData.append('client_name', clientName);
    formData.append('first_mobile_no', firstmobile);
    formData.append('second_mobile_no', secondmobile);
    formData.append('first_email_id', firstemail);
    formData.append('second_email_id', secondemail);
    formData.append('zip_code', zipcode);
    formData.append('full_address', address);
    formData.append('website_url', url);
    formData.append('short_name', shortname);
    formData.append('company_logo', logo);
    formData.append('country_id', selectedCountry);
    formData.append('state_id', selectedState);
    setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('save-company', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/company-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
    finally {
        setIsSubmitting(false);
      }
  };
}
 // console.log(logo);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div style={{float:'right'}}>
              <Link className="btn btn-info" to="/company-list">Back</Link>

            </div>
              <div className="card-body">
                <form onSubmit={savecompany}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="bussiness name" style={{ fontSize: '16px' }}>Business Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="Business-name"
                            placeholder="Enter Business Name"
                            value = {businessName}
                            onChange={(event)=>{
                              setbusinessName(event.target.value)}}
                            
                          />
                          {validationErrors.business_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.business_name}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Company Short Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="company-short-name"
                            placeholder="Enter Company Short Name"
                            value = {shortname}
                            onChange={(event)=>{
                              setshortname(event.target.value)}}
                            
                          />
                          {validationErrors.short_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.short_name}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Client Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="client-name"
                            placeholder="Enter Client Name"
                            value = {clientName}
                            onChange={(event)=>{
                              setclientName(event.target.value)}}
                            
                          />
                          {validationErrors.client_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.client_name}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Primary Mobile No</label>
                          <input
                            type="text"
                            className="form-control"
                            id="primary-mobile"
                            placeholder="Enter First Mobile No"
                            value = {firstmobile}
                            onChange={(event)=>{
                              setfirstmobile(event.target.value)}}
                              
                          />
                          {validationErrors.first_mobile_no && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.first_mobile_no}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Secondry Mobile No</label>
                          <input
                            type="text"
                            className="form-control"
                            id="second-mobile"
                            placeholder="Enter Second Mobile No"
                            value = {secondmobile}
                            onChange={(event)=>{
                              setsecondmobile(event.target.value)}}
                          />
                           {validationErrors.second_mobile_no && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.second_mobile_no}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Primary Email Id</label>
                          <input
                            type="text"
                            className="form-control"
                            id="first-email"
                            placeholder="Enter First Email Id"
                            value = {firstemail}
                            onChange={(event)=>{
                              setfirstemail(event.target.value)}}
                            
                          />
                           {validationErrors.first_email_id&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.first_email_id}</div>
                             )}
                        </div>
                      </div>

                      </div>
                      <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Second Email Id</label>
                          <input
                            type="text"
                            className="form-control"
                            id="second-email"
                            placeholder="Enter Second Email Id"
                            value = {secondemail}
                            onChange={(event)=>{
                              setsecondemail(event.target.value)}}
                          />
                          {validationErrors.second_email_id&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.second_email_id}</div>
                             )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px'}}>Select Country</label>
                          <select className="form-control"
                           value={selectedCountry}
                           onChange={(e) => setselectedCountry(e.target.value)}
                            >
                            <option value=''>Select Country</option>
                            {options.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.name}
                              </option>
                              ))}
                              
                          </select>
                           {validationErrors.country_id&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.country_id}</div>
                             )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px'}}>Select State</label>
                          <select className="form-control" 
                          value={selectedState}
                           onChange={(e) => setselectedState(e.target.value)}
                           >
                           <option value=''>Select State</option>
                            {states ? (
                             states.map((state) => (
                             <option key={state.id} value={state.id}>
                             {state.state_name}
                            </option>
                            ))
                           ) : (
                            <option disabled>No states available for the selected country</option>
                           )}
                          </select>
                           {validationErrors.state_id&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.state_id}</div>
                             )}
                        </div>
                      </div>
                     </div>
                     <div className="row">
                     <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize:'16px' }}>Zip Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="zip-code"
                            placeholder="Enter Zip Code"
                            value={zipcode}
                            onChange={(e) => setzipcode(e.target.value)}
                          />
                           {validationErrors.zip_code&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.zip_code}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize:'16px' }}>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            placeholder="Enter Address"
                            value={address}
                            onChange={(e) => setaddress(e.target.value)}
                          />
                          {validationErrors.full_address&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.full_address}</div>
                             )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize:'16px' }}>Website Url</label>
                          <input
                            type="text"
                            className="form-control"
                            id="url"
                            placeholder="Enter Website Url"
                            value={url}
                            onChange={(e) => seturl(e.target.value)}
                          />
                          {validationErrors.website_url&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.website_url}</div>
                             )}
                        </div>
                      </div>

                     </div>
                     <div className="row">
                     <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="last name" style={{ fontSize: '16px' }}>Upload Logo</label>
                          <input
                            type="file"
                            className="form-control"
                            id="logo"
                            onChange={(e) => setlogo(e.target.files[0])}
                          />
                          {validationErrors.company_logo&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.company_logo}</div>
                             )}
                        </div>
                      </div>

                     </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                  
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddCompany;
