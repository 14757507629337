import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

//const [loading, setLoading] = useState(true);

function AddWorkingDay() {
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate('');
  const [date, setdate] = useState();
  const [reason, setreason] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
   useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  const saveworkingday = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!date) {
      errors.date = 'Date is required';
    }
    if (!reason) {
      errors.reason = 'Reason is required';
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('date', date);
    formData.append('reason',reason);
    try {
      const response = await dashboardApi.post('create-working-day', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/working-day-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
  };
}

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <Link className='btn btn-info' to='/working-day-list'>
                  Back
                </Link>
                <form onSubmit={saveworkingday}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Select Date</label>
                          <input
                            type="date"
                            className="form-control"
                            value={date}
                            onChange={(e) => setdate(e.target.value)}
                          />
                          {validationErrors.date && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.date}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Enter Reason</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Reason"
                            value={reason}
                            onChange={(e) => setreason(e.target.value)}
                           />
                           {validationErrors.reason && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.reason}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                           Save
                        </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddWorkingDay;
