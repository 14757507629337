import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,Link } from "react-router-dom";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import PackageDetailsHeader from "layouts/common_components/package_details_header";
import  secureLocalStorage  from  "react-secure-storage";

// for modalbox
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { TextField } from "@mui/material";
import Swal from "sweetalert2";



function PackageDetails()
{
    const navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({});
    const emp_id = secureLocalStorage.getItem('emp_id');
    const token = secureLocalStorage.getItem('token');
    const [duration_name,setDurationName] = useState('');
    const [durationModal,setDurationModal] = useState(false);
    const [duration_price,setDurationPrice] = useState('');
    const [packageTypeModal,setPackageTypeModal] = useState(false);
    const [durationListing,setDurationListing] = useState([]);
    const [packageTypeList,setPackageTypeList] = useState([]);
    const [package_type,setPackageType] = useState('');
    const [type_price, setTypePrice] = useState('');
    
    
    useEffect(()=>{
        if(!token)
        {
            navigate('/sign-in');
        }
        else
        {
            getDurationList();
            getPackageTypeList();
        }
    },[token, navigate]);

    const durationModalOpenControl = ()=>{
        setDurationModal(true);
    }

    const packageModalOpenControl = ()=>{
        setPackageTypeModal(true);
    }

    const durationModalCloseControl = ()=>{
        setDurationModal(false);
    }

    const packageModalCloseControl = ()=>{
        setPackageTypeModal(false);
    }

    // Add New Duration Attribute
    const durationSubmit = async() =>
    {
        const errors = {};
        if (Object.keys(errors).length > 0) {
        return; 
        }
        const formData = new FormData();
        formData.append('name',duration_name);
        formData.append('price',duration_price)
        formData.append('created_by',emp_id);
        formData.append('fact_type',1);
        try {
        const response = await dashboardApi.post('add-duration-type-factor', formData);

        if (response.data.status === 200) {
            Swal.fire({
            icon: 'success',
            text: response.data.message,
            });
            navigate("/package-details");
            durationModalCloseControl();

        } else if (response.data.status === 400) {
            setValidationErrors(response.data.messages);
        } else {
            Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
            });
        }
        } catch (error) {
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
        } 
    }

    const packageTypeSubmit = async() =>
    {
        const errors = {};
        if (Object.keys(errors).length > 0) {
        return; 
        }
        const formData = new FormData();
        formData.append('name',package_type);
        formData.append('price',type_price);
        formData.append('created_by',emp_id);
        formData.append('fact_type',2);

        try {
        const response = await dashboardApi.post('add-duration-type-factor', formData);

        if (response.data.status === 200) {
            Swal.fire({
            icon: 'success',
            text: response.data.message,
            });
            navigate("/package-details");
            durationModalCloseControl();

        } else if (response.data.status === 400) {
            setValidationErrors(response.data.messages);
        } else {
            Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
            });
        }
        } catch (error) {
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
        } 
    }

    const getDurationList = async() =>{
        let type='all';
        const durationResponce = await dashboardApi.get(`get-duration-list/${type}`);
        if(durationResponce.status==200)
        {
            setDurationListing(durationResponce.data.data);
        }
        else
        {
            console.log('something went wrong,Please Try Again');
        }
    }

    const getPackageTypeList = async() =>{
        let type='all';
        const durationResponce = await dashboardApi.get(`get-package-type-list/${type}`);
        if(durationResponce.status==200)
        {
            setPackageTypeList(durationResponce.data.data);
        }
        else
        {
            console.log('something went wrong,Please Try Again');
        }
    }

    return(
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-sm-11">
                                            <PackageDetailsHeader></PackageDetailsHeader>
                                        </div>
                                        <div class="col-sm-1">
                                        <Link className='btn btn-info' onClick={()=>durationModalOpenControl()}>
                                            +
                                        </Link>
                                        </div>
                                    </div>
                                    
                                    <div className="row" style={{marginTop:'1%'}}>
                                        <div class="col-sm-12"><h4>Duration Factor Details</h4></div>
                                    </div>

                                    <table className="table table-bordered table-hovered">
                                        <tr>
                                            <td>S.No</td>
                                            <td>Duration Type</td>
                                            <td>Base Price</td>
                                            <td>Status</td>
                                            <td>Created By</td>
                                        
                                            <td>Action</td>
                                        </tr>
                                        
                                        {durationListing.map((durationListing,index)=>(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{durationListing.name}</td>
                                            <td>{durationListing.fact_price}</td>
                                            <td>{durationListing.status}</td>
                                            <td>{durationListing.created_by}</td>
                                            
                                            <td><button className="btn btn-primary btn-sm">View</button></td>
                                        </tr>
                                        ))
                                        }
                                    </table>
                                </div>
                            </Card>
                            
                            <Card style = {{marginTop:'2%'}}>
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-sm-11">
                                            <h4>Package Type Factor Details</h4>
                                        </div>
                                        <div class="col-sm-1">
                                        <Link className='btn btn-info' onClick={()=>packageModalOpenControl()}>
                                            +
                                        </Link>
                                        </div>
                                    </div>
                                    
                                    <table className="table table-bordered table-hovered" style={{marginTop:'1%'}}>
                                        <tr>
                                            <td>S.No</td>
                                            <td>Package Type</td>
                                            <td>Base Price</td>
                                            <td>Status</td>
                                            <td>Created By</td>
                                          
                                            <td>Action</td>
                                        </tr>
                                        {packageTypeList.map((packageTypeList,index)=>(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{packageTypeList.name}</td>
                                            <td>{packageTypeList.fact_price}</td>
                                            <td>{packageTypeList.status}</td>
                                            <td>{packageTypeList.created_by}</td>
                                          
                                            
                                            
                                            <td><button className="btn btn-primary btn-sm">View</button></td>
                                        </tr>
                                        ))
                                        }
                                        
                                    </table>
                                </div>
                            </Card>

                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />

                <Dialog open={durationModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '20px' } }}>
                    <DialogTitle> Add New Package Duration Factor </DialogTitle>
                        <DialogContent>
                            <form>
                                <div className="col-sm-12" style={{marginTop:'5px'}}>
                                    <label>Duration Name</label>   
                                    <TextField type="text" className="form-control" value={duration_name} onChange={(e) =>setDurationName(e.target.value)}></TextField>
                                </div>

                                <div className="col-sm-12" style={{marginTop:'5px'}}>
                                    <label>Price</label>   
                                    <TextField type="text" className="form-control" value={duration_price} onChange={(e) =>setDurationPrice(e.target.value)}></TextField>
                                </div>

                            </form>
                        </DialogContent>

                        <DialogActions>
                            <Link className="btn btn-primary btn-sm" onClick={durationModalCloseControl}>Close</Link>
                            <Link className="btn btn-success btn-sm" onClick={packageTypeSubmit}>Add Duration</Link>
                        </DialogActions>
                   
                </Dialog>


                <Dialog open={packageTypeModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '20px' } }}>
                    <DialogTitle> Add New Package Type Factor </DialogTitle>
                        <DialogContent>
                            <form>
                                <div className="col-sm-12" style={{marginTop:'5px'}}>
                                    <label>Package Type</label>   
                                    <TextField type="text" className="form-control" value={package_type} onChange={(e) =>setPackageType(e.target.value)}></TextField>
                                </div>

                                <div className="col-sm-12" style={{marginTop:'5px'}}>
                                    <label>Price</label>   
                                    <TextField type="text" className="form-control" value={type_price} onChange={(e) =>setTypePrice(e.target.value)}></TextField>
                                </div>

                            </form>
                        </DialogContent>

                        <DialogActions>
                            <Link className="btn btn-primary btn-sm" onClick={packageModalCloseControl}>Close</Link>
                            <Link className="btn btn-success btn-sm" onClick={durationSubmit}>Add Duration</Link>
                        </DialogActions>
                   
                </Dialog>

                


        </DashboardLayout>
    );
}
export default PackageDetails;