import React, { useState } from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";
import './company.css';

function Company() {
    const [company, setCompany] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchCompany();
    }, []);

    const fetchCompany = async () => {
        try {
            const response = await dashboardApi.get('company-list');
            if (response.data.status === 200) {
                setCompany(response.data.data);
            } else {
                console.error('Error fetching companies:', response.data.message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setLoading(false);
        }
    };
    const toggleStatus = async (companyId) => {
      const isConfirm = await Swal.fire({
              title: 'Are you sure?',
              text: "Are You Want To Change Status!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Change it!'
            }).then((result) => {
              return result.isConfirmed
            });
  
            if(!isConfirm){
              return;
            }
      const response = await dashboardApi.get(`company-status/${companyId}`);
      if (response.data.status === 200) {
        const currentStatus = response.data.data;
        console.log(response.data.data);
        // Calculate the new status (toggle)
        const newStatus = currentStatus === 1 ? 0 : 1;
        // Make an API call to update the status
        const updateResponse = await dashboardApi.post('company-change-status', {
          company_id: companyId,
          status: newStatus,
        });
        if (updateResponse.data.status === 200) {
          // Update the local state with the new status
          setCompany((prevState) =>
            prevState.map((companyObj) =>
              companyObj.id === companyId ? { ...companyObj, status: newStatus } : companyObj
            )
          );
        } else {
          console.error('Error updating status:', updateResponse.data.message);
        }
      } else {
        console.error('Error fetching current status:', response.data.message);
      }
    };

    const zoomIn = (event) => {
        const container = event.currentTarget;
        const contentElement = container.querySelector('img, iframe');

        if (contentElement) {
            container.style.position = 'relative';
            container.style.zIndex = '1';
            container.style.overflow = 'visible';

            contentElement.style.position = 'absolute';
            contentElement.style.top = '50%';
            contentElement.style.left = '50%';
            contentElement.style.transform = 'translate(-50%, -50%) scale(14)';
            contentElement.style.transformOrigin = 'center center';
        }
    };

    const zoomOut = (event) => {
        const container = event.currentTarget;
        const contentElement = container.querySelector('img, iframe');

        if (contentElement) {
            container.style.position = '';
            container.style.zIndex = '';
            container.style.overflow = '';

            contentElement.style.position = '';
            contentElement.style.top = '';
            contentElement.style.left = '';
            contentElement.style.transform = 'scale(1)';
            contentElement.style.transformOrigin = '';
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                        <div style={{float:'right'}}>
                              <Link className="btn btn-info" to="/add-company">+</Link>
                              </div>
                            <div className="card-body">
                                <table className="table table-bordered table-hovered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Business Name</th>
                                            <th>Client Name</th>
                                            <th>Email</th>
                                            <th>Company Logo</th>
                                            <th>Phone</th>
                                            <th>Country</th>
                                            <th>State</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan="10">Loading...</td>
                                            </tr>
                                        ) : (
                                            company.map((companyObj, index) => (
                                                <tr key={companyObj.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{companyObj.business_name}</td>
                                                    <td>{companyObj.client_name}</td>
                                                    <td>{companyObj.primary_email_id}</td>
                                                    <td>
                                                        <div className="image-container" onClick={zoomIn} onMouseLeave={zoomOut}>
                                                            {companyObj.company_logo.endsWith('.pdf') ? (
                                                              <a
                                                                href={`http://192.168.1.253:4000/logoimage/${companyObj.company_logo}#toolbar=0`}
                                                                download="term_and_condition.pdf"
          
                                                               >
                                                              <img
                                                                src={`http://192.168.1.253:4000/logoimage/${companyObj.company_logo}#toolbar=0`}
                                                                alt="Term and Condition"
                                                                style={{ maxWidth: '80px', height: '80px', cursor: 'pointer' }}
                                                               />
                                                              </a>                                                            ) : (
                                                                <img src={`http://192.168.1.253:4000/logoimage/${companyObj.company_logo}`} alt="Company Logo" style={{ width: '100px', height: 'auto' }} />
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>{companyObj.primary_mobile_no}</td>
                                                    <td>{companyObj.country_name}</td>
                                                    <td>{companyObj.state_name}</td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-${companyObj.status === 1 ? 'success' : 'danger'}`}
                                                            onClick={() => toggleStatus(companyObj.id)}
                                                        >
                                                            {companyObj.status === 1 ? 'Active' : 'Inactive'}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <Link to={`/edit-company/${companyObj.id}`} className='btn btn-success me-2'>
                                                            Edit
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Company;
