import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import StockDetailsHeader from "layouts/common_components/stock_details_header";

function StockHistory() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const{id} = useParams();

  const token = secureLocalStorage.getItem('token');
  const [requestList, setRequestList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stocklist,setstocklist] = useState([]);

  

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchStock();

  },[])

    const fetchStock = async () => {
      try {
        const endpoint = `stock-history/${id}`;
        const response = await dashboardApi.get(endpoint);
        if (response.data.status === 200) {
            setstocklist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

  

 
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <StockDetailsHeader/>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Assign To</td>
                        <td>Assign Date</td>
                        <td>Return Date</td>
                        <td>Return Status</td>
                        <td>Status</td>
                        <td>created By</td>
                      </tr>
                    </thead>
                    <tbody>
                      {stocklist.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.assign_to}</td>
                          <td>{row.assign_date}</td>
                          <td>{row.return_date}</td>
                          <td>{row.return_status}</td>
                          <td>{row.status}</td>
                          <td>{row.created_by}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StockHistory;