import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

//const [loading, setLoading] = useState(true);

function Add_followup_details() {
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [dispostionName, setDispositionName] = useState('');
  const [activityName, setActivityName] = useState('');
  const [activityHoldingDays, setHoldingDays] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
   useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  //console.log(selectedCountry);
   useEffect(() => {
   const fetchData = async () => {
    try {
      const endpoint = 'get-active-country'; 
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setOptions(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

  const savestate = async (e) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('dispostionName', dispostionName)
    formData.append('name', activityName)
    formData.append('holding_days', activityHoldingDays)
     setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('add-followup-status', formData);
      //console.log(response);

      if (response.data.status === 200) {
        //alert();
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        //navigate("/state-list")
        //console.log('Success: Popup displayed'); //
         //navigate("/state-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the state',
      });
    }
    finally {
        setIsSubmitting(false);
      }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <form onSubmit={savestate}>
                  <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">

                          <label htmlFor="country" style={{ fontSize: '16px' }}>Disposition</label>
                          <select className="form-control" value={dispostionName}
                            onChange={(e) => setDispositionName(e.target.value)}>
                            <option value=''>Select Disposition</option>
                            <option value='Connected'>Connected</option>
                            <option value='Not_Connected'>Not Connected</option>
                          </select>
                            {validationErrors.dispostionName && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.dispostionName}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Activity Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="activity-name"
                            placeholder="Enter Activity Name"
                            value={activityName}
                            onChange={(e) => setActivityName(e.target.value)}
                          />
                          {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Holding Days</label>
                          <input
                            type="text"
                            className="form-control"
                            id="holding_days"
                            placeholder="Enter Holding Days"
                            value={activityHoldingDays}
                            onChange={(e) => setHoldingDays(e.target.value)}
                           />
                           {validationErrors.holding_days && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.holding_days}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Add_followup_details;
