import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function EmpBirthday() {
  const navigate = useNavigate();
  const [birthday, getBirthday] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = secureLocalStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `show-emp-birthday`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          getBirthday(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Card>
      <MDBox display="flex" flexDirection="column" p={3}>
        <span className="material-icons">cake</span>
        <ul style = {{listStyleType:'none'}}>
          {birthday.map((birth, index) => (
            <li key={birth.id} >
              <div>{birth.birth_date}</div>
              <div>
                <span style={{ color: 'green' }}>{birth.name}</span><span style={{ float: 'right' }}>{birth.diff}</span>
              </div>
              {index < birthday.length - 1 && <hr />}
            </li>
          ))}
        </ul>
      </MDBox>
    </Card>
  );
}

export default EmpBirthday;
