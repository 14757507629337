import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import Finance_details from "./layouts/finance";
import Approved_amount from "layouts/finance/approved-amount";
import Payment_history from "layouts/finance/payment-history";
import Ledger_details from "layouts/finance/ledger-details";
import  secureLocalStorage  from  "react-secure-storage";
const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];

const financeroute = [
{
      type: "collapse",
      name: "Finance",
      key: "Finance",
      icon: <Icon fontSize="small">account_tree</Icon>,
      nested: [
        {
            route: "/finance-admin",
            name: "Finance Admin",
            key: "Finance Admin",
            icon: <Icon fontSize="small">published_with_changes</Icon>,
            component: (token!=null) ? <Finance_details name ='finance'/> : <SignIn/>,
        }
      ]
    },
    {
      route: "/finance-admin",
      component: (token!=null) ? <Finance_details/> :  <SignIn/>,
    },
    {
      route: "/approved-amount",
      component: (token!=null) ? <Approved_amount/> :  <SignIn/>,
    },
    {
      route: "/payment-history",
      component: (token!=null) ? <Payment_history/> :  <SignIn/>,
    }
    ,
    {
      route: "/ledger-details",
      component: (token!=null) ? <Ledger_details/> :  <SignIn/>,
    }
    
];
export default financeroute;