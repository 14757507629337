// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import StockDetailsHeader from "layouts/common_components/stock_details_header";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

function CategoryList() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [categorylist, setcategorylist] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
    useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'category-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setcategorylist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  fetchData();
}, []);
const toggleStatus = async (categoryId) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`category-status/${categoryId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('change-category-status', {
        category_id:categoryId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setcategorylist((prevState) =>
          prevState.map((categoryObj) =>
           categoryObj.id === categoryId ? { ...categoryObj, status: newStatus } : categoryObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StockDetailsHeader/>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' to={"/add-category"}>
                   +
                </Link>
                </div>
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Category Id</td>
                  <td>Category Name</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {categorylist.map((category, index) => (
                <tr key={category.id}>
                  <td>{index + 1}</td>
                  <td>{category.id}</td>
                  <td>{category.category_name}</td>
                  <td><button
                  type="button"
                  className={`btn btn-${category.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(category.id)}
                  >
                  {category.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 <td>
                      <Link to={`/edit-category/${category.id}`} className='btn btn-success me-2'>
                             Edit
                    </Link>
                   </td>
               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CategoryList;
