import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';

import OfferDetailsHeader from 'layouts/common_components/offer_details_header';


import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import { parseISO, isBefore, format } from 'date-fns';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function Offer_type()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offerType, setOfferType] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [validationErrors, setValidationErrors] = useState({});
  const [offerModal,setOfferModal]=useState(false);

  const[offerTitle,setOfferTitle]=useState('');
  const[offerDescription,setOfferDescription]=useState('');

  const [editMode, setEditMode] = useState(false);
  const [editOfferTypeId, setEditOfferTypeId] = useState(null);
  const [status, setStatus] = useState(1);
  const handleEdit = (offerType) =>
  {
    setOfferModal(true);
    setEditMode(true);
    setEditOfferTypeId(offerType.id);
    setOfferTitle(offerType.type_name);
    setOfferDescription(offerType.description);
  };

  useEffect(()=>{
    getExeDetails();
  },[]);

  useEffect(()=>{
    getOfferType();
  },[currentPage])

  const getOfferType = async () =>
  {
      try {
        const response = await dashboardApi.get(`get-offer-type-details`,{
          params:{
            emp_id:emp_id,
            per_page: postPerPage,
            page: currentPage + 1,
          }
        });

        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const exeData = responseData.data || responseData;
          setOfferType(exeData);
          setNumber(responseData.last_page);
       
        } else {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }
    const getExeDetails = async () => 
    {
      try{
        const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
        if (response.data.status === 200) {
          setExeDetails(response.data.data);
        } else {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }catch (error){
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }

    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };

    const openOfferTypeModal = () =>
    {
      setOfferModal(true);
    }
    const closeOfferModal= ()=>{
      resetOfferFields();

      setOfferModal(false);
    }

    const resetOfferFields = ()=>{
      setEditOfferTypeId('');
      setEditMode(false);
      setOfferTitle('');
      setOfferDescription('');
    }

    const formSubmit = async()=>
    {
      const errors = {};
      const formData = new FormData();
      formData.append('offerTitle',offerTitle);
      formData.append('offerDescription',offerDescription);
      formData.append('created_by',emp_id);
      formData.append('offerId',editOfferTypeId);
      
      
      if(!offerTitle)
      {
        errors.offerTitle = 'Offer title is required.';
      }
      if(!offerDescription)
      {
        errors.offerDescription = 'Offer description is required.';
      }
      setValidationErrors(errors);
      
      try
      {
        const url = editMode ? `update-offer-type` : 'add-offer-type';
        const response = await dashboardApi.post(url, formData);
        
        if (response.data.status === 200) {
          setOfferModal(false);
          resetOfferFields();
          setEditMode(false);
          getOfferType();
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      }
      catch (error)
      {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while submitting the form',
        });
      }
    }

    const handleStatusToggle = async (offerType) => {
      const newStatus = offerType.status === 1 ? 0 : 1;
      
      try {
        const response = await dashboardApi.post(`update-offer-type-status/${offerType.id}`, {
          status: newStatus,
        });
    
        if (response.data.status === 200) {
          getOfferType(); // Refresh the list
          Swal.fire({
            icon: 'success',
            text: `Offer type has been ${newStatus === 1 ? 'activated' : 'deactivated'}.`,
          });
        } else {
          Swal.fire({
            icon: 'error',
            text: 'An error occurred while updating the status.',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while updating the status.',
        });
      }
    };

  return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                    <div class="col-sm-11">
                        <OfferDetailsHeader></OfferDetailsHeader>
                    </div>
                    <div class="col-sm-1">
                      <Link className='btn btn-info' onClick={openOfferTypeModal}>
                          +
                      </Link>
                    </div>
                  </div>
                  <div className='row'>
                    
                  </div>


                  {loading ? (
                  <p style={{align:'center'}}>
                  <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                  </p>
                  ) : (
                  <div style={{overflowY: "hidden"}}>
                    <table className="table table-bordered table-hovered" style = {{marginTop:'20px'}}>
                    <tr>
                      <th>S.No</th>
                      <th>Offer Title</th>
                      <th>Dscription</th>
                      <th>Status</th>
                      <th>Created By</th>
                      <th>Created Date</th>
                      <th>Action</th>
                    </tr>
                      {offerType && offerType.map((pr,index)=>(
                        <tr>
                          <td>{index + 1}</td>
                          <td>{pr.type_name}</td>
                          <td>{pr.description}</td>
                          <button
                              className={`btn ${pr.status === 1 ? 'btn-success' : 'btn-warning'} btn-sm`}
                              onClick={() => handleStatusToggle(pr)}
                            >
                              {pr.status === 1 ? 'Active' : 'Inactive'}
                            </button>
                          
                          <td>{pr.created_by_name}</td>
                          <td>{format(parseISO(pr.created_at), 'dd/MM/yyyy')}</td>
                          <td>
                          <button className="btn btn-primary btn-sm" onClick={() => handleEdit(pr)}>
                            Edit
                          </button>
                          </td>
                        </tr>
                        ))
                      }
                    <tr>
                      <td colspan="10">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={number}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link-style"}
                          breakLinkClassName={"break-link-style"}
                          previousLinkClassName={"prev-link-style"}
                          nextLinkClassName={"next-link-style"}
                          forcePage={currentPage} // Add this line
                        />
                        </Pagination>
                      </td>
                    </tr>
                </table>
                </div>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={offerModal} fullWidth PaperProps={{ style: { maxWidth: '40%', padding: '20px' } }}>
      <DialogTitle>{editMode?'Update Offer Type':'Add Offer Type'}</DialogTitle>
        <DialogContent style={{height:"250px"}}>
            <form>
              <div className="row">
                
                <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                    <p style={{ margin: "0 10px" }}>Offer Details</p>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                </div>
          
                <div className="col-sm-12">
                  <label>Offer Title</label>
                  <TextField type="text" className="form-control" value={offerTitle} onChange={(e) => setOfferTitle(e.target.value)}></TextField>
                  {validationErrors.offerTitle && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.offerTitle}</div>
                  )}
                </div>

                <div className="col-sm-12">
                  <label>Offer Description</label>
                  <TextField type="text" className="form-control" value={offerDescription} onChange={(e) => setOfferDescription(e.target.value)}></TextField>
                  {validationErrors.offerDescription && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.offerDescription}</div>
                  )}
                </div>
             
              </div>
            
              
              </form>
            </DialogContent>

            <DialogActions>
                <Link className="btn btn-primary btn-sm" onClick={closeOfferModal}>Close</Link>
                {editMode?
                  <Link className="btn btn-success btn-sm" onClick={formSubmit}>Update Details</Link>
                  :
                  <Link className="btn btn-success btn-sm" onClick={formSubmit}>Add Details</Link>
                }
                
            </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
  
}
export default Offer_type;