import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import Swal from 'sweetalert2';
import { useNavigate,Link } from 'react-router-dom'
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function AddModule() {
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [moduleName, setModuleName] = useState('');
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const emp_id = secureLocalStorage.getItem('emp_id');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'department-list';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setOptions(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleDepartmentChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setSelectedDepartments(selectedOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!moduleName) {
      errors.module = 'Module is required';
    }
    if (selectedDepartments.length === 0) {
      errors.department = 'Department is required';
  }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('module', moduleName);
    formData.append('emp_id',emp_id);
    setIsSubmitting(true);
    const departmentArray = Array.isArray(selectedDepartments)
      ? selectedDepartments
      : [selectedDepartments];

    departmentArray.forEach((departmentId) => {
      formData.append('department[]', departmentId);
    });
    try {
      const response = await dashboardApi.post('save-module', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/module-list");
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
     finally {
        setIsSubmitting(false);
      }
  };
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
             <div style={{float:'right'}}>
              <Link to='/module-list' className="btn btn-info">
                 Back
              </Link>

             </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="moduleName" style={{ fontSize: '16px' }}>Module Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={moduleName}
                            onChange={(event) => setModuleName(event.target.value)}
                            placeholder="Enter Module Name"
                          />
                          {validationErrors.module && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.module}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="selectedDepartments" style={{ fontSize: '16px' }}>Select Departments</label>
                          <select
                            className="form-control"
                            multiple
                            value={selectedDepartments}
                            onChange={handleDepartmentChange}
                          >
                            {options.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.department_name}
                              </option>
                            ))}
                          </select>
                           {validationErrors.department && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                             )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddModule;
