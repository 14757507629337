import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import  secureLocalStorage  from  "react-secure-storage";
import DepartmentTarget from 'layouts/company_target/department_target';
import AssignTarget from "layouts/company_target/assign_target.js";
import AssignGroups from 'layouts/company_target/assign_to_groups';
import AdditionalTarget from "layouts/company_target/additional_targets";
import SalesLead from "layouts/company_target/sales_lead";
import AssignGroupsToEmployee from "layouts/company_target/assign_group_to_employee";
//const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];
let nested = [];
const digital_marketing_leads = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "Digital_marketing_lead");
const department_target = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "department-target");
const sales_target = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "sales-lead");



const Companytarget = [

  ];
  if(digital_marketing_leads){
    Companytarget.push({
        route: "/assign-to-groups",
        component: (token!=null) ?  <AssignGroups /> : <SignIn/> ,
        nested:[],
  });
  
  }
    Companytarget.push({
         route: "/department-target",
         component: (token!=null) ?  <DepartmentTarget /> : <SignIn/> ,
        nested:[],
  });
  
  if(digital_marketing_leads){
    Companytarget.push({
      route: "/assign-emp-group",
      component: (token!=null) ?  <AssignGroupsToEmployee /> : <SignIn/> ,
        nested:[],
  });
  
  }
 
    Companytarget.push({
      route: "/target-list",
      component: (token!=null) ?  <AdditionalTarget /> : <SignIn/> ,
      nested:[],
  });
 
    Companytarget.push({
        route: "/sales-lead",
        component: (token!=null) ?  <SalesLead /> : <SignIn/> ,
      nested:[],
  });
        Companytarget.push({
          route: "/assign-target",
          component: (token!=null) ?  <AssignTarget /> : <SignIn/> ,
          nested:[],
       });


export default Companytarget;