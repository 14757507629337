import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function TeamResign() {
  const token = secureLocalStorage.getItem('token');
  const dept_id = secureLocalStorage.getItem('department_id');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const[remark,setremark] = useState('');
  const [openmodal,setopenmodal] = useState('');
  const [validationErrors,setValidationErrors] = useState({});
  const[list,setlist] = useState([]);
  const[status,setstatus] = useState('');
  const[reporting,setreporting] = useState();
  const[employeeid,setemployeeid] = useState('');
  const[id,setid] = useState('');
 // alert(dept_id);

 useEffect(()=>{
    resigndetails() 
},[])
const resigndetails = async () => {
try {
   const response = await dashboardApi.get('show-team-resign-list',{
    params:{
        emp_id:emp_id,
        dept_id:dept_id,
      }
    });

  if (response.data.status === 200) {
    setlist(response.data.data);
  } else {
    console.error('Error fetching branch type:', response.data.message);
  }
  setLoading(false);
} catch (error) {
  console.error('Error fetching branch type:', error);
  setLoading(false);
}
};

const open_modal = (reporting,empid,id)=>{
  setid(id);
  setreporting(reporting);
  setemployeeid(empid)
  setopenmodal(true);


}

const closemodal = ()=>{
  setopenmodal(false);
  setremark('');
  setstatus('');
  setid('');
  setemployeeid('');
  setreporting('');
  setValidationErrors({});
}
const save_remark = async (e)=>{
  e.preventDefault();
  const errors = {};
  if (!status) {
    errors.status = 'Status is required';
  }
  if (!remark) {
    errors.remark = 'Remark is required';
  }

  setValidationErrors(errors);

  if (Object.keys(errors).length === 0) {
  const formData = new FormData()
  formData.append('status', status);
  formData.append('remark',remark);
  formData.append('emp_id', emp_id);
  formData.append('empid',employeeid);
  formData.append('id',id);
  formData.append('dept_id',dept_id);
  try {
    const response = await dashboardApi.post('save-resign-remark', formData);

    if (response.data.status === 200) {
      //console.log('iiii');
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
      resigndetails();
      closemodal();
     
    }  else {
      Swal.fire({
        icon: 'error',
        text: response.data.message || 'An error occurred',
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'An error occurred while creating',
    });
  }
};


}

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Details</td>
                  <td>Reason</td>
                  <td>Manager Status</td>
                  <td>Manager Remark</td>
                  <td>Hr Status</td>
                  <td>Hr Remark</td>
                  <td>Action</td>
               </tr>

               {list.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.emp_fname} {row.emp_lame}
                      ({row.department_name}),
                  
                    ({row.created_date})


                  </td>
                  <td>{row.reason}</td>
                  {
                    row.manager_status ==0 &&
                    <td>Pending</td>
                  }
                   {
                    row.manager_status ==1 &&
                    <td>Accepted</td>
                  }
                   {
                    row.manager_status ==2 &&
                    <td>Rejected</td>
                  }
                  <td>{row.manager_remark}</td>
                  {
                    row.hr_status ==0 &&
                    <td>Pending</td>
                  }
                   {
                    row.hr_status ==1 &&
                    <td>Accepted</td>
                  }
                   {
                    row.hr_status ==2 &&
                    <td>Rejected</td>
                  }
                  <td>{row.hr_remark}</td>
                  <td>
                  {
                    (row.reporting_manager === emp_id || dept_id === 5) && (
                  
                   <button className='btn btn-info btn-sm' onClick={() => open_modal(row.reporting_manager, row.emp_id, row.id)}>
                        Remark
                  </button>

                   
                  )
                 }
                 &nbsp;
                 <br/>
                
               
                 <Link className='btn btn-info btn-sm' to={`/resign-description/${row.id}/${row.emp_id}`}>
                     view
                 </Link>

                 </td>

                  

               </tr>
              ))}

            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openmodal}
        //onClose={closeModalHandler}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'450px'} }}
      >
        <DialogTitle>Add Remark</DialogTitle>
        <DialogContent>
          <form>
           <div className='container-fluid'>
           <div className="row">
            <div className="form-group col-sm-12">
            <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Status</label>
            <select
            className='form-control'
            value={status}
            onChange={(e) => setstatus(e.target.value)}
            >
            <option value = ''>Select Status</option>
            <option value='1'>Accept</option>
            <option value='2'>Rejected</option>
           </select>
           {validationErrors.status && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                             )}
            
            </div>
            </div>
            <div className="row">
            <div className="form-group col-sm-12">
            <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Remark</label>
            <textarea rows={5} cols={5} style={{height:'50px'}}
            value={remark}
            onChange={(e) => setremark(e.target.value)}
            className = "form-control"
            placeholder="Remark"
           />
           {validationErrors.remark && (
              <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                             )}
            </div>
            </div>

            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodal}>
            Close
          </button>
          <button className="btn btn-info" onClick={save_remark}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default TeamResign;
