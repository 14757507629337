import React, { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function AddLeave() {
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate();
  const [name, setName] = useState("")
  const [date, setdate] = useState("")
  const [leavetype,setleavetype] = useState("");
  const [branch ,setbranch] = useState([]);
  const [selectedbranch,setselectedbranch] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const handlebranchChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setselectedbranch(selectedOptions);
  };
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  const saveleave = async (e) => {
    e.preventDefault();
    const errors = {};
   if (!leavetype) {
      errors.leave_type = 'Leave Type is required';
    }
    if (!name) {
      errors.name = 'Leave Name is required';
    }
    if (!date) {
      errors.date = 'Date  is required';
    }
   
     setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('date', date)
    formData.append('leave_type',leavetype)
   //formData.append('branch',selectedbranch)
    const branchArray = Array.isArray(selectedbranch)
      ? selectedbranch
      : [selectedbranch];

    branchArray.forEach((branchId) => {
      formData.append('branch[]', branchId);
    });
    try {
      const response = await dashboardApi.post('/create-leave', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/leave-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the Leave',
      });
    }
  }
  };
  useEffect(() => {
  if (leavetype == 'branch') {
    const fetchData = async () => {
      try {
        const endpoint = `get-active-branch`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setbranch(response.data.data);
        } else {
          console.error('Error fetching  data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching  data:', error);
      }
    };

    fetchData();
  }
}, [leavetype == 'branch']);
//console.log(branch);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <Link className='btn btn-primary mb-2 float-end' to={"/leave-list"}>
                   Back
                  </Link>
                <form onSubmit={saveleave}>
                  <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Leave Type</label>
                          <select className="form-control"
                            value={leavetype}
                            onChange={(event) => {
                              setleavetype(event.target.value)
                            }}
                          >
                            <option value=''>Select Leave Type</option>
                            <option value='international'>International</option>
                            <option value='national'>National</option>
                            <option value='branch'>Branch Wise</option>
                          </select>
                        </div>
                        {validationErrors.leave_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.leave_type}</div>
                             )}
                      </div>
                       {leavetype == 'branch'&&( 
                        <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px'}}>Select Branch</label>
                          <select className="form-control"
                               multiple
                            value={selectedbranch}
                            onChange={handlebranchChange}
                            >
                            <option value=''>Select Branch</option>
                            {branch.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.branch_name}
                              </option>
                              ))}
                              
                          </select>
                        </div>
                      </div>
                       )}
                      
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Leave Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value = {name}
                            onChange={(event)=>{
                              setName(event.target.value)}}
                            placeholder="Enter Leave Name"
                          />
                           {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Leave Date</label>
                          <input
                            type="date"
                            className="form-control"
                            value = {date}
                            onChange={(event)=>{
                              setdate(event.target.value)}}
                          />
                           {validationErrors.date && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.date}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                           Save
                        </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddLeave;