import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import Card from "@mui/material/Card";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import secureLocalStorage from 'react-secure-storage';

const RenewalGone = () => {
  const navigate = useNavigate();
  const { total_enquiry } = useParams();
  const token = secureLocalStorage.getItem('token');
  const [productlist, setProductlist] = useState([]);
  const [product, setProduct] = useState('');
  const [servicelist, setServicelist] = useState([]);
  const [service, setService] = useState('');
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [grouplist, setGroupList] = useState([]);
  const [group, setGroup] = useState('');
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;
   const [category_list,set_category_list] = useState([]);
  const [category,selectedcategory] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    groupDetails();
    productDetails();
  }, []);

  useEffect(() => {
    getData(currentPage);
  }, [currentPage, product, service, start_date, end_date, group,category]);

  const groupDetails = async () => {
    try {
      const response = await dashboardApi.get('group-details');
      if (response.data.status === 200) {
        setGroupList(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const productDetails = async () => {
    try {
      const response = await dashboardApi.get('get-product-list');
      if (response.data.status === 200) {
        setProductlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const response = await dashboardApi.get(`get-service-list-by-product-id/${product}`);
          if (response.data.status === 200) {
            setServicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);


   useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);

  const getData = async (page) => {
    setLoading(true);
    try {
      const response = await dashboardApi.get('renewal-gone-due-to-lead', {
        params: {
          start_date: start_date,
          end_date: end_date,
          group: group,
        }
      });

      if (response.data.status == 200) {
        setList(response.data.data);
       // setTotalPages(response.data.last_page);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  console.log(list);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <div>
            <div className="d-flex flex-wrap">
              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Group</option>
                  {grouplist.map((row) => (
                    <option key={row.group_id} value={row.group_id}>
                      {row.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={start_date}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>
              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={end_date}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>
            </div>
          </div>

          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered table-hovered">
                  <thead>
                    <tr>
                      <td style={{ fontSize: '12px' }}>Client Id</td>
                      <td style={{ fontSize: '12px' }}>Client</td>
                      <td style={{ fontSize: '12px' }}>Group</td>
                      <td style={{ fontSize: '12px' }}>City</td>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((row) => (
                      <tr key={row.id}>
                        <td style={{ fontSize: '12px' }}>{row.client_id}</td>
                        <td style={{ fontSize: '12px' }}>{row.client_name}</td>
                        <td style={{ fontSize: '12px' }}>{row.group}</td>
                        <td style={{ fontSize: '12px' }}>{row.city}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Card>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default RenewalGone;
