import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import axios from 'axios';
import Swal from 'sweetalert2';
import {useNavigate,useParams,Link } from 'react-router-dom';
import EmployeeDetailsHeader from "layouts/common_components/emp_details_header";
import dashboardApi from "layouts/common_components/apibase_url";
import docurl from "layouts/common_components/doc_baseurl";
import  secureLocalStorage  from  "react-secure-storage";

function EditBasicInfo() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const navigate = useNavigate();
  const[company, setcompany] = useState([]);
  const[selectedCompany, setselectedCompany] = useState('');
  const[branch, setbranch] = useState([]);
  const[selectedbranch,setselectedbranch] = useState('');
  const[department,setdepartment] = useState([]);
  const[selecteddepartment,setselecteddepartment] = useState('');
  const[designation,setdesignation] = useState([]);
  const[selecteddesignation,setselecteddesignation] = useState('');
  const[emptype,setemptype] = useState([]);
  const[selectedemptype,setselectedemptype] = useState('');
  const[firstname,setfirstname] = useState('');
  const[middlename,setmiddlename] = useState('');
  const[lastname,setlastname] = useState('');
  const[fathername,setfathername] = useState('');
  const[gender,setgender] = useState('');
  const[phone,setphone] = useState('');
  const[officephone,setofficephone] = useState('');
  const[email,setemail] = useState('');
  const[officeemail,setofficeemail] = useState('');
  const[dob,setdob] = useState('');
  const[doj,setdoj] = useState('');
  const[dor,setdor] = useState('');
  const[eod,seteod] = useState('');
  const[empid,setempid] = useState('');
  const[pic,setpic] = useState(null);
  const[manager , getmanager] = useState([]);
  const[selectedmanager ,setselectedmanager] = useState('');
  const[changemanager,setchangemanager] = useState(' ');
  const [imageUrl, setimageUrl] = useState(null);
  const [emp_id,getemp_id] = useState(' ');
  const[alternate,setalternate] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  

  const[password,setpassword] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
   useEffect(()=>{
    fetchEmployee()
  },[])
  const fetchEmployee = async () => {
     await dashboardApi.get(`edit-employee/${id}`).then(({data})=>{
      const {company_id, branch_id,dept_id,desi_id,emp_type,emp_fname,emp_lame,emp_mname,emp_father_name,emp_sex,emp_phone,emp_office_phone
        ,emp_email,emp_office_email,emp_dob,emp_doj,emp_dor,emp_eod,login_password,emp_id,profile_picture,reporting_manager,alternate_no} = data.basic_info
      setselectedCompany(company_id)
      setselectedbranch(branch_id)
      setselecteddepartment(dept_id)
      setselecteddesignation(desi_id)
      setselectedemptype(emp_type)
      setfirstname(emp_fname)
      setmiddlename(emp_mname)
      setlastname(emp_lame)
      setfathername(emp_father_name)
      setgender(emp_sex)
      setphone(emp_phone);
      setofficephone(emp_office_phone)
      setemail(emp_email)
      setofficeemail(emp_office_email)
      setdob(emp_dob)
      setdoj(emp_doj)
      setdor(emp_dor)
      seteod(emp_eod)
      setempid(emp_id);
      setpassword(login_password);
      setpic(profile_picture);
      setselectedmanager(reporting_manager);
      getemp_id(emp_id);
      setalternate(alternate_no);
    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }
  const baseUrl = docurl();
  const imageUrls = `${baseUrl}profile_picture/${pic}`;
  //alert(imageUrls);

   useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);
  useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-company'; // Replace with the actual endpoint
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setcompany(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
const handleimageChange = (e) => {
    const file = e.target.files[0];
    //setimageUrl(URL.createObjectURL(file));
    setpic(file);
  };

  useEffect(() => {
  if (selectedCompany) {
    const fetchData = async () => {
      try {
        const endpoint = `company-based-branch/${selectedCompany}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setbranch(response.data.data);
        } else {
          console.error('Error fetching states data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching states data:', error);
      }
    };

    fetchData();
  }
}, [selectedCompany])
  useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-department'; // Replace with the actual endpoint
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
       setdepartment(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
useEffect(() => {
  if (selecteddepartment) {
    const fetchData = async () => {
      try {
        const endpoint = `get-manager/${selecteddepartment}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          getmanager(response.data.data);
        } else {
          console.error('Error fetching  data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching  data:', error);
      }
    };

    fetchData();
  }
}, [selecteddepartment])

  useEffect(() => {
  if (selecteddepartment) {
    const fetchData = async () => {
      try {
        const endpoint = `department-based-designation/${selecteddepartment}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setdesignation(response.data.data);
        } else {
          console.error('Error fetching states data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching states data:', error);
      }
    };

    fetchData();
  }
}, [selecteddepartment])
    useEffect(() => {
   const fetchData = async () => {
    try {
      const endpoint = 'active-employee-type'; 
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
       setemptype(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

  const updateemployee = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!selectedCompany) {
      errors.company = 'Company is required';
    }
    if (!selectedbranch) {
      errors.branch = 'branch is required';
    }
    if (!selecteddepartment) {
      errors.department = 'department is required';
    }
    if (!selecteddesignation) {
      errors.designation = 'designation is required';
    }
     if (!selectedemptype) {
      errors.emp_code = 'Emp Type is required';
    }
     if(!firstname) {
      errors.first_name = 'First Name is required';
    }

    if(!fathername){
      errors.father_name = 'Father Name is required';

    }
    if(!gender){
      errors.gender = 'Gender is required';

    }
    if(!phone){
      errors.phone = 'phone is required';
    }
    if(!email){
      errors.email = 'Email is required';
    }
     if(!password){
      errors.password = 'Password is required';
    }
    if(!dob){
      errors.Date_of_birth = 'DOB is required';
    }
     if(!doj){
      errors.Date_of_join = 'DOJ is required';
    }
    if(!selectedmanager){
      errors.manager = 'Manager is required';

    }
   setValidationErrors(errors);

  if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('company', selectedCompany);
    formData.append('branch', selectedbranch);
    formData.append('department', selecteddepartment);
    formData.append('designation', selecteddesignation);
    formData.append('emp_type', selectedemptype);
    formData.append('first_name', firstname);
    formData.append('father_name', fathername);
    formData.append('gender', gender);
    formData.append('phone', phone);
    formData.append('Date_of_birth', dob);
    formData.append('Date_of_join', doj);
    formData.append('password', password);
    formData.append('middle_name', middlename);
    formData.append('last_name', lastname);
    formData.append('office_phone', officephone);
    formData.append('email', email);
    formData.append('office_email', officeemail);
    formData.append('date_of_resign', dor);
    formData.append('end_of_date', eod);
    formData.append('manager',selectedmanager);
    formData.append('change_manager',changemanager);
    formData.append('alternate',alternate);
    formData.append('pic',pic);
    setIsSubmitting(true);

    try {
        const response =  await dashboardApi.post(`update-employee/${id}`, formData);

        if (response.data.status === 200) {
          Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
          const emp_id = response.data.data[0].emp_id;
          navigate(`/document-info/${emp_id}`);
        } else if (response.data.status === 400) {
          // Display validation errors below the input fields
          setValidationErrors(response.data.messages);
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while creating the employee',
        });
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <EmployeeDetailsHeader id={id}/>
            <Card>
              <div className="card-body">
              <Link className='btn btn-primary mb-2 float-end' to={"/employee-list"}>
                  Back
                </Link>
               <form onSubmit={updateemployee}>
               <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="bussiness name" style={{ fontSize: '16px' }}>Select Company</label>
                           <select className="form-control"
                           value={selectedCompany}
                           onChange={(event)=>{
                              setselectedCompany(event.target.value)}}
                            >
                            <option value=''>Select Company</option>
                            {company.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.business_name}
                              </option>
                              ))}
                              
                          </select>
                           {validationErrors.company && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.company}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Select Branch</label>
                        <select className="form-control" 
                          value={selectedbranch}
                           onChange={(e) => setselectedbranch(e.target.value)}
                           >
                           <option value=''>Select Branch</option>
                            {branch ? (
                             branch.map((branches) => (
                             <option key={branches.id} value={branches.id}>
                             {branches.branch_name}
                            </option>
                            ))
                           ) : (
                            <option disabled>No Branch available for the selected company</option>
                           )}
                          </select>
                           {validationErrors.branch && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.branch}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Select Department</label>
                           <select className="form-control"
                           value={selecteddepartment}
                           onChange={(event)=>{
                              setselecteddepartment(event.target.value)}}
                            >
                            <option value=''>Select Deprtment</option>
                            {department.map((departments) => (
                             <option key={departments.id} value={departments.id}>
                              {departments.department_name}
                              </option>
                              ))}
                              
                          </select>
                           {validationErrors.department && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Select Designtion</label>
                           <select className="form-control" 
                           value={selecteddesignation}
                           onChange={(e) => setselecteddesignation(e.target.value)}
                           >
                           <option value=''>Select Designation</option>
                            {designation ? (
                             designation.map((designations) => (
                             <option key={designations.id} value={designations.id}>
                             {designations.designation_name}
                            </option>
                            ))
                           ) : (
                            <option disabled>No Designation available for the selected Department</option>
                           )}
                          </select>
                           {validationErrors.designation && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.designation}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Employee Type</label>
                          <select className="form-control"
                           value={selectedemptype}
                           onChange={(event)=>{
                              setselectedemptype(event.target.value)}}
                            >
                            <option value=''>Select Employee Type</option>
                            {emptype.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.emp_type}
                              </option>
                              ))}
                              
                          </select>
                           {validationErrors.emp_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.company}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="first-email"
                            placeholder="Enter First Name"
                            value={firstname}
                             onChange={(event)=>{
                              setfirstname(event.target.value)}}
                            
                          />
                           {validationErrors.first_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.first_name}</div>
                             )}
                        </div>
                      </div>

                      </div>
                      <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Middle Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="second-email"
                            placeholder="Enter middle name"
                            value={middlename}
                             onChange={(event)=>{
                              setmiddlename(event.target.value)}}
                            
                          />
                          {validationErrors.middle_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.middle_name}</div>
                             )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px'}}>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="second-email"
                            placeholder="Enter last name"
                            value={lastname}
                             onChange={(event)=>{
                              setlastname(event.target.value)}}

                          />
                          {validationErrors.last_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.last_name}</div>
                             )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px'}}>Father Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="second-email"
                            placeholder="Enter father name"
                            value={fathername}
                             onChange={(event)=>{
                              setfathername(event.target.value)}}
                          />
                          {validationErrors.father_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.father_name}</div>
                             )}
                        </div>
                      </div>
                     </div>
                    <div className="row">
                    <div className="col-md-4">
                     <div className="form-group">
                     <label htmlFor="country" style={{ fontSize: '16px'}}>Select Gender</label>
                     <select className="form-control"
                      value={gender}
                      onChange={(e) => setgender(e.target.value)}
                      >
                            <option value=''>Select Gender</option>
                            <option value='male'>Male</option>
                            <option value='female'>FeMale</option>
                            <option value='other'>Other</option>

                          </select>
                          {validationErrors.gender && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.gender}</div>
                             )}

                    </div>
                    </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize:'16px' }}>Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            placeholder="Enter Phone"
                             value={phone}
                             onChange={(e) => setphone(e.target.value)}
                           />
                           {validationErrors.phone && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.phone}</div>
                             )}
                        </div>
                       </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize:'16px' }}>Office Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            id="url"
                            placeholder="Enter office phone"
                            value={officephone}
                             onChange={(e) => setofficephone(e.target.value)}
                            
                          />
                          
                        </div>
                      </div>

                     </div>
                     <div className="row">
                     <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize:'16px' }}>Alternate Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Alternate Number"
                            value={alternate}
                             onChange={(e) => setalternate(e.target.value)}
                            
                          />
                          
                        </div>
                      </div>
                     <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="last name" style={{ fontSize: '16px' }}>Email</label>
                           <input
                            type="email"
                            className="form-control"
                            id="url"
                            placeholder="Enter Email"
                            value={email}
                             onChange={(e) => setemail(e.target.value)}
                            
                          />
                          {validationErrors.email && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.email}</div>
                             )}
                         
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="last name" style={{ fontSize: '16px' }}>Office Email</label>
                           <input
                            type="email"
                            className="form-control"
                            id="url"
                            placeholder="Enter office email"
                             value={officeemail}
                             onChange={(e) => setofficeemail(e.target.value)}
                            
                          />
                         
                        </div>
                      </div>
                      </div>
                      <div className = "row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="last name" style={{ fontSize: '16px' }}>Date Of Birth</label>
                           <input
                            type="date"
                            className="form-control"
                            value={dob}
                             onChange={(e) => setdob(e.target.value)}
                            
                          />
                          {validationErrors.Date_of_birth && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.Date_of_birth}</div>
                             )}
                         
                        </div>
                      </div>
                      
                     <div className="col-md-4">
                     <div className="form-group">
                          <label htmlFor="last name" style={{ fontSize: '16px' }}>Date of Join</label>
                           <input
                            type="date"
                            className="form-control"
                            value={doj}
                             onChange={(e) => setdoj(e.target.value)}
                            
                          />
                          {validationErrors.Date_of_join && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.Date_of_join}</div>
                             )}
                         
                        </div>

                     </div>

                     <div className="col-md-4">
                     <div className="form-group">
                          <label htmlFor="last name" style={{ fontSize: '16px' }}>Password</label>
                           <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Password"
                            value={password}
                             onChange={(e) => setpassword(e.target.value)}
                            
                          />
                          {validationErrors.password && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.password}</div>
                             )}
                         
                        </div>

                     </div>
                     </div>
                     <div className = "row">
                    <div className="col-md-4">
                     <div className="form-group">
                          <label htmlFor="last name" style={{ fontSize: '16px' }}>Profile Image</label>
                           <input
                            type="file"
                            className="form-control"
                            onChange={(e) => setpic(e.target.files[0])}
                            
                          />
                         
                         
                        </div>
                        {pic && (
                        <img
                        src={imageUrls}
                        style={{ maxWidth: '80px', height: '80px' }}
                      />
                       )}

                     </div>
                        <div className = "col-md-4">
                      <div className="form-group">
                       <label htmlFor="last name" style={{ fontSize: '16px' }}>Select Reporting Manager</label>
                        <select className="form-control"
                           value={selectedmanager}
                           onChange={(event)=>{
                              setselectedmanager(event.target.value)}}
                            >
                            <option value=''>Select Manager</option>
                            {manager.map((option) => (
                             <option key={option.emp_id} value={option.emp_id}>
                              {option.emp_fname} {option.emp_mname} {option.emp_lame}
                              </option>
                              ))}
                              
                          </select>
                          {validationErrors.manager && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.manager}</div>
                             )}
                           
                        
                        </div>
                        </div>
                        </div>
                        </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Updating...' : 'Update'}
                      </button>
                      </div>
                    </div>
                  
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditBasicInfo;