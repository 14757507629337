// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {useNavigate } from 'react-router-dom'
import Switch from 'react-switch';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function Designation() {
   const navigate = useNavigate();
    const emp_id = secureLocalStorage.getItem('emp_id');
    const  token = secureLocalStorage.getItem('token');
   const [designation, setDesignation] = useState([])
   const [loading, setLoading] = useState(true);
   const permission = secureLocalStorage.getItem('permission');
   const parsedPermission = permission ? JSON.parse(permission) : [];
   const add_designation = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-designation");
   const edit_designation = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-designation");
   const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
   useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
   useEffect(()=>{
        fetchDesignation() 
    },[])
   const fetchDesignation = async () => {
    //alert();
    try {
      const response = await dashboardApi.get('designation-list');
     // console.log(response);
      if (response.data.status === 200) {
        setDesignation(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };
  const toggleStatus = async (designationId) => {
     const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`designation-status/${designationId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('designation-change-status', {
        designation_id: designationId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setDesignation((prevState) =>
          prevState.map((designationObj) =>
            designationObj.designation_id === designationId ? { ...designationObj, status: newStatus } : designationObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
            {add_designation && (
              <div style={{float:'right'}}>
                 <Link className='btn btn-primary mb-2 float-end' to={"/add-designation"}>
                    +
                </Link>

              </div>
            )}
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Name</td>
                  <td>Department</td>
                  {change_status && (
                  <td>Status</td>
                  )}
                  {edit_designation && (
                  <td>Action</td>
                 )}
               </tr>
               {designation.map((designations, index) => (
                <tr key={designations.designation_id}>
                  <td>{index + 1}</td>
                  <td>{designations.designation_name}</td>
                  <td>{designations.department_name}</td>
                  {change_status && (
                  <td><button
                  type="button"
                  className={`btn btn-${designations.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(designations.designation_id)}
                  >
                  {designations.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 )}
                  {edit_designation && (
                   <td>
                      <Link to={`/edit-designation/${designations.designation_id}`} className='btn btn-success me-2'>
                             Edit
                    </Link>
                   </td>
                 )}
               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Designation;
