import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

function ResignDescription() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const { employee_id } = useParams();
  const dept_id = secureLocalStorage.getItem('department_id');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [immididate_terminate, set_immididate_terminate] = useState();
  const [notice_period, set_notice_period] = useState('');
  const [notice_period_days, set_notice_period_days] = useState('');
  const [is_notice_period_served, set_is_notice_period_served] = useState('');
  const [fnf_salary, set_fnf_salary] = useState('');
  const [resign_date, set_resign_date] = useState('');
  const [last_working_day, set_last_working_day] = useState('');
  const [asset_list, set_asset_list] = useState([]);
  const [validationErrors, setValidationErrors] = useState({})
  const[reason,setreason] = useState('');
  const [emp_manager,set_emp_manager] = useState('');

  useEffect(() => {
    const get_details = async () => {
      try {
        const response = await dashboardApi.get(`get-resign-description-details/${id}`);
        if (response.data.status === 200) {
          const data = response.data.data;
          set_immididate_terminate(data.is_terminate);
          set_notice_period(data.notice_period);
          set_notice_period_days(data.no_of_notice_period_days);
          set_is_notice_period_served(data.is_notice_period_served);
          set_fnf_salary(data.fnf_salary);
          set_resign_date(data.created_date);
          set_last_working_day(data.last_working_day);
          setreason(data.termination_reason);
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while fetching details',
        });
      }
    }
    get_details();
  }, []);

  const immidiate_terminate_function = () => {
    set_immididate_terminate(1);
    set_notice_period(0);
    set_notice_period_days('');
  }

  const notice_period_function = () => {
    set_notice_period(1);
    set_immididate_terminate(0);

  }

  const is_notice_period_served_function = (e) => {
    set_is_notice_period_served(e);
  }

  const save_details = async (e) => {
    e.preventDefault();
    const errors = {};
    if(immididate_terminate==1 && (reason=='null'|| !reason)){
      errors.reason ='Reason Is Required'

    }
    
    if(notice_period==1 &&(notice_period_days=='null' || !notice_period_days)){ 
      errors.notice_period_days ='Days Is Required';

    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      const formData = new FormData();
      formData.append('terminate', immididate_terminate);
      formData.append('notice_period', notice_period);
      formData.append('days', notice_period_days);
      formData.append('last_working_day', last_working_day);
      formData.append('notice_period_serve', is_notice_period_served);
      formData.append('salary', fnf_salary);
      formData.append('id', id);
      formData.append('type','save_details');
      formData.append('reason',reason)
      formData.append('employee',employee_id)
      try {
        const response = await dashboardApi.post('save-resign-description-details', formData);
        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while saving details',
        });
      }
    }
  };

  const fnf_details = async (e) => {
    e.preventDefault();

    const errors = {};
    if(!fnf_salary || fnf_salary =='null'){
      errors.fnf = 'Salary Is Required';
    }
    setValidationErrors(errors);
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "Are You Want To Submit FNF!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      return result.isConfirmed
    });
    
    if (!isConfirm) {
      return;
    }      

    if (Object.keys(errors).length === 0) {
      const formData = new FormData();
      formData.append('terminate', immididate_terminate);
      formData.append('notice_period', notice_period);
      formData.append('days', notice_period_days);
      formData.append('last_working_day', last_working_day);
      formData.append('notice_period_serve', is_notice_period_served);
      formData.append('salary', fnf_salary);
      formData.append('id', id);
      formData.append('type','fnf_details');
      formData.append('employee',employee_id)
      try {
        const response = await dashboardApi.post('save-resign-description-details', formData);
        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
        }
         else if (response.data.status === 400) {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
          });
        }

         else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while saving details',
        });
      }
    }
  };

  useEffect(() => {
    const get_asset_details = async () => {
      try {
        const response = await dashboardApi.get(`emp-asset-details-for-fnf/${employee_id}`);
        if (response.data.status === 200) {
          set_asset_list(response.data.data);
          set_emp_manager(response.data.manager);
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while fetching asset details',
        });
      }
    }
    get_asset_details();
  }, []);

  const handlechange = (e) => {
    const newNoticePeriodDays = e;
    set_notice_period_days(newNoticePeriodDays);
    if (newNoticePeriodDays) {
      const new_date = resign_date.substring(0, 10);
      const resignDateObj = new Date(new_date);
      resignDateObj.setDate(resignDateObj.getDate() + parseInt(newNoticePeriodDays));
      const last_date = resignDateObj.toISOString().substring(0, 10);
      set_last_working_day(last_date);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div className='row'>
                <div className='col-md-4'>
                <Link to='/emp-resign' className='btn btn-info btn-sm'>Back</Link>
                </div>

              </div>
              <div className="card-body">
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-3'>
                      <label style={{fontSize:'15px'}}>Immediate Terminate</label>
                      <input
                        type='radio'
                        className='form-control'
                        style={{ height: '20px' }}
                        name='resign_type'
                        value={immididate_terminate}
                        onChange={immidiate_terminate_function}
                        checked={immididate_terminate === 1}
                      />
                    </div>
                    <div className='col-md-3'>
                      <label style={{fontSize:'15px'}}>Serve Notice Period</label>
                      <input
                        type='radio'
                        className='form-control'
                        name='resign_type'
                        value={notice_period}
                        onChange={notice_period_function}
                        style={{ height: '20px' }}
                        checked={notice_period === 1}
                      />
                    </div>
                    {notice_period === 1 && (
                      <div className='col-md-3'>
                        <label style={{fontSize:'15px'}}>No Of Notice Period Days</label>
                        <input
                          type='number'
                          className='form-control'
                          placeholder='No Of Days'
                          value={notice_period_days}
                          onChange={(e) => handlechange(e.target.value)}
                        />
                        {validationErrors.notice_period_days && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.notice_period_days}</div>
                             )}
                      </div>
                    )}

                    {immididate_terminate === 1 && (
                      <div className='col-md-4'>
                        <label style={{fontSize:'15px'}}>Termination Reason</label>
                        <textarea rows={5} cols={5} style={{height:'50px'}}
                         value={reason}
                         onChange={(e) => setreason(e.target.value)}
                         className = "form-control"
                         placeholder="Reason"
                        />
                        {validationErrors.reason && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.reason}</div>
                             )}
                      </div>
                    )}
                  </div>
                  {immididate_terminate === 1 && (
                     <div className='col-md-4'>
                        <label style={{fontSize:'15px'}}>Last Working Day</label>
                         <input
                         type='date'
                         value={last_working_day}
                         className='form-control'
                         onChange={(e) => set_last_working_day(e.target.value)}


                         />
                      </div>

                    )}
                    {notice_period === 1 && (
                        <div className='col-md-4'>
                    <label style={{fontSize:'15px'}}>Last Working Day: {last_working_day}</label>
                  </div>

                      )}
                  { (emp_id !== employee_id) && (emp_manager === emp_id) || (dept_id === 5) ?
                   (
                   <div className='col-md-12 d-flex justify-content-end'>
                   <button className='btn btn-info btn-sm' onClick={save_details}>Save</button>
                   </div>
                  ) : null
                  }

                  
                </div>
                <hr />
                <center>Asset Details</center>
                <table className='table'>
                  <thead>
                    <tr>
                      <td>S.no</td>
                      <td>Asset</td>
                      <td>Return Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {asset_list.map((row, index) => (
                      <tr key={row.id}>
                        <td>{index + 1}</td>
                        <td>{row.brand_name}</td>
                        <td>{row.return_status === 0 ? 'Pending' : (row.return_status === 1 ? 'Return Successfully' : 'Rejected')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <hr />
                <center>FNF Details</center>
                <div className='container'>
                  <div className='row'>
    <div className='col-md-4'>
      <label style={{fontSize:'15px'}}>Is Notice Period Served</label>
      <input
        type='checkbox'
        className='form-control'
        style={{ height: '20px' }}
        value={is_notice_period_served}
        name='notice_period_served'
        onChange={(e) => is_notice_period_served_function(e.target.checked ? 1 : 0)}
        checked={is_notice_period_served === 1}
      />
    </div>
                    <div className='col-md-4'>
                      <label style={{fontSize:'15px'}}>Enter FNF Salary</label>
                      <input
                        type='number'
                        className='form-control'
                        placeholder='Enter Salary'
                        value={fnf_salary}
                        onChange={(e) => set_fnf_salary(e.target.value)}
                      />
                      {validationErrors.fnf && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.fnf}</div>
                             )}
                    </div>
                  </div>
                  <br />
                  <div className='row'>
                  { (emp_id !== employee_id) && (emp_manager === emp_id) || (dept_id === 5) ?
                   (
                   <div className='col-md-12 d-flex justify-content-end'>
                   <button className='btn btn-info btn-sm' onClick={fnf_details}>Full And Final</button>
                  </div>
                ) : null
              }
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ResignDescription;