import React from 'react';
import './popup.css';
import  secureLocalStorage  from  "react-secure-storage";



const Popup = ({ data }) => {
   const  department = secureLocalStorage.getItem('department');
   //console.log(department);

  //console.log(data)
  let message = '';
  let login_time = '';
  let logout_time = '';
  let status = '';
  let hours = '';
  if(data.leavedata){
     message = data.leavedata.leave_name;

  }
  else if(data.leavetaken){
    message = data.leavetaken.name;
   }
 else if(data.wfhdata){
  login_time = data.apiData.login_time;
  logout_time = data.apiData.logout_time;
  hours = data.apiData.working_hours;
  status = data.apiData.status;
  message = 'Work From Home'


 }
 else if(data.rosterdata){
  message = 'Roster Off'


 }

 else if (data.workdata) {
  message = data.workdata.reason;
}

 else if(data.apiData.login_time!={} && !data.leavetaken && !data.leavedata  &&  department !== 'Customer Support' && data.weekday!="Sunday" && data.weekday!="Saturday"){
  login_time = data.apiData.login_time;
  logout_time = data.apiData.logout_time;
  hours = data.apiData.working_hours;
  status = data.apiData.status;


 


 }
 else if(data.apiData.login_time!={} && !data.leavetaken && !data.leavedata  &&  department == 'Customer Support' && !data.rosterdata){
  login_time = data.apiData.login_time;
  logout_time = data.apiData.logout_time;
  hours = data.apiData.working_hours;
  status = data.apiData.status;


 


 }
  return (
    <div className="smalls-cards-popup-s">
      <div className="cards-popups" style={{width: "18rem" ,padding:"20px",backgroundColor:'gold' }}>
       <center>{message}</center>
       {data.apiData!={} && !data.leavetaken && !data.leavedata &&  department !== 'Customer Support' &&  data.weekday!="Sunday" && data.weekday!="Saturday" &&
       <>
        <div className='row'>
            <div className='col-md-6'>LogIn:<br/>
            {login_time}</div>
            <div className='col-md-6'>Hours:<br/>
            {hours}</div>
          </div>

          <div className='row'>
            <div className='col-md-6'>LogOut:<br/>
            {logout_time}</div>
            <div className='col-md-6'>Status:<br/>
            {status}</div>
          </div>
       
       </>
          

       }
        {data.apiData!={} && !data.leavetaken && !data.leavedata && !data.rosterdata && department == 'Customer Support' &&
       <>
        <div className='row'>
            <div className='col-md-6'>LogIn:<br/>
            {login_time}</div>
            <div className='col-md-6'>Hours:<br/>
            {hours}</div>
          </div>

          <div className='row'>
            <div className='col-md-6'>LogOut:<br/>
            {logout_time}</div>
            <div className='col-md-6'>Status:<br/>
            {status}</div>
          </div>
       
       </>
          

       }
       
      </div>
    </div>
  );
};

export default Popup;
