import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MatureClientDetailsHeader from 'layouts/common_components/mature_client_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import  secureLocalStorage  from  "react-secure-storage";

function Mature_client_details(props) 
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addModal,setAddModal]=useState(false);
  const [clientMobile,setClientMobile] = useState("");
  const [clientName,setClientName] = useState('');
  const [clientBusinessName,setClientBusinessName] = useState('');
  const [clientEmail,setClientEmail] = useState('');
  // const [clientCity,setClientCity] = useState('');
  
  const [clientAddress,setClientAddress] = useState();
  const [clientInfo,setClientInfo] = useState();
  const [isDivShow,setDivShow] = useState(false);
  const [cityList,setCityList] = useState([]);
  const [selectedCity,setSelectedCity] = useState();
  const [product,setProduct]=useState([]);
  const [selectedProduct,setSelectedProduct] = useState();
  const [service,setService]=useState([]);
  const [selectedService,setSelectedService] = useState();
    
  const [category,setCategory]=useState([]);
  const [selectedCategory,setSelectedCategory] = useState();

  const [selectedCallStatus,setCallStatus] = useState(''); 
  
  const [followup,setFollowup] = useState([]);

  const [selectedFollowupStatus,setFollowupStatus] = useState('');

  const [nextDays,setNextDays] = useState(0);
  
  const [nextFollowupDate, setNextFollowupDate] = useState('');
  const [remarks , setRemarks] = useState('');
  const [amount,setClientAmount] = useState('');
 
  const [clientDetails, setClientDetails] = useState([]);
  
  const myDate = new Date(nextFollowupDate);
  const nxtFolDate= myDate.toLocaleString();

  const [follwoupModal,setFollowupModal] = useState(false);
  const [selectedFollowCallStatus,setFollCallStatus] = useState(''); 
  const [selectedFollowStatus,setFollStatus] = useState('');
  const [followupRemarks , setFollowupRemarks] = useState('');
  const [followupAmount,setFollowupAmount] = useState('');
  const [nxtFollowupDate, setNxtFollowupDate] = useState('');
  const [nextDays1,setNextDays1] = useState(0);
  const myDate1 = new Date(nxtFollowupDate);
  const nxtFolDate1 = myDate.toLocaleString();
  const [selectedClientId,setClientId] = useState();

  //setClientType(props.name);
  const followupOpenControl=(id)=>{
    setClientId(id);
    setFollowupModal(true);
  }
  
  const closeFollowupControl = ()=>
  {
    setFollowupModal(false);
  }

  const submitFollowupForm = async() =>
  {
      const errors = {};
      if (Object.keys(errors).length > 0) {
        return; 
      }
      const formData = new FormData();
      formData.append('client_id',selectedClientId);
      formData.append('disposition',selectedFollowCallStatus);
      formData.append('followup_status',selectedFollowStatus);
      formData.append('next_followup_date',nxtFolDate1);
      formData.append('remarks',followupRemarks);
      formData.append('amount',followupAmount);
      formData.append('created_by',emp_id);
       
      try 
      {
          const response = await dashboardApi.post('add-client-followup', formData);
            if(response.data.status === 200)
            {
                Swal.fire({
                icon: 'success',
                text: response.data.message,
                });

              navigate("/guest-client-details");
              closeModalOpenControl();
            }
            else
            {
              Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
              });
            }
      }catch (error){
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
      } 
  }
  
  const submitForm = async() =>
  {
      const errors = {};
      if (Object.keys(errors).length > 0) {
        return; 
      }
      const formData = new FormData();
      formData.append('mobile',clientMobile);
      formData.append('name',clientName);
      formData.append('business_name',clientBusinessName);
      formData.append('email',clientEmail);
      formData.append('city',selectedCity);
      formData.append('address',clientAddress);
      formData.append('product_id',selectedProduct);
      formData.append('service_id',selectedService);
      formData.append('category_id',selectedCategory);

      formData.append('disposition',selectedCallStatus);
      formData.append('followup_status',selectedFollowupStatus);
      formData.append('next_followup_date',nxtFolDate);
      formData.append('remarks',remarks);
      formData.append('amount',amount);
      formData.append('created_by',emp_id);
       
      try 
      {
          const response = await dashboardApi.post('add-guest-client', formData);
            if(response.data.status === 200)
            {
                Swal.fire({
                icon: 'success',
                text: response.data.message,
                });

              navigate("/guest-client-details");
              closeModalOpenControl();
            }
            else
            {
              Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
              });
            }
      }catch (error){
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
      } 
  }

    useEffect(() => {
      if (!token) {
        navigate("/sign-in");
      }
    }, [token, navigate]);

    useEffect(()=>{
      checkClientsDetails();
      getCityDetails();
      getProduct();
    },[])

    const addModalOpenControl=()=>{
      setAddModal(true);
    }

    const closeModalOpenControl = ()=>
    {
      setAddModal(false);
    }
      
    const checkClientsDetails = async () =>
    {
      if(clientMobile)
      {
        try {
          const response = await dashboardApi.get(`check-client-mobile-number/${clientMobile}`);
          if (response.data.status === 200)
          {
            setDivShow(false);
            setClientInfo(response.data.message);
          } 
          else
          {
            setDivShow(true);
            setClientInfo('');
          }
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
    }
  
    function checkMobileNumber()
    {
      checkClientsDetails();
    }

    const checkInput = (e) => {
      const number = e.target.value.replace(/\D/g, "");
      setClientMobile(number);
    };

    const getCityDetails= async()=>
    {
      try {
          const response = await dashboardApi.get(`get-city-details`);
          if (response.data.status === 200)
          {
            setCityList(response.data.data);
          } 
          else
          {
           console.log('Data not found');
          }
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
    }

    const getProduct = async () => 
    {
      try{
        const response = await dashboardApi.get(`sales-product-list/active`);
        if(response.data.status === 200)
        {
          setProduct(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    };

    useEffect(()=>{
      if(selectedProduct)
      {
        const fetchService = async () => {
          try {
            const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
            if (response.data.status === 200) {
              setService(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchService();
      }
    },[selectedProduct]);
  
      useEffect(()=>{
          if(selectedService)
          {
          const fetchCategory = async () => {
            try {
              const response = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
              if (response.data.status === 200) {
                  setCategory(response.data.data);
              } else {
                  console.error('Error fetching countries:', response.data.message);
              }
              setLoading(false);
              } catch (error) {
              console.error('Error fetching countries:', error);
              setLoading(false);
              }
          }
          fetchCategory();
          }
      },[selectedService]);

      useEffect(()=>{
        if(selectedCallStatus)
        {
          

        const getFollowupStatus = async () => {
            try {
            const response = await dashboardApi.get(`get-followup-list/${selectedCallStatus}`);
            if (response.data.status === 200) {
              setFollowup(response.data.data);
             } else {
                console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
            } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
            }
        }
        getFollowupStatus();
        }
    },[selectedCallStatus]);

    useEffect(()=>{
      if(selectedFollowCallStatus)
      {
        

      const getFollowupStatus = async () => {
          try {
          const response = await dashboardApi.get(`get-followup-list/${selectedFollowCallStatus}`);
          if (response.data.status === 200)
          {
            setFollowup(response.data.data);
          } else {
              console.error('Error fetching countries:', response.data.message);
          }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
      }
      getFollowupStatus();
      }
  },[selectedFollowCallStatus]);
  

    useEffect(()=>{
      if(selectedFollowupStatus)
      {
      const getNextFollowupDate = async () => {
          try {
          const response = await dashboardApi.get(`get-next-followup-date/${selectedFollowupStatus}`);
          if (response.data.status === 200) {
            setNextFollowupDate('');
            setNextDays(response.data.data);
            
          } else {
              console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
          } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
          }
      }
      getNextFollowupDate();
      }
  },[selectedFollowupStatus]);

  useEffect(()=>{
    if(selectedFollowStatus)
    {
    const getNextFollowupDate = async () => {
        try {
        const response = await dashboardApi.get(`get-next-followup-date/${selectedFollowStatus}`);
        if (response.data.status === 200) {
          setNextFollowupDate('');
          setNextDays1(response.data.data);
          
        } else {
            console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
        } catch (error) {
        console.error('Error fetching countries:', error);
        setLoading(false);
        }
    }
    getNextFollowupDate();
    }
},[selectedFollowStatus]);

  useEffect(()=>{
    if(emp_id)
    {
      const getClientDetails = async () => {
        try {
          const response = await dashboardApi.get(`get-mature-client-details/${emp_id}`);
          if (response.data.status === 200) {
            setClientDetails(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      getClientDetails();
    }
  },[emp_id]);
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <MatureClientDetailsHeader></MatureClientDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                      {/* <Link className='btn btn-info' onClick={addModalOpenControl}>
                          +
                      </Link> */}
                      </div>
                  </div>

                  {loading ? (
                    <p style={{align:'center'}}>
                    <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                    </p>
                ) : (
                  <table className="table table-bordered table-hovered">
                  
                  <tr>
                    <td>S.No</td>
                    <td>Client Info</td>
                    <td>Contact Info</td>
                    <td>City</td>
                    <td>Status</td>
                    <td>Action</td>
                  </tr>

                  {clientDetails && clientDetails.map((cd,index)=>(
                    <tr>
                        <td>{index + 1}</td>
                        <td>{cd.client_name} <br /> {cd.business_name}</td>
                        <td>{cd.mobile_no} <br /> {cd.email}</td>
                        <td>{cd.city}</td>
                        <td>{cd.status === 1 ?'Active':'Deactive'}</td>
                        <td>
                          <Link className='btn btn-info' onClick={() => followupOpenControl(cd.id) }>
                            Followup
                          </Link>&nbsp;&nbsp;&nbsp;&nbsp;
                          
                          <Link className="btn btn-primary mb-2 float-end" to={`/mature-client-profile/${cd.client_id}`}>
                            View
                          </Link>
                        </td>
                    </tr>
                    ))
                  }
              </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      
      

      <Dialog open={addModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
        <DialogTitle>Add Client Details</DialogTitle>
            <DialogContent>
                <form>
                <div className="row">
                  <div className="col-sm-6">
                      <label>Check Mobile No</label>
                      <TextField type="text"  maxLength={10}  className='form-control' value={clientMobile} onChange={(e) =>checkInput(e)} ></TextField>
                      <p style={{color:'red'}}>{clientInfo}</p>
                  </div>
                  <div className="col-sm-6">
                      <Button style={{marginTop:'35px',float:'right'}} onClick={checkMobileNumber}>Check Client Details</Button>
                  </div>
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  
                      <p style={{ margin: "0 10px" }}>Basic Details</p>
                  
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                </div>


                {isDivShow && (
                <div className="row">
                  <div class="col-sm-6">
                      <label>Client Name</label>
                      <TextField type="text" className='form-control' value={clientName} onChange={(e)=>setClientName(e.target.value)}></TextField>
                  </div>

                  <div class="col-sm-6">
                      <label>Business Name</label>
                      <TextField type="text" className='form-control' value={clientBusinessName} onChange={(e)=>setClientBusinessName(e.target.value)}></TextField>
                  </div>

                  <div class="col-sm-6">
                      <label>Mobile Number</label>
                      <TextField type="text" className='form-control' value={clientMobile} readOnly></TextField>
                  </div>

                  <div class="col-sm-6">
                      <label>Email Id</label>
                      <TextField type="text" className='form-control' value={clientEmail} onChange={(e)=>setClientEmail(e.target.value)}></TextField>
                  </div>

                  <div class="col-sm-6">
                    <label>Select City (Group)</label>
                    <select className="form-control"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        >
                        <option value=''>Select City</option>
                        {cityList.map((city) => (
                        <option key={city.group_id} value={city.group_id}>
                        {city.name}
                        </option>
                        ))}
                    </select>
                  </div>

                  <div class="col-sm-12">
                      <label>Address</label>
                      <TextField type="text" className='form-control' value={clientAddress} onChange={(e)=>setClientAddress(e.target.value)}></TextField>
                  </div>

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Product Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div className="col-sm-6">
                    <label>Salect Product</label>
                    <select className="form-control" value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}>
                          <option value=''>Select Product</option>
                          {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                    </select>
                  </div>
                  {selectedProduct &&(
                  <div className="col-sm-6">
                      <label>Select Service</label>
                      <select className="form-control"
                              value={selectedService}
                              onChange={(e) => setSelectedService(e.target.value)}
                              >
                              <option value=''>Select Service</option>
                              {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                              {ser.service_name}
                              </option>
                              ))}
                      </select>
                  </div>
                  )}

                  {selectedService &&(
                  <div className="col-sm-6">
                      <label>Select Category</label>
                      <select className="form-control"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          >
                          <option value=''>Select Category</option>
                          {category.map((cate) => (
                          <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                          </option>
                          ))}
                      </select>
                  </div>
                )}
                {/* {selectedCategory &&( */}
                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  
                      <p style={{ margin: "0 10px" }}>Followup Details</p>
                  
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                {/* )} */}

                {/* {selectedCategory &&( */}
                  <div className="col-sm-6">
                      <label>Call Status</label>
                      <select className="form-control"
                          value={selectedCallStatus}
                          onChange={(e) => setCallStatus(e.target.value)}
                          >
                          <option value=''>Select Call Status</option>
                          <option value='Connected'>Connected</option>
                          <option value='Not_Connected'>Not Connected</option>
                        </select>
                  </div>
                {/* )} */}

                {selectedCallStatus  &&(
                  <div className="col-sm-6">
                      <label>Followup Status</label>
                      <select className="form-control"
                          value={selectedFollowupStatus}
                          onChange={(e) => setFollowupStatus(e.target.value)}
                          >
                          <option value=''>Select Followup Status</option>
                          {followup.map((follo) => (
                          <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                          </option>
                          ))}
                        </select>
                  </div>
                )}
                
                

                {(selectedFollowupStatus ==6 || selectedFollowupStatus ==9 || selectedFollowupStatus ==18)  &&(
                  
                  <div className="col-sm-6">
                      <label>Amount</label>
                      <TextField type="text" className='form-control' value={amount} onChange={(e)=>setClientAmount(e.target.value)}></TextField>
                  </div>
                )}

                {selectedFollowupStatus &&(
                  
                  <div className="col-sm-6">
                      <label>Next Followup Date</label>
                      <DatePicker className="form-control"
                        minDate={new Date()}
                        maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                        selected={nextFollowupDate}
                        onChange={(date) => setNextFollowupDate(date)}
                        dateFormat="dd/MM/YYYY"
                        onKeyDown={(e) => {
                          e.preventDefault();
                       }}
                      />
                  </div>
                )}

                {selectedFollowupStatus &&(
                  
                  <div className="col-sm-12">
                    <label>Remarks</label>
                    <TextField type="text" className="form-control" value={remarks} onChange={(e) => setRemarks(e.target.value)}></TextField> 
                  </div>
                )}
                

                </div>
              )}
              </form>
            </DialogContent>

            <DialogActions>
                <Link className="btn btn-primary btn-sm" onClick={closeModalOpenControl}>Close</Link>
                {isDivShow && (
                <Link className="btn btn-success btn-sm" onClick={submitForm}>Add Details</Link>
                )}
            </DialogActions>
      </Dialog>
      
      <Dialog open={follwoupModal} fullWidth PaperProps={{ style: { maxWidth: '50%', padding: '20px' } }}>
      <DialogTitle>Client Followup</DialogTitle>
        <DialogContent style={{height:"350px"}}>
            <form>
              <div className="row">
                
                <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                    <p style={{ margin: "0 10px" }}>Followup Details</p>
                    <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                </div>
          
                <div className="col-sm-6">
                    <label>Call Status</label>
                    <select className="form-control"
                        value={selectedFollowCallStatus}
                        onChange={(e) => setFollCallStatus(e.target.value)}
                        >
                        <option value=''>Select Call Status</option>
                        <option value='Connected'>Connected</option>
                        <option value='Not_Connected'>Not Connected</option>
                    </select>
                </div>
          

                {selectedFollowCallStatus &&(
                  <div className="col-sm-6">
                      <label>Followup Status</label>
                      <select className="form-control"
                          value={selectedFollowStatus}
                          onChange={(e) => setFollStatus(e.target.value)}
                          >
                          <option value=''>Select Followup Status</option>
                          {followup.map((follo) => (
                          <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                          </option>
                          ))}
                        </select>
                  </div>
                )}

              {(selectedFollowStatus ==6 || selectedFollowStatus ==9 || selectedFollowStatus ==18)  &&(
                <div className="col-sm-6">
                    <label>Amount</label>
                    <TextField type="text" className='form-control' value={followupAmount} onChange={(e)=>setFollowupAmount(e.target.value)}></TextField>
                </div>
              )}
              
          
              {selectedFollowStatus &&(
                <div className="col-sm-6">
                    <label>Next Followup Date</label>
                    <DatePicker className="form-control" style={{position: 'absolute'}}
                      minDate={new Date()}
                      maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays1))}
                      selected={nxtFollowupDate}
                      onChange={(date) => setNxtFollowupDate(date)}
                      dateFormat="dd/MM/YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    />
                </div>
              )}
            
              

              {selectedFollowStatus &&(
                <div className="col-sm-12">
                  <label>Remarks</label>
                  <TextField type="text" className="form-control" value={followupRemarks} onChange={(e) => setFollowupRemarks(e.target.value)}></TextField> 
                </div>
              )}
              </div>
        </form>
        </DialogContent>

        <DialogActions>
            <Link className="btn btn-primary btn-sm" onClick={closeFollowupControl}>Close</Link>
            {isDivShow && (
            <Link className="btn btn-success btn-sm" onClick={submitFollowupForm}>Add Details</Link>
            )}
        </DialogActions>
    </Dialog>

    </DashboardLayout>
  );
}
export default Mature_client_details;
