// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import  secureLocalStorage  from  "react-secure-storage";
import { Link } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

// Data
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
function ViewpositionDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [receivelist, setreceivelist] = useState([]);
  //const [ids,setid] = useState('');
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [status, setstatus] = useState([]);
  const [remark, setremark] = useState('');
  const [name,setname] = useState('');
  const [mobile,setmobile] = useState('');
  const [email,setemail] = useState('');
  const [image,setimage] = useState(null);
  const [resource,setresource] = useState('');
  const [position,setposition] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [replace_emp,setreplace_emp] = useState({});
  const [followup_details,setfollowup_details] = useState([]);
  const [expectation,setexpectation] = useState('');
  const [editmodal,seteditmodal] = useState(false);
  const [candidate_id,setcandidate_id] = useState('');
  const[final_salary,setfinal_salary] = useState('');
  const[address,setaddress] = useState('');
  const [doj,setdoj] = useState('');
  const [fix_salary,setfix_salary] = useState('');
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchData();
    fetchDetails();
   // fetchemployee();
   },[])
  const fetchData = async () => {
    try {
      const endpoint = `followup-candidate-details/${id}/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setreceivelist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const fetchDetails = async () => {
    try {
      const endpoint = `job-position-details/${id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setposition(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'active-followup-attribute';
        const response = await dashboardApi.get(endpoint);
        //console.log(response);
  
        if (response.data.status === 200) {
          setfollowup_details(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  useEffect(() => {
    if (position.replace_to) {
      const fetchData = async () => {
        try {
          const endpoint = `emp-details/${position.replace_to}`;
          const response = await dashboardApi.get(endpoint);
  
          if (response.data.status === 200) {
            setreplace_emp(response.data.data);
             //setLoading(false);
          } else {
            console.error('Error fetching states data:', response.data.message);
          }
          //setLoading(false);
        } catch (error) {
          console.error('Error fetching states data:', error);
          //setLoading(false);
        }
      };
  
      fetchData();
    }
  }, [position.replace_to]);

  const editprofile = async (id)=>{
     setcandidate_id(id);
    try {
        const endpoint = `edit-candidate-profile/${id}`;
        const response = await dashboardApi.get(endpoint);
        //console.log(response.data.data);
       if (response.data.status === 200) {
         setremark(response.data.data.hr_remark);
         setValidationErrors('');
         setOpenModal(false);
         setemail(response.data.data.email);
         setmobile(response.data.data.mobile);
        setname(response.data.data.candidate_name);
        setresource(response.data.data.resource_from);
        setexpectation(response.data.data.salary_expectation);
        setfinal_salary(response.data.data.final_ctc)
        setfix_salary(response.data.data.fixed_salary)
        setaddress(response.data.data.address)
        setdoj(response.data.data.date_of_joining)
      
        } else {
          console.error('Error fetching data:');
        }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }


    //setopenmodal(true);
    seteditmodal(true);
   
  }
 // console.log(candidate_id);
  //console.log(replace_emp);

const handleOpenModal = async () => {
  //alert(request_id);
    //setid(request_id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    //setid('');
    setremark('');
    setValidationErrors('');
    setOpenModal(false);
    setemail('');
    setmobile('');
    setname('');
    setimage('');
    setresource('');
    setstatus('');
    setexpectation('');
    seteditmodal(false);
   // se
  };
  const handlesave = async(e)=>{
    e.preventDefault();
    const errors = {};
    const formData = new FormData();
    formData.append('name',name);
    formData.append('remark',remark);
    formData.append('job_id',id);
    formData.append('emp_id',emp_id);
    formData.append('mobile',mobile);
    formData.append('email',email);
    formData.append('image',image);
    formData.append('status',status);
    formData.append('resource',resource);
    formData.append('salary',expectation);
    
    //setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('save-followup-details', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchData();
        handleCloseModal();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }

 }
 const handleupdate = async(e)=>{
    e.preventDefault();
    const errors = {};
    const formData = new FormData();
    formData.append('name',name);
    formData.append('remark',remark);
    formData.append('mobile',mobile);
    formData.append('email',email);
    formData.append('status',status);
    formData.append('resource',resource);
    formData.append('salary',expectation);
    formData.append('doj',doj);
    formData.append('final_salary',final_salary);
    formData.append('address',address);
    formData.append('fix_salary',fix_salary);
    
    //setIsSubmitting(true);
    try {
      const response = await dashboardApi.post(`update-candidate-profile/${candidate_id}`, formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchData();
        handleCloseModal();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }


 }

 //console.log(receivelist);

//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-8">
                    <Typography variant="h4" align="center">Job Description</Typography>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td><strong>Department:</strong> {position.department_name}</td>
                          <td><strong>Required Experience:</strong> {position.exprience}</td>
                        </tr>
                        <tr>
                          <td><strong>Salary Range:</strong> {position.salary}</td>
                          <td><strong>Skill:</strong> {position.skill}</td>
                        </tr>
                        <tr>
                          <td><strong>Position Opening Date:</strong> {position.date}</td>
                          <td><strong>Title:</strong> {position.title}</td>
                        </tr>
                         <tr>
                          <td style={{maxWidth:'12px'}}><strong>Remark:</strong> {position.remark}</td>
                          <td style={{maxWidth:'12px'}}><strong>Branch:</strong> {position.branch}</td>
                        </tr>
                        <tr>
                          <td style={{maxWidth:'12px'}}><strong>Assigned Person:</strong> {position.assign_member}</td>
                          <td style={{maxWidth:'12px'}}><strong>Expected End Date For This Position :</strong> {position.end_date}</td>
                        </tr>
                        <tr>
                        <td style={{maxWidth:'12px'}}><strong>Close Date:</strong> {position.close_date}</td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <Typography variant="h4" align="center">Replacement</Typography>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td><strong>Emp Name:</strong> {replace_emp.emp_name}</td>
                          <td><strong>Department:</strong> {replace_emp.emp_department}</td>
                        </tr>
                        <tr>
                          <td><strong>Designation:</strong> {replace_emp.emp_designation}</td>
                          <td><strong>Branch:</strong> {replace_emp.emp_branch}</td>
                        </tr>
                        <tr>
                          <td><strong>CTC:</strong> {replace_emp.emp_package}</td>
                          <td><strong>Date of Join:</strong> {replace_emp.doj}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
               <div className="card-body">
                <Link className="btn btn-info btn-sm" onClick={handleOpenModal}>
                   Add Candidate
                 </Link>
                   {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Name</td>
                  <td>Mobile</td>
                  <td>Email</td>
                  <td>CV</td>
                  <td>Followup Status</td>
                  <td>Resource From</td>
                  <td>Remark</td>
                  <td>Final Status</td>
                  <td>Action</td>
               </tr>
              {receivelist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.candidate_name}</td>
                  <td>{row.mobile}</td>
                  <td>{row.email}</td>
                   <a
                   href={`http://192.168.1.253:4000/candidate_followup/${row.resume}`}
                   download="cv"
                  >
                  <FontAwesomeIcon icon={faDownload} /> Download
                  </a>
                  <td>{row.name}</td>
                  <td>{row.resource_from}</td>
                   <td>{row.hr_remark}</td>
                   <td>{row.final_status}</td>
                  <td>
                  <Link to = {`/candidate-profile/${row.id}/${id}`}
                  className="btn btn-info btn-sm">Profile</Link>&nbsp;

                  <button className="btn btn-info btn-sm" onClick={() => editprofile(row.id)}>Edit</button>

                  </td>

                  </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Add Details</DialogTitle>
        <DialogContent>
        <form>
            <div class="container">
            <div class = "row">
            <div class = "col-md-6">
              <label>Candidate Name:</label>
              <TextField
                type="text"
                fullWidth
                 value={name}
                onChange={(e) => setname(e.target.value)}
                 placeholder = "Name"
                  />
                {validationErrors.name && (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                )}
             </div>
              <div className="col-md-6">
             <label>Mobile:</label>
              <TextField
                type="text"
                fullWidth
                 value={mobile}
                onChange={(e) => setmobile(e.target.value)}
                 placeholder = "mobile"
              />
            </div>

            </div>

            <div class = "row">
            <div class = "col-md-6">
              <label>Email:</label>
              <TextField
                type="text"
                fullWidth
                 value={email}
                onChange={(e) => setemail(e.target.value)}
                 placeholder = "email"
              />
            </div>
              <div className="col-md-6">
             <label>Resource From:</label>
              <TextField
                type="text"
                fullWidth
                 value={resource}
                onChange={(e) => setresource(e.target.value)}
                 placeholder = "Resource"
              />
              {validationErrors.resource && (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.resource}</div>
                )}
            </div>
            </div>
            <div class = "row">
            <div class = "col-md-6">
              <label>Upload Cv:</label>
              <TextField
               type = "file"
               fullWidth
               onChange={(e) => setimage(e.target.files[0])}
                 />
              </div>
              <div className="col-md-6">
             <label>Status:</label>
             <select className="form-control" value={status} onChange={(e) => setstatus(e.target.value)} fullWidth>
             <option value=" ">Change Status</option>
                {followup_details.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.name}
                              </option>
                              ))}
              </select>
              {validationErrors.status && (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                )}
                  
            </div>

            </div>
             <label>Remark:</label>
              <TextField
               type = "text"
               fullWidth
               placeholder = "remark"
               value={remark}
                  onChange={(e) => setremark(e.target.value)}
                />
                {validationErrors.remark && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
             )}

            <label>salary expectations(LPA):</label>
              <TextField
               type = "text"
               fullWidth
               placeholder = "salary expectations"
               value={expectation}
                  onChange={(e) => setexpectation(e.target.value)}
                />

            </div>

          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick ={handlesave}>
            Save
          </Link>
        </DialogActions>
      </Dialog>

       <Dialog
        open={editmodal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth='sm'
        PaperProps={{ style: { maxHeight: '100vh', padding: '20px' } }}
      >
        <DialogTitle>Edit Details</DialogTitle>
        <DialogContent>
        <form>
            <div class="container">
            <div class = "row">
            <div class = "col-md-6">
              <label>Candidate Name:</label>
              <TextField
                type="text"
                fullWidth
                 value={name}
                onChange={(e) => setname(e.target.value)}
                 placeholder = "Name"
                  />
                {validationErrors.name && (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                )}
             </div>
              <div className="col-md-6">
             <label>Mobile:</label>
              <TextField
                type="text"
                fullWidth
                 value={mobile}
                onChange={(e) => setmobile(e.target.value)}
                 placeholder = "mobile"
              />
            </div>

            </div>

            <div class = "row">
            <div class = "col-md-6">
              <label>Email:</label>
              <TextField
                type="text"
                fullWidth
                 value={email}
                onChange={(e) => setemail(e.target.value)}
                 placeholder = "email"
              />
            </div>
              <div className="col-md-6">
             <label>Resource From:</label>
              <TextField
                type="text"
                fullWidth
                 value={resource}
                onChange={(e) => setresource(e.target.value)}
                 placeholder = "Resource"
              />
              {validationErrors.resource && (
                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.resource}</div>
                )}
            </div>
            </div>
             <label>Remark:</label>
              <TextField
               type = "text"
               fullWidth
               placeholder = "remark"
               value={remark}
                  onChange={(e) => setremark(e.target.value)}
                />
                {validationErrors.remark && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
             )}

            <label>salary expectations(LPA):</label>
              <TextField
               type = "text"
               fullWidth
               placeholder = "salary expectations"
               value={expectation}
                  onChange={(e) => setexpectation(e.target.value)}
                />

          

             <label>Final CTC(LPA):</label>
              <TextField
               type = "text"
               fullWidth
               placeholder = "Final salary"
               value={final_salary}
                  onChange={(e) => setfinal_salary(e.target.value)}
                />

              <label>Fixed Salary(LPA):</label>
              <TextField
               type = "text"
               fullWidth
               placeholder = "Fixed salary"
               value={fix_salary}
                  onChange={(e) => setfix_salary(e.target.value)}
                />


             <label>Address:</label>
              <TextField
               type = "text"
               fullWidth
               placeholder = "Address"
               value={address}
                  onChange={(e) => setaddress(e.target.value)}
                />

              <label>Date of Join:</label>
              <TextField
               type = "date"
               fullWidth
               placeholder = "Address"
               value={doj}
                  onChange={(e) => setdoj(e.target.value)}
                />

                </div>

           

          </form>
        </DialogContent>
        <DialogActions>
           <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
          <Link className="btn btn-info" onClick ={handleupdate}>
            Save
          </Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default ViewpositionDetails;