import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import OfferDetailsHeader from 'layouts/common_components/offer_details_header';
import { TextField } from '@mui/material';
import Button from 'react-bootstrap/Button';
import secureLocalStorage from "react-secure-storage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";
import {parseISO, isBefore, format} from 'date-fns';

function Offer_grab_history()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  
  const [paymentReports, setPaymentReports] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [postPerPage] = useState(10);
  const [selectFromDate, setFilterDateFrom] = useState('');
  const [selectToDate, setFilterDateTo] = useState('');
  const [dateError, setDateError] = useState('');
  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState(1); 

  useEffect(() => {
    getExeDetails();
  }, []);

  useEffect(() => {
    getPaymentReports();
  }, [currentPage]);

  const handleFromDateChange = (e) => {
    setFilterDateFrom(e.target.value);
    validateDates(e.target.value, selectToDate);
  };

  const handleToDateChange = (e) => {
    setFilterDateTo(e.target.value);
    validateDates(selectFromDate, e.target.value);
  };

  const validateDates = (from, to) => {
    if (from && to) {
      const fromDateObj = parseISO(from);
      const toDateObj = parseISO(to);

      if (isBefore(toDateObj, fromDateObj)) {
        setDateError('To date cannot be before from date.');
        setFilterDateTo('');
      } else {
        setDateError('');
      }
    }
  };

  const getPaymentReports = async () => {
    try {
      const response = await dashboardApi.get(`get-payment-history-reports`, {
        params: {
          emp_id,
          from_date: selectFromDate,
          to_date: selectToDate,
          per_page: postPerPage,
          page: currentPage + 1,
        }
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const exeData = responseData.data || responseData;
        setPaymentReports(exeData);
        setNumber(responseData.last_page);
        setFilterDateFrom(response.data.from_date);
        setFilterDateTo(response.data.to_date);
      } else {
        console.error('Error fetching payment reports:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching payment reports:', error);
      setLoading(false);
    }
  }

  const getExeDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
      if (response.data.status === 200) {
        setExeDetails(response.data.data);
      } else {
        console.error('Error fetching executive details:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching executive details:', error);
      setLoading(false);
    }
  }

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <OfferDetailsHeader />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="fromDate">From Date:</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="form-control"
                      onChange={handleFromDateChange}
                      value={selectFromDate}
                    />
                  </div>

                  <div className="col-sm-2">
                    <label htmlFor="toDate">To Date:</label>
                    <input
                      type="date"
                      id="toDate"
                      className="form-control"
                      onChange={handleToDateChange}
                      value={selectToDate}
                    />
                    {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                  </div>

                  <div className="col-sm-2">
                    <br />
                    <Button className="btn btn-primary" onClick={getPaymentReports}>Search</Button>
                  </div>
                </div>

                {loading ? (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }} alt="Loading..." />
                  </div>
                ) : (
                  <div style={{ overflowY: "hidden", marginTop: '20px' }}>
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Business Name</th>
                          <th>Product Name</th>
                          <th>Service Name</th>
                          <th>Package Id</th>
                          <th>Payment For</th>
                          <th>Paid Amount</th>
                          <th>Reg Amount</th>
                          <th>Tax Amount</th>
                          <th>Invoice Name</th>
                          <th>Created By</th>
                          <th>Created Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentReports && paymentReports.map((pr, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{pr.business_name}</td>
                            <td>{pr.product_name}</td>
                            <td>{pr.service_name}</td>
                            <td>{pr.package_id}</td>
                            <td>{pr.payment_for}</td>
                            <td>{pr.paid_amount}</td>
                            <td>{pr.reg_amount}</td>
                            <td>{pr.tax_amount}</td>
                            <td>{pr.invoice_name}</td>
                            <td>{pr.created_by_name}</td>
                            <td>{format(parseISO(pr.created_date), 'yyyy-MM-dd')}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="12">
                            <Pagination className="custom-pagination">
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={number}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                pageLinkClassName={"page-link-style"}
                                breakLinkClassName={"break-link-style"}
                                previousLinkClassName={"prev-link-style"}
                                nextLinkClassName={"next-link-style"}
                                forcePage={currentPage}
                              />
                            </Pagination>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Offer_grab_history;
