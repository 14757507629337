import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";


function EmployeeDetailsHeader(props) {

  return (
            <Card>
              <div className="card-body">
                  <div className="container-fluid">
                  <div className="row">
                 
                  <Link to={`/edit-employee/${props.id}`} className='btn btn-outline-primary'>
                            Basic Info
                  </Link>
                  &nbsp; &nbsp;
                  
                  
                 <Link to={`/document-info/${props.id}`} className='btn btn-outline-primary'>
                            Document Details
                  </Link>
                  &nbsp; &nbsp;
                 
                 
                 <Link to={`/account-info/${props.id}`} className='btn btn-outline-primary'>
                             Account Info
                  </Link>
                  &nbsp; &nbsp;
                
                  
                  <Link to={`/sallary-info/${props.id}`} className='btn btn-outline-primary'>
                             Salary Details
                  </Link>
                  &nbsp; &nbsp;
                 
                  
                  <Link to={`/module-permission/${props.id}`} className='btn btn-outline-primary'>
                             Module Permission
                  </Link>
                   &nbsp; &nbsp;

                  
                   
                   <Link to={`/leave-permission/${props.id}`} className='btn btn-outline-primary'>
                             Leave Permission
                  </Link>
                  &nbsp;
                 

                  </div>
                  </div>
              </div>
            </Card>
  );
}


export default EmployeeDetailsHeader;
