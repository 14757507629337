// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import SuperadminHeader from "layouts/common_components/superadminheader";

function QuestionOption() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [productlist, setproductlist] = useState([]);
  const [product,setproduct] = useState('');
  const [utmlist,setutmlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [name, setname] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [editopenmodel,seteditopenmodel] = useState(false);
  const [servicelist,setservicelist] = useState([]);
  const [service,setservice] = useState('');
  const[id,setid] = useState('');
  const [question,setquestion] = useState('');
  const [checked,setchecked] = useState('');
  const [questionlist,setquestionlist] = useState([]);
  const [selectedquestion,setselectedquestion] = useState('');
  const [checkoptiontype,setcheckoptiontype] = useState('');
  const [inputList, setInputList] = useState([{optionvalue: "",}]);
  const [questionoptionlist,setquestionoption] = useState([]);
  const [optionedit,setoptionedit] = useState('');

   const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, {optionvalue: ""}]);
  };

  //console.log(JSON.stringify(inputList));
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchquestion();
    productdetails();
    fetchquestionoption();

  },[])
  const fetchquestion = async () => {
    try {
      const endpoint = 'question-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setquestionlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchquestionoption = async () => {
    try {
      const endpoint = 'question-option-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setquestionoption(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    if(product){
    const fetchData = async () => {
      try {
        const endpoint = `get-service-list-by-product-id/${product}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
            setservicelist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }
  }, [product]);


  useEffect(() => {
    if(selectedquestion){
    const fetchData = async () => {
      try {
        const endpoint = `question-option-type/${selectedquestion}`;
        const response = await dashboardApi.get(endpoint);
        //console.log(response);

        if (response.data.status === 200) {
            setcheckoptiontype(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }
  }, [selectedquestion]);


  //console.log(checkoptiontype);






  const closemodel = ()=>{
   // alert('kk');
    setproduct(' ');
    setservice('');
    setselectedquestion('');
    setchecked(false);
    setValidationErrors({});
    setOpenModal(false);
    seteditopenmodel(false);
     setInputList([{optionvalue: ''}]);
    //setInputList({});


  }


const openpopup = ()=>{
    setOpenModal(true);

}
const editform = async(e)=>{
    setid(e);
        try {
            const endpoint = `option-edit/${e}`;
            const response = await dashboardApi.get(endpoint);
           if (response.data.status === 200) {
            //console.log(response.data.data.department_id);
            setproduct(response.data.data.product_id);
            setservice(response.data.data.service_id);
            setselectedquestion(response.data.data.question_id);
            setoptionedit(response.data.data.option_name)
           //setQuantity(response.data.data.quantity);
          //setselectedemployee(assignToValues);
            } else {
              console.error('Error fetching data:');
            }
          // setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            //setLoading(false);
          }
          seteditopenmodel(true);




    

}
// const toggleStatus = async (documentId) => {
//    // alert(documentId);
//     const isConfirm = await Swal.fire({
//             title: 'Are you sure?',
//             text: "Are You Want To Change Status!",
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#3085d6',
//             cancelButtonColor: '#d33',
//             confirmButtonText: 'Yes, Change it!'
//           }).then((result) => {
//             return result.isConfirmed
//           });

//           if(!isConfirm){
//             return;
//           }
//     const response = await dashboardApi.get(`utm-status/${documentId}`);
//     if (response.data.status === 200) {
//       const currentStatus = response.data.data;
//       const newStatus = currentStatus === 1 ? 0 : 1;
//       const updateResponse = await dashboardApi.post('utm-status-change', {
//         id:documentId,
//         status: newStatus,
//       });
//       if (updateResponse.data.status === 200) {
//         // Update the local state with the new status
//         setutmlist((prevState) =>
//           prevState.map((docObj) =>
//           docObj.id === documentId ? { ...docObj, status: newStatus } : docObj
//           )
//         );
//       } else {
//         console.error('Error updating status:', updateResponse.data.message);
//       }
//     } else {
//       console.error('Error fetching current status:', response.data.message);
//     }
//   };
  const savequestion = async (e) => {
    e.preventDefault();
    const errors = {};
    setValidationErrors(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('product',product);
    formData.append('service',service);
    formData.append('question',selectedquestion);
    formData.append('emp_id',emp_id);
    formData.append('optionvalue',JSON.stringify(inputList));

    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-question-option', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
       // alert();
       //setOpenModal(false);
       closemodel();
       fetchquestionoption();

      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
   finally {
        //setIsSubmitting(false);
      }
  };
}
const toggleStatus = async (documentId) => {
  // alert(documentId);
   const isConfirm = await Swal.fire({
           title: 'Are you sure?',
           text: "Are You Want To Change Status!",
           icon: 'warning',
           showCancelButton: true,
           confirmButtonColor: '#3085d6',
           cancelButtonColor: '#d33',
           confirmButtonText: 'Yes, Change it!'
         }).then((result) => {
           return result.isConfirmed
         });

         if(!isConfirm){
           return;
         }
   const response = await dashboardApi.get(`option-status/${documentId}`);
   if (response.data.status === 200) {
     const currentStatus = response.data.data;
     const newStatus = currentStatus === 1 ? 0 : 1;
     const updateResponse = await dashboardApi.post('option-status-change', {
       id:documentId,
       status: newStatus,
     });
     if (updateResponse.data.status === 200) {
       // Update the local state with the new status
       setquestionoption((prevState) =>
         prevState.map((docObj) =>
         docObj.id === documentId ? { ...docObj, status: newStatus } : docObj
         )
       );
     } else {
       console.error('Error updating status:', updateResponse.data.message);
     }
   } else {
     console.error('Error fetching current status:', response.data.message);
   }
 };

const updatedoc = async(e)=>{
    e.preventDefault();
    const errors = {};
    setValidationErrors(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('product',product);
    formData.append('service',service);
    formData.append('question',selectedquestion);
    formData.append('emp_id',emp_id);
    formData.append('option',optionedit)

    //setIsSubmitting(true);

    try {
     const response =  await dashboardApi.post(`option-update/${id}`, formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        closemodel();
        fetchquestionoption();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
 }
}


//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuperadminHeader/>
          
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={openpopup}>
                    +
                </Link>
                </div>
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Product</td>
                  <td>Service</td>
                  <td>Question</td>
                  <td>Option</td>
                  <td>Status</td>
                  <td>Action</td>

               </tr>
               {questionoptionlist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.product}</td>
                  <td>{row.service}</td>
                  <td>{row.question}</td>
                  <td>{row.option}</td>
                  <td><button
                  type="button"
                  className={`btn btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 <td>
                      <button className="btn btn-info"onClick={() => editform(row.id)}>Edit</button>
                   </td>
               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
            <Dialog
        open={openModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'700px'} }}
      >
        <DialogTitle>Add Question Option</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row">
                <label>Select Product</label>
                  <select
                   className="form-select form-control"
                    value={product}
                    onChange={(e) => setproduct(e.target.value)}
                    //aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select product</option>
                    {productlist.map(pro => (
                      <option key={pro.id} value={pro.id}>
                        {pro.product_name}
                      </option>
                    ))}
                   
                  </select>
               

            </div>

            <div className="row">
                <label>Select Service</label>
                  <select
                    className="form-select form-control"
                    value={service}
                    onChange={(e) => setservice(e.target.value)}
                    //aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select service</option>
                    {servicelist.map(ser => (
                      <option key={ser.id} value={ser.id}>
                        {ser.service_name}
                      </option>
                    ))}
                   
                  </select>
               

            </div>

            <div className="row">
                <label>Select Question</label>
                  <select
                    className="form-select form-control"
                    value={selectedquestion}
                    onChange={(e) => setselectedquestion(e.target.value)}
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="0">Select Question</option>
                    {questionlist.map(ser => (
                      <option key={ser.id} value={ser.id}>
                        {ser.question}
                      </option>
                    ))}
                   
                  </select>
                </div>
                <br/><br/>

               
               {checkoptiontype == 1 && selectedquestion!=0 && inputList.map((x, i) => {
                 return (
                 <div className="box">
                 <input
                    name="optionvalue"
                    placeholder="Enter Option"
                    value={x.optionvalue}
                    onChange={e => handleInputChange(e, i)}
                    className = "form-control"
            />
            <div style={{display:"flex"}}>
            {inputList.length !== 1 && <button
                className="btn btn-danger"
                onClick={() => handleRemoveClick(i)}>-</button>}
            </div>
            <br/>
            <div className="btn-box">
              {inputList.length - 1 === i && <button onClick={handleAddClick} className="btn btn-info">+</button>}
            </div>
          </div>
        );
      })}
           
           
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodel} >
            Close
          </button>
          {checkoptiontype == 1 && selectedquestion!=0 &&
          <button className="btn btn-info" onClick={savequestion} >
            Save
          </button>
        }
        </DialogActions>
       </Dialog>

       <Dialog
        open={editopenmodel}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'530px'} }}
      >
        <DialogTitle>Edit Question Option</DialogTitle>
        <DialogContent>
        <form>
            <div className="container">
              <div className="row">
                <label>Select Product</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={product}
                    onChange={(e) => setproduct(e.target.value)}
                    //aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select product</option>
                    {productlist.map(pro => (
                      <option key={pro.id} value={pro.id}>
                        {pro.product_name}
                      </option>
                    ))}
                   
                  </select>
               

            </div>

            <div className="row">
                <label>Select Service</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={service}
                    onChange={(e) => setservice(e.target.value)}
                    //aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select service</option>
                    {servicelist.map(ser => (
                      <option key={ser.id} value={ser.id}>
                        {ser.service_name}
                      </option>
                    ))}
                   
                  </select>
               

            </div>

            <div className="row">
                <label>Select Question</label>
                  <select
                    class="form-select"
                    classname="form-control"
                    value={selectedquestion}
                    onChange={(e) => setselectedquestion(e.target.value)}
                    //aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Question</option>
                    {questionlist.map(ser => (
                      <option key={ser.id} value={ser.id}>
                        {ser.question}
                      </option>
                    ))}
                   
                  </select>
               

            </div>

            <div className="row">
                <label>Option</label>
                 <input
                 type = "text"
                 placeholder='option'
                 className='form-control'
                 value={optionedit}
                 onChange={(e) => setoptionedit(e.target.value)}
                 
                 />
               

            </div>
           
           
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodel} >
            Close
          </button>
          <button className="btn btn-info" onClick={updatedoc} >
            Update
          </button>
        </DialogActions>
       </Dialog>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default QuestionOption;