import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import secureLocalStorage from "react-secure-storage";


function TicketRaiseHeader() {

  const dept_id = secureLocalStorage.getItem('department_id');



  return (
            <Card>
              <div className="card-body">
                 
              <Link  to='/generate-ticket' className='btn btn-outline-primary'>
                         Raise Ticket
                  </Link>
                  &nbsp; 
                  <Link  to='/raise-details' className='btn btn-outline-primary'>
                          Raise List
                  </Link>
                  &nbsp; 
                 
                   {
                    dept_id !=9 && 
                   <Link to='/ticket-receive' className='btn btn-outline-primary'>
                            Ticket For Dept
                  </Link>
                }
                  &nbsp; 
                 
                  {
                    dept_id !=9 && 
                   <Link to='/ticket-assign' className='btn btn-outline-primary'>
                            Assigned To You
                   </Link>
                 }
                  &nbsp; 
                  {
                    dept_id ==9 &&
                    <Link to='/org-ticket-list' className='btn btn-outline-primary'>
                           Ticket Raise In ORG
                  </Link>
                  }
                  &nbsp; 
                 
                   
                   <Link to='/ticket-new-time-request' className='btn btn-outline-primary'>
                            Time Change Request
                  </Link>
                  &nbsp; 

                  
                   <Link to = '/new-time-request-approval' className='btn btn-outline-primary'>
                           Time Approval
                  </Link>
                  &nbsp; 
                 

                 
                  </div>
            </Card>
  );
}


export default TicketRaiseHeader;
