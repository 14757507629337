import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import StockDetailsHeader from "layouts/common_components/stock_details_header";

function StockRepair() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [requestList, setRequestList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  //const [id,setid] = useState('');
  //const [remark, setRemark] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [status,setstatus] = useState('');
  const [remark,setremark] = useState('');
  const [categorylist,setcategorylist] = useState([]);
  const [category,setcategory] = useState('');
  const [stocklist,setstocklist] = useState([]);
  const [stock,setstock] = useState();
  const [product_id,setproduct_id] = useState();
  const [device_id,setdevice_id] = useState();
  const [amount,setamount] = useState();
  const [quantity,setquantity] = useState();
  const [date,setdate] = useState();
  const [message,setmessage] = useState();
  const [editmodal,openeditmodal] = useState(false);
  const [id,setid] = useState();
  const [start_date,setstartdate] = useState();
  const [end_date,setend_date] = useState();
  const[activebranch,getactivebranch] = useState([]);
  const[selectedbranch,setselectedbranch] = useState('');
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchStock();

  },[])

    const fetchStock = async () => {
      try {
        const endpoint = 'asset-repair-list';
        const response = await dashboardApi.get(endpoint);
        if (response.data.status === 200) {
          setRequestList(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };


    useEffect(() => {
      const branchlist = async () => {
      try {
        const endpoint = 'get-active-branch';
        const response = await dashboardApi.get(endpoint);
  
        if (response.data.status === 200) {
          getactivebranch(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    branchlist();
  }, []);




  const closeModalHandler = () => {
    setcategory('')
    setstock('');
    setproduct_id('');
    setdevice_id('');
    setmessage('');
    setamount('');
    setquantity('');
    setdate('');
    setOpenModal(false);
    setValidationErrors('');
   
  }
  const editasset = async(id)=>{
    setid(id);
         try {
            const endpoint = `edit-repair-asset/${id}`;
            const response = await dashboardApi.get(endpoint);
           if (response.data.status === 200) {
          setcategory(response.data.data.category_id);
          setstock(response.data.data.stock_id);
          setproduct_id(response.data.data.product_id);
          setdevice_id(response.data.data.device_id);
          setmessage(response.data.data.reason)
          setamount(response.data.data.amount)
          setquantity(response.data.data.quantity)
          setdate(response.data.data.date);
          setselectedbranch(response.data.data.branch_id);

          //setselectedemployee(assignToValues);
            } else {
              console.error('Error fetching data:');
            }
           setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
   
   openeditmodal(true);

  }
  const get_report = async () => {
    const errors = {};
    if (!start_date) {
        errors.start_date = 'Start date is required';
    }
    if (!end_date) {
        errors.end_date = 'End date is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
        try {
            setLoading(true);
            const response = await dashboardApi.get('get-asset-repair-report', {
                params: {
                    start_date: start_date,
                    end_date: end_date,
                },
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'asset_report.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the report:', error);
        } finally {
            setLoading(false);
        }
    }
};



  const saveHandler = async(e) => {
    e.preventDefault();
    const errors = {};
    if(!category){
     errors.category = 'Category is required'

    }
    if(!stock){
        errors.stock = 'stock is required'
   
       }
       if(!message){
        errors.message = 'message is required'
   
       }
       if(!amount){
        errors.amount = 'amount is required'
   
       }
       if(!quantity){
        errors.quantity = 'Quantity is required'
   
       }
       if(!date){
        errors.date = 'Date is required'
   
       }
       if(!selectedbranch){
        errors.branch = 'Branch Is Required'
       }

    setValidationErrors(errors);
    if (Object.keys(errors).length === 0){
    const formData = new FormData();
    formData.append('category',category);
    formData.append('stock',stock);
    formData.append('product',product_id);
    formData.append('device',device_id);
    formData.append('message',message);
    formData.append('amount',amount);
    formData.append('quantity',quantity);
    formData.append('date',date);
    formData.append('emp_id',empId)
    formData.append('branch',selectedbranch);


    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-repair-asset', formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchStock();
        closeModalHandler();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }
  }
}
  useEffect(() => {
    const fetchData = async () => {
    try {
      const endpoint = 'active-category';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setcategorylist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
const modalclose = ()=>{
    setcategory('')
    setstock('');
    setproduct_id('');
    setdevice_id('');
    setmessage('');
    setamount('');
    setquantity('');
    setdate('');
    setValidationErrors('');
    openeditmodal(false);
    setselectedbranch('');

}

const updatedata = async(e)=>{
    e.preventDefault();
    const errors = {};
    if(!category){
     errors.category = 'Category is required'

    }
    if(!stock){
        errors.stock = 'stock is required'
   
       }
       if(!message){
        errors.message = 'message is required'
   
       }
       if(!amount){
        errors.amount = 'amount is required'
   
       }
       if(!quantity){
        errors.quantity = 'Quantity is required'
   
       }
       if(!date){
        errors.date = 'Date is required'
   
       }
       if(!selectedbranch){
        errors.branch = 'Branch Is Required'
       }
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0){
    const formData = new FormData();
    formData.append('category',category);
    formData.append('stock',stock);
    formData.append('product',product_id);
    formData.append('device',device_id);
    formData.append('message',message);
    formData.append('amount',amount);
    formData.append('quantity',quantity);
    formData.append('date',date);
    formData.append('emp_id',empId)
    formData.append('branch',selectedbranch);


    //setIsSubmitting(true);

    try {
     const response =  await dashboardApi.post(`update-repair-asset/${id}`, formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchStock();
        modalclose();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the enquiry',
      });
    } finally {
      //setIsSubmitting(false);
    }
  }


}

useEffect(() => {
    if (category) {
      const fetchData = async () => {
        try {
          const endpoint = `category-based-stock-list/${category}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setstocklist(response.data.data);
          } else {
            console.error('Error fetching states data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching states data:', error);
        }
      };

      fetchData();
    }
  }, [category]);
  const openModalHandler = ()=>{
    setOpenModal(true);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <StockDetailsHeader/>
              <div className="card-body">
                <div className='row'>
                    <div className='col-md-3'>
                    <input type="date" className='form-control'
                     value={start_date}
                     onChange={(e) => setstartdate(e.target.value)}
                    />
                     {validationErrors.start_date && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.start_date}</div>
                     )}

                    </div>
                    <div className='col-md-3'>
                    <input type="date" className='form-control'
                    value={end_date}
                    onChange={(e) => setend_date(e.target.value)}
                    />
                     {validationErrors.end_date && (
                     <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.end_date}</div>
                     )}

                    </div>
                    <button className='btn btn-info' onClick={get_report}>Download Report</button>

                </div>
              <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={openModalHandler}>
                  +
                </Link>
                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Category</th>
                        <th>Stock</th>
                        <th>Branch</th>
                        <th>product id</th>
                        <th>Device id</th>
                        <th>Quantity</th>
                        <th>Reason</th>
                        <th>Amount</th>
                        <th>Repair Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestList.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.category}</td>
                          <td>{row.stock}</td>
                          <td>{row.branch}</td>
                          <td>{row.product_id}</td>
                          <td>{row.device_id}</td>
                          <td>{row.quantity}</td>
                          <td>{row.reason}</td>
                          <td>{row.amount}</td>
                          <td>{row.date}</td>
                          <button className='btn btn-info'onClick={() => editasset(row.id)}>Edit</button>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        onClose={closeModalHandler}
        maxWidth="md"
        PaperProps={{ style: {padding: '40px', maxHeight: '2200px'} }}
      >
        <DialogTitle>Asset Repair</DialogTitle>
        <DialogContent>
          <form>
           <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="first name" style={{ fontSize: '16px' }}>Category</label>
                <select className="form-control"
                value={category}
                onChange={(e) => setcategory(e.target.value)}
                >
                <option value=''>Select Category</option>
                {categorylist.map((option) => (
                <option key={option.id} value={option.id}>
                    {option.category_name}
                </option>
                ))}
                </select>
                {validationErrors.category && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.category}</div>
                )}

                </div>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Select Stock</label>
                <select className="form-control"
                value={stock}
                onChange={(e) => setstock(e.target.value)}
                 >
                <option value=''>Select Stock</option>
                {stocklist.map((option) => (
                <option key={option.id} value={option.id}>
                {option.brand_name}
                 </option>
                 ))}
                </select>
                {validationErrors.stock && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.stock}</div>
                )}

                </div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Product Id</label>
                 <input
                     type="text"
                     className="form-control"
                     value = {product_id}
                     onChange={(event)=>{
                     setproduct_id(event.target.value)}}
                     placeholder="Enter Product Id"
                     />
                   

                </div>

                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Device Id</label>
                 <input
                     type="text"
                     className="form-control"
                     value = {device_id}
                     onChange={(event)=>{
                     setdevice_id(event.target.value)}}
                     placeholder="Enter Device Id"
                     />
                   

                </div>


            </div>
            <br/>

            <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Repair Quantity</label>
                 <input
                     type="number"
                     className="form-control"
                     value = {quantity}
                     onChange={(event)=>{
                     setquantity(event.target.value)}}
                     placeholder="Enter Quantity"
                     />
                    {validationErrors.quantity && (
                     <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.quantity}</div>
                    )}

                </div>

                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Amount</label>
                 <input
                     type="text"
                     className="form-control"
                     value = {amount}
                     onChange={(event)=>{
                     setamount(event.target.value)}}
                     placeholder="Enter Amount"
                     />
                    {validationErrors.amount && (
                     <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.amount}</div>
                    )}

                </div>


            </div>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Repair Date</label>
                <input
                     type="date"
                     className="form-control"
                     value = {date}
                     onChange={(event)=>{
                     setdate(event.target.value)}}
                     placeholder="Date"
                     />
                    {validationErrors.date && (
                     <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.date}</div>
                    )}


                </div>

                <div className="form-group col-md-6">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Reason</label>
                           <textarea rows={4} cols={40} style={{height:'43px'}}
                            value={message}
                            onChange={(e) => setmessage(e.target.value)}
                            className = "form-control"


                           />
                           {validationErrors.message && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.message}

                           </div>
                             )} 
                    </div>
            </div>
            <div className='row'>
              <div className='form-group col-md-6'>
              <select
                    className="form-control"
                    value={selectedbranch}
                    onChange={(e) => setselectedbranch(e.target.value)}
                  >
                    <option value=''>Select Branch</option>
                    {activebranch.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.branch && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.branch}</div>
                  )}


              </div>

            </div>


           </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closeModalHandler}>
            Close
          </button>
          <button className="btn btn-info" onClick={saveHandler}>
            Save
          </button>
        </DialogActions>
      </Dialog>

      
      <Dialog
        open={editmodal}
        onClose={modalclose}
        maxWidth="md"
        PaperProps={{ style: {padding: '40px', maxHeight: '2200px'} }}
      >
        <DialogTitle> Edit Asset Repair</DialogTitle>
        <DialogContent>
        <form>
           <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="first name" style={{ fontSize: '16px' }}>Category</label>
                <select className="form-control"
                value={category}
                onChange={(e) => setcategory(e.target.value)}
                >
                <option value=''>Select Category</option>
                {categorylist.map((option) => (
                <option key={option.id} value={option.id}>
                    {option.category_name}
                </option>
                ))}
                </select>
                {validationErrors.category && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.category}</div>
                )}

                </div>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Select Stock</label>
                <select className="form-control"
                value={stock}
                onChange={(e) => setstock(e.target.value)}
                 >
                <option value=''>Select Stock</option>
                {stocklist.map((option) => (
                <option key={option.id} value={option.id}>
                {option.brand_name}
                 </option>
                 ))}
                </select>
                {validationErrors.stock && (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.stock}</div>
                )}

                </div>
            </div>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Product Id</label>
                 <input
                     type="text"
                     className="form-control"
                     value = {product_id}
                     onChange={(event)=>{
                     setproduct_id(event.target.value)}}
                     placeholder="Enter Product Id"
                     />
                   

                </div>

                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Device Id</label>
                 <input
                     type="text"
                     className="form-control"
                     value = {device_id}
                     onChange={(event)=>{
                     setdevice_id(event.target.value)}}
                     placeholder="Enter Device Id"
                     />
                   

                </div>


            </div>
            <br/>

            <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Repair Quantity</label>
                 <input
                     type="number"
                     className="form-control"
                     value = {quantity}
                     onChange={(event)=>{
                     setquantity(event.target.value)}}
                     placeholder="Enter Quantity"
                     />
                    {validationErrors.quantity && (
                     <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.quantity}</div>
                    )}

                </div>

                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Amount</label>
                 <input
                     type="text"
                     className="form-control"
                     value = {amount}
                     onChange={(event)=>{
                     setamount(event.target.value)}}
                     placeholder="Enter Amount"
                     />
                    {validationErrors.amount && (
                     <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.amount}</div>
                    )}

                </div>


            </div>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Repair Date</label>
                <input
                     type="date"
                     className="form-control"
                     value = {date}
                     onChange={(event)=>{
                     setdate(event.target.value)}}
                     placeholder="Date"
                     />
                    {validationErrors.date && (
                     <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.date}</div>
                    )}


                </div>

                <div className="form-group col-md-6">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Reason</label>
                           <textarea rows={4} cols={40} style={{height:'43px'}}
                            value={message}
                            onChange={(e) => setmessage(e.target.value)}
                            className = "form-control"


                           />
                           {validationErrors.message && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.message}

                           </div>
                             )} 
                    </div>
            </div>
            <div className='row'>
              <div className='form-group col-md-6'>
              <select
                    className="form-control"
                    value={selectedbranch}
                    onChange={(e) => setselectedbranch(e.target.value)}
                  >
                    <option value=''>Select Branch</option>
                    {activebranch.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                  {validationErrors.branch && (
                    <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.branch}</div>
                  )}


              </div>

            </div>


           </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={modalclose} >
            Close
          </button>
          <button className="btn btn-info" onClick={updatedata}>
            Update
          </button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default StockRepair;