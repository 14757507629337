import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
//import ToggleButton from 'react-bootstrap/ToggleButton';
//import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import  secureLocalStorage  from  "react-secure-storage";

function BranchType() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [branchType, setbranchType] = useState([])
  const [loading, setLoading] = useState(true);
  const permission = secureLocalStorage.getItem('permission');
  const parsedPermission = permission ? JSON.parse(permission) : [];
  const add_branch_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "add-branch-type");
  const edit_branch_type = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "edit-branch-type");
  const change_status = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "change-status");
    useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
        fetchBranchType() 
    },[])
   const fetchBranchType = async () => {
    try {
       const response = await dashboardApi.get('branch-type-list');

      if (response.data.status === 200) {
        setbranchType(response.data.data);
      } else {
        console.error('Error fetching branch type:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching branch type:', error);
      setLoading(false);
    }
  };
  const toggleStatus = async (branch_type_id) => {
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`branch-type-status/${branch_type_id}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      //console.log(response.data.data);
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('branch-type-change-status', {
        branch_type_id: branch_type_id,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        setbranchType((prevState) =>
          prevState.map((branch_typeObj) =>
            branch_typeObj.id === branch_type_id ? { ...branch_typeObj, status: newStatus } : branch_typeObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              
              <Link className='btn btn-primary mb-2 float-end' to={"/add-branch-type"}>
                    Add Branch Type
                </Link>
              
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Branch Type Name</td>
                  {change_status &&(
                  <td>Status</td>
                  )}
                  {edit_branch_type &&(
                  <td>Action</td>
                  )}
               </tr>
               {branchType.map((branchTypes, index) => (
                <tr key={branchTypes.id}>
                  <td>{index + 1}</td>
                  <td>{branchTypes.branch_type}</td>
                 {change_status &&(
                  <td><button
                  type="button"
                  className={`btn btn-${branchTypes.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(branchTypes.id)}
                  >
                  {branchTypes.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 )}
                 {edit_branch_type &&(
                  <td>
                  <Link to={`/edit-branch-type/${branchTypes.id}`} className='btn btn-success me-2'>
                             Edit
                  </Link>
                  </td>
                  )}
               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BranchType;
