import React, { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
import StockDetailsHeader from "layouts/common_components/stock_details_header";


function AssignStock() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [departmentlist, setdepartmentlist] = useState([]);
  const [department, setdepartment] = useState('');
  const [employee, setemployee] = useState([]);
  const [categorylist,setcategorylist] = useState([]);
  const [category, setcategory] = useState('');
  const [stocklist,setstocklist] = useState([]);
  const [assign_date,setassign_date] = useState('');
  const [stock, setstock] = useState('');
  const [quantity,setquantity] = useState('');
  const [selectedemployee,setselectedemployee] = useState('');
  const [term_and_conditon,setterm_and_condition] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [count,setcount] = useState('');
  const [branchlist,setbranchlist] = useState([]);
  const [selectedbranch,setselectedbranch] = useState('');
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(()=>{
    branchdetails();

  },[])

   useEffect(() => {
    const fetchData = async () => {
    try {
      const endpoint = 'get-active-department';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setdepartmentlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

    const branchdetails = async () => {
    try {
      const endpoint = 'get-active-branch';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setbranchlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


useEffect(() => {
  if (department) {
    const fetchData = async () => {
      try {
        const endpoint = `department-employee/${department}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setemployee(response.data.data);
        } else {
          console.error('Error fetching states data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching states data:', error);
      }
    };

    fetchData();
  }
}, [department]);
 //console.log(employee);


 useEffect(() => {
    const fetchData = async () => {
    try {
      const endpoint = 'active-category';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setcategorylist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

 useEffect(() => {
    if (category && selectedbranch) {
      const fetchData = async () => {
        try {
          const endpoint = `category-based-stock/${category}/${selectedbranch}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setstocklist(response.data.data);
          } else {
            console.error('Error fetching states data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching states data:', error);
        }
      };

      fetchData();
    }
  }, [category,selectedbranch]);
  const assign_stock = async (e) => {
    e.preventDefault();
    const errors = {};
    setValidationErrors(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('category', category);
    formData.append('department',department);
    formData.append('employee',selectedemployee);
    formData.append('stock',stock);
    formData.append('quantity', quantity);
    formData.append('emp_id',emp_id);
    formData.append('image',term_and_conditon);
    formData.append('assign_date',assign_date);
    setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('assign-stock', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate("/assign-list");
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      }
      else if (response.data.status === 201) {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });
      }
       else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the stock',
      });
    }
   finally {
        setIsSubmitting(false);
      }
  };
}
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <StockDetailsHeader/>
            <Card>
              <div className="card-body">
                <form onSubmit={assign_stock}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Select Department</label>
                         <select className="form-control"
                          value={department}
                          onChange={(e) => setdepartment(e.target.value)}
                          >
                            <option value=''>Select Department</option>
                             {departmentlist.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.department_name}
                              </option>
                              ))}
                          </select>
                          {validationErrors.department && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                             )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Select Branch</label>
                         <select className="form-control"
                          value={selectedbranch}
                          onChange={(e) => setselectedbranch(e.target.value)}
                          >
                            <option value=''>Select Branch</option>
                             {branchlist.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.branch_name}
                              </option>
                              ))}
                          </select>
                          {validationErrors.department && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Select Employee</label>
                           <select className="form-control"
                          value={selectedemployee}
                          onChange={(e) => setselectedemployee(e.target.value)}
                          >
                            <option value=''>Select Employee</option>
                             {employee.map((option) => (
                             <option key={option.emp_id} value={option.emp_id}>
                              {option.emp_fname}
                              </option>
                              ))}
                          </select>
                          {validationErrors.employee && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.employee}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      <div className='row'>
                       <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Category</label>
                          <select className="form-control"
                          value={category}
                          onChange={(e) => setcategory(e.target.value)}
                          >
                            <option value=''>Select Category</option>
                             {categorylist.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.category_name}
                              </option>
                              ))}
                          </select>
                          {validationErrors.category && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.category}</div>
                             )}
                        </div>
                      </div>
                       <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Select Stock</label>
                             <select className="form-control"
                             value={stock}
                               onChange={(e) => setstock(e.target.value)}
                              >
                            <option value=''>Select Stock</option>
                             {stocklist.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.brand_name}
                              </option>
                              ))}
                          </select>
                          {validationErrors.stock && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.stock}</div>
                             )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Quantity</label>
                            <input
                            type="text"
                            className="form-control"
                            value = {quantity}
                            onChange={(event)=>{
                              setquantity(event.target.value)}}
                           placeholder="Enter Quantity"
                          />
                           {validationErrors.quantity && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.quantity}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      <div className='row'>
                      <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Assign Date</label>
                           <input
                            type="date"
                            className="form-control"
                            value = {assign_date}
                            onChange={(event)=>{
                              setassign_date(event.target.value)}}
                          />
                           {validationErrors.assign_date && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.assign_date}</div>
                             )}
                        </div>
                      </div>
                    

                      <div className="col-md-4">
                        <div className="form-group">
                           <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Term and Condition image Upload</label>
                            <input
                            type="file"
                            className="form-control"
                            onChange={(e) => setterm_and_condition(e.target.files[0])}
                          />
                        </div>
                      </div>

                     </div>
                     </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AssignStock;