import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate,useParams } from "react-router-dom";
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";


function WFH() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const [startdate, setstartdate] = useState('');
  const [enddate, setenddate] = useState('');
  const [days, setdays] = useState('');
  const [reason,setreason] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [leavedata,getleavedata] = useState([]);
  let emp_id = secureLocalStorage.getItem('emp_id');
  const employee_id  = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [empleavedata,getempleavedata] = useState([]);
  const [createleave,setcreateleave] = useState('');
  const[wfh,wfhlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openmodal,setopenmodal] = useState(false);
  const[task,settask] = useState('');
  const [status,setstatus] = useState('');
  const[hour,sethour] = useState('');
  const[taskid,settaskid] = useState('');
  const [validationErrors1,setValidationErrors1] = useState({});
  const [inputList, setInputList] = useState([{ wfhtask: "",workingstatus: "" }]);

  useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);
 // emp_id = id;

  useEffect(() => {
  if (startdate && enddate ) {
    const fetchData = async () => {
      try {
       const response =  await dashboardApi.post('count-days-wfh', {
        start_date:startdate,
        end_date:enddate,
      });
      if (response.data.status === 200) {
          setdays(response.data.data);
        } else {
          Swal.fire({
          icon: 'error',
          text: response.data.message,
        });
          setstartdate('');
          setenddate('');
          setdays('');
          
        }
      } catch (error) {
        console.error('Error fetching states data:', error);
      }
    };

    fetchData();
  }
}, [startdate,enddate]);
//console.log(inputList.length);

const savetask = async(e)=>{
  //alert('');
  //setValidationErrors1(errors);
  //if (Object.keys(errors).length === 0) {
    const formData = new FormData()
   // formData.append('task',inputList);
    formData.append('task',JSON.stringify(inputList));
    formData.append('emp_id',emp_id);
    formData.append('wfh_id',taskid);
    try {
      const response =  await dashboardApi.post('update-wfh-task', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         closemodal();
         fetchwfhdata();
      } else {
          const errors = {};
          errors.task  = response.data.message;
          setValidationErrors1(errors);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }



  //}


}
const closemodal = ()=>{
  setopenmodal(false);
  settaskid('')
  settask('');
  setValidationErrors1('');
  setInputList([{wfhtask: ''}]);
  setInputList([{workingstatus: ''}]);


}

const handleInputChange = (e, index) => {
  const { name, value } = e.target;
  const list = [...inputList];
  list[index][name] = value;
  setInputList(list);
};
const handleRemoveClick = index => {
  const list = [...inputList];
  list.splice(index, 1);
  setInputList(list);
};
const handleAddClick = () => {
  setInputList([...inputList, { wfhtask: "", workingstatus: "" }]);
};


  const saveleave = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!startdate) {
      errors.date_from = 'Start Date is required';
    }
    if (!enddate) {
      errors.date_to = 'End Date is required';
    }
    if (!days) {
      errors.no_of_days = 'Days is required';
    }
    if (!reason) {
      errors.reason = 'Reason is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('date_from', startdate);
    formData.append('date_to', enddate);
    formData.append('no_of_days', days);
    formData.append('reason', reason);
    formData.append('emp_id', emp_id);
    setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('apply-wfh', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
          setstartdate('');
          setenddate('');
          setdays('');
          setreason('');
          fetchwfhdata(); 
         //navigate("/emp-type-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      }
      else if(response.data.status === 500) {
        // Display validation errors below the input fields
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }

       else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred',
      });
    }
    finally {
        setIsSubmitting(false);
      }
  };
}
 useEffect(()=>{
        fetchwfhdata(); 
    },[currentPage])
   const  fetchwfhdata = async () => {
    try {
       const response = await dashboardApi.post('wfh-list', {
        emp_id: emp_id,
        per_page:postPerPage,
        page: currentPage + 1,
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
         wfhlist(employeeData);
        setNumber(response.data.last_page);
      } else {
        console.error('Error fetching:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching:', error);
      setLoading(false);
    }
  };
 const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };
  const updatetask = async (id)=>{
    settaskid(id);


    setopenmodal(true);
   

  }
 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <form onSubmit={saveleave}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Date From </label>
                          <input
                            type="date"
                            className="form-control"
                            value={startdate}
                            onChange={(e) => setstartdate(e.target.value)}
                          />
                          {validationErrors.date_from && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.date_from}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Date To </label>
                          <input
                            type="date"
                            className="form-control"
                            value={enddate}
                            onChange={(e) => setenddate(e.target.value)}
                          />
                          {validationErrors.date_to && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.date_to}</div>
                             )}
                        </div>
                      </div>
                   
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>No Of Days</label>
                          <input
                            type="text"
                            className="form-control"
                            value={days}
                            placeholder = "No Of Days"

                          />
                        {validationErrors.no_of_days && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.no_of_days}</div>
                             )}
                          
                        </div>
                      </div>
                     <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Reason</label>
                          <input
                            type="text"
                            className="form-control"
                            value={reason}
                            onChange={(e) => setreason(e.target.value)}
                            placeholder = "Enter Reason"

                          />
                           {validationErrors.reason && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.reason}

                           </div>
                             )}
                        </div> 
                    </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                  </div>
                </form>
                <center>Work From Home  Details</center>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Date From</td>
                  <td>Date To</td>
                  <td>No Of Days</td>
                  <td>Reason</td>
                  <td>Rejection Reason</td>
                  <td>Wfh Status</td>
                  <td>Action</td>
               </tr>
               {wfh.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.days_from}</td>
                  <td>{row.days_to}</td>
                  <td>{row.no_of_days}</td>
                  <td>{row.reason_for_wfh}</td>
                  <td>{row.rejection_reason}</td>
                 <td><button className="btn btn-info btn-sm">{row.status === 1 ? 'Approved' : row.status === 2 ? 'Rejected' : 'Pending'}</button></td>
                 <td><button className="btn btn-info btn-sm" onClick={() => updatetask(row.id)}>Update</button>
                  &nbsp;&nbsp;
                 <Link className="btn btn-info btn-sm" to = {`/view-wfh-description/${row.id}`} >View</Link></td>

               </tr>
              ))}

            </table>
             )}
              {wfh.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                

              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      
      <Dialog
        open={openmodal}
        onClose={closemodal}
        fullWidth
        maxWidth='lg'
        PaperProps={{ style: { padding: '40px', height:'700px'} }}
      >
        <DialogTitle>Update Work From Home Task</DialogTitle>
        <span style={{color:'red'}}>{validationErrors1.task}</span>
        <DialogContent>
            <div className="container">
            {inputList.map((x, i) => {
         return (
          <div className="row">
            <div className="col-md-7">
            <label>Task</label>
             <textarea rows={20} cols={40} style={{height:'43px'}}
                name="wfhtask"
                value={x.wfhtask}
                onChange={e => handleInputChange(e, i)}
                className = "form-control"
                placeholder = "Task"
                />
              </div>
              <br/>
           <div className="col-md-3">
              <label> Working Status</label>
             <input
                name = "workingstatus"
                value={x.workingstatus}
                onChange={e => handleInputChange(e, i)}
                className = "form-control"
                placeholder = "Status"
             
              />
              <br/>
              {inputList.length !== 1 && <button
                className=" btn btn-danger"
                onClick={() => handleRemoveClick(i)}>Remove</button>}
              
              </div>
             <br/> <br/>
            <div className="btn-box">
                &nbsp; &nbsp; &nbsp;
              {inputList.length - 1 === i && <button className="btn btn-info" onClick={handleAddClick}>Add</button>}
            </div>
          </div>
        );
      })}
    </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodal}>
            Close
          </button>
          <button className="btn btn-info" onClick={savetask}>
            Update
          </button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default WFH;