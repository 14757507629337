import React from 'react';
import { Link, useLocation } from "react-router-dom";
function PackageHeader() 
{
    let location = useLocation();
    let currentPage = location.pathname;

  return (
    
    <div>
      <Link style={currentPage === "/lead-based-package" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/lead-based-package">
      Lead Based Package
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/unlimited-package" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/unlimited-package">
      Unlimited Package
      </Link>

      <Link style={currentPage === "/lead-based-location-package" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/lead-based-location-package">
      Lead Based Location Factor
      </Link>

      <Link style={currentPage === "/unlimited-location-package" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/unlimited-location-package">
      Lead Based Location Factor
      </Link>
    </div>
  );
}


export default PackageHeader;
