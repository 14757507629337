import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AdminDetailsHeader from 'layouts/common_components/admin_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function Admin_details() 
{
  const navigate = useNavigate();
  const emp_id = localStorage.getItem('emp_id');
  const token = localStorage.getItem('token');
  const department = localStorage.getItem('department');
  const [newKycDoc,setNewKycDoc] = useState([]);
  const [approvalModal,setApprovalModal] = useState(false);
  const [selectedApprovalStatus,setApprovalStatus] = useState();
  const [remarks,setRemarks] = useState();

    const closeApprovalControl = ()=>
    {
      setApprovalModal(false);
    }
  
    useEffect(() => {
      getNewKycDetails();
    }, []);

    const getNewKycDetails = async () => 
    {
      try{
        const response = await dashboardApi.get(`get-new-kyc-details`);
        if(response.data.status === 200)
        {
          setNewKycDoc(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
      }
    };
    return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <div className="row">
                      <div class="col-sm-11">
                          <AdminDetailsHeader></AdminDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                        <Link className='btn btn-info'>
                            +
                        </Link>
                      </div>
                  </div>

             
                  <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                    <tr>
                      <td>S.No</td>
                      <td>Client Name</td>
                      <td>Company Name</td>
                      <td>Source From</td>
                      <td>Exe Name</td>
                      <td>Status</td>
                      <td>Action</td>
                    </tr>
                    {newKycDoc && newKycDoc.map((kyc,index)=>(
                      <tr>
                        <td>{index + 1}</td>
                        <td>{kyc.client_name}</td>
                        <td>{kyc.business_name}</td>
                        <td>{kyc.created_by}</td>
                        <td>{kyc.emp_name}</td>
                        <td>{kyc.status==0 ||kyc.status==1 ?'New':kyc.status==2?'Active':kyc.status==3?'Inactive':kyc.status==4?'Expire':kyc.status==0?'BlackList'?kyc.status==6:'Deleted':''} </td>
                        <td>
                          <Link className='btn btn-info' to={`/verified-kyc/${kyc.client_id}`}>
                            View
                          </Link>&nbsp;
                        </td>
                      </tr>
                    ))
                    }
                  </table>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

     
    

    </DashboardLayout>
  );
}
export default Admin_details;
