import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import AppCrudHeader from 'layouts/common_components/app_crud_header';

function AppVersion() {
  const navigate = useNavigate();
  const [Leave, setLeave] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = secureLocalStorage.getItem("token");
  const [openModal, setOpenModal] = useState(false);
  const [editopenModal,seteditopenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const emp_id = secureLocalStorage.getItem('emp_id');
  const [productlist,setproductlist] = useState([]);
  const [selectedproduct,setselectedproduct] = useState('');
  const [version_list,get_version_list] = useState([]);
  const [id,setid] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;
  const [client_type,set_client_type] = useState('');
  const [version_id,set_version_id] = useState('');
  //const [OpeneditModal,seteditopenModal] = useState(false);

 
  useEffect(() => {
       if (!token) {
        navigate("/sign-in");
     }
    }, [token, navigate]);

    useEffect(()=>{
        productdetails();
        app_version_list();


    },[])

    useEffect(() => {
      app_version_list(currentPage);
    }, [currentPage]);
    const productdetails = async () => {
        try {
          const endpoint = 'get-product-list';
          const response = await dashboardApi.get(endpoint);
    
          if (response.data.status === 200) {
            setproductlist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const app_version_list = async (page) => {
        try {
          // const endpoint = 'otp-template-list';
          // const response = await dashboardApi.get(endpoint);

          const response = await dashboardApi.get('app-version-list', {
            params: {
              per_page: postsPerPage,
              page: page + 1, 
            }
          });
    
          if (response.data.status === 200) {
            const enqData = response.data.data.data || response.data.data;
            get_version_list(enqData);
            setTotalPages(response.data.last_page);
          } else {
            console.error('Error fetching data:', response.data.error);
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.error('Error fetching data:', error);
        }
      };

      const handlePageClick = (selected) => {
        setCurrentPage(selected.selected);
      };
   const handleCloseModal = () => {
    setValidationErrors('');
    setOpenModal(false);
    seteditopenModal(false);
    set_client_type('');
    setselectedproduct('');
    set_version_id('');
   
  };
  const handleOpenModal = async () => {
    setOpenModal(true);
  };
  const editviewopen = async(version_id)=>{
     setid(version_id);
      try {
      const endpoint = `edit-app-version/${version_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        set_version_id(response.data.data.version_id);
        set_client_type(response.data.data.client_type);
        setselectedproduct(response.data.data.product_id)
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
    seteditopenModal(true);

  }
  
  const handlesave = async (e)=>{
   e.preventDefault();
    const formData = new FormData();
    formData.append('client_type',client_type);
    formData.append('version',version_id);
    formData.append('product',selectedproduct);
    formData.append('emp_id',emp_id);

    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('app-version-add', formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        handleCloseModal();
       // otp_template_list();
      } 

      else if (response.data.status === 201) {
        setValidationErrors(response.data.messages);
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });

        handleCloseModal();
      }
      
       else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the followup',
      });
    }

  }
  const handleupdate = async (e)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append('client_type',client_type);
    formData.append('version',version_id);
    formData.append('product',selectedproduct);
    formData.append('emp_id',emp_id);
    formData.append('id',id);
 
     try {
       const response = await dashboardApi.post('update-app-version', formData);
 
       if (response.data.status === 200) {
         Swal.fire({
           icon: 'success',
           text: response.data.message,
         });
         app_version_list()
         handleCloseModal();
       } else if (response.data.status === 400) {
         setValidationErrors(response.data.messages);
       } else {
         Swal.fire({
           icon: 'error',
           text: response.data.message || 'An error occurred',
         });
       }
     } catch (error) {
       Swal.fire({
         icon: 'error',
         text: 'An error occurred while updating',
       });
     }
 
   }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppCrudHeader></AppCrudHeader>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={handleOpenModal}>
                   +
                </Link>

                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Product</td>
                  <td>Client Type</td>
                  <td>Version Id</td>
                  <td>Action</td>
               </tr>
               {version_list.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.product_name}</td>
                  <td>{row.otp_version_type}</td>
                  <td>{row.version_id}</td>
                 <td>
                 <button className='btn btn-primary btn-sm mb-2 float-end'onClick={() => editviewopen(row.id)}>
                    Edit
                </button>
                 </td>
               </tr>
              ))}

            </table>
             )}
             {version_list.length > 0 && (
            <Pagination className="custom-pagination">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link-style"}
                breakLinkClassName={"break-link-style"}
                previousLinkClassName={"prev-link-style"}
                nextLinkClassName={"next-link-style"}
              />
            </Pagination>
          )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog open={openModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>App Version</DialogTitle>
          <DialogContent>
            <form>
              <div>
                  <label>Select Product</label>
                  <select className="form-control"
                        value={selectedproduct}
                        onChange={(e) => setselectedproduct(e.target.value)}
                        >
                        <option value=''>Select Product</option>
                        {productlist.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.product && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.product}</div>
                  )}
              </div>
              <div>
                  <label>Client Type</label>
                  <select className="form-control"
                    value={client_type}
                    onChange={(e) => set_client_type(e.target.value)}
                        >
                        <option value=''>Client Type</option>
                        <option value='0'>Vendor/Client</option>
                        <option value='1'>Customer</option>
                  </select>
                  {validationErrors.client_type && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.client_type}</div>
                  )}
              </div>

              <div>
                  <label>Version Id</label>
                  <input
                    type='text'
                    className="form-control"
                    value={version_id}
                   onChange={(e) => set_version_id(e.target.value)}
                  />
                   {validationErrors.version && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.version}</div>
                  )}
              </div>
            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick={handleCloseModal}>
              Close
            </Link>
            &nbsp;
            <button className="btn btn-info" onClick={handlesave}>
             Save
            </button>
          </DialogActions>
      </Dialog>

      <Dialog open={editopenModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}>
        <DialogTitle>App Version Edit</DialogTitle>
          <DialogContent>
          <form>
              <div>
                  <label>Select Product</label>
                  <select className="form-control"
                        value={selectedproduct}
                        onChange={(e) => setselectedproduct(e.target.value)}
                        >
                        <option value=''>Select Product</option>
                        {productlist.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                  </select>
                  {validationErrors.product && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.product}</div>
                  )}
              </div>
              <div>
                  <label>Client Type</label>
                  <select className="form-control"
                    value={client_type}
                    onChange={(e) => set_client_type(e.target.value)}
                        >
                        <option value=''>Client Type</option>
                        <option value='0'>Vendor/Client</option>
                        <option value='1'>Customer</option>
                  </select>
                  {validationErrors.use_as && (
                    <div className="text-danger" style={{ fontSize: '14px' }}>{validationErrors.use_as}</div>
                  )}
              </div>

              <div>
                  <label>Version Id</label>
                  <input
                    type='text'
                    className="form-control"
                    value={version_id}
                   onChange={(e) => set_version_id(e.target.value)}
                  />
              </div>
            </form>
          </DialogContent>
          
          <DialogActions>
            <Link className="btn btn-danger" onClick={handleCloseModal}>
              Close
            </Link>
            &nbsp;
            <button className="btn btn-info" onClick={handleupdate}>
             Save
            </button>
          </DialogActions>
      </Dialog>

    </DashboardLayout>
  );
}

export default AppVersion;