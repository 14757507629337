import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { useAbsoluteLayout } from 'react-table';
//import { Bar } from 'react-chartjs-2';
//import totalcollection from '../assets/images/totalcollection.png';

const DmDashboard = () => {
  const navigate = useNavigate();
  const { sales } = reportsLineChartData;
  const [productlist, setProductlist] = useState([]);
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
  const [product, setproduct] = useState('');
  const [servicelist, setServicelist] = useState([]);
  const [service, setservice] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [grouplist, setgrouplist] = useState([]);
  const [group, setgroup] = useState('');
  const [count,setcount] = useState('');
  const [overrunning,setoverrunning] = useState('');
  const [lmartpercent,setlmartpercent] = useState('');
  const [zoopgo_percent,setzoopgo_percent] = useState('');
  const [not_sent,set_not_sent] = useState('');
  const [organic_enquiry,setorganic_enquiry] = useState('');
  const [sent,setsent] = useState('');
  const [sale,setsale] = useState('');
  const [toll_free,set_toll_free] = useState('');
  const [feedback,setfeedback] = useState('');
  const [return_avarage,set_return_avarage] = useState('');
  const [category_list,set_category_list] = useState([]);
  const [category,selectedcategory] = useState('');
  const [social_media_enquiry,set_social_media_enquiry] = useState('');
  const [renewal_gone,set_renewal_gone] = useState('');
  const [per_day_required,set_per_day_required] = useState('');
  const [valid_lead_percent,set_valid_lead_percent] = useState('');
  const [lead_quality_count,set_lead_quality_count] = useState('');
  let toastId = 0;




  useEffect(()=>{
    groupdetails();
    productdetails();
    dashboard_data();
   

  },[])

  useEffect(()=>{
    get_sent_percent_notification();

  },[dept_id,toastId==0])



  const groupdetails = async () => {
    try {
      const endpoint = 'group-details';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setgrouplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setProductlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setServicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);


  useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);


  const dashboard_data = async () => {
    try {
      const endpoint = 'dm-dashboard-count';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setcount(response.data.data.count);
        set_not_sent(response.data.data.not_sent);
        setsent(response.data.data.sent)
        setsale(response.data.data.sale)
        set_toll_free(response.data.data.toll_free);
        setlmartpercent(response.data.data.lmart_percent)
        setzoopgo_percent(response.data.data.zoopgo_percent);
        setoverrunning(response.data.data.overrunning)
        setorganic_enquiry(response.data.data.organic_enquiry)
        set_return_avarage(response.data.data.return_avarage)
        setfeedback(response.data.data.feedback);
        set_social_media_enquiry(response.data.data.social_media_enquiry)
        set_renewal_gone(response.data.data.renewal_gone);
        set_per_day_required(response.data.data.max_per_day)
        set_valid_lead_percent(response.data.data.valid_lead_percent);
        set_lead_quality_count(response.data.data.lead_quality_count);



      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  const get_sent_percent_notification = async () => {
    if(toastId<1){
    try {

      toastId++;

      const endpoint = `get-sent-percent-calculation-lmart/${dept_id}/${'dm_dashboard'}`;
      const response = await dashboardApi.get(endpoint);
      if (toastId !== null) {
          toast.dismiss(toastId);
      }

      if (response.data.status === 200) {
          toastId = toast.error(response.data.message, {
              position: "top-center",
              autoClose: 30000, // 30 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              style: {
                  width: '800px',
                  maxWidth: '100%'
              }
          });
      }
  }  catch (error) {
       console.error('Error fetching data:');
      }
    }
    };



 

  const dashboard_data_filter = async () => {
    // Only proceed if at least one filter value is defined
    
        try {
            const response = await dashboardApi.get('dm-dashboard-count', {
              params: {
                start_date: start_date,
                end_date: end_date,
                product: product,
                service: service,
                group: group,
                category:category
              }
            });

            // Check if the response is successful
            if (response.data.status === 200) {
                const data = response.data.data;
                // Update state with the fetched data
                setcount(data.count);
                set_not_sent(data.not_sent);
                setsent(data.sent);
                setsale(data.sale);
                set_toll_free(data.toll_free);
                setlmartpercent(data.lmart_percent);
                setzoopgo_percent(data.zoopgo_percent);
                setoverrunning(data.overrunning);
                setorganic_enquiry(data.organic_enquiry);
                set_return_avarage(data.return_avarage); 
                setfeedback(data.feedback);
                set_social_media_enquiry(response.data.data.social_media_enquiry)
                 set_renewal_gone(response.data.data.renewal_gone);
                 set_per_day_required(response.data.data.max_per_day)
                 set_valid_lead_percent(response.data.data.valid_lead_percent);

            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        
    }
};

useEffect(() => {
  dashboard_data_filter();
}, [product, service, group, start_date, end_date,category]);









  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <div>
          <div className="d-flex flex-wrap">
            <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={group}
                onChange={(e) => setgroup(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option>Select Group</option>
                {grouplist.map((row) => (
                  <option key={row.group_idid} value={row.group_id}>
                    {row.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={product}
                onChange={(e) => setproduct(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option>Select Product</option>
                {productlist.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.product_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={service}
                onChange={(e) => setservice(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Service</option>
                {servicelist.map((ser) => (
                  <option key={ser.id} value={ser.id}>
                    {ser.service_name}
                  </option>
                ))}
              </select>
            </div>
             <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={category}
                onChange={(e) => selectedcategory(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Category</option>
                {category_list.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.category_name}
                  </option>
                ))}
              </select>
            </div>


            <div className="col-sm-3 col-6 p-1">
              <input
                type="date"
                value={start_date}
                onChange={(e) => setstart_date(e.target.value)}
                className="form-control"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              />
            </div>
            <div className="col-sm-3 col-6 p-1">
              <input
                type="date"
                value={end_date}
                onChange={(e) => setend_date(e.target.value)}
                className="form-control"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              />
            </div>

          </div>
        </div>
        <div className='d-flex flex-wrap my-3'>
          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
            <span className='btn btn-danger w-100' style={{ fontSize: '12px' }} >CPA : </span>
          </div>

          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
          <Link to ='/renewal-gone-due-to-leads'>
            <span className='btn btn-danger w-100' style={{ fontSize: '12px' }} >Renewal Gone Due to Leads:{renewal_gone}</span>
            </Link>
          </div>

          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
            <span className='btn btn-danger w-100' style={{ fontSize: '12px' }} >Over Running Clients:{overrunning}</span>
          </div>

        </div>
          <div className="d-flex flex-wrap">
            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/leadsale.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>CPA</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>5</h5>
                </div>
              </div>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
            <Link className='h-100'
                //to='/enquiry-details-list'
                to={`/enquiry-details-list/${encodeURIComponent('totalenquiry')}`}
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/dm_dashboard/lead.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Enquiry Generated</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{count}</h5>
                </div>
              </div>
              </Link>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
            <Link className='h-100'
                 to={`/enquiry-details-list/${encodeURIComponent('not-sent')}`}
                style={{ textDecoration: 'none' }}
              >
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/dm_dashboard/not_sent.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Not sent Enquiry</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{not_sent}</h5>
                </div>
              </div>
              </Link>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
            <Link className='h-100'
                to={`/enquiry-details-list/${encodeURIComponent('organic-leads')}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/lead.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />

                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Organic Leads</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{organic_enquiry}</h5>
                   
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <Link className='h-100'
                to={`/upcoming-renewal-details/${encodeURIComponent('upcomingRenewal')}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/loss.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>CPA Loss</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>5</h5>
                   
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/dm_dashboard/cost.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Cost</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>500</h5>
                </div>
              </div>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <Link className='h-100'
                 to={`/enquiry-details-list/${encodeURIComponent('total-sent')}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/sent.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Sent</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sent}</h5>
                   
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
            <Link className='h-100'
                 to={`/enquiry-details-list/${encodeURIComponent('lead-sale')}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/sale.png`} style={{ maxWidth: '100px' }} alt="group loss" />
                  <div class="card-body text-center"> 
                      <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Lead Sale</h5>
                      <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{sale}</h5>
                  </div>
                </div>
              </Link>


            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/dm_dashboard/need.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Per Day Required</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{per_day_required}</h5>
                </div>
              </div>
            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <Link className='h-100'
                 to={`/enquiry-details-list/${encodeURIComponent('call-generated')}`}
                style={{ textDecoration: 'none' }}
              >

                <div className="card align-items-center pt-3 h-100" >
                  
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/call.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Calls Generated</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{toll_free}</h5>
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <Link className='h-100'
                 to='/package-overrunning'
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/over_running.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Package Over Running</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{overrunning}</h5>
                    
                  </div>
                </div>
              </Link>

            </div>


            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <Link className='h-100'
                to='/lead-feadback'
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/feedback.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Lead Feedback</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{feedback}</h5>
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/dm_dashboard/performance.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Improvments Factors</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}></h5>
                </div>
              </div>
            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/sent.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Sent % Zoopgo/Lmart</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{zoopgo_percent}/{lmartpercent}</h5>
                  </div>
                </div>

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'> 
             <Link className='h-100'
                 to={`/enquiry-details-list/${encodeURIComponent('social_media_enquiry')}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/marketing.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Social Media Enquiry</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{social_media_enquiry}</h5>
                  </div>
                </div>
                </Link>
            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'> 
             <Link className='h-100'
                 to='/department-target'
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/organization.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Company Target</h5>
                  </div>
                </div>
                </Link>
            </div>

           <div className='col-sm-3 col-6 p-sm-2 p-1'> 
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/service.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Lead Quality Percent</h5>
                     <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{valid_lead_percent}</h5>
                  </div>
                </div>
            </div>


             <div className='col-sm-3 col-6 p-sm-2 p-1'> 
             <Link to='/enquiry-quality'>
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/dm_dashboard/service.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Not Sent Data(Due To Quality)</h5>
                     <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{lead_quality_count}</h5>
                  </div>
                </div>
                </Link>
            </div>

           
            
          </div>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
     
    </DashboardLayout>
  );
}

export default DmDashboard;