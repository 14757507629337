import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

//const [loading, setLoading] = useState(true);

function AddPriority() {
  //alert();
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [attribute_name, setattribute_name] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
   const [isSubmitting, setIsSubmitting] = useState(false);
   useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  const savepriority = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!setattribute_name) {
      errors.attribute_name = 'attribute is required';
    }
  //    if (!setpriority) {
  //     errors.priority = 'priority is required';
  //   }
  //  if (!settime) {
  //     errors.required_time = 'Time is required';
  //   }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('attribute_name', attribute_name);
    // formData.append('priority', priority);
    // formData.append('required_time', time);
    formData.append('emp_id',emp_id);
    setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-priority', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
       navigate('/ticket-attribute');
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
   finally {
        setIsSubmitting(false);
      }
  };
}

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <Link className='btn btn-info' to="/ticket-attribute">Back</Link>
                <form onSubmit={savepriority}>
                  <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                           <label htmlFor="first name" style={{ fontSize: '16px' }}>Attribute Name</label>
                           <input
                            type = "text"
                            className="form-control"
                            placeholder="Enter Attribute Name"
                            value={attribute_name}
                            onChange={(e) => setattribute_name(e.target.value)}
                            
                            />
                          {validationErrors.attribute_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.attribute_name}</div>
                             )}
                        </div>
                      </div>
                      
                        </div>
                       
                      
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                      <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddPriority;