import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import Admin_details from "layouts/admin";
import Verfied_kyc from "layouts/admin/verified_kyc";
import  secureLocalStorage  from  "react-secure-storage";
const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];
import Return_Renewal from "layouts/admin/approve_return_renewal";
import Add_Renewal from "layouts/admin/add_return_renewal";

const adminroute = [
{
      type: "collapse",
      name: "Admin",
      key: "Admin",
      icon: <Icon fontSize="small">account_tree</Icon>,
      nested: [
        {
            route: "/admin",
            name: "New Kyc",
            key: "New Kyc",
            icon: <Icon fontSize="small">published_with_changes</Icon>,
            component: (token!=null) ? <Admin_details name ='admin'/> : <SignIn/>,
        },
        {
          route: "/approve-return-renewal",
          name: "Return Renewal",
          key: "Return Renewal",
          icon: <Icon fontSize="small">published_with_changes</Icon>,
          component: (token!=null) ? <Return_Renewal name ='admin'/> : <SignIn/>,
        },
        {
          route: "/add-return-renewal",
          name: "Add Renewal",
          key: "add Renewal",
          icon: <Icon fontSize="small">published_with_changes</Icon>,
          component: (token!=null) ? <Return_Renewal name ='admin'/> : <SignIn/>,
        },
        // {
        //   route: "/verified-kyc/:clientId",
        //   name: "Verified Kyc",
        //   key: "Verified Kyc",
        //   icon: <Icon fontSize="small">published_with_changes</Icon>,
        //   component: (token!=null) ? <Verfied_kyc name ='admin'/> : <SignIn/>,
        // }
      ]
    },
    {
      route: "/admin",
      component: (token!=null) ? <Admin_details/> :  <SignIn/>,
    },
    {
      route: "/verified-kyc/:clientId",
      component: (token!=null) ? <Verfied_kyc/> :  <SignIn/>,
    },
    {
      route: "/approve-return-renewal",
      component: (token!=null) ? <Return_Renewal/> :  <SignIn/>,
    },
    {
      route: "/add-return-renewal",
      component: (token!=null) ? <Add_Renewal/> :  <SignIn/>,
    }
];
export default adminroute;