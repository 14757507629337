import React from 'react';
import { Link, useLocation } from "react-router-dom";
function MatureClientDetailsHeader() 
{
    let location = useLocation();
    let currentPage = location.pathname;

  return (
    
    <div>
      <Link style={currentPage === "/mature-client-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/mature-client-details">
        Client Details
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/today-followup" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to="/today-followup">
        Today Followup
      </Link>

    </div>
  );
}


export default MatureClientDetailsHeader;
