import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from "sweetalert2";
import { useNavigate,useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCut } from '@fortawesome/free-solid-svg-icons';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function EditempDocument() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const [documentname, setdocumentname] = useState('');
  const [documentdetails, setdocumentdetails] = useState('');
  const [image, setimage] = useState('');
  const [imageurl,setimageurl] = useState('');
  const [option, setOption] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const[documentlist,setdocumentlist] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);
 useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-document-type'; // Replace with the actual endpoint
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setOption(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
  const clearFields = () => {
    setdocumentname(''); 
    setdocumentdetails('');
    const imageInput = document.querySelector('input[type="file"]');
     if (imageInput) {
       imageInput.value = '';
    }
  };
  useEffect(()=>{
    fetchdocument()
  },[])
  const fetchdocument = async () => {
    await dashboardApi.get(`edit-document/${id}`).then(({data})=>{
      const {emp_id,doc_id,document_details,doc_url} = data.document
      setdocumentname(doc_id);
      setdocumentdetails(document_details);
      setimageurl(doc_url);

    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }
  const savedocument = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!documentname) {
      errors.document = 'document is required';
    }
    if (!documentdetails) {
      errors.document_details = 'document details  is required';
    }
    setValidationErrors(errors);

  if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('document', documentname);
    formData.append('document_details', documentdetails);
    formData.append('image', image);
    setIsSubmitting(true);
    try {
         const response = await dashboardApi.post(`update-document/${id}`, formData);

        if (response.data.status === 200) {
          Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
           const emp_id = response.data.data[0].emp_id;
           navigate(`/document-info/${emp_id}`);

         
        } else if (response.data.status === 400) {
          // Display validation errors below the input fields
          setValidationErrors(response.data.messages);
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while creating the employee',
        });
      }
      finally {
        setIsSubmitting(false);
      }
    }
  };
 const handleimageChange = (e) => {
    const file = e.target.files[0];
    //setLogoUrl(URL.createObjectURL(file));
    setimage(file);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <form onSubmit={savedocument}>
                  <div className="container-fluid">
                      <div  className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label  style={{ fontSize: '16px' }}>
                              Select Document
                            </label>
                            <select
                              className="form-control"
                              value = {documentname}
                              onChange={(e) => setdocumentname(e.target.value)}
                            >
                              <option value=''>Select Document</option>
                              {option.map((row) => (
                                <option key={row.id} value={row.id}>
                                  {row.document_name}
                                </option>
                              ))}
                            </select>
                            {validationErrors.document && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.document}</div>
                             )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label style={{ fontSize: '16px' }}>Document Details</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Document Details"
                              value = {documentdetails}
                              onChange={(e) => setdocumentdetails(e.target.value)}
                            />
                            {validationErrors.document_details && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.document_details}</div>
                             )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label style={{ fontSize: '16px' }}>Select file</label>
                            <input
                              type="file"
                              className="form-control"
                                onChange={handleimageChange}
                            />
                          </div>
                          {imageurl && (
                     <div>
                     <img src={imageurl} alt="Document" style={{ maxWidth: '80px', height: '80px' }} />
                     </div>
                      )}
                        </div>
                      </div>
                    <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="sm"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Updating...' : 'Update'}
                      </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditempDocument;
