// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import SuperadminHeader from "layouts/common_components/superadminheader";

function FollowupStatus() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [productlist, setproductlist] = useState([]);
  const [product,setproduct] = useState('');
  const [utmlist,setutmlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [name, setname] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [editopenmodel,seteditopenmodel] = useState(false);
  const [servicelist,setservicelist] = useState([]);
  const [service,setservice] = useState('');
  const[id,setid] = useState('');
  const [question,setquestion] = useState('');
  const [checked,setchecked] = useState('');
  const [followuplist,setfollowuplist] = useState([]);
  //const [name,setname] =useState('');
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchfollowup();
    productdetails();

  },[])
  const fetchfollowup = async () => {
    try {
      const endpoint = 'enq-followup-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setfollowuplist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    if(product){
    const fetchData = async () => {
      try {
        const endpoint = `get-service-list-by-product-id/${product}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
            setservicelist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }
  }, [product]);

  const closemodel = ()=>{
   // alert('kk');
    setproduct(' ');
    setservice('');
    setname('');
    setValidationErrors({});
    setOpenModal(false);
    seteditopenmodel(false);


  }


const openpopup = ()=>{
    setOpenModal(true);

}
const editform = async(e)=>{
    setid(e);
        try {
            const endpoint = `edit-enq-followup/${e}`;
            const response = await dashboardApi.get(endpoint);
           if (response.data.status === 200) {
            //console.log(response.data.data.department_id);
            setproduct(response.data.data.product_id);
            setservice(response.data.data.service_id);
            setname(response.data.data.name);
           //setQuantity(response.data.data.quantity);
          //setselectedemployee(assignToValues);
            } else {
              console.error('Error fetching data:');
            }
          // setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            //setLoading(false);
          }
          seteditopenmodel(true);
        }

  const savequestion = async (e) => {
    e.preventDefault();
    const errors = {};
    if(!name){
      errors.name = 'Name Is Required'
    }
    setValidationErrors(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('name',name);
    formData.append('emp_id',emp_id);

    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('add-enq-followup', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
       // alert();
       //setOpenModal(false);
       closemodel();
       fetchfollowup();

      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
   finally {
        //setIsSubmitting(false);
      }
  };
}

const updatedoc = async(e)=>{
    e.preventDefault();
    const errors = {};
    if(!name){
      errors.name = 'Name Is Required'
    }
    setValidationErrors(errors);
    //setValidationErrors(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('name',name);
    formData.append('emp_id',emp_id);
    //setIsSubmitting(true);

    try {
     const response =  await dashboardApi.post(`update-enq-followup/${id}`, formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        closemodel();
        fetchfollowup();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
 }
}

const toggleStatus = async (followupid) => {
  // alert(documentId);
   const isConfirm = await Swal.fire({
           title: 'Are you sure?',
           text: "Are You Want To Change Status!",
           icon: 'warning',
           showCancelButton: true,
           confirmButtonColor: '#3085d6',
           cancelButtonColor: '#d33',
           confirmButtonText: 'Yes, Change it!'
         }).then((result) => {
           return result.isConfirmed
         });

         if(!isConfirm){
           return;
         }
   const response = await dashboardApi.get(`enq-followup-status/${followupid}`);
   if (response.data.status === 200) {
     const currentStatus = response.data.data;
     const newStatus = currentStatus === 1 ? 0 : 1;
     const updateResponse = await dashboardApi.post('enq-followup-status-change', {
       id:followupid,
       status: newStatus,
     });
     if (updateResponse.data.status === 200) {
       setfollowuplist((prevState) =>
         prevState.map((docObj) =>
         docObj.id === followupid ? { ...docObj, status: newStatus } : docObj
         )
       );
     } else {
       console.error('Error updating status:', updateResponse.data.message);
     }
   } else {
     console.error('Error fetching current status:', response.data.message);
   }
 };


//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <SuperadminHeader/>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={openpopup}>
                    +
                </Link>
                </div>
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Name</td>
                  <td>Status</td>
                  <td>Action</td>

               </tr>
               {followuplist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.name}</td>
                  <td><button
                             type="button"
                             className={`btn btn-${row.status === 1 ? 'success' : 'danger'}`}
                             onClick={() => toggleStatus(row.id)}
                             >
                           {row.status === 1 ? 'Active' : 'Inactive'}
                         </button></td>

                 <td>
                      <button className="btn btn-info"onClick={() => editform(row.id)}>Edit</button>
                   </td>
               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
            <Dialog
        open={openModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'530px'} }}
      >
        <DialogTitle>Add Followup Status</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
             <div className="row">
                <label>Name</label>
                  <input
                  type = "text"
                  className='form-control'
                  placeholder="name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
               

            </div>
            {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
           
           
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodel} >
            Close
          </button>
          <button className="btn btn-info" onClick={savequestion} >
            Save
          </button>
        </DialogActions>
       </Dialog>

       <Dialog
        open={editopenmodel}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'530px'} }}
      >
        <DialogTitle>Edit Status</DialogTitle>
        <DialogContent>
        <form>
            <div className="container">

            <div className="row">
                <label>Name</label>
                  <input
                  type = "text"
                  className='form-control'
                  placeholder="name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
                 {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
               

            </div>
           
           
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodel} >
            Close
          </button>
          <button className="btn btn-info" onClick={updatedoc} >
            Update
          </button>
        </DialogActions>
       </Dialog>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FollowupStatus;