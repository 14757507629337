import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

// Data
import Swal from 'sweetalert2';
import { useNavigate,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";


function AddBranch() {
  const navigate = useNavigate();
  const token = secureLocalStorage.getItem('token');
  const[options, setOptions] = useState([]);
  const[companyoptions, setcompanyoptions] = useState([]);
  const[branchType, setbranchType] = useState([]);
  const[selectedbranchType,setselectedbranchType] = useState('');
  const[selectedCountry,setselectedCountry] = useState('');
  const[states, setStates] = useState([]);
  const[selectedState,setselectedState] = useState('');
  const[selectedCompany,setselectedCompany] = useState('');
  const[branch,setbranch] = useState('');
  const[zipid,setzipid] = useState('');
  const[address,setaddress] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email,setEmail] = useState('');
  const [phone,setphone] = useState('');
   useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-country'; // Replace with the actual endpoint
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setOptions(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

  useEffect(() => {
  if (selectedCountry) {
    const fetchData = async () => {
      try {
        const endpoint = `country-based-state/${selectedCountry}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setStates(response.data.data);
        } else {
          console.error('Error fetching states data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching states data:', error);
      }
    };

    fetchData();
  }
}, [selectedCountry]);


   useEffect(() => {
   const fetchData = async () => {
    try {
      const endpoint = 'get-active-company'; 
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setcompanyoptions(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

  useEffect(() => {
   const fetchData = async () => {
    try {
      const endpoint = 'get-active-branch-type'; 
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setbranchType(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);


  const savebranch = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!branch) {
      errors.branch_name = 'Branch Name is required';
    }
    if (!address) {
      errors.full_address = 'Address is required';
    }
    if (!zipid) {
      errors.zip_id = 'Zip code is required';
    }
     if (!selectedCountry) {
      errors.country = 'Country is required';
    }
     if (!selectedState) {
      errors.state = 'State is required';
    }
    if(!selectedbranchType){
      errors.branch_type = 'Branch Type is required';

    }
    if(!selectedCompany){
      errors.company_id = 'Company is required';

    }

    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const nameRegex = /^[A-Za-z\s]+$/;
    const zipCodeRegex = /^\d{5}$/; 
    if (!nameRegex.test(branch)) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      branch_name: 'Branch Name should contain only letters.',
    }));
    return;
  }
  if (!nameRegex.test(address)) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      full_address: 'Address should contain only letters.',
    }));
    return;
  }
  if (!zipCodeRegex.test(zipid)) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      zip_id: 'ZIP code must be a 5-digit number.',
    }));
    return;
  }
    const formData = new FormData()
    formData.append('branch_name', branch);
    formData.append('country', selectedCountry);
    formData.append('state', selectedState);
    formData.append('zip_id', zipid);
    formData.append('full_address', address);
    formData.append('branch_type', selectedbranchType);
    formData.append('company_id', selectedCompany);
    formData.append('email',email);
    formData.append('phone',phone);
    

     setIsSubmitting(true);
    try {
      const response = await dashboardApi.post('save-branch', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/branch-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the branch',
      });
    }
    finally {
        setIsSubmitting(false);
      }
  };
}


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div style={{float:'right'}}>
                <Link to='/branch-list' className="btn btn-info">Back</Link>

              </div>
              <div className="card-body">
                <form onSubmit={savebranch}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Select Company</label>
                          <select className="form-control"
                           value={selectedCompany}
                           onChange={(e) => setselectedCompany(e.target.value)}
                            >
                            <option value=''>Select Company</option>
                            {companyoptions.map((row) => (
                             <option key={row.id} value={row.id}>
                              {row.business_name}
                              </option>
                              ))}
                              
                          </select>
                          {validationErrors.company_id && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.company_id}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Select Branch Type</label>
                          <select className="form-control"
                           value={selectedbranchType}
                           onChange={(e) => setselectedbranchType(e.target.value)}
                            >
                            <option value=''>Branch Type</option>
                            {branchType.map((row) => (
                             <option key={row.id} value={row.id}>
                              {row.branch_type}
                              </option>
                              ))}
                              
                          </select>
                           {validationErrors.branch_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.branch_type}</div>
                             )}
                        </div>
                      </div>
                       <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="branch name" style={{ fontSize: '16px' }}>Branch Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="branch-name"
                            placeholder="Enter Branch Name"
                            value={branch}
                            onChange={(e) => setbranch(e.target.value)}
                          />
                          {validationErrors.branch_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.branch_name}</div>
                             )}
                        </div>
                      </div>

                      </div>
                      <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Zip Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="zip-code"
                            placeholder="Enter Zip Code"
                            value={zipid}
                            onChange={(e) => setzipid(e.target.value)}
                          />
                           {validationErrors.zip_id && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.zip_id}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            placeholder="Enter Address"
                             value={address}
                            onChange={(e) => setaddress(e.target.value)}
                          />
                           {validationErrors.full_address && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.full_address}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px'}}>Select Country</label>
                         <select className="form-control"
                           value={selectedCountry}
                           onChange={(e) => setselectedCountry(e.target.value)}
                            >
                            <option value=''>Select Country</option>
                            {options.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.name}
                              </option>
                              ))}
                              
                          </select>
                          {validationErrors.country && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.country}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Select State</label>
                          <select className="form-control" 
                          value={selectedState}
                           onChange={(e) => setselectedState(e.target.value)}
                           >
                           <option value=''>Select State</option>
                            {states ? (
                             states.map((state) => (
                             <option key={state.id} value={state.id}>
                             {state.state_name}
                            </option>
                            ))
                           ) : (
                            <option disabled>No states available for the selected country</option>
                           )}
                          </select>
                          {validationErrors.state && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.state}</div>
                             )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Branch Email</label>
                          <input type = 'email'
                          className="form-control" 
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                         />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Branch Phone</label>
                          <input type = 'number'
                           className="form-control"
                           placeholder="Enter phone" 
                          value={phone}
                          onChange={(e) => setphone(e.target.value)}
                         />
                        </div>
                      </div>


                      </div>


                      

                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                      <button
                        variant="primary"
                        className="mt-2 btn btn-info"
                        size="lg"
                        block="block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddBranch;
