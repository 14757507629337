import React from 'react';
import { Link, useLocation } from "react-router-dom";
function OfferDetailsHeader() 
{
    let location = useLocation();
    let currentPage = location.pathname;

  return (
    <div>
      <Link style={currentPage === "/offers" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/offers">
        Offer Details
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/offer-type" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/offer-type">
        Offer Type
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/offer-category" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/offer-category">
        Offer Category
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/offer-grab-history" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/offer-grab-history">
        Offer Grab Details
      </Link>
    </div>
  );
}


export default OfferDetailsHeader;
