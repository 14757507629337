import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate, useNavigate,useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import Card from "@mui/material/Card";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';

const EnquiryList = () => {
  const navigate = useNavigate();

  const { sales } = reportsLineChartData;
  const {total_enquiry} = useParams();
  const token = secureLocalStorage.getItem('token');
  const [productlist, setProductlist] = useState([]);
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
  const [product, setProduct] = useState('');
  const [servicelist, setServicelist] = useState([]);
  const [service, setService] = useState('');
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [grouplist, setGroupList] = useState([]);
  const [group, setGroup] = useState('');
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 10;
  const [source_type,get_source_type] = useState([]);
  const [selectedsource_type,setselectedsource_type] = useState('');
  const [selected_enqstatus,setselected_enqstatus] = useState('');
  const [verified_status,setverified_status] = useState('');
   const [category_list,set_category_list] = useState([]);
  const [category,selectedcategory] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    groupDetails();
    productDetails();
    source_type_details();
  }, []);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  useEffect(()=>{
    fetchData()

  },[product,service,start_date,end_date,group,selectedsource_type,selected_enqstatus,verified_status,category,currentPage])

  const groupDetails = async () => {
    try {
      const response = await dashboardApi.get('group-details');
      if (response.data.status === 200) {
        setGroupList(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const productDetails = async () => {
    try {
      const response = await dashboardApi.get('get-product-list');
      if (response.data.status === 200) {
        setProductlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const response = await dashboardApi.get(`get-service-list-by-product-id/${product}`);
          if (response.data.status === 200) {
            setServicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

   useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);

 const fetchData = async () => {
    setLoading(true);
    try {
      const response = await dashboardApi.get('dm-dashboard-enquiry-data', {
        params: {
          per_page: postsPerPage,
          page: currentPage + 1,
          product,
          service,
          start_date,
          end_date,
          group,
          source_type: selectedsource_type,
          enq_status: selected_enqstatus,
          verified_status,
          type: total_enquiry,
          category
        }
      });

      if (response.data.status === 200) {
        setList(response.data.data.data || response.data.data);
        setTotalPages(response.data.data.last_page || 1);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  //  const getData_filter = async (page) => {
  //   try {
  //     const response = await dashboardApi.get('dm-dashboard-enquiry-data', {
  //       params: {
  //         per_page: postsPerPage,
  //         page: page + 1, 
  //         product:product,
  //         service:service,
  //         start_date:start_date,
  //         end_date:end_date,
  //         group:group,
  //         source_type:selectedsource_type,
  //         enq_status:selected_enqstatus,
  //         verified_status:verified_status,
  //         type:total_enquiry,
  //         category:category,
  //       }
  //     });

  //     if (response.data.status === 200) {
  //       const enqData = response.data.data.data || response.data.data;
  //       setList(enqData);
  //       setTotalPages(response.data.data.last_page);
  //     } else {
  //       console.error('Error fetching data:', response.data.error);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setLoading(false);
  //   }
  // };

  const handlePageClick = (selected) => {
    console.log(selected);
    setCurrentPage(selected.selected);
  };
 
  const source_type_details = async () => {
    try {
      const response = await dashboardApi.get('source-type-list');
      if (response.data.status === 200) {
        get_source_type(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
}

const downloadata = async()=>{
  try {
    const response = await dashboardApi.get('dm-dashboard-enquiry-data', {
     params: {
       download: 'csv',
          product:product,
          service:service,
          start_date:start_date,
          end_date:end_date,
          group:group,
          source_type:selectedsource_type,
          enq_status:selected_enqstatus,
          verified_status:verified_status,
          type:total_enquiry,
      },
     responseType: 'blob',
  });

    const blob = new Blob([response.data], { type: 'text/csv' });
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `sales_data_${new Date().toISOString()}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading the CSV file', error);
  }
 

}

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <div>
            <div className="d-flex flex-wrap">
              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Group</option>
                  {grouplist.map((row) => (
                    <option key={row.group_id} value={row.group_id}>
                      {row.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Product</option>
                  {productlist.map((row) => (
                    <option key={row.id} value={row.id}>
                      {row.product_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Service</option>
                  {servicelist.map((ser) => (
                    <option key={ser.id} value={ser.id}>
                      {ser.service_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={category}
                onChange={(e) => selectedcategory(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Category</option>
                {category_list.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.category_name}
                  </option>
                ))}
              </select>
            </div>

              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={start_date}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>
              <div className="col-sm-3 col-6 p-1">
                <input
                  type="date"
                  value={end_date}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="form-control"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                />
              </div>

              <div className="col-sm-3 col-6 p-1">
              <select
                  className="form-control form-select"
                  value={selectedsource_type}
                  onChange={(e) => setselectedsource_type(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Source Type</option>
                  {source_type.map((row) => (
                    <option key={row.source_type} value={row.source_type}>
                      {row.source_type}
                    </option>
                  ))}
                </select>

              
              </div>

              
              <div className="col-sm-3 col-6 p-1">
              <select
                  className="form-control form-select"
                  value={selected_enqstatus}
                  onChange={(e) => setselected_enqstatus(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Enq Status</option>
                  <option value='1'>Sent</option>
                  <option value='3'>Not Sent</option>
                  <option value='2'>Duplicate</option>
                </select>

              
              </div>

              <div className="col-sm-3 col-6 p-1">
              <select
                  className="form-control form-select"
                  value={verified_status}
                  onChange={(e) => setverified_status(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Verified By</option>
                  <option value='otp'>OTP</option>
                  <option value='cs'>CS</option>
                </select>

              
              </div>
            </div>
          </div>

          <div className="card-body">
           <button className='btn btn-info btn btn-sm' onClick={downloadata}>Download</button>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered table-hovered">
                  <thead>
                    <tr>
                      <td style ={{fontSize:'12px'}}>Enq id</td>
                      <td style={{fontSize:'12px'}}>Cust.Name</td>
                      <td style={{fontSize:'12px'}}>Group</td>
                      <td style={{fontSize:'12px'}}>Product</td>
                      <td style={{fontSize:'12px'}}>Category</td>
                      <td style={{fontSize:'12px'}}>Service</td>
                      <td style={{fontSize:'12px'}}>Sent Count</td>
                      <td style={{fontSize:'12px'}}>Source Type</td>
                      <td style={{fontSize:'12px'}}>Source url</td>
                      <td style={{fontSize:'12px'}}>Verified By</td>
                      <td style={{fontSize:'12px'}}>Date</td>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((row, index) => (
                      <tr key={row.id}>
                        <td style={{fontSize:'12px'}}>{row.id}</td>
                        <td style={{fontSize:'12px'}}>{row.name}</td>
                        <td style={{fontSize:'12px'}}>{row.group_name}</td>
                        <td style={{fontSize:'12px'}}>{row.product_name}</td>
                        <td style={{fontSize:'12px'}}>{row.category_name}</td>
                        <td style={{fontSize:'12px'}}>{row.service_name}</td>
                        <td style={{fontSize:'12px'}}>{row.sent_count}</td>
                        <td style={{fontSize:'12px'}}>{row.source_type}</td>
                        <td style={{fontSize:'12px'}}>{row.source}</td>
                        <td style={{fontSize:'12px'}}>{row.verification_status}</td>
                       <td style={{fontSize:'12px'}}>{row.created_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {list.length > 0 && (
            <Pagination className="custom-pagination">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link-style"}
                breakLinkClassName={"break-link-style"}
                previousLinkClassName={"prev-link-style"}
                nextLinkClassName={"next-link-style"}
              />
            </Pagination>
          )}
        </Card>
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
}

export default EnquiryList;
