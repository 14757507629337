import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";


function SuperadminHeader() {

  return (
            <Card>
              <div className="card-body">
                  <div className="container-fluid">
                  <div className="row">
                  <div className="col-md-2">
                  <Link to='/utm-list' className='btn btn-outline-success'>
                            UTM
                  </Link>
                  </div>
                  <div className="col-md-2">
                 <Link to='/question-list' className='btn btn-outline-success'>
                            Question
                  </Link>
                  </div>

                  <div className="col-md-2">
                 <Link to='/followup-status' className='btn btn-outline-success'>
                             Followup Status
                  </Link>
                  </div>

                  </div>
                  </div>
              </div>
            </Card>
  );
}


export default SuperadminHeader;
