
import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import Swal from 'sweetalert2';
import { useNavigate,useParams,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

function EditService() {
  const { id } = useParams();
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const[product,setproduct] = useState('');
  const[selectedproduct,setselectedproduct] = useState('');
  const[service,setservice] = useState('');
  const[shortname,setshortname] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-company'; // Replace with the actual endpoint
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setOptions(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

  useEffect(() => {
  if (selectedCompany) {
    const fetchData = async () => {
      try {
        const endpoint = `company-based-product/${selectedCompany}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setproduct(response.data.data);
        } else {
          console.error('Error fetching states data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching states data:', error);
      }
    };

    fetchData();
  }
}, [selectedCompany]);
   useEffect(()=>{
    fetchservice()
  },[])
  const fetchservice = async () => {
   await dashboardApi.get(`edit-service/${id}`).then(({data})=>{
      //console.log('hi');
      const {product_id,company_id,service_short_name,service_name} = data.service
      setservice(service_name);
      setshortname(service_short_name);
      setSelectedCompany(company_id);
      setselectedproduct(product_id);

      //console.log(ShortName);



    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }
  const updateservice = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!service) {
      errors.service_name = 'Service Name is required';
    }
    if (!shortname) {
      errors.short_name = 'Short Name is required';
    }
    if (!selectedCompany) {
      errors.company = 'Company is required';
    }
      if (!selectedproduct) {
      errors.product = 'Product is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(service)) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      service_name: 'Service Name should contain only letters.',
    }));
    return;
  }
   if (!nameRegex.test(shortname)) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      short_name: 'Short Name should contain only letters.',
    }));
    return;
  }
    const formData = new FormData()
    formData.append('service_name', service);
    formData.append('short_name', shortname);
    formData.append('company', selectedCompany);
    formData.append('product', selectedproduct);
    try {
       const response = await dashboardApi.post(`update-service/${id}`, formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
         navigate("/service-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the country',
      });
    }
  };
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div style={{float:'right'}}>
               <Link to='/service-list' className='btn btn-info'>Back</Link>
              </div>
              <div className="card-body">
                <form onSubmit={updateservice}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Select Company</label>
                         <select className="form-control"
                          value={selectedCompany}
                          onChange={(e) => setSelectedCompany(e.target.value)}

                          >
                            <option value=''>Select Company</option>
                            {options.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.business_name}
                              </option>
                              ))}
                          </select>
                           {validationErrors.company && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.company}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Select Product</label>
                         <select className="form-control" 
                          value={selectedproduct}
                           onChange={(e) => setselectedproduct(e.target.value)}
                           >
                           <option value=''>Select product</option>
                            {product ? (
                             product.map((products) => (
                             <option key={products.id} value={products.id}>
                             {products.product_name}
                            </option>
                            ))
                           ) : (
                            <option disabled>No product available for the selected country</option>
                           )}
                          </select>
                           {validationErrors.product && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Service Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="service_name"
                            placeholder="Enter Service Name"
                            value={service}
                           onChange={(e) => setservice(e.target.value)}
                          />
                          {validationErrors.service_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.service_name}</div>
                             )}
                        </div>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Service Short Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="service-short-name"
                            placeholder="Enter Service Short Name"
                            value={shortname}
                           onChange={(e) => setshortname(e.target.value)}
                          />
                          {validationErrors.short_name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.short_name}</div>
                             )}
                        </div>
                      </div>
                     </div>


                      

                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                           Save
                        </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditService;
