// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import SuperadminHeader from "layouts/common_components/superadminheader";

function Utm() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [utmlist, setutmlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [name, setname] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [editopenmodel,seteditopenmodel] = useState(false);
  const[id,setid] = useState('');
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchDocument();

  },[])
  const fetchDocument = async () => {
    try {
      const endpoint = 'utm-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setutmlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const closemodel = ()=>{
   // alert('kk');
    setname(' ');
    setOpenModal(false);
    seteditopenmodel(false);
    setValidationErrors({});


  }


const openpopup = ()=>{
    setOpenModal(true);

}
const editform = async(e)=>{
    setid(e);
        try {
            const endpoint = `utm-edit/${e}`;
            const response = await dashboardApi.get(endpoint);
           if (response.data.status === 200) {
            //console.log(response.data.data.department_id);
            setname(response.data.data.name);
           //setQuantity(response.data.data.quantity);
          //setselectedemployee(assignToValues);
            } else {
              console.error('Error fetching data:');
            }
          // setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            //setLoading(false);
          }
          seteditopenmodel(true);




    

}
const toggleStatus = async (documentId) => {
   // alert(documentId);
    const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`utm-status/${documentId}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('utm-status-change', {
        id:documentId,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        setutmlist((prevState) =>
          prevState.map((docObj) =>
          docObj.id === documentId ? { ...docObj, status: newStatus } : docObj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };
  const savedocument = async (e) => {
    e.preventDefault();
    const errors = {};
    setValidationErrors(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('name',name);
    formData.append('emp_id',emp_id);
    //setIsSubmitting(true);

    try {
      const response = await dashboardApi.post('save-utm', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
       // alert();
       //setOpenModal(false);
       closemodel();
       fetchDocument();

      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
   finally {
        //setIsSubmitting(false);
      }
  };
}

const updatedoc = async(e)=>{
    e.preventDefault();
    const errors = {};
    if(!name){
        errors.name = 'Name is required';
    }
    setValidationErrors(errors);
   if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('name',name);
    formData.append('emp_id',emp_id);

    //setIsSubmitting(true);

    try {
     const response =  await dashboardApi.post(`utm-update/${id}`, formData);
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchDocument();
        closemodel();

      }
      else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
      });
    }
 }
}


//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <SuperadminHeader/>
            <Card>
              <div className="card-body">
                <div style={{float:'right'}}>
                <Link className='btn btn-primary mb-2 float-end' onClick={openpopup}>
                    +
                </Link>
                </div>
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Name</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {utmlist.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.name}</td>
                 
                  <td><button
                  type="button"
                  className={`btn btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button></td>
                 <td>
                      <button className="btn btn-info"onClick={() => editform(row.id)}>Edit</button>
                   </td>
               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
            <Dialog
        open={openModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'350px'} }}
      >
        <DialogTitle>Add Utm</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row">
                <label>Utm name</label>
                <div className='col-md-12'>
                    <input type = "text"
                    placeholder='Enter Utm'
                    className='form-control'
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    
                    />
                    {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
                 </div>
               

            </div>
           
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodel} >
            Close
          </button>
          <button className="btn btn-info" onClick={savedocument} >
            Save
          </button>
        </DialogActions>
       </Dialog>

       <Dialog
        open={editopenmodel}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'350px'} }}
      >
        <DialogTitle>Edit Utm</DialogTitle>
        <DialogContent>
        <form>
            <div className="container">
              <div className="row">
              <label>Utm name</label>
                <div className='col-md-12'>
                    <input type = "text"
                    placeholder='Enter Utm'
                    className='form-control'
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    />
                    {validationErrors.name && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.name}</div>
                             )}
                 </div>
               

            </div>
           
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodel} >
            Close
          </button>
          <button className="btn btn-info" onClick={updatedoc} >
            Update
          </button>
        </DialogActions>
       </Dialog>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Utm;