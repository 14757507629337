import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,Link } from "react-router-dom";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import PackageDetailsHeader from "layouts/common_components/package_details_header";

// for modalbox
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import Select from "@mui/material";
import  secureLocalStorage  from  "react-secure-storage";



function CategoryFactor()
{
    const navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({});
    const emp_id = secureLocalStorage.getItem('emp_id');
    const token = secureLocalStorage.getItem('token');

    const [categoryFact,setCategoryFact] = useState('');

    const [product,setProduct]=useState([]);
    const [selectedProduct,setSelectedProduct] = useState();

    const [service,setService]=useState([]);
    const [selectedService,setSelectedService] = useState();
    
    const [category,setCategory]=useState([]);
    const [selectedCategory,setSelectedCategory] = useState();

    // const [selectLocation,setSelectLocation] = useState('');
    const [leads,setRangeFrom] = useState('');
    const [base_price,setBasePrice] = useState('');
   
    
    const [leadModal,setCategoryModal] = useState(false);
    const [loading, setLoading] = useState(true);
    
    useEffect(()=>{
        if(!token)
        {
            navigate('/sign-in');
        }
        else
        {
            fetchProduct();
            getCategoryFact();
        }
    },[token, navigate]);

    const categoryModalOpenControl = ()=>{
        setCategoryModal(true);
    }
    
    const leadModalCloseControl = ()=>{
        setCategoryModal(false);
    }

    const fetchProduct = async () => 
    {
      try{
        const response = await dashboardApi.get(`sales-product-list/active`);
        if(response.data.status === 200)
        {
          setProduct(response.data.data);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    };

   
  useEffect(()=>{
    if(selectedProduct)
    {
      const fetchService = async () => {
        try {
          const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
          if (response.data.status === 200) {
            setService(response.data.data);
          } else {
            console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
      }
      fetchService();
    }
  },[selectedProduct]);

    useEffect(()=>{
        if(selectedService)
        {
        const fetchCategory = async () => {
            try {
            const response = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
            if (response.data.status === 200) {
                setCategory(response.data.data);
            } else {
                console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
            } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
            }
        }
        fetchCategory();
        }
    },[selectedService]);

    // Add New Daya wise factor
    const categoryFactSubmit = async() =>
    {
        const errors = {};
        if (Object.keys(errors).length > 0) {
        return; 
        }
        const formData = new FormData();
        formData.append('product_id',selectedProduct);
        formData.append('service_id',selectedService);
        formData.append('category_id',selectedCategory);
        formData.append('base_price',base_price);
        formData.append('created_by',emp_id);
       
        try {
        const response = await dashboardApi.post('add-category-factor-details', formData);
            if(response.data.status === 200)
            {
                Swal.fire({
                icon: 'success',
                text: response.data.message,
            });

            navigate("/category-factor");
            leadModalCloseControl();

        } else if (response.data.status === 400) {
            setValidationErrors(response.data.messages);
        } else {
            Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
            });
        }
        } catch (error) {
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
        } 
    }

    const getCategoryFact = async() =>{
        const dayResponce = await dashboardApi.get(`get-category-fact-list`);
        if(dayResponce.status==200)
        {
            setCategoryFact(dayResponce.data.data);
        }
        else
        {
            console.log('something went wrong,Please Try Again');
        }
    }

    return(
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-sm-11">
                                            <PackageDetailsHeader></PackageDetailsHeader>
                                        </div>
                                        <div class="col-sm-1">
                                        <Link className='btn btn-info' onClick={()=>categoryModalOpenControl()}>
                                            +
                                        </Link>
                                        </div>
                                    </div>
                                    
                                    <div className="row" style={{marginTop:'1%'}}>
                                        <div class="col-sm-12"><h4>Category Factor Details</h4></div>
                                    </div>

                                    <table className="table table-bordered table-hovered">
                                        <tr>
                                            <td>S.No</td>
                                            <td>Product Name</td>
                                            <td>Service Name</td>
                                            <td>Category Name</td>
                                            <td>Base Price</td>
                                            <td>Status</td>
                                            <td>Created By</td>
                                            <td>Action</td>
                                        </tr>
                                        
                                        {categoryFact && categoryFact.map((categoryFact,index)=>(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{categoryFact.product_name}</td>
                                            <td>{categoryFact.service_name}</td>
                                            <td>{categoryFact.category_name}</td>
                                            <td>{categoryFact.base_price}</td>
                                            <td>{categoryFact.status === 1 ?'Active':'Deactive'}</td>
                                            <td>{categoryFact.created_by}</td>
                                            <td><button className="btn btn-primary btn-sm">Edit</button></td>
                                        </tr>
                                        ))
                                        }
                                    </table>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />

                <Dialog open={leadModal} fullWidth PaperProps={{ style: { maxWidth: 'xl', padding: '20px' } }}>
                    <DialogTitle>Add New Days Factor</DialogTitle>
                        <DialogContent>
                            <form>
                                
                            <div>
                                <label>Select Product</label>
                                <select className="form-control"
                                        value={selectedProduct}
                                        onChange={(e) => setSelectedProduct(e.target.value)}
                                        >
                                        <option value=''>Select Product</option>
                                        {product.map((pro) => (
                                        <option key={pro.id} value={pro.id}>
                                        {pro.product_name}
                                        </option>
                                        ))}
                                </select>
                            </div>

                            <div>
                                <label>Select Service</label>
                                <select className="form-control"
                                        value={selectedService}
                                        onChange={(e) => setSelectedService(e.target.value)}
                                        >
                                        <option value=''>Select Service</option>
                                        {service.map((ser) => (
                                        <option key={ser.id} value={ser.id}>
                                        {ser.service_name}
                                        </option>
                                        ))}
                                </select>
                            </div>

                            <div>
                                <label>Select Service</label>
                                <select className="form-control"
                                    value={selectedCategory}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                    >
                                    <option value=''>Select Category</option>
                                    {category.map((cate) => (
                                    <option key={cate.id} value={cate.id}>
                                    {cate.category_name}
                                    </option>
                                    ))}
                                </select>
                            </div>

                                <div className="col-sm-12" style={{marginTop:'5px'}}>
                                    <label>Basic Price</label>   
                                    <TextField type="text" className="form-control" value={base_price} onChange={(e) =>setBasePrice(e.target.value)}></TextField>
                                </div>
                            </form>
                        </DialogContent>

                        <DialogActions>
                            <Link className="btn btn-primary btn-sm" onClick={leadModalCloseControl}>Close</Link>
                            <Link className="btn btn-success btn-sm" onClick={categoryFactSubmit}>Add Category Factor</Link>
                        </DialogActions>
                   
                </Dialog>
            </DashboardLayout>
    );
}
export default CategoryFactor;