// @mui material components
import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// Data
import { Link } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import Swal from 'sweetalert2'
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams} from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import EmpHistoryHeader from "layouts/common_components/emp_profile_header";
import docurl from "layouts/common_components/doc_baseurl";


function EmpStockList() {
  const navigate = useNavigate();
  const { EmployeeId } = useParams();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const employee_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [assignlist, setassignlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remark,setremark] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [id,setid] = useState('');
  const [stock,setstock] = useState('');
  const [assign,setassign] = useState('');
  const [returnkey,setreturnkey] = useState('');
  let empid = '';
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchData();
 },[])

 if(EmployeeId){
  empid = EmployeeId;

 }
 else{
  empid = emp_id;
 }
  const handleAccept = async (assign_id,stocks_id,assign_to) => {
    //alert(stock_id);
    const isConfirm = await Swal.fire({
        title: 'Are you sure?',
        text: "Are You Want To Accept!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        return result.isConfirmed
      });

      if(!isConfirm){
        return;
      }
      const stock_status = 1;
      const remark_data = 'null';
      try {
        const endpoint = `change-stock-status/${assign_id}/${stocks_id}/${stock_status}/${remark_data}/${assign_to}/${employee_id}`;
        const response = await dashboardApi.get(endpoint);
  
        if (response.data.status === 200) {
            Swal.fire({
                icon: 'success',
                text: response.data.message,
              });
              fetchData();
            
          } 

        else {
          console.error('Error fetching data:');
        }
       //setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        //setLoading(false);
      }

  }
  const handlereject = async(id,stock_id,assign_to)=>{
    try {
      const endpoint = `stock-reject-reason/${id}/${assign_to}`;
      const response = await dashboardApi.get(endpoint);
  
      if (response.data.status === 200) {
        setremark(response.data.data.reject_reason);
      } else {
        console.error('Error fetching data:');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setOpenModal(true);
    setid(id);
    setstock(stock_id);
    setassign(assign_to);
    setreturnkey('');
}
 const handleCloseModal = ()=>{
    setOpenModal(false);
    setremark('');
    setid('');
    setstock('');
    setassign('');
    setreturnkey('');
 }
 const handlereturn = async (id,stock_id,assign_to,custom)=>{
  //alert();
  try {
    const endpoint = `emp-return-edit/${id}/${assign_to}`;
    const response = await dashboardApi.get(endpoint);

    if (response.data.status === 200) {
      setremark(response.data.data.emp_return_remark);
    } else {
      console.error('Error fetching data:');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
    
    setOpenModal(true);
    setid(id);
    setstock(stock_id);
    setassign(assign_to);
    setreturnkey(custom);
}
//alert(returnkey);
 const savedata = async()=>{
  //alert();
    const errors = {};
    if (!remark) {
        errors.remark = 'Please Enter Reason';
      }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }
    const status = 2;
   //alert(status);
    //const remark_data = '';
    try {
      const endpoint = `change-stock-status/${id}/${stock}/${status}/${remark}/${assign}/${employee_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
          Swal.fire({
              icon: 'success',
              text: response.data.message,
            });
            fetchData();
            handleCloseModal();
          
        } 

      else {
        console.error('Error fetching data:');
      }
     //setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      //setLoading(false);
    }

    

}
//alert(id);
const returndata = async()=>{
 const errors = {};
    if (!remark) {
        errors.remark = 'Please Enter Reason';
      }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }
     try {
      const endpoint = `return-stock/${id}/${remark}/${assign}/${employee_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
          Swal.fire({
              icon: 'success',
              text: response.data.message,
            });
            fetchData();
            handleCloseModal();
          
        } 

      else {
        console.error('Error fetching data:');
      }
     //setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      //setLoading(false);
    }

}

  //const endpoint = `ticket-raise-notification/${emp_id}`;
  const fetchData = async () => {
    try {
      const endpoint = `emp-stock-list/${empid}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setassignlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
     setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

//console.log(raiselist);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            {
              EmployeeId && 
               <EmpHistoryHeader emp_id = {EmployeeId}></EmpHistoryHeader>
            }
             
              <div className="card-body">
                 {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Category</td>
                  <td>Stock</td>
                  <td>Quantity</td>
                  <td>Assign Date</td>
                  <td>Return Date</td>
                  <td>Return Status</td>
                  <td>Return Remark</td>
                  <td>Approved Status</td>
                  <td>Term & Condition</td>
                   {
                    !EmployeeId && 
                      <td>Action</td>
                   }
                   {
                    !EmployeeId && 
                    <td>History</td>

                   }
                   
                 
               </tr>
               {assignlist.map((assign, index) => (
                <tr key={assign.id}>
                  <td>{index + 1}</td>
                  <td>{assign.category_name}</td>
                  <td>{assign.brand_name}</td>
                  <td>{assign.quantity}</td>
                  <td>{assign.assign_date}</td>
                  <td>{assign.return_date}</td>
                  <td>{assign.return_status === 1 && (
                     <p>Returned</p>
                  )} 
                  {assign.return_status ===0 && (
                     <p>pending</p>
                  )}
                   {assign.return_status ===2 && (
                     <p>Reject</p>
                  )}

                  </td>
                  <td>{assign.admin_return_remark}</td>
                  <td>{assign.status === 1 && (
                    <p>Accept</p>
                  )} 
                  {assign.status ===2 && (
                     <p>Reject</p>
                  )}</td>
                 <td>
      {assign.term_and_condition_image && (
        <React.Fragment>
          {assign.term_and_condition_image.endsWith('.pdf') ? (
            <a
              href={`http://localhost:8000/term_and_condition/${assign.term_and_condition_image}##toolbar=0`}
              download="term_and_condition.pdf"
              style={{ width: '100%', height: '400px' }}
            >
            <FontAwesomeIcon icon={faFilePdf} /> Term And Condition
            </a>
          ) : (
            <a
              href={`http://localhost:8000/term_and_condition/${assign.term_and_condition_image}`}
              download="term_and_condition"
            >
              <img
                src={`http://localhost:8000/term_and_condition/${assign.term_and_condition_image}`}
                alt="Term and Condition"
                style={{ maxWidth: '80px', height: 'auto', cursor: 'pointer' }}
              />
            </a>
          )}
        </React.Fragment>
      )}
    </td>
                  {
  (assign.status === 1 || assign.status === 2) && assign.return_status!=1 &&!EmployeeId ? (
    <td>
      <Link className='btn btn-success me-2 btn-sm' onClick={() => handlereturn(assign.id, assign.stock_id, assign.assign_to, 'returnbutton')}>
        Return 
      </Link>
    </td>
  ) : (
    assign.return_status === 1 && (
      <td>
        <p>Returned</p>
      </td>
    )
  )
}
{
  !(assign.status === 1 || assign.status === 2) && (
    <td>
      <Link className='btn btn-success me-2 btn-sm' onClick={() => handleAccept(assign.id, assign.stock_id, assign.assign_to)}>
        Accept
      </Link>
      <Link className='btn btn-danger me-2 btn-sm' onClick={() => handlereject(assign.id, assign.stock_id, assign.assign_to)}>
        Reject
      </Link>
    </td>
  )
}
{
  !EmployeeId && 
  <td><Link className='btn btn-info btn-sm' to={`/emp-stock-history/${assign.stock_id}`}>view</Link></td>
}


               </tr>
              ))}
            </table>
             )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px' } }}
      >
        <DialogTitle>Reject Stock</DialogTitle>
        <DialogContent>
          <form>
            <div>
              <label>Reason:</label>
              <TextField
                type="text"
                fullWidth
                 value={remark}
                onChange={(e) => setremark(e.target.value)}
              />
             {validationErrors.remark&& (
                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                )}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
        <Link className="btn btn-danger" onClick={handleCloseModal}>
            Close
          </Link>
  <Link className="btn btn-info" onClick={returnkey && returnkey === 'returnbutton' ? returndata : savedata}>
    {returnkey && returnkey === 'returnbutton' ? 'Return' : 'Save'}
  </Link>

        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default EmpStockList;