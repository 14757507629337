import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import ReactPaginate from "react-paginate";
import secureLocalStorage from "react-secure-storage";

function LeaveData() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const navigate = useNavigate();
  const [leavedata, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [permission, setPermission] = useState();
  const [rejectionReason, setRejectionReason] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [postPerPage] = useState(1);
  const [searchEmp, setSearchEmp] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const handleStatusChange = (index, value) => {
    const updatedLeaveData = [...leavedata];
    updatedLeaveData[index].status = value;
    setLeaveData(updatedLeaveData);
  };

  const handleReasonChange = (e) => {
    setRejectionReason(e.target.value);
  };

  const handleSaveButtonClick = async (leaveId, leaveTypeId, empId, no_of_days, index, value) => {
    try {
      const errors = {};
        if( value == 2 && !rejectionReason){
           errors.reason = 'please enter reason';
           alert('please enter reason');
         }
         setValidationErrors(errors);
        if (Object.keys(errors).length === 0) { 
       const response = await dashboardApi.post("emp-leave-status", {
        leaveId,
        empId,
        emp_id:emp_id,
        leave_type: leaveTypeId,
        status: value,
        no_of_days: no_of_days,
        rejectionReason: value === "2" ? rejectionReason : "", 
      });

      if (response.data.status === 200) {
        Swal.fire({
          icon: "success",
          text: response.data.message,
        });
        fetchLeaveData(currentPage);  
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.message,
        });
        fetchLeaveData(currentPage);  
      }
    }
    } catch (error) {
      console.error("Error updating leave status:", error);
    }
}

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchLeaveData(currentPage);
  }, [currentPage, searchEmp]);

  const fetchLeaveData = async (page = 0) => {
    setLoading(true);
    try {
      const response = await dashboardApi.post('team-leave-data', {
        id: emp_id,
        per_page: postPerPage,
        page: page + 1,
        emp: searchEmp,
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        setLeaveData(employeeData);
        setTotalPages(response.data.last_page);
      } else {
        console.error("Error fetching leave data:", response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching leave data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const endpoint = `get-permission/${emp_id}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setPermission(response.data.data);
        } else {
          console.error("Error fetching permissions:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermission();
  }, [emp_id]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div style={{ float: 'right' }}>
                  <input 
                    type="text"
                    className="form-control"
                    placeholder="Search By Name"
                    value={searchEmp}
                    onChange={(e) => setSearchEmp(e.target.value)}
                  />
                </div>
                <br /> <br />
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered table-striped">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Leave Name</th>
                        <th>From</th>
                        <th>To</th>
                        <th>No Of Days</th>
                        <th>Message</th>
                        <th>Rejected Reason</th>
                        <th style={{ minWidth: '200px' }}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leavedata.map((leave, index) => (
                        <tr key={leave.id}>
                          <td>{index + 1 + currentPage * postPerPage}</td>
                          <td>{leave.emp_fname} {leave.emp_mname} {leave.emp_lname}</td>
                          <td>{leave.leave_name}</td>
                          <td>{leave.date_from}</td>
                          <td>{leave.date_to}</td>
                          <td>{leave.no_of_days}</td>
                          <td>{leave.message}</td>
                          <td>{leave.rejection_reason}</td>
                          <td>
                            <select
                              className="form-control"
                              value={leave.status || ""}
                              onChange={(e) => handleStatusChange(index, e.target.value)}
                            >
                              <option value="">Select Status</option>
                              <option value="1">Approved</option>
                              <option value="2">Rejected</option>
                            </select>
                            {leave.status === "2" && (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter rejection reason"
                                onChange={handleReasonChange}
                                required
                              />
                              
                            )}
                            <button
                              className="mt-2 ms-6 btn btn-info"
                              type="button"
                              onClick={() => handleSaveButtonClick(leave.id, leave.leave_id, leave.emp_id, leave.no_of_days, index, leave.status)}
                            >
                              Save
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {leavedata.length > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link-style"}
                    breakLinkClassName={"break-link-style"}
                    previousLinkClassName={"prev-link-style"}
                    nextLinkClassName={"next-link-style"}
                  />
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LeaveData;
